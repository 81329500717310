import { Button, Col, Form, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import request from '../../../../utils/axios'

function HolidayForm() {
    const [form] = Form.useForm()
    const [holidayListFile, setHolidayListFile] = useState(null)
    const [holidayLeavePolicyList, setHolidayLeavePolicyList] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])

    const handleSelectAll = () => {
        setSelectedOptions(holidayLeavePolicyList.map((item) => {
            return item.value
        }))
    }

    const onValuesChange = (changedValues, allValues) => {
        if (changedValues?.leave_policy) {
            if (changedValues?.leave_policy.includes("all")) {
                form.setFieldsValue({
                    leave_policy: holidayLeavePolicyList.map((item) => {
                        return item.value
                    })
                });
            }
        }
        console.log("changedValues", changedValues);
    }

    const onFinishHoliday = (values) => {
        try {
            const formData = new FormData();
            formData.append('year', values.year);
            formData.append('leave_policy', values.leave_policy);
            formData.append('candidate_contact', values.candidate_contact);
            formData.append('file', holidayListFile);


            console.log("onFinishHoliday", formData);
            form.resetFields();
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const fetchAllLeavePolicy = async () => {
        try {
            const data = await request({
                method: "get",
                url: "leave/leave_policy/all/?page=1&page_size=2",
            })


            const holidayLeavePolicyList = data.pagination_data.data.map((item, index) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })

            setHolidayLeavePolicyList(holidayLeavePolicyList)
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {

        fetchAllLeavePolicy()

    }, [])

    return (
        <div
            style={{
                padding: "16px",
                height: "100vh"
            }}>
            <div style={{
                background: "white",
                padding: "20px 10px"
            }}>
                <p>HolidayForm</p>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinishHoliday}
                    onValuesChange={onValuesChange}
                >
                    <Row gutter={[32, 32]}>
                        <Col span={24}>
                            <Form.Item
                                label="Year"
                                name="year"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Year!',
                                    },
                                ]}
                            >
                                <Select
                                    size='large'
                                // onChange={(e) => setLeavePolicyForm({ ...leavePolicyForm, start_time: e })}
                                >
                                    <Select.Option value={new Date().getFullYear()}>{new Date().getFullYear()}</Select.Option>
                                    <Select.Option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</Select.Option>
                                    <Select.Option value={new Date().getFullYear() + 2}>{new Date().getFullYear() + 2}</Select.Option>
                                    <Select.Option value={new Date().getFullYear() + 3}>{new Date().getFullYear() + 3}</Select.Option>
                                    <Select.Option value={new Date().getFullYear() + 4}>{new Date().getFullYear() + 4}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                            <Form.Item
                                label="Leave Policy"
                                name="leave_policy"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Leave Policy!',
                                    },
                                ]}
                            >
                                <Select
                                    size='large'
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    value={selectedOptions}
                                    name="leave_policy"
                                    onSelect={handleSelectAll}
                                >
                                    <Select.Option value="all">All</Select.Option>
                                    {holidayLeavePolicyList.map(option => (
                                        <Select.Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Select.Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <p>Upload File</p>
                            <Form.Item
                                label="Upload File"
                                name="end_time"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Date (To)!',
                                    },
                                ]}
                            >
                                <div className="file-upload-container">
                                    <input
                                        type="file"
                                        id="file-upload"
                                        className="input-file"
                                        // onChange={(e) => setHolidayListFile(e.target.files && e.target.files[0])}
                                        onChange={(e) => {
                                            const selectedFile = e.target.files[0];
                                            const maxSizeInBytes = 5 * 1024 * 1024;

                                            if (selectedFile && selectedFile.type === 'text/csv') {
                                                if (selectedFile.size <= maxSizeInBytes) {
                                                    setHolidayListFile(e.target.files && selectedFile)
                                                }
                                                else {
                                                    setHolidayListFile(null);
                                                    alert('File size exceeds the limit of 5MB.');
                                                }
                                            } else {
                                                // Reset the file state and show an error message
                                                setHolidayListFile(null);
                                                alert('Please upload a CSV file.');
                                            }
                                        }}
                                    />
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                        <label htmlFor="file-upload" className="file-upload-label">

                                        </label>
                                        {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                        <p style={{ zIndex: 1, margin: "0px" }}>{holidayListFile ? holidayListFile.name : 'Choose a file'}</p>
                                    </div>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button
                        htmlType='submit'
                        style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                    >
                        Submit
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default HolidayForm