import { Steps } from 'antd'
import React from 'react'
import groupSetting from "../../../../assets/PerformanceManagement/Group.png";
import SelfReview from "../../../../assets/PerformanceManagement/SelfReview.png";
import PeerReview from "../../../../assets/PerformanceManagement/PeerReview.png";
import ManagerReview from "../../../../assets/PerformanceManagement/ManagerReview.png";
import Subordinates from "../../../../assets/PerformanceManagement/Subordinates.png";
import Peers from "../../../../assets/PerformanceManagement/Peers.png";
import Others from "../../../../assets/PerformanceManagement/Others.png";
import ExternalStakeholders from "../../../../assets/PerformanceManagement/ExternalStakeholders.png";

function Feedback360Dashboard() {
    return (
        <div style={{ padding: "50px" }}>
            <div style={{ background: "white", padding: "30px", borderRadius: "5px" }}>
                <p style={{ fontSize: "24px", fontWeight: "600", color: "#161616" }}>360 Feedback</p>
                <Steps
                    current={6}
                    labelPlacement="vertical"
                    items={[
                        {
                            title: 'Respondent Nomination',
                            icon: <img src={groupSetting} alt="groupSetting" width={40} height={40} />,
                        },
                        {
                            title: 'Manager Approval',
                            icon: <img src={SelfReview} alt="SelfReview" width={40} height={40} />,
                        },
                        {
                            title: 'Feedback Collection',
                            icon: <img src={PeerReview} alt="PeerReview" width={40} height={40} />,
                        },
                        {
                            title: 'Download Reports',
                            icon: <img src={ManagerReview} alt="ManagerReview" width={40} height={40} />,
                        }
                    ]}
                />
            </div>
            <div style={{ background: "white", padding: "30px", borderRadius: "5px", marginTop: "30px" }}>
                <p style={{ fontSize: "24px", fontWeight: "600", color: "#161616" }}>Summary</p>
                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>
                    Nomination : {" "}
                    <span style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}> Requested 2</span>
                </p>
            </div>

            <div style={{ background: "white", padding: "30px", borderRadius: "5px", marginTop: "30px" }}>
                <p style={{ fontSize: "24px", fontWeight: "600", color: "#161616" }}>Nominations </p>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
                    <div>
                        <center>
                            <img src={Subordinates} alt="Subordinates" width={75} height={75} />
                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>Subordinates</p>
                            <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>0 Nominations</p>
                            <button
                                style={{
                                    width: "132px",
                                    height: "40px",
                                    color: "#008080",
                                    background: "transparent",
                                    borderRadius: "5px",
                                    border: "1px solid #008080",
                                    fontWeight: "600",
                                }}
                            >Nominate</button>
                        </center>
                    </div>
                    <div>
                        <center>
                            <img src={Peers} alt="Peers" width={75} height={75} />
                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>Peers</p>
                            <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>0 Nominations</p>
                            <button
                                style={{
                                    width: "132px",
                                    height: "40px",
                                    color: "#008080",
                                    background: "transparent",
                                    borderRadius: "5px",
                                    border: "1px solid #008080",
                                    fontWeight: "600",
                                }}
                            >Nominate</button>
                        </center>
                    </div>
                    <div>
                        <center>
                            <img src={Others} alt="Others" width={75} height={75} />
                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>Others</p>
                            <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>0 Nominations</p>
                            <button
                                style={{
                                    width: "132px",
                                    height: "40px",
                                    color: "#008080",
                                    background: "transparent",
                                    borderRadius: "5px",
                                    border: "1px solid #008080",
                                    fontWeight: "600",
                                }}
                            >Nominate</button>
                        </center>
                    </div>
                    <div>
                        <center>
                            <img src={ExternalStakeholders} alt="ExternalStakeholders" width={75} height={75} />
                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>External Stakeholders</p>
                            <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>0 Nominations</p>
                            <button
                                style={{
                                    width: "132px",
                                    height: "40px",
                                    color: "#008080",
                                    background: "transparent",
                                    borderRadius: "5px",
                                    border: "1px solid #008080",
                                    fontWeight: "600",
                                }}
                            >Nominate</button>
                        </center>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feedback360Dashboard