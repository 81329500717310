import React, { useEffect, useState } from 'react'
import request from '../../../utils/axios';
import "./EmployeeSearchBar.scss"

function EmployeeSearchBar({
    setEmployeeListTable, // set table data for search employee 
    fetchTableDataFromApi, // if input field get empty then setTableData function call api and set data in table
    selectedOrganisation
}) {
    const [employeeList, setEmployeeList] = useState([])
    const handleSelectChange = async (value) => {
        try {
            const response = await request({
                method: "get",
                url: `search/employee/details/?employement_number=${value}`,
            })

            console.log("response", response);

            setEmployeeListTable([response.data])
            setEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const search = async (data) => {
        try {
            
            let response
            
            if (selectedOrganisation){
                 response = await request({
                    method: "get",
                    url: `leave/search/employees/?employee_name=${data}&&type=admin&&org=${selectedOrganisation}`,
            })
            }else{
                 response = await request({
                    method: "get",
                    url: `leave/search/employees/?employee_name=${data}&&type=admin`,
            }
            )}

            setEmployeeList(response)
            if (data === "") {
                fetchTableDataFromApi()
            }
        }
        catch (err) {
            console.log(err);
        }
    }


    return (
        <div className="search-container">
            <input
                type="text"
                placeholder="Search Employee Name"
                onChange={(e) => search(e.target.value)}
            />
            {employeeList.length > 0 && (
                <ul>
                    {employeeList.map((item, index) => (
                        <li key={index} onClick={() => handleSelectChange(item.employement_number)}>
                            {item.employee_name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default EmployeeSearchBar