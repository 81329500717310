import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Table, DatePicker, Space, Modal, Select} from 'antd';
import CalenderIcon from '../../../../../assets/tracking/calendericon.svg';
import LocationIcon from '../../../../../assets/tracking/LocationIcomn.svg';
import request from '../../../../../utils/axios';
import SearchBar from '../../../SearchBar/Search';
import {useNavigate} from 'react-router-dom';
import Add from '../../../../../assets/tracking/add.svg';
import fullView from '../../../../../assets/fullView.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import getScreenSize from '../../../../../utils/getScreenSize';
import MobileStyle from './MoileStyle.module.scss';
import TimeIcon from '../../../../../assets/tracking/time.svg';
import clientIcon from '../../../../../assets/tracking/Client.svg';
const TeamMeeting = ({changeTabsValue, tabsValue}) => {
  const {RangePicker} = DatePicker;
  const navigate = useNavigate();
  const screenSize = getScreenSize();

  const [open, setOpen] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [filterDate, setFilterDate] = useState([]);
  const [dateRange, setDateRange] = useState([]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 3, // Number of items per page
    total: 0,
  });
  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async () => {
    const apiUrl = `tracking/admin/reporting-manager/meeting-details?page=${
      pagination.current
    }&page_size=${pagination.pageSize}&from_date=${
      filterDate.length > 0 ? filterDate[0] : ''
    }&to_date=${filterDate.length > 0 ? filterDate[1] : ''}`;

    try {
      const res = await request({
        method: 'get',
        url: apiUrl,
      });
      setDataList(res.data?.pagination_data?.data);

      // console.log(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleDateChange = async value => {
    const formattedRange = value.map(dateObj => {
      return moment(dateObj.$d).format('YYYY-MM-DD');
    });
    setFilterDate(formattedRange);
    setDateRange(value);
  };
  const handleDelete = async record => {
    console.log(record);
    const apiUrl = `tracking/employee/employee-meeting/delete-meeting`;

    try {
      const res = await request({
        method: 'post',
        url: apiUrl,
        data: {
          id: record.id,
        },
      });
      LoadData();
      console.log(res);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const handleSelectChange = (value, record) => {
    if (value == 'Setting') {
      changeTabsValue('3');
    }
    if (value == 'Delete') {
      handleDelete(record);
    }
    if (value == 'Edit') {
      navigate('/tracking/editmeeting', {
        state: {MeetingType: 'TeamMeeting', record: record},
      });
    }
    console.log(`selected ${value}`);
  };
  const internalJobColumns = [
    {
      title: 'Employee name',
      dataIndex: 'employee_name',
      key: 'employee_name',
      //   render: (text, record) => (
      //     <span>{moment(text).format("DD-MM-YYYY")}</span>
      //   ),
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <span>{moment(text).format('DD-MM-YYYY')}</span>
      ),
    },
    {
      title: 'Time Duration',
      dataIndex: 'meeting_time',
      key: 'meeting_time',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      // render: (text, record) => (
      //   <div
      //     style={{
      //       display: 'flex',
      //       gap: '10px',
      //     }}>
      //     <div>{text}</div>
      //   </div>
      // ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div
          style={{
            backgroundColor: '#FFDBDB',
            color: '#FD3434',
            borderRadius: '24px',
            padding: '6px 15px',
            fontSize: '16px',
            font: '400',
            textAlign: 'center',
          }}>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      render: (text, record) => (
        <Select
          defaultValue="Select"
          style={{
            width: 180,
          }}
          onChange={value => handleSelectChange(value, record)}
          options={[
            {
              value: 'Setting',
              label: 'Setting',
            },
            {
              value: 'Delete',
              label: 'Delete',
            },
            {
              value: 'Edit',
              label: 'Edit',
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      {screenSize.width < 768 ? (
        <div className={MobileStyle.container}>
          <div className={MobileStyle.headerContainer}>
            <h2>Team Meeting</h2>
            <div className={MobileStyle.headerleftcontainer}>
              <div
                className={MobileStyle.filterdiv}
                onClick={() => {
                  setOpen(true);
                }}>
                <img src={CalenderIcon} />
                {filterDate.length > 0
                  ? `${filterDate[0]} To ${filterDate[1]}`
                  : 'Today'}
              </div>
              <img
                src={Add}
                alt="add"
                onClick={() => {
                  navigate('/tracking/addmeeting', {
                    state: {MeetingType: 'TeamMeeting'},
                  });
                }}
              />
              {tabsValue != '3' && (
                <img
                  src={fullView}
                  alt="fullviewmoile"
                  onClick={() => {
                    changeTabsValue('3');
                  }}
                  // className={MobileStyle.icon}
                />
              )}
            </div>
          </div>
          <SearchBar />
          <div className={MobileStyle.contentContainer}>
            <div
              id="scrollableDiv"
              style={{
                height: 'auto',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}>
              {dataList.map((item, index) => {
                return (
                  <div className={MobileStyle.meetingDiv} key={index}>
                    <div className={MobileStyle.top}>
                      <div className={MobileStyle.namecontainer}>
                        <div className={MobileStyle.name}>
                          {item.employee_name}
                        </div>
                        <div
                          className={MobileStyle.status}
                          style={{
                            backgroundColor:
                              item?.status == 'Attended'
                                ? '#D4EDDA'
                                : '#FFDBDB',
                            color:
                              item.status == 'Attended' ? '#28B15F' : '#FD3434',
                          }}>
                          {item.status}
                        </div>
                      </div>
                      <div className={MobileStyle.selectcontainer}>
                        <Select
                          defaultValue="select"
                          // style={{width: 100}}
                          onChange={value =>
                            handleSelectChange(
                              value,
                              item.record,
                              index,
                              'status',
                            )
                          }
                          options={[
                            {
                              value: 'Attend',
                              label: 'Attend',
                              disabled: item.isAttendDisabled, // Disable 'Attend' option if status is 'attended'
                            },
                            {
                              value: 'Delete',
                              label: 'Delete',
                            },
                            {
                              value: 'Edit',
                              label: 'Edit',
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div className={MobileStyle.bottom}>
                      <div className={MobileStyle.clientContainer}>
                        <img src={clientIcon} alt="clientIcon" />
                        <span>{item.client_name}</span>
                      </div>
                      <div className={MobileStyle.dateContainer}>
                        <img src={CalenderIcon} alt="calendericon" />
                        <span>{moment(item.date).format('DD-MM-YYYY')}</span>
                      </div>
                      <div className={MobileStyle.duration}>
                        {' '}
                        <img src={TimeIcon} alt="calendericon" />
                        <span>{item.meeting_time}</span>
                      </div>
                      <div className={MobileStyle.containerlocation}>
                        <div className={MobileStyle.leftcontainer}>
                          <img src={LocationIcon} alt="locationicon" />
                          <div className={MobileStyle.location}>
                            <div>{item.location}</div>
                            {/* <div>Gurugram, Haryana 122018</div> */}
                          </div>
                        </div>
                        <div className={MobileStyle.rightcontainer}>
                          <img src={LocationIcon} alt="locationicon" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div style={{background: 'white', padding: '30px 30px'}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              // border: "1px solid red",
              // padding: "50px",
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '24px',
              }}>
              <div style={{display: 'flex', gap: '24px', alignItems: 'center'}}>
                <div
                  style={{
                    fontSize: '24px',
                    fontWeight: '600',
                    color: '#161616',
                  }}>
                  Team Meeting
                </div>
                <div style={{width: '350px'}}>
                  <SearchBar />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '24px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    backgroundColor: '#008080',
                    color: '#fff',
                    fontSize: '16px',
                    font: 400,
                    borderRadius: '5px',
                    padding: '9px 40px',
                    display: 'flex',
                    gap: '10px',
                    justifyCointent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/tracking/addmeeting', {
                      state: {MeetingType: 'TeamMeeting'},
                    });
                  }}>
                  Add Meeting
                </div>

                <div
                  style={{
                    border: '1px solid #008080',
                    fontSize: '16px',
                    font: 400,
                    borderRadius: '5px',
                    padding: '9px 40px',
                    display: 'flex',
                    gap: '10px',
                    justifyCointent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}>
                  <img src={CalenderIcon} />
                  {filterDate.length > 0
                    ? `${filterDate[0]} To ${filterDate[1]}`
                    : 'Today'}
                </div>
                {tabsValue != '3' && (
                  <img
                    src={fullView}
                    alt="fullView"
                    width={40}
                    height={40}
                    style={{marginLeft: '20px'}}
                    onClick={() => {
                      changeTabsValue('3');
                    }}
                  />
                )}
              </div>
            </div>
            <div
              id="scrollableDiv"
              style={{
                height: 'auto',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}>
              <Table
                columns={internalJobColumns}
                dataSource={dataList}
                pagination={false}
              />
            </div>

            <Modal
              title="Select Date Range"
              centered
              open={open}
              onCancel={() => setOpen(false)}
              onOk={async () => {
                setOpen(false);
                LoadData();
              }}
              okButtonProps={{
                style: {
                  backgroundColor: '#008080',

                  color: 'white',
                  width: '100px',
                }, // Custom color for the Ok button
              }}
              cancelButtonProps={{
                style: {color: 'black'}, // Optional: Custom style for the cancel button
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <RangePicker
                  value={dateRange}
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                  style={{width: '100%'}} // Ensures the RangePicker takes up full width
                />
              </div>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamMeeting;
