import { Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import getScreenSize from "../../../../utils/getScreenSize";
import DeparttmentList from '../DepartmentList/DepartmentList';
import DesignationList from '../DesignationList/DesignationList';
import GradeList from '../GradeList/GradeList';
import WorkLocation from '../WorkLocation/WorkLocationList';
import EmployeeFunction from '../EmployeeFunction/EmployeeFunction';
import PayrollSetting from '../PayrollSetting/Tabs/Tabs';
import AttendanceSettingTabs from '../AttendanceSetting/Tabs/Tabs';
import General from '../General/General';

function Dashboard() {
  const screenSize = getScreenSize();
  const ref = useRef(null);

  const changeTabsValue = (e) => {
    console.log("e", e);
    setTabsValue(e)
  }
  const items = [
    {
      key: '1',
      label: "General",
      children: <General />
    },
    {
      key: '2',
      label: 'Payroll Setting',
      children: <PayrollSetting />,
    },
    {
      key: '3',
      label: "Attendance Setting",
      children: <AttendanceSettingTabs />
    },
    
  ];
  const [tabsValue, setTabsValue] = useState("1")

  return (
    <div ref={ref}>
      {
        screenSize.width < 768
          ?
          <>
            <Tabs
              activeKey={tabsValue}
              items={items}
              onChange={changeTabsValue}
            />
          </>

          :
          <>
            <Tabs
              activeKey={tabsValue}
              items={items}
              onChange={changeTabsValue}
            />
          </>

      }
    </div>

  )
}

export default Dashboard