import React, { useEffect, useRef, useState } from "react";

import {
  Tabs,
} from "antd";
import getScreenSize from "../../../../utils/getScreenSize";
import LeaveDashboardView from "../Dashboard/Dashboard";
import MyTeamLeaveFullView from "../MyTeam/MyTeam";
import LeaveDetailsFullView from "../LeaveDetails/LeaveDetails";
import UserHolidayFullView from "../Holiday/UserHolidayFullView";
import ApplyLeave from "../ApplyLeave/ApplyLeave";
import TeamLeaveRequests from "../TeamLeaveRequests/TeamLeaveRequests";

function Dashboard() {
  const ref = useRef(null);
  const screenSize = getScreenSize();

  const changeTabsValue = (e) => {
    setTabsValue(e)
  }

  const items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <LeaveDashboardView changeTabsValue={changeTabsValue} />,
    },
    {
      key: '2',
      label: 'My team Summary',
      children: <MyTeamLeaveFullView />,
    },
    {
      key: '3',
      label: 'My Leave Details',
      children: <LeaveDetailsFullView />,
    },
    {
      key: '4',
      label: 'Team Leave Requests',
      children: <TeamLeaveRequests />,
    }
  ];

  const mweb_items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <LeaveDashboardView changeTabsValue={changeTabsValue} />,
    },
    {
      key: '5',
      label: 'Apply Leave',
      children: <ApplyLeave changeTabsValue={changeTabsValue} />,
    },
    {
      key: '3',
      label: 'Leave Requests',
      children: <LeaveDetailsFullView />,
    },
    {
      key: '2',
      label: 'My team Summary',
      children: <MyTeamLeaveFullView />,
    },
    {
      key: '4',
      label: 'Holiday List',
      children: <UserHolidayFullView />,
    },
    {
      key: '6',
      label: 'Team Leave Requests',
      children: <TeamLeaveRequests />,
    }
  ];

  const [tabsValue, setTabsValue] = useState("1")

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="" ref={ref}>
      {screenSize.width < 768 ? (
        <>
          <Tabs
            activeKey={tabsValue}
            items={mweb_items}
            onChange={changeTabsValue}
          />
        </>
      ) : (
        <>
          <Tabs
            activeKey={tabsValue}
            items={items}
            onChange={changeTabsValue}
          />
        </>
      )}
    </div>
  );
}

export default Dashboard;