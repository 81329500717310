import { Button, Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import request from '../../../../utils/axios'

function FeedBackForm() {
    const ratingList = [
        {
            id: 1,
            value: 1
        },
        {
            id: 2,
            value: 2
        },
        {
            id: 3,
            value: 3
        },
        {
            id: 4,
            value: 4
        },
        {
            id: 5,
            value: 5
        }
    ]
    const [roundList, setRoundList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const { TextArea } = Input;
    const ref = useRef(null);

    const [rate, setRate] = useState(null)

    const onFinish = async (values) => {
        console.log("values", values, rate);
        if (rate === null) {
            toast.error("Please select rating", {
                position: "bottom-left",
            })
        }
        else {
            await request({
                method: "post",
                url: `recruitment/feedback/create/`,
                data: {
                    ...values,
                    ratings: rate
                }
            })

            // setReload(!reload)
            // setOpen(false)
            // form.resetFields();
        }
    }

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div style={{ padding: "0px 16px" }}>

            <div>
                <p style={{ fontSize: "20px", fontWeight: "600", marginTop: "80px" }} >Feedback</p>
                <p>Rate your candidate</p>
                <div style={{ display: "flex", marginTop: "20px", justifyContent: "space-between" }} >
                    {
                        ratingList.map((item) => {
                            return (
                                <div key={item.id} style={{
                                    width: "50px", height: "50px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#E8E8E8",
                                    border: rate === item.value ? "2px solid #008080" : "none", cursor: "pointer"
                                }}>
                                    <div
                                        style={{ color: "#8A8A8A" }}

                                        onClick={() => setRate(item.value)}
                                    >
                                        <span>{item.value}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ marginTop: "20px" }}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    // form={form}
                    >
                        <Row gutter={[32, 32]}>
                            <Col xs={{ span: 24 }} >
                                <Form.Item
                                    label="Round"
                                    name="round"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your round!',
                                        },
                                    ]}
                                >
                                    <Select
                                        className='dropDown'
                                        placeholder="Select Round"
                                        size='large'
                                        showSearch={false}
                                    >
                                        {
                                            roundList.map((item, index) => {
                                                return (
                                                    <Select.Option key={index} value={item}>{item}</Select.Option>
                                                )
                                            }
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} >
                                <Form.Item
                                    name="feedback"
                                    label="Comment"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your feedback!',
                                        },
                                    ]}
                                >
                                    <TextArea
                                        rows={5}
                                        placeholder="Please enter your feedback"
                                        className='textArea'
                                    />
                                </Form.Item>
                            </Col>


                        </Row>
                        <Button
                            className='button'
                            htmlType='submit'
                        >Submit</Button>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default FeedBackForm