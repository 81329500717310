import React, {useEffect, useState} from 'react';
import {DatePicker, Space} from 'antd';
import Search from '../../SearchBar/Search';
import MeetingOverview from './MeetingOverview';
import moment from 'moment';
import dayjs from 'dayjs';
import {Table} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import request from '../../../../utils/axios';
const Summary = () => {
  const [searchValue, setSearchValue] = useState('');
  const [month, setMonth] = useState(moment(new Date()).format('YYYY-MM'));
  const [dataList, setDataList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Number of items per page
    total: 0,
  });

  // hande month chnage Function
  const onChange = (date, dateString) => {
    console.log(dateString);
    setMonth(dateString);
    const pagination = {
      current: 1,
      pageSize: 10, // Number of items per page
      total: 0,
    };
    const dataList = [];
    LoadData(pagination, dataList, dateString);
  };

  const LoadData = async (newpagination, list, dateString) => {
    const {current, pageSize} = newpagination || pagination;
    const MonthPayload = dateString?.split('-') || month.split('-');
    const apiUrl = `tracking/admin/employee-meeting/details/all?page=${current}&page_size=${pageSize}&month=${MonthPayload[1]}&year=${MonthPayload[0]}`;
    try {
      const res = await request({
        method: 'get',
        url: apiUrl,
      });
      if (list) {
        setDataList([...list, ...res.data?.pagination_data?.data]);
      } else {
        setDataList([...dataList, ...res.data?.pagination_data?.data]);
      }

      setPagination({
        ...pagination,
        current: pagination.current + 1,
        total: res?.data.pagination_data?.total,
      });

      if (res?.data?.pagination_data?.next === null) {
        setHasMore(false);
        return;
      }
      // console.log(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const internalJobColumns = [
    {
      title: 'Employee name',
      dataIndex: 'employee_name',
      key: 'employee_name',
      width: 200,
      //   render: (text, record) => (
      //     <span>{moment(text).format("DD-MM-YYYY")}</span>
      //   ),
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      width: 200,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 200,
      render: (text, record) => (
        <span>{moment(text).format('DD-MM-YYYY')}</span>
      ),
    },
    {
      title: 'Time Duration',
      dataIndex: 'meeting_time',
      key: 'meeting_time',
      width: 250,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div
          style={{
            backgroundColor: text == 'Attended' ? '#D4EDDA' : '#FFDBDB',
            color: text == 'Attended' ? '#28B15F' : '#FD3434',
            borderRadius: '24px',
            padding: '6px 15px',
            fontSize: '16px',
            font: '400',
            textAlign: 'center',
          }}>
          {text}
        </div>
      ),
    },
  ];

  useEffect(() => {
    LoadData();
  }, []);
  return (
    <div style={{background: 'white', padding: '30px 30px'}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '24px',
        }}>
        <div style={{display: 'flex', alignItems: 'center', gap: '24px'}}>
          <div style={{fontSize: '24px', fontWeight: '500', color: '#161616'}}>
            Meeting Summary
          </div>
          <div>
            <div style={{width: '350px'}}>
              <Search />
            </div>
          </div>
        </div>
        <DatePicker
          onChange={onChange}
          // onSelect={handleSelectMonth}
          picker="month"
          defaultValue={dayjs()}
        />
      </div>
      <MeetingOverview month={month} />
      {/* meeting_table */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '32px',
          // border: "1px solid red",
          // padding: "50px",
        }}>
        <div
          id="scrollableDiv"
          style={{
            height: 'auto',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
          }}>
          <InfiniteScroll
            dataLength={
              pagination.total - dataList?.length * pagination.current
            }
            next={LoadData}
            hasMore={hasMore}
            endMessage={
              <p style={{textAlign: 'center'}}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            loader={
              <p style={{textAlign: 'center'}}>
                <b>Loading...</b>
              </p>
            }
            scrollableTarget="scrollableDiv"
            height="70vh">
            <Table
              columns={internalJobColumns}
              dataSource={dataList}
              pagination={false}
            />
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default Summary;
