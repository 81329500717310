import { createSlice } from '@reduxjs/toolkit'

const initialState = ""
const companyNameSlice = createSlice({
    name: 'companyName',
    initialState,
    reducers: {
        setCompanyName: (state, action) => {
            console.log(action.payload)
            return action.payload
        },
    },
})
export const { setCompanyName } = companyNameSlice.actions;

export default companyNameSlice.reducer;