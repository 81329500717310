import React, { useEffect, useRef, useState } from 'react'
import { Button, Radio, Table } from "antd"
import "./DashboardView.scss"

import fullView from "../../../../assets/fullView.svg";
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination';
import request from '../../../../utils/axios';
// import sheduling from "../../../assets/Recruitment/sheduling.svg";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ApplyJob from '../ApplyJob/ApplyJob';
// import useScreenSize from '../../../utils/getScreenSize';
import calendar from "../../../../assets/calendar.svg";
import location from "../../../../assets/location.svg";
import ApplyReferCardList from '../../../../component/ApplyReferCardList/ApplyReferCardList';
import AddRequisition from '../AddRequisition/AddRequisition';
import getScreenSize from "../../../../utils/getScreenSize";
import mobileStyle from "./DashboardView.module.scss";
import dayjs from 'dayjs';
import time from "../../../../assets/time.svg"
import rupee from "../../../../assets/activePayrollImg.svg"
import workMode from "../../../../assets/workMode.png"
import add from "../../../../assets/Org/add.svg"
function RecruitmentDashboardView({ changeTabsValue }) {
  const screenSize = getScreenSize();
  const ref = useRef(null);
  const navigate = useNavigate();

  const referralColumns = [
    {
      title: 'Date',
      dataIndex: 'applied_on',
      key: 'applied_on',
      render: (text, record) => <span>
        {
          moment(text).format('DD-MM-YYYY')
        }
      </span>
    },
    {
      title: 'Candidate Name',
      dataIndex: 'candidate_name',
      key: 'candidate_name',
    },
    {
      title: 'Job Title',
      dataIndex: 'job',
      key: 'job',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record) =>
        <div>
          {
            record?.location?.map((item, index) => {
              return (
                <span key={index}>
                  <span>{item}</span>
                  {
                    record?.location?.length - 1 !== index && <span> , </span>
                  }
                </span>

              )
            })
          }
        </div>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <button className={`${text}StatusButton`}>{text}</button>
      ),
    }
  ]

  const openingColumns = [
    {
      title: 'Date',
      dataIndex: 'posting_date',
      key: 'posting_date',
      render: (text, record) => <span>
        {
          moment(text).format('DD-MM-YYYY')
        }
      </span>
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title',
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record) =>
        <div>
          {
            record?.location?.map((item, index) => {
              return (
                <span key={index}>
                  <span>{item}</span>
                  {
                    record?.location?.length - 1 !== index && <span> , </span>
                  }
                </span>

              )
            })
          }
        </div>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <ApplyJob title={"Refer a Candidate"} jobId={record.id} btnContent="Refer" loginUserData="" />
    }
  ]

  const schedulingColumns = [
    {
      title: 'Date',
      dataIndex: 'interview_date',
      key: 'interview_date',
    },
    {
      title: 'Candidate Name',
      dataIndex: 'candidate',
      key: 'candidate',
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title',
    },

    // {
    //   title: 'Scheduled By',
    //   dataIndex: 'scheduled_by',
    //   key: 'scheduled_by',
    // },
    {
      title: 'Timing',
      dataIndex: 'interview_time',
      key: 'interview_time',
    }
  ]

  const internalJobColumns = [
    {
      title: 'Date',
      dataIndex: 'posting_date',
      key: 'posting_date',
      render: (text, record) => <span>
        {
          moment(text).format('DD-MM-YYYY')
        }
      </span>
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title',
    },
    {
      title: 'Posted By',
      dataIndex: 'posted_by',
      key: 'posted_by',
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: 'Funtion',
      dataIndex: 'job_function',
      key: 'job_function',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record) =>
        <div>
          {
            record?.location?.map((item, index) => {
              return (
                <span key={index}>
                  <span>{item}</span>
                  {
                    record?.location?.length - 1 !== index && <span> , </span>
                  }
                </span>

              )
            })
          }
        </div>
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => <ApplyJob title={"Apply"} jobId={record.id} btnContent="Apply" loginUserData={loginUserDetails} />
    }
  ]

  const requisitionColumns = [
    {
      title: 'Job Ttile',
      dataIndex: 'job_title',
      key: 'job_title',
    },
    {
      title: 'Salary Range',
      dataIndex: 'salary_range',
      key: 'salary_range',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record) =>
        <div>
          {
            record?.location?.map((item, index) => {
              return (
                <span key={index}>
                  <span>{item}</span>
                  {
                    record?.location?.length - 1 !== index && <span> , </span>
                  }
                </span>

              )
            })
          }
        </div>
    },
    {
      title: 'Function',
      dataIndex: 'job_function',
      key: 'job_function',
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <button className={`${text}StatusButton`}>{text}</button>
      ),
    }
  ]

  const [reload, setReload] = useState(false)

  const [view, setView] = useState("opening");
  const [internalJobOpening, setInternalJobOpening] = useState([])
  const fetchInternalJobOpening = async (page, page_size) => {

    const data = await request({
      method: "get",
      url: `recruitment/active-jobs?page=${page}&page_size=${page_size}`,
    })

    console.log("internal", data.pagination_data.data)
    setInternalJobOpening([...data.pagination_data.data])

  }

  const [openingList, setOpeningList] = useState([])
  const fetchRowOpeningData = async (page, page_size) => {

    const data = await request({
      method: "get",
      url: `recruitment/referral-jobs?page=${page}&page_size=${page_size}`,
    })
    setOpeningList([...data.pagination_data.data])
    return data
  }

  const [interviewScheduleList, setInterviewScheduleList] = useState([])
  const fetchRowSchedulingData = async (page, page_size) => {

    const data = await request({
      method: "get",
      url: `recruitment/interviews/all?page=${page}&page_size=${page_size}`,
    })
    setInterviewScheduleList([...data.pagination_data.data])
    // return data
  }

  const [refferalList, setRefferalList] = useState([])

  const fetchRowMyReferralData = async (page, page_size) => {

    const data = await request({
      method: "get",
      url: `recruitment/employee/my-referrals?page=${page}&page_size=${page_size}`,
    })

    setRefferalList([...data.pagination_data.data])
    // return data
  }

  const [requisitionList, setRequisitionList] = useState([])
  const fetchRequisitionData = async (page, page_size) => {

    const data = await request({
      method: "get",
      url: `recruitment/employee/requisitions/all?page=${page}&page_size=${page_size}`,
    })
    setRequisitionList([...data.pagination_data.data])
    return data
  }

  const [loginUserDetails, setLoginUserDetails] = useState({
    candidate_name: "",
    candidate_email: "",
    candidate_contact: "",
    current_company: "",
    candidate_resume: "",
    linkedin_url: ""
  });
  const fetchUserData = async () => {
    const employee_number = localStorage.getItem("employee_number");

    let data = await request({
      method: "get",
      url: `profile/details/`,
    })

    setLoginUserDetails({
      ...loginUserDetails,
      candidate_name: data.data.data.employee_name,
      candidate_email: data.data.data.official_email,
      candidate_contact: data.data.data.contact,
      current_company: data.data.data.current_company,

    })
  }

  const [data, setData] = useState()
  const fetchDataForMobileView = async (page, page_size) => {

    if (view === "referral") {
      const data = await fetchRowMyReferralData(page, page_size);

    } else {
      const data = await await fetchRowOpeningData(page, page_size)
      setData(...data.pagination_data.data)
    }
  }

  useEffect(() => {


    fetchDataForMobileView(1, 4)
    fetchInternalJobOpening(1, 4);
    fetchRowSchedulingData(1, 4)
    fetchRequisitionData(1, 4)
  }, [])

  useEffect(() => {
    fetchDataForMobileView(1, 4)
  }, [view])



  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    fetchUserData();
  }, [])

  return (
    <div ref={ref}>
      {
        screenSize.width < 768
          ?
          <div>
            {/* Referrals */}
            <div>
              <div className={mobileStyle.refferalHeadingContainer}>
                <div className={mobileStyle.refferalHeadingWrapper}>
                  <p className={mobileStyle.refferalHeading}>Referrals</p>
                  <img src={fullView} alt="fullView"
                    onClick={() => changeTabsValue("2")}
                  />
                </div>
                <center>
                  <Radio.Group defaultValue={view} buttonStyle="solid"
                    size='large'
                    onChange={e => setView(e.target.value)}
                    style={{
                      width: "100%"
                    }}
                  >
                    <Radio.Button value="opening"
                      style={{
                        width: "50%"
                      }}>Openings</Radio.Button>
                    <Radio.Button value="referral" style={{
                      width: "50%"
                    }}>My Refers</Radio.Button>
                  </Radio.Group>
                </center>
              </div>
              {
                view === "referral"
                  ?
                  <div>
                    {
                      refferalList?.map((item, index) => {
                        return (
                          <>
                            <div key={index} className={mobileStyle.refferalContainer}>

                              <p className={mobileStyle.refferalName}>
                                {item?.candidate_name}
                                <span
                                  className={mobileStyle.refferalStatus}
                                  style={{
                                    color: item?.status === ("Shortlisted" || "Selected") ? "green" : item?.status === "Interview" ? "purple" : item.status === "Rejected" ? "red" : "gray",
                                    border: item?.status === ("Shortlisted" || "Selected") ? "1px solid green" : item.status === "Interview" ? "1px solid purple" : item.status === "Rejected" ? "1px solid red" : "1px solid gray"
                                  }}
                                >{item.status}</span>
                              </p>
                              <p className={mobileStyle.refferalJob}>{item.job}</p>
                              <p className={mobileStyle.refferalDate}>
                                <img src={calendar} alt="calendar" width={20} height={20} />{" "}
                                {dayjs(item.applied_on).format("DD-MM-YYYY")}
                              </p>
                              <p className={mobileStyle.refferalLocation}>
                                <img src={location} alt="location" width={20} height={20} />{" "}
                                {item?.location[0]}
                              </p>

                            </div>
                            {
                              index !== refferalList.length - 1 && <hr />
                            }
                          </>
                        )
                      })
                    }
                  </div>
                  :
                  <div>
                    {
                      openingList?.map((item, index) => {
                        return (
                          <>
                            <div key={index} className={mobileStyle.openingContainer}>

                              <div>
                                <p className={mobileStyle.jobTitle}>
                                  {item.job_title}
                                </p>
                                <p className={mobileStyle.industry}>{item.industry}</p>
                                <p className={mobileStyle.date}>
                                  <img src={calendar} alt="calendar" width={20} height={20} />{" "}
                                  {dayjs(item.posting_date).format("DD-MM-YYYY")}
                                </p>
                                <p className={mobileStyle.location}>
                                  <img src={location} alt="location" width={20} height={20} />{" "}
                                  {item?.location[0]}
                                </p>
                              </div>
                              <button className={mobileStyle.applyBtn}
                                onClick={() => navigate(`/recruitment/refer-form/${item.id}`, { replace: true })}
                              >Refer</button>
                            </div>
                            {
                              index !== openingList.length - 1 && <hr />
                            }
                          </>
                        )
                      })
                    }
                  </div>
              }

            </div>

            {/* divider */}
            <div
              style={{
                height: "8px",
                backgroundColor: "#F7F8FC",
                marginTop: "10px",
              }}
            >
            </div>

            {/* Interview Schedule */}
            {
              interviewScheduleList.length > 0
              &&

              <div className={mobileStyle.interviewSchedule}>
                <div className={mobileStyle.headerContainer}>
                  <p className={mobileStyle.heading}>Interview Schedule</p>
                  <img src={fullView} alt="fullView"
                    onClick={() => changeTabsValue("5")}
                  />
                </div>

                {
                  interviewScheduleList?.map((item, index) => {
                    return (
                      <>
                        <div key={index} className={mobileStyle.interviewContainer}>

                          <div>
                            <p className={mobileStyle.candidate}>
                              {item.candidate}
                            </p>
                            <p className={mobileStyle.jobTitle}>{item.job_title}</p>
                            <p className={mobileStyle.date}>
                              <img src={calendar} alt="calendar" width={20} height={20} />{" "}
                              {dayjs(item.interview_date).format("DD-MM-YYYY")}
                            </p>
                            <p className={mobileStyle.time}>
                              <img src={time} alt="time" width={20} height={20} />{" "}
                              {item?.interview_start_time}
                            </p>
                          </div>
                          <button className={mobileStyle.feedbackBtn}
                            onClick={() => navigate(`/recruitment/interview-schedule/feedback`, { replace: true })}
                          >Feedback</button>
                        </div>
                        {
                          index !== interviewScheduleList.length - 1 && <hr />
                        }
                      </>
                    )
                  })
                }
              </div>
            }


            {/* divider */}
            <div
              style={{
                height: "8px",
                backgroundColor: "#F7F8FC",
                marginTop: "10px",
              }}
            >
            </div>

            {/* Internal Job Openings */}
            <div className={mobileStyle.internalJobOpening}>
              <div className={mobileStyle.headerContainer}>
                <p className={mobileStyle.heading}>Internal Job Openings</p>
                <img src={fullView} alt="fullView"
                  onClick={() => changeTabsValue("4")}
                />
              </div>

              {
                internalJobOpening?.map((item, index) => {
                  return (
                    <>
                      <div key={index} className={mobileStyle.internalJobOpeningContainer}>

                        <div>
                          <p className={mobileStyle.candidate}>
                            {item.job_title}
                          </p>
                          <p className={mobileStyle.jobTitle}>{item.industry}</p>
                          <p className={mobileStyle.date}>
                            <img src={calendar} alt="calendar" width={20} height={20} />{" "}
                            {dayjs(item.posting_date).format("DD-MM-YYYY")}
                          </p>
                          <p className={mobileStyle.time}>
                            <img src={time} alt="time" width={20} height={20} />{" "}
                            {item?.job_function}
                          </p>
                          <p className={mobileStyle.location}>
                            <img src={location} alt="location" width={20} height={20} />{" "}
                            {item?.location[0]}
                          </p>
                        </div>
                        <button className={mobileStyle.applyBtn}
                          onClick={() => { navigate(`/recruitment/apply-form/${item.id}`, { replace: true }) }}
                        >Apply</button>
                      </div>
                      {
                        index !== internalJobOpening.length - 1 && <hr />
                      }
                    </>
                  )
                })
              }
            </div>

            {/* divider */}
            <div
              style={{
                height: "8px",
                backgroundColor: "#F7F8FC",
                marginTop: "10px",
              }}
            >
            </div>

            {/* Requisition */}
            <div className={mobileStyle.internalJobOpening}>
              <div className={mobileStyle.headerContainer}>
                <p className={mobileStyle.heading}>Requisition</p>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  gap: "10px",
                }}>
                  <img src={add} alt="add" width={32} height={32}
                    onClick={() => navigate("/recruitment/requisition-form", { replace: true })}
                  />
                  <img src={fullView} alt="fullView" onClick={() => changeTabsValue("3")} />
                </div>
              </div>

              {
                requisitionList?.map((item, index) => {
                  return (
                    <>
                      <div key={index} className={mobileStyle.internalJobOpeningContainer}>

                        <div>
                          <p className={mobileStyle.candidate}>
                            {item.raised_by}
                          </p>
                          <p className={mobileStyle.jobTitle}>{item.industry}</p>
                          <p className={mobileStyle.date}>
                            <img src={rupee} alt="rupee" width={20} height={20} />{" "}
                            {item.salary_range}
                          </p>
                          <p className={mobileStyle.time}>
                            <img src={workMode} alt="workMode" width={20} height={20} />{" "}
                            {item?.job_function}
                          </p>
                          <p className={mobileStyle.location}>
                            <img src={location} alt="location" width={20} height={20} />{" "}
                            {
                              item?.location?.map((el, elIndex) => {
                                return <span key={elIndex}>{el}
                                  {
                                    item?.location?.length - 1 !== elIndex && <span> , </span>
                                  }
                                </span>
                              })
                            }
                          </p>
                        </div>
                      </div>
                      {
                        index !== requisitionList.length - 1 && <hr />
                      }
                    </>
                  )
                })
              }
            </div>

          </div>
          :
          <div className='recruitmentContainer' >

            {/* <h1 className='heading'>Recruitment</h1> */}

            <div className='firstContainer'>
              <div className='refferalContainer'>
                <div className='flexSpaceBetween'>
                  <p className='refferalHeading'>Referrals</p>
                  <img src={fullView} alt="fullView" onClick={() => changeTabsValue("2")}
                  // 
                  />
                </div>


                <Button
                  className={view === "opening" ? "Btn openingBtn active" : "Btn"}
                  onClick={() => setView("opening")}>
                  Openings
                </Button>

                <Button
                  className={view === "referral" ? "Btn active" : "Btn"}
                  onClick={() => setView("referral")}>
                  My Refers
                </Button>

                <div style={{ marginTop: "2vh" }}>
                  {
                    view === "referral"
                    &&
                    // <DynamicTable
                    //   fetchData={fetchRowMyReferralData}
                    //   columns={referralColumns}
                    //   handleRowClick={() => { }}
                    //   pageSize={3}
                    //   showPagination={false}
                    // />
                    <Table
                      columns={referralColumns}
                      dataSource={refferalList}
                      pagination={false}
                    />

                  }
                  {
                    view === "opening" &&
                    <>
                      {screenSize.width < 768

                        ?
                        <ApplyReferCardList
                          index={1}
                          item={data}
                          btnContent={"Refer"}
                        />
                        :
                        <Table
                          columns={openingColumns}
                          dataSource={openingList}
                          pagination={false}
                        />
                      }
                    </>
                  }
                </div>
              </div>

              <div className='shedulingContainer'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <p className='shedulingHeading' style={{ margin: "0px" }}>Interview Scheduling</p>
                  <img src={fullView} alt="fullView" onClick={() => changeTabsValue("5")} />
                </div>
                <div>
                  <Table
                    columns={schedulingColumns}
                    dataSource={interviewScheduleList}
                    pagination={false}
                  />
                </div>
              </div>
            </div>

            <div className='flexCenter'>
              <div className='internalJobOpeningContainer'>
                <div className='flexSpaceBetween'>
                  <p className='internalJobOpeningHeading'>Internal Job Openings</p>
                  <img src={fullView} alt="fullView" onClick={() => changeTabsValue("4")} />
                </div>
                <div style={{ marginTop: "2vh" }}>

                  <Table
                    columns={internalJobColumns}
                    dataSource={internalJobOpening}
                    pagination={false}
                  />

                </div>
              </div>
            </div>

            <div className='flexCenter'>
              <div className='internalJobOpeningContainer'>
                <div className='flexSpaceBetween'>
                  <p className='internalJobOpeningHeading'>Requisition</p>
                  <div style={{ display: "flex", gap: "40px" }}>
                    <AddRequisition
                      reload={reload}
                      setReload={setReload}
                    />
                    <img src={fullView} alt="fullView" onClick={() => changeTabsValue("3")} />
                  </div>
                </div>
                <div style={{ marginTop: "2vh" }}>

                  <Table
                    columns={requisitionColumns}
                    dataSource={requisitionList}
                    pagination={false}
                  />
                </div>
              </div>
            </div>

          </div>
      }
    </div>

  )
}

export default RecruitmentDashboardView