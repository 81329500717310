import { Popover, Button, Col, Form, Input, Modal, Row, Select, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import dayjs from "dayjs";
import getScreenSize from "../../../../utils/getScreenSize";
import moment from "moment";
import "./EmployeeLeavesFullView.scss";
import request from "../../../../utils/axios";
import { InfoCircleOutlined, DownOutlined } from "@ant-design/icons";
import back from "../../../../assets/Recruitment/back.svg"
import { useNavigate } from "react-router-dom";
import InfiniteScrollComponent from "../../../../component/InfiniteScroll/InfiniteScroll";
import CommonTable from "../../../../component/CommonTable/CommonTable";
import view from "../../../../assets/view.png";
import desktopStyle from "../../../PayRoll/Admin/ReimbursementFullView/ReimbursementFullView.module.scss";
import { saveAs } from 'file-saver';
import activeDocumentImg from "../../../../assets/activeDocumentImg.svg";
import activeDownload from "../../../../assets/activeDownload.png";


function EmployeeLeavesFullView() {
  const screenSize = getScreenSize();
  const [employeeLeaveDetailList, setEmployeeLeaveDetailList] = useState([]);
  const navigate = useNavigate();
  let status = "Approved";
  let textColor = "red";
  let leaveDetail = [
    {
      date: "2022-01-01",
      type: "full_day",
    },
    {
      date: "2022-01-02",
      type: "full_day",
    },
    {
      date: "2022-01-03",
      type: "full_day",
    },
  ];
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 7, // Number of items per page
    total: 0,
  })

  const employeeLeaveColumn = [
    {
      title: "Name",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "No. Of Leaves",
      dataIndex: "leave_day",
      key: "leave_day",
      render: (text, record) => (
        <span>
          {text}
          <Popover
            placement="top"
            title="Leaves"
            content={employeeLeavePopUp(record)}
          >
            <InfoCircleOutlined />
          </Popover>
        </span>
      ),
    },
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      key: "leave_type",
    },
    {
      title: "Leave From",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Leave To",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <>
          <button className={`${text}LeaveButton`}>{text}</button>
          
          <img src={view} alt="view" width={18} height={18} style={{ marginLeft: "15px" }}
          onClick={() => {
              setDetailsModel(true)
              setSelectedDetailsData(record)
          }}
          />
        </>
              
      ),
    },
  ];

  const [detailsModel, setDetailsModel] = useState(false);
  const [selectedDetailsData, setSelectedDetailsData] = useState([]);

  const employeeLeavePopUp = (record) => {
    return (
      <div>
        <div style={{ display: "flex", gap: "10px" }}>
          {record?.leave_dates?.map((item, index) => {
            return (
              <>
                <span>
                  {item.leave_for === "full_day" ? (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "white",
                        padding: "3px",
                        borderRadius: "50%",
                        background: "#008080",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                      }}
                    >
                      {dayjs(item.date).format("DD")}
                    </div>
                  ) : record.from_second_half_start_date ? (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "black",
                        padding: "3px",
                        borderRadius: "50%",
                        background:
                          "linear-gradient(to left, #008080, #008080 50%, #DDDDDD 50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                      }}
                    >
                      {dayjs(item.date).format("DD")}
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "black",
                        padding: "3px",
                        borderRadius: "50%",
                        background:
                          "linear-gradient(to right, #008080, #008080 50%, #DDDDDD 50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                      }}
                    >
                      {dayjs(item.date).format("DD")}
                    </div>
                  )}
                </span>
              </>
            );
          })}
        </div>
      </div>
    );
  };
  const fetchEmployeeLeaveDetail = async () => {
    try {
      const response = await request({
        method: "get",
        url: `leave/employees/leave-application/all/details/?page=${pagination.current}&page_size=${pagination.pageSize}`,
      });
      console.log("aaaaaaaaaaaaaaaaaa", response.pagination_data.data);
      setEmployeeLeaveDetailList(response.pagination_data.data);
      setPagination({
        ...pagination,
        total: response?.pagination_data?.total
      })
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (screenSize.width > 768) {
      fetchEmployeeLeaveDetail();
    }
  }, [pagination.current]);

  const renderUI = (dataList) => {
    return dataList.map((item, index) => {
      return (
        <>
          <div style={{ padding: "0px 16px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <p className="name">{item.employee_name}</p>
              <div
                className="pendingContainer"
                style={{
                  borderColor: item?.is_approved ? "#28B15F" : "red",
                }}
              >
                <p
                  className="status"
                  style={{
                    color: item?.is_approved ? "#28B15F" : "red",
                  }}
                >
                  {item?.is_pending && "Pending"}
                  {item?.is_approved || item?.is_predefined_leaves
                    ? "Approved"
                    : ""}

                  {item?.is_rejected && "Rejected"}
                </p>
              </div>
            </div>

            <p className="leaveText">{item.leave_type}</p>

            <div>
              <p className="date">
                {moment(item.start_date).format("DD MMM YYYY")} To{" "}
                {moment(item.end_date).format("DD MMM YYYY")}
              </p>
            </div>
            <div className="leavesText">
              {item.leave_day} leaves
              {" "}
              <Popover
                placement="topLeft"
                title="Leaves"
                content={employeeLeavePopUp(item)}
              >
                <DownOutlined />
              </Popover>
            </div>
          </div>

          {index !== employeeLeaveDetailList?.length - 1 && (
            <hr className="divider" />
          )}
        </>
      );
    })
  }

  return (
    <div className="employeeLeavesFullViewContainer">
      {screenSize.width < 768 ? (
        <div
          style={{ background: "white" }}
        >
          {
            <>
              <div style={{ padding: "0px 16px" }}>
                <p className="employeeText" style={{ margin: "0px" }}>Employee Leaves</p>
              </div>


              <InfiniteScrollComponent
                apiEndpoint="leave/employees/leave-application/all/details/"
                renderUI={renderUI}
                initialPage={1}
              />
            </>
          }
        </div>
      ) : (
        <div style={{ marginBottom: "40px", background: "white", padding: "30px", borderRadius: "5px" }}>
          <div className="leavePolicyContainer">
            <div>
              {/* <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                <img src={back} alt="back" />
                <span style={{ paddingLeft: "10px" }}>Back</span>
              </p> */}
              {/* <p className="heading">Leave</p> */}
              <div className="flexSpaceBetween">
                <p className="employeeLeavesHeading">Employee Leaves </p>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                ></div>
              </div>
              {/* <Table
                columns={employeeLeaveColumn}
                dataSource={employeeLeaveDetailList}
                pagination={false}
              /> */}
              <CommonTable
                columns={employeeLeaveColumn}
                data={employeeLeaveDetailList}
                pagination={pagination}
                setPagination={setPagination}
                handleRowClick={() => { }}
              />
            </div>
          </div>
        </div>
      )}

            <Modal
                centered
                open={detailsModel}
                onOk={() => setDetailsModel(false)}
                onCancel={() => setDetailsModel(false)}
                width={1000}
                footer={null}
            >
                <p className={desktopStyle.reimbursementModelTitle}>Employee Leaves Detail</p>
                <Row gutter={[32, 12]}>
                    <Col span={11}>
                        <div className={desktopStyle.reimbursementModelKeyValueContainer}>
                            <p className={desktopStyle.reimbursementModelKey}>Name</p>
                            <p className={desktopStyle.reimbursementModelValue}>{selectedDetailsData?.employee_name}</p>
                        </div>
                        <div className={desktopStyle.reimbursementModelKeyValueContainer}>
                            <p className={desktopStyle.reimbursementModelKey}>Leave From</p>
                            <p className={desktopStyle.reimbursementModelValue}>{selectedDetailsData?.start_date}</p>
                        </div>
                        <div className={desktopStyle.reimbursementModelKeyValueContainer}>
                            <p className={desktopStyle.reimbursementModelKey}>Leave To</p>
                            <p className={desktopStyle.reimbursementModelValue}>{selectedDetailsData?.end_date}</p>
                        </div>
                        <div className={desktopStyle.reimbursementModelKeyValueContainer}>
                            <p className={desktopStyle.reimbursementModelKey}>No of Leaves</p>
                            <p className={desktopStyle.reimbursementModelValue}>
                              {selectedDetailsData?.leave_day}           
                              <Popover
                                placement="top"
                                title="Leaves"
                                content={employeeLeavePopUp(selectedDetailsData)}
                              >
                                <InfoCircleOutlined />
                              </Popover>
                              </p>
                        </div>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                        <div className={desktopStyle.reimbursementModelKeyValueContainer}>
                            <p className={desktopStyle.reimbursementModelKey}>Status</p>                            
                            <button className={`${selectedDetailsData?.status}LeaveButton`}>{selectedDetailsData?.status}</button>
                        </div>
                    </Col>
                    <Col span={24}>
                        <p className={desktopStyle.reimbursementModelKey}>Description</p>
                        <div style={{ border: "1px solid #DDDDDD", background: "#F8F8F8", padding: "10px" }}>
                            <p className={desktopStyle.reimbursementModelValue}>{selectedDetailsData?.reason}</p>
                        </div>
                    </Col>
                    {
                        selectedDetailsData?.document_details?.length > 0 &&
                        <Col span={24}>
                            <p className={desktopStyle.reimbursementModelKey}>Download documents</p>


                            {
                                selectedDetailsData?.document_details?.map((item, index) => {
                                    return (
                                        <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "space-between", padding: "10px", background: "#F8F8F8", width: "50%", marginBottom: "10px" }}>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <img src={activeDocumentImg} alt="activeDocumentImg" />
                                                <p style={{ margin: "0px", color: "#505050", fontWeight: "600", fontSize: "16px" }}>{item?.file_name}</p>
                                            </div>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <img src={view} alt="view" width={18} height={18}
                                                    onClick={() => {
                                                        window.open(item?.url)
                                                    }}
                                                />
                                                {/* download  pdf*/}

                                                <a href={item.url} target="_blank" download>
                                                    <img src={activeDownload} alt="activeDownload" width={18} height={18}
                                                        onClick={async () => {

                                                            try {
                                                                const response = await fetch(item.url);
                                                                const blob = await response.blob();
                                                                saveAs(blob, `${item.file_name}.pdf`);
                                                            } catch (error) {
                                                                console.error('Error downloading PDF:', error);
                                                            }
                                                        }}
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Col>
                    }
                </Row>
            </Modal>

    </div>
  );
}

export default EmployeeLeavesFullView;
