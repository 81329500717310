import { DatePicker, Form, Input, Modal, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import view from "../../../../assets/view.png";
import desktopStyle from "./Posts.module.scss";
import getScreenSize from "../../../../utils/getScreenSize";
import mobileStyle from "./MobilePost.module.scss";
import dayjs from 'dayjs';
import request from '../../../../utils/axios';
import like from "../../../../assets/Feeds/Admin/like.png";
import comment from "../../../../assets/Feeds/Admin/message.png";
import { useNavigate } from 'react-router-dom';

function Posts() {
    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const { TextArea } = Input;
    const [addPostModal, setAddPostModal] = useState(false)
    const [postDetails, setPostDetails] = useState({
        visible_to_departments: "",
        choseTitle: "",
        text: "",
        uploadType: "",
        image: "",
    })

    const postColumns = [
        {
            title: 'Post Title',
            dataIndex: 'post_type',
            key: 'post_type',
        },
        {
            title: 'Reaction',
            dataIndex: 'reaction',
            key: 'reaction',
            render: (text, record) => <div>
                <img src={like} alt="like" width={50} />
                <span style={{ fontWeight: "600", marginLeft: "5px" }}>{record.total_reaction}</span>
                <img src={comment} alt="comment" width={20} style={{ marginLeft: "15px" }} />
                <span style={{ fontWeight: "600", marginLeft: "5px" }}>{record.total_comments}</span>
            </div>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => <img src={view} alt="view" width={25}
                onClick={() => {
                    navigate(`/admin/feeds/display-post/${record.id}`)
                    // admin/feeds/display-post/d
                }}
            />
        }
    ]

    const [postDateFilter, setPostDateFilter] = useState({
        month: dayjs(new Date()).format("MM"),
        year: dayjs(new Date()).format("YYYY"),
    })

    const onPostDateChange = (date, dateString) => {
        setPostDateFilter({
            ...postDateFilter,
            month: dayjs(date).format("MM"),
            year: dayjs(date).format("YYYY"),
        })
    };

    const [postList, setPostList] = useState([])
    const fetchPostList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `feeds/admin/posts/all?month=${postDateFilter.month}&year=${postDateFilter.year}`,
            })
            setPostList(response.data)
        }
        catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        fetchPostList();
    }, [postDateFilter])

    const [postType, setPostType] = useState([])
    const fetchPostVisibleList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `feeds/admin/post-type/dropdown`,
            })
            setPostType(response.data)
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const [departmentList, setDepartmentList] = useState([])

    const fetchDepartmentList = async () => {
        try {
            const res = await request(
                {
                    method: "get",
                    url: `feeds/admin/departments/all`,
                }
            );

            setDepartmentList(res.data)
        }
        catch (error) {
            console.log("error ", error)
        }
    }

    useEffect(() => {
        fetchPostVisibleList();
        fetchDepartmentList();
    }, [])

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            formData.append("visible_to_departments", postDetails.visible_to_departments);
            formData.append("post_type", postDetails.post_type);
            formData.append("text", postDetails.text);
            formData.append("image", postDetails.image);
            formData.append("added_by_admin", true);

            const response = await request({
                method: "post",
                url: `feeds/admin/feeds/add`,
                data: formData
            })
            console.log("response", response.data);
            setAddPostModal(false)
        }
        catch (error) {
            console.log("error", error)
        }
    }

    return (
        <div>
            {
                screenSize.width < 768 ?
                    // mobile
                    <>
                        <div className={mobileStyle.postContainer}>
                            <div className={mobileStyle.flexSpaceBetween} style={{ marginTop: "15px", padding: "0px 16px" }}>
                                <p className={mobileStyle.heading}>Posts</p>

                                <DatePicker
                                    placeholder="Select Date"
                                    size='medium'
                                    style={{ width: "150px" }}
                                    onChange={onPostDateChange}
                                />
                            </div>
                            {
                                postList?.map((item) => {
                                    return (
                                        <div>
                                            <div className={mobileStyle.postCard}
                                                onClick={()=>{
                                                    navigate(`/admin/feeds/display-post/${item.id}`)
                                                }}
                                            >
                                                <p className={mobileStyle.postHeading}>{item.post_type}</p>
                                                <div className={mobileStyle.flexSpaceBetween}>
                                                    <div>
                                                        <span className={mobileStyle.countText}>Reaction</span>
                                                        <span className={mobileStyle.countValue}>{item.total_reaction}</span>
                                                    </div>
                                                    <div>
                                                        <span className={mobileStyle.countText}>Comment</span>
                                                        <span className={mobileStyle.countValue}>{item?.total_comments}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </>
                    // desktop
                    :
                    <div style={{ padding: "50px" }}>
                        <div style={{ background: "white", padding: "30px", borderRadius: "10px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                    <p style={{ fontSize: "24px", fontWeight: "600", margin: "0" }}>Posts</p>
                                    <button style={{ background: "#008080", color: "white", padding: "7px 15px", borderRadius: "5px", border: "none" }}
                                        onClick={() => setAddPostModal(true)}
                                    >Add Post</button>
                                </div>
                                <DatePicker
                                    size='large'
                                    onChange={onPostDateChange}
                                />
                            </div>

                            <div style={{ marginTop: "20px" }}>
                                <Table
                                    columns={postColumns}
                                    dataSource={postList}
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </div>
            }


            <Modal
                centered
                open={addPostModal}
                onOk={() => setAddPostModal(false)}
                onCancel={() => {
                    setAddPostModal(false);
                    setPostDetails({
                        visible_to_departments: "",
                        post_type: "",
                        text: "",
                        uploadType: "",
                        image: "",
                    })
                }}
                footer={null}
            >
                <div>
                    <p style={{ color: "#161616", fontSize: "24px", fontWeight: "500", lineHeight: "27px" }}>Add Post</p>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        style={{ marginTop: "20px" }}
                        fields={[
                            {
                                name: "visible_to_departments",
                                value: postDetails.visible_to_departments
                            },
                            {
                                name: "choseTitle",
                                value: postDetails.choseTitle
                            },
                            {
                                name: "text",
                                value: postDetails.text
                            },
                            {
                                name: "uploadType",
                                value: postDetails.uploadType
                            },
                        ]}
                    >
                        <Form.Item
                            label="Post Visible"
                            name="title"
                        >
                            <Select
                                size='large'
                                style={{ width: "100%" }}
                                placeholder="Select Category"
                                value={postDetails.uploadType}
                                onChange={(value) => setPostDetails({
                                    ...postDetails,
                                    visible_to_departments: value
                                })}
                            >
                                {
                                    departmentList?.map((item) => {
                                        return (
                                            <Select.Option value={item.id}>{item.name}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Post Type"
                            name="post_type"
                        >
                            <Select
                                size='large'
                                style={{ width: "100%" }}
                                placeholder="Select Category"
                                value={postDetails.post_type}
                                onChange={(value) => setPostDetails({
                                    ...postDetails,
                                    post_type: value
                                })}
                            >
                                {
                                    postType?.map((item) => {
                                        return (
                                            <Select.Option value={item.value}>{item.name}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Text"
                            name="text"
                        >
                            <TextArea
                                placeholder="Write text here"
                                size='large'
                                rows={4}
                                onChange={(e) => setPostDetails({
                                    ...postDetails,
                                    text: e.target.value
                                })}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Upload image"
                            name="uploadType"
                        >
                            <Select
                                size='large'
                                style={{ width: "100%" }}
                                placeholder="Select Category"
                                value={postDetails.uploadType}
                                onChange={(value) => setPostDetails({
                                    ...postDetails,
                                    uploadType: value
                                })}
                            >
                                <Select.Option value="1">Default Image</Select.Option>
                                <Select.Option value="2">Upload Image</Select.Option>
                                <Select.Option value="3">None</Select.Option>
                            </Select>
                        </Form.Item>

                        {
                            postDetails.uploadType === "1" &&
                            <div style={{ marginBottom: "20px" }}>
                                <img src="https://picsum.photos/200" alt="" width={100} height={100} />
                            </div>
                        }

                        {
                            postDetails.uploadType === "2" &&
                            <Form.Item
                                // label="Upload File"
                                name="document"

                            >
                                <div className={desktopStyle.fileUploadContainer}>
                                    <input
                                        type="file"
                                        id="file-upload"
                                        className={desktopStyle.inputFile}
                                        onChange={(e) => {
                                            const selectedFile = e.target.files[0];
                                            const maxSizeInBytes = 5 * 1024 * 1024;

                                            const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', "image/jpg"];

                                            // Check if the selected file is an allowed image type
                                            if (allowedImageTypes.includes(selectedFile.type)) {
                                                // Check if the file size is within the allowed limit
                                                if (selectedFile.size <= maxSizeInBytes) {
                                                    console.log(selectedFile);
                                                    setPostDetails({
                                                        ...postDetails,
                                                        image: selectedFile
                                                    })
                                                } else {
                                                    // File size exceeds the limit
                                                    // setFile(null);
                                                    setPostDetails({
                                                        ...postDetails,
                                                        image: ""
                                                    })
                                                    alert('File size exceeds the limit of 5MB.');
                                                }
                                            } else {
                                                // File is not an allowed image type
                                                setPostDetails({
                                                    ...postDetails,
                                                    image: ""
                                                })
                                                alert('Please upload a valid image file (JPEG, PNG, GIF).');
                                            }
                                        }
                                        }
                                    />
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                        <label htmlFor="file-upload" className={desktopStyle.fileUploadLabel}>

                                        </label>
                                        {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                        <p style={{ zIndex: 1, margin: "10px" }}>{postDetails?.image ? postDetails?.image.name : "Select a image or drag and drop here"}</p>
                                    </div>
                                </div>
                            </Form.Item>
                        }

                        <button
                            style={{ border: "none", background: "#008080", color: "white", height: "40px", width: "150px", borderRadius: "5px" }}
                        >Post</button>
                    </Form>
                </div>
            </Modal>
        </div >
    )
}

export default Posts