// src/Main.js
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import App from './App';
import { setCompanyName } from './redux/reducer/CompName';


const Main = () => {
    let companyName = useSelector((state) => state.companyName);
    const dispatch = useDispatch();

    const frontend_base_url = process.env.REACT_APP_FRONT_APP_BASE_URL;

    let splitValue = window.location.href?.split(frontend_base_url)[1]?.split("/")[1];
    companyName = splitValue === "app" ? "" : splitValue;

    dispatch(setCompanyName(companyName));
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
        const companyNameLocalStorage = localStorage.getItem("companyName")

        if (companyNameLocalStorage) {
            companyName = companyNameLocalStorage
        }
        // reactnative web view code
        // Send the tenant name to the React Native app
        if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
            // window.ReactNativeWebView.postMessage(JSON.stringify({ tenantName }));
            window.ReactNativeWebView.postMessage(JSON.stringify({ companyName }));
        } else {
            console.warn('ReactNativeWebView is not defined.');
        }

        
    }

    return (
        <BrowserRouter basename={companyName}>
            <App />
        </BrowserRouter>
    );
};

export default Main;
