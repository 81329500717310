import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import request from '../../../utils/axios';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';

function EditPersonalDetails() {
    const navigate = useNavigate();
    const params = useParams();
    const { TextArea } = Input;

    const [userProfile, setUserProfile] = useState({
        employee_name: "",
        personal_email: "",
        contact: "",
        address: "",
        dob: "",
        gender: "",
        marital_status: "",
        anniversary: "",
        employee_type: "",
        department: "",
        reporting_manager: "",
        joining_date: "",
        work_mode: "",
        work_location: "",
        notice_period: "",
        employement_number: "",
        empfunction: "",
        location: ""
    })

    const handleFormChange = (changedValues, allValues) => {
        setUserProfile(allValues);
    }


    const onFinish = async (values) => {
        try {
            const dob = dayjs(values.dob).format('YYYY-MM-DD');
            values = { ...values, dob }

            if (values?.anniversary) {
                const anniversary = dayjs(values.anniversary).format('YYYY-MM-DD');
                values = { ...values, dob, anniversary }
            }

            await request({
                method: "put",
                url: `profile/details/update/${params.id}/`,
                data: values,
            })

            navigate("/user-profile", { replace: true })
        }
        catch (error) {
            console.log("error", error);

        }

        // fetchProfileData()
        // setEditPersonalDetails(!editPersonalDetails)
    };

    const fetchProfileData = async () => {
        let data = await request({
            method: "get",
            url: `profile/details/`,
        })

        // setDobDisplay(data.data.data.dob);
        // if (data.data.data.anniversary) {
        //     setAnniversaryDisplay(data.data.data.anniversary);
        // }
        const dobDate = new Date(data.data.data.dob).toISOString();

        data.data.data = { ...data.data.data, dob: dayjs(dobDate) }

        if (data.data.data.anniversary) {
            const anniversaryDate = new Date(data.data.data.anniversary);
            const anniversary = anniversaryDate.toISOString();
            data.data.data = { ...data.data.data, anniversary: dayjs(anniversary) }
        }

        setUserProfile({
            ...data.data.data,
        })
    }

    useEffect(() => {
        fetchProfileData();
    }, [])

    return (
        <div>
            <div style={{ padding: "16px", background: "white" }}>
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    onValuesChange={handleFormChange}
                    initialValues={userProfile}
                    fields={[
                        {
                            name: ["contact"],
                            value: userProfile.contact
                        },
                        {
                            name: ["address"],
                            value: userProfile.address
                        },
                        {
                            name: ["dob"],
                            value: userProfile.dob
                        },
                        {
                            name: ["gender"],
                            value: userProfile.gender
                        },
                        {
                            name: ["marital_status"],
                            value: userProfile.marital_status,
                        },
                        {
                            name: ["anniversary"],
                            value: userProfile.anniversary
                        },
                        {
                            name: ["employee_type"],
                            value: userProfile.employee_type
                        },
                    ]}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Gender"
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select your Gender!',
                                    },
                                ]}

                            >
                                <Select
                                    size='large'
                                >
                                    <Select.Option value={0}>Male</Select.Option>
                                    <Select.Option value={1}>Female</Select.Option>
                                    <Select.Option value={2}>Other</Select.Option>
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Marital Status"
                                name="marital_status"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Marital Status!',
                                    },
                                ]}
                            >
                                <Select
                                    size='large'
                                >
                                    <Select.Option value={0}>Married</Select.Option>
                                    <Select.Option value={1}>Single</Select.Option>
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Address"
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your address!',
                                    },
                                ]}
                            >
                                <TextArea
                                    rows={5}
                                // disabled={!editPersonalDetails}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Date of Birth"
                                name="dob"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Date of Birth!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Anniversary"
                                name="anniversary"
                                rules={
                                    [
                                        {
                                            required: userProfile.marital_status === 0 ? true : false,
                                            message: 'Please enter your Anniversary!',
                                        },
                                    ]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    size='large'
                                    disabled={userProfile.marital_status === 1 ? true : false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Contact No."
                                name="contact"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your contact!',
                                    },
                                    {
                                        pattern: /^[0-9]{10}$/, // A regular expression that matches a 10-digit contact number
                                        message: 'Please enter a valid 10-digit contact number!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button
                        htmlType='submit'
                        style={{ width: "100%", height: "34px", background: "#008080", color: "white", fontSize: "poppins" }}
                    >
                        Save
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default EditPersonalDetails