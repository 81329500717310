import React, { useEffect, useState } from 'react'
import request from '../../../../../utils/axios';
import "./LogsSearchBar.scss"

function LogsSearchBar({
    setTableData, // set table data for search employee 
    setHasMore,
    fetchTableDataFromApi, // if input field get empty then setTableData function call api and set data in table
    setPagination,
    placeholderText,
    empType
}) {
    const [searchEmployeeList, setSearchEmployeeList] = useState([])
    const handleSelectChange = async (value) => {
        try {
            const response = await request({
                method: "get",
                url: `attendance/my-team/employee/attendance/details/?employement_number=${value}`,
            })

            setTableData([...response])
            setHasMore(false)
            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const search = async (data) => {
        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=${empType}`,

            })

            setSearchEmployeeList(response)
            if (data === "") {
                setSearchEmployeeList([])
                const newPagination = {
                    current: 1,
                    pageSize: 12,
                    total: 0,
                }
                setPagination(newPagination)
                const dataList = []
                setHasMore(true)
                fetchTableDataFromApi(newPagination, dataList)
            }
        }
        catch (err) {
            console.log(err);
        }
    }


    return (
        <div className="search-container">
            <input
                type="text"
                placeholder={placeholderText}
                onChange={(e) => search(e.target.value)}
            />
            {searchEmployeeList.length > 0 && (
                <ul>
                    {searchEmployeeList.map((item, index) => (
                        <li key={index} onClick={() => handleSelectChange(item.employement_number)}>
                            {item.employee_name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default LogsSearchBar