import { Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import request from '../../../../../utils/axios';
import { setSelectedEmployeePayrollProcessing } from '../../../../../redux/reducer/BulkPayrollProcessing';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


function EveryOne() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const everyoneColumns = [
        {
            title: "ID",
            dataIndex: "employement_number",
            key: "employement_number"
        },
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        }
    ]

    const [bulkUploadType, setBulkUploadType] = useState("everyone");
    const [selectiveDataList, setSelectiveDataList] = useState([]);
    const [selectedSelectiveRowKeys, setSelectedSelectiveRowKeys] = useState([]);
    const [selectedEveryOneRowKeys, setSelectedEveryOneRowKeys] = useState([]);
    const [everyoneDataList, setEveryoneDataList] = useState([]);


    const rowSelectionEveryOne = {
        selectedEveryOneRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedEveryOneRowKeys(selectedRowKeys)
        }
    }


    const [changeByDropdownList, setChangeByDropdownList] = useState([]);
    const [selectedChangeBy, setSelectedChangeBy] = useState('');


    const fetchEmployeesDetailList = async () => {
        try {

            let param = '';
            if (selectedChangeBy === 1) {
                param = 'departments'
            }
            else if (selectedChangeBy === 2) {
                param = 'designations'
            }
            else if (selectedChangeBy === 3) {
                param = 'functions'
            }
            else if (selectedChangeBy === 4) {
                param = 'grades'
            }
            else if (selectedChangeBy === 5) {
                param = 'work_locations'
            }
            const data = await request({
                method: "get",
                url: `payroll/admin/payroll/get-bulk-details?select_by=everyone&${param && `${param}=${selectedSelectBy}`}`,
            })

            setEveryoneDataList(data.data)

        }
        catch (error) {
            console.log("error", error)
        }
    }

    const fetchChangeByDropdownList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `admin/bulk-update/dropdown`,
            });

            setChangeByDropdownList(response.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const [selectByDropdownList, setSelectByDropdownList] = useState([]);
    const [selectedSelectBy, setSelectedSelectBy] = useState([]);

    const fetchSelectByDropdownList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `admin/bulk-update/select-by/dropdown?id=${selectedChangeBy}`,
            });

            setSelectByDropdownList(response.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        fetchEmployeesDetailList();
        fetchChangeByDropdownList();
    }, [])

    useEffect(() => {
        if (selectedChangeBy) {
            fetchSelectByDropdownList();
        }
    }, [selectedChangeBy])

    useEffect(() => {
        if (bulkUploadType === 'everyone') {
            fetchEmployeesDetailList()
        }
    }, [selectedSelectBy])

    useEffect(() => {
        setSelectiveDataList([]);
        fetchEmployeesDetailList();
    }, [])
    const runPayroll = () => {
        console.log("runPayroll button....")
        console.log("^^^^^^^^^^^^^^^^", selectedEveryOneRowKeys)
        // dispatch(setSelectedEmployeePayrollProcessing([1]))
        // dispatch(setSelectedEmployeePayrollProcessing({ selectedSelectiveRowKeys: "dfds" }));
        dispatch(setSelectedEmployeePayrollProcessing(
            selectedEveryOneRowKeys,
        ));
        navigate("/admin/payroll/9")
    }


    return (
        <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: "20px", width: "100%" }}>
                    <Select
                        size='large'
                        style={{ width: "30%" }}
                        placeholder='Select Change By'
                        onChange={(e) => {
                            console.log("e....", e);
                            setSelectedChangeBy(e)
                        }}
                    >
                        {
                            changeByDropdownList.map((item, index) => {
                                return <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                    <Select
                        mode='multiple'
                        size='large'
                        style={{ width: "30%" }}
                        onChange={(e) => setSelectedSelectBy([...e])}
                        placeholder='Select Select By'
                    >
                        {
                            selectByDropdownList?.map((item, index) => {
                                return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </div>
                <button
                    style={{
                        border: "none",
                        background: "#008080",
                        color: "white",
                        cursor: "pointer",
                        borderRadius: "4px",
                        height: "40px",
                        width: "150px",
                        marginLeft: "20px"
                    }}
                    // onClick={() => setSelectPolicyModal(true)}
                    onClick={() => runPayroll()}
                >Run Payroll</button>
            </div>
            <div style={{ marginTop: "20px" }}>
                <Table
                    // rowKey={(record) => `everyone-${record.key}`}
                    rowSelection={rowSelectionEveryOne}
                    columns={everyoneColumns}
                    dataSource={everyoneDataList}
                />
            </div>
        </div>
    )
}

export default EveryOne