import { Timeline } from 'antd'
import React, { useEffect, useState } from 'react';

import "../SalaryDetails/SalaryDetails.scss"
import { useNavigate, useParams } from 'react-router-dom';
import request from '../../../../utils/axios';
import getScreenSize from '../../../../utils/getScreenSize';
import bullet from "../../../../assets/bullet.svg"
import { RightOutlined } from '@ant-design/icons';

function SalaryDetails() {
  const navigate = useNavigate();
  const screenSize = getScreenSize();
  const [salaryHistoryforEmployee, setsalaryHistoryforEmployee] = useState([])
  const fetchSalaryHistory = async () => {
    try {
      let data = await request({
        method: "get",
        url: `payroll/employee-salary-history`,
      })
      console.log("data:", data?.data)
      setsalaryHistoryforEmployee(data?.data)
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchSalaryHistory();
  }, [])
  useEffect(() => {
    // fetchSalaryHistory();
  }, [salaryHistoryforEmployee])


  const displaySalaryDetails = () => {
    const data = salaryHistoryforEmployee.map((item, index) => {
      return {
        label: <div>
          <p className='reviseDate'>{item.date}</p>
          <p className='reviseAppresalValue'>{item.appresalType} {item.appresalPercentage > 0 && item.appresalPercentage}</p>
        </div>,
        children:
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", border: "1px solid #F0F0F0", padding: "20px 10px" }}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", width: "100%" }}>
              <div className='appresalBox'  >
                <div>
                  <p className='appresalHeading'>Total CTC</p>
                  <p className='appresalValue'>{item.total_ctc}</p>
                  {
                    item.appresalPercentage > 0 && <p className='reviseAppresalValue'>{item.appresalPercentage}%</p>
                  }
                </div>
                <p className='appresalSign'>
                  =
                </p>
              </div>
              {
                item.Fixed > 0 &&
                <div className='appresalBox'>
                  <div>
                    <p className='appresalHeading'>Fixed</p>
                    <p className='appresalValue'>{item.Fixed}</p>
                    {
                      item.fixedAppresalBy > 0 && <p className='reviseAppresalValue'>{item.fixedAppresalBy}%</p>
                    }
                  </div>
                  {
                    item.Flexi > 0 &&
                    <p className='appresalSign'>
                      +
                    </p>
                  }

                </div>
              }


              {
                item.Flexi > 0 &&
                <div className='appresalBox'>
                  <div>
                    <p className='appresalHeading'>Flexi</p>
                    <p className='appresalValue'>{item.Flexi}</p>
                    {
                      item.flexiAppresalBy > 0 && <p className='reviseAppresalValue'>{item.flexiAppresalBy}%</p>
                    }
                  </div>
                  {
                    item.Retirals > 0 &&
                    <p className='appresalSign'>
                      +
                    </p>
                  }

                </div>
              }

              {
                item.Retirals > 0 &&
                <div className='appresalBox'>
                  <div>
                    <p className='appresalHeading'>Retirals</p>
                    <p className='appresalValue'>{item.Retirals}</p>
                    {
                      item.retiralsAppresalBy > 0 && <p className='reviseAppresalValue'>{item.retiralsAppresalBy}%</p>
                    }
                  </div>
                  {
                    item.Variable > 0 &&
                    <p className='appresalSign'>
                      +
                    </p>
                  }

                </div>
              }

              {
                item.Variable > 0 &&
                <div className='appresalBox'>
                  <div>
                    <p className='appresalHeading'>Variable</p>
                    <p className='appresalValue'>{item.Variable}</p>
                    {
                      item.variableAppresalBy > 0 && <p className='reviseAppresalValue'>{item.variableAppresalBy}%</p>
                    }
                  </div>
                  <p></p>
                </div>
              }

            </div>
            <div>
              <span className='appresalSign'
                onClick={() => {
                  navigate(`/payroll/salary-details/salary-breakup/${item.date}`, { replace: true });
                  // navigate("/payroll/summary")
                }}
              >{">"}</span>
            </div>
          </div>
      }
    })

    return data;
  }

  return (
    <div>
      {
        screenSize.width < 768
          ?
          <div style={{ padding: "0px 16px", marginTop: "80px" }}>
            <p style={{ fontSize: "24px ", fontWeight: "500", color: "#161616" }}>Salary Details</p>
            <p style={{ fontSize: "18px ", fontWeight: "500", color: "#161616" }}>Salary History</p>
            {
              salaryHistoryforEmployee?.map((item, index) => {
                return (
                  <div>
                    <p style={{ fontSize: "18px ", fontWeight: "500", color: "#161616" }}>
                      <img src={bullet} alt="bullet" width={20} height={20} style={{ marginRight: "10px" }} />
                      {item.date}
                      {
                        item.appresalType == "Inc"
                        &&
                        <span style={{ color: "#28B15F", fontSize: "16px", fontWeight: "600", marginLeft: "10px" }}>+ {item.appresalPercentage}%</span>
                      }
                      {
                        item.appresalType == "DEC"
                        &&
                        <span style={{ color: "red", fontSize: "16px", fontWeight: "600", marginLeft: "10px" }}>- {item.appresalPercentage}%</span>
                      }
                    </p>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 0px 0px 50px" }}>


                      <p style={{ fontSize: "16px", color: "#77777A" }}>Total CTC</p>
                      <p style={{ fontSize: "16px", color: "#161616", fontWeight: "600" }}>₹ {item.total_ctc}
                        <span><RightOutlined
                          onClick={() => navigate(`/payroll/salary-details/salary-breakup/${item.date}`, { replace: true })}
                        /></span>
                      </p>



                    </div>
                  </div>
                )
              })
            }
          </div>
          :
          <div style={{ padding: "50px" }}>
            <p style={{ fontSize: "32px ", fontWeight: "500", color: "#161616" }}>Salary Details</p>
            <div style={{
              background: "white",
            }}>


              <div className='timeline'>
                <p style={{ fontSize: "24px ", fontWeight: "500", color: "#161616" }}>Salary History</p>
                <br />
                <Timeline
                  mode="left"
                  items={displaySalaryDetails()}
                />
              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default SalaryDetails