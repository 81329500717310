import React, { useEffect, useState } from 'react'
import { Form, Input, Button, ConfigProvider, Modal } from 'antd';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

import "../Login/Login.scss"
import login from "../../assets/login.png";
import getScreenSize from "../../utils/getScreenSize";
import { jwtDecode } from "jwt-decode";
import request from "../../utils/axios"
import { toast } from 'react-toastify';
import forgotImg from "../../assets/Attendance/forgotImg.svg"
import axios from 'axios';
import ErrorImg from '../../assets/Error.svg'
import desktopEngageLoginLogo from "../../assets/desktopEngageLogin.svg";
import googleBtnApp from "../../assets/googleBtnApp.png"

function Login() {
    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const googleClientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET
    const mobileGoogleClientId = process.env.REACT_APP_MOBILE_GOOGLE_CLIENT_ID
    const screenSize = getScreenSize();
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const handleGoogleLogin = async (response) => {
        console.log("handleGoogleLogin...");
        let deviceType;
        if (screenSize.width < 768) {
            // mobile
            deviceType = 0
        }
        else if (screenSize.width < 1440) {
            // laptop
            deviceType = 1
        }
        else {
            // desktop
            deviceType = 2
        }
        const res = await request({

            method: "post",
            url: `/users/verify-login`,
            data: {
                token: response.credential,
                backend: "google-oauth2",
                grant_type: "convert_token",
                client_id: googleClientId,
                client_secret: googleClientSecret,
                device_type: deviceType
            }
        })

        localStorage.setItem("access_token", res.access_token);
        localStorage.setItem("employee_number", res.data.employee_number);
        localStorage.setItem("on_boarding", res.data.on_boarding);
        localStorage.setItem("user_id", res.data.user_id)
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("parent_company_id", res.data.parent_company_id)
        localStorage.setItem("is_admin", res.data.is_admin)
        localStorage.setItem("role", "Emp")
        localStorage.setItem("role_list", JSON.stringify(res.data.role_list))
        localStorage.setItem("is_org_head", res.data.is_org_head);
        // console.log("res", res.data.profile_image);


        if (res && res.data.access_token && res.data.on_boarding) {
            navigate('/dashboard', { replace: true });
        } else {
            navigate('/onboarding', { replace: true })
        }
    };

    const onFinish = async (values) => {
        try {
            let deviceType;
            if (screenSize.width < 768) {
                // mobile
                deviceType = 0
            }
            else if (screenSize.width < 1440) {
                // laptop
                deviceType = 1
            }
            else {
                // desktop
                deviceType = 2
            }

            const data = await request({
                method: "post",
                url: `/users/login`,
                data: {
                    email: values.email.split(' ').join('').toLowerCase(),
                    password: values.password,
                    device_type: deviceType
                }
            })
            localStorage.setItem("employee_number", data.data.employee_number);
            localStorage.setItem("on_boarding", data.data.on_boarding);
            localStorage.setItem("user_id", data.data.user_id)
            localStorage.setItem("token", data.data.token);
            localStorage.setItem("parent_company_id", data.data.parent_company_id)
            localStorage.setItem("is_admin", data.data.is_admin)
            localStorage.setItem("role", "Emp")
            localStorage.setItem("role_list", JSON.stringify(data.data.role_list))
            localStorage.setItem("is_all_documents_signed", data.data.is_all_documents_signed)
            localStorage.setItem("is_org_head", data.data.is_org_head);
            // localStorage.setItem("profile_image", data.data.profile_image)
            if (data.data.on_boarding) {
                if (data.data.is_all_documents_signed) {
                    navigate('/dashboard', { replace: true });
                }
                else {
                    navigate('/forced-document', { replace: true });
                }
            }
            else {
                navigate('/onboarding', { replace: true });
            }

            // reactnative web view code
            const frontend_base_url = process.env.REACT_APP_FRONT_APP_BASE_URL;

            let companyName = window.location.href?.split(frontend_base_url)[1]?.split("/")[1];

            // Send the tenant name to the React Native app
            if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
                // window.ReactNativeWebView.postMessage(JSON.stringify({ tenantName }));
                window.ReactNativeWebView.postMessage(JSON.stringify({ companyName }));
            } else {
                console.warn('ReactNativeWebView is not defined.');
            }
        }
        catch (error) {
            setLoginError(true)
            if (error.response.data.status === 404) {
                setErrorMsg(error.response.data.message)
            }
            console.log(error)
        }
    };

    const [forgotPasswordModel, setForgotPasswordModel] = useState(false)
    const sendMail = async (value) => {
        try {
            const data = await request({
                method: "post",
                url: `/users/forgot-password/`,
                data: value
            })
            setSendMailSuccessfullModel(true)
        }
        catch (err) {
            toast.error(err.response.data.message);
            console.log(err)
        }
        finally {
            setForgotPasswordModel(false)
        }
    }

    const [sendMailSuccessfullModel, setSendMailSuccessfullModel] = useState(false)

    const isWebView = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return userAgent.includes('MyAppWebView/1.0');
    };

    const [isApp, setIsApp] = useState(false)

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        console.log("cccccc",userAgent.includes('MyAppWebView/1.0'));

        // const userAgent = window.navigator.userAgent.toLowerCase();
        // const isRunningInWebView =
        //     userAgent.includes('iphone') ||
        //     userAgent.includes('android') ||
        //     userAgent.includes('ipad') ||
        //     userAgent.includes('ipod') ||
        //     userAgent.includes('webview');
        // console.log('isRunningInWebView', isRunningInWebView);
        // setIsApp(isRunningInWebView);
        setIsApp(userAgent.includes('MyAppWebView/1.0'));
    }, []);

    // useEffect(() => {
    //     window.addEventListener('message', (event) => {
    //         console.log("google...", event);
    //         // Adjust the origin to match your local development setup
    //         // if (event?.origin !== 'http://localhost:8000') {
    //         //     // Validate the origin of the message for security
    //         //     console.warn('Invalid origin:', event?.origin);
    //         //     return;
    //         // }

    //         try {
    //             const data = JSON.parse(event?.data);
    //             if (data?.idToken) {
    //                 // Use the idToken as needed
    //                 console.log('Received idToken:', data?.idToken);
    //                 // You can now send this token to your backend for verification or use it in your app
    //                 handleGoogleLogin({ credential: data?.idToken });
    //             }
    //         } catch (e) {
    //             console.error('Error parsing message data:', e);
    //         }
    //     });
    // }, [])

    useEffect(() => {
        const handleMessage = (event) => {
            console.log('Received event:', event);

            // Ensure the message is from a trusted source
            // if (event.origin === 'http://localhost:8081') {
            try {
                const data = JSON.parse(event.data);
                console.log('Message data:', data);
                if (data.source === 'react-native-webview') {
                    console.log('Message from React Native WebView:', data);
                } else {
                    console.log('Message from another source:', data);
                }
                handleGoogleLogin({ credential: data?.idToken });
            } catch (error) {
                console.error('Failed to parse message data:', error);
            }
            // }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    function sendMessageToReactNative() {
        const googleLogin = "triggerGoogleSignIn";
        console.log("message....:", googleLogin);
        if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
            window.ReactNativeWebView.postMessage(JSON.stringify({ googleLogin }));
        } else {
            console.warn('ReactNativeWebView is not defined.');
        }


    }

    // const sendMessage = () => {
    //     const stopTracking = "stopTracking";
    //     console.log("message....:", stopTracking);
    //     if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
    //         window.ReactNativeWebView.postMessage(JSON.stringify({ stopTracking }));
    //     } else {
    //         console.warn('ReactNativeWebView is not defined.');
    //     }
    // }

    // const startTracking = () => {
    //     const startTracking = "startTracking";
    //     console.log("message....:", startTracking);
    //     if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
    //         window.ReactNativeWebView.postMessage(JSON.stringify({ startTracking }));
    //     } else {
    //         console.warn('ReactNativeWebView is not defined.');
    //     }
    // }


    return (
        <div className="container">
            <img src={desktopEngageLoginLogo} alt="" className='engageLogo' />
            <center>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
                    <div className="leftContainer">
                        <img
                            src={login}
                            alt="login_image"
                            className='loginImg'
                        />
                    </div>
                    <div className="rightContainer">
                        <div>
                            <h1 className='signInHeading'>Sign In</h1>
                            {/* <p className='engageHeading'>Engage</p> */}
                            {/* <p className='welcomeHeading'>Welcome to Engage</p> */}
                            {
                                loginError &&
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <div
                                        className='errorMsgContainer'
                                    >
                                        <div style={{
                                            display: "flex",

                                            gap: "20px",

                                            padding: "10px 10px",
                                        }}>
                                            <div>
                                                <img src={ErrorImg} alt={ErrorImg} />
                                            </div>
                                            <div>

                                                {
                                                    errorMsg
                                                        ?
                                                        <p style={{
                                                            fontWeight: "700",
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px"
                                                        }}>{errorMsg}.</p>
                                                        :
                                                        <span style={{
                                                            fontWeight: "700",
                                                            fontSize: "16px",
                                                            margin: "0px",
                                                            padding: "0px"
                                                        }}>Incorrect email or password. <br />
                                                            <span style={{
                                                                fontWeight: "400",
                                                                fontSize: "16px",
                                                                margin: "0px",
                                                                padding: "0px"
                                                            }}>
                                                                Either the email or password you entered is incorrect.
                                                            </span>
                                                        </span>
                                                }

                                                <span style={{
                                                    fontWeight: "400",
                                                    fontSize: "16px",
                                                    margin: "0px",
                                                    padding: "0px"
                                                }}>

                                                    Please try again or try to
                                                    <span style={{ color: "#3354F4", fontWeight: "700", }}> contact support </span> if you are unable to access your account.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }

                            <ConfigProvider
                                theme={{
                                    token: {
                                        // fontSize: 20
                                    },
                                }}
                            >

                                <Form
                                    layout="vertical"
                                    onFinish={onFinish}
                                    autoComplete="on"
                                >
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Email must be required',
                                            },
                                            {
                                                type: 'email',
                                                message: 'Email must be valid',
                                            }
                                        ]}
                                    >
                                        <Input
                                            size='large'
                                            placeholder='Email'
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Password must be required',
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            type="password"
                                            size='large'
                                            placeholder='Password'
                                            autoComplete='on'
                                        />
                                    </Form.Item>

                                    <div className='forgotPasswordContainer'>
                                        <span className='forgotPassword'
                                            onClick={() => { setForgotPasswordModel(true) }}>
                                            Forgot Password
                                        </span>
                                    </div>

                                    <Form.Item
                                        className='signInBtnContainer'
                                    >
                                        <Button
                                            htmlType="Sign In"
                                            className="signInBtn"
                                        >
                                            Sign In
                                        </Button>
                                        {
                                            isApp
                                                ?
                                                <div>
                                                    <img
                                                        src={googleBtnApp}
                                                        alt="googleBtnApp"
                                                        width={48}
                                                        height={48}
                                                        onClick={() => sendMessageToReactNative()}
                                                    />
                                                </div>
                                                :
                                                <div>
                                                    {
                                                        isWebView() ?
                                                            <GoogleOAuthProvider
                                                                clientId={mobileGoogleClientId}
                                                            >
                                                                <GoogleLogin
                                                                    onSuccess={(response) => handleGoogleLogin(response)}
                                                                    width={screenSize.width > 767 ? "400px" : "300px"}
                                                                />
                                                            </GoogleOAuthProvider>
                                                            :
                                                            <GoogleOAuthProvider
                                                                clientId={googleClientId}
                                                            >
                                                                <GoogleLogin
                                                                    onSuccess={(response) => handleGoogleLogin(response)}
                                                                    width={screenSize.width > 767 ? "400px" : "300px"}
                                                                />
                                                            </GoogleOAuthProvider>
                                                    }
                                                </div>
                                        }


                                        {/* <button type="button" onClick={() => sendMessageToReactNative()}>Send Message to React Native</button> */}
                                        {/* <button type="button" onClick={() => startTracking()}>Start</button>
                                        <button type="button" onClick={() => sendMessage()}>Stop</button> */}
                                    </Form.Item>
                                </Form>
                            </ConfigProvider>
                        </div>
                    </div>
                </div>
            </center>
            <Modal
                centered
                open={forgotPasswordModel}
                footer={null}
                onOk={() => setForgotPasswordModel(false)}
                onCancel={() => setForgotPasswordModel(false)}
            >
                <p className='forgotPasswordText'>Forgot Password</p>
                <Form
                    layout="vertical"
                    onFinish={sendMail}
                    autoComplete="on"
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your email!',
                            },
                        ]}
                    >
                        <Input
                            size='large'
                            placeholder='Email'
                        />
                    </Form.Item>

                    <Button
                        htmlType='submit'
                        className='forgotPasswordButton'
                    >
                        Submit
                    </Button>
                </Form>
            </Modal>

            <Modal
                centered
                open={sendMailSuccessfullModel}
                footer={null}
                onOk={() => setSendMailSuccessfullModel(false)}
                onCancel={() => setSendMailSuccessfullModel(false)}
            >
                <center>
                    <img src={forgotImg} alt="forgotImg" />
                    <p>An Email Has been Sent To You With Reset Password</p>
                </center>
            </Modal>


        </div>

    )
}

export default Login