import React, { useEffect, useState } from 'react'
import request from '../../utils/axios';
import update from "immutability-helper";
import { Checkbox } from 'antd';
import styles from "./ForcedDocument.module.scss"
import { useNavigate } from 'react-router-dom';

function ForcedDocument() {
    const navigate = useNavigate();
    const [forcedDocumentList, setForcedDocumentList] = useState([])
    const [currentDocument, setCurrentDocument] = useState(0);

    const fetchForcedDocuments = async () => {
        try {
            const data = await request({
                method: "get",
                url: `/documentation/employee/policy/document/not-signed`,
            });

            if (data.data.length) {
                setForcedDocumentList(data.data.map((item, index) => {
                    return {
                        ...item,
                        isSigned: false
                    }
                }))
            }
            else {
                navigate("/dashboard", { replace: true })
            }
        } catch (error) {
            console.error("Error sending image to the server:", error);
        }
    }

    const submitCheckedForcedDocs = async () => {
        try {

            for (let i = 0; i < forcedDocumentList.length; i++) {
                const document = forcedDocumentList[i];

                // Check if the document is signed
                if (document.isSigned) {
                    // If signed, call the API to mark the document as signed
                    await request({
                        method: "post",
                        url: `/documentation/policy/document/signed`,
                        data: {
                            sign: true,
                            policy_document: document.id
                        }
                    });
                }
            }

            fetchForcedDocuments();

        } catch (error) {
            console.error("Error sending image to the server:", error);
        }
    }
    const onHandleChange = async (index, docId) => {

        const updatedData = update(forcedDocumentList, {
            [index]: {
                isSigned: {
                    $set: !forcedDocumentList[index].isSigned
                }
            }
        })
        setForcedDocumentList(updatedData)
    }

    useEffect(() => {
        fetchForcedDocuments();
    }, [])
    return (
        <div className={styles.container}>
            <p className={styles.heading}>New Policy Documents</p>
            <p className={styles.subHeading}>Please read the new policy document and accept the changes</p>
            <iframe
                src={forcedDocumentList[currentDocument]?.document}
                className={styles.docFrame}
            ></iframe>
            <div className={styles.checkboxBox}>
                <Checkbox
                    checked={forcedDocumentList[currentDocument]?.isSigned}
                    onChange={() => onHandleChange(currentDocument, forcedDocumentList[currentDocument].id)}
                >
                    <p className={styles.text}>
                        I confirm that I have read and understood this document completely and would like to sign off on the document.
                    </p>
                </Checkbox>
            </div>


            <div className={styles.btnBox}>
                {
                    currentDocument > 0 && (
                        <button
                            className={styles.btn}
                            onClick={() => setCurrentDocument(currentDocument - 1)}
                        >
                            Prev
                        </button>
                    )
                }

                {
                    currentDocument === forcedDocumentList.length - 1 ? (
                        <button
                            className={styles.btn}
                            onClick={() => submitCheckedForcedDocs()}
                        >
                            Submit
                        </button>
                    )
                        :
                        <button
                            className={styles.btn}
                            onClick={() => setCurrentDocument(currentDocument + 1)}
                        >
                            Next
                        </button>
                }
            </div>


        </div>
    )
}

export default ForcedDocument