import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs';

import './EmploymentDetails.scss';
// import "../../../styles/_global.scss";
import { setEmploymentDetails } from "../../../redux/reducer/OnboardingReducer"
import onboardingImg from "../../../assets/onboardingImg.svg";
import { request } from '../../../utils/axios';
import moment from 'moment';


function EmploymentDetails({ next, prev }) {
    const employmentDetails = useSelector((state) => state.onboarding.employmentDetails)
    const dispatch = useDispatch()
    const ref = useRef();
    const [form] = Form.useForm();

    const onFinish = (values) => {
        dispatch(setEmploymentDetails(values));
        next();
    };

    const fetchData = async () => {
        try {
            const employee_number = localStorage.getItem("employee_number");

            let data = await request({
                method: "get",
                url: `/get-employee-details/${employee_number}`,

            })

            const joining_date = moment(data.data?.joining_date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            data.data.joining_date = joining_date

            dispatch(setEmploymentDetails(data.data));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div className='employeeDetailsContainer' ref={ref}>
            <div className='formContainer'>
                <p className='heading'>Employment</p>
                <p className='subHeading'>Fill up your Employment details</p>
                <div style={{ width: "100%" }}>
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={employmentDetails}
                        onFinish={onFinish}
                        autoComplete="true"
                    >
                        <Row gutter={[32,8]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Designation"
                                    name="designation"
                                >
                                    <Input
                                        size='large'
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Reporting Manager"
                                    name="reporting_manager"
                                >
                                    <Input
                                        size='large'
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                       
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Date Of joining"
                                    name="joining_date"
                                >
                                    <Input
                                        size='large'
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Employee Type"
                                    name="employee_type"
                                >
                                    <Input
                                        size='large'
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                       
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Department"
                                    name="department"
                                >
                                    <Input
                                        size='large'
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Working Policy"
                                    name="work_mode"
                                >
                                    <Input
                                        size='large'
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                       
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Working Location"
                                    name="work_location"
                                >
                                    <Input
                                        size='large'
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Notice Period"
                                    name="notice_period"
                                >
                                    <Select
                                        // className='dropDown'
                                        disabled
                                        style={{ width: "100%" }}
                                        size='large'
                                    >
                                        <Select.Option value={15}>15 Days</Select.Option>
                                        <Select.Option value={30}>1 Month</Select.Option>
                                        <Select.Option value={60}>2 Months</Select.Option>
                                        <Select.Option value={90}>3 MOnths</Select.Option>

                                    </Select>

                                </Form.Item>
                            </Col>
                      
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Employment no."
                                    name="employee_number"
                                >
                                    <InputNumber
                                        size='large'
                                        style={{
                                            width: "100%"
                                        }}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Function"
                                    name="empfunction"
                                >
                                    <Input
                                        size='large'
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div style={{ marginTop: "10px", display: "flex", gap: "20px" }}>
                            <Button style={{ height: "40px", backgroundColor: "#008080", color: "white", padding: "0px 15px" }} onClick={() => prev()} >Previous</Button>
                            <Button style={{ height: "40px", backgroundColor: "#008080", color: "white", padding: "0px 15px" }} htmlType='submit' >Next</Button>
                        </div>
                    </Form>
                </div>
            </div>
            <div className='sideImage'>
                {/* <img src={onboardingImg} alt="side image" /> */}
            </div>
        </div>
    )
}

export default EmploymentDetails