import { Steps } from 'antd'
import React from 'react'
import groupSetting from "../../../../assets/PerformanceManagement/Group.png";
import SelfReview from "../../../../assets/PerformanceManagement/SelfReview.png";
import PeerReview from "../../../../assets/PerformanceManagement/PeerReview.png";
import ManagerReview from "../../../../assets/PerformanceManagement/ManagerReview.png";
import Calibration from "../../../../assets/PerformanceManagement/Calibration.png";
import EmploymentAcknowledgement from "../../../../assets/PerformanceManagement/EmploymentAcknowledgement.png";
import { RightOutlined } from '@ant-design/icons';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import fullView from "../../../../assets/fullView.svg";
import add from "../../../../assets/Org/add.svg"

function Dashboard() {
    const navigate = useNavigate();
    const data = {
        labels: ['In Progress', 'Not Started', 'Complete', 'Awaiting Signature'],
        datasets: [
            {
                label: '',
                data: [12, 19, 3, 5],
                backgroundColor: [
                    '#FFD686',
                    '#FFA6A6',
                    '#28B15F',
                    '#5FABFC',
                ],
                borderColor: [
                    '#FFD686',
                    '#FFA6A6',
                    '#28B15F',
                    '#5FABFC',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
                // text: 'Pie Chart Example',
            },
        },
    };

    return (
        <div style={{ padding: "50px" }}>
            <p style={{ fontSize: "32px", fontWeight: "400" }}>Performance Review</p>

            <div style={{ background: "white", padding: "30px", borderRadius: "5px" }}>
                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Performance Cycle</p>

                <Steps
                    current={6}
                    labelPlacement="vertical"
                    items={[
                        {
                            title: 'Goal Setting',
                            icon: <img src={groupSetting} alt="groupSetting" width={40} height={40} />,
                        },
                        {
                            title: 'Self Review',
                            icon: <img src={SelfReview} alt="SelfReview" width={40} height={40} />,
                        },
                        {
                            title: 'Peer Review',
                            icon: <img src={PeerReview} alt="PeerReview" width={40} height={40} />,
                        },
                        {
                            title: 'Manager Review',
                            icon: <img src={ManagerReview} alt="ManagerReview" width={40} height={40} />,
                        },
                        {
                            title: 'Calibration',
                            icon: <img src={Calibration} alt="Calibration" width={40} height={40} />,
                        },
                        {
                            title: 'Employment Acknowledgement',
                            icon: <img src={EmploymentAcknowledgement} alt="EmploymentAcknowledgement" width={40} height={40} />,
                        },
                    ]}
                />
            </div>

            <div style={{ background: "white", marginTop: "30px", padding: "30px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0px" }}>Open Tasks</p>
                    <button style={{ color: "white", background: "#008080", border: "none", borderRadius: "5px", height: "40px", padding: "0px 16px" }}>View All</button>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", border: "1px solid #E7E7E7", padding: "20px 15px" }}>
                    <div>
                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616", margin: "0px", marginBottom: "10px" }}>Aditya  Feedback is pending for completion</p>
                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#8A8A8A", margin: "0px" }}>an hour ago | MSF</p>
                    </div>
                    <RightOutlined color='#737373' style={{ color: '#737373', fontSize: "30px" }} />
                </div>
            </div>

            <div style={{ background: "white", marginTop: "30px", padding: "30px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0px" }}>New Goal Plan</p>
                    <div style={{ display: "flex", gap: "20px" }}>
                        <img src={add} alt="add" onClick={() => navigate("/performance-management/goal-form")} style={{ cursor: "pointer", width: "40px", height: "40px" }} />
                        <img src={fullView} alt="fullView" onClick={() => navigate("/performance-management/goal")} style={{ cursor: "pointer", width: "40px", height: "40px" }} />
                    </div>

                </div>

                <div style={{ display: "flex", gap: "50px", alignItems: "center", marginTop: "30px" }}>
                    <div style={{ background: "#FAFAFA", padding: "20px", width: "300px", borderRadius: "5px" }}>
                        <p style={{ fontSize: "24px", fontWeight: "600", color: "#145757" }}>0</p>
                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>Objectives</p>
                    </div>
                    <div style={{ background: "#FAFAFA", padding: "20px", width: "300px", borderRadius: "5px" }}>
                        <p style={{ fontSize: "24px", fontWeight: "600", color: "#145757" }}>0.00%</p>
                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>Average Achievement</p>
                    </div>
                </div>


                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", marginTop: "30px" }}
                >
                    Goal Overview
                    <RightOutlined color='#737373' style={{ color: '#008080', fontSize: "25px", marginLeft: "10px" }} onClick={() => navigate("/performance-management/goal")} />
                </p>

                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", gap: "50px", alignItems: "center", marginTop: "30px" }}>
                    <div style={{ background: "#FAFAFA", padding: "20px", width: "100%", borderRadius: "5px" }}>
                        <p style={{ fontSize: "24px", fontWeight: "600", color: "#145757" }}>0</p>
                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>Completed</p>
                    </div>
                    <div style={{ background: "#FAFAFA", padding: "20px", width: "100%", borderRadius: "5px" }}>
                        <p style={{ fontSize: "24px", fontWeight: "600", color: "#145757" }}>0</p>
                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>In Progress</p>
                    </div>
                    <div style={{ background: "#FAFAFA", padding: "20px", width: "100%", borderRadius: "5px" }}>
                        <p style={{ fontSize: "24px", fontWeight: "600", color: "#145757" }}>0</p>
                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>On Hold</p>
                    </div>
                    <div style={{ background: "#FAFAFA", padding: "20px", width: "100%", borderRadius: "5px" }}>
                        <p style={{ fontSize: "24px", fontWeight: "600", color: "#145757" }}>0</p>
                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>At Risk</p>
                    </div>
                    <div style={{ background: "#FAFAFA", padding: "20px", width: "100%", borderRadius: "5px" }}>
                        <p style={{ fontSize: "24px", fontWeight: "600", color: "#145757" }}>0</p>
                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>Delayed</p>
                    </div>
                    <div style={{ background: "#FAFAFA", padding: "20px", width: "100%", borderRadius: "5px" }}>
                        <p style={{ fontSize: "24px", fontWeight: "600", color: "#145757" }}>0</p>
                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>Not Started</p>
                    </div>
                </div>

                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "50px", alignItems: "center", marginTop: "30px" }}>
                    <div style={{ width: '300px', height: '300px' }}>
                        <Doughnut data={data} options={options} />
                    </div>
                    <div style={{ width: '300px', height: '300px' }}>
                        <Doughnut data={data} options={options} />
                    </div>
                    <div style={{ width: '300px', height: '300px' }}>
                        <Doughnut data={data} options={options} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard