import React, { useEffect, useState } from 'react';
import pollIcon from "../../../../assets/Feeds/pollIcon.png";
import threeDot from "../../../../assets/Feeds/threedot.png";
import getScreenSize from '../../../../utils/getScreenSize';

import { Checkbox, Col, Row } from 'antd';

function Poll() {
    const options = [
        'Option 1',
        'Option 2',
        'Option 3',
        'Option 4',
    ]

    const [selectedOptions, setSelectedOptions] = useState([])
    const screenSize = getScreenSize();

    const onChangeCheckbox = (event) => {
        if (event.target.checked) {
            setSelectedOptions([...selectedOptions, event.target.value])
        }
        else {
            setSelectedOptions(selectedOptions.filter(item => item !== event.target.value))
        }
    }

    return (
        <div>
            {
                screenSize.width < 768
                    ?
                    <div style={{ padding: "0px 16px" }}>
                        <div style={{ border: "1px solid #F2F2F2", padding: "7px", marginTop: "20px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }} >
                                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                    <img src={pollIcon} alt="pollIcon" width={50} height={50} />
                                    <p style={{ fontSize: "22px", fontWeight: "600", color: "#008080", margin: "0px" }}>Polls</p>
                                </div>
                                <img src={threeDot} alt="threeDot" width={6} height={28} />
                            </div>
                            <p style={{ color: "#727272", fontSize: "12px", fontWeight: "400", lineHeight: "18px", marginTop: "20px" }}>Fill the polls Figma ipsum component variant main layer. Edit edit background layer overflow ellipse.Overflow bullet link scale italic.</p>
                            <div style={{ border: "1px solid #F2F2F2", padding: "10px", marginTop: "20px" }}>
                                <p style={{ color: "#161616", fontSize: "16px", fontWeight: "500", lineHeight: "24px" }}>What do you like most about your coworkers? </p>
                                <Row>
                                    {
                                        options.map((item, index) => {
                                            return (
                                                <Col key={index} span={24} style={{ marginBottom: "10px" }} onChange={(e) => onChangeCheckbox(e)}>
                                                    <Checkbox value={item} key={index} >{item}</Checkbox>
                                                </ Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ border: "1px solid #F2F2F2", padding: "20px", marginTop: "20px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }} >
                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <img src={pollIcon} alt="pollIcon" width={65} height={65} />
                                <p style={{ fontSize: "22px", fontWeight: "600", color: "#008080", margin: "0px" }}>Polls</p>
                            </div>
                            <img src={threeDot} alt="threeDot" width={6} height={28} />
                        </div>
                        <p style={{ color: "#727272", fontSize: "18px", fontWeight: "400", lineHeight: "27px", marginTop: "20px" }}>Fill the polls Figma ipsum component variant main layer. Edit edit background layer overflow ellipse.Overflow bullet link scale italic.</p>
                        <div style={{ border: "1px solid #F2F2F2", padding: "20px", marginTop: "20px" }}>
                            <p style={{ color: "#161616", fontSize: "22px", fontWeight: "500", lineHeight: "27px" }}>What do you like most about your coworkers? </p>
                            <Row>
                                {
                                    options.map((item, index) => {
                                        return (
                                            <Col key={index} span={24} style={{ marginBottom: "10px" }} onChange={(e) => onChangeCheckbox(e)}>
                                                <Checkbox value={item} key={index} >{item}</Checkbox>
                                            </ Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </div>
            }
        </div>
    )
}

export default Poll