import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import CommonTable from '../../../../../component/CommonTable/CommonTable';
import request from '../../../../../utils/axios';
import getScreenSize from '../../../../../utils/getScreenSize';
import add from "../../../../../assets/Org/add.svg"

function OfficeLocation() {
    const screenSize = getScreenSize();
    const [open, setOpen] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })
    const [locationForm] = Form.useForm();
    const officeLocationColumns = [
        {
            title: 'Name',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude',
        },
        {
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude',
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     key: 'action',
        // }
    ]

    const [savedLocationList, setSavedLocationList] = useState([])
    const fetchSavedLocation = async () => {
        try {
            const data = await request({
                method: "get",
                url: `/attendance/saved-location/all`,
            })
            setSavedLocationList(data.data)
        }
        catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        fetchSavedLocation();
    }, [pagination.current])

    const addLocation = async (values) => {
        try {
            await request({
                method: "post",
                url: `/attendance/location/create`,
                data: {
                    address: values.address,
                    longitude: values.longitude,
                    latitude: values.latitude
                }
            })
            locationForm.resetFields();
            setOpen(false);
            fetchSavedLocation();
        }
        catch (error) {
            console.log("error", error);
        }
    }

    return (
        <div>
            {
                screenSize.width < 768 ?
                    <>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px", marginTop: "20px" }}>
                            <p style={{ fontSize: "24px", fontWeight: "400", color: "#161616", margin: "0px" }}>Office Location</p>
                            <img src={add} alt="" onClick={() => setOpen(true)} />
                        </div>
                        {
                            savedLocationList.map((item, index) => {
                                return (
                                    <div key={index} style={{ marginTop: "15px" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px" }}>
                                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#616161" }}>Address</p>
                                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>{item.address}</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px" }}>
                                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#616161" }}>Latitude</p>
                                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>{item.latitude}</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px" }}>
                                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#616161" }}>Longitude</p>
                                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>{item.longitude}</p>
                                        </div>
                                        <hr />
                                    </div>
                                )
                            })
                        }
                    </>
                    :
                    <div style={{ padding: "0px 30px" }}>
                        <Button
                            style={{
                                float: "right",
                                background: "#008080",
                                color: "white",
                                height: "40px",
                                marginBottom: "20px"
                            }}
                            onClick={() => setOpen(true)}
                        >Add Location</Button>
                        <div>
                            <CommonTable
                                columns={officeLocationColumns}
                                data={savedLocationList}
                                pagination={pagination}
                                setPagination={setPagination}
                                handleRowClick={() => { }}
                            />
                        </div>
                    </div>
            }
            <Modal
                centered
                open={open}
                onOk={() => { }}
                onCancel={() => {
                    locationForm.resetFields();
                    setOpen(false)
                }}
                footer={null}
                width={600}
            >
                <Form
                    layout="vertical"
                    onFinish={addLocation}
                    form={locationForm}
                >
                    <Form.Item
                        name="address"
                        label="Name"
                        rules={[{ required: true, message: 'Please enter name!' }]}
                    >
                        <Input
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="latitude"
                        label="Latitude"
                        rules={[{ required: true, message: 'Please enter latitude!' }]}
                    >
                        <Input
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="longitude"
                        label="Longitude"
                        rules={[{ required: true, message: 'Please enter longitude!' }]}
                    >
                        <Input
                            size="large"
                        />
                    </Form.Item>
                    <Button
                        htmlType="submit"
                        style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                    >
                        Save
                    </Button>
                </Form>
            </Modal>
        </div>

    )
}

export default OfficeLocation