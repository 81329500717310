import dashboardImg from "../assets/dashboard.svg";
import orgImg from "../assets/Org.svg";
import employeeImg from "../assets/employee.svg";
import leaveImg from "../assets/leave.svg";
import attendanceImg from "../assets/attendance.svg";
import payrollImg from "../assets/payroll.svg";
import profileImg from "../assets/profile.svg";
import settingImg from "../assets/setting.svg";
import jobImg from "../assets/job.svg";
import activeInbox from "../assets/activeInbox.svg";
import performanceManagement from "../assets/performanceManagement.png";
import tracking from "../assets/tracking.png";
import lms from "../assets/inactiveLms.png";

import activeDashboardImg from "../assets/activeDashboardImg.svg";
import activeAttendanceImg from "../assets/activeAttendanceImg.svg";
import activeLeaveImg from "../assets/activeLeaveImg.svg";
import activeEmployeeImg from "../assets/activeEmployeeImg.svg";
import activeJobImg from "../assets/activeJobImg.svg";
import activePayrollImg from "../assets/activePayrollImg.svg";
import activePerformanceManagement from "../assets/activePerformanceManagement.png";
import activeTracking from "../assets/location.svg"
import activeLms from "../assets/activeLms.png";


const menuList = [
    {
        key: "dashboard",
        image: dashboardImg,
        activeImage: activeDashboardImg,
        label: "Dashboard",
        path: "/dashboard"
    },
    {
        key: "recruitment",
        image: jobImg,
        activeImage: activeJobImg,
        label: "Job",
        path: "/recruitment"
    },
    {
        key: "leave",
        image: leaveImg,
        activeImage: activeLeaveImg,
        label: "Leave",
        path: "/leave"
    },
    {
        key: "attendance",
        image: attendanceImg,
        activeImage: activeAttendanceImg,
        label: "Attendance",
        path: "/attendance"
    },
    {
        key: "payroll",
        image: payrollImg,
        activeImage: activePayrollImg,
        label: "Payroll",
        path: "/payroll"
    },
    {
        key: "inbox",
        image: profileImg,
        activeImage: activeInbox,
        label: "Inbox",
        path: "/inbox/leave"
    },
    {
        key: "lms",
        image: lms,
        activeImage: activeLms,
        label: "LMS",
        path: "/lms"
    }
    // {
    //     key: "performance",
    //     image: performanceManagement,
    //     activeImage: activePerformanceManagement,
    //     label: "Performance Management",
    //     path: "/performance-management"
    // },
    // {
    //     key: "tracking",
    //     image: tracking,
    //     activeImage: activeTracking,
    //     label: "Tracking",
    //     path: "/tracking"
    // },
]


export default menuList;
