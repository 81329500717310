import React, { useEffect, useState } from "react";
import edit from "../../../assets/edit.svg";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Steps,
  Timeline,
} from "antd";

import styles from "./EmploymentDetails.module.scss";
import request from "../../../utils/axios";
import useScreenSize from "../../../utils/getScreenSize";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import profileLocation from "../../../assets/location.svg";
import { useParams } from "react-router-dom";

function EmploymentDetails() {
  const screenSize = useScreenSize();
  const employee_number = localStorage.getItem("employee_number");
  const is_admin = localStorage.getItem("is_admin");
  const [isEdit, setIsEdit] = useState(false);
  const params = useParams();
  const TextArea = Input;
  const [userProfile, setUserProfile] = useState({
    joining_date: "",
    notice_period: "",
    employement_number: "",
    empfunction: "",
    work_location: "",
    employee_type: "",
    reporting_manager: "",
    designation: "",
    department: "",
    work_mode: "",
    org: "",
  });

  const [fullView, setFullView] = useState({
    workLocation: false,
    empType: false,
    grade: false,
    reportingManager: false,
    designation: false,
    hrbp: false,
  });

  const fetchProfileData = async () => {
    try {
      if (params.id) {
        let data = await request({
          method: "get",
          url: `admin/profile/details/${params.id}/`,
        });

        setUserProfile({
          ...data.data.data,
        });
      } else {
        let data = await request({
          method: "get",
          url: `profile/details/`,
        });

        setUserProfile({
          ...data.data.data,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const [employeeTypeList, setEmployeetTypeList] = useState([]);

  const getEmployeeTypeList = async () => {
    const data = await request({
      method: "get",
      url: `/employee-type/all`,
    });
    setEmployeetTypeList(data.data);
  };

  const [employeeList, setEmployeeList] = useState([]);

  const getEmployeeList = async () => {
    let data = await request({
      method: "get",
      url: `get-all-employees`,
    });
    setEmployeeList(data.data);
  };
  const [cityList, setCityList] = useState([]);
  const getCityList = async () => {
    const data = await request({
      method: "get",
      url: `work-location/all`,
    });
    setCityList(data.data);
  };

  const [designationList, setDesignationList] = useState([]);
  const getDesignationList = async (org) => {
    const data = await request({
      method: "get",
      url: `admin/designation/all?org=${org}`,
    });
    setDesignationList(data.data);
  };

  const [employeesFunctionList, setEmployeesFunctionList] = useState([]);

  const getEmployeeFunctionList = async (org) => {
    const data = await request({
      method: "get",
      url: `admin/employee-function/all?org=${org}`,
    });
    setEmployeesFunctionList(data.data);
  };

  const [gradeList, setGradeList] = useState([]);
  const getGradeList = async (org) => {
    const data = await request({
      method: "get",
      url: `admin/grades/all?org=${org}`,
    });
    setGradeList(data.data);
  };

  useEffect(() => {
    fetchProfileData();
    getCityList();
    getEmployeeTypeList();
    getEmployeeList();
  }, []);

  useEffect(() => {
    if (userProfile.org) {
      console.log("userProfile", userProfile.org);
      getGradeList(userProfile.org);
      getDesignationList(userProfile.org);
      getEmployeeFunctionList(userProfile.org);
    }
  }, [userProfile]);
  const onFinish = async (values) => {
    await request({
      method: "put",
      url: `profile/employee/details/update/?employement_number=${params.id}`,
      data: values,
    });

    fetchProfileData();
    setIsEdit(false);
  };

  const workLocationFullView = () => {
    return userProfile?.work_location.map((item, index) => {
      return {
        children: (
          <div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                margin: "0px",
                padding: "0px",
                color: "#4E4E4E",
              }}
            >
              {item.work_location}
              {index === 0 && (
                <CaretUpFilled
                  style={{
                    fontSize: "25px",
                    color: "#008080",
                  }}
                  onClick={() =>
                    setFullView({
                      ...fullView,
                      workLocation: !fullView.workLocation,
                    })
                  }
                />
              )}
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "600",
                margin: "0px",
                padding: "0px",
                color: "#838383",
              }}
            >
              {item.assigned_on}
            </p>
          </div>
        ),
      };
    });
  };

  const graderFullView = () => {
    return userProfile?.grade.map((item, index) => {
      return {
        children: (
          <div>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "400",
                margin: "0px",
                padding: "0px",
                color: "#4E4E4E",
              }}
            >
              {item.grade}
              {index === 0 && (
                <CaretUpFilled
                  style={{
                    fontSize: "25px",
                    color: "#008080",
                  }}
                  onClick={() =>
                    setFullView({ ...fullView, grade: !fullView.grade })
                  }
                />
              )}
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "600",
                margin: "0px",
                padding: "0px",
                color: "#838383",
              }}
            >
              {item.assigned_on}
            </p>
          </div>
        ),
      };
    });
  };

  const reportingManagerFullView = () => {
    return userProfile?.reporting_manager?.map((item, index) => {
      return {
        children: (
          <div>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "400",
                margin: "0px",
                padding: "0px",
                color: "#4E4E4E",
              }}
            >
              {item?.reporting_manager}
              {index === 0 && (
                <CaretUpFilled
                  style={{
                    fontSize: "25px",
                    color: "#008080",
                  }}
                  onClick={() =>
                    setFullView({
                      ...fullView,
                      reportingManager: !fullView.reportingManager,
                    })
                  }
                />
              )}
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "600",
                margin: "0px",
                padding: "0px",
                color: "#838383",
              }}
            >
              {item?.assigned_on}
            </p>
          </div>
        ),
      };
    });
  };

  const designationFullView = () => {
    return userProfile?.designation.map((item, index) => {
      return {
        children: (
          <div>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "400",
                margin: "0px",
                padding: "0px",
                color: "#4E4E4E",
              }}
            >
              {item.designation}
              {index === 0 && (
                <CaretUpFilled
                  style={{
                    fontSize: "25px",
                    color: "#008080",
                  }}
                  onClick={() =>
                    setFullView({
                      ...fullView,
                      designation: !fullView.designation,
                    })
                  }
                />
              )}
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "600",
                margin: "0px",
                padding: "0px",
                color: "#838383",
              }}
            >
              {item.assigned_on}
            </p>
          </div>
        ),
      };
    });
  };

  const hrbpFullView = () => {
    return userProfile?.hrbp.map((item, index) => {
      return {
        children: (
          <div>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "400",
                margin: "0px",
                padding: "0px",
                color: "#4E4E4E",
              }}
            >
              {item.hrbp}
              {index === 0 && (
                <CaretUpFilled
                  style={{
                    fontSize: "25px",
                    color: "#008080",
                  }}
                  onClick={() =>
                    setFullView({ ...fullView, hrbp: !fullView.hrbp })
                  }
                />
              )}
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "600",
                margin: "0px",
                padding: "0px",
                color: "#838383",
              }}
            >
              {item.assigned_on}
            </p>
          </div>
        ),
      };
    });
  };

  const [editUserProfileDetails, setEditUserProfileDetails] = useState();
  const fetchEmployeeDetails = async () => {
    try {
      let data = await request({
        method: "get",
        url: `employee/employement/details/${params.id}`,
      });
      setEditUserProfileDetails(data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className={styles.employmentDetailsContainer}>
      {
        <div className={styles.employmentDetailsInnerContainer}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={styles.heading}>Employment Details</p>
            {params.id && is_admin && (
              <img
                src={edit}
                alt="edit"
                width={25}
                height={25}
                onClick={() => {
                  fetchEmployeeDetails();
                  setIsEdit(!isEdit);
                }}
              />
            )}
          </div>

          <Row>
            <Col span={8}>
              <p className={styles.keyText}>Date Of Joining</p>
            </Col>
            <Col span={16}>
              <p className={styles.valueText}>{userProfile?.joining_date}</p>
            </Col>

            <Col span={8}>
              <p className={styles.keyText}>Notice Period</p>
            </Col>
            <Col span={16}>
              <p className={styles.valueText}>{userProfile?.notice_period}</p>
            </Col>

            <Col span={8}>
              <p className={styles.keyText}>Employment No.</p>
            </Col>
            <Col span={16}>
              <p className={styles.valueText}>
                {userProfile?.employement_number}
              </p>
            </Col>

            <Col span={8}>
              <p className={styles.keyText}>Function</p>
            </Col>
            <Col span={16}>
              <p className={styles.valueText}>{userProfile?.empfunction}</p>
            </Col>

            <Col span={8}>
              <p className={styles.keyText}>Working Location</p>
            </Col>
            <Col span={16}>
              {fullView.workLocation ? (
                <Timeline items={workLocationFullView()} />
              ) : (
                <div style={{ display: "flex", gap: "10px" }}>
                  <img src={profileLocation} alt="" />
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      margin: "0px",
                      padding: "0px",
                      color: "#4E4E4E",
                    }}
                  >
                    {userProfile?.work_location[0]?.work_location}
                  </p>
                  <CaretDownFilled
                    style={{
                      fontSize: "25px",
                      color: "#008080",
                    }}
                    onClick={() =>
                      setFullView({
                        ...fullView,
                        workLocation: !fullView.workLocation,
                      })
                    }
                  />
                </div>
              )}
            </Col>
            <Col span={8}>
              <p className={styles.keyText}>Employment Type</p>
            </Col>
            <Col span={16}>
              <p className={styles.valueText}>{userProfile?.employee_type}</p>
            </Col>

            <Col span={8}>
              <p className={styles.keyText}>Grade</p>
            </Col>
            <Col span={16}>
              {fullView.grade ? (
                <Timeline items={graderFullView()} />
              ) : (
                <div style={{ display: "flex", gap: "10px" }}>
                  {/* <img src={profileLocation} alt="" /> */}
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      margin: "0px",
                      padding: "0px",
                      color: "#4E4E4E",
                    }}
                  >
                    {userProfile?.grade && userProfile?.grade[0]?.grade}
                  </p>
                  <CaretDownFilled
                    style={{
                      fontSize: "25px",
                      color: "#008080",
                    }}
                    onClick={() =>
                      setFullView({ ...fullView, grade: !fullView.grade })
                    }
                  />
                </div>
              )}
            </Col>

            <Col span={8}>
              <p className={styles.keyText}>Reporting Manager</p>
            </Col>
            <Col span={16}>
              {userProfile?.reporting_manager.length !== 0 && (
                <>
                  {fullView.reportingManager ? (
                    <Timeline items={reportingManagerFullView()} />
                  ) : (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          margin: "0px",
                          padding: "0px",
                          color: "#4E4E4E",
                        }}
                      >
                        {userProfile?.reporting_manager[0]?.reporting_manager}
                      </p>
                      <CaretDownFilled
                        style={{
                          fontSize: "25px",
                          color: "#008080",
                        }}
                        onClick={() =>
                          setFullView({
                            ...fullView,
                            reportingManager: !fullView.reportingManager,
                          })
                        }
                      />
                    </div>
                  )}
                </>
              )}
            </Col>

            <Col span={8}>
              <p className={styles.keyText}>Designation</p>
            </Col>
            <Col span={16}>
              {/* <p className={styles.valueText}>{userProfile?.designation}</p> */}
              {fullView.designation ? (
                <Timeline items={designationFullView()} />
              ) : (
                <div style={{ display: "flex", gap: "10px" }}>
                  {/* <img src={profileLocation} alt="" /> */}
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      margin: "0px",
                      padding: "0px",
                      color: "#4E4E4E",
                    }}
                  >
                    {userProfile?.designation[0]?.designation}
                  </p>
                  <CaretDownFilled
                    style={{
                      fontSize: "25px",
                      color: "#008080",
                    }}
                    onClick={() =>
                      setFullView({
                        ...fullView,
                        designation: !fullView.designation,
                      })
                    }
                  />
                </div>
              )}
            </Col>

            <Col span={8}>
              <p className={styles.keyText}>HRBP</p>
            </Col>
            <Col span={16}>
              {userProfile?.reporting_manager.length !== 0 && (
                <>
                  {fullView.hrbp ? (
                    <Timeline items={hrbpFullView()} />
                  ) : (
                    <div style={{ display: "flex", gap: "10px" }}>
                      {/* <img src={profileLocation} alt="" /> */}
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          margin: "0px",
                          padding: "0px",
                          color: "#4E4E4E",
                        }}
                      >
                        {userProfile?.hrbp[0]?.hrbp}
                      </p>
                      <CaretDownFilled
                        style={{
                          fontSize: "25px",
                          color: "#008080",
                        }}
                        onClick={() =>
                          setFullView({ ...fullView, hrbp: !fullView.hrbp })
                        }
                      />
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
      }

      <Modal
        title="Employment Details"
        centered
        open={isEdit}
        footer={null}
        onCancel={() => setIsEdit(!isEdit)}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          // onValuesChange={handleFormChange}
          initialValues={editUserProfileDetails}
          fields={[
            {
              name: ["notice_period"],
              value: editUserProfileDetails?.notice_period,
            },
            {
              name: ["employement_number"],
              value: editUserProfileDetails?.employee_number,
            },
            {
              name: ["empfunction"],
              value: editUserProfileDetails?.empfunction,
            },
            {
              name: ["work_location"],
              value: editUserProfileDetails?.work_location,
            },
            {
              name: ["employee_type"],
              value: editUserProfileDetails?.employee_type,
            },
            {
              name: ["grade"],
              value: editUserProfileDetails?.grade,
            },
            {
              name: ["designation"],
              value: editUserProfileDetails?.designation,
            },
            {
              name: ["hrbp"],
              value: editUserProfileDetails?.hrbp,
            },
            {
              name: ["reporting_manager"],
              value: editUserProfileDetails?.reporting_manager,
            },
          ]}
        >
          <Row gutter={[0, 0]}>
            <Col lg={{ span: 24 }}>
              <Form.Item
                label="Notice Period"
                name="notice_period"
                rules={[
                  {
                    required: true,
                    message: "Please enter your notice_period!",
                  },
                ]}
              >
                <Select className="dropDown">
                  {[...Array(180)].map((_, index) => {
                    return (
                      <Select.Option key={index} value={index + 1}>
                        {index + 1} Days
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={{ span: 24 }}>
              <Form.Item label="Employment No." name="employement_number">
                <Input size="large" disabled />
              </Form.Item>
            </Col>

            <Col lg={{ span: 24 }}>
              <Form.Item label="Function" name="empfunction">
                <Select className="dropDown">
                  {employeesFunctionList.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col lg={{ span: 24 }}>
              <Form.Item label="Work Location" name="work_location">
                <Select
                  // className='dropDown'
                  size="large"
                >
                  {cityList.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                  {/* <Select.Option value="Gurugram">Gurugram</Select.Option>
                  <Select.Option value="Delhi">Delhi</Select.Option> */}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={{ span: 24 }}>
              <Form.Item
                label="Employee Type"
                name="employee_type"
                rules={[
                  {
                    required: true,
                    message: "Please enter your employee_type!",
                  },
                ]}
              >
                <Select size="large">
                  {employeeTypeList?.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col lg={{ span: 24 }}>
              <Form.Item label="Grade" name="grade">
                <Select className="dropDown">
                  {gradeList.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={{ span: 24 }}>
              <Form.Item label="Reporting Manager" name="reporting_manager">
                <Select className="dropDown">
                  {employeeList?.map((item, index) => {
                    return (
                      <Select.Option
                        key={index}
                        value={item.employement_number}
                      >
                        {item.employee_name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={{ span: 24 }}>
              <Form.Item
                label="Designation"
                name="designation"
                rules={[
                  {
                    required: true,
                    message: "Please enter your designation!",
                  },
                ]}
              >
                <Select className="dropDown">
                  {designationList.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col lg={{ span: 24 }}>
              <Form.Item
                label="HRBP"
                name="hrbp"
                rules={[
                  {
                    required: true,
                    message: "Please enter HRBP!",
                  },
                ]}
              >
                <Select className="dropDown">
                  {employeeList?.map((item, index) => {
                    return (
                      <Select.Option
                        key={index}
                        value={item.employement_number}
                      >
                        {item.employee_name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Button
            htmlType="submit"
            style={{
              width: "100%",
              height: "47px",
              background: "#008080",
              color: "white",
            }}
          >
            Save
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default EmploymentDetails;
