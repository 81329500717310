import React, { useEffect, useRef, useState } from 'react'
import { Tabs } from 'antd'
import AttendanceDashBoardView from '../DashBoard/DashBoard'
import AttendancePolicyFullView from '../AttendancePolicyFullView/AttendancePolicyFullView'
import EmployeeAttendancePolicyManagerFullView from '../EmployeeAttendancePolicyManagerFullView/EmployeeAttendancePolicyManagerFullView'
import AttendanceReportFullView from '../AttendanceReportFullView/AttendanceReportFullView'
import OvertimeReport from '../OvertimeReport/OvertimeReport'
import { useParams } from 'react-router-dom'

function DashBoard() {
  const ref = useRef(null);
  const changeTabsValue = (e) => {
    setTabsValue(e)
  }

  const params = useParams()

  const items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <AttendanceDashBoardView changeTabsValue={changeTabsValue} />,
    },
    {
      key: '2',
      label: 'Attendance policy',
      children: <AttendancePolicyFullView />,
    },
    {
      key: '3',
      label: 'Attendance Report',
      children: <AttendanceReportFullView />,
    },
    {
      key: '4',
      label: 'Employee Attendance Manager',
      children: <EmployeeAttendancePolicyManagerFullView />,
    },
    {
      key: '5',
      label: "Overtime Report",
      children: <OvertimeReport />
    }
  ];
  const [tabsValue, setTabsValue] = useState("1")

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: "instant" });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setTabsValue(params?.componentId)
  }, []);


  return (
    <div ref={ref}>
      <Tabs
        activeKey={tabsValue}
        items={items}
        onChange={changeTabsValue}
      />
    </div>
  )
}

export default DashBoard