import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
    Button,
    Col,
    Form,
    Input,
    Row,
    Select
} from 'antd';

import "./PostJob.scss"
import mobileStyle from './Mobile.module.scss'
import { useNavigate, useParams } from 'react-router-dom';

import request from '../../../../utils/axios';
import moment from 'moment';
import JoditEditor from 'jodit-react';
import getScreenSize from "../../../../utils/getScreenSize";
import back from "../../../../assets/Recruitment/back.svg";





function PostJob() {
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const navigate = useNavigate();
    let { id } = useParams();
    const screenSize = getScreenSize();

    const [loading, setLoading] = useState(true);

    const [jobDetails, setJobDetails] = useState({
        job_title: "",
        posting_date: "",
        location: [],
        job_function: "",
        industry: "",
        job_type: "",
        job_description: "",
    });

    const editor = useRef(null);
    const [content, setContent] = useState('');

    const editorConfig = {
        enableDragAndDropFileToServer: false,
        spellcheck: true,
        toolbarAdaptive: false,
        fontSize: true,
        buttons: [
            'bold',
            'italic',
            'underline',
            '|',
            'fontsize', // This will add the font size dropdown to the toolbar
        ],
    };



    const getJobDetails = async () => {
        const data = await request({
            method: "get",
            url: `recruitment/job-detail-to-edit/${id}`,
        })
        setJobDetails(data.data.data)
    }

    const [industryList, setIndustryList] = useState([]);

    const getIndustryList = async () => {
        const data = await request({
            method: "get",
            url: `/recruitment/industry/all/`,
        })

        setIndustryList(data.data)
    }

    const [cityList, setCityList] = useState([]);
    const getCityList = async () => {

        const data = await request({
            method: "get",
            url: `/recruitment/location/all/`,
        })
        setCityList(data.data)
    }

    const [employeeFunctionList, setEmployeeFunctionList] = useState([]);

    const getEmployeeFunctionList = async () => {
        const data = await request({
            method: "get",
            url: `/recruitment/employee_types/`,
        })
        setEmployeeFunctionList(data.data)
    }

    const [jobTypeList, setJobTypeList] = useState([]);
    const getJobTypeList = async () => {
        const data = await request({
            method: "get",
            url: `/recruitment/job_types/`,
        })
        setJobTypeList(data.data)
    }
    useEffect(() => {
        if (id) {
            getJobDetails();
        }
        getIndustryList();
        // getCityList();
        getEmployeeFunctionList();
        getJobTypeList();
        getCityList()
    }, [])

    useEffect(() => {
        setLoading(false)
    }, [jobDetails])

    const updateJobDetails = async () => {
        const data = await request({
            method: "put",
            url: `/recruitment/jobs/${id}/update/`,
            data: {
                ...jobDetails,
                user_id: localStorage.getItem("user_id")
            }
        })
        navigate("/admin/recruitment", { replace: true })
    }

    const handleFormChange = (changedValues, allValues) => {
        console.log("allValues......", allValues);
        setJobDetails(allValues);
    };

    const onFinish = async (values) => {
        const posting_date = moment(values.posting_date).format('YYYY-MM-DD');
        values = { ...values, posting_date }

        const data = await request({
            method: "post",
            url: `/recruitment/create-job`,
            data: {
                ...values
            }
        })
        navigate("/admin/recruitment", { replace: true })
    };


    return (
        <div>

            {/* for mobile */}
            {screenSize.width < 768 ?
                <>
                    <div>

                        {
                            loading
                                ?
                                <>loading</>
                                :
                                <div className={mobileStyle.postJobContainerMobile} >
                                    <h1 className={mobileStyle.postJobHeadingMobile}>Post a Job</h1>
                                    <div className='formContainer'>
                                        <Form
                                            layout="vertical"
                                            onFinish={onFinish}
                                            autoComplete="true"
                                            onValuesChange={handleFormChange}
                                            form={form}
                                            fields={[
                                                {
                                                    name: ["job_title"],
                                                    value: jobDetails.job_title,
                                                },
                                                {
                                                    name: ["posting_date"],
                                                    value: jobDetails.posting_date
                                                },
                                                {
                                                    name: ["location"],
                                                    value: jobDetails.location
                                                },
                                                {
                                                    name: ["job_function"],
                                                    value: jobDetails.job_function
                                                },
                                                {
                                                    name: ["industry"],
                                                    value: jobDetails.industry
                                                },
                                                {
                                                    name: ["job_type"],
                                                    value: jobDetails.job_type
                                                },
                                                {
                                                    name: ["job_description"],
                                                    value: jobDetails.job_description
                                                }
                                            ]}
                                        >
                                            <Row gutter={[16, 16]}>
                                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                                    <Form.Item
                                                        label="Job title"
                                                        name="job_title"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter your title!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            className='inputField'
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                                    <Form.Item
                                                        label="Industry"
                                                        name="industry"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter your industry!',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className='dropDown'
                                                        >
                                                            {
                                                                industryList?.map((item, index) => {
                                                                    return (
                                                                        <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                                    <Form.Item
                                                        label="Job Type"
                                                        name="job_type"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter your job_type!',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className='dropDown'
                                                        >
                                                            {
                                                                jobTypeList.map((item, index) => {
                                                                    return (
                                                                        <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                                    <Form.Item
                                                        label="Location"
                                                        name="location"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter your location!',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            className='multiSelectDropDown'
                                                            showSearch={false}
                                                        >
                                                            {
                                                                cityList.map((item, index) => {
                                                                    return (
                                                                        <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>


                                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                                    <Form.Item
                                                        label="Function"
                                                        name="job_function"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter your job_function!',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className='dropDown'
                                                        >
                                                            {
                                                                employeeFunctionList.map((item, index) => {
                                                                    return (
                                                                        <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={{ span: 24 }}  >
                                                    <Form.Item
                                                        label="Job Description"
                                                        name="job_description"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter your Job Description!',
                                                            },
                                                        ]}
                                                    >
                                                        <div className={mobileStyle.joditEditorContainerMobile}>
                                                            <JoditEditor
                                                                ref={editor}
                                                                config={editorConfig}
                                                                value={jobDetails.job_description}
                                                                tabIndex={1} // tabIndex of textarea
                                                                onBlur={(content) => setJobDetails({ ...jobDetails, job_description: content })}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <div className='buttonContainer'>
                                                {
                                                    id
                                                        ?
                                                        <Button className='button' onClick={() => updateJobDetails()} >Update</Button>
                                                        :
                                                        <Button className='button' htmlType='submit' >Post a job</Button>
                                                }
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                        }
                    </div>

                </>
                :
                //  desktop
                <div>
                    <div style={{ paddingLeft: '50px', paddingTop: "30px" }}>
                        <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                            <img src={back} alt="back" />
                            <span style={{ paddingLeft: "10px" }}>Back</span>
                        </p>
                        <h1 className='postJobHeading'>Post a Job</h1>
                    </div>
                    <div style={{ background: 'white', margin: '50px', marginTop: "10px" }}>

                        <div className='postJobContainer'>

                            <div className='formContainer'>
                                <Form
                                    layout="vertical"
                                    onFinish={onFinish}
                                    autoComplete="true"
                                    onValuesChange={handleFormChange}
                                    form={form}
                                    fields={[
                                        {
                                            name: ["job_title"],
                                            value: jobDetails.job_title,
                                        },
                                        {
                                            name: ["posting_date"],
                                            value: jobDetails.posting_date
                                        },
                                        {
                                            name: ["location"],
                                            value: jobDetails.location
                                        },
                                        {
                                            name: ["job_function"],
                                            value: jobDetails.job_function
                                        },
                                        {
                                            name: ["industry"],
                                            value: jobDetails.industry
                                        },
                                        {
                                            name: ["job_type"],
                                            value: jobDetails.job_type
                                        },
                                        {
                                            name: ["job_description"],
                                            value: jobDetails.job_description
                                        }
                                    ]}
                                >
                                    <Row>
                                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                            <Form.Item
                                                label="Job title"
                                                name="job_title"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your title!',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className='inputField'
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                            <Form.Item
                                                label="Industry"
                                                name="industry"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your industry!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className='dropDown'
                                                >
                                                    {
                                                        industryList?.map((item, index) => {
                                                            return (
                                                                <Select.Option key={index}
                                                                    value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                            <Form.Item
                                                label="Job Type"
                                                name="job_type"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your job_type!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className='dropDown'
                                                >
                                                    {
                                                        jobTypeList.map((item, index) => {
                                                            return (
                                                                <Select.Option key={index}
                                                                    value={item.value}>{item.label}</Select.Option>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                            <Form.Item
                                                label="Location"
                                                name="location"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your location!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    className='multiSelectDropDown'
                                                    showSearch={false}
                                                    size='large'
                                                >
                                                    {
                                                        cityList.map((item, index) => {
                                                            return (
                                                                <Select.Option key={index}
                                                                    value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                            <Form.Item
                                                label="Function"
                                                name="job_function"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your job_function!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className='dropDown'
                                                >
                                                    {
                                                        employeeFunctionList.map((item, index) => {
                                                            return (
                                                                <Select.Option key={index}
                                                                    value={item.value}>{item.label}</Select.Option>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 24 }}>
                                            <Form.Item
                                                label="Job Description"
                                                name="job_description"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your Job Description!',
                                                    },
                                                ]}
                                            >
                                                <div className='joditEditorContainer'>
                                                    <JoditEditor
                                                        ref={editor}
                                                        config={editorConfig}
                                                        value={jobDetails.job_description}
                                                        tabIndex={1} // tabIndex of textarea
                                                        onBlur={(content) => setJobDetails({
                                                            ...jobDetails,
                                                            job_description: content
                                                        })}
                                                    />
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <div className='buttonContainer'>
                                        {
                                            id
                                                ?
                                                <Button className='button'
                                                    onClick={() => updateJobDetails()}>Update</Button>
                                                :
                                                <Button className='button' htmlType='submit'>Post a job</Button>
                                        }
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>}

        </div>
    )
}

export default PostJob
