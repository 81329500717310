import { Button, Col, Form, Input, Row, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import request from '../../../utils/axios'
import { useNavigate } from 'react-router-dom';

function DocumentsHrLetterForm({ modelFor }) {
    const [form] = Form.useForm()
    const navigate = useNavigate();
    const [File, setFile] = useState(null)

    const onFinish = (values) => {
        const formData = new FormData();

        try {
            formData.append("name", values.name);
            formData.append("file_type", values.file_type);
            formData.append("document_for", values.document_for);
            formData.append("document", File);

            request({
                method: "post",
                url: `/documentation/hr/letters/upload`,
                data: formData
            })
        }
        catch (error) {
            console.log("error")
        }
        finally {
            form.resetFields()
            setFile(null)
            navigate("/admin/docs", { replace: true })
        }

    }

    const [fileTypeList, setFileType] = useState([])
    const fetchFileType = async () => {
        try {
            const data = await request({
                method: "get",
                url: `/documentation/file_type/all?type=HRLetters`,
            })
            setFileType(data)
        }
        catch (error) {
            console.log("error");
        }
    }

    const [employeeList, setEmployeeList] = useState([])
    const fetchAllEmployeeList = async () => {

        const data = await request({
            method: "get",
            url: `/get-all-employees`,
        })
        setEmployeeList(data.data)
        // console.log("data", data);
    }


    useEffect(() => {
        fetchFileType();
        fetchAllEmployeeList();
    }, [])

    return (
        <div style={{ padding: "16px", background: "white" }}>
            <p style={{ fontSize: "20px", fontWeight: "600" }}>New HR Letter</p>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            // onValuesChange={onValuesChange}
            >
                <Row gutter={[32, 32]}>
                    <Col xs={{ span: 24 }}>
                        <Form.Item
                            label="File Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your File Name!',
                                },
                            ]}
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>
                </Row>

                <Col span={24}>
                    <Form.Item
                        label="File Type"
                        name="file_type"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your File Type!',
                            },
                        ]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            size='large'
                        >
                            {
                                fileTypeList?.map((option) => {
                                    return (
                                        <Select.Option value={option.value} >
                                            {option.label}
                                        </Select.Option>

                                    )
                                })
                            }
                        </Select>

                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Employee"
                        name="document_for"
                        rules={[
                            {
                                required: true,
                                message: 'Please select Employee!',
                            },
                        ]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            size='large'
                        >
                            {
                                employeeList?.map((item, index) => {
                                    return (
                                        <Select.Option value={item.employement_number} >
                                            {item.employee_name}
                                        </Select.Option>
                                    )
                                })
                            }

                        </Select>

                    </Form.Item>
                </Col>

                <Col span={24}>
                    <p>Upload File</p>
                    <Form.Item
                        label="Upload File"
                        name="document"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your Date (To)!',
                            },
                        ]}
                    >
                        <div className="file-upload-container">
                            <input
                                type="file"
                                id="file-upload"
                                className="input-file"
                                onChange={(e) =>
                                // setFile(e.target.files && e.target.files[0])
                                {
                                    // if (e.target.files[0] && e.target.files[0].type === 'text/csv') {
                                    //     setFile(e.target.files && e.target.files[0])
                                    // } else {
                                    //     // Reset the file state and show an error message
                                    //     setFile(null);
                                    //     alert('Please upload a CSV file.');
                                    // }

                                    const selectedFile = e.target.files[0];
                                    const maxSizeInBytes = 5 * 1024 * 1024;

                                    if (selectedFile && selectedFile.type === 'application/pdf') {
                                        if (selectedFile.size <= maxSizeInBytes) {
                                            setFile(e.target.files && selectedFile)
                                        }
                                        else {
                                            setFile(null);
                                            alert('File size exceeds the limit of 5MB.');
                                        }
                                    } else {
                                        // Reset the file state and show an error message
                                        setFile(null);
                                        alert('Please upload a PDF file.');
                                    }
                                }
                                }
                            />
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                <label htmlFor="file-upload" className="file-upload-label">

                                </label>
                                {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                <p style={{ zIndex: 1, margin: "0px" }}>{File ? File.name : 'Drop Your File Here'}</p>
                            </div>
                        </div>
                    </Form.Item>
                </Col>

                <Button
                    htmlType='submit'
                    style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                >
                    Submit
                </Button>
            </Form>
        </div>
    )
}

export default DocumentsHrLetterForm