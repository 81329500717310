import React, { useState, useEffect } from 'react'
import LeaveDetail from '../LeaveDetail/LeaveDetail'
import styles from './LeaveList.module.scss'
import request from '../../../../utils/axios';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import getScreenSize from "../../../../utils/getScreenSize";
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';

// Import statements remain the same...

function LeaveList() {
    const [leaveData, setLeaveData] = useState([]);
    const [isListView, setIsListView] = useState(true);
    const [selectedLeaveId, setSelectedLeaveId] = useState(null);
    const navigate = useNavigate();
    const { width } = getScreenSize();

    useEffect(() => {
        fetchMyTeamAppliedLeaveDetails();
    }, []);

    const fetchMyTeamAppliedLeaveDetails = async () => {
        try {
            const data = await request({
                method: "get",
                url: `inbox/leave-applications/all/?page=1&page_size=10`,
            });

            setLeaveData(data?.pagination_data?.data);
        } catch (error) {
            console.log(error);
        }
    };

    const rejectOrApproveLeave = async (id, isRejected) => {
        try {
            await request({
                method: "post",
                url: `leave/my-team/leave_application/approval/?id=${id}`,
                data: {
                    is_rejected: isRejected
                }
            });

            setTimeout(() => {
                fetchMyTeamAppliedLeaveDetails();
            }, 1000);

            if (isRejected)
                toast.success("Leave Rejected", {
                    position: "bottom-left",
                });
            else {
                toast.success("Leave Approved", {
                    position: "bottom-left",
                });
            }

        } catch (error) {
            console.log(error);
            if (isRejected) {
                toast.error("Leave could not be rejected, please try after some time", {
                    position: "bottom-left",
                });
            } else {
                toast.error("Leave could not be approved, please try after some time", {
                    position: "bottom-left",
                });
            }

        }
    };

    const handleLeaveDetailClick = (id) => {
        setSelectedLeaveId(id);
        setIsListView(false);
    };

    const renderUI = (dataList) => {
        return dataList?.map((leave) => {
            return (<div>
                <div key={leave.id} className={styles.container} onClick={() => navigate(`/inbox/leave/detail/` + leave.id, { replace: true })}>

                    <div className={styles.userContainer}>
                        <p className={styles.userName}>{leave.employee_name}</p>
                        <p className={styles.date}>{leave.created_at}</p>
                    </div>

                    <p className={styles.dateWithDay}>{leave.start_date} ({leave.leave_day} Day)</p>
                    <p className={styles.leaveType}>{leave.leave_type}</p>

                    <div className={styles.actionsBox}>
                        <button
                            className={styles.approveBtn}
                            onClick={(e) => {
                                rejectOrApproveLeave(leave.id, false);
                                e.stopPropagation()
                            }}
                        >
                            Approve
                        </button>
                        <button
                            className={styles.rejectBtn}
                            onClick={(e) => {
                                rejectOrApproveLeave(leave.id, true);
                                e.stopPropagation()
                            }}
                        >
                            Reject
                        </button>
                    </div>
                </div>
                <hr />
            </div>
            )
        }
        )
    }

    const renderUIDesktop = (dataList) => {
        return dataList?.map((leave) => {
            return (
                <div key={leave.id} className={styles.container} onClick={() => navigate(`/inbox/leave/detail/` + leave.id, { replace: true })}>
                    <div className={styles.userContainer}>
                        <div className={styles.userBox}>
                            <img
                                loading="lazy"
                                src={leave.profile_image}
                                alt={leave.employee_name}
                                className={styles.userImg}
                            />
                            <div className={styles.userDetailBox}>
                                <div className={styles.userName}>
                                    {leave.employee_name}
                                </div>
                                <div className={styles.dateWithDay}>
                                    {leave.start_date} ({leave.leave_day} Day)
                                </div>
                            </div>
                        </div>
                        <div className={styles.date}>{leave.created_at}</div>
                    </div>
                    <div className={styles.leaveType}>{leave.leave_type}</div>
                    <div className={styles.actionsBox}>
                        <button
                            className={styles.approveBtn}
                            onClick={(e) => {
                                rejectOrApproveLeave(leave.id, false);
                                e.stopPropagation()
                            }}
                        >
                            Approve
                        </button>
                        <button
                            className={styles.rejectBtn}
                            onClick={(e) => {
                                rejectOrApproveLeave(leave.id, true);
                                e.stopPropagation()
                            }}
                        >
                            Reject
                        </button>
                    </div>
                </div>
            )
        })
    }

    return (
        <div>
            {
                width < 768
                    // mobile view
                    ?
                    <div>
                        <InfiniteScrollComponent
                            apiEndpoint="inbox/leave-applications/all/"
                            renderUI={renderUI}
                            initialPage={1}
                        />
                        {/* {leaveData.map((leave) => (
                            <div>
                                <div key={leave.id} className={styles.container} onClick={() => navigate(`/inbox/leave/detail/` + leave.id, { replace: true })}>

                                    <div className={styles.userContainer}>
                                        <p className={styles.userName}>{leave.employee_name}</p>
                                        <p className={styles.date}>{leave.created_at}</p>
                                    </div>

                                    <p className={styles.dateWithDay}>{leave.start_date} ({leave.leave_day} Day)</p>
                                    <p className={styles.leaveType}>{leave.leave_type}</p>

                                    <div className={styles.actionsBox}>
                                        <button
                                            className={styles.approveBtn}
                                            onClick={(e) => {
                                                rejectOrApproveLeave(leave.id, false);
                                                e.stopPropagation()
                                            }}
                                        >
                                            Approve
                                        </button>
                                        <button
                                            className={styles.rejectBtn}
                                            onClick={(e) => {
                                                rejectOrApproveLeave(leave.id, true);
                                                e.stopPropagation()
                                            }}
                                        >
                                            Reject
                                        </button>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        ))} */}
                    </div>
                    :
                    // desktop view
                    <>
                        {/* <input
                            type="text"
                            placeholder="Search"
                            className={styles.search}
                        /> */}
                        <InfiniteScrollComponent
                            apiEndpoint="inbox/leave-applications/all/"
                            renderUI={renderUIDesktop}
                            initialPage={1}
                        />
                    </>
            }

        </div>
    );
}

export default LeaveList;