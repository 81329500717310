import React from 'react';
import { Table, Pagination } from 'antd';

const CommonTable = ({ columns, data, pagination, setPagination  , handleRowClick}) => {

    const paginationConfig = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
        onChange: (page, pageSize) => {
            setPagination({
                ...pagination,
                current: page
            });
        },
    };

    return (
        <div>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                onRow={record => ({
                    onClick: () => {
                        handleRowClick(record);
                    }
                })}
            />
            <center>
                <div style={{ marginTop: "20px" }}>
                    <Pagination
                        {...paginationConfig}
                        showTotal={(total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`
                        }
                        cellFontSize={16}
                    />
                </div>
            </center>
        </div>
    );
};

export default CommonTable;