import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";

import "./EmploymentDetails.scss";
import { setEmploymentDetails } from "../../../redux/reducer/EmployeeReducer";
import request from "../../../utils/axios";
import { toast } from "react-toastify";

function EmployementDetails({ prev, next }) {
  const employmentDetails = useSelector(
    (state) => state.employee.employmentDetails
  );
  const orgDetails = useSelector((state) => state.employee.org);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [form] = Form.useForm();

  const domainCheck = async (official_email) => {
    try {
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    }
  };

  const onFinish = async (values) => {
    try {
      const data = await request({
        method: "post",
        url: `admin/employee/add/domain/check?org=${orgDetails}`,
        data: {
          official_email: (values.official_email).toLocaleLowerCase(),
        },
      });
      dispatch(setEmploymentDetails(values));
      next();
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message, {
        position: "bottom-left",
      });
    }
  };

  const [cityList, setCityList] = useState([]);
  const getCityList = async () => {
    const data = await request({
      method: "get",
      url: `admin/employee/work-location/all?org=${orgDetails}`,
    });
    setCityList(data.data);
  };

  const [designationList, setDesignationList] = useState([]);
  const getDesignationList = async () => {
    const data = await request({
      method: "get",
      url: `admin/designation/all?org=${orgDetails}`,
    });
    setDesignationList(data.data);
  };

  const [gradeList, setGradeList] = useState([]);
  const getGradeList = async () => {
    const data = await request({
      method: "get",
      url: `admin/grades/all?org=${orgDetails}`,
    });
    setGradeList(data.data);
  };

  const [employeesFunctionList, setEmployeesFunctionList] = useState([]);

  const getEmployeeFunctionList = async () => {
    const data = await request({
      method: "get",
      url: `admin/employee-function/all?org=${orgDetails}`,
    });
    setEmployeesFunctionList(data.data);
  };

  const [employeeTypeList, setEmployeetTypeList] = useState([]);

  const getEmployeeTypeList = async () => {
    const data = await request({
      method: "get",
      url: `/employee-type/all`,
    });
    setEmployeetTypeList(data.data);
  };

  const [jobTypeList, setJobTypeList] = useState([]);
  const getJobTypeList = async () => {
    const data = await request({
      method: "get",
      url: `/work-policies/all`,
    });
    setJobTypeList(data.data);
  };

  const [employeeList, setEmployeeList] = useState([]);

  const getEmployeeList = async () => {
    let data = await request({
      method: "get",
      url: `admin/employee/employees/all?org=${orgDetails}`,
    });
    setEmployeeList(data.data);
  };

  const [attendancePolicy, setAttendancePolicy] = useState([]);

  const getAttendancePolicy = async () => {
    let data = await request({
      method: "get",
      url: `admin/employee/attendance-policy/all?org=${orgDetails}`,
    });
    setAttendancePolicy(data);
  };

  const [leavePolicy, setLeavePolicy] = useState([]);

  const getLeavePolicy = async () => {
    let data = await request({
      method: "get",
      url: `admin/employee/leave-policy/all?org=${orgDetails}`,
    });
    setLeavePolicy(data.data);
  };

  const [salaryStructure, setSalaryStructure] = useState([]);
  const getSalaryStructure = async () => {
    let data = await request({
      method: "get",
      url: `admin/employee/salary-structure/all?org=${orgDetails}`,
    });
    setSalaryStructure(data);
  };

  const [departmentList, setDepartmentList] = useState([]);
  const getDepartment = async () => {
    let data = await request({
      method: "get",
      url: `admin/employee/department/all?org=${orgDetails}`,
    });
    setDepartmentList(data.data);
  };

  useEffect(() => {
    getDesignationList();
    getEmployeeList();
    getDepartment();
    getEmployeeFunctionList();
    getCityList();
    getEmployeeTypeList();
    getJobTypeList();
    getAttendancePolicy();
    getLeavePolicy();
    getSalaryStructure();
    getGradeList();
  }, []);

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: "instant" });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const [filteredDesignations, setFilteredDesignations] = useState();

  const handleSearch = (value) => {
    console.log(value, designationList);
    if (value) {
      const filteredList = designationList.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredDesignations(filteredList);
    } else {
      setFilteredDesignations(designationList);
    }
  };

  useEffect(() => {
    setFilteredDesignations(designationList);
  }, [designationList]);

  const [filteredEmployees, setFilteredEmployees] = useState(employeeList);

  const handleEmployeeSearch = (value) => {
    if (value) {
      const filteredList = employeeList.filter((item) =>
        item.employee_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredEmployees(filteredList);
    } else {
      setFilteredEmployees(employeeList);
    }
  };

  useEffect(() => {
    setFilteredEmployees(employeeList);
  }, [employeeList]);

  const [filteredDepartments, setFilteredDepartments] =
    useState(departmentList);

  const handleDepartmentSearch = (value) => {
    if (value) {
      const filteredList = departmentList.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredDepartments(filteredList);
    } else {
      setFilteredDepartments(departmentList);
    }
  };

  useEffect(() => {
    setFilteredDepartments(departmentList);
  }, [departmentList]);

  const [filteredFunctions, setFilteredFunctions] = useState(
    employeesFunctionList
  );

  const handleFunctionSearch = (value) => {
    if (value) {
      const filteredList = employeesFunctionList.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredFunctions(filteredList);
    } else {
      setFilteredFunctions(employeesFunctionList);
    }
  };

  useEffect(() => {
    setFilteredFunctions(employeesFunctionList);
  }, [employeesFunctionList]);

  const [filteredCities, setFilteredCities] = useState(cityList);

  const handleCitySearch = (value) => {
    if (value) {
      const filteredList = cityList.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCities(filteredList);
    } else {
      setFilteredCities(cityList);
    }
  };

  useEffect(() => {
    setFilteredCities(cityList);
  }, [cityList]);

  const [filteredGrades, setFilteredGrades] = useState(gradeList);

  const handleGradeSearch = (value) => {
    if (value) {
      const filteredList = gradeList.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredGrades(filteredList);
    } else {
      setFilteredGrades(gradeList);
    }
  };

  useEffect(() => {
    setFilteredGrades(gradeList);
  }, [gradeList]);

  const [filteredHRBPs, setFilteredHRBPs] = useState(employeeList);

  const handleHRBPSearch = (value) => {
    if (value) {
      const filteredList = employeeList.filter((item) =>
        item.employee_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredHRBPs(filteredList);
    } else {
      setFilteredHRBPs(employeeList);
    }
  };

  useEffect(() => {
    setFilteredHRBPs(employeeList);
  }, [employeeList]);

  return (
    <div className="employeeEmploymentDetailsContainer" ref={ref}>
      {/* <p className="heading">Employment Details</p> */}
      {/* <hr /> */}
      <br />
      <br />
      <div>
        <Form
          form={form}
          layout="vertical"
          initialValues={employmentDetails}
          onFinish={onFinish}
          autoComplete="true"
        >
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Offical Email"
                name="official_email"
                rules={[
                  {
                    required: true,
                    message: "Please enter employee Offical Email",
                  },
                ]}
              >
                <Input
                  className="inputField"
                  placeholder="Offical Email"
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Designation"
                name="designation"
                rules={[
                  {
                    required: true,
                    message: "Please enter Designation",
                  },
                ]}
              >
                <Select
                  className="dropDown"
                  showSearch
                  onSearch={handleSearch}
                  filterOption={false} // To prevent default filtering
                  style={{ width: "100%" }}
                  placeholder="Select Designation"
                >
                  <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Search or Select Designation</p></Select.Option>
                  {filteredDesignations?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item label="Reporting Manager" name="reporting_manager">
                <Select
                  className="dropDown"
                  showSearch
                  onSearch={handleEmployeeSearch}
                  filterOption={false} // To prevent default filtering
                  style={{ width: "100%" }}
                  placeholder="Select Reporting Manager"
                >
                  <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Search or Select Reporting Manager</p></Select.Option>
                  {filteredEmployees.map((item, index) => (
                    <Select.Option key={index} value={item.employement_number}>
                      {item.employee_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Probation Period"
                name="probation_period"
              >
                <Select className="dropDown"
                  placeholder="Select Probation Period"
                >
                  <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Select Probation Period</p></Select.Option>
                  {[...Array(180)].map((_, index) => {
                    return (
                      <Select.Option key={index} value={index + 1}>
                        {index + 1} Days
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            {/* </Row>
                    <Row> */}
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Date Of joining"
                name="joining_date"
                rules={[
                  {
                    required: true,
                    message: "Please enter Date Of Joining",
                  },
                ]}
              >
                <DatePicker
                  className="inputField"
                  initialValues={
                    employmentDetails?.joining_date
                      ? dayjs(employmentDetails["joining_date"], "YYYY-MM-DD")
                      : null
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Notice Period"
                name="notice_period"
                rules={[
                  {
                    required: true,
                    message: "Please enter Notice Period",
                  },
                ]}
              >
                <Select className="dropDown">
                  <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Select Notice Period</p></Select.Option>
                  {[...Array(180)].map((_, index) => {
                    return (
                      <Select.Option key={index} value={index + 1}>
                        {index + 1} Days
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            {/* </Row>
                    <Row> */}
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Department"
                name="department"
                rules={[
                  {
                    required: true,
                    message: "Please enter Department",
                  },
                ]}
              >
                <Select
                  className="dropDown"
                  showSearch
                  onSearch={handleDepartmentSearch}
                  filterOption={false} // To prevent default filtering
                  style={{ width: "100%" }}
                >
                  <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Select Department</p></Select.Option>
                  {filteredDepartments.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Function"
                name="empfunction"
                rules={[
                  {
                    required: true,
                    message: "Please enter Function",
                  },
                ]}
              >
                <Select
                  className="dropDown"
                  showSearch
                  onSearch={handleFunctionSearch}
                  filterOption={false} // To prevent default filtering
                  style={{ width: "100%" }}
                  placeholder="Select Function"
                >
                  <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Select Function</p></Select.Option>
                  {filteredFunctions.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                {/* <Input className='inputField' /> */}
              </Form.Item>
            </Col>
            {/* </Row>
                    <Row> */}
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Employee Type"
                name="employee_type"
                rules={[
                  {
                    required: true,
                    message: "Please enter Employee Type",
                  },
                ]}
              >
                <Select className="dropDown"
                  placeholder="Select Employee Type"
                >
                  <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Select Employee Type</p></Select.Option>
                  {employeeTypeList.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Work Location"
                name="work_location"
                rules={[
                  {
                    required: true,
                    message: "Please enter Work Location",
                  },
                ]}
              >
                <Select
                  className="dropDown"
                  showSearch
                  onSearch={handleCitySearch}
                  filterOption={false} // To prevent default filtering
                  style={{ width: "100%" }}
                  placeholder="Select Work Location"
                >
                  <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Select Work Location</p></Select.Option>
                  {filteredCities.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* </Row>
                    <Row> */}

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Grade"
                name="grade"
              >
                <Select
                  className="dropDown"
                  showSearch
                  onSearch={handleGradeSearch}
                  filterOption={false} // To prevent default filtering
                  style={{ width: "100%" }}
                  placeholder="Select Grade"
                >
                  <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Select Grade</p></Select.Option>
                  {filteredGrades.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item label="HRBP" name="hrbp">
                <Select
                  placeholder="Select HRBP"
                  className="dropDown"
                  optionFilterProp="label"
                  showSearch
                  onSearch={handleHRBPSearch}
                  filterOption={false} // To prevent default filtering
                  style={{ width: "100%" }}
                // value=""
                >
                  <Select.Option value="" disabled><p style={{ color: "gray", margin: "0px" }}>Select HRBP</p></Select.Option>
                  {filteredHRBPs?.map((item, index) => (
                    <Select.Option key={index} value={item?.employement_number}>
                      {item?.employee_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div className="buttonContainer">
            <Button className="button" onClick={() => prev()}>
              Previous
            </Button>
            <Button className="button" htmlType="submit">
              Next
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EmployementDetails;
