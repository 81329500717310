import React, { useEffect, useRef, useState } from 'react'
import arrowCircle from "../../../../assets/fullView.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import request from "../../../../utils/axios";
import styles from './HolidayFullView.module.scss'
import back from "../../../../assets/Recruitment/back.svg"
import getScreenSize from "../../../../utils/getScreenSize";


function UserHolidayFullView() {
  const ref = useRef(null);
  const [holidayData, setHolidayData] = useState([]);
  const navigate = useNavigate();
  const screenSize = getScreenSize();
  const fetchHolidayData = async () => {
    try {
      const data = await request({
        method: "get",
        url: `/leave/m-web/holiday/list/all`,
      });

      if (data && data?.data) {
        setHolidayData(data?.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchHolidayData()
  }, [])

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div ref={ref}>
      {
        screenSize.width < 768
          ?
          // Mobile View
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >


              <p style={{ fontWeight: "500", fontSize: "24px", padding: "0px 16px" }}>Holidays</p>

              {/* <div className="calendarContainer">
              <p className="calendarText">Calendar</p>
              <img
                src={filterCalendar}
                alt="filter"
                className="filterCalendarImage"
              />
            </div> */}
            </div>
            {Object.entries(holidayData).map(([month, holidays]) => (
              <div key={month}>
                <div style={{ padding: '0px 16px' }}>
                  <div>
                    <p className="month">{month}</p>
                  </div>
                  {holidays?.map((item) => (
                    <div key={item.id} className="hStack">
                      <div className="ocassionDateContainer">
                        <p className="ocassionDate">
                          {moment(item.date).format("DD")}
                        </p>
                      </div>
                      <div className="vStack">
                        <div>
                          <p className="occasion">{item.name}</p>
                        </div>
                        <div>
                          <p className="day">{item.day}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <hr className="divider" />
              </div>
            ))}
          </div>
          :
          <div style={{ padding: '50px' }}>
            <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
              <img src={back} alt="back" />
              <span style={{ paddingLeft: "10px" }}>Back</span>
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >


              <p style={{ fontWeight: "500", fontSize: "24px" }}>Holidays</p>

              {/* <div className="calendarContainer">
            <p className="calendarText">Calendar</p>
            <img
              src={filterCalendar}
              alt="filter"
              className="filterCalendarImage"
            />
          </div> */}
            </div>
            {Object.entries(holidayData).map(([month, holidays]) => (
              <div key={month}>
                <div>
                  <p className="month">{month}</p>
                </div>
                {holidays?.map((item) => (
                  <div key={item.id} className="hStack">
                    <div className="ocassionDateContainer">
                      <p className="ocassionDate">
                        {moment(item.date).format("DD")}
                      </p>
                    </div>
                    <div className="vStack">
                      <div>
                        <p className="occasion">{item.name}</p>
                      </div>
                      <div>
                        <p className="day">{item.day}</p>
                      </div>
                    </div>
                  </div>
                ))}
                <hr className="divider" />
              </div>
            ))}
          </div>
      }
    </div>
  )
}

export default UserHolidayFullView
