import { Radio } from 'antd'
import React, { useState } from 'react'
import Selective from './Selective/Selective';
import EveryOne from './EveryOne/EveryOne';

function BulkPayrollProcessing() {
    const [bulkUploadType, setBulkUploadType] = useState("selective");


    return (
        <div style={{ padding: "50px" }}>
            <div style={{ background: "white", padding: "30px", borderRadius: "10px" }}>
                <div>
                    <p style={{ color: "#161616", fontSize: "24px", fontWeight: "600" }}>Select Employees for Payroll</p>

                    <div >
                        <Radio.Group
                            size='large'
                            value={bulkUploadType}
                            onChange={(e) => {
                                setBulkUploadType(e.target.value)
                            }}
                        >
                            <Radio.Button value="selective">Selective</Radio.Button>
                            <Radio.Button value="everyone">Everyone</Radio.Button>
                        </Radio.Group>


                    </div>
                </div>
                <div>
                    {
                        bulkUploadType === "selective"
                            ?
                            <Selective />
                            :
                            <EveryOne />
                    }
                </div>
            </div>
        </div>
    )
}

export default BulkPayrollProcessing