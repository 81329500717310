import {
  DownOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  ColorPicker,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  theme,
  Tooltip,
  TreeSelect,
} from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import fullView from "../../../../assets/fullView.svg";
import edit from "../../../../assets/edit.svg";

import "./DashboardView.scss";
import { redirect, useNavigate } from "react-router-dom";
import downloadImg from "../../../../assets/downlaod.svg";
import getScreenSize from "../../../../utils/getScreenSize";
import request from "../../../../utils/axios";
import dayjs from "dayjs";
import update from "immutability-helper";
import moment from "moment";
import date from "../../../../assets/Attendance/Admin/date.svg";
import LeaveReportSearchBar from "../LeaveReportSearchBar/LeaveReportSearchBar";
import { toast } from "react-toastify";

// leave type form,
import annual from "../../../../assets/Leave/leaveType/annual.svg";
import approvalRequired from "../../../../assets/Leave/leaveType/approvalRequired.svg";
import contractual from "../../../../assets/Leave/leaveType/contractual.svg";
import coolingOffPeriod from "../../../../assets/Leave/leaveType/coolingOffPeriod.svg";
import disallowed from "../../../../assets/Leave/leaveType/disallowed.svg";
import documentUpload from "../../../../assets/Leave/leaveType/documentUpload.svg";
import encashment from "../../../../assets/Leave/leaveType/encashment.svg";
import female from "../../../../assets/Leave/leaveType/female.svg";
import halfDay from "../../../../assets/Leave/leaveType/halfDay.svg";
import male from "../../../../assets/Leave/leaveType/male.svg";
import married from "../../../../assets/Leave/leaveType/married.svg";
import maxAllowed from "../../../../assets/Leave/leaveType/maxAllowed.svg";
import month from "../../../../assets/Leave/leaveType/month.svg";
import newJoinee from "../../../../assets/Leave/leaveType/newJoinee.svg";
import notice from "../../../../assets/Leave/leaveType/notice.svg";
import onceDuringEmployment from "../../../../assets/Leave/leaveType/onceDuringEmployment.svg";
import other from "../../../../assets/Leave/leaveType/other.svg";
import permanent from "../../../../assets/Leave/leaveType/permanent.svg";
import proRata from "../../../../assets/Leave/leaveType/proRata.svg";
import single from "../../../../assets/Leave/leaveType/single.svg";
import waitingPeriod from "../../../../assets/Leave/leaveType/waitingPeriod.svg";
import weeklyOff from "../../../../assets/Leave/leaveType/weeklyOff.svg";
import casualleaves from "../../../../assets/casualleaves.svg";
import Dragger from "antd/es/upload/Dragger";
import arrowCircle from "../../../../assets/fullView.svg";
import add from "../../../../assets/Org/add.svg";
import searchImage from "../../../../assets/searchImage.svg";
import time from "../../../../assets/time.svg";
import clone_icon from "../../../../assets/clone_icon.svg";
import download from "../../../../assets/download.png"


import { saveAs } from 'file-saver';

function LeaveDashboardView({ changeTabsValue }) {
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const screenSize = getScreenSize();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const fileBaseUrl = process.env.REACT_APP_IMG_BASE_URL;
  const [openHolidayEditModal, setOpenHolidayEditModal] = useState(false)
  const [editHolidayDetails, setEditHolidayDetails] = useState(null);
  // Leave Policy
  // ##############################################################################################################################################################################
  // ##############################################################################################################################################################################
  let status = "Approved";
  let textColor = "red";
  let leaveDetail = [
    {
      date: "2022-01-01",
      type: "full_day",
    },
    {
      date: "2022-01-02",
      type: "full_day",
    },
    {
      date: "2022-01-03",
      type: "full_day",
    },
  ];
  const onClickHandler = () => {
    navigate("/admin/leave/leave-type-form", { replace: true });
  };
  const employeeLeavePopUp = (record) => {
    return (
      <div>
        <div style={{ display: "flex", gap: "10px" }}>
          {record.leave_dates.map((item, index) => {
            return (
              <>
                <span>
                  {item.leave_for === "full_day" ? (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "white",
                        padding: "3px",
                        borderRadius: "50%",
                        background: "#008080",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                      }}
                    >
                      {dayjs(item.date).format("DD")}
                    </div>
                  ) : record.from_second_half_start_date ? (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "black",
                        padding: "3px",
                        borderRadius: "50%",
                        background:
                          "linear-gradient(to left, #008080, #008080 50%, #DDDDDD 50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                      }}
                    >
                      {dayjs(item.date).format("DD")}
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "black",
                        padding: "3px",
                        borderRadius: "50%",
                        background:
                          "linear-gradient(to right, #008080, #008080 50%, #DDDDDD 50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                      }}
                    >
                      {dayjs(item.date).format("DD")}
                    </div>
                  )}
                </span>
              </>
            );
          })}
        </div>
      </div>
    );
  };
  const employeeLeaveColumn = [
    {
      title: "Name",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "No. Of Leaves",
      dataIndex: "leave_day",
      key: "leave_day",
      render: (text, record) => (
        <span>
          {text}
          <Popover
            placement="top"
            title="Leaves"
            content={employeeLeavePopUp(record)}
          >
            <InfoCircleOutlined />
          </Popover>
        </span>
      ),
    },
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      key: "leave_type",
    },
    {
      title: "Leave From",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Leave To",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <button className={`${text}LeaveButton`}>{text}</button>
      ),
    },
  ];

  const [isClone, setIsClone] = useState(false)
  const leavePolicyColumns = [
    {
      title: "Policy",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "From",
      dataIndex: "start_time",
      key: "start_time",
      width: 150,
    },
    {
      title: "To",
      dataIndex: "end_time",
      key: "end_time",
      width: 150,
    },
    {
      title: "Leave Types",
      dataIndex: "leave_type_details",
      key: "leave_type_details",
      render: (text, record) => (
        <span>
          {record.leave_type_details.map((item, index) => {
            return (
              <>
                {
                  <span>
                    {item.name} ({item.leaves_numbers})
                    {record.leave_type_details?.length - 1 !== index && (
                      <span> , </span>
                    )}
                  </span>
                }
              </>
            );
          })}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <img
            src={edit}
            alt="edit"
            style={{ cursor: "pointer", marginRight: "15px" }}
            onClick={() => {
              setOpenLeavePolicy(true);
              setLeavePolicyId(record.id);
            }}
          />
          <img
            src={clone_icon}
            alt="clone"
            onClick={() => {
              setOpenLeavePolicy(true);
              setLeavePolicyId(record.id);
              setIsClone(true)
            }}
          />
          {/* <Button
            style={{
              background: "#008080",
              color: "white",
              border: "none",
              padding: "5px 10px",
              borderRadius: "5px",
            }}

            onClick={() => {
              setOpenLeavePolicy(true);
              setLeavePolicyId(record.id);
              setIsClone(true)

            }}
          >
            Clone
          </Button> */}
        </div>
      ),
    },
  ];

  const compOffColumn = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      key: "employee",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Compensatory Off",
      dataIndex: "leaves_number",
      key: "leaves_number",
    },
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      key: "leave_type",
    },
  ];

  const holidayColumns = [
    {
      title: "Year",
      dataIndex: "annual_year",
      key: "annual_year",
    },
    {
      title: "Policy",
      dataIndex: "leave_policy",
      key: "leave_policy",
      render: (text, record) => (
        <span>
          {text}
          {/* <span style={{ padding: "5px", background: "#E6F2FF", borderRadius: "50%", color: "#0043FF", marginLeft: "10px" }}>+3</span > */}
        </span>
      ),
    },
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "Action",
      dataIndex: "holiday_file",
      key: "holiday_file",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <img
            src={edit}
            alt="edit"
            onClick={() => {
              setOpenHolidayEditModal(true);
              setEditHolidayDetails({
                leave_policy: record.policy_id,
                annual_year: record.annual_year,
              });
            }}
          />
          <p
            style={{
              borderRadius: "15px",
              textAlign: "center",
              cursor: "pointer",
              margin: "0px"
            }}
            // onClick={async () => {
            //   console.log("res start.......", text)
            //   const response = await request({
            //     method: "get",
            //     url: `${text}`,
            //     responseType: "text", // Change response type to text
            //   });
            //   console.log("res1...", response)
            //   const csvData = new Blob([response], { type: "text/csv" }); // Set MIME type to text/csv
            //   const url = window.URL.createObjectURL(csvData);
            //   const link = document.createElement("a");
            //   link.href = url;
            //   link.download = "file.csv"; // Change filename to .csv
            //   link.click();
            // }}

            onClick={async () => {

              try {
                const response = await fetch(text);
                const blob = await response.blob();
                saveAs(blob, `holidaylist_${record.annual_year}.csv`);
              } catch (error) {
                console.error('Error downloading CSV:', error);
              }
            }}
          >
            <img src={downloadImg} alt="downloadImg" />
          </p>
        </div>
      ),
    },
  ];

  const searchTry = [
    {
      employee_name: "abc",
      employement_number: "abc",
    },
  ];

  const [searchLoading, setSearchLoading] = useState(false);
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const handleSelectChange = async (value) => {
    console.log(`selected ${value}`);
    try {
      const response = await request({
        method: "get",
        url: `leave/employee/search/my-team/leave/details/?employement_number=${value}`,
      });
      console.log("response 11", response);
      setLeaveReportList([response]);
      setSearchEmployeeList([]);
    } catch (err) {
      console.log(err);
    }
  };

  const employeeLeavePolicyMangerColumns = [
    {
      title: 'Id',
      dataIndex: 'employement_number',
      key: 'employement_number',
    },
    {
      title: 'Employee Name',
      dataIndex: 'employee_name',
      key: 'employee_name',
    },
    {
      title: 'Leave Policy',
      dataIndex: 'leave_policy',
      key: 'leave_policy',
    },
    {
      title: 'Action',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => <img src={edit} alt="edit" onClick={() => {
        setSelectedEmployeeLeavePolicyManager({
          ...selectedEmployeeLeavePolicyManager,
          user_id: record.employement_number,
          policy_id: +record.policy_id
        })
        setChangeLeavePolicyModel(true)
      }} />
    }
  ]

  const [leavePolicyId, setLeavePolicyId] = useState(null);

  const [leavePolicyDataList, setLeavePolicyDataList] = useState([]);
  const [holidayLeavePolicyList, setHolidayLeavePolicyList] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectAll = () => {
    setSelectedOptions(
      holidayLeavePolicyList.map((item) => {
        return item.value;
      })
    );
  };
  const [changeLeavePolicyModel, setChangeLeavePolicyModel] = useState(false)
  const [selectedEmployeeLeavePolicyManager, setSelectedEmployeeLeavePolicyManager] = useState({
    user_id: "",
    policy_id: ""
  })

  const [holidayListFile, setHolidayListFile] = useState(null);
  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.leave_policy) {
      if (changedValues?.leave_policy.includes("all")) {
        form.setFieldsValue({
          leave_policy: holidayLeavePolicyList.map((item) => {
            return item.value;
          }),
        });
      }
    }
    console.log("changedValues", changedValues);
  };


  const handleDateChangeForTable = async (value) => {
    const start_date = value[0]?.format("YYYY-MM-DD");
    const end_date = value[1]?.format("YYYY-MM-DD");
    setDateRange(value);
    setLeaveReportCustomDateForTable({ ...leaveReportCustomDateForTable, start_date, end_date })
  };  

  const [leaveReportDaysForTable, setLeaveReportDaysForTable] = useState(7);
  const [leaveReportCustomDateForTable, setLeaveReportCustomDateForTable] = useState({
    start_date: "",
    end_date: ""
  });

  const handleDaysChangeForTable = (value) => {
    console.log("value", value);
    if (value === "custom") {
      // setShowDatePicker(true)
      setLeaveReportDaysForTable(value);
      setOpen(true);
    } else {
      // setShowDatePicker(false)
      setLeaveReportDaysForTable(value);
    }
  };  

  const fetchAllLeavePolicy = async () => {
    try {
      const data = await request({
        method: "get",
        url: "leave/leave_policy/all/?page=1&page_size=5",
      });

      setLeavePolicyDataList(data.pagination_data.data);
      const holidayLeavePolicyList = data.pagination_data.data.map(
        (item, index) => {
          return {
            label: item.name,
            value: item.id,
          };
        }
      );

      setHolidayLeavePolicyList(holidayLeavePolicyList);
    } catch (err) {
      console.log(err);
    }
  };

  const [openLeavePolicy, setOpenLeavePolicy] = useState(false);
  const [leavePolicyForm, setLeavePolicyForm] = useState({
    name: "",
    start_time: "",
    end_time: "",
    leave_type_details: [],
  });

  const fileInputRef = useRef(null);

  const onFinishPolicyForm = async (values) => {
    // console.log('Success:', leavePolicyForm);

    try {
      const start_time = dayjs(leavePolicyForm.start_time).format("YYYY-MM-DD");
      const end_time = dayjs(leavePolicyForm.end_time).format("YYYY-MM-DD");
      // values = { ...values, dob }

      if (leavePolicyId) {
        if (isClone) {
          await request({
            method: "post",
            url: `leave/admin/leave-policy/clone`,
            data: {
              ...leavePolicyForm,
              start_time: start_time,
              end_time: end_time
            }
          })
        }
        else {
          await request({
            method: "put",
            url: `leave/leave_policy/update/?leave_id=${leavePolicyId}`,
            data: {
              ...leavePolicyForm,
              start_time: start_time,
              end_time: end_time,
            },
          });

          toast.success("Leave Policy updated", {
            position: "bottom-left",
          });
        }

      } else {
        await request({
          method: "post",
          url: "leave/leave_policy/create/",
          data: {
            ...leavePolicyForm,
            start_time: start_time,
            end_time: end_time,
          },
        });
        toast.success("New Leave Policy added", {
          position: "bottom-left",
        });
      }
      setLeavePolicyId(null);
      setOpenLeavePolicy(false);
      setTimeout(() => {
        fetchAllLeavePolicy();
      }, 1000);
      // fetchAllLeavePolicy()
      navigate("/admin/leave/leave-policy", { replace: true });
    } catch (err) {
      console.log(err);
      if (leavePolicyId) {
        toast.error(
          "Leave Type could not be updated, please try after some time",
          {
            position: "bottom-left",
          }
        );
      } else {
        toast.error(
          "New Leave Policy could not be added, please try after some time",
          {
            position: "bottom-left",
          }
        );
      }
    }
  };

  const fetchLeaveType = async () => {
    try {
      const data = await request({
        method: "get",
        url: "leave/leave_types/all/",
      });

      setLeavePolicyForm({
        name: "",
        start_time: "",
        end_time: "",
        leave_type_details: data.map((item) => ({
          ...item,
          leaves_numbers: null,
        })),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSpecficLeavePolicy = async () => {
    try {
      const data = await request({
        method: "get",
        url: `leave/leave_policy/details/?leave_id=${leavePolicyId}`,
      });

      const start_date = new Date(data.data.start_time).toISOString();
      const end_date = new Date(data.data.end_time).toISOString();

      const mergedArray = leavePolicyForm.leave_type_details.map((item1) => {
        const item2 = data.data.leave_type_details.find((i) => {
          return i.name === item1.name;
        });

        return { ...item1, ...item2 };
      });

      setLeavePolicyForm({
        ...leavePolicyForm,
        name: data.data.name,
        start_time: dayjs(start_date),
        end_time: dayjs(end_date),
        leave_type_details: mergedArray,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // ##############################################################################################################################################################################
  // ##############################################################################################################################################################################

  // Leave Report
  // ##############################################################################################################################################################################
  // ##############################################################################################################################################################################
  const [leaveReportColums, setLeaveReportColums] = useState([
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "ID",
      dataIndex: "employement_number",
      key: "employement_number",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ]);

  const [mobileLeaveColumList, setMobileLeaveColumList] = useState([]);
  const fetchLeaveRoportColumn = async () => {
    try {
      const data = await request({
        method: "get",
        url: "leave/leave_type/columns/",
      });
      setMobileLeaveColumList(data.data);
      setLeaveReportColums([...leaveReportColums, ...data.data]);
      fetchLeaveReportData();
    } catch (err) {
      console.log(err);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [leaveReportList, setLeaveReportList] = useState([]);
  const fetchLeaveReportData = async () => {
    try {
      if (leaveReportDaysForTable === "custom") {
        const data = await request({
          method: "get",
          url: `leave/admin/leave-report/all?page=1&page_size=4&start_date=${leaveReportCustomDateForTable.start_date}&end_date=${leaveReportCustomDateForTable.end_date}`,
      });
        setLeaveReportList(data.pagination_data.data);
      }
      else {
        const data = await request({
          method: "get",
          url: `leave/admin/leave-report/all?page=1&page_size=4&days=${leaveReportDaysForTable}`,
      });
        setLeaveReportList(data.pagination_data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    fetchLeaveReportData();
  }, [leaveReportDaysForTable, leaveReportCustomDateForTable]);

  const [filter, setFilter] = useState("");

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
  };
  // ##############################################################################################################################################################################
  // ##############################################################################################################################################################################

  // Leave Type
  // ##############################################################################################################################################################################
  // ##############################################################################################################################################################################
  const [leavTypeForm, setLeaveTypeForm] = useState({
    name: "",
    description: "",
    is_predefined: false,
    color: "",
    image: null,
  });
  const bgColor = useMemo(
    () =>
      typeof leavTypeForm.color === "string"
        ? leavTypeForm.color
        : leavTypeForm?.color?.toHexString(),
    [leavTypeForm.color]
  );
  const btnStyle = {
    backgroundColor: bgColor,
  };
  const [showLeaveTypeModal, setShowLeaveTypeModal] = useState(false);

  const [leaveTypesList, setLeaveTypesList] = useState([]);
  const fetchLeaveTypeList = async () => {
    try {
      const data = await request({
        method: "get",
        url: "leave/admin/leave_types/all/",
      });
      setLeaveTypesList(
        data.data.map((item) => {
          return {
            ...item,
            isCollapsed: true
          }
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const [editLeaveTypeId, setEditLeaveTypeId] = useState(null);

  const fetchSpecficLeaveType = async () => {
    try {
      const data = await request({
        method: "get",
        url: `leave/leave_type/detail/?id=${editLeaveTypeId}`,
      });

      setLeaveTypeForm({
        name: data.name,
        description: data.description,
        is_predefined: data.is_predefined,
        color: data.color,
        image: "",
      });
    } catch (err) {
      console.log(err);
    }
  };

  // const onFinish = async (values) => {
  //   const formData = new FormData();
  //   formData.append("name", leavTypeForm.name);
  //   formData.append("description", leavTypeForm.description);
  //   formData.append("is_predefined", leavTypeForm.is_predefined);
  //   formData.append("color", leavTypeForm.color);
  //   formData.append("image", leavTypeForm.image);

  //   try {
  //     if (editLeaveTypeId) {
  //       formData.append("id", editLeaveTypeId);
  //       await request({
  //         method: "put",
  //         url: `leave/leave_type/update/?id=${editLeaveTypeId}`,
  //         data: formData,
  //       });
  //     } else {
  //       await request({
  //         method: "post",
  //         url: "leave/leave_type/create/",
  //         data: formData,
  //       });
  //     }

  //     setEditLeaveTypeId(null);
  //     setShowLeaveTypeModal(false);
  //     setLeaveTypeForm({
  //       name: "",
  //       description: "",
  //       is_predefined: false,
  //       color: "",
  //       image: "",
  //     });
  //     fetchLeaveTypeList();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // ##############################################################################################################################################################################
  // ##############################################################################################################################################################################
  const handleFormChange = (changedValues, allValues) => {
    if (!changedValues.image) {
      if (changedValues?.color) {
        setLeaveTypeForm({
          ...leavTypeForm,
          ...changedValues,
          color: changedValues?.color?.toHexString().substring(0, 7),
        });
      } else {
        setLeaveTypeForm({ ...leavTypeForm, ...changedValues });
      }
    }
  };

  const downloadReport = async () => {
    if (leaveReportDays === "custom") {
      const response = await request({
        method: "get",
        url: `leave/admin/leave-report/download?start_date=${leaveReportCustomDate.start_date}&end_date=${leaveReportCustomDate.end_date}`,
        responseType: "arraybuffer",
      });
      const file = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = "file.pdf";
      link.click();
    }
    else {
      const response = await request({
        method: "get",
        url: `leave/admin/leave-report/download?days=${leaveReportDays}`,
        responseType: "arraybuffer",
      });
      const file = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = "file.pdf";
      link.click();
    }
  };

  const [employeeLeaveDetailList, setEmployeeLeaveDetailList] = useState([]);

  const fetchEmployeeLeaveDetail = async () => {
    try {
      const response = await request({
        method: "get",
        url: "leave/employees/leave-application/all/details/?page=1&page_size=4",
      });
      setEmployeeLeaveDetailList(response.pagination_data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const [compOffList, setCompOffList] = useState([]);
  const fetchCompOffList = async () => {
    try {
      const response = await request({
        method: "get",
        url: "leave/admin/comp-off/leaves/all/?page=1&page_size=4",
      });

      setCompOffList(response.pagination_data.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchLeaveTypeList();
    fetchAllLeavePolicy();
    fetchLeaveType();
    fetchLeaveRoportColumn();
    fetchEmployeeLeaveDetail();
    fetchCompOffList();
  }, []);

  useEffect(() => {
    if (editLeaveTypeId) {
      fetchSpecficLeaveType();
    }
    if (leavePolicyId) {
      fetchSpecficLeavePolicy();
    }
  }, [editLeaveTypeId, leavePolicyId]);

  const search = async (data) => {
    setSearchLoading(true);
    try {
      const response = await request({
        method: "get",
        url: `leave/admin/search/employees/?employee_name=${data}`,
      });

      console.log("response", response);
      setSearchEmployeeList(response);
      if (data === "") {
        fetchLeaveReportData();
      }
    } catch (err) {
      console.log(err);
    }
    setSearchLoading(false);
  };

  const [isCollapsed, setIsCollapsed] = useState(true);
  const defaultDisplayLimit = 8;

  const handleToggleCollapse = (index) => {
    let updatedValue = update(leaveTypesList, {
      [index]: {
        isCollapsed: { $set: !leaveTypesList[index].isCollapsed },
      }
    });
    setLeaveTypesList(updatedValue)
  };

  const iconLabelList = [
    {
      value: "Male",
      name: "is_for_male",
    },
    {
      value: "Female",
      name: "is_for_female",
    },
    {
      value: "Other",
      name: "is_for_others",
    },
    {
      value: "Permanent",
      name: "is_for_full_time",
    },
    {
      value: "Part Time",
      name: "is_for_part_time",
    },
    {
      value: "Contractual",
      name: "is_for_contractual",
    },
    {
      value: "Internship",
      name: "is_for_internship",
    },
    {
      value: "Married",
      name: "is_for_married",
    },
    {
      value: "Single",
      name: "is_for_single",
    },
    {
      value: "Pro Rata",
      name: "is_pro_rata",
    },
    {
      value: "Once During Employement",
      name: "is_once_during_employement_year",
    },
    {
      value: "Document Required",
      name: "is_document_required",
    },
    {
      value: "Allowed During Notice Period",
      name: "is_allowed_during_notice_period",
    },
    {
      value: "Half Day Interval",
      name: "is_half_day_interval_allowed",
    },
    {
      value: "Waiting Period",
      name: "is_waiting_period",
    },
    {
      value: "Weekly Off",
      name: "is_weekly_off_inclusion",
    },
    {
      value: "Back Dated Allowed",
      name: "is_back_dated_allowed",
    },
    {
      value: "Carry Forward Leaves",
      name: "is_carry_forward_leaves",
    },
    {
      value: "Encashement",
      name: "is_encashement",
    },
    {
      value: "Cooling Off Period",
      name: "is_cooling_off_period",
    },
    {
      value: "New Joinee",
      name: "is_applicable_for_new_joinee",
    },
    {
      value: "Disallowed",
      name: "is_disallowed",
    },
    {
      value: "Max Allowed",
      name: "is_max_allowed",
    },
    {
      value: "Min Allowed",
      name: "is_minimum_allowed",
    },
  ];

  const [openHolidayModal, setOpenHolidayModal] = useState(false);

  const onFinishHoliday = async (values) => {
    try {
      const formData = new FormData();
      formData.append("annual_year", values.year);
      formData.append("leave_policy", values.leave_policy);
      formData.append("holiday_file", holidayListFile);

      const response = await request({
        method: "post",
        url: `leave/leave_policy/holiday/upload`,
        data: formData,
      });

      setOpenHolidayModal(false);
      await fetchHolidayList();
      console.log("onFinishHoliday", response);
      toast.success("Holiday list uploaded ", {
        position: "bottom-left",
      });
      form.resetFields();
    } catch (error) {
      console.log("error", error);
      toast.error("Could not upload Holiday list, please try after some time", {
        position: "bottom-left",
      });
    }
  };

  const editHoliday = async () => {
    try {
      const formData = new FormData();
      formData.append("holiday_file", holidayListFile);
      formData.append("leave_policy", editHolidayDetails.leave_policy);
      formData.append("annual_year", editHolidayDetails.annual_year);

      const response = await request({
        method: "post",
        url: `leave/holiday/list/update/`,
        data: formData,
      });

      setOpenHolidayEditModal(false);
      console.log("onFinishHoliday", response);
      setHolidayListFile(null);
      toast.success("Holiday Updated ", {
        position: "bottom-left",
      });
      // form.resetFields();
    }
    catch (error) {
      console.log("error", error);
      toast.error("Could not upload Holiday list, please try after some time", {
        position: "bottom-left",
      });
    }
  }

  const [holidayList, setHolidayList] = useState([]);
  const fetchHolidayList = async () => {
    try {
      const response = await request({
        method: "get",
        url: `leave/admin/holiday/list/details/?page=1&page_size=4`,
      });
      console.log("fetchHolidayList", response);
      setHolidayList(response?.pagination_data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const [compOffLeaveTypeList, setCompOffLeaveTypeList] = useState([]);
  const fetchCompOffLeaveTypeList = async () => {
    try {
      const response = await request({
        method: "get",
        url: `leave/admin/comp-off/leaves-types/all`,
      });

      setCompOffLeaveTypeList(response?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const [employeeList, setEmployeeList] = useState([]);

  const fetchEmployeeList = async () => {
    try {
      const response = await request({
        method: "get",
        url: `/get-all-employees`,
      });

      setEmployeeList(response?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const [employeeLeavePolicyMangerList, setEmployeeLeavePolicyMangerList] = useState([])
  const fetchEmployeeLeavePolicyManger = async () => {
    try {
      const data = await request({
        method: "get",
        url: `leave/admin/leave-policy-management/?page=1&page_size=4`,
      })
      setEmployeeLeavePolicyMangerList(data.pagination_data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const [leavePolicy, setLeavePolicy] = useState([])
  const getLeavePolicy = async () => {
    let data = await request({
      method: "get",
      url: `leave/leave/policy/all/dropdown`,
    })
    setLeavePolicy(data.data)
  }


  useEffect(() => {
    fetchHolidayList();
    fetchEmployeeList();
    fetchCompOffLeaveTypeList();
    fetchEmployeeLeavePolicyManger();
    getLeavePolicy();
  }, []);

  const onFinishCompOff = async (values) => {
    try {
      const response = await request({
        method: "post",
        url: `leave/employee/leave-type/add/`,
        data: values
      });

      setAddCompOffModal(false);
      fetchCompOffList();
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateEmployeeLeavePolicy = async (values) => {
    try {
      const response = await request({
        method: "post",
        url: `leave/admin/employee/leave-policy/update?employement_number=${selectedEmployeeLeavePolicyManager.user_id}`,
        data: {
          policy_id: values.leave_policy
        }
      });
      setChangeLeavePolicyModel(false)
      fetchEmployeeLeavePolicyManger();
    }
    catch (err) {
      console.log("err", err);
    }
  }


  const [addCompOffModal, setAddCompOffModal] = useState(false)
  const [open, setOpen] = useState(false)

  const [dateRange, setDateRange] = useState();
  const [leaveReportDays, setLeaveReportDays] = useState(7);
  const [leaveReportCustomDate, setLeaveReportCustomDate] = useState({
    start_date: "",
    end_date: ""
  });

  const handleDateChange = async (value) => {
    const start_date = value[0]?.format("YYYY-MM-DD");
    const end_date = value[1]?.format("YYYY-MM-DD");
    setDateRange(value);
    setLeaveReportCustomDate({ ...leaveReportCustomDate, start_date, end_date })
  };

  const getPopupContainer = (triggerNode) => {
    return triggerNode.parentNode;
  };

  const handleDaysChange = (value) => {
    console.log("value", value);
    if (value === "custom") {
      // setShowDatePicker(true)
      setLeaveReportDays(value);
      setOpen(true);
    } else {
      // setShowDatePicker(false)
      setLeaveReportDays(value);
    }
  };

  const [downloadModal, setDownloadModal] = useState(false)

  const ref = useRef(null);
  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const leaveReportDownloadPopover = (
    <>
      <Select
        style={{ width: 300, height: 40 }}
        placeholder="Select an option"
        onChange={handleDaysChange}
        value={leaveReportDays}
      >
        <Select.Option value={7}>Last 7 days</Select.Option>
        <Select.Option value={14}>Last 14 days</Select.Option>
        <Select.Option value={30}>Last 30 days</Select.Option>
        <Select.Option value="custom">
          <Popover
            title=""
            content={
              <div className="modal-content">
                <RangePicker
                  value={dateRange}
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                />
              </div>
            }
            getPopupContainer={getPopupContainer}
            placement="right"
          >
            <p
              style={{
                cursor: "pointer",
                width: "90%",
                margin: "0px",
              }}
            >
              Custom Date
            </p>
          </Popover>
        </Select.Option>
      </Select>
      <br /><br />
      <button
        style={{
          border: "none",
          color: "white",
          background: "#008080",
          borderRadius: "4px",
          height: "36px",
          padding: "0px 10px"
        }}
        onClick={() => downloadReport()}
      >
        Download
      </button>
    </>
  )

  return (
    <div className="adminLeaveDashboardContainer" ref={ref}>
      {screenSize.width < 768 ? (
        // Mobile View
        <>
          {/* Leave Distribution */}
          {/* <div style={{ fontSize: "24px", fontWeight: "500" }}>
            Leaves Distribution
          </div>
          <div className="leavesRowContainer">
            <div className="leavesCard">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="leavesCardHeading">30</h2>
                <img
                  src={casualleaves}
                  alt="icon"
                  style={{ width: "25px", height: "25px" }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    background: "#FFA0A0",
                    height: "8px",
                    width: "6%",
                    borderRadius: "100px",
                    marginTop: "8px",
                  }}
                ></div>
                <p className="leavesSubHeading">Casual Leave</p>
              </div>
            </div>
          </div>
          <div
            style={{
              height: "8px",
              backgroundColor: "#F7F8FC",
              marginTop: "20px",
            }}
          /> */}

          {/* Employee Leaves */}

          <div style={{ padding: "0px 16px", display: "flex", justifyContent: "space-between" }}>
            <div className="employeeText">Employee Leaves</div>
            <img
              onClick={() => changeTabsValue("2")}
              src={arrowCircle}
              alt="arrow"
              className="employeeArrowImage"
            />
          </div>

          {employeeLeaveDetailList?.map((item, index) => {
            return (
              <>
                <div style={{ padding: "0px 16px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <p className="name">{item?.employee_name}</p>
                    <div
                      className="pendingContainer"
                      style={{
                        borderColor: item?.is_approved ? "#28B15F" : "red",
                      }}
                    >
                      <p
                        className="status"
                        style={{ color: item?.is_approved ? "#28B15F" : "red" }}
                      >
                        {item?.is_pending && "Pending"}
                        {item?.is_approved || item?.is_predefined_leaves
                          ? "Approved"
                          : ""}

                        {item?.is_rejected && "Rejected"}
                      </p>
                    </div>
                  </div>

                  <p className="leaveText">{item.leave_type}</p>

                  <div>
                    <p className="date">
                      {moment(item.start_date).format("DD MMM YYYY")} To{" "}
                      {moment(item.end_date).format("DD MMM YYYY")}
                    </p>
                  </div>
                  <div className="leavesText">
                    {item.leave_day} leaves {" "}
                    <Popover
                      placement="topLeft"
                      title="Leaves"
                      content={employeeLeavePopUp(item)}
                    >
                      <DownOutlined />
                    </Popover>
                  </div>
                </div>
                {index !== employeeLeaveDetailList?.length - 1 && (
                  <hr className="divider" />
                )}
              </>
            );
          })}

          <div
            style={{
              height: "8px",
              backgroundColor: "#F7F8FC",
              marginTop: "20px",
            }}
          />

          {/* Leave Policy */}

          <div className="myTeamContainerNew">
            <div className="myTeamTextNew">Leave Policy</div>
            <div className="leavePolicyImageContainer">

              <img src={add} alt="add" className="addImage"
                onClick={() => {
                  navigate("/admin/leave/leave-policy/form", { replace: true })
                }}
              />

              <img
                onClick={() => changeTabsValue("3")}
                src={arrowCircle}
                alt="arrow"
                className="employeeArrowImage"
              />
            </div>
          </div>

          {leavePolicyDataList.map((item, index) => {
            return (
              <>
                <div style={{ padding: "0px 16px" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                    <div className="policyText">{item?.name}</div>
                    <img src={edit} alt="edit" className="editImage"
                      width={15}
                      height={15}
                      onClick={() => {
                        // setOpenLeavePolicy(true);
                        // setLeavePolicyId(item.id);
                        navigate("/admin/leave/leave-policy/form/" + item?.id, { replace: true })
                      }}
                    />
                  </div>
                  <div
                    style={{
                      color: "#8A8A8A",
                      fontSize: "12px",
                      fontWeight: "400",
                      marginTop: "15px",
                    }}
                  >
                    {moment(item?.start_time).format("DD MMM YYYY")} To{" "}
                    {moment(item?.end_time).format("DD MMM YYYY")}{" "}
                  </div>

                  {/* <div style={{ display: "flex", gap: "10px" }}>
                    {item?.leave_type_details.map((el, index) => {
                      return (
                        <>
                          <div
                            style={{
                              color: "#8A8A8A",
                              fontSize: "12px",
                              fontWeight: "400",
                              marginTop: "15px",
                              flexDirection: "row",
                            }}
                          >
                            <span> {el?.name}</span>
                            {index !== item?.leave_type_details?.length - 1 && (
                              <span>, </span>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div> */}
                  <div style={{ display: "flex", gap: "10px" }}>
                    {item?.leave_type_details.map((el, index) => {
                      return (
                        <>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                              marginTop: "15px",
                            }}
                          >
                            <span>
                              {el?.name}({el?.leaves_numbers})
                            </span>
                            {index !== item?.leave_type_details?.length - 1 && (
                              <span>, </span>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                {index !== leavePolicyDataList?.length - 1 && (
                  <hr className="divider" />
                )}
              </>
            );
          })}

          <div
            style={{
              height: "8px",
              backgroundColor: "#F7F8FC",
              marginTop: "20px",
            }}
          />
          {/* Leave Report */}
          <div className="myTeamContainerNew">
            <div className="myTeamTextNew">Leave Report</div>
            <div style={{ width: "25%", display: "flex", justifyContent: "space-between" }}><img src={download} alt="download" width={35} height={35}
              onClick={() => {
                setDownloadModal(true);
              }}
            />

              <img
                onClick={() => changeTabsValue("4")}
                src={arrowCircle}
                alt="arrow"
              /></div>


          </div>
          <div style={{ marginTop: "20px", marginBlock: "20px", padding: "0px 16px" }}>
            <LeaveReportSearchBar
              setTableDataForSearchEmployee={setLeaveReportList}
              fetchTableDataFromApi={fetchLeaveReportData}
              placeholderText="Search for employees"
              empType="admin"
            />
            <br />
            {/* <img
              src={downloadImg}
              alt="downlaod"
              className="downlaodImage"
              onClick={() => {
                setDownloadModal(true);
              }}
            /> */}

          </div>

          {leaveReportList.map((item, index) => {
            return (
              <>
                <div style={{ padding: "0px 16px" }}>

                  <div className="policyText">{item?.employee_name}</div>
                  <div
                    style={{
                      fontWeight: "400px",
                      fontSize: "16px",
                      color: "#8A8A8A",
                      marginTop: "10px",
                    }}
                  >
                    {item?.employement_number}
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "10px",
                      alignItems: "center",
                    }}
                  >
                    <img src={time} alt="downlaod" className="downlaodImage" />
                    <div
                      style={{
                        marginLeft: "8px",
                        color: "#8A8A8A",
                        fontWeight: "400px",
                        fontSize: "12px",
                      }}
                    >
                      {item.date} 
                    </div>
                  </div> */}
                  <div
                    style={{
                      marginTop: "12px",

                      justifyContent: "space-between",
                      // width: "45%",
                    }}
                  >
                    {mobileLeaveColumList.map((el, elIndex) => {
                      return (
                        <>
                          {item[el?.dataIndex] === "N/A"
                            ?
                            <></>
                            :
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "400px",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {el?.title}
                              </div>
                              <div
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "400px",
                                  color: "#8A8A8A",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {item[el?.dataIndex]}
                              </div>
                            </div>
                          }
                        </>
                      );
                    })}
                  </div>
                </div>
                {index !== leaveReportList?.length - 1 && (
                  <hr className="divider" />
                )}
              </>
            );
          })}
          <div
            style={{
              height: "8px",
              backgroundColor: "#F7F8FC",
              marginTop: "20px",
            }}
          />

          {/* Holidays */}

          <div className="employeeLeavesContainer" style={{ padding: "0px 16px" }}>
            <div className="employeeText">Holidays</div>
            <div style={{ display: "flex", gap: "10px" }}><img src={add} alt="add" width={35} height={35}
              onClick={() => {
                setOpenHolidayModal(true)
              }}
            />
              <img
                onClick={() => changeTabsValue("6")}
                src={arrowCircle}
                alt="arrow"
                className="employeeArrowImage"
              />
            </div>
          </div>
          {holidayList.map((item, index) => {
            return (
              <>
                <div style={{ padding: "0px 16px" }}>
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "500px",
                      marginTop: "10px",
                    }}
                  >
                    {item?.annual_year}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "12px",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "45%",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "400px",
                        color: "#8A8A8A",
                      }}
                    >
                      {/* {item?.leave_policy.map((el, elIndex) => {
                      return <div>{el}</div>;
                    })} */}
                      {item?.leave_policy[0]}
                    </div>
                    {item.leave_policy.length > 1 && (
                      <div
                        style={{
                          color: "#0043FF",
                          fontSize: "12px",
                          fontWeight: "400px",
                          height: "20px",
                          width: "20px",
                          backgroundColor: "#E6F2FF",
                          borderRadius: "100px",
                          padding: 2,
                          textAlign: "center",
                        }}
                      >
                        + {item?.leave_policy.length - 1}
                      </div>
                    )}
                  </div>
                  <div className="fileNameContainer">
                    <div>{item?.file_name}</div>
                    <img
                      src={downloadImg}
                      alt="downlaod"
                      className="downlaodImage"
                    />
                  </div>
                </div>
                {index !== holidayList?.length - 1 && (
                  <hr className="divider" />
                )}
              </>
            );
          })}
          <div
            style={{
              height: "8px",
              backgroundColor: "#F7F8FC",
              marginTop: "20px",
            }}
          />

          {/* Compensatory Off */}

          <div style={{ marginTop: "30px" }}>
            <div style={{ padding: "0px 16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <p style={{ fontSize: "24px", color: "#161616", fontWeight: "500px", margin: "0px" }}>Compensatory Off</p>
              <div style={{ display: "flex", gap: "10px" }}>
                <img src={add} alt="add" className="addImage"
                  onClick={() => {
                    navigate("/admin/leave/comp-off/form", { replace: true })
                  }}
                />

                <img
                  onClick={() => changeTabsValue("7")}
                  src={arrowCircle}
                  alt="arrow"
                  className="employeeArrowImage"
                />
              </div>
            </div>

            <div>
              {
                compOffList?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div style={{ padding: "0px 16px", marginTop: "15px" }}>
                        <Row>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Employee Name</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", textAlign: "end" }}>{item?.employee}</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Date</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", textAlign: "end" }}>{item?.created_at}</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Compensatory off</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", textAlign: "end" }}>{item?.leaves_number}</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Leave Type</p>
                          </Col>
                          <Col span={12}>
                            <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", textAlign: "end" }}>{item?.leave_type}</p>
                          </Col>
                        </Row>
                      </div>
                      {
                        index !== compOffList?.length - 1 && (
                          <hr className="divider" />
                        )
                      }
                    </div>
                  )
                })
              }
            </div>

          </div>

          <div
            style={{
              height: "8px",
              backgroundColor: "#F7F8FC",
              marginTop: "20px",
            }}
          />

          {/* Employee Leave Policy Manager */}
          <div style={{ marginTop: "30px" }}>
            <div style={{ padding: "0px 16px", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "50px" }}>
              <p style={{ fontSize: "24px", color: "#161616", fontWeight: "500px", margin: "0px" }}>Employee Leave Policy Manager</p>
              <div style={{ display: "flex", gap: "10px" }}>
                <img src={add} alt="add" className="addImage"
                  onClick={() => {
                    // navigate("/admin/leave/leave-policy/form")
                  }}
                />

                <img
                  onClick={() => changeTabsValue("5")}
                  src={arrowCircle}
                  alt="arrow"
                  className="employeeArrowImage"
                />
              </div>
            </div>

            <div>
              {
                employeeLeavePolicyMangerList?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div style={{ padding: "0px 16px", marginTop: "15px" }}>
                        <Row>
                          <Col span={23}>
                            <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>{item?.employee_name}</p>
                          </Col>
                          <Col span={1}>
                            <img src={edit} alt="edit" className="editImage"
                              onClick={() => {
                                setSelectedEmployeeLeavePolicyManager({
                                  ...selectedEmployeeLeavePolicyManager,
                                  user_id: item.employement_number,
                                  policy_id: +item.policy_id
                                })
                                setChangeLeavePolicyModel(true)
                              }}
                            />
                          </Col>
                          <Col span={24}>
                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#939393" }}>{item?.employement_number}</p>
                          </Col>
                          <Col span={24}>
                            <p style={{ fontSize: "16px", fontWeight: "400", color: "#939393" }}>{item?.leave_policy}</p>
                          </Col>
                        </Row>
                      </div>
                      {
                        index !== employeeLeavePolicyMangerList?.length - 1 && (
                          <hr className="divider" />
                        )
                      }
                    </div>
                  )
                })
              }
            </div>

          </div>

          <div
            style={{
              height: "8px",
              backgroundColor: "#F7F8FC",
              marginTop: "20px",
            }}
          />

          {/* leave type */}
          <div
            style={{ marginTop: "30px", padding: "10px", background: "white" }}
          >
            <div className="myTeamContainerNew">
              <div className="myTeamTextNew">Leave Type</div>
              <div className="leavePolicyImageContainer">
                <img onClick={() => changeTabsValue("8")}
                  src={arrowCircle}
                  alt="arrow"
                  className="employeeArrowImage"
                />
                <img
                  src={add}
                  alt="add"
                  className="addImage"
                  onClick={() => {
                    setShowLeaveTypeModal(true);
                    onClickHandler();
                  }}
                />
              </div>

            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                marginBottom: "20px",
              }}
            ></div>
            {
              <>
                {leaveTypesList?.map((item, index) => {
                  const icon = item.image;

                  return (
                    <div
                      style={{
                        border: "1px solid #F7F7F7",
                        padding: "25px 20px",
                        borderRadius: "5px",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img src={icon} alt="" width={25} height={25} />
                          <span className="leaveTypeTitle">
                            {item?.leave_type_name}
                          </span>
                        </div>
                        <img
                          src={edit}
                          alt="edit"
                          width={15}
                          height={15}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/admin/leave/leave-type-form/${item.id}`, { replace: true });
                          }}
                        />
                      </div>
                      <div style={{}}>
                        {/* <span style={{ fontSize: "14px", color: "#575757" }}>
                              {item.description}
                            </span> */}
                        <div style={{ width: "100%", marginTop: "20px" }}>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr 1fr 1fr",
                              gap: "10px",
                            }}
                          >
                            {item.data.map((el, i) => {
                              const leaveTypeIcon =
                                process.env.REACT_APP_IMG_BASE_URL + el.icon;

                              const iconLabel = iconLabelList.filter(
                                (x) => x.name == el.name
                              );

                              const content = el.text.map((data) => {
                                return (
                                  <>
                                    {data.value && (
                                      <>
                                        <p
                                          style={{
                                            color: "#252C58",
                                            fontSize: "16px",
                                            margin: "0px",
                                          }}
                                        >
                                          {data.title}
                                        </p>
                                        <p
                                          style={{
                                            color: "#6A6A6A",
                                            fontSize: "14px",
                                            margin: "0px",
                                          }}
                                        >
                                          {data.value}
                                        </p>
                                      </>
                                    )}
                                  </>
                                );
                              });
                              return (
                                <>
                                  {i < 8 && (
                                    <Popover
                                      placement="top"
                                      title={iconLabel[0]?.value}
                                      content={content}
                                    >
                                      <img
                                        key={i}
                                        src={leaveTypeIcon}
                                        alt={leaveTypeIcon}
                                      />
                                      <p
                                        style={{
                                          fontSize: "10px",
                                          color: "#313131",
                                          textAlign: "center",
                                        }}
                                      >
                                        {iconLabel[0]?.value}
                                      </p>
                                    </Popover>
                                  )}
                                  {!item.isCollapsed && i >= 8 && (
                                    <Popover
                                      placement="top"
                                      title={iconLabel[0]?.value}
                                      content={content}
                                    >
                                      <img
                                        key={i}
                                        src={leaveTypeIcon}
                                        alt={leaveTypeIcon}
                                      />
                                      <p
                                        style={{
                                          fontSize: "10px",
                                          color: "#313131",
                                          textAlign: "center",
                                        }}
                                      >
                                        {iconLabel[0]?.value}
                                      </p>
                                    </Popover>
                                  )}
                                </>
                              );
                            })}
                          </div>
                          <>
                            {item?.data?.length > defaultDisplayLimit && (
                              <div onClick={() => handleToggleCollapse(index)}>
                                <center>
                                  {item.isCollapsed ? (
                                    <DownOutlined
                                      style={{
                                        color: "#008080",
                                        fontWeight: "bolder",
                                        fontSize: "x-large",
                                      }}
                                    />
                                  ) : (
                                    <UpOutlined
                                      style={{
                                        color: "#008080",
                                        fontWeight: "bolder",
                                        fontSize: "x-large",
                                      }}
                                    />
                                  )}
                                </center>
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            }
          </div>
        </>
      ) : (
        <>
          <div className="flexSpaceBetween">
            {/* <p className="heading">Leave</p> */}
            {/* <Button className='applyLeaceButton'>Apply Leave</Button> */}
          </div>
          {/* Employee Leaves */}
          <div className="leavePolicyContainer">
            <div>
              <div className="flexSpaceBetween">
                <p className="leavePolicyHeading">Employee Leaves</p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <img
                    src={fullView}
                    alt="fullView"
                    width={35}
                    height={35}
                    onClick={() => {
                      changeTabsValue("2");
                    }}
                  />
                </div>
              </div>
              <Table
                columns={employeeLeaveColumn}
                dataSource={employeeLeaveDetailList}
                pagination={false}
              />
            </div>
          </div>

          {/* leave Policy */}
          <div className="leavePolicyContainer" style={{ marginTop: "30px" }}>
            <div>
              <div className="flexSpaceBetween">
                <p className="leavePolicyHeading">Leave Policy</p>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Button
                    className="addNewPolicyButton"
                    onClick={() => {
                      fetchLeaveType();
                      setOpenLeavePolicy(true);
                    }}
                  >
                    Add New Policy
                  </Button>
                  {/* <LeavePolicy /> */}
                  <img
                    src={fullView}
                    alt="fullView"
                    width={35}
                    height={35}
                    onClick={() => {
                      changeTabsValue("3");
                    }}
                  />
                </div>
              </div>
              <Table
                columns={leavePolicyColumns}
                dataSource={leavePolicyDataList}
                pagination={false}
              />
            </div>
          </div>

          {/* Leave Report */}
          <div className="leaveReportContainer">
            <div>
              <div className="flexSpaceBetween">
                <p className="leaveReportHeading">Leave Report</p>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >

                  <Select
                      style={{ width: 300, height: 40}}
                      placeholder="Select an option"
                      onChange={handleDaysChangeForTable}
                      // dafaultValue={attendanceReportDaysForTable}
                      value={leaveReportDaysForTable}
                  >
                      <Select.Option value={7}>Last 7 days</Select.Option>
                      <Select.Option value={14}>Last 14 days</Select.Option>
                      <Select.Option value={30}>Last 30 days</Select.Option>
                      <Select.Option value="custom">
                      <Popover
                          title=""
                          content={
                          <div className="modal-content">
                              <RangePicker
                              value={dateRange}
                              onChange={handleDateChangeForTable}
                              format="YYYY-MM-DD"
                              disabledDate={(current) =>
                                  current && current > moment().endOf("day")
                              }
                              />
                          </div>
                          }
                          getPopupContainer={getPopupContainer}
                          placement="right"
                      >
                          <p
                          style={{
                              cursor: "pointer",
                              width: "90%",
                              margin: "0px",
                          }}
                          >
                          Custom Date
                          </p>
                      </Popover>
                      </Select.Option>
                  </Select>


                  <LeaveReportSearchBar
                    setTableDataForSearchEmployee={setLeaveReportList}
                    fetchTableDataFromApi={fetchLeaveReportData}
                    placeholderText="Search for employees"
                    empType="admin"
                  />

                  {/* <img
                    src={downloadImg}
                    alt="downloadImg"
                    onClick={() => {
                      downloadReport();
                    }}
                  /> */}

                  <Popover placement="bottomRight" trigger="click" title="Download report" content={leaveReportDownloadPopover} arrow={false}>
                    <img src={download} alt="download" width={35} height={35}
                    />
                  </Popover>

                  <img
                    src={fullView}
                    alt="fullView"
                    width={35}
                    height={35}
                    onClick={() => {
                      changeTabsValue("4");
                    }}
                  />
                </div>
              </div>
              <Table
                columns={leaveReportColums}
                dataSource={leaveReportList}
                pagination={false}
              />
            </div>
          </div>

          {/* Comp Off */}
          <div style={{ marginTop: "30px" }}>
            <div className="leavePolicyContainer">
              <div>
                <div className="flexSpaceBetween">
                  <p className="leavePolicyHeading">Compensatory Off</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Button
                      style={{
                        background: "#008080",
                        color: "white",
                        height: "40px",
                      }}
                      onClick={() => setAddCompOffModal(true)}
                    >
                      Add Compensatory Off
                    </Button>
                    <img
                      src={fullView}
                      alt="fullView"
                      width={35}
                      height={35}
                      onClick={() => {
                        changeTabsValue("7");
                      }}
                    />
                  </div>
                </div>
                <Table
                  columns={compOffColumn}
                  dataSource={compOffList}
                  pagination={false}
                />
              </div>
            </div>
          </div>

          {/* Employee Leave Policy Manager */}

          <div style={{ marginTop: "30px" }}>
            <div className="leavePolicyContainer">
              <div>
                <div className="flexSpaceBetween">
                  <p className="leavePolicyHeading">Employee Leave Policy Manager</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <img
                      src={fullView}
                      alt="fullView"
                      width={35}
                      height={35}
                      onClick={() => {
                        changeTabsValue("5");
                      }}
                    />
                  </div>
                </div>
                <Table
                  columns={employeeLeavePolicyMangerColumns}
                  dataSource={employeeLeavePolicyMangerList}
                  pagination={false}
                />
              </div>
            </div>
          </div>

          {/* Holiday */}

          <div style={{ marginTop: "30px" }}>
            <div className="leavePolicyContainer">
              <div>
                <div className="flexSpaceBetween">
                  <p className="leavePolicyHeading">Holidays</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Button
                      style={{
                        background: "#008080",
                        color: "white",
                        height: "40px",
                      }}
                      onClick={() => setOpenHolidayModal(true)}
                    >
                      Add Holiday List
                    </Button>
                    <img
                      src={fullView}
                      alt="fullView"
                      width={35}
                      height={35}
                      onClick={() => {
                        changeTabsValue("6");
                      }}
                    />
                  </div>
                </div>
                <Table
                  columns={holidayColumns}
                  dataSource={holidayList}
                  pagination={false}
                />
              </div>
            </div>
          </div>

          {/* Leave Type */}
          <div className="leaveTypeContainer">
            <div className="flexSpaceBetween">
              <p className="leaveTypeHeading">Leave Type</p>
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <Button
                  className="addNewTypeButton"
                  onClick={() => {
                    changeTabsValue("8");
                  }}
                >
                  Add New Type
                </Button>
                {/* <img src={fullView} alt="fullView" width={35} height={35} onClick={() => { navigate("/leave/admin/leave-type") }} /> */}
              </div>
            </div>
            {leaveTypesList?.map((item, index) => {
              const icon = item.image;

              return (
                <div
                  style={{
                    border: "1px solid #B7B7B7",
                    padding: "25px 30px",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col span={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <img src={icon} alt="" width={30} height={30} />
                        <span className="leaveTypeTitle">
                          {item.leave_type_name}
                        </span>
                      </div>
                    </Col>
                    <Col span={17}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                          gap: "10px",
                        }}
                      >
                        {item.data.map((el, i) => {
                          const leaveTypeIcon =
                            process.env.REACT_APP_IMG_BASE_URL + el.icon;

                          const iconLabel = iconLabelList.filter(
                            (x) => x.name == el.name
                          );

                          const content = el.text.map((data) => {
                            return (
                              <>
                                {data.value && (
                                  <>
                                    <p
                                      style={{
                                        color: "#3F3F3F",
                                        fontSize: "16px",
                                        margin: "0px",
                                      }}
                                    >
                                      {data.title}
                                    </p>
                                    <p
                                      style={{
                                        color: "#6A6A6A",
                                        fontSize: "14px",
                                        margin: "0px",
                                      }}
                                    >
                                      {data.value}
                                    </p>
                                  </>
                                )}
                              </>
                            );
                          });

                          return (
                            <div key={i}>
                              {i < 8 && (
                                <Popover
                                  placement="top"
                                  title={iconLabel[0]?.value}
                                  content={content}
                                >
                                  <img
                                    src={leaveTypeIcon}
                                    alt={leaveTypeIcon}
                                  />
                                </Popover>
                              )}
                              {!item.isCollapsed && i >= 8 && (
                                <Popover
                                  placement="top"
                                  title={iconLabel[0]?.value}
                                  content={content}
                                >
                                  <img
                                    src={leaveTypeIcon}
                                    alt={leaveTypeIcon}
                                  />
                                </Popover>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <>
                        {item?.data?.length > defaultDisplayLimit && (
                          <div onClick={() => handleToggleCollapse(index)}>
                            <center>
                              {item.isCollapsed ? (
                                <DownOutlined
                                  style={{
                                    color: "#008080",
                                    fontWeight: "bolder",
                                    fontSize: "x-large",
                                  }}
                                />
                              ) : (
                                <UpOutlined
                                  style={{
                                    color: "#008080",
                                    fontWeight: "bolder",
                                    fontSize: "x-large",
                                  }}
                                />
                              )}
                            </center>
                          </div>
                        )}
                      </>
                    </Col>
                    <Col span={1}>
                      <img
                        src={edit}
                        alt="edit"
                        width={15}
                        height={15}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/admin/leave/leave-type-form/" + item.id, { replace: true });
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </>
      )}

      {/* Leave Policy Form */}
      <Modal
        open={openLeavePolicy}
        onOk={() => { }}
        onCancel={() => {
          setOpenLeavePolicy(false);
          setLeavePolicyId(null);
          fetchLeaveType();
          setLeavePolicyForm({
            name: "",
            start_time: "",
            end_time: "",
            leave_type_details: [],
          });
        }}
        footer={null}
        centered
        width={1000}
      >
        <p className="leavePolicyModelHeading">Leave Policy</p>
        <Form
          layout="vertical"
          onFinish={onFinishPolicyForm}
          fields={[
            {
              name: ["name"],
              value: leavePolicyForm.name,
            },
            {
              name: ["start_time"],
              value: leavePolicyForm.start_time,
            },
            {
              name: ["end_time"],
              value: leavePolicyForm.end_time,
            },
          ]}
        >
          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }}>
              <Form.Item
                label="Policy Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Policy Name!",
                  },
                ]}
              >
                <Input
                  className="leavePolicyInputField"
                  // placeholder='Enter Policy Name'
                  onChange={(e) =>
                    setLeavePolicyForm({
                      ...leavePolicyForm,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          {screenSize.width < 768 ? (
            // mobile view
            <Row gutter={[0, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Date (From)"
                  name="start_time"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Date (From)!",
                    },
                  ]}
                >
                  <DatePicker
                    className="leavePolicyInputField"
                    onChange={(e) =>
                      setLeavePolicyForm({ ...leavePolicyForm, start_time: e })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Date (To)"
                  name="end_time"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Date (To)!",
                    },
                  ]}
                >
                  <DatePicker
                    className="leavePolicyInputField"
                    onChange={(e) =>
                      setLeavePolicyForm({ ...leavePolicyForm, end_time: e })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            // desktop view
            <Row gutter={[32, 32]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Date (From)"
                  name="start_time"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Date (From)!",
                    },
                  ]}
                >
                  <DatePicker
                    className="leavePolicyInputField"
                    onChange={(e) =>
                      setLeavePolicyForm({ ...leavePolicyForm, start_time: e })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Date (To)"
                  name="end_time"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Date (To)!",
                    },
                  ]}
                >
                  <DatePicker
                    className="leavePolicyInputField"
                    onChange={(e) =>
                      setLeavePolicyForm({ ...leavePolicyForm, end_time: e })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <p className="noOfLeaveDaysHeadingModel">No. of leave Days</p>
              {leavePolicyForm?.leave_type_details?.map((item, index) => (
                <div key={index}>
                  <Row>
                    <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                      <p className="leaveNameModel">{item.name}</p>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 19 }}>
                      <Form.Item style={{ margin: "0px" }}>
                        <Select
                          size="default"
                          className="selectNoOfLeave"
                          onChange={(value) => {
                            if (
                              leavePolicyForm.leave_type_details[index]
                                .leaves_numbers === null
                            ) {
                              setLeavePolicyForm(
                                update(leavePolicyForm, {
                                  leave_type_details: {
                                    [index]: {
                                      id: {
                                        $set: "",
                                      },
                                      leave_type_id: {
                                        $set: leavePolicyForm
                                          .leave_type_details[index].id,
                                      },
                                      leaves_numbers: {
                                        $set: value,
                                      },
                                    },
                                  },
                                })
                              );
                            } else {
                              setLeavePolicyForm(
                                update(leavePolicyForm, {
                                  leave_type_details: {
                                    [index]: {
                                      leaves_numbers: {
                                        $set: value,
                                      },
                                    },
                                  },
                                })
                              );
                            }
                          }}
                          value={item.leaves_numbers}
                        >
                          {[...Array(51)].map((_, i) => (
                            <Select.Option key={i} value={i}>
                              {i}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
            </Col>
          </Row>
          {
            isClone ?
              <Button htmlType="submit" className="addNewPolicyFormButton">
                Clone Policy
              </Button>
              :
              <Button htmlType="submit" className="addNewPolicyFormButton">
                {leavePolicyId ? "Update Policy" : "Add New Policy"}
              </Button>
          }

        </Form>
      </Modal>

      {/* Comp Off */}

      <Modal
        open={addCompOffModal}
        onOk={() => { }}
        onCancel={() => {
          setAddCompOffModal(false);
        }}
        footer={null}
        centered
        width={540}
      >
        <p className="leavePolicyModelHeading">Comp Off</p>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishCompOff}
        >
          <Form.Item
            label="Employee Name"
            name="employee"
            rules={[
              {
                required: true,
                message: "Please enter your Employee Name!",
              },
            ]}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
            >
              {employeeList.map((option, index) => (
                <Select.Option key={index} value={option.employement_number}>
                  {option.employee_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Leave Type"
            name="leave_type"
            rules={[
              {
                required: true,
                message: "Please enter your Leave Type!",
              },
            ]}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
            >
              {compOffLeaveTypeList.map((option, index) => (
                <Select.Option key={index} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Number Of Comp Off"
            name="leaves_number"
            rules={[
              {
                required: true,
                message: "Please enter your Number Of Comp Off!",
              },
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
          <Button
            htmlType="submit"
            style={{
              width: "100%",
              color: "white",
              background: "#008080",
              height: "44px",
              marginTop: "40px",
            }}
          >
            Submit
          </Button>
        </Form>
      </Modal>

      {/* add holiday in list */}
      <Modal
        open={openHolidayModal}
        onOk={() => { }}
        onCancel={() => {
          setOpenHolidayModal(false);
          form.resetFields();
        }}
        footer={null}
        centered
        width={540}
      >
        <p className="leavePolicyModelHeading">Holiday</p>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishHoliday}
          onValuesChange={onValuesChange}
        >
          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }}>
              <Form.Item
                label="Year"
                name="year"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Year!",
                  },
                ]}
              >
                <Select
                  size="large"
                  onChange={(e) =>
                    setLeavePolicyForm({ ...leavePolicyForm, start_time: e })
                  }
                >
                  <Select.Option value={new Date().getFullYear()}>
                    {new Date().getFullYear()}
                  </Select.Option>
                  <Select.Option value={new Date().getFullYear() + 1}>
                    {new Date().getFullYear() + 1}
                  </Select.Option>
                  <Select.Option value={new Date().getFullYear() + 2}>
                    {new Date().getFullYear() + 2}
                  </Select.Option>
                  <Select.Option value={new Date().getFullYear() + 3}>
                    {new Date().getFullYear() + 3}
                  </Select.Option>
                  <Select.Option value={new Date().getFullYear() + 4}>
                    {new Date().getFullYear() + 4}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Col span={24}>
            <Form.Item
              label="Leave Policy"
              name="leave_policy"
              rules={[
                {
                  required: true,
                  message: "Please enter your Leave Policy!",
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                value={selectedOptions}
                name="leave_policy"
                onSelect={handleSelectAll}
              >
                <Select.Option value="all">All</Select.Option>
                {holidayLeavePolicyList.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <p>Upload File</p>
            <Form.Item
              label="Upload File"
              name="end_time"
              rules={[
                {
                  required: true,
                  message: "Please enter your Date (To)!",
                },
              ]}
            >
              <div className="file-upload-container">
                <input
                  type="file"
                  id="file-upload"
                  className="input-file"
                  onChange={(e) => {
                    const selectedFile = e.target.files[0];
                    const maxSizeInBytes = 5 * 1024 * 1024;

                    if (selectedFile && selectedFile.type === "text/csv") {
                      if (selectedFile.size <= maxSizeInBytes) {
                        setHolidayListFile(e.target.files && selectedFile);
                      } else {
                        setHolidayListFile(null);
                        alert("File size exceeds the limit of 5MB.");
                      }
                    } else {
                      // Reset the file state and show an error message
                      setHolidayListFile(null);
                      alert("Please upload a CSV file.");
                    }
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label
                    htmlFor="file-upload"
                    className="file-upload-label"
                  ></label>
                  {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                  <p style={{ zIndex: 1, margin: "0px" }}>
                    {holidayListFile ? holidayListFile.name : "Choose a file"}
                  </p>
                </div>
              </div>
            </Form.Item>
          </Col>

          <Button
            htmlType="submit"
            style={{
              width: "100%",
              color: "white",
              background: "#008080",
              height: "44px",
              marginTop: "40px",
            }}
          >
            Submit
          </Button>
        </Form>
      </Modal>

      {/* edit holiday */}
      <Modal
        title="Edit Holiday"
        centered
        open={openHolidayEditModal}
        onOk={() => setOpenHolidayEditModal(false)}
        onCancel={() => {
          setOpenHolidayEditModal(false)
          setHolidayListFile(null)
        }}
        footer={null}
      >
        <Form
          layout="vertical"
          // initialValues={policies}
          onFinish={editHoliday}
        >
          <Row>
            <Col span={24}>
              <p>Upload File</p>
              <Form.Item
                label="Upload File"
                name="holiday_file"
              >
                <div className="file-upload-container">
                  <input
                    type="file"
                    id="file-upload"
                    className="input-file"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      const maxSizeInBytes = 5 * 1024 * 1024;

                      if (selectedFile && selectedFile.type === "text/csv") {
                        if (selectedFile.size <= maxSizeInBytes) {
                          setHolidayListFile(e.target.files && selectedFile);
                        } else {
                          setHolidayListFile(null);
                          alert("File size exceeds the limit of 5MB.");
                        }
                      } else {
                        // Reset the file state and show an error message
                        setHolidayListFile(null);
                        alert("Please upload a CSV file.");
                      }
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label
                      htmlFor="file-upload"
                      className="file-upload-label"
                    ></label>
                    {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                    <p style={{ zIndex: 1, margin: "0px" }}>
                      {holidayListFile ? holidayListFile.name : "Choose a file"}
                    </p>
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Button htmlType="submit" style={{ background: "#008080", color: "white", marginTop: "40px", height: "40px" }}>Update</Button>
        </Form>
      </Modal>

      {/* Change Leave Policy */}
      <Modal
        title="Change Leave Policy"
        centered
        open={changeLeavePolicyModel}
        onOk={() => setChangeLeavePolicyModel(false)}
        onCancel={() => setChangeLeavePolicyModel(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          // initialValues={policies}
          onFinish={updateEmployeeLeavePolicy}
          fields={[
            {
              name: "leave_policy",
              value: selectedEmployeeLeavePolicyManager.policy_id
            }
          ]}
        >
          <Form.Item
            label="Attendance Policy"
            name="leave_policy"
            rules={[
              {
                required: true,
                message: 'Please Select Leave Policy!',
              },
            ]}
          >
            <Select
              size='large'
            >
              {
                leavePolicy.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                  )
                }
                )
              }
            </Select>

          </Form.Item>
          <Button htmlType="submit" style={{ background: "#008080", color: "white" }}>Update</Button>
        </Form>
      </Modal>

      {/* download leave report modal */}
      <Modal
        title="Download Report"
        centered
        open={downloadModal}
        // onOk={() => setReviseSalary(false)}
        onCancel={() => setDownloadModal(false)}
        width={800}
        footer={null}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
          <Select
            style={{ width: "100%", marginTop: "20px" }}
            placeholder="Select an option"
            onChange={handleDaysChange}
            defaultValue={leaveReportDays}
            size='large'
          >
            <Select.Option value={7}>Last 7 days</Select.Option>
            <Select.Option value={14}>Last 14 days</Select.Option>
            <Select.Option value={30}>Last 30 days</Select.Option>
            <Select.Option value="custom">
              <span
                onClick={() => {
                  setOpen(true);
                }}
              >
                Custom Date
              </span>
            </Select.Option>
          </Select>

          <Modal
            title="Select Date Range"
            centered
            open={open}
            onCancel={() => setOpen(false)}
            onOk={async () => {

              setOpen(false);
            }}
          >
            <RangePicker
              value={dateRange}
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              style={{ color: "black !important" }}
            />
          </Modal>


        </div>

        <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }}
          onClick={() => downloadReport()}
        >
          Download
        </Button>

      </Modal>
    </div>
  );
}

export default LeaveDashboardView;
