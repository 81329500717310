import React, {useState, useEffect} from 'react';
import moment from 'moment';
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
import ApplyJob from '../../../Recruitment/User/ApplyJob/ApplyJob';
import {Table, DatePicker, Space, Modal, Select, Spin} from 'antd';
import CalenderIcon from '../../../../assets/tracking/calendericon.svg';
import TimeIcon from '../../../../assets/tracking/time.svg';
import LocationIcon from '../../../../assets/tracking/LocationIcomn.svg';
import request from '../../../../utils/axios';
import fullView from '../../../../assets/fullView.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import AttendMeeting from '../../AttendMeeting/AttendMeeting';
import {toast} from 'react-toastify';
import update from 'immutability-helper';
import getScreenSize from '../../../../utils/getScreenSize';
import MobileStyle from './MoileStyle.module.scss';
const Meeting = ({changeTabsValue, tabsValue}) => {
  const screenSize = getScreenSize();
  const {RangePicker} = DatePicker;
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterDate, setFilterDate] = useState([]);
  const [reporting_manager, setReporting_manager] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [attendPopup, setAtttendPop] = useState(false);
  const [attendRecord, setAtttendRecord] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Number of items per page
    total: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let Reporting_Manager = localStorage?.getItem('Is_Reporting_Mannger');
    setReporting_manager(JSON.parse(Reporting_Manager)?.is_reporting_manager);
    LoadData();
  }, []);

  const LoadData = async newPaginaiton => {
    const {current, pageSize} = newPaginaiton || pagination;
    const apiUrl = `/tracking/employee/employee-meeting/details?page=${current}&page_size=${pageSize}&from_date=${
      filterDate.length > 0 ? filterDate[0] : ''
    }&to_date=${filterDate.length > 0 ? filterDate[1] : ''}`;

    try {
      const res = await request({
        method: 'get',
        url: apiUrl,
      });
      setDataList([...dataList, ...res.data?.pagination_data?.data]);

      setPagination({
        ...pagination,
        current: pagination.current + 1,
        total: res?.data.pagination_data?.total,
      });

      if (res?.data?.pagination_data?.next === null) {
        setHasMore(false);
        return;
      }
      // setDataList([...dataList, ...res.data?.pagination_data?.data]);
      // setPagination({
      //   ...pagination,
      //   current: pagination.current + 1,
      //   total: res?.data.pagination_data?.total,
      // });

      // if (res?.data?.pagination_data?.next === null) {
      //   setHasMore(false);
      //   return;
      // }
      // console.log(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const isWebView = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return userAgent.includes('MyAppWebView/1.0');
  };
  console.log(isWebView());
  const handleDateChange = async value => {
    const formattedRange = value.map(dateObj => {
      return moment(dateObj.$d).format('YYYY-MM-DD');
    });
    setDataList([]);
    setFilterDate(formattedRange);
    setDateRange(value);
  };
  const handleDelete = async (record, index) => {
    // console.log(record);
    const apiUrl = `tracking/employee/employee-meeting/delete-meeting`;

    try {
      const res = await request({
        method: 'post',
        url: apiUrl,
        data: {
          id: record.id,
        },
      });
      const updatedArray = update(dataList, {$splice: [[index, 1]]});
      setDataList(updatedArray);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const handleSelectChange = (value, record, index, key) => {
    if (value == 'Attend') {
      setAtttendRecord({...record, ['key']: key, ['index']: index});
      setAtttendPop(true);
    }
    if (value == 'Delete') {
      handleDelete(record, index);
    }
    if (value == 'Edit') {
      navigate('/tracking/editmeeting', {
        state: {MeetingType: 'MyMeeting', record: record},
      });
    }
    // console.log(`selected ${value}`);
  };
  function sendMessageToReactNative(id) {
    console.log(id);
    const AttendendPayload = {
      AttendMetting: 'triggerAttendMeeting',
      MeetingId: id,
    };
    if (
      window.ReactNativeWebView &&
      typeof window.ReactNativeWebView.postMessage === 'function'
    ) {
      window.ReactNativeWebView.postMessage(JSON.stringify(AttendendPayload));
    } else {
      console.warn('ReactNativeWebView is not defined.');
    }
  }
  const handleAttend = async record => {
    if (isWebView()) {
      sendMessageToReactNative(record.id);
      setLoading(true);
    } else {
      navigate(`/tracking/attendmeeting/${record.id}`, {
        state: {record: record},
      });
    }
  };
  const handleSelectChangeMoible = (value, record, index, key) => {
    console.log(value, record, index, key);
    if (value == 'Attend') {
      handleAttend(record);
    }
    if (value == 'Delete') {
      handleDelete(record, index);
    }
    if (value == 'Edit') {
      navigate('/tracking/editmeeting', {
        state: {MeetingType: 'MyMeeting', record: record},
      });
    }
  };
  const handleAttendMeeting = async (longitude, latitude, record) => {
    console.log(record);
    const apiUrl = `tracking/employee/employee-meeting/marked?longitude=${longitude}&latitude=${latitude}&id=${record.id}`;

    try {
      const res = await request({
        method: 'get',
        url: apiUrl,
      });
      setAtttendPop(false);
      const updatedArray = update(dataList, {
        [record.index]: {
          [record.key]: {
            $set: 'Attended',
          },
        },
      });
      setDataList(updatedArray);

      // setDataList([])
      // setPagination({
      //   current: 1,
      //   pageSize: 10, // Number of items per page
      //   total: 0,
      // })
      // LoadData();
      console.log(res);
    } catch (error) {
      console.error('Error fetching data: ', error);
      toast.error(error.message, {
        position: 'bottom-left',
      });
    }
  };
  const internalJobColumns = [
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      //   render: (text, record) => (
      //     <span>{moment(text).format("DD-MM-YYYY")}</span>
      //   ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <span>{moment(text).format('DD-MM-YYYY')}</span>
      ),
    },
    {
      title: 'Time Duration',
      dataIndex: 'meeting_time',
      key: 'meeting_time',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}>
          <div>{text}</div>
          <img src={LocationIcon} />
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div
          style={{
            backgroundColor: text == 'Attended' ? '#D4EDDA' : '#FFDBDB',
            color: text == 'Attended' ? '#28B15F' : '#FD3434',
            borderRadius: '24px',
            padding: '6px 15px',
            fontSize: '16px',
            font: '400',
            textAlign: 'center',
          }}>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      render: (text, record, index) => {
        // Determine if the 'Attend' option should be disabled
        const isAttendDisabled = record.status === 'Attended';

        return (
          <Select
            defaultValue="Select"
            style={{width: 180}}
            onChange={value =>
              handleSelectChange(value, record, index, 'status')
            }
            options={[
              {
                value: 'Attend',
                label: 'Attend',
                disabled: isAttendDisabled, // Disable 'Attend' option if status is 'attended'
              },
              {
                value: 'Delete',
                label: 'Delete',
              },
              {
                value: 'Edit',
                label: 'Edit',
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <>
      {screenSize.width < 768 ? (
        <>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vw',
              }}>
              {' '}
              <Spin size="large" />
            </div>
          ) : (
            <div className={MobileStyle.container}>
              <div className={MobileStyle.headerContainer}>
                <h2>My Meeting</h2>
                <div
                  className={MobileStyle.filterdiv}
                  onClick={() => {
                    setOpen(true);
                  }}>
                  <img src={CalenderIcon} />
                  {filterDate.length > 0
                    ? `${filterDate[0]} To ${filterDate[1]}`
                    : 'Today'}
                </div>
              </div>
              <div className={MobileStyle.contentContainer}>
                <div
                  id="scrollableDiv"
                  style={{
                    height: 'auto',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                  }}>
                  <InfiniteScroll
                    dataLength={
                      pagination.total - dataList?.length * pagination.current
                    }
                    next={LoadData}
                    hasMore={hasMore}
                    endMessage={
                      <p style={{textAlign: 'center'}}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                    loader={
                      <p style={{textAlign: 'center'}}>
                        <b>Loading...</b>
                      </p>
                    }
                    scrollableTarget="scrollableDiv">
                    {dataList.map((item, index) => {
                      return (
                        <div className={MobileStyle.meetingDiv} key={index}>
                          <div className={MobileStyle.top}>
                            <div className={MobileStyle.namecontainer}>
                              <div className={MobileStyle.name}>
                                {item.client_name}
                              </div>
                              <div
                                className={MobileStyle.status}
                                style={{
                                  backgroundColor:
                                    item?.status == 'Attended'
                                      ? '#D4EDDA'
                                      : '#FFDBDB',
                                  color:
                                    item.status == 'Attended'
                                      ? '#28B15F'
                                      : '#FD3434',
                                }}>
                                {item.status}
                              </div>
                            </div>
                            <div className={MobileStyle.selectcontainer}>
                              <Select
                                defaultValue="Select"
                                // style={{width: 100}}
                                onChange={value =>
                                  handleSelectChangeMoible(
                                    value,
                                    item,
                                    index,
                                    'status',
                                  )
                                }
                                options={[
                                  {
                                    value: 'Attend',
                                    label: 'Attend',
                                    disabled: item.isAttendDisabled, // Disable 'Attend' option if status is 'attended'
                                  },
                                  {
                                    value: 'Delete',
                                    label: 'Delete',
                                  },
                                  {
                                    value: 'Edit',
                                    label: 'Edit',
                                  },
                                ]}
                              />
                            </div>
                          </div>
                          <div className={MobileStyle.bottom}>
                            <div className={MobileStyle.dateContainer}>
                              <img src={CalenderIcon} alt="calendericon" />
                              <span>
                                {moment(item.date).format('DD-MM-YYYY')}
                              </span>
                            </div>
                            <div className={MobileStyle.duration}>
                              {' '}
                              <img src={TimeIcon} alt="calendericon" />
                              <span>{item.meeting_time}</span>
                            </div>
                            <div className={MobileStyle.containerlocation}>
                              <div className={MobileStyle.leftcontainer}>
                                <img src={LocationIcon} alt="locationicon" />
                                <div className={MobileStyle.location}>
                                  <div>{item.location}</div>
                                  {/* <div>Gurugram, Haryana 122018</div> */}
                                </div>
                              </div>
                              <div className={MobileStyle.rightcontainer}>
                                <img src={LocationIcon} alt="locationicon" />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div style={{background: 'white', padding: '30px 30px'}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              // border: "1px solid red",
              // padding: "50px",
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '24px',
              }}>
              <p
                style={{fontSize: '24px', fontWeight: '600', color: '#161616'}}>
                My Meeting
              </p>
              <div
                style={{
                  display: 'flex',
                  gap: '24px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                {reporting_manager && (
                  <div
                    style={{
                      backgroundColor: '#008080',
                      color: '#fff',
                      fontSize: '16px',
                      font: 400,
                      borderRadius: '5px',
                      padding: '9px 40px',
                      display: 'flex',
                      gap: '10px',
                      justifyCointent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigate('/tracking/addmeeting', {
                        state: {MeetingType: 'MyMeeting'},
                      });
                    }}>
                    Add Meeting
                  </div>
                )}
                <div
                  style={{
                    border: '1px solid #008080',
                    fontSize: '16px',
                    font: 400,
                    borderRadius: '5px',
                    padding: '9px 40px',
                    display: 'flex',
                    gap: '10px',
                    justifyCointent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}>
                  <img src={CalenderIcon} />
                  {filterDate.length > 0
                    ? `${filterDate[0]} To ${filterDate[1]}`
                    : 'Today'}
                </div>
                {/* {tabsValue != '4' && tabsValue != '1' && (
              <img
                src={fullView}
                alt="fullView"
                width={40}
                height={40}
                style={{marginLeft: '20px'}}
                onClick={() => {
                  changeTabsValue('4');
                }}
              />
            )} */}
              </div>
            </div>

            <div
              id="scrollableDiv"
              style={{
                height: 'auto',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}>
              <InfiniteScroll
                dataLength={
                  pagination.total - dataList?.length * pagination.current
                }
                next={LoadData}
                hasMore={hasMore}
                endMessage={
                  <p style={{textAlign: 'center'}}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
                loader={
                  <p style={{textAlign: 'center'}}>
                    <b>Loading...</b>
                  </p>
                }
                scrollableTarget="scrollableDiv"
                height="70vh">
                <Table
                  columns={internalJobColumns}
                  dataSource={dataList}
                  pagination={false}
                />
              </InfiniteScroll>
            </div>
            <Modal
              title="Select Date Range"
              centered
              open={open}
              onCancel={() => setOpen(false)}
              onOk={async () => {
                setOpen(false);
                LoadData({
                  current: 1,
                  pageSize: 10,
                  total: 0,
                });
              }}
              okButtonProps={{
                style: {
                  backgroundColor: '#008080',

                  color: 'white',
                  width: '100px',
                }, // Custom color for the Ok button
              }}
              cancelButtonProps={{
                style: {color: 'black'}, // Optional: Custom style for the cancel button
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <RangePicker
                  value={dateRange}
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                  style={{width: '100%'}} // Ensures the RangePicker takes up full width
                />
              </div>
            </Modal>
            {attendPopup && (
              <AttendMeeting
                attendPopup={attendPopup}
                attendRecord={attendRecord}
                setAtttendPop={setAtttendPop}
                handleAttendMeeting={handleAttendMeeting}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Meeting;
