import { Button, Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import request from '../../../utils/axios';

function EditEmployeeDetails() {
    // const screenSize = useScreenSize();
    const employee_number = localStorage.getItem("employee_number");
    const is_admin = localStorage.getItem("is_admin");
    const [isEdit, setIsEdit] = useState(false);
    const params = useParams();
    const TextArea = Input;
    const [userProfile, setUserProfile] = useState({
        joining_date: "",
        notice_period: "",
        employement_number: "",
        empfunction: "",
        work_location: "",
        employee_type: "",
        reporting_manager: "",
        designation: "",
        department: "",
        work_mode: "",
    })

    const [fullView, setFullView] = useState({
        workLocation: false,
        empType: false,
        grade: false,
        reportingManager: false,
        hrbp: false,
    });

    const fetchProfileData = async () => {
        let data = await request({
            method: "get",
            url: `profile/details/`,
        })

        setUserProfile({
            ...data.data.data,
        })
    }

    const [employeeFunctionList, setEmployeeFunctionList] = useState([]);

    const getEmployeeFunctionList = async () => {
        const data = await request({
            method: "get",
            url: `/employee-type/all`,
        })
        setEmployeeFunctionList(data?.data)
    }

    const [employeeList, setEmployeeList] = useState([])

    const getEmployeeList = async () => {
        let data = await request({
            method: "get",
            url: `get-all-employees`,
        })
        setEmployeeList(data.data)
    }

    useEffect(() => {
        fetchProfileData();
        getEmployeeFunctionList();
        getEmployeeList();
    }, [])

    const onFinish = async (values) => {

        await request({
            method: "put",
            url: `profile/details/update/${params.id}/`,
            data: values,
        })

        fetchProfileData()
        setIsEdit(false)
    };


    return (
        <div>
            <div style={{ padding: "16px" }}>
                <p>Employee Details</p>
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    // onValuesChange={handleFormChange}
                    initialValues={userProfile}
                    fields={[
                        {
                            name: ["notice_period"],
                            value: userProfile.notice_period
                        },
                        {
                            name: ["employement_number"],
                            value: userProfile.employement_number
                        },
                        {
                            name: ["empfunction"],
                            value: userProfile.empfunction
                        },
                        {
                            name: ["work_location"],
                            value: userProfile.work_location
                        },
                        {
                            name: ["employee_type"],
                            value: userProfile.employee_type,
                        },
                        {
                            name: ["grade"],
                            value: userProfile.grade
                        },
                        {
                            name: ["designation"],
                            value: userProfile.designation
                        },
                        {
                            name: ["hrbp"],
                            value: userProfile.hrbp
                        },
                    ]}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label="Notice Period"
                                name="notice_period"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your notice_period!',
                                    },
                                ]}
                            >
                                <Select
                                    size='large'
                                >
                                    <Select.Option value="15">15 Days</Select.Option>
                                    <Select.Option value="30">1 Month</Select.Option>
                                    <Select.Option value="60">2 Months</Select.Option>
                                    <Select.Option value="90">3 MOnths</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Employment No."
                                name="employement_number"
                            >
                                <Input
                                    size='large'
                                    disabled
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Function"
                                name="empfunction"
                            >
                                <Input
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Work Location"
                                name="work_location"
                            >
                                <Select
                                    // className='dropDown'
                                    size='large'
                                >
                                    {/* {
                       cityList.map((item, index) => {
                              return (
                                   <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                               )
                                        }
                                    )
                                    } */}
                                    <Select.Option value="Gurugram">Gurugram</Select.Option>
                                    <Select.Option value="Delhi">Delhi</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Employee Type"
                                name="employee_type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your employee_type!',
                                    },
                                ]}
                            >
                                <Select
                                    size='large'
                                >
                                    {
                                        employeeFunctionList?.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                            )
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Grade"
                                name="grade"
                            >
                                <Input
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Reporting Manager"
                                name="reporting_manager"
                            >
                                <Select
                                    size='large'
                                >
                                    {
                                        employeeList?.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.employement_number}>{item.employee_name}</Select.Option>
                                            )
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Designation"
                                name="designation"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your designation!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="HRBP"
                                name="hrbp"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter HRBP!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button
                        htmlType='submit'
                        style={{ width: "100%", height: "47px", background: "#008080", color: "white" }}
                    >
                        Save
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default EditEmployeeDetails