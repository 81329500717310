import { LinkOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from "./Others.module.scss"
import request from '../../../../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { setOtherDecalaration } from "../../../../../redux/reducer/TaxDeclarationReducer";
function Others({ windowOpen }) {
    const dispatch = useDispatch();
    const otherDecalaration = useSelector(state => state.taxDeclaration.otherDecalaration)

    const [otherChoices, setOtherChoices] = useState([])


    const fetchSection80GList = async () => {
        try {
            const data = await request({
                method: "get",
                url: `regim_tax_rules/other_source_choices`,
            })

            setOtherChoices([...data.data])

        }
        catch (error) {
            console.log("error", error);
        }
    }

    const fetchOthersDeclarationData = async () => {
        try {
            const data = await request({
                method: "get",
                url: `regim_tax_rules/get-all-other-source-declaration`,
            })

            dispatch(setOtherDecalaration({
                ...otherDecalaration,
                choice: data.data.OtherSources.choice,
                declared_amount: data.data.OtherSources.declared_amount,
                attachment: data.data.OtherSources.attachment,
                file_name: data.data.OtherSources.file_name
            }))

        }
        catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        fetchSection80GList()
        fetchOthersDeclarationData()
    }, [])

    const onHandleChange = (key, value) => {

        if (key === "attachment") {

            const reader = new FileReader();
            reader.readAsDataURL(value);
            reader.onload = () => {

                dispatch(setOtherDecalaration({
                    ...otherDecalaration,
                    [key]: reader.result,
                    file_name: value.name
                }))
            };
            reader.onerror = (error) => {
                console.error('Error uploading image: ', error);
            };
        }
        else {
            dispatch(setOtherDecalaration({
                ...otherDecalaration,
                [key]: value
            }))
        }
    }

    return (
        <div className={styles.others}>
            <p className={styles.othersText}>Others</p>
            <div>
                <div className={styles.declartionBox}>
                    <p className={styles.declartionText}>Income From Other Sources</p>
                </div>
                <Form>
                    <Row gutter={32}>
                        <Col span={6}>
                            <Form.Item>
                                <Select size='large'
                                    value={otherDecalaration.choice}
                                    onChange={(e) => onHandleChange("choice", e)}
                                    disabled={windowOpen}
                                >
                                    {
                                        otherChoices.map((item, index) => {
                                            return (
                                                <Select.Option value={item.value}>{item.name}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18}></Col>
                        <Col span={24}>
                            {
                                otherDecalaration.choice !== null &&
                                <Row gutter={32}>
                                    <Col span={6}>
                                        <Form.Item>
                                            <Input size='large'
                                                value={otherDecalaration.declared_amount}
                                                onChange={(e) => onHandleChange("declared_amount", e.target.value)}
                                                disabled={windowOpen}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item>
                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <div
                                                    className={styles.attachFileBox}
                                                >
                                                    <input
                                                        type="file"
                                                        id="file-upload"
                                                        className={styles.inputFile}
                                                        onChange={(e) =>
                                                        // setFile(e.target.files && e.target.files[0])
                                                        {
                                                            console.log("e.target.files[0]", e.target.files[0])

                                                            const selectedFile = e.target.files[0];

                                                            if (selectedFile) {
                                                                onHandleChange("attachment", selectedFile)
                                                                // onHandleChange(index, "attach_file", selectedFile)
                                                                // setFile(e.target.files && selectedFile)
                                                            }
                                                        }
                                                        }
                                                        disabled={windowOpen}
                                                    />
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>

                                                        <label htmlFor="file-upload" >

                                                        </label>
                                                        <p
                                                            className={styles.attachFileLabel}
                                                            style={{ marginTop: "7px" }}>
                                                            <LinkOutlined /> {" "}Attach File
                                                        </p>
                                                    </div>
                                                </div>
                                                <p style={{ margin: '0' }}>{otherDecalaration?.file_name}</p>
                                            </div>

                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </Col>


                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default Others