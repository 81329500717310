import React, { useEffect, useRef, useState } from 'react'
import request from '../../../../utils/axios';
import downloadImg from "../../../../assets/downlaod.svg"
import fullView from "../../../../assets/fullView.svg"
import back from "../../../../assets/Recruitment/back.svg"

import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Select, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import getScreenSize from "../../../../utils/getScreenSize"
import edit from "../../../../assets/edit.svg";

import "./HolidayFullView.scss"
import { toast } from 'react-toastify';
import CommonTable from '../../../../component/CommonTable/CommonTable';
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
function HolidayFullView() {
    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const [form] = Form.useForm()
    const ref = useRef(null);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })
    const [holidayList, setHolidayList] = useState([]);
    const [openHolidayModal, setOpenHolidayModal] = useState(false);
    const [holidayListFile, setHolidayListFile] = useState(null)
    const [holidayLeavePolicyList, setHolidayLeavePolicyList] = useState([])
    const [leavePolicyForm, setLeavePolicyForm] = useState({
        name: "",
        start_time: "",
        end_time: "",
        leave_type_details: [],
    })
    const [selectedOptions, setSelectedOptions] = useState([])
    const [openHolidayEditModal, setOpenHolidayEditModal] = useState(false)
    const [editHolidayDetails, setEditHolidayDetails] = useState(null);


    const holidayColumns = [
        {
            title: 'Year',
            dataIndex: 'annual_year',
            key: 'annual_year',
        },
        {
            title: 'Policy',
            dataIndex: 'leave_policy',
            key: 'leave_policy',
            render: (text, record) => <span>
                {text}
                {/* <span style={{ padding: "5px", background: "#E6F2FF", borderRadius: "50%", color: "#0043FF", marginLeft: "10px" }}>+3</span > */}
            </span>
        },
        {
            title: 'File Name',
            dataIndex: 'file_name',
            key: 'file_name',
        },
        {
            title: 'Action',
            dataIndex: 'holiday_file',
            key: 'holiday_file',
            render: (text, record) =>
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <img
                        src={edit}
                        alt="edit"
                        onClick={() => {
                            setOpenHolidayEditModal(true);
                            setEditHolidayDetails({
                                leave_policy: record.policy_id,
                                annual_year: record.annual_year,
                            });
                        }}
                    />
                    <p
                        style={{
                            borderRadius: "15px",
                            textAlign: "center",
                            cursor: "pointer",
                            margin: "0px"
                        }}
                        onClick={async () => {
                            const response = await request({
                                method: "get",
                                url: `${text}/`,
                                responseType: "text", // Change response type to text
                            });

                            const csvData = new Blob([response], { type: "text/csv" }); // Set MIME type to text/csv
                            const url = window.URL.createObjectURL(csvData);
                            const link = document.createElement("a");
                            link.href = url;
                            link.download = "file.csv"; // Change filename to .csv
                            link.click();
                        }}
                    >
                        <img src={downloadImg} alt="downloadImg" />
                    </p>
                </div>
        }
    ]

    const fetchHolidayList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `leave/admin/holiday/list/details/?page=${pagination.current}&page_size=${pagination.pageSize}`,
            })
            console.log("fetchHolidayList", response);
            setHolidayList(response?.pagination_data.data)
            setPagination({
                ...pagination,
                total: response?.pagination_data?.total
            })
        }
        catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        fetchHolidayList()
    }, [pagination.current])

    const getLeavePolicy = async () => {
        let data = await request({
            method: "get",
            url: `admin/employee/leave-policies/all`,
        })
        console.log("setHolidayLeavePolicyList..", data.data);
        setHolidayLeavePolicyList(data.data)
    }

    const onFinishHoliday = async (values) => {
        try {
            const formData = new FormData();
            formData.append('annual_year', values.year);
            formData.append('leave_policy', values.leave_policy);
            formData.append('holiday_file', holidayListFile);

            const response = await request({
                method: "post",
                url: `leave/leave_policy/holiday/upload`,
                data: formData
            })

            setOpenHolidayModal(false);
            fetchHolidayList();
            // form.resetFields();
            toast.success("Holiday list uploaded ", {
                position: "bottom-left",
            });
            form.resetFields();
        }
        catch (error) {
            console.log("error", error);
            toast.error("Could not upload Holiday list, please try after some time", {
                position: "bottom-left",
            });
        }
    }

    const onValuesChange = (changedValues, allValues) => {
        if (changedValues?.leave_policy) {
            if (changedValues?.leave_policy.includes("all")) {
                form.setFieldsValue({
                    leave_policy: holidayLeavePolicyList.map((item) => {
                        return item.id
                    })
                });
            }
        }
        console.log("changedValues", changedValues);
    }

    const handleSelectAll = () => {
        setSelectedOptions(holidayLeavePolicyList.map((item) => {
            return item.id
        }))
    }

    const editHoliday = async () => {
        try {
            const formData = new FormData();
            formData.append("holiday_file", holidayListFile);
            formData.append("leave_policy", editHolidayDetails.leave_policy);
            formData.append("annual_year", editHolidayDetails.annual_year);

            const response = await request({
                method: "post",
                url: `leave/holiday/list/update/`,
                data: formData,
            });

            setOpenHolidayEditModal(false);
            console.log("onFinishHoliday", response);
            setHolidayListFile(null);
            toast.success("Holiday Updated ", {
                position: "bottom-left",
            });
            // form.resetFields();
        }
        catch (error) {
            console.log("error", error);
            toast.error("Could not upload Holiday list, please try after some time", {
                position: "bottom-left",
            });
        }
    }

    useEffect(() => {
        fetchHolidayList();
        getLeavePolicy();
        // fetchAllLeavePolicy();
    }, [])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    const renderUI = (itemList) => {
        return itemList.map((item, index) => {
            return (
                <>
                    <div style={{ padding: "0px 16px" }}>
                        <div
                            style={{
                                fontSize: "18px",
                                fontWeight: "500px",
                                marginTop: "10px",
                            }}
                        >
                            {item?.annual_year}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "12px",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "45%",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "400px",
                                    color: "#8A8A8A",
                                }}
                            >
                                {item?.leave_policy[0]}
                            </div>
                            {item.leave_policy.length > 1 && (
                                <div
                                    style={{
                                        color: "#0043FF",
                                        fontSize: "12px",
                                        fontWeight: "400px",
                                        height: "20px",
                                        width: "20px",
                                        backgroundColor: "#E6F2FF",
                                        borderRadius: "100px",
                                        padding: 2,
                                        textAlign: "center",
                                    }}
                                >
                                    + {item?.leave_policy.length - 1}
                                </div>
                            )}
                        </div>
                        <div className="fileNameContainer">
                            <div>{item?.file_name}</div>
                            <img
                                src={downloadImg}
                                alt="downlaod"
                                className="downlaodImage"
                            />
                        </div>
                    </div>
                    {index !== holidayList?.length - 1 && (
                        <hr className="divider" />
                    )}
                </>
            );
        })
    }

    return (
        <div className='holidayFullViewContainer' ref={ref}>

            {
                screenSize.width < 768
                    ?
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "15px", padding: "0px 16px", }}>
                            <p style={{ fontSize: "24px", fontWeight: "500", margin: "0px" }}>Holidays</p>
                            <Button
                                style={{ background: "#008080", color: "white", height: "45px" }}
                                onClick={() => setOpenHolidayModal(true)}>
                                Add Holiday List
                            </Button>
                        </div>

                        <InfiniteScrollComponent
                            apiEndpoint="leave/admin/holiday/list/details/"
                            renderUI={renderUI}
                            initialPage={1}
                        />



                        {/* <div style={{ background: "white", padding: "20px" }}>
                            <div className='flexSpaceBetween'>
                                <p style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>Holidays</p>
                                <Button
                                    style={{ background: "#008080", color: "white" }}
                                    onClick={() => navigate("/admin/leave/add-holiday")}>
                                    Add Holiday List
                                </Button>

                            </div>
                            {
                                holidayList?.map((item, index) => {
                                    return (
                                        <div style={{
                                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                            borderRadius: "8px",
                                            padding: "16px",
                                            marginTop: "10px"
                                        }}>
                                            <p style={{ fontSize: "13px", fontWeight: "500", color: "#313131" }}>{item.annual_year}</p>
                                            <p style={{ fontSize: "12px", fontWeight: "400", color: "#696969" }}>{item.leave_policy}
                                                <span style={{ padding: "5px", background: "#E6F2FF", borderRadius: "50%", color: "#0043FF", marginLeft: "10px" }}>+3</span >
                                            </p>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#3C3C3C", margin: "0px " }}>File Name</p>
                                                <DownloadOutlined style={{ color: "#696969" }}

                                                    onClick={async () => {
                                                        const response = await request({
                                                            method: "get",
                                                            url: `${fileBaseUrl}/${item.holiday_file}/`,
                                                            responseType: 'text', // Change response type to text
                                                        });

                                                        const csvData = new Blob([response], { type: 'text/csv' }); // Set MIME type to text/csv
                                                        const url = window.URL.createObjectURL(csvData);
                                                        const link = document.createElement('a');
                                                        link.href = url;
                                                        link.download = 'file.csv'; // Change filename to .csv
                                                        link.click();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div> */}
                    </div>
                    :
                    <>
                        <div style={{ marginTop: "20px", padding: "40px", background: "white" }}>
                            <div className='leavePolicyContainer'>
                                <div>
                                    <div className='flexSpaceBetween'>
                                        <p style={{ fontSize: "20px", fontWeight: "600" }}>Holidays</p>
                                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                            <Button
                                                style={{ background: "#008080", color: "white", height: "45px" }}
                                                onClick={() => setOpenHolidayModal(true)}>
                                                Add Holiday List
                                            </Button>

                                        </div>
                                    </div>
                                    {/* <Table
                                        columns={holidayColumns}
                                        dataSource={holidayList}
                                        pagination={false}
                                    /> */}
                                    <CommonTable
                                        columns={holidayColumns}
                                        data={holidayList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        handleRowClick={() => { }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
            }


            <Modal
                open={openHolidayModal}
                onOk={() => { }}
                onCancel={() => {
                    setOpenHolidayModal(false)
                    form.resetFields();
                }}
                footer={null}
                centered
                width={540}
            >
                <p className='leavePolicyModelHeading'>Holiday</p>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinishHoliday}
                    onValuesChange={onValuesChange}
                >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }}>
                            <Form.Item
                                label="Year"
                                name="year"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Year!',
                                    },
                                ]}
                            >
                                <Select
                                    size='large'
                                    onChange={(e) => setLeavePolicyForm({ ...leavePolicyForm, start_time: e })}
                                >
                                    <Select.Option value={new Date().getFullYear()}>{new Date().getFullYear()}</Select.Option>
                                    <Select.Option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</Select.Option>
                                    <Select.Option value={new Date().getFullYear() + 2}>{new Date().getFullYear() + 2}</Select.Option>
                                    <Select.Option value={new Date().getFullYear() + 3}>{new Date().getFullYear() + 3}</Select.Option>
                                    <Select.Option value={new Date().getFullYear() + 4}>{new Date().getFullYear() + 4}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col span={24}>
                        <Form.Item
                            label="Leave Policy"
                            name="leave_policy"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your Leave Policy!',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                value={selectedOptions}
                                name="leave_policy"
                                onSelect={handleSelectAll}
                            >
                                <Select.Option value="all">All</Select.Option>
                                {holidayLeavePolicyList.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {option.name}
                                    </Select.Option>
                                ))}
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <p>Upload File</p>
                        <Form.Item
                            label="Upload File"
                            name="end_time"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your Date (To)!',
                                },
                            ]}
                        >
                            <div className="file-upload-container">
                                <input
                                    type="file"
                                    id="file-upload"
                                    className="input-file"
                                    onChange={(e) => setHolidayListFile(e.target.files && e.target.files[0])}
                                />
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                    <label htmlFor="file-upload" className="file-upload-label">

                                    </label>
                                    {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                    <p style={{ zIndex: 1, margin: "0px" }}>{holidayListFile ? holidayListFile.name : 'Choose a file'}</p>
                                </div>
                            </div>
                        </Form.Item>
                    </Col>

                    <Button
                        htmlType='submit'
                        style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                    >
                        Submit
                    </Button>
                </Form>
            </Modal>

            {/* edit holiday */}
            <Modal
                title="Edit Holiday"
                centered
                open={openHolidayEditModal}
                onOk={() => setOpenHolidayEditModal(false)}
                onCancel={() => {
                    setOpenHolidayEditModal(false)
                    setHolidayListFile(null)
                }}
                footer={null}
            >
                <Form
                    layout="vertical"
                    // initialValues={policies}
                    onFinish={editHoliday}
                >
                    <Row>
                        <Col span={24}>
                            <p>Upload File</p>
                            <Form.Item
                                label="Upload File"
                                name="holiday_file"
                            >
                                <div className="file-upload-container">
                                    <input
                                        type="file"
                                        id="file-upload"
                                        className="input-file"
                                        onChange={(e) => {
                                            const selectedFile = e.target.files[0];
                                            const maxSizeInBytes = 5 * 1024 * 1024;

                                            if (selectedFile && selectedFile.type === "text/csv") {
                                                if (selectedFile.size <= maxSizeInBytes) {
                                                    setHolidayListFile(e.target.files && selectedFile);
                                                } else {
                                                    setHolidayListFile(null);
                                                    alert("File size exceeds the limit of 5MB.");
                                                }
                                            } else {
                                                // Reset the file state and show an error message
                                                setHolidayListFile(null);
                                                alert("Please upload a CSV file.");
                                            }
                                        }}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <label
                                            htmlFor="file-upload"
                                            className="file-upload-label"
                                        ></label>
                                        {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                        <p style={{ zIndex: 1, margin: "0px" }}>
                                            {holidayListFile ? holidayListFile.name : "Choose a file"}
                                        </p>
                                    </div>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button htmlType="submit" style={{ background: "#008080", color: "white", marginTop: "40px", height: "40px" }}>Update</Button>
                </Form>
            </Modal>
        </div>
    )
}

export default HolidayFullView