import React, { useState, useEffect } from "react";
import styles from "./LmsList.module.scss";
import request from "../../../utils/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import getScreenSize from "../../../utils/getScreenSize";
import InfiniteScrollComponent from "../../../component/InfiniteScroll/InfiniteScroll";

// Import statements remain the same...

function InboxLmsList() {
  const [lmsData, setLmsData] = useState([]);
  const [isListView, setIsListView] = useState(true);
  const [selectedLeaveId, setSelectedLeaveId] = useState(null);
  const navigate = useNavigate();
  const { width } = getScreenSize();

  useEffect(() => {
    fetchLmsData();
  }, []);

  const fetchLmsData = async () => {
    try {
      const data = await request({
        method: "get",
        url: `inbox/training-videos/all?page=1&page_size=10`,
      });

      setLmsData(data?.pagination_data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLeaveDetailClick = (id) => {
    setSelectedLeaveId(id);
    setIsListView(false);
  };


  const renderUI = (dataList) => {
    return dataList?.map((lms) => {
      return (
        <div>
          <div
            key={lms.id}
            className={styles.container}
            onClick={() =>
                navigate(`/inbox/lms/detail/` + lms.id, { replace: true })
            }
          >
            <div className={styles.userContainer}>
              <p className={styles.userName}>{lms.added_by}</p>
              <p className={styles.date}>{lms.created_at}</p>
            </div>

            {/* <p className={styles.dateWithDay}>
              {lms.start_date} ({lms.leave_day} Day)
            </p> */}
            <p className={styles.leaveType}>{lms.added_by} has uploaded a new training video.{" "} </p>

            <div className={styles.actionsBox}>
              <button
                className={styles.approveBtn}
                onClick={(e) => {
                    navigate(`/inbox/lms/detail/` + lms.id, { replace: true })
                  // rejectOrApproveLeave(lms.id, false);
                  e.stopPropagation();
                }}
              >
                Watch Now
              </button>
              {/* <button
                className={styles.rejectBtn}
                onClick={(e) => {
                  // rejectOrApproveLeave(lms.id, true);
                  e.stopPropagation();
                }}
              >
                Later
              </button> */}
            </div>
          </div>
          <hr />
        </div>
      );
    });
  };

  const renderUIDesktop = (dataList) => {
    return dataList?.map((lms) => {
      return (
        <div
          key={lms.id}
          className={styles.container}
          onClick={() =>
            navigate(`/inbox/lms/detail/` + lms.id, { replace: true })
          }
        >
          <div className={styles.userContainer}>
            <div className={styles.userBox}>
              <img
                loading="lazy"
                src={lms?.profile_image ? lms.profile_image : `https://ui-avatars.com/api/?name=${lms?.added_by.charAt(0)}`}
                alt={lms.added_by}
                className={styles.userImg}
              />
              <div className={styles.userDetailBox}>
                <div className={styles.userName}>{lms.added_by}</div>
                {/* <div className={styles.dateWithDay}>
                                    {lms.start_date} ({lms.leave_day} Day)
                                </div> */}
              </div>
            </div>
            <div className={styles.date}>{lms.created_at}</div>
          </div>
          <div className={styles.leaveType}>
            {lms.added_by} has uploaded a new training video.{" "}
          </div>
          <div className={styles.actionsBox}>
            <button
              className={styles.approveBtn}
              onClick={(e) => {
                navigate(`/lms/instruction/` + lms.id, { replace: true });
                e.stopPropagation();
              }}
            >
              Watch Now
            </button>
            {/* <button
              className={styles.rejectBtn}
              onClick={(e) => {
                // rejectOrApproveLeave(lms.id, true);
                e.stopPropagation();
              }}
            >
              Later
            </button> */}
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      {width < 768 ? (
        // mobile view
        <div>
          <InfiniteScrollComponent
            apiEndpoint="inbox/training-videos/all"
            renderUI={renderUI}
            initialPage={1}
          />
        </div>
      ) : (
        // desktop view
        <>
          {/* <input type="text" placeholder="Search" className={styles.search} /> */}
          <InfiniteScrollComponent
            apiEndpoint="inbox/training-videos/all"
            renderUI={renderUIDesktop}
            initialPage={1}
          />
        </>
      )}
    </div>
  );
}

export default InboxLmsList;
