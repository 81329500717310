import React, { useEffect, useRef, useState } from "react";
import useScreenSize from "../../../../../utils/getScreenSize";
import { useNavigate } from "react-router-dom";
import back from "../../../../../assets/Recruitment/back.svg";
import { Col, DatePicker, Modal, Row, Select, Switch, Table, Tag } from "antd";
import request from "../../../../../utils/axios";
import moment from "moment";
import CalendarScheduler from "../../../../../component/CalendarScheduler/CalendarScheduler";
import Comment from "../../Comment/Comment";
import locationImg from "../../../../../assets/location.svg";
import Vector from '../../../../../assets/Vector.svg'
import time from "../../../../../assets/time.svg";
import department from "../../../../../assets/Profile/department.svg";
import "./LogsFullView.scss";
function LogsFullView() {
  const ref = useRef(null);
  const [shiftData, setShiftData] = useState();
  const [attendanceLogsShift, setAttendanceLogsShift] = useState(1);
  const columns = [
    {
      title: "Date",
      dataIndex: "attendance_date",
      key: "attendance_date",
      render: (text, record) => (
        <>
          {moment(text).format("DD-MM-YYYY")}
          {record.is_holiday && (
            <div>
              <Tag
                color="blue"
                style={{
                  padding: "0px 10px",
                  fontSize: "14px",
                  marginTop: "5px",
                }}
              >
                {record.attendance_logs[0].holiday_name}
              </Tag>
            </div>
          )}
          {record.attendance_logs[0].is_weekly_holiday && (
            <div>
              <Tag
                color="green"
                style={{
                  padding: "0px 10px",
                  fontSize: "14px",
                  marginTop: "5px",
                }}
              >
                Weekly Off
              </Tag>
            </div>
          )}
          {record.on_leave && (
            <div>
              <Tag
                color={record.attendance_logs[0].leave_type_color}
                style={{
                  padding: "0px 10px",
                  fontSize: "14px",
                  marginTop: "5px",
                }}
              >
                {record.attendance_logs[0].leave_type}
              </Tag>
            </div>
          )}
        </>
      ),
    },
    {
      title: " Location",
      dataIndex: "location",
      key: "location",
      render: (text, record) => (
        <>
          {!record.attendance_logs[0].is_holiday &&
            !record.attendance_logs[0].is_weekly_holiday &&
            !record.attendance_logs[0].on_leave &&
            text}
        </>
      ),
    },
    {
      title: "Check-in",
      dataIndex: "check_in_time",
      key: "check_in_time",
      render: (text, record) => (
        <span>
          {!record.attendance_logs[0].is_holiday &&
            !record.attendance_logs[0].is_weekly_holiday &&
            !record.attendance_logs[0].on_leave && (
              <>
                {record.attendance_logs[0].is_absent ? (
                  <span style={{ color: "red" }}>Absent</span>
                ) : text === null ? (
                  ""
                ) : record.attendance_logs[0].is_pending === true ? (<span style={{ color: "rgb(246 154 11)" }}>Approval Pending</span>
                ) : record.attendance_logs[0].yet_to_checkin ? <span style={{ color: "rgb(246 154 11)" }}>Pending</span> : (
                  record.attendance_logs[0].check_in_time
                )}
              </>
            )}
        </span>
      ),
    },
    {
      title: "Check-out",
      dataIndex: "check_out_time",
      key: "check_out_time",
      render: (text, record) => (
        <span>
          {!record.attendance_logs[0].is_holiday &&
            !record.attendance_logs[0].is_weekly_holiday &&
            !record.attendance_logs[0].on_leave && (
              <>
                {record.attendance_logs[0].is_absent ? (
                  <span style={{ color: "red" }}>Absent</span>
                ) : text === null ? (
                  ""
                ) : record.attendance_logs[0].check_out_time === "-" ? (<span style={{ color: "rgb(246 154 11)" }}>Pending</span>
                ) : record.attendance_logs[0].is_pending === true ? (<span style={{ color: "rgb(246 154 11)" }}>Approval Pending</span>
                ) :
                  record.attendance_logs[0].check_out_time === "no_checkout" ? (<span style={{ color: "rgb(0, 96, 255)" }}>No Check Out</span>
                  ) : (
                    record.attendance_logs[0].check_out_time
                  )}
              </>
            )}
        </span>
      ),
    },
    {
      title: "Work Duration",
      dataIndex: "working_duration",
      key: "working_duration",
      render: (text, record) => (
        <span>
          {!record.attendance_logs[0].is_holiday &&
            !record.attendance_logs[0].is_weekly_holiday &&
            !record.attendance_logs[0].on_leave && !record.attendance_logs[0].is_pending && <>{text === "N/A" ? "" : text}</>}
        </span>
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (text, record) => (
        <span>
          {!record.attendance_logs[0].is_holiday &&
            !record.attendance_logs[0].is_weekly_holiday &&
            !record.attendance_logs[0].on_leave && (
              <>
                {text ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <p>{text}</p>
                    <Comment
                      btnType={"Update"}
                      data={text}
                      date={record.attendance_date}
                      setReload={setReload}
                      reload={reload}
                    />
                  </div>
                ) : (
                  <Comment
                    btnType={"Add"}
                    date={record.attendance_date}
                    setReload={setReload}
                    reload={reload}
                  />
                )}
              </>
            )}
        </span>
      ),
    },
  ];

  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const [calendarView, setCalendarView] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [open, setOpen] = useState(false);
  const [days, setDays] = useState(7);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const { Option } = Select;
  const [dateRange, setDateRange] = useState([]);
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(true);
  const [calendarData, setCalendarData] = useState([]);
  const [calendarMonth, setCalendarMonth] = useState("");
  const [calendarYear, setCalendarYear] = useState("");
  const [reload, setReload] = useState(false);

  const { RangePicker } = DatePicker;

  const handleSelectChange = (value) => {
    console.log("value", value);
    if (value === "custom") {
      setShowDatePicker(true);
      setOpen(true);
    } else {
      setShowDatePicker(false);
      setDays(value);
    }
  };

  const handleDateChange = (value) => {
    setDateRange(value);
  };

  const handleSwitchChange = (checked) => {
    setCalendarView(checked);
  };

  const fetchTableLogs = async () => {
    if (shiftData === 1) {
      const data = await request({
        method: "get",
        url: `attendance/employee/attendance/details/?days=${days}`,
      });
      setTableData(data.data.attendance_data);
      setShiftData(data.data.shift_number);
    }
    else {
      const data = await request({
        method: "get",
        url: `attendance/employee/attendance/details/?days=${days}&shift=${attendanceLogsShift}`,
      });
      setTableData(data.data.attendance_data);
      setShiftData(data.data.shift_number);
    }

    setLoading(false)
  };

  const fetchCalendarData = async () => {
    const data = await request({
      method: "get",
      url: `attendance/employee/attendance/calender/monthly/?month=${calendarMonth}&year=${calendarYear}`,
    });
    setCalendarData(data.data.monthly_attendance);
  };
  useEffect(() => {
    fetchCalendarData();
  }, [calendarMonth, calendarYear]);

  useEffect(() => {
    fetchTableLogs();
  }, [days, attendanceLogsShift, reload]);

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="attendanceLogsFullViewContainer" ref={ref}>
      {screenSize.width < 768 ? (
        // mobile view
        <div className="referralSubContainer">
          <div className="logsContainer">
            <div className="flexSpaceBetween">
              <span
                style={{
                  fontSize: "24px",
                  color: "#161616",
                  fontWeight: "500",
                }}
              >
                Logs
              </span>
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <Switch checked={calendarView} onChange={handleSwitchChange} />
                <span className="calendarView">
                  {calendarView ? "Calendar View" : "Calendar View"}
                </span>
              </div>
            </div>

            <div className="shiftContainer" style={{ marginTop: "15px" }} >
              {
                shiftData > 1
                &&
                <button
                  className={attendanceLogsShift === 1 ? "activeShiftBtn" : "shiftBtn"}
                  onClick={() => setAttendanceLogsShift(1)}
                >Shift 1</button>
              }
              {
                shiftData >= 2
                &&
                <button
                  className={attendanceLogsShift === 2 ? "activeShiftBtn" : "shiftBtn"}
                  onClick={() => setAttendanceLogsShift(2)}
                >Shift 2</button>
              }
              {
                shiftData >= 3
                &&
                <button className={attendanceLogsShift === 3 ? "activeShiftBtn" : "shiftBtn"}
                  onClick={() => setAttendanceLogsShift(3)}
                >Shift 3</button>
              }
            </div>

            <div className="flexSpaceBetween" style={{ marginTop: "20px" }}>
              <div
                style={{
                  fontSize: "16px",
                  //   padding: "8px 20px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  fontSize: "12px",
                }}
              >
                {calendarView ? "Calendar View" : "Calendar View"}
              </div>
              {!calendarView && (
                <Select
                  style={{ width: 120, height: 30 }}
                  placeholder="Select an option"
                  onChange={handleSelectChange}
                  defaultValue={days ? days : selectedDateRange}
                >
                  <Option value={7}>Last 7 days</Option>
                  <Option value={14}>Last 14 days</Option>
                  <Option value={30}>Last 30 days</Option>
                  <Option value="custom">
                    <span
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      Custom Date
                    </span>
                  </Option>
                </Select>
              )}

              <Modal
                title="Select Date Range"
                centered
                open={open}
                onCancel={() => setOpen(false)}
                onOk={async () => {
                  const data = await request({
                    method: "get",
                    url: `attendance/employee/attendance/details/?start_date=${dateRange[0]?.format(
                      "YYYY-MM-DD"
                    )}&end_date=${dateRange[1]?.format("YYYY-MM-DD")}`,
                  });
                  console.log("data", data);
                  setTableData(data.data.attendance_data);

                  setLoading(false);
                  setOpen(false);
                }}
              >
                <RangePicker
                  value={dateRange}
                  showTime={{}}
                  picker="date"
                  format="YYYY-MM-DD"
                  onChange={handleDateChange}
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                />
              </Modal>
            </div>

            <br />
            {calendarView ? (
              <CalendarScheduler
                data={calendarData}
                setCalendarMonth={setCalendarMonth}
                setCalendarYear={setCalendarYear}
                type="self"
              />
            ) : (
              <div>
                {tableData?.map((item, index) => {
                  return (
                    <>
                      <div>
                        <p
                          style={{
                            fontSize: "18px",
                            fontFamily: "Poppins-Medium",
                            color: "#161616",
                            marginTop: "5px",
                          }}
                        >
                          {item?.attendance_date}
                        </p>
                        {item?.attendance_logs[0]?.is_absent ||
                          item?.attendance_logs[0]?.is_holiday ||
                          item?.attendance_logs[0]?.is_weekly_holiday ||
                          item?.attendance_logs[0]?.on_leave ? (
                          <div>
                            <p>
                              {item?.attendance_logs[0]?.is_holiday ? (
                                item?.attendance_logs[0]?.is_holidayName
                              ) : item?.attendance_logs[0]?.is_weekly_holiday ? (
                                <div className="weeklyOff">
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "#008080",
                                      padding: "4px",
                                      fontWeight: 400,
                                      textAlign: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    Weekly Off
                                  </p>
                                </div>
                              ) : (
                                item?.attendance_logs[0]?.is_absent && <span style={{ color: "red" }}>Absent</span>
                              )}
                              {
                                item?.on_leave &&
                                <span style={{ color: item.leave_type_color }}>
                                  {item?.attendance_logs[0]?.leave_type}
                                </span>

                              }
                            </p>
                          </div>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <img src={time} alt="time" />
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "Poppins-Regular",
                                    fontSize: "16px",
                                    margin: "0px",
                                    marginLeft: "10px",
                                    color: "#8A8A8A",
                                  }}
                                >
                                  {
                                    item.yet_to_checkin
                                      ?
                                      <span style={{ color: "rgb(246 154 11)" }}>Pending</span>
                                      : (
                                        item?.attendance_logs[0]?.check_in_time
                                      )
                                  }
                                  - - -
                                </p>
                                <p
                                  style={{
                                    fontFamily: "Poppins-Regular",
                                    fontSize: "16px",
                                    color: "#8A8A8A",
                                    margin: "0px",
                                  }}
                                >
                                  {
                                    item?.attendance_logs[0]?.check_out_time === null
                                      ?
                                      ("")
                                      :
                                      item?.attendance_logs[0]?.check_out_time === "-"
                                        ?
                                        (<span style={{ color: "rgb(246 154 11)" }}>Pending</span>)
                                        :
                                        item?.attendance_logs[0]?.check_out_time === "no_checkout"
                                          ?
                                          (<span style={{ color: "rgb(0, 96, 255)" }}>No Check Out</span>)
                                          :
                                          (
                                            item?.attendance_logs[0]?.check_out_time
                                          )
                                  }
                                </p>
                              </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px", padding: "10px 0px" }}>
                              <img src={department} alt="department" width={18} height={18} />
                              <p style={{ margin: "0px", fontSize: "16px", color: "#8A8A8A" }}>{item?.attendance_logs[0]?.working_duration}</p>
                            </div>
                            {
                              item?.attendance_logs[0]?.location !== "-"
                              &&
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <img
                                  alt="noimg"
                                  src={Vector}
                                  width={18}
                                  height={18}
                                />
                                <p
                                  style={{
                                    fontFamily: "Poppins-Regular",
                                    fontSize: "16px",
                                    color: "#8A8A8A",
                                    margin: "0px",
                                  }}
                                >
                                  {item?.attendance_logs[0]?.location}
                                </p>
                              </div>
                            }

                            {
                              item?.comment
                              &&
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Poppins-Regular",
                                    color: "black",
                                  }}
                                >
                                  Comment
                                </p>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Poppins-Regular",
                                    color: "#8A8A8A",
                                  }}
                                >
                                  {item?.comment}
                                </p>
                              </div>
                            }

                          </>
                        )}
                        <hr
                          style={{
                            height: "1px",
                            backgroundColor: "#F0F0F0",
                            border: "none",
                          }}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      ) : (
        // Desktop View
        <>
          <p
            onClick={() => {
              navigate(-2);
            }}
            style={{ cursor: "pointer" }}
          >
            <img src={back} alt="back" />
            <span style={{ paddingLeft: "10px" }}>Back</span>
          </p>
          <h1>Attendance</h1>
          <div style={{ background: "white", padding: "20px" }}>
            <div className="flexSpaceBetween" style={{ marginBottom: "20px" }}>
              <div
                style={{
                  fontSize: "16px",
                  display: "flex",
                  gap: "20px",
                }}
              >
                <span className="logs">Logs</span>

                <div
                  style={{ display: "flex", gap: "20px", alignItems: "center" }}
                >
                  <Switch
                    checked={calendarView}
                    onChange={handleSwitchChange}
                  />
                  <span className="calendarView">
                    {calendarView ? "Calendar View" : "Calender View"}
                  </span>
                </div>
              </div>
              <>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <div className="shiftContainer" >
                    {
                      shiftData > 1
                      &&
                      <button
                        className={attendanceLogsShift === 1 ? "activeShiftBtn" : "shiftBtn"}
                        onClick={() => setAttendanceLogsShift(1)}
                      >Shift 1</button>
                    }
                    {
                      shiftData >= 2
                      &&
                      <button
                        className={attendanceLogsShift === 2 ? "activeShiftBtn" : "shiftBtn"}
                        onClick={() => setAttendanceLogsShift(2)}
                      >Shift 2</button>
                    }
                    {
                      shiftData >= 3
                      &&
                      <button className={attendanceLogsShift === 3 ? "activeShiftBtn" : "shiftBtn"}
                        onClick={() => setAttendanceLogsShift(3)}
                      >Shift 3</button>
                    }
                  </div>
                  {!calendarView && (
                    <Select
                      style={{ width: 180, height: 44 }}
                      placeholder="Select an option"
                      onChange={handleSelectChange}
                      defaultValue={days ? days : selectedDateRange}
                    >
                      <Option value={7}>Last 7 days</Option>
                      <Option value={14}>Last 14 days</Option>
                      <Option value={30}>Last 30 days</Option>
                      <Option value="custom">
                        <span
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          Custom Date
                        </span>
                      </Option>
                    </Select>
                  )}
                </div>
              </>

              <Modal
                title="Select Date Range"
                centered
                open={open}
                onCancel={() => setOpen(false)}
                onOk={async () => {
                  const data = await request({
                    method: "get",
                    url: `attendance/employee/attendance/details/?start_date=${dateRange[0]?.format(
                      "YYYY-MM-DD"
                    )}&end_date=${dateRange[1]?.format("YYYY-MM-DD")}`,
                  });
                  setTableData(data.data.attendance_data);

                  setLoading(false);
                  setOpen(false);
                }}
              >
                <RangePicker
                  value={dateRange}
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                />
              </Modal>
            </div>
            <>
              {calendarView ? (
                <CalendarScheduler
                  data={calendarData}
                  setCalendarMonth={setCalendarMonth}
                  setCalendarYear={setCalendarYear}
                  type="self"
                />
              ) : (
                <Table
                  columns={columns}
                  dataSource={tableData}
                  loading={loading}
                  pagination={false}
                />
              )}
            </>
          </div>
        </>
      )}
    </div>
  );
}

export default LogsFullView;
