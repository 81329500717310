import React, {useState, useEffect, useRef} from 'react';
import {Modal, Input, Button} from 'antd';
import mapLocation from '../../../assets/location.svg';
import getScreenSize from '../../../utils/getScreenSize';
import {useParams} from 'react-router-dom';
import LocationIcon from '../../../assets/tracking/LocationIcomn.svg';
import {toast} from 'react-toastify';

import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  Marker,
} from '@react-google-maps/api';
import request from '../../../utils/axios';
const containerStyleMobile = {
  width: '100%',
  height: '55vh',
};
const containerStyle = {
  width: '100%',
  height: '400px',
};
const inputStyle = {
  width: '100%',
  padding: '0.5rem',
  boxSizing: 'border-box',
  marginBottom: '10px',
  borderRadius: '8px',
  borderColor: '#d9d9d9', // Corrected property name
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

// const customMarkerIcon = {
//   url: mapLocation, // Replace this with the actual path to your marker icon
//   scaledSize: new window.google.maps.Size(40, 40), // Adjust the size if necessary
// };
const AttendMeeting = ({
  attendPopup,
  setAtttendPop,
  handleAttendMeeting,
  attendRecord,
}) => {
  const {id} = useParams();
  console.log(id);
  const screenSize = getScreenSize();
  const autocompleteRef = useRef(null);
  const geocoderRef = useRef(null);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [markerPosition, setMarkerPosition] = useState(center);
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const {latitude, longitude} = position.coords;
          console.log(latitude, longitude);
          setMarkerPosition({lat: latitude, lng: longitude});
          locationCondinateAddress(latitude, longitude);
        },
        error => {
          console.error('Error fetching location:', error);
        },
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };
  const locationCondinateAddress = async (latitude, longitude) => {
    try {
      const data = await request({
        method: 'get',
        url: `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`,
      });
      console.log(data);
      setSelectedAddress(data.display_name);
    } catch (error) {
      console.log('error', error);
    }
  };

  // const getAddressFromCoordinates = (latitude, longitude) => {
  //   if (geocoderRef.current) {
  //     const latLng = {lat: latitude, lng: longitude};
  //     geocoderRef.current.geocode({location: latLng}, (results, status) => {
  //       //console.log(results, status);
  //       if (status === 'OK' && results[0]) {
  //         console.log(results);
  //         setSelectedAddress(results[0].formatted_address); // Set the address in the input field
  //       } else {
  //         console.error('Geocoding failed:', status);
  //       }
  //     });
  //   }
  // };

  // Call the function to get the current location when the component mounts

  const isWebView = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return userAgent.includes('MyAppWebView/1.0');
  };
  useEffect(() => {
    if (isWebView()) {
      const handleMessage = event => {
        console.log('Received event:', event);

        // Ensure the message is from a trusted source
        // if (event.origin === 'http://localhost:8081') {
        try {
          const data = JSON.parse(event.data);
          console.log('Message data:', data);
          if (data.source === 'react-native-webview') {
            console.log('Message from React Native WebView:', data);
          } else {
            console.log('Message from another source:', data);
          }
          setMarkerPosition({lat: data?.lat, lng: data?.lng});
          locationCondinateAddress(data?.lat, data?.lng);
        } catch (error) {
          console.error('Failed to parse message data:', error);
        }
        // }
      };

      window.addEventListener('message', handleMessage);

      return () => {
        window.removeEventListener('message', handleMessage);
      };
    } else {
      getCurrentLocation();
    }
  }, []);
  // useEffect(() => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //   console.log('cccccc', userAgent.includes('MyAppWebView/1.0'));
  //   setIsApp(userAgent.includes('MyAppWebView/1.0'));
  // }, []);

  const onPlaceChanged = () => {
    const autocomplete = autocompleteRef.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setMarkerPosition({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        setSelectedAddress(place.formatted_address);
      }
    }
  };
  const handleAttended = async () => {
    console.log(markerPosition, id);
    const apiUrl = `tracking/employee/employee-meeting/marked?longitude=${markerPosition.lat}&latitude=${markerPosition.lng}&id=${id}`;

    try {
      const res = await request({
        method: 'get',
        url: apiUrl,
      });
      if (res.status == 200) {
        toast.success(res.message, {
          position: 'bottom-left',
        });
      } else {
        toast.error(res.message, {position: 'bottom-left'});
      }

      // console.log(res);
    } catch (error) {
      console.error('Error fetching data: ', error);
      toast.error(error.message, {
        position: 'bottom-left',
      });
    }
  };
  return (
    <>
      {screenSize.width < 768 ? (
        <div
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}>
          <div style={{fontSize: '20px', fontWeight: '500'}}>
            Metting Attend
          </div>
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            libraries={['places']}
            onLoad={() => {
              geocoderRef.current = new window.google.maps.Geocoder(); // Initialize geocoder
            }}>
            <div style={{position: 'relative'}}>
              <Autocomplete
                onLoad={autocomplete => {
                  autocompleteRef.current = autocomplete;
                }}
                onPlaceChanged={onPlaceChanged}>
                <Input
                  type="text"
                  placeholder="Search for an address"
                  style={{display: 'none'}}
                  value={selectedAddress}
                  onChange={e => {
                    setSelectedAddress(e.target.value);
                  }}
                />
              </Autocomplete>
              <GoogleMap
                mapContainerStyle={containerStyleMobile}
                center={markerPosition}
                zoom={15}>
                <Marker position={markerPosition} />
              </GoogleMap>
            </div>
          </LoadScript>
          <div>
            <label style={{fontSize: '16px', font: '400'}}>Your Location</label>
            <Input
              type="text"
              placeholder="Search for an address"
              prefix={<img src={LocationIcon} />}
              style={{
                width: '100%',
                boxSizing: 'border-box',
                margin: '10px 0px',
                padding: '1rem',
                width: '100%',
                whiteSpace: 'pre-wrap', // Ensure text wraps properly
                overflowWrap: 'break-word',
              }}
              disabled={true}
              value={selectedAddress}
              onChange={e => {
                setSelectedAddress(e.target.value);
              }}
            />
          </div>
          <Button
            onClick={handleAttended}
            style={{
              backgroundColor: '#008080',
              color: 'white',
              padding: '24px 54px',
              borderRadius: '8px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}>
            Attended
          </Button>
        </div>
      ) : (
        <Modal
          title={
            <span
              style={{fontSize: '20px', fontWeight: '500', color: '#161616'}}>
              Meeting Attended
            </span>
          }
          centered
          open={attendPopup}
          onCancel={() => setAtttendPop(false)}
          footer={
            <div style={{textAlign: 'left'}}>
              <button
                key="ok"
                onClick={() =>
                  handleAttendMeeting(
                    markerPosition.lng,
                    markerPosition.lat,
                    attendRecord,
                  )
                }
                style={{
                  backgroundColor: '#008080',
                  color: 'white',
                  width: '200px',
                  padding: '6px 24px',
                  fontSize: '16px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}>
                Attended
              </button>
            </div>
          }>
          <div style={{}}>
            <label style={{fontSize: '16px', font: '400'}}>Your Location</label>
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
              libraries={['places']}
              onLoad={() => {
                geocoderRef.current = new window.google.maps.Geocoder(); // Initialize geocoder
              }}>
              <div style={{position: 'relative'}}>
                <Autocomplete
                  onLoad={autocomplete => {
                    autocompleteRef.current = autocomplete;
                  }}
                  onPlaceChanged={onPlaceChanged}>
                  <Input
                    type="text"
                    placeholder="Search for an address"
                    style={inputStyle}
                    value={selectedAddress}
                    onChange={e => {
                      setSelectedAddress(e.target.value);
                    }}
                  />
                </Autocomplete>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={markerPosition}
                  zoom={15}>
                  <Marker position={markerPosition} />
                </GoogleMap>
              </div>
            </LoadScript>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AttendMeeting;
