import {
  Avatar,
  Badge,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Steps,
  Switch,
  Tabs,
  Timeline,
} from "antd";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import CommonTable from "../../../../../component/CommonTable/CommonTable";
import { toast } from "react-toastify";
import request from "../../../../../utils/axios";
import { useParams } from "react-router-dom";
import getScreenSize from "../../../../../utils/getScreenSize";
import editImage from "../../../../../assets/editImage.png";
function CompanyLogo() {
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Number of items per page
    total: 0,
  });
  const [File, setFile] = useState(null);
  const [locationForm] = Form.useForm();
  const params = useParams();
  const officeLocationColumns = [
    {
      title: "Name",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
    },
    // {
    //     title: 'Action',
    //     dataIndex: 'action',
    //     key: 'action',
    // }
  ];
  const screenSize = getScreenSize();
  const [companyDetails, setCompanyDetails] = useState([]);
  const fetchSavedLocation = async () => {
    try {
      const data = await request({
        method: "get",
        url: `/admin/company-logo?org_id=${params.id}`,
      });
      setCompanyDetails(data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const uploadCompanyLogo = async (e) => {
    try {
      const formData = new FormData();
      formData.append("company_logo", e.target.files[0]);
      const response = await request({
        method: "post",
        url: `admin/company-logo/upload?org_id=${params.id}`,
        data: formData,
      });

      setOpen(false);
      setFile(null);
      toast.success("Company Logo uploaded successfully", {
        position: "bottom-left",
      })
      fetchSavedLocation();
    } catch (error) {

      toast.error(error.response.data.message, {
        position: "bottom-left",
      });
    }
  };

  useEffect(() => {
    fetchSavedLocation();
  }, [pagination.current]);

  const addLocation = async (values) => {
    try {
      await request({
        method: "post",
        url: `/attendance/location/create`,
        data: {
          address: values.address,
          longitude: values.longitude,
          latitude: values.latitude,
        },
      });
      locationForm.resetFields();
      setOpen(false);
      fetchSavedLocation();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>{
      screenSize.width < 768 ?
        <Row gutter={[32, 32]}>
          <Col span={4}>
            <center>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "30px",
                  marginLeft: "31px",
                  marginTop: "30px",
                }}
              >

                <div>
                  {companyDetails?.company_logo ? (
                    <div>
                      <Badge
                        style={{ position: "relative" }}
                        onClick={() =>
                          document.getElementById("file-upload").click()
                        }
                      >
                        <img
                          src={companyDetails?.company_logo}
                          alt="profileImg"
                          width={166}
                          height={154}
                          style={{ borderRadius: "5%" }}
                        />
                        {/* <EditOutlined
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            fontSize: "24px",
                            color: "black",
                          }}
                        /> */}
                        <img src={editImage} alt="editImage" style={{
                          position: "absolute",
                          top: -10,
                          right: -20,
                          fontSize: "24px",
                          color: "black",
                        }} />
                      </Badge>
                      <input
                        id="file-upload"
                        type="file"
                        onChange={uploadCompanyLogo}
                        style={{ display: "none" }}
                      />
                    </div>
                  ) : (
                    <label htmlFor="file-upload">
                      <Avatar size={150} shape="square" icon={<UploadOutlined />} />
                      <p style={{ position: "relative", bottom: 35, right: 0, fontSize: "12px", fontWeight: "400" }}>
                        <u>
                          Upload company logo
                        </u>
                      </p>
                      <input
                        id="file-upload"
                        type="file"
                        onChange={uploadCompanyLogo}
                        style={{ display: "none" }} // Hide the default file input styling
                      />
                    </label>
                  )}
                </div>
              </div>
            </center>
          </Col>
        </Row>
        :
        <Row gutter={[32, 32]}>
          <Col span={4}>
            <center>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "30px",
                  marginLeft: "31px",
                  marginTop: "10px",
                }}
              >
                <p style={{ fontSize: "15px", marginTop: "20px" }}>Company Logo</p>
                <div style={{ marginLeft: "45px" }}>
                  {companyDetails?.company_logo ? (
                    <div>
                      <Badge
                        style={{ position: "relative" }}
                        onClick={() =>
                          document.getElementById("file-upload").click()
                        }
                      >
                        <img
                          src={companyDetails?.company_logo}
                          alt="profileImg"
                          width={100}
                          height={100}
                          style={{ borderRadius: "50%" }}
                        />
                        <EditOutlined
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            fontSize: "24px",
                            color: "black",
                          }}
                        />
                      </Badge>
                      <input
                        id="file-upload"
                        type="file"
                        onChange={uploadCompanyLogo}
                        style={{ display: "none" }}
                      />
                    </div>
                  ) : (
                    <label htmlFor="file-upload">
                      <Avatar size={100} shape="square" icon={<UploadOutlined />} />
                      <input
                        id="file-upload"
                        type="file"
                        onChange={uploadCompanyLogo}
                        style={{ display: "none" }} // Hide the default file input styling
                      />
                    </label>
                  )}
                </div>
              </div>
            </center>
          </Col>
        </Row>
    }


      <Modal
        centered
        open={open}
        onOk={() => { }}
        onCancel={() => {
          locationForm.resetFields();
          setOpen(false);
        }}
        footer={null}
        width={600}
      >
        <Form layout="vertical" onFinish={addLocation} form={locationForm}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <p>Upload File</p>
              <Form.Item
                label="Upload File"
                name="end_time"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Date (To)!",
                  },
                ]}
              >
                <div className="file-upload-container">
                  <input
                    type="file"
                    id="file-upload"
                    className="input-file"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      const maxSizeInBytes = 5 * 1024 * 1024;

                      if (selectedFile && selectedFile.type === "text/csv") {
                        if (selectedFile.size <= maxSizeInBytes) {
                          setFile(e.target.files && selectedFile);
                        } else {
                          setFile(null);
                          alert("File size exceeds the limit of 5MB.");
                        }
                      } else {
                        // Reset the file state and show an error message
                        setFile(null);
                        alert("Please upload a CSV file.");
                      }
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label
                      htmlFor="file-upload"
                      className="file-upload-label"
                    ></label>
                    {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                    <p style={{ zIndex: 1, margin: "0px" }}>
                      {File ? File.name : "Drop Your File Here"}
                    </p>
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Button
            htmlType="submit"
            style={{
              width: "100%",
              color: "white",
              background: "#008080",
              height: "44px",
              marginTop: "40px",
            }}
          >
            Save
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default CompanyLogo;
