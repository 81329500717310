import { Col, Input, Modal, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import edit from "../../../../assets/edit.svg"
import { useParams } from 'react-router-dom'
import request from '../../../../utils/axios';
import { toast } from 'react-toastify';

function IntegarationStatus() {
    const params = useParams();

    const columns = [
        {
            title: 'Employee Code',
            dataIndex: 'employement_number',
            key: 'employement_number',
        },
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Punch Id',
            dataIndex: 'punch_id',
            key: 'punch_id',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => <img src={edit} alt="edit" onClick={() => handleEdit(record)}
             />

        }
    ]
    

    const [modal, setModal] = useState(false);
    const [employeeBioMetricDetails, setEmployeeBioMetricDetails] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [updatedPunchId, setUpdatedPunchId] = useState("");


    const handleEdit = (employee) => {
        setSelectedEmployee(employee);
        setUpdatedPunchId(employee.punch_id);
        setModal(true);
    }

    const fetchEmployeesDetails = async () => {
        try {
      
            const response = await request({
              method: "get",
              url: `/biometric/admin/get-particular-device/details/?id=${params.id}`,
            });
            setEmployeeBioMetricDetails(response?.data)
          }
          catch (e) {
            console.log(e)
          }
    }
    const updatePunchId = async () => {
        try {
            await request({
                method: "post",
                url: `/biometric/admin/employee-details/edit`,
                data: {
                    id: selectedEmployee.id,
                    punch_id: updatedPunchId,
                }
            });
            toast.success("Punch ID Updated", {
                position: "bottom-left",
            });
            setModal(false);
            fetchEmployeesDetails();
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        fetchEmployeesDetails()
    }, [])

    

    return (
        <div style={{ padding: "50px" }}>
            <div style={{ padding: "30px", background: "white" }}>
                <p style={{ fontSize: "20px", fontWeight: "500", color: "#161616" }}>ETA Biometric</p>

                <Table
                    columns={columns}
                    dataSource={employeeBioMetricDetails}
                    pagination={false}
                />
            </div>

            <Modal
                title="Edit Employee Details"
                centered
                open={modal}
                onOk={() => setModal(false)}
                onCancel={() => setModal(false)}
                footer={null}
            >
                <Row>
                    <Col span={12}>
                        <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616" }}>Employee Name:</p>
                    </Col>
                    <Col span={12}>
                    <p>{selectedEmployee?.employee_name}</p>
                    </Col>
                    <Col span={12}>
                        <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616" }}>Employee Punch ID:</p>
                    </Col>
                    <Col span={12}>
                    <Input 
                            value={updatedPunchId}  // Controlled input for punch ID
                            onChange={(e) => setUpdatedPunchId(e.target.value)}  // Update punch ID on change
                        />
                    </Col>
                    <button style={{ marginTop: "20px", color: "white", background: "#008080", border: "none", width: "120px", height: "40px", borderRadius: "4px", cursor: "pointer" }} onClick={updatePunchId}>Update</button>
                </Row>
            </Modal>
        </div>
    )
}

export default IntegarationStatus