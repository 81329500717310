import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import request from '../../../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function ReimbursementForm() {
    const ref = useRef();
    const navigate = useNavigate();
    const { TextArea } = Input;
    const [EmployeeReimbursementData, setEmployeeReimbursementData] = useState({
        title: "",
        amount: "",
        merchant_name: "",
        description: "",
        invoice_id: "",
        date: "",
        attach_file: []
    })
    const [reimbursementForm] = Form.useForm()
    const [formSubmit, setFormSubmit] = useState(false)
    const [fileType, setFileType] = useState(false)
    const onFinish = async (values) => {
        const formData = new FormData();
        try {

            formData.append("title", EmployeeReimbursementData.title);
            formData.append("amount", EmployeeReimbursementData.amount);
            formData.append("merchant_name", EmployeeReimbursementData.merchant_name);
            formData.append("description", EmployeeReimbursementData.description);
            formData.append("invoice_id", EmployeeReimbursementData.invoice_id);
            // formData.append("file", EmployeeReimbursementData.attach_file)
            EmployeeReimbursementData.attach_file.forEach(file => {
                formData.append("files", file); // Append each file
            });
            formData.append("date", EmployeeReimbursementData.date)
            console.log("Form_data : ", formData)

            await request({
                method: "post",
                url: `payroll/create-employee-reimbursement`,
                data: formData
            })

            reimbursementForm.resetFields();
            toast.success("Reimbursement Request submitted successfully", {
                position: "bottom-left",
            })
            navigate("/payroll/reimbursement", { replace: true })
        }
        catch (error) {
            console.log(error)
            toast.error("Reimnursement request could not be submitted, please try after some time", {
                position: "bottom-left",
            })
        }
    }

    const onHandleChange = (key, value) => {
        if (key === "attach_file") {
            setEmployeeReimbursementData({
                ...EmployeeReimbursementData,
                "attach_file": [...EmployeeReimbursementData.attach_file, value]
            });
        }
        else {
            setEmployeeReimbursementData({
                ...EmployeeReimbursementData,
                [key]: value
            });
        }
    }

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div style={{ padding: "0px 16px", marginTop: "80px" }} ref={ref}>
            <p style={{ fontSize: "24px", fontWeight: "600" }} >Reimbursement</p>
            <Form
                layout="vertical"
                onFinish={onFinish} >
                <Row gutter={[32, 16]}>
                    <Col xs={{ span: 24 }}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter title!',
                                },
                            ]}
                        >
                            <Input
                                size='large'
                                placeholder="Enter title"
                                onChange={(e) => onHandleChange("title", e.target.value)}
                                value={EmployeeReimbursementData.title}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="Date"
                            name="date"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter date!',
                                },
                            ]}
                        >
                            <DatePicker
                                className='leaveInputField'
                                onChange={(date, dateString) => onHandleChange("date", dateString)}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="Amount"
                            name="amount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter amount!',
                                },
                            ]}
                        >
                            <Input
                                size='large'
                                placeholder="Enter Amount"
                                onChange={(e) => onHandleChange("amount", e.target.value)}
                                value={EmployeeReimbursementData.amount}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="Invoice ID"
                            name="InvoiceId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter invoice id!',
                                },
                            ]}
                        >
                            <Input
                                size='large'
                                placeholder="Enter Invoice Id"
                                onChange={(e) => onHandleChange("invoice_id", e.target.value)}
                                value={EmployeeReimbursementData.invoice_id}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="Merchant Name"
                            name="merchant_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter merchant name!',
                                },
                            ]}
                        >
                            <Input
                                size='large'
                                placeholder="Enter Merchant Name"
                                onChange={(e) => onHandleChange("merchant_name", e.target.value)}
                                value={EmployeeReimbursementData.merchant_name}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter description!',
                                },
                            ]}
                        >
                            <TextArea
                                rows={4}
                                placeholder="Enter Description"
                                onChange={(e) => onHandleChange("description", e.target.value)}
                                value={EmployeeReimbursementData.description}
                            />
                            {/* <Input
                                size='large'
                                placeholder="Enter Description"
                                onChange={(e) => onHandleChange("description", e.target.value)}
                                value={EmployeeReimbursementData.description}
                            /> */}
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }}>
                        <Form.Item
                            label="Upload File"
                            name="file"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please upload file!',
                        //     },
                        // ]}
                        >
                            <p>Upload File</p>
                            <div className="file-upload-container">
                                <input
                                    type="file"
                                    id="file-upload"
                                    className="input-file"
                                    onChange={(e) => {
                                        const selectedFile = e.target.files[0];
                                        if (selectedFile && selectedFile.type === 'application/pdf') {
                                            onHandleChange("attach_file", selectedFile)
                                            setFileType(false)
                                        } else {
                                            // Reset the file state and show an error message
                                            // alert('Please upload a PDF file.');
                                            setFileType(true)
                                        }
                                    }}
                                />
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                    <label htmlFor="file-upload" className="file-upload-label">

                                    </label>
                                    <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p>
                                </div>
                            </div>


                            {
                                fileType && <p style={{ color: "#ff4d4f", fontSize: "12px", marginTop: "40px" }}>Please upload a pdf file</p>
                            }

                            {
                                (formSubmit && EmployeeReimbursementData.attach_file === "" && !fileType) && <p style={{ color: "#ff4d4f", fontSize: "12px", marginTop: "40px" }}>Please Upload File!</p>
                            }
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }}>
                        <Button style={{ width: "100%", marginTop: "40px", marginBottom: "20px", height: "40px", backgroundColor: "#008080", color: "white" }} htmlType="submit"
                            onClick={() => setFormSubmit(true)}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ReimbursementForm