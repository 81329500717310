import React, { useEffect, useRef, useState } from "react";
import request from "../../../../utils/axios";
import moment from "moment";
import {
  DownOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import styles from "./LeaveRequest.module.scss";

function LeaveRequestFullView() {
  const ref = useRef(null);
  const [myAppliedLeaveDetails, setMyAppliedLeaveDetails] = useState([]);
  const fetchLeaveDetails = async () => {
    const data = await request({
      method: "get",
      url: `leave/leave-application/all/details/?page=1&page_size=4`,
    });

    if (data && data?.pagination_data && data?.pagination_data?.data) {
      setMyAppliedLeaveDetails([...data?.pagination_data?.data]);
    }
  };
  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    fetchLeaveDetails();
  }, []);



  return (
    <div ref={ref}>
      <div className={styles.teamContainer} style={{ padding: "0px 16px", marginTop: "80px" }}>
        <div className={styles.teamText}>Leave Request</div>
      </div>
      {myAppliedLeaveDetails.map((item, index) => {
        return (
          <div>
            <div style={{ padding: "0px 16px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <p className={styles.name}>{item?.employee_name}</p>
                <div
                  className={styles.pendingContainer}
                  style={{
                    borderColor: item?.is_approved ? "#28B15F" : "red",
                  }}
                >
                  <p
                    className={styles.status}
                    style={{ color: item?.is_approved ? "#28B15F" : "red" }}
                  >
                    {item?.is_pending && "Pending"}
                    {item?.is_approved || item?.is_predefined_leaves
                      ? "Approved"
                      : ""}

                    {item?.is_rejected && "Rejected"}
                  </p>
                </div>
              </div>

              <p className={styles.leaveText}>{item?.leave_type}</p>

              <div>
                <p className={styles.date}>
                  {moment(item?.start_date).format("DD-MM-YYYY")} To{" "}
                  {moment(item?.end_date).format("DD-MM-YYYY")}
                </p>
              </div>
              <div className={styles.leavesText}>
                {item?.leave_day} leaves <DownOutlined />
              </div>
            </div>
            {index !== myAppliedLeaveDetails?.length - 1 && (
              <hr className={styles.divider} />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default LeaveRequestFullView;
