import React, { useEffect, useState } from "react";
import {
  Form,
  Col,
  DatePicker,
  Row,
  Switch,
  Button,
  Input,
  Select,
} from "antd";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import request from "../../../../utils/axios";
import styles from "./ApplyLeave.module.scss";
import Error from "../../../../assets/Leave/Error.svg";

function ApplyLeave({ changeTabsValue }) {
  const { TextArea } = Input;
  const [appliedLeaveId, setAppliedLeaveId] = useState(null);
  const [selectedLeaveTypeCount, setSelectedLeaveTypeCount] = useState(null);
  const [leaveRule, setLeaveRule] = useState({
    back_dated: "",
    doc_requried: "",
    leave_type_eligible: "",
  });

  const [editLeaveDetails, setEditLeaveDetails] = useState({
    leave_type: "",
    start_date: "",
    from_second_half_start_date: false,
    end_date: "",
    till_first_half_end_date: false,
    reason: "",
    document: null,
  });

  const [reportingManager, setReportingManager] = useState("");
  const fetchReportingManager = async () => {
    try {
      const data = await request({
        method: "get",
        url: `/leave/get/reporting_manager/`,
      });

      setReportingManager(data?.reporting_manager);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values) => {
    try {
      const start_date = dayjs(values.start_date).format("YYYY-MM-DD");

      const end_date = dayjs(values.end_date).format("YYYY-MM-DD");

      // editLeaveDetails.start_date = start_date;
      // editLeaveDetails.end_date = end_date;

      const formData = new FormData();
      formData.append("start_date", start_date);
      formData.append("end_date", end_date);
      formData.append("leave_type", editLeaveDetails.leave_type);
      formData.append("leave_left", selectedLeaveTypeCount);
      formData.append("from_second_half_start_date", editLeaveDetails.from_second_half_start_date);
      formData.append("till_first_half_end_date", editLeaveDetails.till_first_half_end_date);
      formData.append("reason", editLeaveDetails.reason);
      if (editLeaveDetails.document) {
        formData.append("document", editLeaveDetails.document);
      }

      if (appliedLeaveId) {
        await request({
          method: "post",
          url: `/leave/leave/update/?id=${appliedLeaveId}`,
          // data: {
          //   ...editLeaveDetails,
          //   start_date,
          //   end_date,
          //   leave_left: selectedLeaveTypeCount,
          // },
          data: formData
        });
        setAppliedLeaveId(null);

        toast.success("Leave Application updated", {
          position: "bottom-left",
        });
      } else {
        await request({
          method: "post",
          url: `/leave/leave_application/create/`,
          // data: {
          //   ...editLeaveDetails,
          //   start_date,
          //   end_date,
          //   leave_left: selectedLeaveTypeCount,
          // },
          data: formData
        });
        setAppliedLeaveId(null);
        toast.success("Leave Application Submitted", {
          position: "bottom-left",
        });
      }

      // setApplyLeaveModel(false);
      setEditLeaveDetails({
        leave_type: "",
        start_date: "",
        from_second_half_start_date: false,
        end_date: "",
        till_first_half_end_date: false,
        reason: "",
        document: null,
      });

      changeTabsValue("3")
    } catch (error) {
      console.log(error);
      toast.error("Leave Application could not be submitted", {
        position: "bottom-left",
      });
    }
  };
  const handleFormChange = (key, value) => {
    setEditLeaveDetails({
      ...editLeaveDetails,
      [key]: value
    });
  };

  useEffect(() => {
    console.log("editLeaveDetails", editLeaveDetails)
  }, [editLeaveDetails])

  const [personalLeaveLeftDetails, setPersonalLeaveLeftDetails] = useState([]);
  const fetchPersonalLeaveLeftDetails = async () => {
    try {
      const data = await request({
        method: "get",
        url: `/leave/employee/leave/stats/`,
      });

      setPersonalLeaveLeftDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchReportingManager();
    fetchPersonalLeaveLeftDetails();
  }, []);

  const [leaveRuleByDate, setLeaveRuleByDate] = useState({
    back_dated: "",
    doc_requried: "",
    leave_type_eligible: "",
  });

  const checkRuleByDate = async () => {
    try {
      const data = await request({
        method: "post",
        url: `leave/leave/application/check?leave_type_id=${editLeaveDetails.leave_type}`,
        data: {
          start_date: dayjs(editLeaveDetails.start_date).format("YYYY-MM-DD"),
          end_date: dayjs(editLeaveDetails.end_date).format("YYYY-MM-DD"),
          from_second_half_start_date:
            editLeaveDetails.from_second_half_start_date,
          till_first_half_end_date: editLeaveDetails.till_first_half_end_date,
          leave_left: selectedLeaveTypeCount,
        },
      });

      setLeaveRuleByDate(data);
    } catch (error) {
      console.log(error);
      setLeaveRuleByDate(error.response.data);

    }
  };
  useEffect(() => {
    if (editLeaveDetails.start_date && editLeaveDetails.end_date) {
      checkRuleByDate();
    }
  }, [
    editLeaveDetails.start_date,
    editLeaveDetails.end_date,
    editLeaveDetails.from_second_half_start_date,
    editLeaveDetails.till_first_half_end_date,
  ]);

  return (
    <div style={{ padding: " 0px 16px" }}>
      <h1 style={{ fontSize: 24 }}>Apply Leave</h1>
      <Form
        layout="vertical"
        onFinish={onFinish}
        // onValuesChange={handleFormChange}
        initialValues={editLeaveDetails}
        fields={[
          {
            name: ["leave_type"],
            value: editLeaveDetails.leave_type,
          },
          {
            name: ["reporting_manager"],
            value: reportingManager,
          },
          {
            name: ["start_date"],
            value: editLeaveDetails.start_date,
          },
          {
            name: ["end_date"],
            value: editLeaveDetails.end_date,
          },
          {
            name: ["reason"],
            value: editLeaveDetails.reason,
          },
        ]}
      >
        <Row gutter={[10, 10]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Leave Type"
              name="leave_type"
              rules={[
                {
                  required: true,
                  message: "Please select Leave Type!",
                },
              ]}
            >
              <Select className={styles.leaveInputField}
                onChange={(value) => handleFormChange("leave_type", value)}
              >
                {personalLeaveLeftDetails?.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.leave_id}>
                      <div
                        onClick={() =>
                          setSelectedLeaveTypeCount(item.balance_leave)
                        }
                      >
                        {item.leave_name} ( {item.balance_leave} leave left )
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {/* <p style={{ color: "red" }}>{leaveRule?.message?.map((item, index) => {
                                    return (
                                        <p>{item}</p>
                                    )
                                })}</p> */}
            {leaveRule?.message?.length > 0 && (
              <div
                style={{
                  background: "#FDF3CF",
                  height: "40px",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <img src={Error} alt="" />
                  <p style={{ margin: "0px" }}>{leaveRule?.message[0]}</p>
                </div>
              </div>
            )}
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name="reporting_manager" label="Approval Authority">
              <Input className={styles.leaveInputField} disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Date (From)"
              name="start_date"
              rules={[
                {
                  required: true,
                  message: "Please enter your Date (From)!",
                },
              ]}
            >
              <DatePicker className={styles.leaveInputField}
                onChange={(value) => handleFormChange("start_date", value)}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              label="Date (To)"
              name="end_date"
              rules={[
                {
                  required: true,
                  message: "Please enter your Date (To)!",
                },
              ]}
            >
              <DatePicker className={styles.leaveInputField}
                onChange={(value) => handleFormChange("end_date", value)}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  marginBottom: "15px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Form.Item name="from_second_half_start_date" >
                  {" "}
                  <Switch
                    checked={editLeaveDetails.from_second_half_start_date}
                    onChange={() =>
                      setEditLeaveDetails({
                        ...editLeaveDetails,
                        from_second_half_start_date:
                          !editLeaveDetails.from_second_half_start_date,
                      })
                    }
                  />
                  {' '}{' '}Second half day
                </Form.Item>
              </div>
              <div
                style={{
                  marginBottom: "15px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Form.Item
                  name="till_first_half_end_date"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Date (From)!",
                    },
                  ]}
                >
                  <Switch
                    checked={editLeaveDetails.till_first_half_end_date}
                    name="from_second_half_start_date"
                    onChange={() =>
                      setEditLeaveDetails({
                        ...editLeaveDetails,
                        till_first_half_end_date:
                          !editLeaveDetails.till_first_half_end_date,
                      })
                    }
                  />
                  {' '}{' '}First half day
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }}>
            <div style={{ height: "auto" }}>
              {typeof leaveRuleByDate?.message === "array" && leaveRuleByDate?.message?.length > 0 && (
                <div
                  style={{
                    background: "#FDF3CF",
                    height: "auto",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      padding: "10px",
                      height: "40px",
                    }}
                  >
                    <img src={Error} alt="" />
                    <p style={{ margin: "0px" }}>
                      {leaveRuleByDate?.message[0]}
                    </p>
                  </div>
                </div>

              )}
              {leaveRuleByDate?.message && (
                <div
                  style={{
                    background: "#FDF3CF",
                    height: "auto",
                    borderRadius: "5px",

                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      padding: "10px",

                    }}
                  >
                    <img src={Error} alt="" />
                    <p style={{ margin: "0px" }}>
                      {leaveRuleByDate?.message}
                    </p>
                  </div>
                </div>

              )}
            </div>
          </Col>
          {
            leaveRule?.doc_requried
            &&
            <Col span={22}>
              <p>Upload File</p>
              <Form.Item
                label="Upload File"
                name="document"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Date (To)!',
                  },
                ]}
              >
                <div className="file-upload-container">
                  <input
                    type="file"
                    id="file-upload"
                    className="input-file"
                    onChange={(e) =>
                    // setFile(e.target.files && e.target.files[0])
                    {
                      // if (e.target.files[0] && e.target.files[0].type === 'text/csv') {
                      //     setFile(e.target.files && e.target.files[0])
                      // } else {
                      //     // Reset the file state and show an error message
                      //     setFile(null);
                      //     alert('Please upload a CSV file.');
                      // }

                      const selectedFile = e.target.files[0];
                      // const maxSizeInBytes = 5 * 1024 * 1024;

                      if (selectedFile && selectedFile.type === 'application/pdf') {
                        // if (selectedFile.size <= maxSizeInBytes) {
                        console.log('editLeaveDetails../../../../', selectedFile)
                        setEditLeaveDetails({
                          ...editLeaveDetails,
                          document: selectedFile
                        })
                        // }
                        // else {
                        //   // setFile(null);
                        //   alert('File size exceeds the limit of 5MB.');
                        // }
                      } else {
                        // Reset the file state and show an error message
                        // setFile(null);
                        alert('Please upload a PDF file.');
                      }
                    }
                    }
                  />
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                    <label htmlFor="file-upload" className="file-upload-label">

                    </label>
                    {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                    <p style={{ zIndex: 1, margin: "0px" }}>{editLeaveDetails.document ? editLeaveDetails.document.name : 'Drop Your File Here'}</p>
                  </div>
                </div>
              </Form.Item>
            </Col>

          }
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              label="Reason"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Please add Reason!",
                },
              ]}
            >
              <TextArea
                rows={5}
                placeholder="Enter Your Reason"
                className={styles.leaveTextArea}
                onChange={(e) =>
                  handleFormChange("reason", e.target.value)
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Button htmlType="submit" className={styles.applyLeave}>
          {appliedLeaveId ? "Edit Leave" : "Apply Leave"}
        </Button>
      </Form>
    </div>
  );
}

export default ApplyLeave;
