import home from "../assets/MobileMenu/home.png";
import homeActive from "../assets/MobileMenu/homeActive.png";
import attendance from "../assets/MobileMenu/attendance.png";
import attendanceActive from "../assets/MobileMenu/attendanceActive.png";
// import profile from "../assets/MobileMenu/profile.png";
// import profileActive from "../assets/MobileMenu/profileActive.png";
import more from "../assets/MobileMenu/more.png";
import moreActive from "../assets/MobileMenu/moreActive.png";
import inbox from "../assets/MobileMenu/inbox.png"
import inboxActive from "../assets/MobileMenu/inboxActive.png"

const mobileMenuList = [
    {
        key: "home",
        image: home,
        activeImage: homeActive,
        label: "Home",
        path: "/dashboard"
    },
    {
        key: "attendance",
        image: attendance,
        activeImage: attendanceActive,
        label: "Attendance",
        path: "/attendance"
    },
    {
        key: "inbox",
        image: inbox,
        activeImage: inboxActive,
        label: "Inbox",
        path: "/inbox/leave"
    },
    {
        key: "more",
        image: more,
        activeImage: moreActive,
        label: "More",
        path: ""
    },
]


export default mobileMenuList;