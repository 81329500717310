import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DynamicTable from "../../../../component/TableWithPagination/TableWithPagination";
import request from "../../../../utils/axios";
import useScreenSize from "../../../../utils/getScreenSize";
import calendar from "../../../../assets/calendar.svg";
import location from "../../../../assets/location.svg";
import InfiniteScroll from "../../../../component/InfiniteScroll/InfiniteScroll";
import { useNavigate } from "react-router-dom";
import back from "../../../../assets/Recruitment/back.svg";
// import "../ReferralOpening/ReferralOpening.scss"
import "../../../Recruitment/User/ReferralOpening/ReferralOpening.scss";
import CommonTable from "../../../../component/CommonTable/CommonTable";
import { toast } from "react-toastify";
import rupee from "../../../../assets/activePayrollImg.svg";
import workMode from "../../../../assets/workMode.png";

import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import mobileStyle from "../../Admin/Tabs/Mobile.module.scss";
import dayjs from "dayjs";
import InfiniteScrollComponent from "../../../../component/InfiniteScroll/InfiniteScroll";
function RequisitionFullView() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5, // Number of items per page
    total: 0,
  });
  const onHandleChangeForRequisition = async (value, id) => {
    //  approved
    if (value === 1) {
      // setApprovedModal(true)
      navigate(`/admin/recruitment/requisition/job-form/${id}`, {
        replace: true,
      });
    }
    // Rejected
    if (value === 3) {
      const data = await request({
        url: `recruitment/requisitions/status/update?id=${id}`,
        method: "PUT",
        data: {
          status: "REJECTED",
        },
      });
      getallrequistion();
      toast.success("Requisition Rejected successfully", {
        position: "bottom-left",
      });
    }
  };

  const requisitionColumns = [
    {
      title: "Job Ttile",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Raised By",
      dataIndex: "raised_by",
      key: "raised_by",
    },
    {
      title: "Salary Range",
      dataIndex: "salary_range",
      key: "salary_range",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text, record) => (
        <div>
          {record.location.map((item, index) => {
            return (
              <span key={index}>
                <span>{item}</span>
                {record?.location?.length - 1 !== index && <span> , </span>}
              </span>
            );
          })}
        </div>
      ),
    },
    {
      title: "Function",
      dataIndex: "job_function",
      key: "job_function",
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Select
          value={record.status === null ? 2 : record.status}
          onChange={(value) => onHandleChangeForRequisition(value, record.id)}
          style={{ width: 150 }}
        >
          {/* <Select.Option value={0}>Select</Select.Option> */}
          <Select.Option value={1}>Approved</Select.Option>
          <Select.Option value={2}>Pending</Select.Option>
          <Select.Option value={3}>Rejected</Select.Option>
        </Select>
      ),
    },
  ];

  const screenSize = useScreenSize();

  const [requisitionList, setRequisitionList] = useState([]);
  const getallrequistion = async () => {
    const data = await request({
      method: "get",
      url: `recruitment/requisitions/all?page=${pagination.current}&page_size=${pagination.pageSize}`,
    });
    setRequisitionList(data?.pagination_data.data);
    // return data
    setPagination({
      ...pagination,
      total: data?.pagination_data?.total,
    });
  };
  useEffect(() => {
    getallrequistion();
  }, [pagination.current]);

  const renderPosts = (posts) => (
    <>
      {posts.map((item, index) => (
        <div className="mobileContainer">
          <div key={index} className="adminJobListCard">
            <div className="headingContainer">
              <span className="title">{item.candidate_name}</span>
              <div style={{ display: "flex" }}>
                <button
                  style={{
                    backgroundColor: "#28B15F",
                    color: "white",
                    padding: "4px 12px",
                    border: "none",
                    fontSize: "13px",
                    borderRadius: "2px",
                  }}
                >
                  Selected
                </button>
              </div>
            </div>
            <span className="function">{item.job}</span>
            <br />
            <span className="function">{item.job}</span>
            <br />
            <span className="function">{item.job}</span>
            <div className="adminJobListCardFooter">
              {/* <div> */}

              <span style={{ display: "flex", alignItems: "center" }}>
                <img src={location} alt="" />{" "}
                <span className="location">
                  {item.location.map((el, index) => {
                    return (
                      <span key={index}>
                        <span>{el}</span>
                        {item?.location?.length - 1 !== index && (
                          <span> , </span>
                        )}
                      </span>
                    );
                  })}
                </span>
              </span>
              {/* </div> */}
            </div>
          </div>
        </div>
      ))}
    </>
  );

//   useEffect(() => {
//     ref?.current?.scrollIntoView({ behavior: "instant" });
//     document.body.scrollTop = document.documentElement.scrollTop = 0;
//   }, []);

  const renderUI = (dataList) => {
    return dataList?.map((item, index) => {
      return (
        <>
          <div key={index} style={{ padding: "0px 16px" }}>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p className={mobileStyle.candidate}>{item.job_title}</p>
                <Select
                  value={item.status === null ? 2 : item.status}
                  onChange={(value) => {
                    onHandleChangeForRequisition(value, item.id);
                  }}
                  style={{ width: 150 }}
                >
                  {/* <Select.Option value={0}>Select</Select.Option> */}
                  <Select.Option value={1}>Approved</Select.Option>
                  <Select.Option value={2}>Pending</Select.Option>
                  <Select.Option value={3}>Rejected</Select.Option>
                </Select>
              </div>
              <p className={mobileStyle.jobTitle}>{item.industry}</p>
              <p className={mobileStyle.date}>
                <img src={rupee} alt="rupee" width={20} height={20} />{" "}
                {item.salary_range}
              </p>
              <p className={mobileStyle.time}>
                <img src={workMode} alt="workMode" width={20} height={20} />{" "}
                {item?.job_function}
              </p>
              <p className={mobileStyle.location}>
                <img src={location} alt="location" width={20} height={20} />{" "}
                {item.location.map((el, elIndex) => {
                  return (
                    <span key={elIndex}>
                      {el}
                      {item?.location?.length - 1 !== elIndex && (
                        <span> , </span>
                      )}
                    </span>
                  );
                })}
              </p>
            </div>
          </div>
          {index !== requisitionList.length - 1 && <hr />}
        </>
      );
    });
  };

  const renderUIDesktop = (dataList) => {
    return (
      <Table
        columns={requisitionColumns}
        dataSource={dataList}
        pagination={false}
      />
    );
  };

  return (
    <div className="Container" ref={ref}>
      {screenSize.width < 768 ? ( // mobile view
        <div className={mobileStyle.internalJobOpening}>
          <div className={mobileStyle.headerContainer}>
            <p className={mobileStyle.heading}>Requisitions</p>
            {/* <img src={fullView} alt="fullView" /> */}
          </div>

          <InfiniteScrollComponent
            apiEndpoint="recruitment/requisitions/all"
            renderUI={renderUI}
            initialPage={1}
          />
        </div>
      ) : (
        <>
          <div>
            {/* <h1>Requisition</h1> */}

            <div
              className="referralSubContainer"
              style={{ background: "white" }}
            >
              <div style={{ marginTop: "2vh" }}>
                <InfiniteScrollComponent
                  apiEndpoint="recruitment/requisitions/all"
                  renderUI={renderUIDesktop}
                  initialPage={1}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default RequisitionFullView;
