import { LinkOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from "./InvestmentDeclaration.module.scss"
import request from '../../../../../utils/axios';
import {
    setInvestmentDeclaration
} from "../../../../../redux/reducer/TaxDeclarationReducer"
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';

function InvestmentDeclaration({ windowOpen }) {
    const dispatch = useDispatch();
    const investmentDeclaration = useSelector(state => state.taxDeclaration.investmentDeclaration)

    const [investmentDeclarationData, setInvestmentDeclarationData] = useState([])

    const fetchInvestmentDeclarationData = async () => {
        try {
            const data = await request({
                method: "GET",
                url: `regim_tax_rules/get-all-investment-declaration`,
            })

            setInvestmentDeclarationData(data.data)
            dispatch(setInvestmentDeclaration(data.data))
        }
        catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        fetchInvestmentDeclarationData()
    }, [])

    const onHandleChange = (section, key, value, index) => {

        if (key === "attachment") {

            const reader = new FileReader();
            reader.readAsDataURL(value);
            reader.onload = () => {
                const updatedData = update(investmentDeclarationData, {
                    [index]: { [key]: { $set: reader.result }, file_name: { $set: value.name } }
                });

                setInvestmentDeclarationData(updatedData)

                dispatch(setInvestmentDeclaration(updatedData))
            };
            reader.onerror = (error) => {
                console.error('Error uploading image: ', error);
            };
        }
        else {
            const updatedData = update(investmentDeclarationData, {
                [index]: { [key]: { $set: value } }
            });

            setInvestmentDeclarationData(updatedData)

            dispatch(setInvestmentDeclaration(updatedData))
        }
    }

    return (
        <div className={styles.investmentDeclaration}>
            <p className={styles.investmentDeclarationText}>Investment Declaration</p>
            <div>

                <Form>
                    <Row gutter={32}>
                        {
                            investmentDeclarationData?.map((item, index) => {
                                return (
                                    <>
                                        <Col span={24}>
                                            <div className={styles.declartionBox}>
                                                <p className={styles.declartionText}>{item.title}</p>
                                            </div>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item>
                                                <Select size='large'
                                                    value={investmentDeclarationData[index]?.choice}
                                                    onChange={(e) => onHandleChange("section80C", "choice", e, index)}
                                                    disabled={windowOpen}
                                                >
                                                    {item?.choiceList.map((item, index) => (
                                                        <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={18}></Col>
                                        <Col span={24}>
                                            {
                                                investmentDeclarationData[index]?.choice !== null &&
                                                <Row gutter={32}>
                                                    <Col span={6}>
                                                        <Form.Item>
                                                            <Input size='large'
                                                                placeholder='Enter Amount'
                                                                value={investmentDeclarationData[index]?.declared_amount}
                                                                onChange={(e) => onHandleChange("section80C", "declared_amount", e.target.value, index)}
                                                                disabled={windowOpen}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={3}>
                                                        <Form.Item>
                                                            <div
                                                                className={styles.attachFileBox}
                                                            >
                                                                <input
                                                                    type="file"
                                                                    id="file-upload"
                                                                    className={styles.inputFile}
                                                                    onChange={(e) =>
                                                                    // setFile(e.target.files && e.target.files[0])
                                                                    {
                                                                        console.log("e.target.files[0]", e.target.files[0])

                                                                        const selectedFile = e.target.files[0];

                                                                        if (selectedFile) {
                                                                            onHandleChange("section80C", "attachment", selectedFile, index)
                                                                            // onHandleChange(index, "attach_file", selectedFile)
                                                                            // setFile(e.target.files && selectedFile)
                                                                        }
                                                                    }
                                                                    }
                                                                    disabled={windowOpen}
                                                                />
                                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>

                                                                    <label htmlFor="file-upload" >

                                                                    </label>
                                                                    <p
                                                                        className={styles.attachFileLabel}
                                                                        style={{ marginTop: "7px" }}>
                                                                        <LinkOutlined /> {" "}Attach File
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={5}>
                                                        <p style={{ margin: '0', marginTop: "10px" }}>{item?.file_name}</p>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                    </>
                                )
                            })
                        }
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default InvestmentDeclaration