import React, { useEffect, useRef, useState } from 'react'
import "./Desktop.scss"
import { Button, Col, DatePicker, Form, Input, Modal, Popover, Row, Select, Switch, Table, TimePicker } from 'antd'
import moment from 'moment'

import sun from "../../../../assets/Attendance/Admin/sun.svg"
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination'
import edit from "../../../../assets/edit.svg"
import fullView from "../../../../assets/fullView.svg"
import CalendarScheduler from '../../../../component/CalendarScheduler/CalendarScheduler'
import request from '../../../../utils/axios'
import getScreenSize from "../../../../utils/getScreenSize"
import location from "../../../../assets/location.svg"
import { DownloadOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons'
import integarationStatus from "../../../../assets/Attendance/Admin/integrationStatus.svg"
import attendanceUpload from "../../../../assets/Attendance/Admin/attendanceUpload.svg"
import date from "../../../../assets/Attendance/Admin/date.svg";
import leave from "../../../../assets/Attendance/Admin/leave.svg"
// import duration from "../../../../assets/Attendance/Admin/duration.svg"
import total from "../../../../assets/Attendance/Admin/total.svg"
import absent from "../../../../assets/Attendance/Admin/absent.svg"
import Early from "../../../../assets/Attendance/Admin/Early.svg"
import onTime from "../../../../assets/Attendance/Admin/onTime.svg"
import lateArrival from "../../../../assets/Attendance/Admin/lateArrival.svg"
import timeOff from "../../../../assets/Attendance/Admin/timeOff.svg"
import PolicyForm from '../PolicyForm/PolicyForm'
import { useNavigate } from 'react-router-dom'
import AttendanceReportSearchBar from '../AttendanceReportSearchBar/AttendanceReportSearchBar'
import cutomIcon from "../../../../assets/Attendance/cutomIcon.svg"
import AttendanceCalendarReportSearchBar from '../AttendanceCalendarReportSearchBar/AttendanceCalendarReportSearchBar'
import mobileStyles from "./Mobile.module.scss"
import add from "../../../../assets/Org/add.svg"
import timeduration from "../../../../assets/Dashboard/Admin/duration.png";
import dateImg from "../../../../assets/Dashboard/Admin/date.png";
import EmployeeAttendancePolicyManagerSearchBar from '../EmployeeAttendancePolicyManagerSearchBar/EmployeeAttendancePolicyManagerSearchBar'
import clone_icon from "../../../../assets/clone_icon.svg"
import { toast } from 'react-toastify'
import time from "../../../../assets/time.svg"
import info from "../../../../assets/info.png"
import weekOff from "../../../../assets/weekOff.png";
import duration from "../../../../assets/Dashboard/Admin/duration.png";
import calendar from "../../../../assets/calendar.svg";
import download from "../../../../assets/download.png"
import dayjs from 'dayjs'
import TextArea from 'antd/es/input/TextArea'
function AttendanceDashBoardView({ changeTabsValue }) {
  const ref = useRef(null);
  const [form] = Form.useForm()
  const downloadLink = `${process.env.REACT_APP_IMG_BASE_URL}/media/Sample_Formats/Attendance Upload Sample.csv`;
  const [shiftModel, setShiftModel] = useState(false)

  const [regularizationForm] = Form.useForm();
  
  const [openRegularizationModal, setOpenRegularizationModal] = useState(false)
  
  const [selectedDate, setSelectedDate] = useState(new Date())

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null)

  const [selectedShift, setSelectedShift] = useState(null)

  const attendancePolicyColumns = [
    {
      title: 'Policy',
      dataIndex: 'policy_name',
      key: 'policy_name',
    },
    {
      title: ' Working Policy',
      dataIndex: 'working_policy',
      key: 'working_policy',
    },
    {
      title: 'Shift Time',
      dataIndex: 'shifts',
      key: 'shifts',
      render: (text, record) => <>

        {

          // is_standard_shift
          record?.is_standard_shift
            // true
            ?
            <>
              <div style={{ display: "flex", gap: "10px" }}>
                {
                  record?.shifts[0]?.timings[0].start_time
                }
                {" "}to {" "}
                {
                  record?.shifts[0]?.timings[0].end_time
                }

                {
                  record?.shifts[0]?.timings.length > 1
                    ?
                    <div
                      style={{ borderRadius: "50%", height: "25px", width: "25px", background: "#F1EEEE", color: "#6D6D6D", alignItems: "center", justifyContent: "center", display: "flex" }}
                      onClick={() => {
                        setStandardShitTimeViewModal(true)
                        setViewStandardShiftTimeData(record)
                      }}
                    >
                      {"+"}{record?.shifts[0]?.timings.length - 1}
                    </div>
                    :
                    ""
                }

              </div>
              {standardShitTimeViewModal && renderStandardShiftTime(record)}
            </>
            :
            <>
              <button
                onClick={() => {
                  setCustomeShitTimeViewModal(true)
                  setViewCustomeShiftTimeData(record)
                }}
                style={{ padding: "5px 10px", borderRadius: "5px", border: "none", color: "#9b9797", background: "none" }}
              >Custom <img src={cutomIcon} alt="" /></button>

              {
                customeShitTimeViewModal && renderCustomShiftTime(record)
              }

            </>


        }</>
    },
    {
      title: 'Weekly Off',
      dataIndex: 'week_off_entries',
      key: 'week_off_entries',
      render: (text, record) => <>
        <div className='renderCustomWeeklyOffOutterContainer'>
          {
            record?.is_week_off_standard
              ?
              <>
                <button
                  onClick={() => {
                    setStandardViewModal(true)
                    setSetPolicyId(record.policy_id)
                  }}
                  style={{ padding: "5px 10px", borderRadius: "5px", border: "none", color: "#9b9797", background: "none" }}
                >
                  {record?.week_off_entries[0]?.total_leaves} Weekly off  <img src={cutomIcon} alt="" />
                </button>
                {
                  standardViewModal && renderStandardWeeklyOff(record)
                }
              </>
              :
              <>
                <button
                  onClick={() => {
                    setCustomeViewModal(true)
                    setSetPolicyId(record.policy_id)
                  }}
                  style={{ padding: "5px 10px", borderRadius: "5px", border: "none", color: "#9b9797", background: "none" }}
                >Custom <img src={cutomIcon} alt="" /></button>

                {
                  customeViewModal && renderCustomWeeklyOff(record)
                }
              </>
          }
        </div>
      </>
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => <>
        <img src={edit} alt="edit" style={{ cursor: "pointer", marginRight: "20px" }} onClick={() => { navigate(`/admin/attendance/dashboard/policy-form/${record.policy_id}`, { replace: true }) }} />
        <img src={clone_icon} alt="clone" style={{ cursor: "pointer" }} onClick={() => { navigate(`/admin/attendance/dashboard/policy-form/${record.policy_id}/clone`, { replace: true }) }} />
        {/* <button onClick={() => >Clone</button> */}
      </>
    }
  ]

  const attendanceReportColumns = [
    {
      title: 'Id',
      dataIndex: 'employee_id',
      key: 'employee_id',
    },
    {
      title: ' Date',
      dataIndex: 'attendance_date',
      key: 'attendance_date',
    },
    {
      title: 'Employee',
      dataIndex: 'employee_name',
      key: 'employee_name',
    },
    {
      title: 'Role/Department',
      dataIndex: 'role',
      key: 'role',
      render: (text, record) => <>
        {
          record.designation + " / " + record.department
        }
      </>
    },
    {
      title: ' Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <>
        {
          text === "Present" && <span style={{ backgroundColor: "#008080", padding: "5px 10px", color: "white", borderRadius: "5px" }}>{text}</span>
        }
        {
          text === "Absent" && <span style={{ backgroundColor: "#F9A1B5", padding: "5px 10px", color: "white", borderRadius: "5px" }}>{text}</span>
        }
        {
          text === "On Leave" && <span style={{ backgroundColor: record.color, padding: "5px 10px", color: "white", borderRadius: "5px" }}>{record.leave_type}</span>
        }
        {
          text === "Weekly Off" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "5px" }}>{text}</span>
        }
        {
          text === "Yet to checkin" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "5px" }}>Pending</span>
        }
        {
          text === "Holiday" && <span style={{ backgroundColor: "#33b249", color: "white", padding: "5px 10px", borderRadius: "5px" }}>{record.holiday_name}</span>
        }
        {
          text === "Late ClockIn" && <span style={{ backgroundColor: "#f53444", padding: "5px 10px", color: "white", borderRadius: "5px" }}>{text}</span>
        }
        {
          text === "Early Departure" && <span style={{ backgroundColor: "rgb(0, 96, 255)", padding: "5px 10px", color: "rgb(233, 241, 255)", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>Short Duration</span>
        }

      </>
    },
    {
      title: 'Check-in',
      dataIndex: 'check_in_time',
      key: 'check_in_time',
    },
    {
      title: 'Check-out',
      dataIndex: 'check_out_time',
      key: 'check_out_time',
      render: (text, record) => <span>
        {
          text === null ? "Pending..." : text
        }
      </span>
    },
    {
      title: 'Work hours',
      dataIndex: 'working_duration',
      key: 'working_duration',
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
          <div>
              {
                  !record.is_holiday && !record.is_weekly_holiday && !record.on_leave && !record.is_pending &&

                  <Button className="button" onClick={() => {
                      setSelectedDate(record.attendance_date)
                      setSelectedEmployeeId(record.employee_id)
                      setSelectedShift(record.shift_type)
                      setOpenRegularizationModal(true)
                  }}>
                      Regularise
                  </Button>


              }
          </div>)
  },
  ]

  const employeeAttendancePolicyMangerColumns = [
    {
      title: 'Id',
      dataIndex: 'employement_number',
      key: 'employement_number',
    },
    {
      title: 'Employee Name',
      dataIndex: 'employee_name',
      key: 'employee_name',
    },
    {
      title: 'Work Policy',
      dataIndex: 'work_policy',
      key: 'work_policy',
    },
    {
      title: 'Action',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => <img src={edit} alt="edit" onClick={() => {
        setSelectedEmployeeAttendancePolicyManager({
          ...selectedEmployeeAttendancePolicyManager,
          user_id: record.employement_number,
          policy_id: +record.policy_id
        })
        setChangeAttendancePolicyModel(true)
      }} />
    }
  ]

  const integarationStatusColumns = [
    {
      title: 'Device Name',
      dataIndex: 'device_name',
      key: 'device_name',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Employee Number',
      dataIndex: 'total_employees',
      key: 'total_employees',
      render: (text, record) => <span
      onClick={() => navigate(`/admin/attendance/integaration-status/${record.id}`)}
      >{record.total_employees}</span>
      
    },
  ]

  const [integarationStatus, setIntegrationStatus] = useState([])

  const fetchBiometricIntegrationStatus = async () => {
    try {
      
      const response = await request({
        method: "get",
        url: "/biometric/admin/biometric-devices/",
      });
      console.log("fetchBiometricIntegrationStatus",response?.data)
      setIntegrationStatus(response?.data)
    }
    catch (e) {
      console.log(e)
    }
  }

  const [overtimeInfoModal, setOvertimeInfoModal] = useState(false);
  const [selectedOverTimeData, setSelectedOverTimeData] = useState(null)
  const fetchEligibleDays = async (empId) => {
    try {
      const response = await request({
        method: "get",
        url: `/attendance/admin/overtime-attendance/eligible-days?employment_number=${empId}`,
      });

      setEligibleDays(response?.eligible_days)
      const options = [];

      for (let i = 1; i <= response?.eligible_days; i++) {
        options.push(i);
      }
      setNumberOfDays(options)
    }
    catch (e) {
      console.log(e)
    }
  }
  const overtimeReportColumns = [
    {
      title: "Employee name",
      dataIndex: "employee_name",
      key: "employee_name"
    },
    {
      title: "Hours",
      dataIndex: "total_overtime_duration",
      key: "total_overtime_duration",
      render: (text, record) => <p>
        {text}
        <InfoCircleOutlined
          style={{ color: "#008080", marginLeft: "10px" }}
          onClick={() => {
            setOvertimeInfoModal(true)
            setSelectedOverTimeData(record)
          }}
        />
      </p>
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => <button
        style={{
          border: "none",
          background: "#008080",
          color: "white",
          cursor: "pointer",
          borderRadius: "4px",
          height: "28px",
          padding: "0px 10px"
        }}
        onClick={() => {
          fetchEligibleDays(record.employee)
          setOvertimeFormModal(true)
          setSelectedOverTimeData(record)
        }}
      >
        Comp Off
      </button>
    },
  ]
  const navigate = useNavigate();
  const [calendarView, setCalendarView] = useState(false);
  const [calendarData, setCalendarData] = useState([])
  const [calendarMonth, setCalendarMonth] = useState('');
  const [calendarYear, setCalendarYear] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [employeeList, setEmployeeList] = useState([]);
  // const [loading, setLoading] = useState(true);
  const screenSize = getScreenSize();
  const [addendancePolicy, setAddendancePolicy] = useState([]);
  const [attendanceReport, setAttendanceReport] = useState([]);

  const [setPolicyId, setSetPolicyId] = useState(null);
  const [specificPolicyDetails, setSpecificPolicyDetails] = useState({});

  const [selectedOrganisation, setSelectedOrganisation] = useState(null);

  const fetchSpecificPolicy = async () => {
    try {
      const data = await request({
        method: "get",
        url: `attendance/policy/details/?policy_id=${setPolicyId}`,
      })
      setSpecificPolicyDetails(data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const [adminWidget, setAdminWidget] = useState({
    total_count: "0",
    on_time: "0",
    on_late: "0",
    absent_count: "0",
  })
  const handleSwitchChange = (checked) => {
    setCalendarView(checked);
  }

  const handleSelectChange = (value) => {
    setEmployeeId(value)
  }

  useEffect(() => {
    if (employeeId) {
      fetchCalendarData();
    }
  }, [employeeId, calendarMonth, calendarYear])

  const fetchCalendarData = async () => {
    try {
      const data = await request({
        method: "get",
        url: `attendance/admin/employee/calender/monthly/?employement_number=${employeeId}&month=${calendarMonth}&year=${calendarYear}`,
      })
      setCalendarData(data.data.monthly_attendance)
    } catch (error) {
      console.log(error)
    }
  }
  const fetchEmployeeList = async () => {
    const data = await request({
      method: "get",
      url: `get-all-employees`,
    })

    setEmployeeList(data.data)
    // setLoading(false)
  }

  const fetchAdminWidget = async () => {
    try {
      const data = await request({
        method: "get",
        url: `attendance/admin/widget/`,
      })
      setAdminWidget({ ...data })
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAttendanceReport = async () => {
    try {
      const data = await request({
        method: "get",
        url: `attendance/admin/employees/attendance/details/?page=1&page_size=4`,
      })
      setAttendanceReport(data?.pagination_data.data)
      // return data
    } catch (error) {
      console.log(error)
    }
  }

  const [employeeAttendancePolicyMangerList, setEmployeeAttendancePolicyMangerList] = useState([])
  const fetchEmployeeAttendancePolicyManger = async () => {
    try {
      const data = await request({
        method: "get",
        url: `attendance/admin/attendance-policy-management/?page=1&page_size=4`,
      })
      setEmployeeAttendancePolicyMangerList(data.pagination_data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const [attendancePolicy, setAttendancePolicy] = useState([])

  const getAttendancePolicy = async () => {
    let data = await request({
      method: "get",
      url: `get-all-attendance-policy/`,
    })
    setAttendancePolicy(data.data)
  }

  useEffect(() => {
    if (setPolicyId) {
      fetchSpecificPolicy()
    }
  }, [setPolicyId])

  useEffect(() => {
    fetchAdminWidget()
    fetchEmployeeList()
    fetchAttendanceReport()
    fetchEmployeeAttendancePolicyManger()
    getAttendancePolicy()
    fetchOrganisationList()
    fetchBiometricIntegrationStatus()
  }, [])

  const fetchAttendancePolicy = async (page, pageSize) => {
    const data = await request({
      method: "get",
      url: `attendance/policies/all/?page=${page}&page_size=${pageSize}`,
    })
    setAddendancePolicy(data.pagination_data.data)
    return data;
  }
  useEffect(() => {
    if (screenSize.width < 768) {
      fetchAttendancePolicy(1, 4)
    }
  }, [screenSize.width])

  const [open, setOpen] = useState(false)

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ]

  const [customeShitTimeViewModal, setCustomeShitTimeViewModal] = useState(false)
  const [standardShitTimeViewModal, setStandardShitTimeViewModal] = useState(false)

  const [standardViewModal, setStandardViewModal] = useState(false)
  const [viewStandardShiftTimeData, setViewStandardShiftTimeData] = useState(null)
  const [viewCustomeShiftTimeData, setViewCustomeShiftTimeData] = useState(null)
  const renderStandardShiftTime = (record) => {
    console.log("record", record);
    return (
      <>
        <Modal
          centered
          open={standardShitTimeViewModal}
          onOk={() => setStandardShitTimeViewModal(false)}
          onCancel={() => setStandardShitTimeViewModal(false)}
          footer={null}
        >

          {
            viewStandardShiftTimeData?.shifts[0]?.timings.map((item, index) => {
              return (
                <div style={{ display: "flex", gap: "50px" }}>
                  {
                    index > 0
                    &&
                    <>
                      <p>Shift {index + 1}</p>
                      <p>
                        {item.start_time} to {item.end_time}
                      </p>
                    </>

                  }
                </div>
              )

            })
          }
        </Modal>
      </>
    )
  }


  const renderStandardWeeklyOff = (data) => {
    return (
      <>
        <Modal
          centered
          open={standardViewModal}
          onOk={() => setStandardViewModal(false)}
          onCancel={() => setStandardViewModal(false)}
          footer={null}
        >
          <div className='renderStandardWeeklyOffOuterDiv' >
            <p>All Weeks</p>
            <div>
              {
                specificPolicyDetails?.week_off_entries?.slice(0, 1).map((weekDetail, dayIndex) => {
                  return (
                    <div className='renderStandardWeeklyOffContainer' key={dayIndex}>
                      <div className='renderStandardWeeklyDaysContainer'>
                        {
                          days.map((day, index) => {
                            const selected = (weekDetail.week_off[index] === 1 || weekDetail.week_off[index] === 0.5);

                            return (
                              <div>
                                <div
                                  className={selected ? weekDetail.week_off[index] === 0.5 ? "selectedHalfDay" : "selectedFullDay" : "disabledFullDay"}
                                >
                                  {day.slice(0, 2)}
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Modal>
      </>
    )
  }

  const [customeViewModal, setCustomeViewModal] = useState(false)
  const renderCustomWeeklyOff = (data) => {
    return (
      <>
        <Modal
          centered
          open={customeViewModal}
          onOk={() => setCustomeViewModal(false)}
          onCancel={() => setCustomeViewModal(false)}
          footer={null}
        >
          {specificPolicyDetails?.week_off_entries?.map((weekDetail, weekIndex) => (
            <div key={weekIndex} >

              <div className='renderCustomWeeklyOffContainer'>

                <div className='selectNoOfDaysContainer'>
                  <div className='selectNoOfDaysInnerContainer'>
                    <p className='weekNumberText'>Week {weekIndex + 1}</p>
                  </div>
                </div>
                <div className='selectWeeksContainer'>
                  <div style={{ display: "flex", gap: 20 }}>
                    {days.map((day, index) => {
                      const isDaySelected = weekDetail.week_off[index] === 1 || weekDetail.week_off[index] === 0.5;
                      return (
                        <div
                          key={index}
                          className={isDaySelected ? weekDetail.week_off[index] === 0.5 ? "selectedHalfDay" : "selectedFullDay" : "disabledFullDay"}
                        >
                          {day.slice(0, 2)}
                        </div>
                      )
                    })}
                  </div>
                </div>

              </div>
            </div>
          ))}
        </Modal>

      </>
    );
  };

  const renderCustomShiftTime = (record) => {
    return (
      <>
        <Modal
          centered
          open={customeShitTimeViewModal}
          onOk={() => setCustomeShitTimeViewModal(false)}
          onCancel={() => setCustomeShitTimeViewModal(false)}
          footer={null}
          width={625}
        >
          {
            viewCustomeShiftTimeData.shifts.map((item, index) => {

              return (
                <div style={{ marginBottom: "5px", display: "flex", gap: "50px" }}>
                  week {item.week_number}
                  {
                    item?.timings.map((time, timeIndex) => {

                      return (
                        <div>
                          {time.start_time} to {time.end_time}
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </Modal>

      </>
    );
  };

  const downloadReport = async () => {
    if (attendanceReportDays === "custom") {
      const response = await request({
        method: "get",
        url: `attendance/admin/attendance-report-by-date/download?start_date=${attendanceReportCustomDate.start_date}&end_date=${attendanceReportCustomDate.end_date}`,
        responseType: "arraybuffer",
      });
      const file = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = "file.pdf";
      link.click();
    }
    else {
      const response = await request({
        method: "get",
        url: `attendance/admin/attendance-report-by-date/download?days=${attendanceReportDays}`,
        responseType: "arraybuffer",
      });
      const file = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = "file.pdf";
      link.click();
    }

  };

  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState([]);
  const [attendanceReportDays, setAttendanceReportDays] = useState(7);
  const [attendanceReportCustomDate, setAttendanceReportCustomDate] = useState({
    start_date: "",
    end_date: ""
  });

  const handleDateChange = async (value) => {
    const start_date = value[0]?.format("YYYY-MM-DD");
    const end_date = value[1]?.format("YYYY-MM-DD");
    setDateRange(value);
    setAttendanceReportCustomDate({ ...attendanceReportCustomDate, start_date, end_date })
  };

  const getPopupContainer = (triggerNode) => {
    return triggerNode.parentNode;
  };

  const handleDaysChange = (value) => {
    console.log("value", value);
    if (value === "custom") {
      // setShowDatePicker(true)
      setAttendanceReportDays(value);
      setOpen(true);
    } else {
      // setShowDatePicker(false)
      setAttendanceReportDays(value);
    }
  };

  const [changeAttendancePolicyModel, setChangeAttendancePolicyModel] = useState(false)
  const [selectedEmployeeAttendancePolicyManager, setSelectedEmployeeAttendancePolicyManager] = useState({
    user_id: "",
    policy_id: ""
  })

  const updateEmployeeAttendancePolicy = async (values) => {
    try {
      const response = await request({
        method: "post",
        url: `attendance/admin/employee/attendance-policy/update?employement_number=${selectedEmployeeAttendancePolicyManager.user_id}`,
        data: {
          policy_id: values.attendance_policy
        }
      });
      console.log("response", response);
      setChangeAttendancePolicyModel(false)
      fetchEmployeeAttendancePolicyManger();
    }
    catch (err) {
      console.log("err", err);
    }
  }

  const getMonthAndYearFromCalendar = (month, year) => {
    setCalendarMonth(month)
    setCalendarYear(year)
  }

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const [downloadModal, setDownloadModal] = useState(false)

  const [searchEmployeeList, setSearchEmployeeList] = useState([])

  const handleSearchSelectChange = async (value) => {
    try {
      const response = await request({
        method: "get",
        url: `attendance/admin/employee/attendance/details/?employement_number=${value}`,
      })

      setAttendanceReport([...response])
      setSearchEmployeeList([])
    }
    catch (err) {
      console.log(err);
    }

  }

  const search = async (data) => {
    try {
      const response = await request({
        method: "get",
        url: `leave/search/employees/?employee_name=${data}&type=admin`,

      })

      setSearchEmployeeList(response)
      if (data === "") {
        fetchAttendanceReport()
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  const [attendanceUploadModal, setAttendanceUploadModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [attendanceUploadFile, setAttendanceUploadFile] = useState(null)
  const uploadAttendanceCSVFile = async () => {
    try {
      const formData = new FormData();
      formData.append("file", attendanceUploadFile);
      const response = await request({
        method: "post",
        url: `/attendance/employees/attendance/upload/sample`,
        data: formData
      })
      setAttendanceUploadModal(false)
    }
    catch (err) {
      console.log(err)
    }
  }

  const [organisationList, setOrganisationList] = useState([]);
  const fetchOrganisationList = async () => {
    try {
      const data = await request({
        method: "get",
        url: `org/all`,
      });
      setOrganisationList(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      // setReload(false)
    }
  };

  const [File, setFile] = useState(null)

  const onFinish = async (values) => {
    const formData = new FormData();

    try {
      formData.append("org", values.org);
      formData.append("csv_file", File);

      const data = await request({
        method: "post",
        url: `/attendance/admin/attendance/upload/csv`,
        data: formData
      })
      console.log(data)
      setOpenModal(false);
      for (const x in data.message) {
        console.log(data.message[x].status)
        if (data.message[x].status === 400) {
          toast.error(data.message[x].message, {
            position: 'bottom-left',
          });
        }
        else if (data.message[x].status === 200) {
          toast.success(data.message[x].message, {
            position: 'bottom-left',
          });
        }
      }
    }
    catch (error) {
      console.log("error")
      toast.error("Could not add Employee, please try after some time", {
        position: 'bottom-left',
      });
    }
  }

  const [attendanceUploadFileList, setAttendanceUploadFileList] = useState([])
  const fetchAttendanceUploadFileList = async () => {
    try {
      const response = await request({
        method: "get",
        // url: `/attendance/employees/attendance/upload/sample`,
      })
      setAttendanceUploadFileList([...response.data])
    }
    catch (err) {
      console.log(err)
    }
  }
  const [overtimeForm] = Form.useForm();

  const [overtimeFormModal, setOvertimeFormModal] = useState(false)
  const [eligibleDays, setEligibleDays] = useState(0)
  const [numberOfDays, setNumberOfDays] = useState([])

  const [leaveList, setLeaveList] = useState([]);
  const fetchLeaveList = async () => {
    try {
      const response = await request({
        method: "get",
        url: "/leave/admin/comp-off/leaves-types/all",
      })
      setLeaveList(response.data)
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchLeaveList();
  }, [])

  const [overtimeCalendar, setOvertimeCalendar] = useState({
    month: null,
    year: null
  });
  const fetchDataWithRespectedMonth = async (data) => {
    const [calendarYear, calendarMonth] = dayjs(data)
      .format("YYYY-MM")
      .split("-");

    setOvertimeCalendar({
      month: calendarMonth,
      year: calendarYear,
    })
  };

  const [overTimeReportList, setOverTimeReportList] = useState([]);
  const fetchOverTimeReportList = async () => {
    try {
      const response = await request({
        method: "get",
        url: `/attendance/admin/overtime-attendance/all?page=1&page_size=5&${overtimeCalendar.month && `month=${overtimeCalendar.month}`}&${overtimeCalendar.year && `year=${overtimeCalendar.year}`}`,
      })

      setOverTimeReportList([...response.pagination_data.results])
    }
    catch (err) {
      console.log(err)
    }
  }

  const onFinishOvertime = async (values) => {
    try {
      await request({
        method: "post",
        url: `/leave/employee/leave-type/add/`,
        data: {
          employee: selectedOverTimeData?.employee,
          leave_type: values?.leave_type,
          leaves_number: values?.leaves_number,
          month: +overtimeCalendar.month,
          year: +overtimeCalendar.year
        }
      })
      overtimeForm.resetFields();
      setOvertimeFormModal(false)
    }
    catch (error) {
      console.log(error)
    }
  }

  const regularizeRequest = async (values) => {
    console.log("values .....", values);
    const data = await request({
        method: "post",
        url: `attendance/admin/regularization/action?shift=${selectedShift}&employement_number=${selectedEmployeeId}`,
        data: {
            attendance_date: selectedDate   ,
            ...values,
            check_in_time: dayjs(values.check_in_time).format(" HH:mm:ss"),
            check_out_time: values.check_out_time ? dayjs(values.check_out_time).format(" HH:mm:ss") : null,

        },
    });
    setOpenRegularizationModal(false);
    fetchAttendanceReport ();
    regularizationForm.resetFields();
    toast.success("Regularization request submitted successfully", {
        position: "bottom-left",
    });
}

  useEffect(() => {
    // fetchAttendanceUploadFileList()
    fetchOverTimeReportList();
    const today = new Date();
    setOvertimeCalendar({
      month: today.getMonth() + 1,
      year: today.getFullYear()
    })
  }, [overtimeCalendar.month, overtimeCalendar.year])

  const attendanceReportDownloadPopover = (
    <>
      <Select
        style={{ width: 300, height: 40, marginBottom: "15px" }}
        placeholder="Select an option"
        onChange={handleDaysChange}
        dafaultValue={attendanceReportDays}
      >
        <Select.Option value={7}>Last 7 days</Select.Option>
        <Select.Option value={14}>Last 14 days</Select.Option>
        <Select.Option value={30}>Last 30 days</Select.Option>
        <Select.Option value="custom">
          <Popover
            title=""
            content={
              <div className="modal-content">
                <RangePicker
                  value={dateRange}
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                />
              </div>
            }
            getPopupContainer={getPopupContainer}
            placement="right"
          >
            <p
              style={{
                cursor: "pointer",
                width: "90%",
                margin: "0px",
              }}
            >
              Custom Date
            </p>
          </Popover>
        </Select.Option>
      </Select>
      <br />
      <button
        style={{
          border: "none",
          color: "white",
          background: "#008080",
          borderRadius: "4px",
          height: "36px",
          padding: "0px 10px"
        }}
        onClick={() => downloadReport()}
      >
        Download
      </button>
    </>
  )


  const overtimeContent = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );

  return (
    <div ref={ref}>
      {
        screenSize.width < 768
          // Mobile View
          ?
          <div className={mobileStyles.mobileAttendanceAdminDashBoard}>


            {/* Company User Details */}
            <div className={mobileStyles.companyDetails}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <div className={mobileStyles.companyDetailsCard}>
                    <div className='flexSpaceBetween'>
                      <p className={mobileStyles.cardApiData}>{adminWidget.total_count}</p>
                      <img src={total} alt="" className={mobileStyles.cardImage} />
                    </div>
                    <p className={mobileStyles.cardTitle}>Total Employees</p>
                  </div>
                </Col>
                <Col span={12}>
                  <div className={mobileStyles.companyDetailsCard}>
                    <div className='flexSpaceBetween'>
                      <p className={mobileStyles.cardApiData}>{adminWidget.on_time}</p>
                      <img src={onTime} alt="" className={mobileStyles.cardImage} />
                    </div>
                    <p className={mobileStyles.cardTitle}>On Time</p>
                  </div>
                </Col>
                <Col span={12}>
                  <div className={mobileStyles.companyDetailsCard}>
                    <div className='flexSpaceBetween'>
                      <p className={mobileStyles.cardApiData}>{adminWidget.absent_count}</p>
                      <img src={absent} alt="" className={mobileStyles.cardImage} />
                    </div>
                    <p className={mobileStyles.cardTitle}>Absent</p>
                  </div>
                </Col>
                <Col span={12}>
                  <div className={mobileStyles.companyDetailsCard}>
                    <div className='flexSpaceBetween'>
                      <p className={mobileStyles.cardApiData}>{adminWidget.on_late}</p>
                      <img src={lateArrival} alt="" className={mobileStyles.cardImage} />
                    </div>
                    <p className={mobileStyles.cardTitle}>Late Arrival</p>
                  </div>
                </Col>
                <Col span={12}>
                  <div className={mobileStyles.companyDetailsCard}>
                    <div className='flexSpaceBetween'>
                      <p className={mobileStyles.cardApiData}>{adminWidget.early_departures}</p>
                      <img src={Early} alt="" className={mobileStyles.cardImage} />
                    </div>
                    <p className={mobileStyles.cardTitle}>Short Duration</p>
                  </div>
                </Col>
                <Col span={12}>
                  <div className={mobileStyles.companyDetailsCard}>
                    <div className='flexSpaceBetween'>
                      <p className={mobileStyles.cardApiData}>{adminWidget.on_holiday}</p>
                      <img src={timeOff} alt="" className={mobileStyles.cardImage} />
                    </div>
                    <p className={mobileStyles.cardTitle}>On-Holiday</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className={mobileStyles.divider}></div>

            {/* Attendance Policy */}
            <div className={mobileStyles.attendancePolicy}>
              <div className={mobileStyles.headingContainer}>
                <p
                  className={mobileStyles.heading}
                >Attendance Policy</p>
                <div>
                  <img src={add} alt=""
                    width={25} height={25}
                    onClick={() => navigate('/admin/attendance/dashboard/policy-form', { replace: true })}
                  />
                  <img src={fullView} alt="fullView" width={25} height={25} style={{ marginLeft: "20px" }}
                    onClick={() => {
                      changeTabsValue("2")
                    }}
                  />
                </div>
              </div>

              <div className={mobileStyles.cardListContainer}>
                {
                  addendancePolicy?.map((item, index) => {
                    return (
                      <>
                        <div className={mobileStyles.cardContainer}>
                          <div className={mobileStyles.box} >
                            <div className="flexSpaceBetween">
                              <p className={mobileStyles.policyName} >{item?.policy_name}</p>
                              <img src={edit} alt="edit" onClick={() => { navigate(`/admin/attendance/dashboard/policy-form/${item.policy_id}`, { replace: true }) }} />
                            </div>
                          </div>

                          {/* shift timings */}
                          <div className={mobileStyles.box}>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px" }}>
                              <img src={time} alt="time" />
                              {
                                item?.is_standard_shift
                                  ?
                                  <p style={{ fontSize: "16px", color: "#8A8A8A", margin: "0px" }}>Standard Shift</p>
                                  :
                                  <p style={{ fontSize: "16px", color: "#8A8A8A", margin: "0px" }}>Custome Shift</p>
                              }
                              <img src={info} alt="info" width={18} height={18}
                                onClick={() => {
                                  setShiftModel(true)
                                  setSetPolicyId(item.policy_id)
                                }}
                              />
                            </div>
                          </div>

                          {/* work policy */}
                          <div style={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px" }}>
                            <img src={duration} alt="duration" />
                            <p style={{ fontSize: "16px", color: "#8A8A8A", margin: "0px" }}>{item?.working_policy}</p>
                          </div>

                          {/* location */}
                          <div>
                            {
                              item.policy_location
                              &&
                              <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <img src={location} alt="location" />
                                <p style={{ fontSize: "16px", color: "#8A8A8A", margin: "0px" }}>{item?.policy_location}</p>
                              </div>
                            }
                          </div>

                          {/* weekOff */}
                          <div className={mobileStyles.box}>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px" }}>
                              <img src={weekOff} alt="weekOff" width={15} height={15} />
                              {
                                item.is_week_off_standard
                                  ?
                                  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                    <p style={{ fontSize: "16px", color: "#8A8A8A", margin: "0px" }}>Standard week off</p>
                                    <img src={info} alt="info" width={18} height={18}
                                      onClick={() => {
                                        setStandardViewModal(true)
                                        setSetPolicyId(item.policy_id)
                                      }}
                                    />
                                  </div>
                                  :
                                  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                    <p style={{ fontSize: "16px", color: "#8A8A8A", margin: "0px" }}>Custom week off</p>
                                    <img src={info} alt="info" width={18} height={18}
                                      onClick={() => {
                                        setCustomeViewModal(true)
                                        setSetPolicyId(item.policy_id)
                                      }}
                                    />
                                  </div>
                              }

                            </div>
                          </div>


                        </div>
                        <hr style={{ border: "1px solid #F0F0F0", width: "100%" }} />
                      </>
                    )
                  })
                }
              </div>

            </div>

            <div className={mobileStyles.divider}></div>

            {/* Attendance Report */}
            <div className={mobileStyles.attendanceReport}>
              <div className={mobileStyles.headingContainer}>
                <p className={mobileStyles.heading} >Attendance Report</p>
                <div style={{ width: "20%", justifyContent: 'space-between', display: "flex" }}><img src={download} alt="download" width={25} height={25}
                  onClick={() => {

                    setDownloadModal(true);
                  }}
                />
                  <img src={fullView} alt="fullView" width={25} height={25}
                    onClick={() => {
                      changeTabsValue("3")
                    }}
                  /></div>

              </div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 16px", marginTop: "20px", }}>
                <div>

                  <Switch
                    checked={calendarView}
                    onChange={handleSwitchChange}
                    size='small'
                  />
                  <span style={{ marginLeft: "10px", fontSize: "12px", color: "#616161" }}>
                    {
                      calendarView ? "Calendar View" : "Calendar View"
                    }
                  </span>
                </div>



                {/* <DownloadOutlined style={{ color: "#616161" }}

                /> */}

              </div>
              <div style={{ padding: "0px 16px", marginTop: "20px" }}>

                {
                  !calendarView &&
                  <div className="search-container">
                    <input
                      type="text"
                      placeholder="Search for employees"
                      onChange={(e) => search(e.target.value)}
                      style={{
                        height: "40px",
                        width: "100%",
                      }}
                    />
                    {searchEmployeeList.length > 0 && (
                      <ul>
                        {searchEmployeeList.map((item, index) => (
                          <li key={index} onClick={() => handleSearchSelectChange(item.employement_number)}>
                            {item.employee_name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                }
              </div>

              {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 16px" }}>
                <div>
                  <Select
                    style={{ width: 150, height: 30, marginTop: "20px" }}
                    placeholder="Select an option"
                    onChange={handleDaysChange}
                    defaultValue={attendanceReportDays}
                  >
                    <Select.Option value={7}>Last 7 days</Select.Option>
                    <Select.Option value={14}>Last 14 days</Select.Option>
                    <Select.Option value={30}>Last 30 days</Select.Option>
                    <Select.Option value="custom">
                      <span
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        Custom Date
                      </span>
                    </Select.Option>
                  </Select>

                  <Modal
                    title="Select Date Range"
                    centered
                    open={open}
                    onCancel={() => setOpen(false)}
                    onOk={async () => {
                      setOpen(false);
                    }}
                  >
                    <RangePicker
                      value={dateRange}
                      onChange={handleDateChange}
                      format="YYYY-MM-DD"
                      disabledDate={(current) =>
                        current && current > moment().endOf("day")
                      }
                      style={{ color: "black !important" }}
                    />
                  </Modal>
                </div>
                
              </div> */}

              {
                calendarView
                &&
                <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                  <AttendanceCalendarReportSearchBar
                    setTableDataForSearchEmployee={setCalendarData}
                    fetchTableDataFromApi={null}
                    placeholderText="Search for employees"
                    empType="admin"
                    setEmployeeId={setEmployeeId}
                  />
                  {/* <Select
                    className='dropDown'
                    placeholder="Select a person"
                    onChange={handleSelectChange}
                  >
                    {
                      employeeList?.map((item, index) => {
                        return (
                          <Select.Option key={index} value={item.employement_number}>{item.employee_name}</Select.Option>
                        )
                      }
                      )
                    }
                  </Select> */}
                </div>
              }
              {
                calendarView
                  ?
                  <div style={{ padding: "0px 16px" }}>
                    <CalendarScheduler
                      data={calendarData}
                      setCalendarMonth={setCalendarMonth}
                      setCalendarYear={setCalendarYear}
                      employeeId={employeeId}
                      getMonthAndYearFromCalendar={getMonthAndYearFromCalendar}
                    />
                  </div>
                  :
                  attendanceReport?.length === 0
                    ?
                    <center>
                      <span style={{ fontSize: "12px", color: "#DDDDDD" }}>
                        no data found
                      </span>
                    </center>
                    :
                    attendanceReport?.map((item, index) => {
                      return (
                        <>
                          <div className={mobileStyles.cardContainer}>
                            <div className={mobileStyles.box}>
                              <div className='flexSpaceBetween'>
                                <p className={mobileStyles.employeeName}>{item?.employee_name}</p>
                                {/* <button className={mobileStyles.workingPolicy}>{item.working_policy}</button> */}
                                <>
                                    {
                                        item.status === "Present" && <span style={{ backgroundColor: "#008080", padding: "5px 10px", color: "white", borderRadius: "30px", fontSize: "12px" }}>{item.status}</span>
                                    }
                                    {
                                        item.status === "Absent" && <span style={{ backgroundColor: "#F9A1B5", padding: "5px 10px", color: "white", borderRadius: "30px", fontSize: "12px" }}>{item.status}</span>
                                    }
                                    {
                                        item.status === "On Leave" && <span style={{ backgroundColor: item.color, padding: "5px 10px", color: "white", borderRadius: "30px", fontSize: "12px" }}>{item.leave_type}</span>
                                    }
                                    {
                                        item.status === "Weekly Off" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>{item.status}</span>
                                    }
                                    {
                                        item.status === "Yet to checkin" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>Pending</span>
                                    }
                                    {
                                        item.status === "Holiday" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>{item.holiday_name}</span>
                                    }
                                    {
                                        item.status === "Late ClockIn" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>{item.status}</span>
                                    }
                                    {
                                        item.status === "Early Departure" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>Short Duration</span>
                                    }
                                </>
                              </div>
                            </div>
                            <div>
                              <p className={mobileStyles.department} style={{ margin: "0px", marginBottom: "5px" }}>{item.department}</p>
                              <p className={mobileStyles.date}>
                                <img src={calendar} alt="" width={18} height={18} style={{ marginRight: "5px" }} />
                                {item.attendance_date}
                              </p>
                              <p className={mobileStyles.shift}> <img src={dateImg} alt="" style={{ marginRight: "5px" }} /><span className={mobileStyles.shiftTiming}>{item.shift_timing}</span></p>
                              <p className={mobileStyles.shift}> <img src={dateImg} alt="" style={{ marginRight: "5px" }} /><span className={mobileStyles.shiftTiming}>{item.check_in_time} - {item.check_out_time}</span></p>
                              <p className={mobileStyles.workingDuration}>
                                <img src={timeduration} alt="" style={{ marginRight: "5px" }} />
                                {item.working_duration}
                              </p>


                            </div>
                          </div>
                          <hr style={{ border: "1px solid #F0F0F0", width: "100%" }} />
                        </>
                      )
                    })
              }

            </div>

            <div className={mobileStyles.divider}></div>

            <div className={mobileStyles.employeeAttendancePolicyManager}>
              <div className={mobileStyles.headingContainer}>
                <p className={mobileStyles.heading}>
                  Employee Attendance Policy Manager
                </p>
                <img src={fullView} alt="fullView" width={25} height={25} style={{ marginLeft: "20px" }}
                  onClick={() => {
                    changeTabsValue("4")
                  }}
                />
              </div>
              <div>
                {
                  employeeAttendancePolicyMangerList.map((item, index) => {
                    return (
                      // <div>
                      //   <div className={mobileStyles.cardContainer}>
                      //     <p className={mobileStyles.label}>ID</p>
                      //     <p className={mobileStyles.value}>{item.employement_number}</p>
                      //   </div>
                      //   <div className={mobileStyles.cardContainer}>
                      //     <p className={mobileStyles.label}>Employee Name</p>
                      //     <p className={mobileStyles.value}>{item.employee_name}</p>
                      //   </div>
                      //   <div className={mobileStyles.cardContainer}>
                      //     <p className={mobileStyles.label}>Work Policy</p>
                      //     <p className={mobileStyles.value}>{item.work_policy}</p>
                      //   </div>
                      //   <div className={mobileStyles.cardContainer}>
                      //     <p className={mobileStyles.label}>Action</p>
                      //     <img src={edit} alt="edit" onClick={() => {
                      //       console.log("item", item);
                      //       setSelectedEmployeeAttendancePolicyManager({
                      //         ...selectedEmployeeAttendancePolicyManager,
                      //         user_id: item.employement_number,
                      //         policy_id: +item.policy_id
                      //       })
                      //       setChangeAttendancePolicyModel(true)
                      //     }} />
                      //   </div>
                      //   {
                      //     employeeAttendancePolicyMangerList.length - 1 !== index && <hr style={{ border: "1px solid #F0F0F0", width: "100%" }} />
                      //   }
                      // </div>
                      <div>
                        <div className={mobileStyles.cardContainer} style={{ marginBottom: "10px" }}>
                          <p className={mobileStyles.value} style={{ margin: "0px", fontWeight: "600" }}>{item.employee_name}</p>
                          <img src={edit} alt="edit" onClick={() => {
                            setSelectedEmployeeAttendancePolicyManager({
                              ...selectedEmployeeAttendancePolicyManager,
                              user_id: item.employement_number,
                              policy_id: +item.policy_id
                            })
                            setChangeAttendancePolicyModel(true)
                          }} />
                        </div>
                        <div className={mobileStyles.cardContainer}>
                          <p className={mobileStyles.label}>Employee ID</p>
                          <p className={mobileStyles.value}>{item.employement_number}</p>
                        </div>
                        <div className={mobileStyles.cardContainer}>
                          <p className={mobileStyles.label}>Work Policy</p>
                          <p className={mobileStyles.value}>{item.work_policy}</p>
                        </div>
                        {/* <div className={mobileStyles.cardContainer}>
                          <p className={mobileStyles.label}>Action</p>
  
                      </div> */}
                        {
                          employeeAttendancePolicyMangerList.length - 1 !== index && <hr style={{ border: "1px solid #F0F0F0", width: "100%" }} />
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <div className={mobileStyles.divider}></div>

            <div style={{ background: "white", marginTop: "20px" }}>
              <div className='flexSpaceBetween' style={{ marginBottom: "15px", padding: "0px 16px", }}>

                <p
                  style={{ fontSize: "20px", fontWeight: "600", margin: '0' }}
                >Overtime Attendance</p>
                <img src={fullView} alt="fullView" width={25} height={25} style={{ marginLeft: "20px" }}
                  onClick={() => {
                    changeTabsValue("5")
                  }}
                />
              </div>
              <div style={{ padding: "0px 16px" }}>
                <DatePicker
                  size="large"
                  picker="month"
                  style={{
                    // border: "none",
                    outline: "none",
                    // background: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#000000",
                  }}
                  onChange={(e) => {
                    fetchDataWithRespectedMonth(e);
                  }}
                  // can we set default value as month name
                  // defaultValue={moment().format("MMMM")}
                  defaultValue={dayjs().startOf('month')}
                  placeholder="Month"
                  format={"MMMM YYYY"}
                />
              </div>
              {
                overTimeReportList.map((item) => {
                  return (
                    <div>
                      <div style={{ padding: "0px 16px", marginTop: "20px" }}>
                        <div className='flexSpaceBetween' style={{ marginBottom: "10px" }}>
                          <p style={{ fontSize: "18px", color: "#161616", fontWeight: "600", margin: "0" }}>{item?.employee_name}</p>
                          <button style={{ border: "none", background: "#008080", color: "white", borderRadius: "5px", padding: "0px 10px", height: "30px" }}
                            onClick={() => {
                              fetchEligibleDays(item.employee)
                              setOvertimeFormModal(true)
                              setSelectedOverTimeData(item)
                            }}
                          >Comp off</button>
                        </div>
                        <div className='flexSpaceBetween'>
                          <p style={{ fontSize: "16px", color: "#4A4A4A", fontWeight: "400", margin: "0", marginBottom: "10px" }}>Over time</p>
                          <p style={{ fontSize: "16px", color: "#4A4A4A", fontWeight: "400", margin: "0", marginBottom: "10px" }}>{item?.total_overtime_duration}
                            {/* <Popover content={overtimeContent} title="Title" placement="bottomRight"> */}
                            <img src={info} alt="info" width={16} height={16} style={{ marginLeft: "5px" }}
                              onClick={() => {
                                setOvertimeInfoModal(true)
                                setSelectedOverTimeData(item)
                              }}
                            />
                            {/* </Popover> */}
                          </p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )
                })
              }
            </div>

            <div className={mobileStyles.divider}></div>

            <div style={{ padding: "0px 16px", marginTop: "30px" }}>
              <div className='flexSpaceBetween'>
                <p style={{ color: "#161616", fontSize: "24px", fontWeight: "500", margin: "0px" }}>Attendance Upload</p>
                <img src={add} alt="add"
                  onClick={() => setAttendanceUploadModal(true)}
                />
              </div>
              <br />
              <div>
                <Row>
                  <Col xs={24}>
                    <Row>
                      <Col xs={12}>
                        File Name
                      </Col>
                      <Col xs={12}>
                        Date
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={12}>
                        <span style={{ color: "#535151" }}>
                          File Name
                        </span>
                      </Col>
                      <Col xs={12}>
                        <span style={{ color: "#535151" }}>
                          Date
                        </span>
                      </Col>
                    </Row>
                  </Col>

                </Row>
              </div>
            </div>
          </div>
          :
          // Desktop
          <div className='attendanceAdminDashBoard'>


            {/* <p className='heading'>Attendance</p> */}

            {/* Company User Details */}
            <div className='companyDetails'>
              <Row>
                <Col xs={24} lg={5}>
                  <div className='dayDateCard'
                  >
                    <div
                      className='flexSpaceBetween'
                    >
                      <img src={sun} alt="" />
                      <div>
                        <span style={{ fontSize: "26px", color: "#252C58" }}>
                          {
                            moment(new Date()).format("hh:mm:ss A")
                          }
                        </span>
                        <br />
                        <span style={{ fontSize: "13px", color: "#252C58" }}>
                          Realtime Insight
                        </span>
                      </div>
                    </div>
                    <div style={{ marginTop: "120px", fontSize: "20px", color: "#252C58" }}>
                      Today:
                      <br />
                      {
                        moment(new Date()).format("DD MMMM, YYYY")
                      }
                    </div>
                  </div>

                </Col>
                <Col xs={24} lg={19}>
                  <div style={{ paddingLeft: "40px" }}>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <div className='companyDetailsCard'>
                          <div className='flexSpaceBetween'>
                            <p className='cardApiData'>{adminWidget.total_count}</p>
                            <img src={total} alt="" className='cardImage' />
                          </div>
                          <p className='cardTitle'>Total Employees</p>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className='companyDetailsCard'>
                          <div className='flexSpaceBetween'>
                            <p className='cardApiData'>{adminWidget.on_time}</p>
                            <img src={onTime} alt="" className='cardImage' />
                          </div>
                          <p className='cardTitle'>On Time</p>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className='companyDetailsCard'>
                          <div className='flexSpaceBetween'>
                            <p className='cardApiData'>{adminWidget.absent_count}</p>
                            <img src={absent} alt="" className='cardImage' />
                          </div>
                          <p className='cardTitle'>Absent</p>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} style={{ marginTop: "30px" }}>
                      <Col span={8}>
                        <div className='companyDetailsCard'>
                          <div className='flexSpaceBetween'>
                            <p className='cardApiData'>{adminWidget.on_late}</p>
                            <img src={lateArrival} alt="" className='cardImage' />
                          </div>
                          <p className='cardTitle'>Late Arrival</p>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className='companyDetailsCard'>
                          <div className='flexSpaceBetween'>
                            <p className='cardApiData'>{adminWidget.early_departures}</p>
                            <img src={Early} alt="" className='cardImage' />
                          </div>
                          <p className='cardTitle'>Short Duration</p>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className='companyDetailsCard'>
                          <div className='flexSpaceBetween'>
                            <p className='cardApiData'>{adminWidget.on_holiday}</p>
                            <img src={timeOff} alt="" className='cardImage' />
                          </div>
                          <p className='cardTitle'>On-Holiday</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>

            {/* Attendance Policy */}
            <div style={{ marginTop: "30px", padding: "30px", background: "white" }}>
              <div className='flexSpaceBetween'>
                <p
                  style={{ fontSize: "20px", fontWeight: "600" }}
                >Attendance Policy</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <PolicyForm /> */}
                  <Button
                    className='addNewPolicy'
                    onClick={() => navigate('/admin/attendance/dashboard/policy-form', { replace: true })}>
                    Add New Policy
                  </Button>
                  <img src={fullView} alt="fullView" width={35} height={35} style={{ marginLeft: "20px" }}
                    onClick={() => {
                      changeTabsValue("2")
                    }}
                  />

                </div>
              </div>
              <DynamicTable
                fetchData={fetchAttendancePolicy}
                columns={attendancePolicyColumns}
                handleRowClick={() => { }}
                pageSize={5}
                showPagination={false}
              />
            </div>

            {/* Attendance Report */}
            <div style={{ marginTop: "30px", padding: "30px", background: "white" }}>
              <div className='flexSpaceBetween'>
                <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                  <p
                    style={{ fontSize: "20px", fontWeight: "600" }}
                  >Attendance Report</p>
                  <span>
                    <Switch
                      checked={calendarView}
                      onChange={handleSwitchChange}
                    />
                    {" "}
                    <span style={{ paddingLeft: "10px" }}>
                      {
                        calendarView ? " Calendar View" : "Calendar View"
                      }
                    </span>
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "30px", marginBottom: "30px" }}>
                  <>
                    {
                      !calendarView
                      &&
                      <AttendanceReportSearchBar
                        setTableDataForSearchEmployee={setAttendanceReport}
                        fetchTableDataFromApi={fetchAttendanceReport}
                        placeholderText="Search for employees"
                        empType="admin"
                      />
                    }
                  </>

                  {/* <DownloadOutlined style={{ color: "#616161", fontSize: "25px" }}

                  /> */}

                  <Popover placement="bottomRight" trigger="click" title="Download report" content={attendanceReportDownloadPopover} arrow={false}>
                    <img src={download} alt="download" width={35} height={35}
                    />
                  </Popover>

                  <img src={fullView} alt="fullView" width={35} height={35} style={{ marginLeft: "20px" }}
                    onClick={() => {
                      changeTabsValue("3")
                    }}
                  />
                </div>


              </div>
              <div>
                <>
                  {
                    calendarView
                    &&
                    <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                      {/* <Input
                        prefix={<SearchOutlined />}
                        placeholder="Search for employees or "
                        style={{ width: "300px", height: "50px", borderRadius: "5px", border: "2px solid #C4C4C4" }}
                      // onChange={(e) => setSearch(e.target.value)}
                      /> */}
                      <AttendanceCalendarReportSearchBar
                        setTableDataForSearchEmployee={setCalendarData}
                        fetchTableDataFromApi={null}
                        placeholderText="Search for employees"
                        empType="admin"
                        setEmployeeId={setEmployeeId}
                      />
                      {/* <Select
                        className='dropDown'
                        placeholder="Select a person"
                        onChange={handleSelectChange}
                      >
                        {
                          employeeList?.map((item, index) => {
                            return (
                              <Select.Option key={index} value={item.employement_number}>{item.employee_name}</Select.Option>
                            )
                          }
                          )
                        }
                      </Select> */}
                    </div>
                  }
                </>

              </div>
              {
                calendarView
                  ?
                  <CalendarScheduler
                    data={calendarData}
                    setCalendarMonth={setCalendarMonth}
                    setCalendarYear={setCalendarYear}
                    employeeId={employeeId}
                    getMonthAndYearFromCalendar={getMonthAndYearFromCalendar}
                  />
                  :
                  <Table
                    columns={attendanceReportColumns}
                    dataSource={attendanceReport}
                    pagination={false}
                  />
              }

            </div>

            {/* Employee Attendance Policy Manager */}
            <div style={{ marginTop: "30px", padding: "30px", background: "white" }}>
              <div className='flexSpaceBetween'>
                <p
                  style={{ fontSize: "20px", fontWeight: "600" }}
                >Employee Attendance Policy Manager</p>
                <EmployeeAttendancePolicyManagerSearchBar
                  setTableDataForSearchEmployee={setEmployeeAttendancePolicyMangerList}
                  fetchTableDataFromApi={fetchEmployeeAttendancePolicyManger}
                  placeholderText="Search for employees"
                  empType="admin"
                />
                <img src={fullView} alt="fullView" width={35} height={35} style={{ marginLeft: "20px" }}
                  onClick={() => {
                    changeTabsValue("4")
                  }}
                />
              </div>
              <Table
                columns={employeeAttendancePolicyMangerColumns}
                dataSource={employeeAttendancePolicyMangerList}
                pagination={false}
              />
            </div>

            {/* Overtime Report */}
            <div style={{ marginTop: "30px", padding: "30px", background: "white" }}>
              <div className='flexSpaceBetween'>


                <p
                  style={{ fontSize: "20px", fontWeight: "600", margin: "0" }}
                >Overtime Report</p>

                <div style={{ display: "flex", alignItems: "center", gap: "30px", marginBottom: "30px" }}>
                  <div>
                    <DatePicker
                      size="large"
                      picker="month"
                      style={{
                        // border: "none",
                        outline: "none",
                        // background: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#000000",
                      }}
                      onChange={(e) => {
                        fetchDataWithRespectedMonth(e);
                      }}
                      // can we set default value as month name
                      // defaultValue={moment().format("MMMM")}
                      defaultValue={dayjs().startOf('month')}
                      placeholder="Month"
                      format={"MMMM YYYY"}
                    />
                  </div>
                  <img src={fullView} alt="fullView" width={35} height={35} style={{ marginLeft: "20px" }}
                    onClick={() => {
                      changeTabsValue("5")
                    }}
                  />
                </div>


              </div>

              <Table
                columns={overtimeReportColumns}
                dataSource={overTimeReportList}
                pagination={false}
              />
            </div>

            <div style={{ marginTop: "30px" }}>
              <Row gutter={[32, 32]}>
                <Col span={12}>
                  <div style={{ backgroundColor: "white", padding: "20px" }}>
                    <p style={{ color: "#007CFF", fontSize: "18px", fontWeight: "600" }}>Integaration Status</p>
                    <div>
                      <Row>
                        <Col span={20}>
                          <Table
                            columns={integarationStatusColumns}
                            dataSource={integarationStatus}
                            pagination={false}
                          />
                        </Col>
                        <Col span={4}>
                          <img src={integarationStatus} alt="integarationStatus" style={{ width: "100%" }} />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={{ backgroundColor: "white", padding: "20px" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "30px" }}>
                      <p style={{ color: "#007CFF", fontSize: "18px", fontWeight: "600" }}>Attendance Upload</p>
                      <button
                        style={{
                          background: "#008080",
                          color: "white",
                          border: "none",
                          padding: "8px 16px",
                          borderRadius: "4px",
                          cursor: "pointer",
                          marginBottom: "18px",
                        }}
                        onClick={() => {
                          setOpenModal(true)
                        }}
                      >
                        Upload
                      </button>
                    </div>
                    <div>
                      <Row>
                        <Col lg={20}>
                          <Row>
                            <Col xs={12}>
                              File Name
                            </Col>
                            <Col xs={12}>
                              Date
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col xs={12}>
                              <span style={{ color: "#535151" }}>
                                File Name
                              </span>
                            </Col>
                            <Col xs={12}>
                              <span style={{ color: "#535151" }}>
                                Date
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4}>
                          <img src={attendanceUpload} alt="attendanceUpload" style={{ width: "100%" }} />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

          </div>
      }

      <Modal
        title="Change Attendance Policy"
        centered
        open={changeAttendancePolicyModel}
        onOk={() => setChangeAttendancePolicyModel(false)}
        onCancel={() => setChangeAttendancePolicyModel(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          // initialValues={policies}
          onFinish={updateEmployeeAttendancePolicy}
          fields={[
            {
              name: "attendance_policy",
              value: selectedEmployeeAttendancePolicyManager.policy_id
            }
          ]}
        >
          <Form.Item
            label="Attendance Policy"
            name="attendance_policy"
            rules={[
              {
                required: true,
                message: 'Please Select attendance_policytype!',
              },
            ]}
          >
            <Select
              size='large'
            >
              {
                attendancePolicy.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                  )
                }
                )
              }
            </Select>

          </Form.Item>
          <Button htmlType="submit" style={{ background: "#008080", color: "white" }}>Update</Button>
        </Form>
      </Modal>

      <Modal
        title="Download Report"
        centered
        open={downloadModal}
        // onOk={() => setReviseSalary(false)}
        onCancel={() => setDownloadModal(false)}
        width={800}
        footer={null}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
          <Select
            style={{ width: "100%", marginTop: "20px" }}
            placeholder="Select an option"
            onChange={handleDaysChange}
            defaultValue={attendanceReportDays}
            size='large'
          >
            <Select.Option value={7}>Last 7 days</Select.Option>
            <Select.Option value={14}>Last 14 days</Select.Option>
            <Select.Option value={30}>Last 30 days</Select.Option>
            <Select.Option value="custom">
              <span
                onClick={() => {
                  setOpen(true);
                }}
              >
                Custom Date
              </span>
            </Select.Option>
          </Select>

          <Modal
            title="Select Date Range"
            centered
            open={open}
            onCancel={() => setOpen(false)}
            onOk={async () => {

              setOpen(false);
            }}
          >
            <RangePicker
              value={dateRange}
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              style={{ color: "black !important" }}
            />
          </Modal>


        </div>

        <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }}
          onClick={() => downloadReport()}
        >
          Download
        </Button>

      </Modal>
      <Modal
        open={openModal}
        onOk={() => { }}
        onCancel={() => {
          setOpenModal(false)
          form.resetFields();
        }}
        footer={null}
        centered
        width={540}
      >
        <p className='leavePolicyModelHeading'>Upload Document</p>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        // onValuesChange={onValuesChange}
        >

          <Row gutter={[8, 8]}>
            <Col span={24}>

              <Form.Item
                label="Organisation"
                name="org"
                rules={[
                  {
                    required: true,
                    message: "Please Select Organisation!",
                  },
                ]}
              >
                <Select size="large">
                  {organisationList?.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.id}>
                        {item.org_name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <p>Upload File</p>
              <Form.Item
                label="Upload File"
                name="end_time"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Date (To)!',
                  },
                ]}
              >
                <div className="file-upload-container">
                  <input
                    type="file"
                    id="file-upload"
                    className="input-file"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      const maxSizeInBytes = 5 * 1024 * 1024;

                      if (selectedFile && selectedFile.type === 'text/csv') {
                        if (selectedFile.size <= maxSizeInBytes) {
                          setFile(e.target.files && selectedFile)
                        }
                        else {
                          setFile(null);
                          alert('File size exceeds the limit of 5MB.');
                        }
                      } else {
                        // Reset the file state and show an error message
                        setFile(null);
                        alert('Please upload a CSV file.');
                      }
                    }}
                  />
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                    <label htmlFor="file-upload" className="file-upload-label">

                    </label>
                    {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                    <p style={{ zIndex: 1, margin: "0px" }}>{File ? File.name : 'Drop Your File Here'}</p>
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col span={10}>
              <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                <a href={downloadLink} download style={{ color: "#008080", textDecoration: "none" }}>
                  <Button
                    htmlType='button'
                    style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                  >
                    Download Sample CSV
                  </Button>

                </a>

                <Button
                  htmlType='submit'
                  style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                >
                  Submit
                </Button>

              </div>
            </Col>
          </Row>

        </Form>
      </Modal>

      <Modal
        centered
        open={shiftModel}
        onOk={() => setShiftModel(false)}
        onCancel={() => {
          setShiftModel(false)
          setSpecificPolicyDetails({})
          setSetPolicyId("")
        }}
        footer={null}
      >
        {
          specificPolicyDetails?.is_standard_shift
            ?
            <div>
              <p style={{ fontSize: "18px", color: "#161616" }}>Shifts</p>
              <p style={{ fontSize: "16px", color: "#161616", margin: "0px", marginBottom: "5px" }}>All Weeks</p>
              {
                specificPolicyDetails?.shifts[0]?.timings?.map((item, index) => {
                  return (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <p style={{ color: "#8A8A8A", fontSize: "14px", margin: "0px", marginBottom: "5px" }}>Shift {index + 1}</p>
                      <p style={{ color: "#333333", fontSize: "14px", margin: "0px", marginBottom: "5px" }}>
                        {item.start_time} ---- {item.end_time}
                      </p>
                    </div>
                  )
                })
              }
            </div>
            :
            <div>
              <p style={{ fontSize: "18px", color: "#161616" }}>Shifts</p>
              {
                specificPolicyDetails?.shifts?.map((weekItem, index) => {
                  return (
                    <>
                      <p style={{ fontSize: "16px", color: "#161616", margin: "0px", marginBottom: "5px" }}>Week {index + 1}</p>
                      {
                        weekItem?.timings?.map((timingItem, index) => {
                          return (
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <p style={{ color: "#8A8A8A", fontSize: "14px", margin: "0px", marginBottom: "5px" }}>Shift {index + 1}</p>
                              <p style={{ color: "#333333", fontSize: "14px", margin: "0px", marginBottom: "5px" }}>
                                {timingItem?.start_time} ---- {timingItem?.end_time}
                              </p>
                            </div>
                          )
                        })
                      }
                    </>
                  )
                })
              }
            </div>
        }
      </Modal>

      <Modal
        centered
        open={customeViewModal}
        onOk={() => setCustomeViewModal(false)}
        onCancel={() => setCustomeViewModal(false)}
        footer={null}
      >
        {specificPolicyDetails?.week_off_entries?.map((weekDetail, weekIndex) => (
          <div key={weekIndex} >

            <div className='renderCustomWeeklyOffContainer'>

              <div className='selectNoOfDaysContainer'>
                <div className='selectNoOfDaysInnerContainer'>
                  <p className='weekNumberText'>Week {weekIndex + 1}</p>
                </div>
              </div>
              <div className='selectWeeksContainer'>
                <div style={{ display: "flex", gap: 20 }}>
                  {days.map((day, index) => {
                    const isDaySelected = weekDetail.week_off[index] === 1 || weekDetail.week_off[index] === 0.5;
                    return (
                      <div
                        key={index}
                        className={isDaySelected ? weekDetail.week_off[index] === 0.5 ? "selectedHalfDay" : "selectedFullDay" : "disabledFullDay"}
                      >
                        {day.slice(0, 2)}
                      </div>
                    )
                  })}
                </div>
              </div>

            </div>
          </div>
        ))}
      </Modal>

      <Modal
        centered
        open={standardViewModal}
        onOk={() => setStandardViewModal(false)}
        onCancel={() => setStandardViewModal(false)}
        footer={null}
      >
        <div className='renderStandardWeeklyOffOuterDiv'>
          <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>Week Off</p>
          <p>All Weeks</p>
          <div>
            {
              specificPolicyDetails?.week_off_entries?.slice(0, 1).map((weekDetail, dayIndex) => {
                return (
                  <div className='renderStandardWeeklyOffContainer' key={dayIndex}>
                    <div className='renderStandardWeeklyDaysContainer'>
                      {
                        days.map((day, index) => {
                          const selected = (weekDetail.week_off[index] === 1 || weekDetail.week_off[index] === 0.5);

                          return (
                            <div>
                              <div
                                className={selected ? weekDetail.week_off[index] === 0.5 ? "selectedHalfDay" : "selectedFullDay" : "disabledFullDay"}
                              >
                                {day.slice(0, 2)}
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Modal>

      {/* OverTime Report Modal */}
      <Modal
        centered
        open={overtimeInfoModal}
        onOk={() => setOvertimeInfoModal(false)}
        onCancel={() => setOvertimeInfoModal(false)}
        footer={null}
      >
        <p style={{ fontSize: "20px", fontWeight: "500", color: "#161616" }}>Overtime hour</p>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "20px", marginTop: "20px" }}>
          <p style={{ fontSize: "16px", fontWeight: "600", color: "#008080" }}>S.no</p>
          <p style={{ fontSize: "16px", fontWeight: "600", color: "#008080" }}>Date</p>
          <p style={{ fontSize: "16px", fontWeight: "600", color: "#008080" }}>Overtime</p>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "20px", marginTop: "20px", height: "400px", overflowY: "auto" }}>
          {
            selectedOverTimeData?.overtime_details?.map((item, index) => {
              return (
                <>
                  <p style={{ fontSize: "16px", fontWeight: "400", color: "#8A8A8A" }}>{index + 1}</p>
                  <p style={{ fontSize: "16px", fontWeight: "400", color: "#8A8A8A" }}>{item.date}</p>
                  <p style={{ fontSize: "16px", fontWeight: "400", color: "#8A8A8A" }}>{item.duration}</p>
                </>
              )
            })
          }
        </div>

      </Modal>

      <Modal
        centered
        open={overtimeFormModal}
        onOk={() => setOvertimeFormModal(false)}
        onCancel={() => setOvertimeFormModal(false)}
        footer={null}

      >
        <p style={{ fontSize: "20px", fontWeight: "500", color: "#161616" }}>Compensation off</p>
        <Form
          onFinish={onFinishOvertime}
          layout="vertical"
          fields={[
            {
              name: "eligible",
              value: eligibleDays,
            }
          ]}
          form={overtimeForm}
        >
          <p style={{ fontSize: "20px", fontWeight: "500" }}>Eligible days : {eligibleDays}</p>
          {
            eligibleDays !== 0
            &&
            <>
              <Form.Item
                label="Leave type"
                name="leave_type"
                rules={[
                  {
                    required: true,
                    message: "Please select leave type",
                  },
                ]}
              >
                <Select
                  size='large'
                  style={{ width: "100%" }}
                // onChange={(value) => setSelectedDate(value)}
                >
                  {
                    leaveList?.map((item, index) => {
                      return (
                        <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>

              <Form.Item
                label="Select number of days"
                name="leaves_number"
                rules={[
                  {
                    required: true,
                    message: "Please select number of days",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  size='large'
                // onChange={(value) => setSelectedDate(value)}
                >
                  {
                    numberOfDays?.map((date, index) => {
                      return (
                        <Select.Option value={date} key={index}>{date}</Select.Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>

              <Button
                style={{ color: "white", background: "#008080" }}
                htmlType="submit"
              >
                Apply
              </Button>
            </>
          }

        </Form>

      </Modal>

            {/* regularization request */}
            <Modal
                centered
                open={openRegularizationModal}
                footer={null}
                onCancel={() => {
                    setOpenRegularizationModal(false);
                    setSelectedEmployeeId(null);
                    setSelectedShift(null);
                    regularizationForm.resetFields();
                }}

            >
                <p style={{ fontSize: "20px", lineHeight: "30px", fontWeight: "500" }}>Regularisation Request</p>
                <Form
                    form={regularizationForm}
                    onFinish={regularizeRequest}
                    layout="vertical"
                // fields={[
                //   {
                //     name: ['start_date'],
                //     value: GetJoiningDateForEmployee ? dayjs(GetJoiningDateForEmployee) : null
                //   }
                // ]}
                >
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Check In"
                                name="check_in_time"
                                rules={[
                                    { required: true, message: "Required" },
                                ]}
                            >
                                <TimePicker
                                    hourStep={1}
                                    minuteStep={15}
                                    format="HH:mm"
                                    // placeholder="-ve Earliest Time Allowed"
                                    size='large'
                                    style={{ width: "100%" }}
                                    onChange={(time, timeString) => {
                                        console.log(time, "time.....................");
                                        // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                                        // const totalMinutes = hours * 60 + minutes;
                                    }}
                                />
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Check Out"
                                name="check_out_time"
                            >
                                <TimePicker
                                    hourStep={1}
                                    minuteStep={15}
                                    format="HH:mm"
                                    // placeholder="-ve Earliest Time Allowed"
                                    size='large'
                                    style={{ width: "100%" }}
                                    onChange={(time, timeString) => {
                                        console.log(time, "time.....................22222222222222");
                                        // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                                        // const totalMinutes = hours * 60 + minutes;
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: "Please add Reason!",
                                },
                            ]}
                        >
                            <TextArea
                                rows={5}
                                placeholder="Enter Your Reason"
                                className="leaveTextArea"
                                onChange={(e) =>
                                    "reason"
                                    // handleFormChange("reason", e.target.value)
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Button
                        htmlType="submit"
                        className="forgotPasswordButton"
                    >
                       Update
                    </Button>
                </Form>
            </Modal>

    </div >
  )
}

export default AttendanceDashBoardView