import { Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react'

import getScreenSize from '../../../../utils/getScreenSize';

import RequisitionFullView from '../Requisition/Requisition Fullview';
import RecruiterFullView from '../ExternalRecruiters/RecruiterFullView';
import AdminRecruitmentDashboardView from '../DashBoard/DashboardView';
import AdminJobListFullView from '../Jobs/Jobs';

function AdminJobList() {
    const screenSize = getScreenSize();
    const ref = useRef();

    const changeTabsValue = (e) => {
        setTabsValue(e)
    }

    const items = [
        {
            key: '1',
            label: 'Dashboard',
            children: <AdminRecruitmentDashboardView changeTabsValue={changeTabsValue} />,
        },
        {
            key: '2',
            label: 'Jobs',
            children: <AdminJobListFullView />,
        },
        {
            key: '3',
            label: 'Requisitions',
            children: <RequisitionFullView />
        },
        {
            key: '4',
            label: 'External Recruiters',
            children: <RecruiterFullView />,
        }

    ];

    const [tabsValue, setTabsValue] = useState("1")

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);


    return (
        <div ref={ref}>
            {
                screenSize.width < 768
                    ?
                    // mobile view
                    <Tabs
                        activeKey={tabsValue}
                        items={items}
                        onChange={changeTabsValue}
                    />
                    :
                    // desktop view
                    <Tabs
                        activeKey={tabsValue}
                        items={items}
                        onChange={changeTabsValue}
                    />
            }


        </div>
    )
}

export default AdminJobList;