import React from 'react'
import { useNavigate } from 'react-router-dom';
import back from "../../../../assets/Recruitment/back.svg"
import { Button, Col, Row } from 'antd';
import fullView from "../../../../assets/fullView.svg"
import edit from "../../../../assets/edit.svg"
import "./LeaveTypeFullView.scss"
function LeaveTypeFullView() {
    const navigate = useNavigate();

    return (
        <div className='leaveTypeFullViewContainer'>
            <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                <img src={back} alt="back" />
                <span style={{ paddingLeft: "10px" }}>Back</span>
            </p>
            <p className='heading'>Leave</p>
            <div className='leaveTypeContainer'>
                <div className='flexSpaceBetween'>
                    <p className='leaveTypeHeading'>Leave Type</p>
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                        <Button className='addNewTypeButton'>Add New Type</Button>
                    </div>
                </div>
                <div style={{ border: "1px solid #B7B7B7", padding: "25px 30px", borderRadius: "5px" }}>
                    <center>
                        <Row style={{ display: "flex", alignItems: "center" }}>
                            <Col span={5}>
                                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                    <img src={fullView} alt="" width={35} height={35} />
                                    <span className='leaveTypeTitle'>Sick Leave</span>
                                </div>
                            </Col>
                            <Col span={18}>
                                <div style={{ display: "flex", alignItems: "center", }}>
                                    <span className='leaveTypeDescriptionText'>Available only when you haven’t  taken any causal leave</span>

                                </div>
                            </Col>
                            <Col span={1}>
                                <img src={edit} alt="edit" width={15} height={15} />
                            </Col>
                        </Row>
                    </center>
                </div>
            </div>
        </div>
    )
}

export default LeaveTypeFullView