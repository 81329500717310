import { Button, Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import request from '../../../../../utils/axios'

import getScreenSize from '../../../../../utils/getScreenSize';

function PaySchedule() {
    const screenSize = getScreenSize();
    const [payrollSetting, setPayrollSetting] = useState(
        {
            start_time: "",
            end_time: "",
        }
    )
    const fetchPayrollSettingData = async () => {
        try {
            let data = await request({
                method: "get",
                url: "payroll/admin/get-payroll-salary-setting",
            })
            setPayrollSetting(data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchPayrollSettingData()
    }, [])

    const createSetting = async (values) => {
        try {
            let data = await request({
                method: "post",
                url: "payroll/admin/create-payroll-salary-setting",
                data: {
                    end_time: values.end_time,
                    start_time: values.start_time,
                },

            })
            fetchPayrollSettingData()
        }
        catch (err) {
            console.log(err)
        }
    }

    let numberOfDaysInMonth = [];

    for (let i = 1; i <= 31; i++) {
        numberOfDaysInMonth.push({ key: i.toString(), value: i.toString() });
    }

    return (
        <div>
            {
                screenSize.width < 768
                    ?
                    <div style={{ padding: "0px 16px" }}>
                        <p style={{ fontSize: "16px", fontWeight: "600", color: "#252525" }}>Payroll date</p>
                        <Form
                            layout="vertical"
                            onFinish={createSetting}
                            fields={[

                                {
                                    name: ["end_time"],
                                    value: payrollSetting?.payroll_start_date
                                },
                                {
                                    name: ["start_time"],
                                    value: payrollSetting?.payroll_end_date
                                }
                            ]}
                        >
                            <Row gutter={[32, 32]}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Choose date of last month"
                                        name="end_time"
                                    >
                                        <Select
                                            size='large'
                                        >
                                            {
                                                numberOfDaysInMonth?.map((item, index) => {
                                                    return <Select.Option key={index} value={item.value}>
                                                        {item.key}
                                                    </Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Choose date of new month"
                                        name="start_time"
                                    >
                                        <Select
                                            size='large'
                                        >
                                            {
                                                numberOfDaysInMonth?.map((item, index) => {
                                                    return <Select.Option key={index} value={item.value}>
                                                        {item.key}
                                                    </Select.Option>
                                                })
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Button
                                htmlType='submit'
                                style={{ width: "100px", color: "white", background: "#008080", height: "40px" }}
                            >Save</Button>
                        </Form>
                    </div>
                    :
                    <div>
                        <p style={{ fontSize: "16px", fontWeight: "600", color: "#252525" }}>Payroll date</p>
                        <Form
                            layout="vertical"
                            onFinish={createSetting}
                            fields={[

                                {
                                    name: ["end_time"],
                                    value: payrollSetting?.payroll_start_date
                                },
                                {
                                    name: ["start_time"],
                                    value: payrollSetting?.payroll_end_date
                                }
                            ]}
                        >
                            <Row gutter={[32, 32]}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Choose date of last month"
                                        name="end_time"
                                    >
                                        <Select
                                            size='large'
                                        >
                                            {
                                                numberOfDaysInMonth?.map((item, index) => {
                                                    return <Select.Option key={index} value={item.value}>
                                                        {item.key}
                                                    </Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Choose date of new month"
                                        name="start_time"
                                    >
                                        <Select
                                            size='large'
                                        >
                                            {
                                                numberOfDaysInMonth?.map((item, index) => {
                                                    return <Select.Option key={index} value={item.value}>
                                                        {item.key}
                                                    </Select.Option>
                                                })
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Button
                                htmlType='submit'
                                style={{ width: "100px", color: "white", background: "#008080", height: "40px" }}
                            >Save</Button>
                        </Form>
                    </div>
            }

        </div>
    )
}

export default PaySchedule