import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import request from '../../../utils/axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import './AddOrEditOrg.scss'
function AddOrEditOrgMobile({ btn, type, detail, reload, }) {
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    const params = useParams();
    const location = useLocation();
    const isParentOrg = location.pathname.split("/").includes("parent");
    const navigate = useNavigate();

    const onFinish = async (values) => {

        if (params.id) {
            await request({
                method: "put",
                url: `org/${params.id}/update/`,
                data: values
            })
        }
        else {
            const parent_company_id = localStorage.getItem("parent_company_id")
            await request({
                method: "post",
                url: `child-organization/create`,
                data: {
                    ...values,
                    parent_name: parent_company_id
                }
            })
        }

        setOpen(false)
        navigate("/admin/org", { replace: true })
    }

    const [orgDetails, setOrgDetails] = useState(null)
    const fetchParentData = async (page, page_size) => {
        const data = await request({
            method: "get",
            url: `parent-org/all?page=${page}&page_size=${page_size}`,
        })
        setOrgDetails(data.pagination_data.data[0])
    }

    const fetchChildData = async (page, page_size) => {
        const parent_company_id = localStorage.getItem("parent_company_id")
        const data = await request({
            method: "get",
            url: `child-org/detail?org_id=${params.id}`
        })
        setOrgDetails(data.data.data)
    }

    useEffect(() => {
        if (params.id) {
            if (isParentOrg) {
                fetchParentData(1, 1);
            }
            else {
                fetchChildData(1, 20);
            }
        }
    }, [])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div style={{ padding: "16px" }} ref={ref} >

            <p style={{ fontSize: "24px", fontWeight: "500" }}>
                {isParentOrg ? "Parent Organisation" : "Child Organisation"}
            </p>

            <Form
                layout="vertical"
                onFinish={onFinish}
                fields={[
                    {
                        name: ["org_name"],
                        value: orgDetails?.org_name
                    },
                    {
                        name: ["domain_name"],
                        value: orgDetails?.domain_name
                    },
                    {
                        name: ["address"],
                        value: orgDetails?.address
                    },
                    {
                        name: ["gst_details"],
                        value: orgDetails?.gst_details
                    },
                    {
                        name: ["fixed_emp_code"],
                        value: orgDetails?.fixed_emp_code
                    },
                    {
                        name: ["variable_emp_code"],
                        value: orgDetails?.variable_emp_code
                    }
                ]}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="Company Name"
                            name="org_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Company Name!',
                                },
                            ]}
                        >
                            <Input
                                // className='inputField'
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="Domain Name"
                            name="domain_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Domain Name!',
                                },
                            ]}
                        >
                            <Input
                                // className='inputField'
                                size='large'
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Address!',
                                }
                            ]}
                        >
                            <Input
                                // className='inputField'
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="GST Details"
                            name="gst_details"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter GST Details!',
                                }
                            ]}
                        >
                            <Input
                                // className='inputField'
                                size='large'
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <p style={{ fontWeight: "600" }}>Employee Code Sequence</p>
                <Row gutter={[32, 32]}>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Form.Item
                                label="Fixed"
                                name="fixed_emp_code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Fixed!',
                                    }
                                ]}
                            >
                                <Input
                                    // className='inputField'
                                    size='large'
                                    style={{ width: "90%" }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Variable"
                                name="variable_emp_code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Variable!',
                                    }
                                ]}
                            >
                                <Input
                                    // className='inputField'
                                    size='large'
                                    style={{ width: "100%" }}
                                    disabled={type === "Update"}
                                />
                            </Form.Item>
                        </div>
                    </Col>


                </Row>
                <Button
                    style={{ width: "100%", height: "36px", background: "#008080", color: "white", fontSize: "16px", marginTop: "20px" }}
                    htmlType='submit'
                >
                    {params.id ? "Update" : "Submit"}
                </Button>
            </Form>

        </div>
    );
}

export default AddOrEditOrgMobile

