import React, { useEffect, useState } from 'react'
import styles from './SalaryConfigurationForm.module.scss'
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select } from 'antd'
import add from "../../../../assets/Org/add.svg"
import request from '../../../../utils/axios'
import update from 'immutability-helper'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import getScreenSize from "../../../../utils/getScreenSize"
import mobileStyle from "./MobileSalaryConfigurationForm.module.scss"
import back from "../../../../assets/Recruitment/back.svg"

function SalaryConfigurationForm() {
  const params = useParams();
  const navigate = useNavigate();
  const screenSize = getScreenSize();
  const [form] = Form.useForm();
  const [flexiForm] = Form.useForm();
  const [retiralsForm] = Form.useForm();
  const [variableForm] = Form.useForm();
  const [openModel, setOpenModel] = useState(false)
  const [salaryModel, setSalaryModel] = useState({
    fixed: false,
    flexi: false,
    retirals: false,
    variable: false
  })

  const [salaryStructureData, setSalaryStructureData] = useState({
    name: "",
    fixed: [],
    flexi: [],
    retirals: [],
    variable: []
  })

  const [fixedComponentData, setfixedComponentData] = useState([])
  const [flexiComponentData, setflexiComponentData] = useState([])
  const [retiralsComponentData, setretiralsComponentData] = useState([])
  const [variableComponentData, setvariableComponentData] = useState([])

  const [percentageChoiseList, setPercentageChoiseList] = useState([])

  const [selectedFixedOptions, setSelectedFixedOptions] = useState([])
  const [selectedFlexiOptions, setSelectedFlexiOptions] = useState([])
  const [selectedRetiralsOptions, setSelectedRetiralsOptions] = useState([])
  const [selectedVariableOptions, setSelectedVariableOptions] = useState([])


  const fixedComponents = async (value) => {
    try {
      const data = await request({
        method: "post",
        url: `/payroll/admin/fetch-fixed-component`,
        data: value
      })
      setfixedComponentData(data.data)
    }
    catch (error) {
      console.log("error", error);
    }
  }
  const flexiComponents = async (value) => {
    try {
      const data = await request({
        method: "post",
        url: `/payroll/admin/fetch-flexi-component`,
        data: value
      })
      setflexiComponentData([...data.data])
    }
    catch (error) {
      console.log("error", error);
    }
  }

  const retiralsComponents = async (value) => {
    try {
      const data = await request({
        method: "post",
        url: `/payroll/admin/fetch-retiral-Component`,
        data: value
      })

      setretiralsComponentData([...data.data])
    }
    catch (error) {
      console.log("error", error);
    }
  }

  const variableComponents = async (value) => {
    try {
      const data = await request({
        method: "post",
        url: `/payroll/admin/fetch-variable-Component`,
        data: value
      })

      setvariableComponentData([...data.data])
    }
    catch (error) {
      console.log("error", error);
    }
  }

  const fetchPercentageChoiceList = async () => {
    try {
      const data = await request({
        method: "get",
        url: `/payroll/percent_type_choices`,
      })
      setPercentageChoiseList(data.data)
    }
    catch (error) {
      console.log("error", error);
    }
  }

  const onFinish = async (values) => {
    try {
      if (params.id) {
        const data = await request({
          method: "Post",
          url: `payroll/admin/update-salary-structure?id=${params.id}`,
          data: {
            ...values,
            ...salaryStructureData
          }
        })
        toast.success("Salary Structure edited successfully", {
          position: "bottom-left",
        });
      }
      else {
        const data = await request({
          method: "Post",
          url: `payroll/admin/create-salary-structure`,
          data: {
            ...values,
            ...salaryStructureData
          }
        })
        toast.success("New Salary Structure created successfully", {
          position: "bottom-left",
        })
      }
      navigate("/admin/payroll/3", { replace: true });

    }
    catch (error) {
      if (params.id) {
        toast.error("Salary could not be updated, please try after some time", {
          position: "bottom-left",
        });
      } else {
        toast.error("Salary Structure could not be created, please try after some time", {
          position: "bottom-left",
        })
      }
    }
  }


  const onValuesFixedChange = (changedValues, allValues) => {
    if (changedValues?.fixedComponents) {
      if (changedValues?.fixedComponents.includes("all")) {
        form.setFieldsValue({
          fixedComponents: fixedComponentData.map((item) => {
            return item.value
          })

        });
        setSelectedFixedOptions(fixedComponentData.map((item) => {
          return item.value
        }))
      }
      else {
        form.setFieldsValue({
          fixedComponents: changedValues?.fixedComponents
        });

        setSelectedFixedOptions([...changedValues?.fixedComponents])
      }
    }
  }
  const onValuesFlexiChange = (changedValues, allValues) => {
    if (changedValues?.flexiComponents) {
      if (changedValues?.flexiComponents.includes("all")) {
        form.setFieldsValue({
          flexiComponents: flexiComponentData.map((item) => {
            return item.value
          })

        });
        setSelectedFlexiOptions(flexiComponentData.map((item) => {
          return item.value
        }))
      }
      else {
        form.setFieldsValue({
          flexiComponents: changedValues?.flexiComponents
        });

        setSelectedFlexiOptions([...changedValues?.flexiComponents])
      }
    }
  }
  const onValuesRetiralsChange = (changedValues, allValues) => {
    if (changedValues?.retiralsComponents) {
      if (changedValues?.retiralsComponents.includes("all")) {
        form.setFieldsValue({
          retiralsComponents: retiralsComponentData.map((item) => {
            return item.value
          })

        });
        setSelectedRetiralsOptions(retiralsComponentData.map((item) => {
          return item.value
        }))
      }
      else {
        form.setFieldsValue({
          retiralsComponents: changedValues?.retiralsComponents
        });

        setSelectedRetiralsOptions([...changedValues?.retiralsComponents])
      }
    }
  }
  const onValuesVariableChange = (changedValues, allValues) => {
    if (changedValues?.variableComponents) {
      if (changedValues?.variableComponents.includes("all")) {
        form.setFieldsValue({
          variableComponents: variableComponentData.map((item) => {
            return item.value
          })

        });
        setSelectedVariableOptions(variableComponentData.map((item) => {
          return item.value
        }))
      }
      else {
        form.setFieldsValue({
          variableComponents: changedValues?.variableComponents
        });

        setSelectedVariableOptions([...changedValues?.variableComponents])
      }
    }
  }

  const [salarySelectedFixedOptions, setSalarySelectedFixedOptions] = useState([]);

  const onFinishFixed = () => {
    try {
      // Arrays to store selected and not selected options
      let newSelectedFixedOptions = []; // Array to store newly selected options
      let notSelectedFixedOptions = []; // Array to store options that were not selected

      // Removing unselected options from the salarySelectedFixedOptions
      salarySelectedFixedOptions.forEach(element => {
        if (!selectedFixedOptions.includes(element)) { // If an element is not in the selected options
          notSelectedFixedOptions.push(element); // Add it to the not selected options array
          // Removing unselected option from salarySelectedFixedOptions state
          setSalarySelectedFixedOptions(prevArr => { // Updating state using previous state
            const indexToRemove = prevArr.indexOf(element); // Find index of element to remove
            if (indexToRemove !== -1) { // If element is found
              const newArr = [...prevArr]; // Create a new array with previous state
              newArr.splice(indexToRemove, 1); // Remove element
              return newArr; // Return new array
            }
            return prevArr; // Return previous state if element not found
          });
        }
      });

      let updatedData = salaryStructureData; // Copying salaryStructureData to a new variable

      // Removing not selected options from salaryStructureData
      if (notSelectedFixedOptions.length > 0) { // If there are not selected options
        notSelectedFixedOptions.forEach(typeId => { // Iterate through not selected options
          const index = updatedData.fixed.findIndex(item => item.typeId === typeId); // Find index of option to remove
          if (index !== -1) { // If option found
            updatedData = update(updatedData, { // Update data immutably
              fixed: {
                $splice: [[index, 1]], // Splice out the option from fixed array
              },
            });
          }
        });
      }

      // Adding newly selected options to the salaryStructureData
      if (salarySelectedFixedOptions.length) { // If there are newly selected options
        selectedFixedOptions.forEach(item => { // Iterate through selected options
          if (!salarySelectedFixedOptions.includes(item)) { // If an option is newly selected
            newSelectedFixedOptions.push(item); // Add it to newSelectedFixedOptions
            // Adding newly selected option to salarySelectedFixedOptions state
            setSalarySelectedFixedOptions(prevState => [...prevState, item]); // Update state with new option
          }
        });

        // Mapping newly selected options to their respective data format
        const data = newSelectedFixedOptions.map((item, index) => { // Map through newSelectedFixedOptions
          const selectedData = fixedComponentData.find((el) => el.value === item); // Find data for selected option
          return {
            type: selectedData.name,
            typeId: selectedData.value,
            amount: "",
            componentConfigChoice: null,
            percentageType: null,
            compensation: false
          }; // Return formatted data
        });

        // Pushing new data to the salaryStructureData state
        const updatedData = update(salaryStructureData, { // Update data immutably
          fixed: {
            $push: [...data] // Push new data to fixed array
          }
        });

        // Updating the salaryStructureData state
        setSalaryStructureData(updatedData); // Update state with new data
      }

      if (notSelectedFixedOptions.length > 0) { // If there were not selected options
        // Updating the salaryStructureData state
        setSalaryStructureData(updatedData); // Update state with modified data
      }

      if (salaryStructureData.fixed.length === 0) { // If there are no fixed options
        setSalarySelectedFixedOptions([...salarySelectedFixedOptions, ...selectedFixedOptions]) // Add selectedFixedOptions to salarySelectedFixedOptions
        setSalaryStructureData({ // Update salaryStructureData state
          ...salaryStructureData,
          fixed: [
            ...selectedFixedOptions.map((item, index) => { // Map through selectedFixedOptions
              const selectedData = fixedComponentData.find((el) => { // Find data for selected option
                if (el.value === item) { // If option found
                  return el.name // Return name
                }
              })

              return {
                type: selectedData.name,
                typeId: selectedData.value,
                amount: "",
                componentConfigChoice: null,
                percentageType: null,
                compensation: false
              }

            })
          ]
        })
      }

      // Setting the salaryModel state
      setSalaryModel({
        ...salaryModel,
        fixed: false // Update salaryModel state
      });

      // Closing the model
      setOpenModel(false); // Close model
    } catch (error) { // Catch any errors
      console.log("error", error); // Log errors
    }
  };


  const [salarySelectedFlexiOptions, setSalarySelectedFlexiOptions] = useState([]);

  const onFinishFlexi = () => { // Renamed function to reflect handling of flexi options
    try {
      let newSelectedFlexiOptions = []; // Array to store newly selected flexi options
      let notSelectedFlexiOptions = []; // Array to store options that were not selected
      console.log("salarySelectedFlexiOptions", salarySelectedFlexiOptions); // Logging selected flexi options

      // Removing unselected options from the salarySelectedFlexiOptions
      salarySelectedFlexiOptions.forEach(element => {
        if (!selectedFlexiOptions.includes(element)) { // If an element is not in the selected options
          notSelectedFlexiOptions.push(element); // Add it to the not selected options array
          // Removing unselected option from salarySelectedFlexiOptions state
          setSalarySelectedFlexiOptions(prevArr => { // Updating state using previous state
            const indexToRemove = prevArr.indexOf(element); // Find index of element to remove
            if (indexToRemove !== -1) { // If element is found
              const newArr = [...prevArr]; // Create a new array with previous state
              newArr.splice(indexToRemove, 1); // Remove element
              return newArr; // Return new array
            }
            return prevArr; // Return previous state if element not found
          });
        }
      });

      let updatedData = salaryStructureData; // Copying salaryStructureData to a new variable

      // Removing not selected options from salaryStructureData
      if (notSelectedFlexiOptions.length > 0) { // If there are not selected options
        notSelectedFlexiOptions.forEach(typeId => { // Iterate through not selected options
          const index = updatedData.flexi.findIndex(item => item.typeId === typeId); // Find index of option to remove
          if (index !== -1) { // If option found
            updatedData = update(updatedData, { // Update data immutably
              flexi: {
                $splice: [[index, 1]], // Splice out the option from flexi array
              },
            });
          }
        });
      }

      // Adding newly selected options to the salaryStructureData
      if (salarySelectedFlexiOptions.length) { // If there are newly selected options
        selectedFlexiOptions.forEach(item => { // Iterate through selected options
          if (!salarySelectedFlexiOptions.includes(item)) { // If an option is newly selected
            newSelectedFlexiOptions.push(item); // Add it to newSelectedFlexiOptions
            // Adding newly selected option to salarySelectedFlexiOptions state
            setSalarySelectedFlexiOptions(prevState => [...prevState, item]); // Update state with new option
          }
        });

        // Mapping newly selected options to their respective data format
        const data = newSelectedFlexiOptions.map((item, index) => { // Map through newSelectedFlexiOptions
          const selectedData = flexiComponentData.find((el) => el.value === item); // Find data for selected option
          return {
            type: selectedData.name,
            typeId: selectedData.value,
            amount: "",
            componentConfigChoice: null,
            percentageType: null,
            compensation: false
          }; // Return formatted data
        });

        // Pushing new data to the salaryStructureData state
        const updatedData = update(salaryStructureData, { // Update data immutably
          flexi: {
            $push: [...data] // Push new data to flexi array
          }
        });

        // Updating the salaryStructureData state
        setSalaryStructureData(updatedData); // Update state with new data
      }

      if (notSelectedFlexiOptions.length > 0) { // If there were not selected options
        // Updating the salaryStructureData state
        setSalaryStructureData(updatedData); // Update state with modified data
      }

      if (salaryStructureData.flexi.length === 0) { // If there are no flexi options
        setSalarySelectedFlexiOptions([...salarySelectedFlexiOptions, ...selectedFlexiOptions]) // Add selectedFlexiOptions to salarySelectedFlexiOptions
        setSalaryStructureData({ // Update salaryStructureData state
          ...salaryStructureData,
          flexi: [
            ...selectedFlexiOptions.map((item, index) => { // Map through selectedFlexiOptions
              const selectedData = flexiComponentData.find((el) => { // Find data for selected option
                if (el.value === item) { // If option found
                  return el.name // Return name
                }
              })

              return {
                type: selectedData.name,
                typeId: selectedData.value,
                amount: "",
                componentConfigChoice: null,
                percentageType: null,
                compensation: false
              }

            })
          ]
        })
      }

      // Setting the salaryModel state
      setSalaryModel({
        ...salaryModel,
        flexi: false // Update salaryModel state
      });

      // Closing the model
      setOpenModel(false); // Close model
    } catch (error) { // Catch any errors
      console.log("error", error); // Log errors
    }
  };

  const [salarySelectedRetiralsOptions, setSalarySelectedRetiralsOptions] = useState([]);

  const onFinishRetirals = () => { // Renamed function to reflect handling of retirals options
    try {
      let newSelectedRetiralsOptions = []; // Array to store newly selected retirals options
      let notSelectedRetiralsOptions = []; // Array to store options that were not selected
      console.log("salarySelectedRetiralsOptions", salarySelectedRetiralsOptions); // Logging selected retirals options

      // Removing unselected options from the salarySelectedRetiralsOptions
      salarySelectedRetiralsOptions.forEach(element => {
        if (!selectedRetiralsOptions.includes(element)) { // If an element is not in the selected options
          notSelectedRetiralsOptions.push(element); // Add it to the not selected options array
          // Removing unselected option from salarySelectedRetiralsOptions state
          setSalarySelectedRetiralsOptions(prevArr => { // Updating state using previous state
            const indexToRemove = prevArr.indexOf(element); // Find index of element to remove
            if (indexToRemove !== -1) { // If element is found
              const newArr = [...prevArr]; // Create a new array with previous state
              newArr.splice(indexToRemove, 1); // Remove element
              return newArr; // Return new array
            }
            return prevArr; // Return previous state if element not found
          });
        }
      });

      let updatedData = salaryStructureData; // Copying salaryStructureData to a new variable

      // Removing not selected options from salaryStructureData
      if (notSelectedRetiralsOptions.length > 0) { // If there are not selected options
        notSelectedRetiralsOptions.forEach(typeId => { // Iterate through not selected options
          const index = updatedData.retirals.findIndex(item => item.typeId === typeId); // Find index of option to remove
          if (index !== -1) { // If option found
            updatedData = update(updatedData, { // Update data immutably
              retirals: {
                $splice: [[index, 1]], // Splice out the option from retirals array
              },
            });
          }
        });
      }

      // Adding newly selected options to the salaryStructureData
      if (salarySelectedRetiralsOptions.length) { // If there are newly selected options
        selectedRetiralsOptions.forEach(item => { // Iterate through selected options
          if (!salarySelectedRetiralsOptions.includes(item)) { // If an option is newly selected
            newSelectedRetiralsOptions.push(item); // Add it to newSelectedRetiralsOptions
            // Adding newly selected option to salarySelectedRetiralsOptions state
            setSalarySelectedRetiralsOptions(prevState => [...prevState, item]); // Update state with new option
          }
        });

        // Mapping newly selected options to their respective data format
        const data = newSelectedRetiralsOptions.map((item, index) => { // Map through newSelectedRetiralsOptions
          const selectedData = retiralsComponentData.find((el) => el.value === item); // Find data for selected option
          return {
            type: selectedData.name,
            typeId: selectedData.value,
            amount: "",
            componentConfigChoice: null,
            percentageType: null,
            compensation: false
          }; // Return formatted data
        });

        // Pushing new data to the salaryStructureData state
        const updatedData = update(salaryStructureData, { // Update data immutably
          retirals: {
            $push: [...data] // Push new data to retirals array
          }
        });

        // Updating the salaryStructureData state
        setSalaryStructureData(updatedData); // Update state with new data
      }

      if (notSelectedRetiralsOptions.length > 0) { // If there were not selected options
        // Updating the salaryStructureData state
        setSalaryStructureData(updatedData); // Update state with modified data
      }

      if (salaryStructureData.retirals.length === 0) { // If there are no retirals options
        setSalarySelectedRetiralsOptions([...salarySelectedRetiralsOptions, ...selectedRetiralsOptions]) // Add selectedRetiralsOptions to salarySelectedRetiralsOptions
        setSalaryStructureData({ // Update salaryStructureData state
          ...salaryStructureData,
          retirals: [
            ...selectedRetiralsOptions.map((item, index) => { // Map through selectedRetiralsOptions
              const selectedData = retiralsComponentData.find((el) => { // Find data for selected option
                if (el.value === item) { // If option found
                  return el.name // Return name
                }
              })

              return {
                type: selectedData.name,
                typeId: selectedData.value,
                amount: "",
                componentConfigChoice: null,
                percentageType: null,
                compensation: false
              }

            })
          ]
        })
      }

      // Setting the salaryModel state
      setSalaryModel({
        ...salaryModel,
        retirals: false // Update salaryModel state
      });

      // Closing the model
      setOpenModel(false); // Close model
    } catch (error) { // Catch any errors
      console.log("error", error); // Log errors
    }
  };

  const [salarySelectedVariableOptions, setSalarySelectedVariableOptions] = useState([]); // State to store selected variable options

  const onFinishVariable = () => { // Renamed function to reflect handling of variable options
    try {
      let newSelectedVariableOptions = []; // Array to store newly selected variable options
      let notSelectedVariableOptions = []; // Array to store options that were not selected
      console.log("salarySelectedVariableOptions", salarySelectedVariableOptions); // Logging selected variable options

      // Removing unselected options from the salarySelectedVariableOptions
      salarySelectedVariableOptions.forEach(element => {
        if (!selectedVariableOptions.includes(element)) { // If an element is not in the selected options
          notSelectedVariableOptions.push(element); // Add it to the not selected options array
          // Removing unselected option from salarySelectedVariableOptions state
          setSalarySelectedVariableOptions(prevArr => { // Updating state using previous state
            const indexToRemove = prevArr.indexOf(element); // Find index of element to remove
            if (indexToRemove !== -1) { // If element is found
              const newArr = [...prevArr]; // Create a new array with previous state
              newArr.splice(indexToRemove, 1); // Remove element
              return newArr; // Return new array
            }
            return prevArr; // Return previous state if element not found
          });
        }
      });

      let updatedData = salaryStructureData; // Copying salaryStructureData to a new variable

      // Removing not selected options from salaryStructureData
      if (notSelectedVariableOptions.length > 0) { // If there are not selected options
        notSelectedVariableOptions.forEach(typeId => { // Iterate through not selected options
          const index = updatedData.variable.findIndex(item => item.typeId === typeId); // Find index of option to remove
          if (index !== -1) { // If option found
            updatedData = update(updatedData, { // Update data immutably
              variable: {
                $splice: [[index, 1]], // Splice out the option from variable array
              },
            });
          }
        });
      }

      // Adding newly selected options to the salaryStructureData
      if (salarySelectedVariableOptions.length) { // If there are newly selected options
        selectedVariableOptions.forEach(item => { // Iterate through selected options
          if (!salarySelectedVariableOptions.includes(item)) { // If an option is newly selected
            newSelectedVariableOptions.push(item); // Add it to newSelectedVariableOptions
            // Adding newly selected option to salarySelectedVariableOptions state
            setSalarySelectedVariableOptions(prevState => [...prevState, item]); // Update state with new option
          }
        });

        // Mapping newly selected options to their respective data format
        const data = newSelectedVariableOptions.map((item, index) => { // Map through newSelectedVariableOptions
          const selectedData = variableComponentData.find((el) => el.value === item); // Find data for selected option
          return {
            type: selectedData.name,
            typeId: selectedData.value,
            amount: "",
            componentConfigChoice: null,
            percentageType: null,
            compensation: false
          }; // Return formatted data
        });

        // Pushing new data to the salaryStructureData state
        const updatedData = update(salaryStructureData, { // Update data immutably
          variable: {
            $push: [...data] // Push new data to variable array
          }
        });

        // Updating the salaryStructureData state
        setSalaryStructureData(updatedData); // Update state with new data
      }

      if (notSelectedVariableOptions.length > 0) { // If there were not selected options
        // Updating the salaryStructureData state
        setSalaryStructureData(updatedData); // Update state with modified data
      }

      if (salaryStructureData.variable.length === 0) { // If there are no variable options
        setSalarySelectedVariableOptions([...salarySelectedVariableOptions, ...selectedVariableOptions]) // Add selectedVariableOptions to salarySelectedVariableOptions
        setSalaryStructureData({ // Update salaryStructureData state
          ...salaryStructureData,
          variable: [
            ...selectedVariableOptions.map((item, index) => { // Map through selectedVariableOptions
              const selectedData = variableComponentData.find((el) => { // Find data for selected option
                if (el.value === item) { // If option found
                  return el.name // Return name
                }
              })

              return {
                type: selectedData.name,
                typeId: selectedData.value,
                amount: "",
                componentConfigChoice: null,
                percentageType: null,
                compensation: false
              }

            })
          ]
        })
      }

      // Setting the salaryModel state
      setSalaryModel({
        ...salaryModel,
        variable: false // Update salaryModel state
      });

      // Closing the model
      setOpenModel(false); // Close model
    } catch (error) { // Catch any errors
      console.log("error", error); // Log errors
    }
  };


  const onFieldChange = (componentHeader, componentHeaderIndex, componentHeaderChild, componentHeaderChildValue) => {
    if (componentHeaderChild === "componentConfigChoice") {
      const updatedData = update(salaryStructureData, {
        [componentHeader]: {
          [componentHeaderIndex]: {
            [componentHeaderChild]: { $set: componentHeaderChildValue },
            percentageType: { $set: null },
          }
        }
      });

      setSalaryStructureData(updatedData)
    }
    else if (componentHeaderChild === "percentageType") {
      const componentConfigChoiceValue = salaryStructureData[componentHeader][componentHeaderIndex].componentConfigChoice;

      if (componentConfigChoiceValue === 0) {
        const updatedData = update(salaryStructureData, {
          [componentHeader]: {
            [componentHeaderIndex]: {
              [componentHeaderChild]: { $set: componentHeaderChildValue },
              amount: { $set: null },
            }
          }
        });

        setSalaryStructureData(updatedData)
      }
      else {
        const updatedData = update(salaryStructureData, {
          [componentHeader]: {
            [componentHeaderIndex]: {
              [componentHeaderChild]: { $set: componentHeaderChildValue },
            }
          }
        });

        setSalaryStructureData(updatedData)
      }
    }
    else {
      const updatedData = update(salaryStructureData, {
        [componentHeader]: {
          [componentHeaderIndex]: {
            [componentHeaderChild]: { $set: componentHeaderChildValue }
          }
        }
      });

      setSalaryStructureData(updatedData)
    }

  }

  const fetchSalaryConfigurationDetails = async () => {
    try {
      const data = await request({
        method: "get",
        url: `/payroll/get-salary-structure?id=${params.id}`,
      })

      if (data.data.fixed.length) {
        const fixedData = data.data.fixed.map((item, index) => {
          return item.typeId
        })

        form.setFieldsValue({
          fixedComponents: fixedData
        });

        setSelectedFixedOptions(fixedData)
        console.log("fixedData", fixedData);
        setSalarySelectedFixedOptions(fixedData)
      }

      if (data.data.flexi.length) {
        const flexiData = data.data.flexi.map((item, index) => {
          return item.typeId
        })

        flexiForm.setFieldsValue({
          flexiComponents: flexiData
        });

        setSelectedFlexiOptions(flexiData)
        setSalarySelectedFlexiOptions(flexiData)
      }

      if (data.data.retirals.length) {
        const retiralsData = data.data.retirals.map((item, index) => {
          return item.typeId
        })

        retiralsForm.setFieldsValue({
          retiralsComponents: retiralsData
        });

        setSelectedRetiralsOptions(retiralsData)
        setSalarySelectedRetiralsOptions(retiralsData)
      }

      if (data.data.variable.length) {
        const variableData = data.data.variable.map((item, index) => {
          return item.typeId
        })

        variableForm.setFieldsValue({
          variableComponents: variableData
        });

        setvariableComponentData(variableData)
        setSalarySelectedVariableOptions(variableData)
      }

      setSalaryStructureData(data.data)
    }
    catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (params.id) {
      fetchSalaryConfigurationDetails()
    }
    fixedComponents()
    flexiComponents()
    variableComponents()
    retiralsComponents()
    fetchPercentageChoiceList()
  }, [])


  return (
    <div>
      {
        screenSize.width < 768
          ?
          <div className={mobileStyle.container}>
            {/* <p className={mobileStyle.heading}>Payroll</p> */}
            <div className={mobileStyle.subContainer}>
              <p className={mobileStyle.subHeading}>Salary Configuration</p>
              <br />
              <Form
                layout="vertical"
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                fields={[
                  {
                    name: ["name"],
                    value: salaryStructureData?.name
                  }
                ]}
              >
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Name!',
                        },
                      ]}
                    >
                      <Input
                        size='large'
                        onChange={(e) => {
                          setSalaryStructureData({ ...salaryStructureData, name: e.target.value })
                        }}
                      />
                    </Form.Item>

                  </Col>

                  <Col>
                    <p className={mobileStyle.subHeading}>Components</p>
                  </Col>

                  <Col span={24}>

                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <p className={mobileStyle.componentTitle}>Fixed</p>
                      <img src={add} alt="add"
                        onClick={() => {
                          setOpenModel(true);
                          setSalaryModel({ ...salaryModel, fixed: true })
                        }}
                      />
                    </div>
                  </Col>

                  <>
                    {
                      salaryStructureData?.fixed?.map((item, index) => {
                        return (
                          <>
                            <Col span={24}>

                              <p className={mobileStyle.componentSubTitle}>{item.type}</p>
                            </Col>
                            <Col span={24}>
                              <div style={{ display: "grid", gap: "20px", gridTemplateColumns: "1fr" }}>
                                <Input
                                  size='large'
                                  placeholder='Amount'

                                  value={item.amount}
                                  onChange={(e) => {
                                    onFieldChange("fixed", + index, "amount", e.target.value)
                                  }}
                                />
                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.componentConfigChoice}
                                  onChange={(value) => {
                                    onFieldChange("fixed", + index, "componentConfigChoice", value)
                                  }}
                                >
                                  <Select.Option value={0}>Flat Off</Select.Option>
                                  <Select.Option value={1}>Percentage</Select.Option>
                                </Select>

                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.percentageType}
                                  onChange={(value) => {
                                    onFieldChange("fixed", + index, "percentageType", value)
                                  }}
                                >
                                  {
                                    percentageChoiseList?.map((item, index) => {
                                      return (
                                        <Select.Option value={item.value}>{item.name}</Select.Option>
                                      )
                                    })
                                  }
                                </Select>


                              </div>
                              <div style={{ marginTop: "15px" }}>
                                <Checkbox checked={item.compensation} onChange={(e) => { onFieldChange("fixed", + index, "compensation", e.target.checked) }}  >
                                  Deduction
                                </Checkbox>
                              </div>
                            </Col>
                          </>
                        )
                      })
                    }

                  </>




                  <Col span={24}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <p className={mobileStyle.componentTitle}>Flexi</p>
                      <img src={add} alt="add"
                        onClick={() => {
                          setOpenModel(true);
                          setSalaryModel({ ...salaryModel, flexi: true })
                        }}
                      />
                    </div>
                  </Col>

                  <>
                    {
                      salaryStructureData?.flexi?.map((item, index) => {
                        return (
                          <>
                            <Col span={24}>
                              <p className={mobileStyle.componentSubTitle}>{item.type}</p>
                            </Col>
                            <Col span={24}>
                              <div style={{ display: "grid", gap: "20px", gridTemplateColumns: "1fr" }}>
                                <Input
                                  size='large'
                                  placeholder='Amount'
                                  value={item.amount}
                                  onChange={(e) => {
                                    onFieldChange("flexi", + index, "amount", e.target.value)
                                  }}
                                />
                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.componentConfigChoice}
                                  onChange={(value) => {
                                    onFieldChange("flexi", + index, "componentConfigChoice", value)
                                  }}
                                >
                                  <Select.Option value={0}>Flat Off</Select.Option>
                                  <Select.Option value={1}>Percentage</Select.Option>
                                </Select>

                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.percentageType}
                                  onChange={(value) => {
                                    onFieldChange("flexi", + index, "percentageType", value)
                                  }}
                                >
                                  {
                                    percentageChoiseList?.map((item, index) => {
                                      return (
                                        <Select.Option value={item.value}>{item.name}</Select.Option>
                                      )
                                    })
                                  }
                                </Select>


                              </div>
                              <div style={{ marginTop: "15px" }}>
                                <Checkbox checked={item.compensation} onChange={(e) => { onFieldChange("flexi", + index, "compensation", e.target.checked) }}  >
                                  Deduction
                                </Checkbox>
                              </div>
                            </Col>
                          </>
                        )
                      })
                    }
                  </>




                  <Col span={24}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <p className={mobileStyle.componentTitle}>Retirals</p>
                      <img src={add} alt="add"
                        onClick={() => {
                          setOpenModel(true);
                          setSalaryModel({ ...salaryModel, retirals: true })
                        }}
                      />
                    </div>
                  </Col>

                  <>

                    {
                      salaryStructureData?.retirals?.map((item, index) => {
                        return (
                          <>
                            <Col span={24}>
                              <p className={mobileStyle.componentSubTitle}>{item.type}</p>
                            </Col>
                            <Col span={24}>
                              <div style={{ display: "grid", gap: "20px", gridTemplateColumns: "1fr" }}>
                                <Input
                                  size='large'
                                  placeholder='Amount'
                                  value={item.amount}
                                  onChange={(e) => {
                                    onFieldChange("retirals", + index, "amount", e.target.value)
                                  }}
                                />
                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.componentConfigChoice}
                                  onChange={(value) => {
                                    onFieldChange("retirals", + index, "componentConfigChoice", value)
                                  }}
                                >
                                  <Select.Option value={0}>Flat Off</Select.Option>
                                  <Select.Option value={1}>Percentage</Select.Option>
                                </Select>

                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.percentageType}
                                  onChange={(value) => {
                                    onFieldChange("retirals", + index, "percentageType", value)
                                  }}
                                >
                                  {
                                    percentageChoiseList?.map((item, index) => {
                                      return (
                                        <Select.Option value={item.value}>{item.name}</Select.Option>
                                      )
                                    })
                                  }
                                </Select>


                              </div>
                              <div style={{ marginTop: "15px" }}>
                                <Checkbox checked={item.compensation} onChange={(e) => { onFieldChange("retirals", + index, "compensation", e.target.checked) }}  >
                                  Deduction
                                </Checkbox>
                              </div>
                            </Col>
                          </>
                        )
                      })
                    }
                  </>





                  <Col span={24}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <p className={mobileStyle.componentTitle}>Variable</p>
                      <img src={add} alt="add"
                        onClick={() => {
                          setOpenModel(true);
                          setSalaryModel({ ...salaryModel, variable: true })
                        }}
                      />
                    </div>
                  </Col>

                  <>
                    {
                      salaryStructureData?.variable?.map((item, index) => {
                        return (
                          <>
                            <Col span={24}>
                              <p className={mobileStyle.componentSubTitle}>{item.type}</p>
                            </Col>
                            <Col span={24}>
                              <div style={{ display: "grid", gap: "20px", gridTemplateColumns: "1fr" }}>
                                <Input
                                  size='large'
                                  placeholder='Amount'
                                  value={item.amount}
                                  onChange={(e) => {
                                    onFieldChange("variable", + index, "amount", e.target.value)
                                  }}
                                />
                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.componentConfigChoice}
                                  onChange={(value) => {
                                    onFieldChange("variable", + index, "componentConfigChoice", value)
                                  }}
                                >
                                  <Select.Option value={0}>Flat Off</Select.Option>
                                  <Select.Option value={1}>Percentage</Select.Option>
                                </Select>

                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.percentageType}
                                  onChange={(value) => {
                                    onFieldChange("variable", + index, "percentageType", value)
                                  }}
                                >
                                  {
                                    percentageChoiseList?.map((item, index) => {
                                      return (
                                        <Select.Option value={item.value}>{item.name}</Select.Option>
                                      )
                                    })
                                  }
                                </Select>

                              </div>
                              <div style={{ marginTop: "15px" }}>
                                <Checkbox checked={item.compensation} onChange={(e) => { onFieldChange("variable", + index, "compensation", e.target.checked) }}  >
                                  Deduction
                                </Checkbox>
                              </div>
                            </Col>
                          </>
                        )
                      })
                    }
                  </>
                </Row>
                <Button className={mobileStyle.saveBtn} htmlType="submit">
                  Submit
                </Button>

              </Form>
            </div>
          </div>

          :
          <div className={styles.container}>
            {/* <p className={styles.heading}>Payroll</p> */}
            <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
              <img src={back} alt="back" />
              <span style={{ paddingLeft: "10px" }}>Back</span>
            </p>
            <div className={styles.subContainer}>
              <p className={styles.subHeading}>Salary Configuration</p>

              <Form
                layout="vertical"
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                fields={[
                  {
                    name: ["name"],
                    value: salaryStructureData?.name
                  }
                ]}
              >
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Name!',
                        },
                      ]}
                    >
                      <Input
                        size='large'
                        onChange={(e) => {
                          setSalaryStructureData({ ...salaryStructureData, name: e.target.value })
                        }}
                      />
                    </Form.Item>

                  </Col>

                  <p className={styles.subHeading}>Components</p>

                  <Col span={24}>

                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <p className={styles.componentTitle}>Fixed</p>
                      <img src={add} alt="add"
                        onClick={() => {
                          setOpenModel(true);
                          setSalaryModel({ ...salaryModel, fixed: true })
                        }}
                      />
                    </div>
                  </Col>

                  <>
                    {
                      salaryStructureData?.fixed?.map((item, index) => {
                        return (
                          <>
                            <Col span={8}>

                              <p className={styles.componentSubTitle}>{item.type}</p>
                            </Col>
                            <Col span={16}>
                              <div style={{ display: "grid", gap: "10px", gridTemplateColumns: "1fr 1fr 1fr" }}>
                                <Input
                                  size='large'
                                  placeholder='Amount'

                                  value={item.amount}
                                  onChange={(e) => {
                                    onFieldChange("fixed", + index, "amount", e.target.value)
                                  }}
                                  disabled={item.componentConfigChoice === 0 && (item.percentageType === 0 || item.percentageType)}
                                />
                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.componentConfigChoice}
                                  onChange={(value) => {
                                    onFieldChange("fixed", + index, "componentConfigChoice", value)
                                  }}
                                >
                                  <Select.Option value={0}>Flat Off</Select.Option>
                                  <Select.Option value={1}>Percentage</Select.Option>
                                </Select>

                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.percentageType}
                                  onChange={(value) => {
                                    onFieldChange("fixed", + index, "percentageType", value)
                                  }}
                                >
                                  {
                                    percentageChoiseList?.map((item, index) => {
                                      return (
                                        <Select.Option value={item.value}>{item.name}</Select.Option>
                                      )
                                    })
                                  }
                                </Select>


                              </div>
                              <div style={{ marginTop: "15px" }}>
                                <Checkbox checked={item.compensation} onChange={(e) => { onFieldChange("fixed", + index, "compensation", e.target.checked) }}  >
                                  Deduction
                                </Checkbox>
                              </div>
                            </Col>
                          </>
                        )
                      })
                    }

                  </>




                  <Col span={24}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <p className={styles.componentTitle}>Flexi</p>
                      <img src={add} alt="add"
                        onClick={() => {
                          setOpenModel(true);
                          setSalaryModel({ ...salaryModel, flexi: true })
                        }}
                      />
                    </div>
                  </Col>

                  <>
                    {
                      salaryStructureData?.flexi?.map((item, index) => {
                        return (
                          <>
                            <Col span={8}>
                              <p className={styles.componentSubTitle}>{item.type}</p>
                            </Col>
                            <Col span={16}>
                              <div style={{ display: "grid", gap: "10px", gridTemplateColumns: "1fr 1fr 1fr" }}>
                                <Input
                                  size='large'
                                  placeholder='Amount'
                                  value={item.amount}
                                  onChange={(e) => {
                                    onFieldChange("flexi", + index, "amount", e.target.value)
                                  }}
                                  disabled={item.componentConfigChoice === 0 && (item.percentageType === 0 || item.percentageType)}
                                />
                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.componentConfigChoice}
                                  onChange={(value) => {
                                    onFieldChange("flexi", + index, "componentConfigChoice", value)
                                  }}
                                >
                                  <Select.Option value={0}>Flat Off</Select.Option>
                                  <Select.Option value={1}>Percentage</Select.Option>
                                </Select>

                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.percentageType}
                                  onChange={(value) => {
                                    onFieldChange("flexi", + index, "percentageType", value)
                                  }}
                                >
                                  {
                                    percentageChoiseList?.map((item, index) => {
                                      return (
                                        <Select.Option value={item.value}>{item.name}</Select.Option>
                                      )
                                    })
                                  }
                                </Select>


                              </div>
                              <div style={{ marginTop: "15px" }}>
                                <Checkbox checked={item.compensation} onChange={(e) => { onFieldChange("flexi", + index, "compensation", e.target.checked) }}  >
                                  Deduction
                                </Checkbox>
                              </div>
                            </Col>
                          </>
                        )
                      })
                    }
                  </>




                  <Col span={24}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <p className={styles.componentTitle}>Retirals</p>
                      <img src={add} alt="add"
                        onClick={() => {
                          setOpenModel(true);
                          setSalaryModel({ ...salaryModel, retirals: true })
                        }}
                      />
                    </div>
                  </Col>

                  <>

                    {
                      salaryStructureData?.retirals?.map((item, index) => {
                        return (
                          <>
                            <Col span={8}>
                              <p className={styles.componentSubTitle}>{item.type}</p>
                            </Col>
                            <Col span={16}>
                              <div style={{ display: "grid", gap: "10px", gridTemplateColumns: "1fr 1fr 1fr" }}>
                                <Input
                                  size='large'
                                  placeholder='Amount'
                                  value={item.amount}
                                  onChange={(e) => {
                                    onFieldChange("retirals", + index, "amount", e.target.value)
                                  }}
                                  disabled={item.componentConfigChoice === 0 && (item.percentageType === 0 || item.percentageType)}
                                />
                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.componentConfigChoice}
                                  onChange={(value) => {
                                    onFieldChange("retirals", + index, "componentConfigChoice", value)
                                  }}
                                >
                                  <Select.Option value={0}>Flat Off</Select.Option>
                                  <Select.Option value={1}>Percentage</Select.Option>
                                </Select>

                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.percentageType}
                                  onChange={(value) => {
                                    onFieldChange("retirals", + index, "percentageType", value)
                                  }}
                                >
                                  {
                                    percentageChoiseList?.map((item, index) => {
                                      return (
                                        <Select.Option value={item.value}>{item.name}</Select.Option>
                                      )
                                    })
                                  }
                                </Select>


                              </div>
                              <div style={{ marginTop: "15px" }}>
                                <Checkbox checked={item.compensation} onChange={(e) => { onFieldChange("retirals", + index, "compensation", e.target.checked) }}  >
                                  Deduction
                                </Checkbox>
                              </div>
                            </Col>
                          </>
                        )
                      })
                    }
                  </>





                  <Col span={24}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <p className={styles.componentTitle}>Variable</p>
                      <img src={add} alt="add"
                        onClick={() => {
                          setOpenModel(true);
                          setSalaryModel({ ...salaryModel, variable: true })
                        }}
                      />
                    </div>
                  </Col>

                  <>
                    {
                      salaryStructureData?.variable?.map((item, index) => {
                        return (
                          <>
                            <Col span={8}>
                              <p className={styles.componentSubTitle}>{item.type}</p>
                            </Col>
                            <Col span={16}>
                              <div style={{ display: "grid", gap: "10px", gridTemplateColumns: "1fr 1fr 1fr" }}>
                                <Input
                                  size='large'
                                  placeholder='Amount'
                                  value={item.amount}
                                  onChange={(e) => {
                                    onFieldChange("variable", + index, "amount", e.target.value)
                                  }}
                                  disabled={item.componentConfigChoice === 0 && (item.percentageType === 0 || item.percentageType)}
                                />
                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.componentConfigChoice}
                                  onChange={(value) => {
                                    onFieldChange("variable", + index, "componentConfigChoice", value)
                                  }}
                                >
                                  <Select.Option value={0}>Flat Off</Select.Option>
                                  <Select.Option value={1}>Percentage</Select.Option>
                                </Select>

                                <Select
                                  style={{ width: "100%" }}
                                  size='large'
                                  placeholder='Select'
                                  value={item.percentageType}
                                  onChange={(value) => {
                                    onFieldChange("variable", + index, "percentageType", value)
                                  }}
                                >
                                  {
                                    percentageChoiseList?.map((item, index) => {
                                      return (
                                        <Select.Option value={item.value}>{item.name}</Select.Option>
                                      )
                                    })
                                  }
                                </Select>

                              </div>
                              <div style={{ marginTop: "15px" }}>
                                <Checkbox checked={item.compensation} onChange={(e) => { onFieldChange("variable", + index, "compensation", e.target.checked) }}  >
                                  Deduction
                                </Checkbox>
                              </div>
                            </Col>
                          </>
                        )
                      })
                    }
                  </>
                </Row>
                <Button className={styles.saveBtn} htmlType="submit">
                  Submit
                </Button>

              </Form>
            </div>
          </div>

      }

      <Modal
        // title="Fixed"
        centered
        open={openModel}
        onCancel={() => {
          setOpenModel(false);
          setSalaryModel({
            ...salaryModel,
            fixed: false,
            flexi: false,
            retirals: false,
            variable: false
          })
        }}
        footer={null}
        width={500}
      >





        {salaryModel.fixed && <>


          <Form
            form={form}
            layout="vertical"
            onFinish={onFinishFixed}
            onValuesChange={onValuesFixedChange}
          >
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
              <p style={{ fontSize: "18px", color: "#161616", fontWeight: "500" }}>
                {salaryModel.fixed && "Fixed"}
                {salaryModel.flexi && "Flexi"}
                {salaryModel.retirals && "Retirals"}
                {salaryModel.variable && "Variable"}
              </p>
              <Button
                htmlType='submit'
                style={{
                  background: "#008080",
                  color: "white",
                  width: "100px",
                  height: "40px",
                  marginBottom: "40px"
                }}>
                Submit
              </Button>
            </div>
            <Form.Item
              label=""
              name="fixedComponents"
              rules={[
                {
                  required: true,
                  message: 'Please select!',
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                value={selectedFixedOptions}
                name="fixedComponents"
              // onSelect={handleSelectAll}
              >
                <Select.Option value="all">All</Select.Option>
                {fixedComponentData.map(option => {
                  return (
                    <Select.Option key={option.value} value={option.value}>
                      {option.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            {/* <Button
              htmlType='submit'
              style={{
                background: "#008080",
                color: "white",
                width: "100%",
                height: "40px",
                marginTop: "40px"
              }}>
              Submit
            </Button> */}
          </Form>
        </>}
        {salaryModel.flexi &&
          <Form
            form={flexiForm}
            layout="vertical"
            onFinish={onFinishFlexi}
            onValuesChange={onValuesFlexiChange}
          >
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
              <p style={{ fontSize: "18px", color: "#161616", fontWeight: "500" }}>
                {salaryModel.fixed && "Fixed"}
                {salaryModel.flexi && "Flexi"}
                {salaryModel.retirals && "Retirals"}
                {salaryModel.variable && "Variable"}
              </p>
              <Button
                htmlType='submit'
                style={{
                  background: "#008080",
                  color: "white",
                  width: "100px",
                  height: "40px",
                  marginBottom: "40px"
                }}>
                Submit
              </Button>
            </div>
            <Form.Item
              label=""
              name="flexiComponents"
              rules={[
                {
                  required: true,
                  message: 'Please select!',
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                value={selectedFlexiOptions}
                name="flexiComponents"
              // onSelect={handleSelectAll}
              >
                <Select.Option value="all">All</Select.Option>
                {flexiComponentData.map(option => {
                  return (
                    <Select.Option key={option.value} value={option.value}>
                      {option.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            {/* <Button
              htmlType='submit'
              style={{
                background: "#008080",
                color: "white",
                width: "100%",
                height: "40px",
                marginTop: "40px"
              }}>
              Submit
            </Button> */}
          </Form>
        }
        {salaryModel.variable &&
          <Form
            form={variableForm}
            layout="vertical"
            onFinish={onFinishVariable}
            onValuesChange={onValuesVariableChange}
          >
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
              <p style={{ fontSize: "18px", color: "#161616", fontWeight: "500" }}>
                {salaryModel.fixed && "Fixed"}
                {salaryModel.flexi && "Flexi"}
                {salaryModel.retirals && "Retirals"}
                {salaryModel.variable && "Variable"}
              </p>
              <Button
                htmlType='submit'
                style={{
                  background: "#008080",
                  color: "white",
                  width: "100px",
                  height: "40px",
                  marginBottom: "40px"
                }}>
                Submit
              </Button>
            </div>
            <Form.Item
              label=""
              name="variableComponents"
              rules={[
                {
                  required: true,
                  message: 'Please select!',
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                value={selectedVariableOptions}
                name="variableComponents"
              // onSelect={handleSelectAll}
              >
                <Select.Option value="all">All</Select.Option>
                {variableComponentData.map(option => {
                  return (
                    <Select.Option key={option.value} value={option.value}>
                      {option.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            {/* <Button
              htmlType='submit'
              style={{
                background: "#008080",
                color: "white",
                width: "100%",
                height: "40px",
                marginTop: "40px"
              }}>
              Submit
            </Button> */}
          </Form>
        }
        {salaryModel.retirals &&
          <Form
            form={retiralsForm}
            layout="vertical"
            onFinish={onFinishRetirals}
            onValuesChange={onValuesRetiralsChange}
          >
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
              <p style={{ fontSize: "18px", color: "#161616", fontWeight: "500" }}>
                {salaryModel.fixed && "Fixed"}
                {salaryModel.flexi && "Flexi"}
                {salaryModel.retirals && "Retirals"}
                {salaryModel.variable && "Variable"}
              </p>
              <Button
                htmlType='submit'
                style={{
                  background: "#008080",
                  color: "white",
                  width: "100px",
                  height: "40px",
                  marginBottom: "40px"
                }}>
                Submit
              </Button>
            </div>
            <Form.Item
              label=""
              name="retiralsComponents"
              rules={[
                {
                  required: true,
                  message: 'Please select!',
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                value={selectedRetiralsOptions}
                name="retiralsComponents"
              // onSelect={handleSelectAll}
              >
                <Select.Option value="all">All</Select.Option>
                {retiralsComponentData.map(option => {
                  return (
                    <Select.Option key={option.value} value={option.value}>
                      {option.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            {/* <Button
              htmlType='submit'
              style={{
                background: "#008080",
                color: "white",
                width: "100%",
                height: "40px",
                marginTop: "40px"
              }}>
              Submit
            </Button> */}
          </Form>}


      </Modal>
    </div>
  )
}

export default SalaryConfigurationForm