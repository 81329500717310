import React, { useEffect, useState } from 'react'
import request from '../../../utils/axios';
import { toast } from 'react-toastify';
import styles from './Resignation.module.scss';
import getScreenSize from '../../../utils/getScreenSize';
import { useNavigate } from 'react-router-dom';
import InfiniteScrollComponent from '../../../component/InfiniteScroll/InfiniteScroll';

function Resignation() {
    const screenSize = getScreenSize();
    const [resignationList, setResignationList] = useState([]);
    const navigate = useNavigate();
    const fetchResignationList = async () => {
        try {
            const data = await request({
                method: "get",
                url: `my-team/resignation/all`,
            });

            setResignationList(data?.data);
        } catch (error) {
            console.log(error);
        }
    }

    const isApproveReject = async (id, value) => {
        try {
            const data = await request({
                method: "post",
                url: `my-team/resignation/approval?sepration_id=${id}`,
                data:
                {
                    is_approved: value
                }
            });
            setTimeout(() => {
                fetchResignationList();
            }, 1000);
            if (value)
                toast.success("Approved Successfully", {
                    position: "bottom-left",
                });
            else {
                toast.success("Rejected Successfully", {
                    position: "bottom-left",
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchResignationList();
    }, [])

    const renderUI = (dataList) => {
        return dataList?.map((item) => {
            return (
                <div>
                    <div style={{ padding: "0px 16px" }}>
                        <div className={styles.userImageNameBox}
                            onClick={() => navigate("/inbox/resign/" + item.id, { replace: true })}
                        >
                            {/* <img src={image_base_url + item.profile_image} alt="" width={50} height={50}
                                className={styles.userImage}
                            /> */}
                            <p style={{ fontWeight: "600", fontSize: "18px", color: "#2B2B2B", margin: "0px" }}>
                                {item.employee}
                            </p>
                            <p style={{
                                fontWeight: "500",
                                color: "#2B2B2B"
                            }}>{item?.reason?.slice(0, 100)}</p>
                        </div>

                        <div className={styles.actionsBox}>
                            <button
                                style={{
                                    marginRight: "10px",
                                    background: "#28B15F",
                                    border: "none",
                                    color: "#fff",
                                    height: "40px",
                                    padding: "0px 20px",
                                    borderRadius: "5px"
                                }}
                                onClick={() => isApproveReject(item.id, true)}
                            >
                                Approve
                            </button>
                            <button
                                style={{
                                    marginRight: "10px",
                                    background: "none",
                                    border: "1px solid #8A8A8A",
                                    color: "#8A8A8A",
                                    height: "40px",
                                    padding: "0px 20px",
                                    borderRadius: "5px"
                                }}
                                onClick={(e) => isApproveReject(item.id, false)}
                            >
                                Reject
                            </button>

                        </div>

                    </div>
                    <hr />
                </div>
            )
        })
    }


    const renderUIDesktop = (dataList) => {
        return dataList?.map((item, index) => (
            <div>
                <div>
                    <div className={styles.userImageNameBox}
                        onClick={() => navigate("/inbox/resign/" + item.id, { replace: true })}
                    >
                        <img src={item.profile_image} alt="" width={50} height={50}
                            className={styles.userImage}
                        />
                        <div className={styles.userName}>{item.employee}
                            <p style={{
                                marginTop: "10px",
                                fontWeight: "500",
                                color: "#2B2B2B"
                            }}>{item?.reason?.slice(0, 100)}</p>
                        </div>

                    </div>

                    <div className={styles.actionsBox}>
                        <button
                            className={styles.approveBtn}
                            onClick={() => isApproveReject(item.id, true)}
                        >
                            Approve
                        </button>
                        <button
                            className={styles.rejectBtn}
                            onClick={(e) => isApproveReject(item.id, false)}
                        >
                            Reject
                        </button>

                    </div>

                </div>
            </div>
        ))
    }

    return (
        <div>
            {
                screenSize.width < 768 ?
                    // mobile view
                    <>
                        <InfiniteScrollComponent
                            apiEndpoint="my-team/resignation/all"
                            renderUI={renderUI}
                            initialPage={1}
                        />
                    </>
                    :
                    // desktop view
                    <>
                        <InfiniteScrollComponent
                            apiEndpoint="my-team/resignation/all"
                            renderUI={renderUIDesktop}
                            initialPage={1}
                        />
                    </>
            }

        </div>
    )
}

export default Resignation