import { Button, Col, Form, Input, Modal, Radio, Row, Select, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import request from '../../../../utils/axios'
import { useNavigate } from 'react-router-dom';
import arrowCircle from "../../../../assets/fullView.svg";
import back from "../../../../assets/Recruitment/back.svg"
import CommonTable from '../../../../component/CommonTable/CommonTable';
import getScreenSize from '../../../../utils/getScreenSize';
import styles from "./EmployeeSalaryManager.module.scss"
import { toast } from 'react-toastify';
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
function EmployeeSalaryManager({ tabsValue }) {
    const ref = useRef(null);
    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const [ctcBreakUp, setCtcBreakUp] = useState(null)

    const EmployeeSalaryColumn = [
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Employee Code',
            dataIndex: 'employement_number',
            key: 'employement_number',
        },
        {
            title: 'Salary Structure',
            dataIndex: 'structure_name',
            key: 'name',
        },
        {
            title: 'CTC',
            dataIndex: 'ctc',
            key: 'name',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <Select
                    onChange={(value) => onHandleChangeForReviseSalary(value, record)}
                    defaultValue="0"
                    style={{ width: 205 }}
                >
                    <Select.Option value="0">Select</Select.Option>
                    <Select.Option value={1}>Revise Salary</Select.Option>
                    <Select.Option value={2} >Change salary structure</Select.Option>
                </Select>
        },
    ]
    const [selectedEmployeeSalary, setselectedEmployeeSalaryId] = useState({
        id: "",
        name: "",
        appraisal_type: "",
        component_choice: "",
        amount: ""

    })
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })
    const [reviseSalary, setReviseSalary] = useState(false)
    const [selectedEmployeeChangeSalary, setSelectedEmployeeChangeSalary] = useState({
        id: "",
        ctc: '',
        salary_structure: ''
    })
    const [changeSalaryStructure, setChangeSalaryStructure] = useState(false)

    const [employeeSalaryManagerData, setEmployeeSalaryManagerComponentData] = useState([])
    const fetchEmployeeList = async () => {
        const data = await request({
            method: "get",
            url: `payroll/admin/get-all-employees/?page=${pagination.current}&page_size=${pagination.pageSize}`,
        })
        setEmployeeSalaryManagerComponentData([...data.pagination_data.data])
        setPagination({
            ...pagination,
            total: data?.pagination_data?.total
        })
        return data

    }

    const onHandleChangeForReviseSalary = (value, record) => {
        if (value === 1) {
            setselectedEmployeeSalaryId({
                ...selectedEmployeeSalary,
                id: record.employement_number,
                name: record.employee_name
            })
            setReviseSalary(true)
        }
        else if (value === 2) {
            setSelectedEmployeeChangeSalary({
                ...selectedEmployeeChangeSalary,
                id: record.employement_number,
                ctc: record.ctc
            })
            setChangeSalaryStructure(true)
        }
    }

    const onFinish = async (values) => {
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/revised-employee-salary/?id=${selectedEmployeeSalary.id}`,
                data: values

            })

            setReviseSalary(false)
            fetchEmployeeList()
            toast.success("Salary revised successfully", {
                position: "bottom-left",
            })

        } catch (err) {
            console.log(err)
            setReviseSalary(false)
            toast.error("Salary could not be updated, please try after some time", {
                position: "bottom-left",
            })
        }
        // console.log("data : ", data)
        // setrevisedEmployeeData([...data])
    }
    const [breakupDetail, setBreakupDetail] = useState({
        ctc: '',
        salary_structure: ''
    })
    const [salaryStructureList, setSalaryStructureList] = useState([]);

    const fetchStructureList = async () => {
        try {
            const data = await request({
                method: "get",
                url: `payroll/admin/salary-structure/all`,
            });
            setSalaryStructureList(data.data);
        } catch (error) {
            console.log(error);
        } finally {
            // setReload(false)
        }
    };

    const onFinishCTC = async (values) => {
        try {
            const data = await request({
                method: "get",
                url: `payroll/admin/employee-salary-breakup?ctc=${values.ctc}&salary_id=${values.salary_structure}`,
            })
            setCtcBreakUp(data.data)
            setBreakupDetail({
                ctc: values.ctc,
                salary_structure: values.salary_structure
            })
        }
        catch (err) {
            console.log("err : ", err);
        }
    }


    const changeSalarysubmit = async () => {
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/employee/change-salary-structure/?id=${selectedEmployeeChangeSalary.id}`,
                data: breakupDetail
            })
            setChangeSalaryStructure(false)
            setSelectedEmployeeChangeSalary({
                id: "",
                ctc: '',
                salary_structure: ''
            })
            setCtcBreakUp(null)
            fetchEmployeeList();
            toast.success("Salary structure updated successfully", {
                position: "bottom-left",
            })
        }
        catch (err) {
            console.log("err", err)
            toast.error(err.response.data.message, {
                position: "bottom-left",
            })
        }

    }

    useEffect(() => {
        fetchEmployeeList();
    }, [pagination.current, tabsValue])

    useEffect(() => {
        fetchStructureList()
    }, [tabsValue])

    // useEffect(() => {
    //     ref?.current?.scrollIntoView({ behavior: 'instant' })
    //     document.body.scrollTop = document.documentElement.scrollTop = 0;
    // }, []);

    const renderUI = (data) => {
        return data.map((item, index) => {
            return (
                <div>
                    <div className={styles.card}>
                        <div className={styles.header}>
                            <p className={styles.name}>{item.employee_name}</p>
                            <button
                                onClick={() => {
                                    setselectedEmployeeSalaryId({
                                        ...selectedEmployeeSalary,
                                        id: item.employement_number,
                                        name: item.employee_name
                                    })
                                    setReviseSalary(true)

                                }}
                                style={{
                                    background: "#F2F2F2",
                                    border: "none",
                                    color: "#8A8A8A",
                                    padding: "5px 12px",
                                    fontSize: "16px",
                                    borderRadius: "4px"
                                }}
                            >
                                Revise Salary
                            </button>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p className={styles.title}>Employee Code</p>
                            <p className={styles.value}>{item.employement_number}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p className={styles.title}>Salary Structure</p>
                            <p className={styles.value}>{item.structure_name}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p className={styles.title}>CTC</p>
                            <p className={styles.value}>₹{item.ctc}</p>
                        </div>
                    </div>
                    {
                        index < employeeSalaryManagerData.length - 1
                        &&
                        <hr className={styles.line} />
                    }
                </div>
            )
        })
    }

    const renderDesktopUI = (dataList) => {
        return <Table
            columns={EmployeeSalaryColumn}
            dataSource={dataList}
            pagination={false}
            sticky={{ offsetHeader: 0 }}
        />
    }

    return (
        <div ref={ref}>
            {
                screenSize.width < 768
                    ?
                    <div className={styles.employeeSalaryContainer}>
                        {/* <p className={styles.heading}>Employee Salary Manager</p> */}
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                            <p className={styles.heading} style={{ margin: "0px" }}>Employee Salary Manager</p>

                        </div>
                        <InfiniteScrollComponent
                            apiEndpoint="payroll/admin/get-all-employees"
                            renderUI={renderUI}
                            initialPage={1}
                        />
                    </div>
                    :
                    <div style={{ padding: "50px" }}>
                        {/* <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                            <img src={back} alt="back" />
                            <span style={{ paddingLeft: "10px" }}>Back</span>
                        </p>
                        <div className="flexSpaceBetween">
                            <p className='heading'>Employee Salary Manager</p>

                        </div> */}
                        <div style={{ padding: "30px", background: "white", borderRadius: "5px" }}>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Employee Salary Manager</p>
                            <InfiniteScrollComponent
                                apiEndpoint="payroll/admin/get-all-employees"
                                renderUI={renderDesktopUI}
                                initialPage={1}
                            />
                        </div>
                        {/* <CommonTable
                            columns={EmployeeSalaryColumn}
                            data={employeeSalaryManagerData}
                            // loading={loading}
                            pagination={pagination}
                            setPagination={setPagination}
                            handleRowClick={() => { }}
                        /> */}
                    </div>
            }

            <Modal
                title="Revise Salary"
                centered
                open={reviseSalary}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => setReviseSalary(false)}
                width={400}
                footer={null}
            >
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={selectedEmployeeSalary}
                    // onFinishFailed={onFinishFailed}
                    fields={[
                        {
                            name: ["name"],
                            value: selectedEmployeeSalary?.name
                        }
                    ]}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label="Employee Name"
                                name="name"
                            >
                                <Input
                                    size='large'
                                />
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616" }}>Current CTC</p>
                            <Form.Item
                                label=""
                                name="appraisal_type"
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'Increment',
                                            value: 'increment',
                                        },
                                        {
                                            label: 'Decrement',
                                            value: 'decrement',
                                        },
                                    ]}
                                    // onChange={onChange3}
                                    // value={value3}
                                    optionType="button"
                                    buttonCheckedBg="red"
                                    buttonStyle="solid"
                                    colorBgContainer="#E2F6F6"
                                    size='large'
                                />
                            </Form.Item>

                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="component_choice"
                            >
                                <Select
                                    size='large'
                                    onChange={(value) => setselectedEmployeeSalaryId({
                                        ...selectedEmployeeSalary,
                                        component_choice: value
                                    })}
                                >
                                    <Select.Option value="">Select</Select.Option>
                                    <Select.Option value={0}>Flat</Select.Option>
                                    <Select.Option value={1}>Percentage</Select.Option>
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="amount"
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                />
                            </Form.Item>

                        </Col>
                        {selectedEmployeeSalary.component_choice === 1
                            &&
                            <Col span={24}>
                                <Form.Item
                                >
                                    <Input
                                        size='large'
                                        placeholder="Enter Amount"
                                        value="CTC"
                                        disabled={true}
                                    />
                                </Form.Item>

                            </Col>
                        }
                    </Row>
                    <Button style={{ width: "100%", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>

                </Form>
            </Modal>

            <Modal
                title="Change Salary Structure"
                centered
                open={changeSalaryStructure}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => {
                    setChangeSalaryStructure(false)
                    setSelectedEmployeeChangeSalary({
                        id: "",
                        ctc: '',
                        salary_structure: ''
                    })
                    setCtcBreakUp(null)
                }}
                width={600}
                footer={null}>
                <Form
                    // form={form}
                    layout="vertical"
                    onFinish={onFinishCTC}
                    initialValues={selectedEmployeeChangeSalary}
                    autoComplete="true"
                    fields={[
                        {
                            name: ["ctc"],
                            value: selectedEmployeeChangeSalary?.ctc

                        },
                        {
                            name: ['salary_structure'],
                            value: selectedEmployeeChangeSalary?.salary_structure
                        }
                    ]}
                >
                    <Row gutter={32}>
                        <Col span={12}>
                            <Form.Item
                                label="CTC"
                                name="ctc"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter CTC!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter CTC"
                                    disabled="true"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>

                            <Form.Item
                                label="Salary Structure"
                                name="salary_structure"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Salary Structure!",
                                    },
                                ]}
                            >
                                <Select size="large"
                                    onChange={(value) => {
                                        setSelectedEmployeeChangeSalary({
                                            ...selectedEmployeeChangeSalary,
                                            salary_structure: value
                                        })
                                    }}
                                >
                                    {salaryStructureList?.map((item, index) => {
                                        return (
                                            <Select.Option key={index} value={item.id}
                                            >
                                                {item.structure_name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <button type="submit" style={{
                                padding: "0px 15px",
                                height: "40px",
                                background: "#008080",
                                color: "white",
                                border: "none",
                                borderRadius: "5px"
                            }}>Get BreakUp</button>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        {
                            ctcBreakUp?.fixed.length > 0
                            &&
                            <>
                                <Col span={24}>
                                    <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Fixed</p>
                                </Col>

                                {
                                    ctcBreakUp?.fixed?.map((item, index) => {
                                        return (
                                            <>
                                                <Col span={12}>
                                                    <p>{item?.name}</p>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        key={index}
                                                    >
                                                        <Input
                                                            size='large'
                                                            placeholder="Enter Amount"
                                                            value={item?.yearly_amount}
                                                            disabled
                                                        />

                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )
                                    })
                                }
                            </>
                        }

                    </Row>

                    <Row gutter={16}>
                        {
                            ctcBreakUp?.flexi.length > 0
                            &&
                            <>
                                <Col span={24}>
                                    <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Flexi</p>
                                </Col>
                                {
                                    ctcBreakUp?.flexi?.map((item, index) => {
                                        return (
                                            <>
                                                <Col span={12}>
                                                    <p>{item?.name}</p>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        key={index}
                                                    >
                                                        <Input
                                                            size='large'
                                                            placeholder="Enter Amount"
                                                            value={item?.yearly_amount}
                                                            disabled
                                                        />

                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )
                                    })
                                }
                            </>
                        }
                    </Row>

                    <Row gutter={16}>
                        {
                            ctcBreakUp?.retirals?.length > 0
                            &&
                            <>
                                <Col span={24}>
                                    <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Retirals</p>
                                </Col>
                                {
                                    ctcBreakUp?.retirals?.map((item, index) => {
                                        return (
                                            <>
                                                <Col span={12}>
                                                    <p>{item?.name}</p>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        key={index}
                                                    >
                                                        <Input
                                                            size='large'
                                                            placeholder="Enter Amount"
                                                            value={item?.yearly_amount}
                                                            disabled
                                                        />

                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )
                                    })
                                }
                            </>
                        }
                    </Row>

                    <Row gutter={16}>
                        {
                            ctcBreakUp?.variable?.length > 0
                            &&
                            <>
                                <Col span={24}>
                                    <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Variable</p>
                                </Col>
                                {
                                    ctcBreakUp?.variable?.map((item, index) => {
                                        return (
                                            <>
                                                <Col span={12}>
                                                    <p>{item?.name}</p>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        key={index}
                                                    >
                                                        <Input
                                                            size='large'
                                                            placeholder="Enter Amount"
                                                            value={item?.yearly_amount}
                                                            disabled
                                                        />

                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )
                                    })
                                }
                            </>
                        }


                    </Row>
                    {ctcBreakUp?.fixed.length > 0
                        && <div className="buttonContainer">
                            <Button style={{
                                padding: "0px 15px",
                                height: "40px",
                                background: "#008080",
                                color: "white",
                                fontSize: "16px",
                                marginTop: "10px"
                            }} onClick={() => {
                                setChangeSalaryStructure(false)
                                setSelectedEmployeeChangeSalary({
                                    id: "",
                                    ctc: '',
                                    salary_structure: ''
                                })
                                setCtcBreakUp(null)
                            }} >Cancel</Button>

                            <Button style={{
                                padding: "0px 15px",
                                height: "40px",
                                background: "#008080",
                                color: "white",
                                fontSize: "16px",
                                marginTop: "10px"
                            }} onClick={() => changeSalarysubmit()}>Submit</Button>


                        </div>}

                </Form>


            </Modal>
        </div>

    )
}

export default EmployeeSalaryManager