import React, { useEffect, useState } from 'react';
import styles from "./PersonalDetails.module.scss"
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import request from '../../../utils/axios';
import dayjs from 'dayjs';
import edit from "../../../assets/edit.svg"
import useScreenSize from '../../../utils/getScreenSize';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function PersonalDetails() {
    const screenSize = useScreenSize();
    const employee_number = localStorage.getItem("employee_number");
    const [dobDisplay, setDobDisplay] = useState("");
    const [anniversaryDisplay, setAnniversaryDisplay] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const { TextArea } = Input;
    const params = useParams();

    const [userProfile, setUserProfile] = useState({
        employee_name: "",
        personal_email: "",
        contact: "",
        address: "",
        dob: "",
        gender: "",
        marital_status: "",
        anniversary: "",
        employee_type: "",
        department: "",
        reporting_manager: "",
        joining_date: "",
        work_mode: "",
        work_location: "",
        notice_period: "",
        employement_number: "",
        emp_functions: "",
        location: ""
    })
    const [userEditProfile, setUserEditProfile] = useState({
        employee_name: "",
        personal_email: "",
        contact: "",
        address: "",
        dob: "",
        gender: "",
        marital_status: "",
        anniversary: "",
        employee_type: "",
        department: "",
        reporting_manager: "",
        joining_date: "",
        work_mode: "",
        work_location: "",
        notice_period: "",
        employement_number: "",
        emp_functions: "",
        location: ""
    })

    const fetchProfileData = async () => {
        if (params.id) {
            let data = await request({
                method: "get",
                url: `admin/profile/details/${params.id}/`,
            })

            setDobDisplay(data.data.data.dob);
            if (data.data.data.anniversary) {
                setAnniversaryDisplay(data.data.data.anniversary);
            }
            const dobDate = new Date(data.data.data.dob).toISOString();

            data.data.data = { ...data.data.data, dob: dayjs(dobDate) }

            if (data.data.data.anniversary) {
                const anniversaryDate = new Date(data.data.data.anniversary);
                const anniversary = anniversaryDate.toISOString();
                data.data.data = { ...data.data.data, anniversary: dayjs(anniversary) }
            }

            setUserProfile({
                ...data.data.data,
            })
        }
        else {
            let data = await request({
                method: "get",
                url: `profile/details/`,
            })

            setDobDisplay(data.data.data.dob);
            if (data.data.data.anniversary) {
                setAnniversaryDisplay(data.data.data.anniversary);
            }
            const dobDate = new Date(data.data.data.dob).toISOString();

            data.data.data = { ...data.data.data, dob: dayjs(dobDate) }

            if (data.data.data.anniversary) {
                const anniversaryDate = new Date(data.data.data.anniversary);
                const anniversary = anniversaryDate.toISOString();
                data.data.data = { ...data.data.data, anniversary: dayjs(anniversary) }
            }

            setUserProfile({
                ...data.data.data,
            })
        }
    }

    const fetchProfileDataToEdit = async () => {
        let data = await request({
            method: "get",
            url: `profile/personal-details/`,
        })
        setDobDisplay(data.data.data.dob);
        if (data.data.data.anniversary) {
            setAnniversaryDisplay(data.data.data.anniversary);
        }
        const dobDate = new Date(data.data.data.dob).toISOString();

        data.data.data = { ...data.data.data, dob: dayjs(dobDate) }

        if (data.data.data.anniversary) {
            const anniversaryDate = new Date(data.data.data.anniversary);
            const anniversary = anniversaryDate.toISOString();
            data.data.data = { ...data.data.data, anniversary: dayjs(anniversary) }
        }

        setUserEditProfile({
            ...data.data.data,
        })

    }

    // const handleMaritalStatusChange = (maritalStatus) => {
    //     if (maritalStatus === 'MARRIED') {
    //         setUserProfile({ ...userProfile, marital_status: "MARRIED" });
    //     } else {
    //         setUserProfile({ ...userProfile, anniversary: "", marital_status: "SINGLE" });
    //     }
    // };

    const handleFormChange = (changedValues, allValues) => {
        setUserEditProfile(allValues);
    }

    const onFinish = async (values) => {
        try{
            const dob = dayjs(values.dob).format('YYYY-MM-DD');
            values = { ...values, dob }
    
            if (values?.anniversary) {
                const anniversary = dayjs(values.anniversary).format('YYYY-MM-DD');
                values = { ...values, dob, anniversary }
            }
    
            await request({
                method: "put",
                url: `profile/details/update/${employee_number}/`,
                data: values,
            })
    
            fetchProfileData()
            setIsEdit(false)
            toast.success("Profile Updated Successfully", {
                position: "bottom-left",
            });
        }
        catch(error){
            console.log("error", error);
            toast.error("Profile could not be updated, please try after some time", {
                position: "bottom-left",
            })
        }
    };


    useEffect(() => {
        fetchProfileData();
        fetchProfileDataToEdit();
    }, [])

    return (
        <div className={styles.personalDetailsContainer}>
            {
                <div className={styles.personalDetailsInnerContainer}>
                    <div className='flexSpaceBetween'>
                        <p className={styles.heading}>Personal Details</p>
                        {
                            !params.id && <img
                                src={edit}
                                alt={edit}
                                width={25}
                                height={25}
                                style={{ cursor: "pointer" }}
                                onClick={() => setIsEdit(!isEdit)}
                            />
                        }

                    </div>
                    <Row>

                        <Col span={8}>
                            <p className={styles.keyText}>Gender</p>
                        </Col>
                        <Col span={16}>
                            <p className={styles.valueText}>{userProfile.gender}</p>
                        </Col>

                        <Col span={8}>
                            <p className={styles.keyText}>Marital Status</p>
                        </Col>
                        <Col span={16}>
                            <p className={styles.valueText}>{userProfile.marital_status}</p>
                        </Col>

                        <Col span={8}>
                            <p className={styles.keyText}>Address</p>
                        </Col>
                        <Col span={16}>
                            <p className={styles.valueText}>{userProfile.address}</p>
                        </Col>

                        <Col span={8}>
                            <p className={styles.keyText}>Date of Birth</p>
                        </Col>
                        <Col span={16}>
                            <p className={styles.valueText}>{dobDisplay}</p>
                        </Col>

                        <Col span={8}>
                            <p className={styles.keyText}>Anniversary</p>
                        </Col>
                        <Col span={16}>
                            <p className={styles.valueText}>{anniversaryDisplay}</p>
                        </Col>

                        <Col span={8}>
                            <p className={styles.keyText}>Contact No.</p>
                        </Col>
                        <Col span={16}>
                            <p className={styles.valueText}>{userProfile.contact}</p>
                        </Col>
                    </Row>
                </div>
            }



            <Modal
                title="Personal Details"
                centered
                open={isEdit}
                footer={null}
                onCancel={() => setIsEdit(!isEdit)}
            >
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    onValuesChange={handleFormChange}
                    initialValues={userEditProfile}
                    fields={[
                        {
                            name: ["contact"],
                            value: userEditProfile.contact
                        },
                        {
                            name: ["address"],
                            value: userEditProfile.address
                        },
                        {
                            name: ["dob"],
                            value: userEditProfile.dob
                        },
                        {
                            name: ["gender"],
                            value: userEditProfile.gender
                        },
                        {
                            name: ["marital_status"],
                            value: userEditProfile.marital_status,
                        },
                        {
                            name: ["anniversary"],
                            value: userEditProfile.anniversary
                        },
                        {
                            name: ["employee_type"],
                            value: userEditProfile.employee_type
                        },
                    ]}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Gender"
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select your Gender!',
                                    },
                                ]}
                            >
                                <Select
                                    size='large'
                                >
                                    <Select.Option value={0}>Male</Select.Option>
                                    <Select.Option value={1}>Female</Select.Option>
                                    <Select.Option value={2}>Other</Select.Option>
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Marital Status"
                                name="marital_status"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Marital Status!',
                                    },
                                ]}
                            >
                                <Select
                                    size='large'
                                >
                                    <Select.Option value={0}>Married</Select.Option>
                                    <Select.Option value={1}>Single</Select.Option>
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Address"
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your address!',
                                    },
                                ]}
                            >
                                <TextArea
                                    rows={5}
                                    disabled={!edit}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Date of Birth"
                                name="dob"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Date of Birth!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Anniversary"
                                name="anniversary"
                                rules={
                                    [
                                        {
                                            required: userProfile.marital_status === "MARRIED" ? true : false,
                                            message: 'Please enter your Anniversary!',
                                        },
                                    ]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    size='large'
                                    disabled={userProfile.marital_status === "SINGLE" ? true : false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Contact No."
                                name="contact"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your contact!',
                                    },
                                    {
                                        pattern: /^[0-9]{10}$/, // A regular expression that matches a 10-digit contact number
                                        message: 'Please enter a valid 10-digit contact number!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button
                        htmlType='submit'
                        style={{ width: "100%", height: "47px", background: "#008080", color: "white" }}
                    >
                        Save
                    </Button>
                </Form>
            </Modal >
        </div >
    )
}

export default PersonalDetails