import React, { useEffect, useRef, useState } from 'react';
import styles from "./PayRollProcessing.module.scss"
import { Col, DatePicker, Row, Select } from "antd";
// images
import leaveAttendance from "../../../../assets/Payroll/Admin/leaveAttendance.png";
import leaveAttendanceReviewed from "../../../../assets/Payroll/Admin/leaveAttendanceReviewed.png";
import newJoinesAndExit from "../../../../assets/Payroll/Admin/newJoinesAndExit.png";
import newJoinesAndExitReviewed from "../../../../assets/Payroll/Admin/newJoinesAndExitReviewed.png";
import bonusSalaryRevision from "../../../../assets/Payroll/Admin/bonusSalaryRevision.png";
import bonusSalaryRevisionReviewed from "../../../../assets/Payroll/Admin/bonusSalaryRevisionReviewed.png";
import reimbursement from "../../../../assets/Payroll/Admin/reimbursement.png";
import reimbursementReviewed from "../../../../assets/Payroll/Admin/reimbursementReviewed.png";
import salariesAndArrears from "../../../../assets/Payroll/Admin/salariesAndArrears.png";
import salariesAndArrearsReviewed from "../../../../assets/Payroll/Admin/salariesAndArrearsReviewed.png";
import overrides from "../../../../assets/Payroll/Admin/overrides.png";
import overridesReviewed from "../../../../assets/Payroll/Admin/overridesReviewed.png";
import LeaveAttendance from './LeaveAttendance/LeaveAttendance';
import NewJoinesAndExit from './NewJoinesAndExit/NewJoinesAndExit';
import BonusSalaryRevision from './BonusAndSalaryRevision/BonusAndSalaryRevision';
import Reimbursement from './Reimbursement/Reimbursement';
import SalaryOnHoldAndArrears from './SalaryOnHoldAndArrears/SalaryOnHoldAndArrears';
import Overrides from './Overrides/Overrides';
import dayjs from 'dayjs';
import request from '../../../../utils/axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


function PayRollProcessing({ tabsValue }) {
    const navigate = useNavigate();
    const [payrollProcessingReviewData, setPayrollProcessingReviewData] = useState()
    const ref = useRef(null);
    const payrollProcessingTypeList = [
        {
            name: "Leave & Attendance",
            image: leaveAttendance,
            reviewedImage: leaveAttendanceReviewed,
            reviewed: payrollProcessingReviewData?.leaveAttendance,
            key: "leaveAttendance",
            color: "#6732FF"
        },
        {
            name: "New Joines & Exit",
            image: newJoinesAndExit,
            reviewedImage: newJoinesAndExitReviewed,
            reviewed: payrollProcessingReviewData?.newJoineesAndExits,
            key: "newJoinesAndExit",
            color: "#FBA602"
        },
        {
            name: "Bonus Salary Revision",
            image: bonusSalaryRevision,
            reviewedImage: bonusSalaryRevisionReviewed,
            reviewed: payrollProcessingReviewData?.bonusSalaryRevision,
            key: "bonusSalaryRevision",
            color: "#28B15F"
        },
        {
            name: "Reimbursement",
            image: reimbursement,
            reviewedImage: reimbursementReviewed,
            reviewed: payrollProcessingReviewData?.reimbursementAndAdhocs,
            key: "reimbursement",
            color: "#3354F4"
        },
        {
            name: "Salaries & Arrears",
            image: salariesAndArrears,
            reviewedImage: salariesAndArrearsReviewed,
            reviewed: payrollProcessingReviewData?.salariesAndArrears,
            key: "salariesAndArrears",
            color: "#FA0D0D"
        },
        {
            name: "Overrides ( PT, EST, T..)",
            image: overrides,
            reviewedImage: overridesReviewed,
            reviewed: payrollProcessingReviewData?.overrides,
            key: "overrides",
            color: "#FF7B99"
        }
    ]

    const [selectProcessingType, setSelectProcessingType] = useState("leaveAttendance")
    const [processingDate, setProcessingDate] = useState({
        month: dayjs().format('MM'),
        year: dayjs().format('YYYY'),
    });


    const fetchPayrollProcessingReviewValues = async () => {
        try {
            const data = await request({
                method: "get",
                url: `payroll/admin/get-payroll-processing-review-tabs?month=${processingDate.month}&year=${processingDate.year}`,
            })

            setPayrollProcessingReviewData(data?.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    const [payrollProcessingChildReviewData, setPayrollProcessingChildReviewData] = useState()
    const fetchPayrollProcessingChildReviewValues = async () => {
        try {
            const data = await request({
                method: "get",
                url: `payroll/admin/get-payroll-processing-child-tabs?month=${processingDate.month}&year=${processingDate.year}`,
            })
            setPayrollProcessingChildReviewData(data?.data)
        }
        catch (err) {
            console.log(err)
        }
    }
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = async (event) => {
        setSelectedOption(event);

        if (event === "delete") {
            try {
                const data = await request({
                    method: "get",
                    url: `payroll/admin/delete-payroll-processing?month=${processingDate.month}&year=${processingDate.year}`,
                });

                toast.success("Payroll processing deleted successfully", {
                    position: "bottom-left",
                });
                fetchReviewDetails();
            } catch (err) {
                console.log(err);
                toast.error("Failed to delete payroll processing", {
                    position: "bottom-left",
                });
            }
        }
        else {
            navigate("/admin/payroll/selective-payroll-processing")
        }
        // Add more conditions if needed for other options
    };
    // const deletePayRun = async () => {
    //     try {
    //         console.log("delete api hit..................")
    //         const data = await request({
    //             method: "get",
    //             url: `payroll/admin/delete-payroll-processing?month=${processingDate.month}&year=${processingDate.year}`,
    //         })
    //         console.log(data.data)
    //         toast.success("Payroll processing deleted successfully", {
    //             position: "bottom-left",
    //         })
    //         fetchReviewDetails();
    //     }
    //     catch (err) {
    //         console.log(err)
    //     }
    // }

    useEffect(() => {
        if (tabsValue === "9") {
            fetchPayrollProcessingReviewValues();
            fetchPayrollProcessingChildReviewValues();
        }
    }, [tabsValue])

    const fetchReviewDetails = async () => {
        fetchPayrollProcessingReviewValues();
        fetchPayrollProcessingChildReviewValues();
    }

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);


    return (
        <div
         ref={ref}
         >
            <div className={styles.payRollProcessingContainer} >
                <div className={styles.container}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        marginBottom: "30px",
                        justifyContent: "space-between"
                    }}>
                        <div style={{ display: "flex", gap: "20px" }}>
                            <p className={styles.heading}>PayRoll</p>
                            <DatePicker
                                size="large"
                                picker="month"
                                defaultValue={dayjs()}
                                style={{
                                    border: "none",
                                    outline: "none",
                                    background: "#FFFFFF",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#000000",
                                }}
                                onChange={(e) => {
                                    console.log("e...", e);
                                    const [calendarYear, calendarMonth] = dayjs(e)
                                        .format("YYYY-MM")
                                        .split("-");
                                    setProcessingDate({
                                        ...processingDate,
                                        month: calendarMonth,
                                        year: calendarYear
                                    })
                                }}
                                // can we set default value as month name
                                // defaultValue={moment().format("MM")}
                                // defaultValue={dayjs().startOf('month')}
                                placeholder="Month"
                                format={"MMMM YYYY"}
                            />
                        </div>
                        <div>
                            <Select size="large" value={selectedOption} style={{ width: 200 }} onChange={handleOptionChange}>
                                <Select.Option value="" disabled>Select</Select.Option>
                                <Select.Option value="delete">Delete PayRoll</Select.Option>
                                <Select.Option value="selective">Selective PayRoll</Select.Option>
                            </Select>
                            {/* <select onChange={handleOptionChange}>
                            <option value="">Select</option>
                            <option value="delete">Delete PayRoll</option>
                            <option value="selective">Selective PayRoll</option>
                        </select> */}
                        </div>
                        {/* <button onClick={deletePayRun}>Delete PayRun</button> */}

                    </div>

                    <div className={styles.payrollProcessingTypeListContainer} style={{ display: "grid", gridTemplateColumns: "repeat(6,1fr)" }}>

                        {
                            payrollProcessingTypeList.map((item) => {
                                return (

                                    <div
                                        key={item.key}
                                        style={{ border: selectProcessingType === item.key && `2px solid ${item.color}`, cursor: "pointer", height: "120px" }}
                                        className={styles.payrollProcessingTypeBox}
                                        onClick={() => setSelectProcessingType(item.key)}
                                    >
                                        <center>
                                            {
                                                item.reviewed
                                                    ?
                                                    <img width={50} height={45} src={item.reviewedImage} alt={item.reviewedImage} />
                                                    :
                                                    <img width={50} height={45} src={item.image} alt={item.name} />
                                            }
                                            <p style={{ color: item.color }} className={styles.name}>{item.name}</p>
                                        </center>
                                    </div>

                                )
                            })
                        }

                    </div>

                    <div className={styles.contentContainer}>
                        {
                            selectProcessingType === "leaveAttendance"
                            &&
                            <LeaveAttendance
                                processingDate={processingDate}
                                setSelectProcessingType={setSelectProcessingType}
                                reviewed={payrollProcessingChildReviewData?.leaveAttendance}
                                fetchReviewDetails={fetchReviewDetails}
                            />
                        }
                        {
                            selectProcessingType === "newJoinesAndExit"
                            &&
                            <NewJoinesAndExit
                                processingDate={processingDate}
                                setSelectProcessingType={setSelectProcessingType}
                                reviewed={payrollProcessingChildReviewData?.newJoineesAndExits}
                                fetchReviewDetails={fetchReviewDetails}
                            />
                        }
                        {
                            selectProcessingType === "bonusSalaryRevision"
                            &&
                            <BonusSalaryRevision
                                processingDate={processingDate}
                                setSelectProcessingType={setSelectProcessingType}
                                reviewed={payrollProcessingChildReviewData?.bonusSalaryRevision}
                                fetchReviewDetails={fetchReviewDetails}
                            />
                        }
                        {
                            selectProcessingType === "reimbursement"
                            &&
                            <Reimbursement
                                processingDate={processingDate}
                                setSelectProcessingType={setSelectProcessingType}
                                reviewed={payrollProcessingChildReviewData?.reimbursementAndAdhocs}
                                fetchReviewDetails={fetchReviewDetails}
                            />
                        }
                        {
                            selectProcessingType === "salariesAndArrears"
                            &&
                            <SalaryOnHoldAndArrears
                                processingDate={processingDate}
                                setSelectProcessingType={setSelectProcessingType}
                                reviewed={payrollProcessingChildReviewData?.salariesAndArrears}
                                fetchReviewDetails={fetchReviewDetails}
                            />
                        }
                        {
                            selectProcessingType === "overrides"
                            &&
                            <Overrides
                                processingDate={processingDate}
                                reviewed={payrollProcessingChildReviewData?.overrides}
                                fetchReviewDetails={fetchReviewDetails}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PayRollProcessing