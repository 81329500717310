import React from 'react';
import {useMemo, useEffect, useState} from 'react';
import request from '../../../../utils/axios';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import {Spin} from 'antd';
import duration from '../../../../assets/Attendance/Admin/duration.svg';
import mylocation from '../../../../assets/mylocation.png';
import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import mapLocation from '../../../../assets/location.svg';
import Search from '../../SearchBar/Search';
import getScreenSize from '../../../../utils/getScreenSize';
import MobileStyle from './MobileStyle.module.scss';

function Location() {
  const screenSize = getScreenSize();
  const [employeeList, setEmployeeList] = useState([]);
  const [tabs, setTabs] = useState(1);
  const containerStyle = {
    width: '100%',
    height: '100vh',
  };
  const MobilecontainerStyle = {
    width: '100%',
    height: '100%',
  };
  useEffect(() => {
    loadData();
  }, []);
  const loadData = async () => {
    const apiUrl = `tracking/reporting-manager/employee/tracking-list`;
    try {
      const res = await request({
        method: 'get',
        url: apiUrl,
      });
      console.log(res.data);
      setEmployeeList(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const peopleLocations = [
    {name: 'Pintu Kumar', lat: 28.7041, lng: 77.1025, role: 'Sales Manager'},
    {
      name: 'Ravi Sharma',
      lat: 28.5355,
      lng: 77.391,
      role: 'Marketing Manager',
    },
    {
      name: 'Amit Singh',
      lat: 28.4595,
      lng: 77.0266,
      role: 'Software Engineer',
    },
  ];
  const customIcon = new Leaflet.Icon({
    iconUrl: mapLocation, // Replace with your image path
    iconSize: [25, 41], // Adjust the size of the marker
    iconAnchor: [12, 41], // The point of the icon which will correspond to the marker's location
    popupAnchor: [1, -34], // Position of the popup relative to the marker
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'), // Optional shadow for marker
    shadowSize: [41, 41],
  });

  return (
    <div style={{background: 'white'}}>
      {screenSize.width < 768 ? (
        // moible View
        <div className={MobileStyle.container}>
          <h2>Team Location</h2>

          <div className={MobileStyle.search}>
            <Search />
          </div>
          {tabs == 1 && (
            <div className={MobileStyle.EmployeeDiv}>
              {employeeList.map((item, index) => {
                return (
                  <div key={index} className={MobileStyle.employee_element}>
                    <div className={MobileStyle.top}>
                      <div className={MobileStyle.name}>
                        {item.employee_name}
                      </div>
                      <div className={MobileStyle.buttonContainer}>
                        <button
                          onClick={() => {
                            setTabs(2);
                          }}>
                          Track
                        </button>
                      </div>
                    </div>
                    <div className={MobileStyle.middle}>
                      <img
                        src={duration}
                        alt="duration"
                        width={18}
                        height={18}
                      />
                      <span>Sales manager</span>
                    </div>
                    <div className={MobileStyle.bottom}>
                      <img
                        src={mylocation}
                        alt="mylocation"
                        width={18}
                        height={18}
                      />
                      <span>
                        BLOCK-F, F61, South City II, Sector 50, Gurugram,
                        Haryana 122018
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {tabs == 2 && (
            <div className={MobileStyle.mapContainer}>
              {' '}
              <MapContainer
                center={[28.7041, 77.1025]}
                zoom={10}
                style={MobilecontainerStyle}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {peopleLocations.map((person, index) => (
                  <Marker
                    key={index}
                    position={[person.lat, person.lng]}
                    icon={customIcon}>
                    <Popup>
                      <strong>{person.name}</strong>
                      <br />
                      Role: {person.role}
                    </Popup>
                  </Marker>
                ))}
              </MapContainer>
            </div>
          )}
        </div>
      ) : (
        <div style={{display: 'grid', gridTemplateColumns: '1fr 2fr'}}>
          <div
            style={{
              padding: '30px',
              borderRadius: '5px',

              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
            }}>
            <div
              style={{fontSize: '24px', fontWeight: '600', color: '#161616'}}>
              Employees
            </div>
            <div>
              {' '}
              <Search />
            </div>
            {employeeList.map((item, index) => {
              return (
                <div key={index} style={{width: '25vw'}}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    <p
                      style={{
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#161616',
                        margin: '0',
                      }}>
                      {item.employee_name}
                    </p>
                    <button
                      style={{
                        border: '1px solid #008080',
                        background: '#008080',
                        cursor: 'pointer',
                        color: 'white',
                        height: '36px',
                        width: '80px',
                        borderRadius: '5px',
                      }}>
                      Track
                    </button>
                  </div>
                  <div>
                    <img
                      src={duration}
                      alt="duration"
                      width={18}
                      height={18}
                      style={{marginRight: '10px'}}
                    />
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#616161',
                      }}>
                      {item.designation}
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      marginTop: '10px',
                    }}>
                    <img
                      src={mylocation}
                      alt="mylocation"
                      width={18}
                      height={18}
                      style={{marginRight: '10px'}}
                    />
                    <p
                      style={{
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#616161',
                      }}>
                      {item.location}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <MapContainer
            center={[28.7041, 77.1025]}
            zoom={10}
            style={containerStyle}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {peopleLocations.map((person, index) => (
              <Marker
                key={index}
                position={[person.lat, person.lng]}
                icon={customIcon}>
                <Popup>
                  <strong>{person.name}</strong>
                  <br />
                  Role: {person.role}
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      )}
    </div>
  );
}

export default Location;
{
  /* <Marker position={position} icon={customIcon}>
        <Popup>
         Employee LOcation
        </Popup>
      </Marker> */
}
