import React, { useEffect, useState } from 'react';
import styles from "./SalaryOnHoldAndArrears.module.scss"
import { Button, Col, Form, Input, Modal, Row, Select, Table, DatePicker } from "antd";
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import request from '../../../../../utils/axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import CommonTable from '../../../../../component/CommonTable/CommonTable';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

function SalaryOnHoldAndArrears({ processingDate, setSelectProcessingType, reviewed, fetchReviewDetails }) {
    const selectedEmployees = useSelector((state) => state.selectedEmployeePayrollProcessing);
    const [arrearsReview, setArrearsReview] = useState([])
    const [holdForm] = Form.useForm()
    const [allEmployeeHoldSalaryReview, setAllEmployeeHoldSalaryReview] = useState([])
    const [employeeArrearsModal, setEmployeeArrearsModal] = useState(false)
    const [holdSalaryModal, setHoldSalaryModal] = useState(false)
    const [hasMore1, setHasMore1] = useState(true);
    const [hasMore2, setHasMore2] = useState(true);

    const onFinishArrears = async (values) => {
        console.log("issue_date : ", moment(employeeArrears.issue_date).format("YYYY-MM-DD"))

        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/add-arrears`,
                data: {
                    ...employeeArrears,
                    // issue_date: moment(employeeArrears.issue_date).format("YYYY-MM-DD")
                }

            })
            console.log("data : ", data)
            setEmployeeArrearsModal(false);
            const pagination = {
                current: 1,
                pageSize: 5, // Number of items per page
                total: 0,
            }
            const dataList = [];
            fetchArrearsData(pagination, dataList);
        }
        catch (err) {
            console.log(err)
        }
    }
    const [arrearsPagination, setArrearsPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })
    const fetchArrearsData = async (paginationParam, dataList) => {
        const { current } = paginationParam || arrearsPagination;
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/get-all-arrears?page=${current}&page_size=${arrearsPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            if(paginationParam){
                setArrearsReview([
                    ...dataList,
                    ...data?.pagination_data?.data
                ])
            }
            else{
                setArrearsReview([
                    ...arrearsReview,
                    ...data?.pagination_data?.data
                ])
            }
           
            // if (arrearsPagination.total !== data.pagination_data.total) {
            setArrearsPagination({
                ...arrearsPagination,
                total: data.pagination_data.total,
                current: current + 1
            });
            // }
            if (data?.pagination_data?.next === null) {
                setHasMore2(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore2(false);
        }
    }
    const [holdSalaryPagination, setHoldSalaryPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    const fetchemployeesHoldSalaryHistory = async (paginationParam, dataList) => {
        const { current } = paginationParam || holdSalaryPagination;

        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/employees/salary-hold/active?page=${current}&page_size=${holdSalaryPagination.pageSize}&month=${processingDate.month}&year=${processingDate.year}&employees=${selectedEmployees}`,
            })
            console.log(data)
            if(paginationParam){
                setAllEmployeeHoldSalaryReview([
                    ...dataList,
                    ...data?.pagination_data?.data
                ])
            }
            else{
                setAllEmployeeHoldSalaryReview([
                    ...allEmployeeHoldSalaryReview,
                    ...data.pagination_data.data
                ])
            }
            
            // if (holdSalaryPagination.total !== data.pagination_data.total) {
            setHoldSalaryPagination({
                ...holdSalaryPagination,
                total: data.pagination_data.total,
                current: holdSalaryPagination.current + 1
            });
            // }
            if (data?.pagination_data?.next === null) {
                setHasMore1(false);
                return;
            }
        }
        catch (err) {
            console.log(err)
            setHasMore1(false);
        }
    }
    // useEffect(() => {
    //     fetchemployeesHoldSalaryHistory();
    // }, [])
    const arrearsRecordDelete = async (id) => {
        try {
            let data = await request({
                method: "get",
                url: `payroll/admin/delete-employee-arrear?id=${id}`,
            })
            console.log(data)
            fetchArrearsData();
        }
        catch (err) {
            console.log(err)
        }
    }
    const [holdSalaryDetail, setHoldSalaryDetail] = useState({
        employement_number: "",
        name: "",
    })
    const onFinishHoldSalary = async (values) => {
        console.log("HoldSalaryvalues", moment(values.issue_date).format("DD-MM-YYYY"))
        try {
            const data = await request({
                method: "post",
                url: `payroll/admin/employee/salary-hold/add`,
                data: {
                    date: moment(values.issue_date).format("YYYY-MM-DD"),
                    employement_number: holdSalaryDetail.employement_number,
                    name: holdSalaryDetail.name
                }
            })
            setHoldSalaryModal(false);
            const pagination = {
                current: 1,
                pageSize: 5, // Number of items per page
                total: 0,
            }
            const dataList = [];
            fetchemployeesHoldSalaryHistory(pagination, dataList);
            // fetchemployeesHoldSalaryHistory();
            toast.success("Hold salary details added successfully", {
                position: "bottom-left",
            })
        }
        catch (err) {
            console.log(err)
            toast.error(err.response.data.message, {
                position: "bottom-left",
            })
        }

    }

    const salaryHoldDelete = async (id, is_checked) => {
        try {
            let data = await request({
                method: "post",
                url: `payroll/admin/salary-hold/update?id=${id}`,
                data: {
                    is_checked
                }
            })
            console.log(data)
            fetchemployeesHoldSalaryHistory();
            toast.success("Hold salary details updated successfully", {
                position: "bottom-left",
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    // useEffect(() => {
    //     fetchArrearsData();
    //     fetchemployeesHoldSalaryHistory();
    // }, [processingDate])

    const salaryProcessOnHoldColumns = [
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Employee Number",
            dataIndex: "employee_number",
            key: "employee_number",
        },
        {
            title: "Hold Date",
            dataIndex: "hold_date",
            key: "hold_date",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => <DeleteOutlined style={{ color: 'red', fontSize: '20px' }}
                onClick={() => salaryHoldDelete(record.id, false)} />,

        }

    ]
    const salaryPaymentOnHoldColumns = [
        {
            title: "Employee",
            dataIndex: "employee_number",
            key: "employee_number",
        },
        {
            title: "Pay Period",
            dataIndex: "pay_period",
            key: "pay_period",
        },
        {
            title: "Pay Amount",
            dataIndex: "pay_amount",
            key: "pay_amount",
        },
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",

        }
    ]

    const arrearColumns = [
        {
            title: "Employee",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Employee Number",
            dataIndex: "employee",
            key: "employee",
        },
        {
            title: "Date",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "Total Arrear Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Reasons",
            dataIndex: "reason",
            key: "reason",

        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => <DeleteOutlined style={{ color: 'red', fontSize: '20px' }}
                onClick={() => arrearsRecordDelete(record.id)} />,

        }
    ]

    const salaryOnHoldAndArrearsTabs = [
        {
            name: "Salary Processing On Hold",
            key: "salaryProcessingOnHold",
            value: 1,
            reviewed: reviewed?.salary_hold
        },
        {
            name: "Arrears",
            key: "arrears",
            value: 2,
            reviewed: reviewed?.arrears
        }
    ]

    const [selectTab, setSelectTab] = useState(1)
    const [searchEmployeeList, setSearchEmployeeList] = useState([])

    const [employeeArrears, setemployeeArrears] = useState({
        employement_number: "",
        name: "",
        amount: "",
        reason: "",
        issue_date: ""
    })

    const onHandleArrearsChange = (key, value) => {
        setemployeeArrears({
            ...employeeArrears,
            [key]: value
        });
    }

    const onHandleHoldSalaryChange = (key, value) => {
        setemployeeArrears({
            ...holdSalaryDetail,
            [key]: value
        });
    }
    const searchForEmployeeArrears = async (data) => {
        onHandleArrearsChange('name', data)
        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`,
            })

            setSearchEmployeeList(response)
        }
        catch (err) {
            console.log(err);
        }
    }
    const searchForHoldSalary = async (data) => {
        setHoldSalaryDetail({
            ...holdSalaryDetail,
            name: data
        });

        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=admin`,
            })

            setSearchEmployeeList(response)
        }
        catch (err) {
            console.log(err);
        }
    }
    const handleSelectChangeForEmployeeArrears = async (employement_number, name) => {
        try {
            setemployeeArrears({
                ...employeeArrears,
                employement_number,
                name
            });

            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }
    const handleSelectChangeForHoldSalary = async (employement_number, name) => {
        try {
            setHoldSalaryDetail({
                ...holdSalaryDetail,
                employement_number,
                name
            });

            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const payrollSummaryReviewed = async () => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/create-payroll-processing-review-history`,
                data: {
                    payroll_review_tab: "salariesAndArrears",
                    month: processingDate.month,
                    year: processingDate.year
                }
            })
            fetchReviewDetails();
            setSelectProcessingType("overrides")
        }
        catch (error) {
            console.log(error)
        }
    }

    const payrollSummaryChild = async (childName) => {
        try {
            await request({
                method: "post",
                url: `payroll/admin/create-payroll-processing-tabs-history`,
                data: {
                    parent_tab: "salariesAndArrears",
                    child_tab: childName,
                    month: processingDate.month,
                    year: processingDate.year
                }
            })
            fetchReviewDetails();
        }
        catch (error) {
            console.log(error)
        }
    }


    const [shouldFetch1, setShouldFetch1] = useState(false);
    const [shouldFetch2, setShouldFetch2] = useState(false);

    useEffect(() => {
        // 1
        setArrearsPagination({
            ...arrearsPagination,
            current: 1
        });
        setArrearsReview([]);
        setHasMore1(true);
        setShouldFetch1(true);

        // 2
        setHoldSalaryPagination({
            ...holdSalaryPagination,
            current: 1
        });
        setAllEmployeeHoldSalaryReview([]);
        setHasMore2(true);
        setShouldFetch2(true);

    }, [processingDate]);

    useEffect(() => {
        if (shouldFetch1) {
            fetchArrearsData();
            setShouldFetch1(false);
        }
        if (shouldFetch2) {
            fetchemployeesHoldSalaryHistory();
            setShouldFetch2(false);
        }
    }, [shouldFetch1, shouldFetch2]);

    const renderUI1 = (dataList) => {
        return <Table
            columns={salaryProcessOnHoldColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    const renderUI2 = (dataList) => {
        return <Table
            columns={arrearColumns}
            dataSource={dataList}
            pagination={false}
        />
    }

    return (
        <div className={styles.salaryOnHoldAndArrearsContainer}>
            <div className={styles.container}>
                <div className={styles.tabButtonContainer}>
                    {
                        salaryOnHoldAndArrearsTabs.map((item, index) => {
                            return (
                                <div
                                    key={item.key}
                                    className={
                                        item?.reviewed
                                            ?
                                            styles.viewedTabButton + " " + styles.tabButton
                                            : selectTab === item.value ? styles.selectTabButton + " " + styles.tabButton : selectTab > index + 1 ? styles.filledTabButton + " " + styles.tabButton : styles.tabButton}

                                >
                                    <div className={
                                        item?.reviewed
                                            ?
                                            styles.viewedIndexBox
                                            :
                                            selectTab === index + 1 ? styles.filledIndexBox : styles.indexBox} >
                                        <center>
                                            {
                                                item?.reviewed
                                                    ?
                                                    <CheckOutlined />
                                                    :
                                                    // selectTab > index + 1
                                                    //     ?
                                                    //     <CheckOutlined  />
                                                    //     :
                                                    item.value
                                            }

                                        </center>
                                    </div>
                                    {item.name}
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        selectTab === 1
                        &&
                        <>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <button
                                    className={styles.btn}
                                    onClick={() => setHoldSalaryModal(true)}>Create</button>
                                {/* <CommonTable
                                columns={salaryProcessOnHoldColumns}
                                data={allEmployeeHoldSalaryReview}
                                pagination={holdSalaryPagination}
                                setPagination={setHoldSalaryPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                                <button
                                    className={styles.btn}
                                    onClick={() => {
                                        payrollSummaryChild("salary_hold")
                                        setSelectTab(2)
                                    }}>Next</button>
                            </div>

                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={holdSalaryPagination.total - (allEmployeeHoldSalaryReview?.length)}
                                    next={fetchemployeesHoldSalaryHistory}
                                    hasMore={hasMore1}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI1(allEmployeeHoldSalaryReview)
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                    }
                </div>
                {/* <div>
                    {
                        selectTab === 2
                        &&
                        <>

                            <Table
                                columns={salaryPaymentOnHoldColumns}
                                dataSource={[]}
                                pagination={false}
                            />                          
                            <button
                                onClick={() => setSelectTab(1)}
                            >Prev</button>
                            <button
                                onClick={() => setSelectTab(3)}
                            >Next</button>
                        </>
                    }
                </div> */}
                <div>
                    {
                        selectTab === 2
                        &&
                        <>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <button
                                    className={styles.btn}
                                    onClick={() => setEmployeeArrearsModal(true)}>Create</button>
                                {/* <CommonTable
                                columns={arrearColumns}
                                data={arrearsReview}
                                pagination={arrearsPagination}
                                setPagination={setArrearsPagination}
                                handleRowClick={() => { }}
                            // pagination={false}
                            /> */}
                                <div>
                                    <button
                                        className={styles.btn}
                                        onClick={() => setSelectTab(1)}
                                    >Prev</button>

                                    <button className={styles.btn} onClick={() => {
                                        payrollSummaryChild("arrears")
                                        payrollSummaryReviewed()
                                    }}>
                                        Submit
                                    </button>
                                </div>
                            </div>

                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={arrearsPagination.total - (arrearsReview?.length)}
                                    next={fetchArrearsData}
                                    hasMore={hasMore2}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                    className={styles.customScrollbar}
                                >
                                    {
                                        renderUI2(arrearsReview)
                                    }
                                </InfiniteScroll>
                            </div>
                        </>
                    }
                </div>
            </div>
            <Modal
                title="Hold Salary"
                centered
                open={holdSalaryModal}
                // onOk={() => setReviseSalary(false)}
                onCancel={() => {
                    setHoldSalaryModal(false)
                    holdForm.resetFields();
                    setHoldSalaryDetail({
                        employement_number: "",
                        name: "",
                    });
                }}
                width={1000}
                footer={null}>
                <Form layout="vertical"
                    onFinish={onFinishHoldSalary}
                    form={holdForm}
                >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employee Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <div className={styles.searchContainer}>
                                    <Input
                                        size="large"
                                        className={styles.searchInput}
                                        value={holdSalaryDetail.name}
                                        type="text"
                                        placeholder="placeholderText"
                                        onChange={(e) => searchForHoldSalary(e.target.value)}
                                    />
                                    {searchEmployeeList.length > 0 && (
                                        <ul>
                                            {searchEmployeeList.map((item, index) => (
                                                <li key={index} onClick={() => handleSelectChangeForHoldSalary(item.employement_number, item.employee_name)}>
                                                    {item.employee_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Date"
                                name="date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter date!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    className='leaveInputField'
                                    onChange={(date, dateString) => onHandleHoldSalaryChange("date", dateString)}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>

            </Modal>

            <Modal
                title="Add Employee Arrears"
                centered
                open={employeeArrearsModal}
                onCancel={() => setEmployeeArrearsModal(false)}
                width={1000}
                footer={null}
            >
                <Form layout="vertical"
                    onFinish={onFinishArrears} >
                    <Row gutter={[32, 0]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employee Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter employee number!',
                                    },
                                ]}
                            >
                                <div className={styles.searchContainer}>
                                    <Input
                                        size="large"
                                        className={styles.searchInput}
                                        value={employeeArrears.name}
                                        type="text"
                                        placeholder="placeholderText"
                                        onChange={(e) => searchForEmployeeArrears(e.target.value)}
                                    />
                                    {searchEmployeeList.length > 0 && (
                                        <ul>
                                            {searchEmployeeList.map((item, index) => (
                                                <li key={index} onClick={() => handleSelectChangeForEmployeeArrears(item.employement_number, item.employee_name)}>
                                                    {item.employee_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Date"
                                name="issue_date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter date!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    className='leaveInputField'
                                    onChange={(date, dateString) => onHandleArrearsChange("issue_date", dateString)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter amount!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Amount"
                                    onChange={(e) => onHandleArrearsChange("amount", e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Reason"
                                name="reason"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Reason!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter reason"
                                    onChange={(e) => onHandleArrearsChange("reason", e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button style={{ background: "#008080", color: "white", height: "40px" }} htmlType="submit">
                        Submit
                    </Button>
                </Form>

            </Modal>


        </div>
    )
}
export default SalaryOnHoldAndArrears