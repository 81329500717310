import React, { useEffect, useRef, useState } from 'react';
import './CompanyPolicy.scss';
import { Button, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import { request } from '../../../utils/axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { companyPolicyDetails } from "../../../redux/reducer/OnboardingReducer"
import update from "immutability-helper"

function CompanyPolicy({ prev }) {
    const pdfUrl = 'https://www.africau.edu/images/default/sample.pdf';
    let employmentDetails = useSelector((state) => state.onboarding.employmentDetails)
    let personalDetails = useSelector((state) => state.onboarding.personalDetails)
    let bankDetails = useSelector((state) => state.onboarding.bankDetails)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const ref = useRef();

    const [companyPolicyCurrent, setCompanyPolicyCurrent] = useState(0);
    const callApi = async () => {
        try {
            const dob = moment(personalDetails.dob).format('YYYY-MM-DD');
            personalDetails = { ...personalDetails, dob }

            if (personalDetails?.anniversary) {
                const anniversary = moment(personalDetails?.anniversary).format('YYYY-MM-DD');
                personalDetails = { ...personalDetails, anniversary }
            }

            const joining_date = moment(employmentDetails.joining_date).format('YYYY-MM-DD');
            employmentDetails = { ...employmentDetails, joining_date }

            const employee_number = localStorage.getItem("employee_number")
            const user_id = localStorage.getItem("user_id")

            const data = await request({
                method: "put",
                url: `/employee/details/update/${employee_number}/`,
                data: {
                    ...personalDetails,
                    ...bankDetails,
                    user_id
                }
            })

            localStorage.setItem("on_boarding", data.data.on_boarding)
            navigate('/dashboard', { replace: true });
        }
        catch (error) {
            console.error('Failed to post data:', error);
        }

    }

    const [docsList, setDocsList] = useState([]);
    const [signedDocs, setSignedDocs] = useState([]);

    const fetchDocs = async () => {
        try {
            const data = await request({
                method: "get",
                url: `/documentation/get/policy/document?page=1&page_size=2`,
            })

            // setDocsList(data.pagination_data.data)
            setDocsList(
                data.pagination_data.data.map((item, index) => {
                    return {
                        ...item,
                        isSigned: false
                    }
                })
            )
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchDocs();
    }, [])

    const onHandleChange = async (index, docId) => {
        // dispatch(companyPolicyDetails())
        const updatedData = update(docsList, {
            [index]: {
                isSigned: {
                    $set: !docsList[index].isSigned
                }
            }
        })
        setDocsList(updatedData)
        try {
            await request({
                method: "post",
                url: `/documentation/policy/document/signed`,
                data: {
                    sign: true,
                    policy_document: docId
                }
            })
        }
        catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div ref={ref}>
            <div className='flexSpaceBetween' style={{ padding: "20px 0px" }}>
                <p>
                    Company Policy
                </p>
                <p style={{ background: "#F4F4F4", padding: "10px 20px", borderRadius: "4px" }}>
                    {companyPolicyCurrent + 1} / {docsList.length}
                </p>
            </div>
            <div className="pdf-viewer">
                <div className="full-screen-container">

                    <div>
                        <embed
                            src={docsList[companyPolicyCurrent]?.document}
                            // src={pdfUrl}
                            type="application/pdf"
                            width="100%"
                            height="600px"
                        />
                        <div style={{ padding: "20px 0px" }}>
                            <Checkbox
                                // checked={checked}
                                // disabled={disabled}
                                onChange={() => onHandleChange(companyPolicyCurrent, docsList[companyPolicyCurrent].id)}
                            >
                                <p style={{ fontSize: "24px", fontSize: "400", margin: "0px" }}>
                                    I agree to the above Policy
                                </p>
                            </Checkbox>
                        </div>
                    </div>



                </div>
            </div>
            <div style={{ marginTop: "10px", display: "flex", gap: "20px" }}>


                {
                    companyPolicyCurrent === 0 ?
                        <Button style={{ height: "40px", backgroundColor: "#008080", color: "white", padding: "0px 15px" }} onClick={() => prev()} >Previous</Button>
                        : (
                            <Button
                                size='large'
                                onClick={() => setCompanyPolicyCurrent(companyPolicyCurrent - 1)}
                                style={{ height: "40px", backgroundColor: "#008080", color: "white", padding: "0px 15px" }}
                                disabled={companyPolicyCurrent <= 0}
                            >
                                Previous
                            </Button>
                        )
                }
                {
                    companyPolicyCurrent < docsList.length - 1 ?
                        <Button
                            size='large'
                            onClick={() => setCompanyPolicyCurrent(companyPolicyCurrent + 1)}
                            style={{ color: "white", background: "#008080" }}
                        >
                            Continue
                        </Button>
                        : (
                            <Button
                                size='large'
                                onClick={() => callApi()}
                                style={{ color: "white", background: "#008080" }}
                            >
                                Submit
                            </Button>
                        )
                }
            </div>
        </div>
    )
}

export default CompanyPolicy