import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import forgotImg from "../../assets/Attendance/forgotImg.svg"
import { useNavigate, useParams } from 'react-router-dom';
import request from '../../utils/axios';
import login from "../../assets/login.png";

function ChangePassword() {
    const [changePasswordModel, setForgotPasswordModel] = useState(true)
    const [resetSuccessfullModel, setResetSuccessfullModel] = useState(false)
    const navigate = useNavigate();
    const { secure_token } = useParams();

    const changePassword = async (value) => {
        try {
            if (value.new_password !== value.confirm_new_password) {
                toast.error("Password does not match");
                return
            }
            else {
                const data = await request({
                    method: "post",
                    url: `/users/reset-password/`,
                    data: {
                        secure_token: secure_token,
                        new_password: value.new_password
                    }
                })
            }
            setResetSuccessfullModel(true)
            setForgotPasswordModel(false)
            navigate("/login", { replace: true })
            toast.success("Password changed successfully", {
                position: "bottom-left",
            })
        }
        catch (err) {
           
            // forEach
            for(const x in err.response.data.message){
                console.log(x,": ",err.response.data.message[x])
                toast.error(err.response.data.message[x], {
                    position: "bottom-left",
                });
            }

            // console.log(err.response.data.message)

        }
        finally {
            // setForgotPasswordModel(false)
        }
    }

    return (
        <div>
            <div style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <img
                    src={login}
                    alt="login_image"
                    className='loginImg'
                    style={{ width: "50%" }}
                />
            </div>


            <Modal
                centered
                open={changePasswordModel}
                footer={null}
                onOk={() => setForgotPasswordModel(false)}
                onCancel={() => {
                    setForgotPasswordModel(false)

                }}
            >
                <p className='forgotPasswordText'>Change Password</p>
                <Form
                    layout="vertical"
                    onFinish={changePassword}
                    autoComplete="on"
                >
                    <Form.Item
                        label="Update Password"
                        name="new_password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your email!',
                            },
                        ]}
                    >
                        <Input.Password
                            type="password"
                            size='large'
                            placeholder='Enter New Password'
                            autoComplete='on'
                        />
                    </Form.Item>
                    <Form.Item
                        label="Confirm new Password"
                        name="confirm_new_password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your email!',
                            },
                        ]}
                    >
                        <Input.Password
                            type="password"
                            size='large'
                            placeholder='Enter New Password'
                            autoComplete='on'
                        />
                    </Form.Item>

                    <Button
                        htmlType='submit'
                        className='forgotPasswordButton'
                    >
                        Submit
                    </Button>
                </Form>
            </Modal>

            <Modal
                centered
                open={resetSuccessfullModel}
                footer={null}
                onOk={() => setResetSuccessfullModel(false)}
                onCancel={() => {
                    setResetSuccessfullModel(false)
                    navigate('/login', { replace: true });
                }}
            >
                <center>
                    <img src={forgotImg} alt="forgotImg" />
                    <p>An Email Has been Sent To You With Reset Password</p>
                </center>
            </Modal>
        </div>
    )
}

export default ChangePassword