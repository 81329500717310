import React, { useEffect, useState } from 'react';
import styles from "./HrLetters.module.scss"
import request from '../../../utils/axios';
import { DownloadOutlined, EyeFilled } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

function HrLetters() {
  const params = useParams();
  const [hrLetterList, setHRLetterList] = useState([])
  const fetchHRLetters = async () => {
    try {
      if (params.id) {
        const data = await request({
          method: "get",
          url: `/admin/profile/hr-letters?employement_number=${params.id}`,
        })

        setHRLetterList(data.data)
      }
      else {
        const data = await request({
          method: "get",
          url: `/profile/hr-letters`,
        })

        setHRLetterList(data.data)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchHRLetters()
  }, [])

  return (
    <div className={styles.hrLettersDetailsContainer}>
      <div className={styles.hrLettersDetailsInnerContainer}>
        <p className={styles.heading}>HR Letters</p>

        {
          hrLetterList?.map((document, index) => {
            return (
              <div style={{ display: "flex", justifyContent: "space-between", width: "50%" }}>
                <p style={{
                  fontSize: "20px",
                  color: "#161616",
                  fontWeight: "400"
                }}>{document.name}</p>

                <div style={{ display: "flex", gap: "30px" }}>
                  <EyeFilled style={{ fontSize: "25px", color: "#757575" }}
                    onClick={() => {
                      window.open(document.document, "_blank");
                    }}
                  />
                  <DownloadOutlined style={{ fontSize: "25px", color: "#757575" }}
                    onClick={async () => {
                      const documentUrl = document.document;

                      try {
                        const response = await request({
                          method: "get",
                          url: documentUrl,
                          responseType: 'arraybuffer',
                        });

                        const file = new Blob([response.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(file);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = 'file.pdf';
                        link.click();
                      } catch (error) {
                        console.error('Error downloading PDF:', error);
                      }
                    }}
                  />
                </div>
              </div>
            )
          })
        }

      </div>
    </div>
  )
}

export default HrLetters