import { LinkOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from "./TaxFreeAllowance.module.scss"
import request from '../../../../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { setTaxFreeDeclarationState, setTaxFreeDeclaration } from '../../../../../redux/reducer/TaxDeclarationReducer';

function TaxFreeAllowance({ windowOpen }) {
    const dispatch = useDispatch();
    const taxFreeAllowance = useSelector(state => state.taxDeclaration.taxFreeAllowance)

    const [taxFreeAllowanceList, setTaxFreeAllowanceList] = useState([])

    const fetchTaxFreeAllowanceList = async () => {
        try {
            const data = await request({
                method: "GET",
                url: `regim_tax_rules/get-taxfree-allowance-components`,
            })
            setTaxFreeAllowanceList(data.data)
            const dynamicStatesObject = {};
            data.data.map(item => {
                dynamicStatesObject[item.value] = {
                    ...item
                }
            })
            dispatch(setTaxFreeDeclarationState(dynamicStatesObject));
        }
        catch (error) {

        }
    }
    useEffect(() => {
        fetchTaxFreeAllowanceList()
    }, [])

    const onHandleChange = (section, key, value) => {

        if (key === "attachment") {

            const reader = new FileReader();
            reader.readAsDataURL(value);
            reader.onload = () => {
                dispatch(setTaxFreeDeclaration({
                    ...taxFreeAllowance[section],
                    id: section,
                    [key]: reader.result,
                    file_name: value.name
                }))

            };
            reader.onerror = (error) => {
                console.error('Error uploading image: ', error);
            };
        }
        else {
            dispatch(setTaxFreeDeclaration({
                ...taxFreeAllowance[section],
                id: section,
                [key]: value
            }))
        }
    }



    return (
        <div className={styles.taxFreeAllowance}>
            <p className={styles.taxFreeAllowanceText}>Sec10 (Tax free Allowances)</p>
            <div>
                <Form>
                    <Row gutter={32}>
                        {
                            taxFreeAllowanceList.map((item, index) => {

                                return (
                                    <>
                                        <Col span={24}>
                                            <div className={styles.section10TextBox}>
                                                <p className={styles.section10Text}>{item.componant_name} (QUALIFIED AMOUNT: {item.eligible_amount} INR)</p>
                                            </div>
                                        </Col>
                                        <Col span={24}>

                                            <Row gutter={32}>
                                                <Col span={6}>
                                                    <Form.Item>
                                                        <Input size='large'
                                                            placeholder='Enter Amount'
                                                            value={taxFreeAllowance[item.value]?.declared_amount}
                                                            onChange={(e) => onHandleChange(item.value, "declared_amount", e.target.value)}
                                                            disabled={windowOpen}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={10}>
                                                    <Form.Item>
                                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                            <div
                                                                className={styles.attachFileBox}
                                                            >
                                                                <input
                                                                    type="file"
                                                                    id="file-upload"
                                                                    className={styles.inputFile}
                                                                    onChange={(e) => {
                                                                        const selectedFile = e.target.files[0];

                                                                        if (selectedFile) {
                                                                            onHandleChange(item.value, "attachment", selectedFile)
                                                                        }
                                                                    }
                                                                    }
                                                                    disabled={windowOpen}
                                                                />
                                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>

                                                                    <label htmlFor="file-upload" >

                                                                    </label>
                                                                    <p
                                                                        className={styles.attachFileLabel}
                                                                        style={{ marginTop: "7px" }}>
                                                                        <LinkOutlined /> {" "}Attach File
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <p style={{ margin: '0' }}>{taxFreeAllowance[item.value]?.file_name}</p>
                                                        </div>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </>
                                )
                            })
                        }

                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default TaxFreeAllowance