import React, { useEffect, useState } from 'react'
import request from '../../../../utils/axios'
import desktopStyle from "./TaxSummaryFullView.module.scss"
import mobileStyle from "./MobileTaxSummaryFullView.module.scss";
import getScreenSize from "../../../../utils/getScreenSize";
import { Col, Radio, Row, Select } from 'antd';
import { toast } from 'react-toastify';

function TaxSummaryFullView() {
    const screenSize = getScreenSize();
    const [employeeTaxSummary, setEmployeeTaxSummary] = useState()
    const [taxRegime, setTaxRegime] = useState(0)
    const [taxSummaryType, setTaxSummaryType] = useState("declaration")
    const [isWindowOpen, setIsWindowOpen] = useState()

    const taxSummaryForDeclaration = async () => {
        try {
            let data = await request({
                method: "get",
                url: `regim_tax_rules/declaration-tax_summary_for_employee`,
            })

            setTaxRegime(data?.regim_type)
            setEmployeeTaxSummary(data?.data)
            setIsWindowOpen(data?.is_window_open)
        }
        catch (err) {
            console.log(err)
        }
    }

    const taxSummaryApprovedDeclaration = async () => {
        try {
            let data = await request({
                method: "get",
                url: `regim_tax_rules/approved-tax_summary_for_employee`,
            })
            console.log(data)
            setEmployeeTaxSummary(data?.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    const [taxDeclarationSummary, setTaxDecalarationSummary] = useState();
    const fetchTaxDeclarationSummary = async () => {
        try {
            let data = await request({
                method: "get",
                url: `investment-declaration/investment-declaration-summary`,
            })
            setTaxDecalarationSummary(data.data)

        }
        catch (error) {
            console.log("error")
        }
    }

    const fetchTaxApprovedSummary = async () => {
        try {
            let data = await request({
                method: "get",
                url: `investment-declaration/investment-approved-summary`,
            })
            setTaxDecalarationSummary(data.data)

        }
        catch (error) {
            console.log("error")
        }
    }

    const sendTaxRegime = async (value) => {
        try {
            await request({
                method: "post",
                url: `regim_tax_rules/employee-regim-selection`,
                data: { regim_type: value }
            })

            toast.success("Regime declaration window updated succesfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log(error)
            toast.error("Regime declaration window could not be updated, please try after some time", {
                position: "bottom-left",
            })
        }
    }

    useEffect(() => {
        if (taxSummaryType === "declaration") {
            taxSummaryForDeclaration();
            fetchTaxDeclarationSummary();
        }
        else {
            fetchTaxApprovedSummary();
            taxSummaryApprovedDeclaration();
        }
    }, [taxSummaryType])

    return (
        <div>
            {
                screenSize.width < 768
                    ?
                    // mobile view
                    <div style={{ background: "white", padding: "20px", borderRadius: "4px" }}>
                        <div className='flexSpaceBetween'>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0" }}>Tax Summary</p>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <Radio.Group
                                size='large'
                                options={[
                                    {
                                        label: 'New Regime',
                                        value: 0,
                                    },
                                    {
                                        label: 'Old Regime',
                                        value: 1,
                                    }
                                ]}
                                onChange={(e) => {
                                    setTaxRegime(e.target.value)
                                    sendTaxRegime(e.target.value)
                                }}
                                value={taxRegime}
                                optionType="button"
                                disabled={!isWindowOpen}
                            />
                        </div>
                        <div className='flexSpaceBetween' style={{ marginTop: "20px" }}>
                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Total CTC</p>
                            <p style={{ fontSize: "18px", fontWeight: "600", color: "#333333" }}>₹{employeeTaxSummary?.total_ctc || 0}</p>
                        </div>
                        <div className='flexSpaceBetween'>
                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Net Taxable Income</p>
                            <p style={{ fontSize: "18px", fontWeight: "600", color: "#333333" }}>₹{employeeTaxSummary?.net_taxable_income || 0}</p>
                        </div>
                        <div className='flexSpaceBetween'>
                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Total Income Tax</p>
                            <p style={{ fontSize: "18px", fontWeight: "600", color: "#333333" }}>₹{employeeTaxSummary?.total_income_tax || 0}</p>
                        </div>
                    </div>
                    :
                    // desktop view
                    <div className={desktopStyle.container}>
                        {/* <p className={desktopStyle.containerHeading}>Compensation</p> */}
                        <div className={desktopStyle.innerContainer}>
                            <div className='flexSpaceBetween'>
                                <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
                                    <p style={{ fontSize: "24px", fontWeight: "600", color: "#161616", margin: "0" }}>Tax Summary</p>
                                    <Radio.Group
                                        size='large'
                                        options={[
                                            {
                                                label: 'New Regime',
                                                value: 0,
                                            },
                                            {
                                                label: 'Old Regime',
                                                value: 1,
                                            }
                                        ]}
                                        onChange={(e) => {
                                            setTaxRegime(e.target.value)
                                            sendTaxRegime(e.target.value)
                                        }}
                                        value={taxRegime}
                                        optionType="button"
                                        disabled={!isWindowOpen}
                                    />
                                </div>
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <Select defaultValue={taxSummaryType} style={{ width: 120 }}
                                        onChange={(e) => {
                                            setTaxSummaryType(e)
                                        }}
                                    >
                                        <Select.Option value="approved">Approved</Select.Option>
                                        <Select.Option value="declaration">Declared</Select.Option>
                                    </Select>
                                </div>
                            </div>
                            <Row>
                                <Col span={12}>
                                    <div className='flexSpaceBetween' style={{ marginTop: "20px" }}>
                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Total CTC</p>
                                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#333333" }}>₹{employeeTaxSummary?.total_ctc || 0}</p>
                                    </div>
                                    <div className='flexSpaceBetween'>
                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Net Taxable Income</p>
                                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#333333" }}>₹{employeeTaxSummary?.net_taxable_income || 0}</p>
                                    </div>
                                    <div className='flexSpaceBetween'>
                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Total Income Tax</p>
                                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#333333" }}>₹{employeeTaxSummary?.total_income_tax || 0}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

            }

        </div>
    )
}

export default TaxSummaryFullView