import React, { useEffect, useRef, useState } from 'react'
import edit from "../../../../assets/edit.svg"
import back from "../../../../assets/Recruitment/back.svg"
import request from '../../../../utils/axios'
import { Button, Form, Modal, Select, Table } from 'antd'
import { useNavigate } from 'react-router-dom';
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination'
import EmployeeAttendancePolicyManagerSearchBar from '../EmployeeAttendancePolicyManagerSearchBar/EmployeeAttendancePolicyManagerSearchBar'
import CommonTable from '../../../../component/CommonTable/CommonTable';
import getScreenSize from '../../../../utils/getScreenSize';
import mobileStyles from "./MobileEmployeeAttendancePolicyManagerFullView.module.scss"
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll'
import InfiniteScroll from 'react-infinite-scroll-component'
function EmployeeAttendancePolicyManagerFullView() {
    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const ref = useRef(null);
    const [hasMore, setHasMore] = useState(true);

    const employeeAttendancePolicyMangerColumns = [
        {
            title: 'Id',
            dataIndex: 'employement_number',
            key: 'employement_number',
        },
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Work Policy',
            dataIndex: 'work_policy',
            key: 'work_policy',
        },
        {
            title: 'Action',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, record) => <img src={edit} alt="edit" width={15} height={15} onClick={() => {
                setSelectedEmployeeAttendancePolicyManager({
                    ...selectedEmployeeAttendancePolicyManager,
                    user_id: record.employement_number,
                    policy_id: +record.policy_id
                })
                setChangeAttendancePolicyModel(true)
            }} />
        }
    ]

    const [changeAttendancePolicyModel, setChangeAttendancePolicyModel] = useState(false)
    const [selectedEmployeeAttendancePolicyManager, setSelectedEmployeeAttendancePolicyManager] = useState({
        user_id: "",
        policy_id: ""
    })
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 12, // Number of items per page
        total: 0,
    })

    const [employeeAttendancePolicyMangerList, setEmployeeAttendancePolicyMangerList] = useState([])

    const fetchEmployeeAttendancePolicyManger = async (pageParams, dataList) => {
        try {
            const { current } = pageParams || pagination;
            const data = await request({
                method: "get",
                url: `attendance/admin/attendance-policy-management/?page=${current}&page_size=${pagination.pageSize}`,
            })
            if (pageParams) {
                setEmployeeAttendancePolicyMangerList([...dataList, ...data.pagination_data.data])
            }
            else {
                setEmployeeAttendancePolicyMangerList([...employeeAttendancePolicyMangerList, ...data.pagination_data.data])
            }

            setPagination({
                ...pagination,
                total: data?.pagination_data?.total,
                current: current + 1,
            })
            if (data?.pagination_data?.next === null) {
                setHasMore(false);
                return;
            }
            return data;
        } catch (error) {
            console.log(error)
            setHasMore(false);
        }
    }



    const [attendancePolicy, setAttendancePolicy] = useState([])

    const getAttendancePolicy = async () => {
        let data = await request({
            method: "get",
            url: `get-all-attendance-policy/`,
        })
        setAttendancePolicy(data.data)
    }

    useEffect(() => {
        getAttendancePolicy()
    }, [])

    useEffect(() => {
        if (screenSize.width > 768) {
            fetchEmployeeAttendancePolicyManger()
        }
    }, [])

    const updateEmployeeAttendancePolicy = async (values) => {
        try {
            const response = await request({
                method: "post",
                url: `attendance/admin/employee/attendance-policy/update?employement_number=${selectedEmployeeAttendancePolicyManager.user_id}`,
                data: {
                    policy_id: values.attendance_policy
                }
            });
            console.log("response", response);
            setChangeAttendancePolicyModel(false)
            fetchEmployeeAttendancePolicyManger();
        }
        catch (err) {
            console.log("err", err);
        }
    }

    // useEffect(() => {
    //     ref?.current?.scrollIntoView({ behavior: 'instant' })
    //     document.body.scrollTop = document.documentElement.scrollTop = 0;
    // }, []);

    const renderUI = (dataList) => {
        return dataList.map((item, index) => {
            return (
                <div>
                    <div className={mobileStyles.cardContainer} style={{ marginBottom: "10px" }}>
                        <p className={mobileStyles.value} style={{ margin: "0px", fontWeight: "600" }}>{item.employee_name}</p>
                        <img src={edit} alt="edit" onClick={() => {
                            setSelectedEmployeeAttendancePolicyManager({
                                ...selectedEmployeeAttendancePolicyManager,
                                user_id: item.employement_number,
                                policy_id: +item.policy_id
                            })
                            setChangeAttendancePolicyModel(true)
                        }} />
                    </div>
                    <div className={mobileStyles.cardContainer}>
                        <p className={mobileStyles.label}>Employee ID</p>
                        <p className={mobileStyles.value}>{item.employement_number}</p>
                    </div>
                    <div className={mobileStyles.cardContainer}>
                        <p className={mobileStyles.label}>Work Policy</p>
                        <p className={mobileStyles.value}>{item.work_policy}</p>
                    </div>
                    {/* <div className={mobileStyles.cardContainer}>
                        <p className={mobileStyles.label}>Action</p>

                    </div> */}
                    {
                        employeeAttendancePolicyMangerList.length - 1 !== index && <hr style={{ border: "1px solid #F0F0F0", width: "100%" }} />
                    }
                </div>
            )
        })
    }

    const renderUIDesktop = (dataList) => {
        return <Table
            columns={employeeAttendancePolicyMangerColumns}
            dataSource={dataList}
            pagination={false}
            sticky={{ offsetHeader: 0 }}
        />
    }


    return (
        <div ref={ref}>
            {
                screenSize.width < 768
                    ?
                    <div className={mobileStyles.employeeAttendancePolicyManager}>
                        <div className={mobileStyles.headingContainer}>
                            <p className={mobileStyles.heading}>
                                Employee Attendance Policy Manager
                            </p>

                        </div>
                        <InfiniteScrollComponent
                            apiEndpoint="attendance/admin/attendance-policy-management/"
                            renderUI={renderUI}
                            initialPage={1}
                        />
                    </div>
                    :
                    <div style={{ padding: "50px" }}>
                        {/* <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                            <img src={back} alt="back" />
                            <span style={{ paddingLeft: "10px" }}>Back</span>
                        </p>
                        <h1>Attendance</h1> */}
                        <div style={{ padding: "30px", background: "white" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", gap: "20px", alignItems: "center", paddingBottom: "20px" }}>
                                    <p
                                        style={{ fontSize: "20px", fontWeight: "600", margin: "0px", padding: "0px" }}
                                    >Employee Attendance Policy Manager</p>
                                    <EmployeeAttendancePolicyManagerSearchBar
                                        setTableData={setEmployeeAttendancePolicyMangerList}
                                        setHasMore={setHasMore}
                                        fetchTableDataFromApi={fetchEmployeeAttendancePolicyManger}
                                        setPagination={setPagination}
                                        placeholderText="Search for employees"
                                        empType="admin"
                                    />

                                </div>
                                <button
                                    style={{ width: "120px", height: "40px", background: "#008080", color: "white", border: "none", borderRadius: "5px", cursor: "pointer" }}
                                    onClick={() => navigate("/admin/attendance/bulk-attendance-policy")}
                                >
                                    Bulk Update
                                </button>

                            </div>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "auto",
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={pagination.total - (employeeAttendancePolicyMangerList.length)}
                                    next={fetchEmployeeAttendancePolicyManger}
                                    hasMore={hasMore}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    loader={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Loading...</b>
                                        </p>
                                    }
                                    scrollableTarget="scrollableDiv"
                                    height="70vh"
                                >
                                    {
                                        renderUIDesktop(employeeAttendancePolicyMangerList)
                                    }
                                </InfiniteScroll>
                            </div>
                            {/* <CommonTable
                                columns={employeeAttendancePolicyMangerColumns}
                                data={employeeAttendancePolicyMangerList}
                                pagination={pagination}
                                setPagination={setPagination}
                                handleRowClick={() => { }}
                            /> */}
                        </div>


                    </div>
            }
            <Modal
                title="Change Attendance Policy"
                centered
                open={changeAttendancePolicyModel}
                onOk={() => setChangeAttendancePolicyModel(false)}
                onCancel={() => setChangeAttendancePolicyModel(false)}
                footer={null}
            >
                <Form
                    layout="vertical"
                    // initialValues={policies}
                    onFinish={updateEmployeeAttendancePolicy}
                    fields={[
                        {
                            name: "attendance_policy",
                            value: selectedEmployeeAttendancePolicyManager.policy_id
                        }
                    ]}
                >
                    <Form.Item
                        label="Attendance Policy"
                        name="attendance_policy"
                        rules={[
                            {
                                required: true,
                                message: 'Please Select attendance_policytype!',
                            },
                        ]}
                    >
                        <Select
                            size='large'
                        >
                            {
                                attendancePolicy.map((item, index) => {
                                    return (
                                        <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                    )
                                }
                                )
                            }
                        </Select>

                    </Form.Item>
                    <Button htmlType="submit" style={{ background: "#008080", color: "white" }}>Update</Button>
                </Form>
            </Modal>
        </div>

    )
}

export default EmployeeAttendancePolicyManagerFullView