import React, { useEffect, useState } from 'react'
import CommonTable from '../../../../../component/CommonTable/CommonTable'
import { Button, Form, Modal, Select, Switch } from 'antd'
import useScreenSize from '../../../../../utils/getScreenSize'
import edit from "../../../../../assets/edit.svg"
import request from '../../../../../utils/axios'
import { toast } from 'react-toastify'

function MailSetting() {
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })
    const [openModal, setOpenModal] = useState(false)
    const screenSize = useScreenSize();

    const [editDetails, setEditDetails] = useState({
        id: "",
        frequency: "",
        mail_to: ""
    })

    const mailSettingcolumns = [
        {
            title: 'Mail Type',
            dataIndex: 'mail_type',
            key: 'mail_type',
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
        },
        {
            title: 'To',
            dataIndex: 'mail_to',
            key: 'mail_to',
        },
        {
            title: 'Action',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, record) => (
                <img src={edit} alt="edit" width={20} height={20} onClick={() => {
                    setOpenModal(true)
                    setEditDetails({
                        ...editDetails,
                        id: record.id,
                        frequency: record.frequency_id,
                        mail_to: record.mail_to_id
                    })
                }} />
            ),
        },
        {
            title: "On/off",
            dataIndex: "is_on",
            key: "is_on",
            render: (text, record) => (

                <Switch defaultChecked={record.is_on} onChange={(e) => onSwitchChange(e, record)} />

            )
        }

    ]

    const onSwitchChange = async (e, record) => {
        try {
            const response = await request({
                method: "post",
                url: `mails/admin/attendance/mail-setting/activate?id=${record.id}`,
                data: {
                    is_on: e
                }
            })
            fetchMailData()
            if (e === true) {
                toast.success("Mail Setting Switched On successfully", {
                    position: "bottom-left",
                })
            }
            else {
                toast.success("Mail Setting Switched Off successfully", {
                    position: "bottom-left",
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const [mailData, setMailData] = useState([]);
    const fetchMailData = async () => {
        const response = await request({
            method: "get",
            url: `mails/admin/attendance/mail-setting`,
        })
        setMailData(response?.data)

    }

    const onFinish = async (values) => {
        try {
            const response = await request({
                method: "post",
                url: `mails/admin/attendance/mail-setting/edit?id=${editDetails.id}`,
                data: values
            })

            setOpenModal(false)
            fetchMailData()
            toast.success("Mail Setting updated successfully", {
                position: "bottom-left",
            })
        }
        catch (err) {
            toast.error("Failed to edit Mail Setting. Please try after some time.", {
                position: "bottom-left",
            })
        }


    }

    useEffect(() => {
        fetchMailData()
    }, [])

    const renderUI = () => {
        return (
            <div>
                {
                    mailData?.map((item, index) => {
                        return (
                            <div style={{ padding: "0px 16px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                                    <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616", margin: "0px" }}>{item?.mail_type}</p>
                                    <img src={edit} alt="edit" width={20} height={20}
                                        onClick={() => {
                                            setOpenModal(true)
                                            setEditDetails({
                                                ...editDetails,
                                                id: item.id,
                                                frequency: item.frequency_id,
                                                mail_to: item.mail_to_id
                                            })
                                        }}
                                    />
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <p style={{ fontSize: "16px", fontWeight: "400", color: "#4A4A4A" }}>Frequency </p>
                                    <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>{item?.frequency}</p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <p style={{ fontSize: "16px", fontWeight: "400", color: "#4A4A4A" }}>To</p>
                                    <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>{item?.mail_to}</p>
                                </div>
                            </div>
                        )
                    })
                }

                <hr />
            </div>
        )
    }


    return (
        <div>
            {
                screenSize.width < 768 ?  // mobile view
                    <div>
                        {
                            renderUI()
                        }
                    </div>
                    :
                    //   desktop view
                    <div style={{ marginTop: "2vh" }}>
                        <CommonTable
                            columns={mailSettingcolumns}
                            data={mailData}
                            pagination={pagination}
                            setPagination={setPagination}
                            handleRowClick={() => { }}
                        />
                    </div>
            }


            <Modal
                centered
                open={openModal}
                onOk={() => setOpenModal(false)}
                onCancel={() => setOpenModal(false)}
                footer={null}
                width={600}
            >
                <p style={{ fontSize: "24px" }}>Edit</p>
                <Form
                    layout="vertical"
                    // initialValues={policies}
                    onFinish={onFinish}
                    fields={[
                        {
                            name: "frequency",
                            value: editDetails.frequency
                        },
                        {
                            name: "mail_to",
                            value: editDetails.mail_to
                        }
                    ]}
                >
                    <Form.Item
                        label="Frequency setting"
                        name="frequency"
                        rules={[
                            {
                                required: true,
                                message: 'Please Select attendance_policytype!',
                            },
                        ]}
                    >
                        <Select
                            size='large'
                        >
                            <Select.Option value={1}>Daily</Select.Option>
                            <Select.Option value={2}>Weekly</Select.Option>
                            <Select.Option value={3}>Monthly</Select.Option>
                        </Select>

                    </Form.Item>
                    <Form.Item
                        label="To"
                        name="mail_to"
                        rules={[
                            {
                                required: true,
                                message: 'Please Select attendance_policytype!',
                            },
                        ]}
                    >
                        <Select
                            size='large'
                        >
                            <Select.Option value={1}>Admin</Select.Option>
                            <Select.Option value={2}>Manager</Select.Option>
                            <Select.Option value={3}>Both</Select.Option>
                        </Select>

                    </Form.Item>
                    <Button htmlType="submit" style={{ background: "#008080", color: "white" }}>Save</Button>
                </Form>
            </Modal>
        </div>
    )
}

export default MailSetting