import { Button, Form, Input, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import request from '../../../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function CompOffForm() {
    const [form] = Form.useForm();
    const [employeeList, setEmployeeList] = useState([]);
    const [compOffLeaveTypeList, setCompOffLeaveTypeList] = useState([]);
    const navigate = useNavigate();
    const ref = useRef(null);
    const onFinishCompOff = async (values) => {
        try {
            const response = await request({
                method: "post",
                url: `leave/employee/leave-type/add/`,
                data: values
            });
            toast.success("Comp off Added successfully", {
                position: "bottom-left",
            });
            navigate("/admin/comp-off/full-view", { replace: true });

        } catch (error) {
            console.log("error", error);
        }
    };

    const fetchEmployeeList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `/get-all-employees`,
            });

            setEmployeeList(response?.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const fetchCompOffLeaveTypeList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `leave/admin/comp-off/leaves-types/all`,
            });

            setCompOffLeaveTypeList(response?.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        fetchEmployeeList();
        fetchCompOffLeaveTypeList();
    }, []);

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div style={{ padding: "0px 16px", marginTop: "80px" }} ref={ref}>

            <p className="leavePolicyModelHeading">Comp Off</p>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinishCompOff}
            >
                <Form.Item
                    label="Employee Name"
                    name="employee"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your Employee Name!",
                        },
                    ]}
                >
                    <Select
                        size="large"
                        style={{ width: "100%" }}
                    >
                        {employeeList.map((option, index) => (
                            <Select.Option key={index} value={option.employement_number}>
                                {option.employee_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Leave Type"
                    name="leave_type"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your Leave Type!",
                        },
                    ]}
                >
                    <Select
                        size="large"
                        style={{ width: "100%" }}
                    >
                        {compOffLeaveTypeList.map((option, index) => (
                            <Select.Option key={index} value={option.id}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Number Of Comp Off"
                    name="leaves_number"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your Number Of Comp Off!",
                        },
                    ]}
                >
                    <Input
                        size="large"
                    />
                </Form.Item>
                <Button
                    htmlType="submit"
                    style={{
                        width: "100%",
                        color: "white",
                        background: "#008080",
                        height: "44px",
                        marginTop: "40px",
                    }}
                >
                    Submit
                </Button>
            </Form>
        </div>
    )
}

export default CompOffForm