import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import request from '../../../utils/axios';

import "../AddOrEditAdmin/AddorEditAdmin.scss"
import { useNavigate, useParams } from 'react-router-dom';
function AddOrEditAdminMobile({ btn, adminId, reload }) {
    const { useForm } = Form;
    const [form] = useForm();
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    const [employeeList, setEmployeeList] = useState([])
    const [moduleList, setModuleList] = useState([])
    const [updatesMade, setUpdatesMade] = useState(false);

    const params = useParams();
    const navigate = useNavigate();

    const getEmployeeList = async () => {
        let data = await request({
            method: "get",
            url: `get-all-employees`,
        })
        setEmployeeList(data.data)
    }

    const getModuleList = async () => {
        let data = await request({
            method: "get",
            url: `module/all`,
        })
        setModuleList(data.data)
    }

    const getAdminDetail = async () => {
        let data = await request({
            method: "get",
            url: `org/admin/${params.id}/details`,
        })
        form.setFieldsValue(data.data.data)
    }
    const onFinish = async (values) => {

        try {
            if (params.id) {
                await request({
                    method: "put",
                    url: `org/admin/${params.id}/update/`,
                    data: values
                })
            }
            else {
                await request({
                    method: "post",
                    url: `org/admin/create`,
                    data: values
                })
            }
            setOpen(false)
            form.resetFields();
            navigate("/admin/org", { replace: true })
        }
        catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        getEmployeeList()
        getModuleList()
        if (params.id) {
            getAdminDetail()
        }
    }, [])

    const handleFormChange = () => {
        setUpdatesMade(true)
    }

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div
            style={{
                padding: "16px"
            }}
            ref={ref}
        >
            <div className='addOrEditAdmin'>
                <p style={{ fontSize: "24px", fontWeight: "500" }}>Admin</p>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onValuesChange={handleFormChange}
                >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Employee Name"
                                name="employee"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your employee name!',
                                    },
                                ]}
                            >
                                {
                                    params?.id
                                        ?
                                        <Input disabled className='inputField' />
                                        :
                                        <Select
                                            className='dropDown'
                                            disabled={params?.id}
                                        >
                                            {
                                                employeeList.map((item, index) => {
                                                    return (
                                                        <Select.Option key={index} value={item.employement_number}>{item.employee_name}</Select.Option>
                                                    )
                                                }
                                                )
                                            }
                                        </Select>
                                }

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Module"
                                name="role"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your role!',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    // className='multiSelectDropDown'
                                    style={{
                                        width: "100%",
                                        minHeight: "40px",
                                        maxHeight: "auto",
                                    }}
                                    showSearch={false}
                                >
                                    {
                                        moduleList.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                            )
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button
                        style={{ width: "100%", height: "36px", background: "#008080", color: "white", fontSize: "16px", marginTop: "20px" }}
                        htmlType='submit'
                        disabled={params?.id && !updatesMade}
                    >
                        {/* {type} */}
                        Submit
                    </Button>

                </Form>
            </div>

        </div>
    );
}

export default AddOrEditAdminMobile

