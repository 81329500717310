import {
  Col,
  Image,
  Row,
  Select,
  Tag,
  Switch,
  Button,
  Table,
  DatePicker,
  Menu,
  Dropdown,
  Modal,
  MenuProps,
  Popover,
  TimePicker,
  Form,
  Spin,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./AttendanceView.scss";
import "../../../../styles/_global.scss";
import averageDuration from "../../../../assets/averageDuration.svg";
import totalLeave from "../../../../assets/Attendance/totalLeave.svg";
import workingDays from "../../../../assets/Attendance/workingDays.svg";
import door from "../../../../assets/door.svg";
import arrowCircle from "../../../../assets/fullView.svg";
import Vector from "../../../../assets/Vector.svg";
import weight from "../../../../assets/weight.svg";
import totalLeaves from "../../../../assets/totalLeaves.png";
import totalWorkingDays from "../../../../assets/totalWorkingDays.png"

import workingPolicy from "../../../../assets/workingPolicy.svg";
// import calendar from "../../../../assets/Attendance/calendar.svg"
import fullView from "../../../../assets/fullView.svg";
import CalendarScheduler from "../../../../component/CalendarScheduler/CalendarScheduler";
import request from "../../../../utils/axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import getScreenSize from "../../../../utils/getScreenSize";
import Comment from "../Comment/Comment";
import Webcam from "react-webcam";
import addFaceImg from "../../../../assets/Attendance/addFace.svg";
import cameraFaceCapture from "../../../../assets/Attendance/cameraFaceCapture.svg";
import cameraFaceSuccess from "../../../../assets/Attendance/cameraFaceSuccess.svg";
import cameraFaceError from "../../../../assets/Attendance/cameraFaceError.svg";
import fingerAttendance from "../../../../assets/Attendance/fingerAttendance.svg";
import mannualAttendance from "../../../../assets/Attendance/mannualAttendance.svg";
import mannual from "../../../../assets/Attendance/mannual.svg";
import locationImg from "../../../../assets/location.svg";
import dayjs from "dayjs";
import { InfoCircleOutlined, LoadingOutlined, WarningFilled } from "@ant-design/icons";
import { toast } from "react-toastify";
import about from "../../../../assets/about.svg";
import TextArea from "antd/es/input/TextArea";
import time from "../../../../assets/time.svg";
import department from "../../../../assets/Profile/department.svg";
import mobileErrorFrame from "../../../../assets/Attendance/mobileErrorFrame.png"
import mobileCaptureFrame from "../../../../assets/Attendance/mobileCaptureFrame.png"
import mobileSucessFrame from "../../../../assets/Attendance/mobileSucessFrame.png"
function AttendanceDashboardView({ changeTabsValue }) {
  const { RangePicker } = DatePicker;
  const screenSize = getScreenSize();
  // const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [tableData, setTableData] = useState();
  const [calendarMonth, setCalendarMonth] = useState("");
  const [calendarYear, setCalendarYear] = useState("");
  const [reload, setReload] = useState(false);
  const [value, onChange] = useState(new Date());
  const [shiftData, setShiftData] = useState();
  const [attendanceLogsShift, setAttendanceLogsShift] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  const [regularizationForm] = Form.useForm();

  const [selectedDate, setSelectedDate] = useState(new Date())

  const columns = [
    {
      title: "Date",
      dataIndex: "attendance_date",
      key: "attendance_date",
      render: (text, record) => (
        <>
          {moment(text).format("DD-MM-YYYY")}
          {record.attendance_logs[0].is_holiday && (
            <div>
              <Tag
                color="blue"
                style={{
                  padding: "0px 10px",
                  fontSize: "14px",
                  marginTop: "5px",
                }}
              >
                {record.attendance_logs[0].holiday_name}
              </Tag>
            </div>
          )}
          {record.attendance_logs[0].is_weekly_holiday && (
            <div>
              <Tag
                color="green"
                style={{
                  padding: "0px 10px",
                  fontSize: "14px",
                  marginTop: "5px",
                }}
              >
                Weekly Off
              </Tag>
            </div>
          )}
          {record.attendance_logs[0].on_leave && (
            <div>
              <Tag
                color={record.attendance_logs[0].leave_type_color}
                style={{
                  padding: "0px 10px",
                  fontSize: "14px",
                  marginTop: "5px",
                }}
              >
                {record.attendance_logs[0].leave_type}
              </Tag>
            </div>
          )}
        </>
      ),
    },
    {
      title: " Location",
      dataIndex: "location",
      key: "location",
      render: (text, record) => (
        <>
          {!record.attendance_logs[0].is_holiday &&
            !record.attendance_logs[0].is_weekly_holiday &&
            !record.attendance_logs[0].on_leave &&
            record.attendance_logs[0].location}
        </>
      ),
    },
    {
      title: "Check-in",
      dataIndex: "check_in_time",
      key: "check_in_time",
      render: (text, record) => (
        <span>
          {!record.attendance_logs[0].is_holiday &&
            !record.attendance_logs[0].is_weekly_holiday &&
            !record.attendance_logs[0].on_leave && (
              <>
                {record.attendance_logs[0].is_absent ? (
                  <span style={{ color: "red" }}>Absent</span>
                ) : text === null ? (
                  ""
                ) : record.attendance_logs[0].is_pending === true ? (<span style={{ color: "rgb(246 154 11)" }}>Approval Pending</span>
                ) : record.attendance_logs[0].yet_to_checkin ? <span style={{ color: "rgb(246 154 11)" }}>Pending</span> : (
                  record.attendance_logs[0].check_in_time
                )}
              </>
            )}
        </span>
      ),
    },
    {
      title: "Check-out",
      dataIndex: "check_out_time",
      key: "check_out_time",
      render: (text, record) => (
        <span>
          {!record.attendance_logs[0].is_holiday &&
            !record.attendance_logs[0].is_weekly_holiday &&
            !record.attendance_logs[0].on_leave && (
              <>
                {record.attendance_logs[0].is_absent ? (
                  <span style={{ color: "red" }}>Absent</span>
                ) : text === null ? (
                  ""
                ) : record.attendance_logs[0].check_out_time === "-" ? (<span style={{ color: "rgb(246 154 11)" }}>Pending</span>
                ) : record.attendance_logs[0].is_pending === true ? (<span style={{ color: "rgb(246 154 11)" }}>Approval Pending</span>
                ) :
                  record.attendance_logs[0].check_out_time === "no_checkout" ? (<span style={{ color: "rgb(0, 96, 255)" }}>No Check Out</span>
                  ) : (
                    record.attendance_logs[0].check_out_time
                  )}
              </>
            )}
        </span>
      ),
    },
    {
      title: "Work Duration",
      dataIndex: "working_duration",
      key: "working_duration",
      render: (text, record) => (
        <span>
          {!record.attendance_logs[0].is_holiday &&
            !record.attendance_logs[0].is_weekly_holiday &&
            !record.attendance_logs[0].on_leave && !record.attendance_logs[0].is_pending && <>{record.attendance_logs[0].working_duration === "N/A" ? "" : record.attendance_logs[0].working_duration}</>}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          {
            !record.attendance_logs[0].is_holiday && !record.attendance_logs[0].is_weekly_holiday && !record.attendance_logs[0].on_leave && !record.attendance_logs[0].is_pending &&

            <Button className="button"
              style={{ fontSize: "14px" }}
              onClick={() => {
                setSelectedDate(record.attendance_date)
                setOpenModal(true)
              }}>
              Regularise
            </Button>


          }
        </div>)
    },
    // <span>
    //   {!record.attendance_logs[0].is_holiday &&
    //     !record.attendance_logs[0].is_weekly_holiday &&
    //     !record.attendance_logs[0].on_leave && (
    //       <>
    //         {record.attendance_logs[0].comment ? (
    //           <div
    //             style={{
    //               display: "flex",
    //               gap: "10px",
    //               alignItems: "center",
    //             }}
    //           >
    //             <p>{record.attendance_logs[0].comment}</p>
    //             <Comment
    //               btnType={"Update"}
    //               data={text}
    //               date={record.attendance_date}
    //               setReload={setReload}
    //               reload={reload}
    //             />
    //           </div>
    //         ) : (
    //           <Comment
    //             btnType={"Add"}
    //             date={record.attendance_date}
    //             setReload={setReload}
    //             reload={reload}
    //           />
    //         )}
    //       </>
    //     )}
    // </span>
    //   ),
    // },
  ];

  const { Option } = Select;
  const [attendanceWithGeoLocation, setAttendanceWithGeoLocation] =
    useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  // const [selectedItems, setSelectedItems] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  // const [showDatePicker, setShowDatePicker] = useState(false);
  const [days, setDays] = useState(7);
  const handleSelectChange = (value) => {
    console.log("value", value);
    if (value === "custom") {
      // setShowDatePicker(true)
      setOpen(true);
    } else {
      // setShowDatePicker(false)
      setDays(value);
    }
  };

  const [personalAttendanceDetails, setPersonalAttendanceDetails] = useState({
    average_working_duration: "",
    total_leaves: "",
    total_present_days: "",
    work_policy: "",
    face_present: "",
    attendance_type: 5,
    policy_id: "",
  });

  const [excemptionAllowed, setExcemptionAllowed] = useState(false)

  const fetchData = async (page, page_size) => {
    const personalAttendanceDetails = await request({
      method: "get",
      url: `attendance/employee/attendance/stats/monthly/`,
    });
    let formattedTime;

    if (personalAttendanceDetails.data.average_working_duration === "0:00") {
      formattedTime = personalAttendanceDetails.data.average_working_duration;
    } else {
      const time = personalAttendanceDetails.data.average_working_duration;
      const [hours, minutes] = time.split(":").slice(0, 2);
      formattedTime = `${hours}:${minutes}`;
    }

    setIsLogin(personalAttendanceDetails.data.is_checked_in_today);
    setIsCheckOut(personalAttendanceDetails.data.is_checked_out_today);
    setPersonalAttendanceDetails({
      ...personalAttendanceDetails.data,
      average_working_duration: formattedTime,
    });
  };

  const fetchTableLogs = async () => {
    if (shiftData === 1) {
      const data = await request({
        method: "get",
        url: `attendance/employee/attendance/details/?days=${days}`,
      });
      setTableData(data.data.attendance_data);
      setShiftData(data.data.shift_number);
    }
    else {
      const data = await request({
        method: "get",
        url: `attendance/employee/attendance/details/?days=${days}&shift=${attendanceLogsShift}`,
      });
      setTableData(data.data.attendance_data);
      setShiftData(data.data.shift_number);
    }

    // setLoading(false)
  };

  const regularizeRequest = async (values) => {
    const data = await request({
      method: "post",
      url: `attendance/employee/regularization/apply?shift=${attendanceLogsShift}`,
      data: {
        attendance_date: selectedDate,
        ...values,
        check_in_time: dayjs(values.check_in_time).format(" HH:mm:ss"),
        check_out_time: values.check_out_time ? dayjs(values.check_out_time).format(" HH:mm:ss") : null,

      },
    });

    setOpenModal(false);
    setReload(!reload);
    fetchData();
    toast.success("Regularization request submitted successfully", {
      position: "bottom-left",
    });
  };

  const [calendarView, setCalendarView] = useState(false);

  const handleSwitchChange = (checked) => {
    setCalendarView(checked);
  };

  const [myTeamAttendanceDetails, setMyTeamAttendanceDetails] = useState({
    date: "",
    absent_count: "",
    present_count: "",
    wfh_count: "",
  });

  const [isReporting, setIsReporting] = useState(false);
  const myTeamDetail = async () => {
    const data = await request({
      method: "get",
      url: `attendance/my-team/widget/`,
    });

    setIsReporting(data.is_reporting);
    setMyTeamAttendanceDetails({
      ...myTeamAttendanceDetails,
      date: data.date,
      absent_count: data.absent_count,
      present_count: data.present_count,
      wfh_count: data.work_from_home,
    });
  };

  useEffect(() => {
    fetchData();
    myTeamDetail();
  }, []);

  useEffect(() => {
    fetchTableLogs();
  }, [days, attendanceLogsShift, reload]);

  const [calendarData, setCalendarData] = useState([]);
  const fetchCalendarData = async () => {
    const data = await request({
      method: "get",
      url: `attendance/employee/attendance/calender/monthly/?month=${calendarMonth}&year=${calendarYear}&shift=${attendanceLogsShift}`,
    });
    setCalendarData(data.data.monthly_attendance);
  };
  useEffect(() => {
    fetchCalendarData();
  }, [calendarMonth, calendarYear, attendanceLogsShift]);

  const [dateRange, setDateRange] = useState([]);
  const handleDateChange = async (value) => {
    setDateRange(value);
    const data = await request({
      method: "get",
      url: `attendance/employee/attendance/details/?start_date=${value[0]?.format(
        "YYYY-MM-DD"
      )}&end_date=${value[1]?.format("YYYY-MM-DD")}`,
    });
    setTableData(data.data.attendance_data);

    // setLoading(false)
  };

  const [open, setOpen] = useState(false);

  const items = [
    {
      key: "1",
      label: (
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <img
            src={fingerAttendance}
            alt="fingerAttendance"
            width={23}
            height={23}
          />
          <span style={{ fontSize: "12px", fontWeight: "600" }}>
            Finger Print
          </span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setFaceIdModel(true);
            setFaceError("");
          }}
          style={{
            cursor: "pointer",
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <img src={addFaceImg} alt="addFaceImg" width={23} height={23} />
          <span style={{ fontSize: "12px", fontWeight: "600" }}>Face Id</span>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={() => {
            setMannualModel(true);
          }}
          style={{
            cursor: "pointer",
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <img
            src={mannualAttendance}
            alt="mannualAttendance"
            width={23}
            height={23}
          />
          <span style={{ fontSize: "12px", fontWeight: "600" }}>Manual</span>
        </div>
      ),
    },
  ];

  {
    /*
    #########################################################################
                                     Face 
    #########################################################################
    */
  }

  const [faceIdModel, setFaceIdModel] = useState(false);
  const webcamRef = useRef(null);
  const [faceError, setFaceError] = useState("");
  {
    /*
    #########################################################################
                                    Add Face 
    #########################################################################
    */
  }
  const [firstModelAddFace, setFirstModelAddFace] = useState(true);
  const [secondModelAddFace, setSecondModelAddFace] = useState(true);
  const [faceStatus, setFaceStatus] = useState("capture");

  const [capturedImage, setCapturedImage] = useState(null);

  const addFace = useCallback(async () => {
    const imageBlob = await fetch(webcamRef.current.getScreenshot()).then(
      (res) => res.blob()
    );

    const formData = new FormData();
    formData.append("image", imageBlob, "captured-image.jpeg");

    try {
      const response = await request({
        method: "post",
        url: `attendance/face/create/`,
        data: formData,
      });

      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage(imageSrc);

      setFaceStatus("success");
    } catch (error) {
      console.error("Error sending image to the server:", error);
      setFaceStatus("error");
    }
  }, [webcamRef]);

  const handleAddFaceModalClose = () => {
    setFaceIdModel(false);
    setFirstModelAddFace(true);
    setFaceStatus("capture");
  };

  {
    /*
    #########################################################################
                                    Face Recognition
    #########################################################################
    */
  }

  // const capture = useCallback(( latitude, longitude) => async () => {
  //     if (webcamRef.current) {
  //         console.log("attendanceWithGeoLocation...",  latitude);
  //         const imageBlob = await fetch(webcamRef?.current?.getScreenshot()).then((res) => res.blob());

  //         const formData = new FormData();
  //         formData.append('image', imageBlob, 'captured-image.jpeg');
  //         console.log("attendanceWithGeoLocation...",  latitude)
  //         // if (attendanceWithGeoLocation) {
  //         formData.append('latitude', latitude);
  //         formData.append('longitude', longitude);
  //         // }
  //         try {
  //             await request({
  //                 method: "post",
  //                 url: `attendance/employee/face/recognize/`,
  //                 data: formData
  //             })
  //             handleModalClose();
  //             fetchTableLogs();
  //             // setAttendanceWithGeoLocation(false)
  //         } catch (error) {
  //             console.error('Error sending image to the server:', error.response.data.error);
  //             setFaceError(error.response.data.error)
  //             setFaceStatus('error')
  //         }

  //     }

  // }, [webcamRef,  latitude, longitude]);

  const [capturePhotoStatus, setCapturePhotoStatus] = useState(false)

  const capture = async (attendanceWithGeoLocation, latitude, longitude) => {
    if (webcamRef.current) {

      setCapturePhotoStatus(true)
      const imageBlob = await fetch(webcamRef?.current?.getScreenshot()).then(
        (res) => res.blob()
      );

      const formData = new FormData();
      formData.append("image", imageBlob, "captured-image.jpeg");

      if (attendanceWithGeoLocation) {
        formData.append("latitude", latitude);
        formData.append("longitude", longitude);
      }
      console.log("attendanceWithGeoLocation...", attendanceWithGeoLocation);
      console.log("attendanceWithGeoLocation...", latitude);
      console.log("attendanceWithGeoLocation...", longitude);
      try {
        if (personalAttendanceDetails?.attendance_type === 7) {
          await request({
            method: "post",
            url: `attendance/field-face-attendance/apply`,
            data: formData,
          })
          
        }
        else {
          await request({
            method: "post",
            url: `attendance/employee/face/recognize/`,
            data: formData,
          });
        }
        handleModalClose();
        fetchTableLogs();
        setAttendanceWithGeoLocation(false);
        setAttendanceError({
          ...attendanceError,
          isError: false,
        });
        setCapturePhotoStatus(false)
        {
          !personalAttendanceDetails?.is_checked_in_today ?
            toast.success("Clock In marked successfully", {
              position: "bottom-left",
            })
            :
            toast.success("Clock Out marked successfully", {
              position: "bottom-left",
            })
        }
        fetchData();

        if (!personalAttendanceDetails?.is_checked_in_today) {
          let startTracking = "startTracking";
          if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
            window.ReactNativeWebView.postMessage(JSON.stringify({ startTracking }));
          } else {
            console.warn('ReactNativeWebView is not defined.');
          }
        }
        else {
          let stopTracking = "stopTracking";
          if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
            window.ReactNativeWebView.postMessage(JSON.stringify({ stopTracking }));
          } else {
            console.warn('ReactNativeWebView is not defined.');
          }
        }
      }
      catch (error) {
        console.error(
          "Error sending image to the server:",
          error.response.data.error
        );
        setFaceError(error.response.data.error);
        setFaceStatus("error");
        setAttendanceError({
          ...attendanceError,
          isError: true,
          message: error.response.data.message,
        });
        setExcemptionAllowed(error.response.data.excemption_allowed);
        setCapturePhotoStatus(false)
        toast.error(
          "Could not mark your attendance, please try after some time",
          {
            position: "bottom-left",
          }
        );
      }
    }
  };

  const handleModalClose = () => {
    setAttendanceError({
      ...attendanceError,
      isError: false,
    });
    setFaceIdModel(false);
    setFaceStatus("capture");
    setExcemptionAllowed(false);
  };

  {
    /*
    #########################################################################
                                    Manual Attendance
    #########################################################################
    */
  }

  const [mannualModel, setMannualModel] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [isCheckOut, setIsCheckOut] = useState(false);
  const [attendanceError, setAttendanceError] = useState({
    isError: false,
    message: "",
  });
  const captureMannualAttendance = async () => {
    try {
      let data = {
        latitude: latitude,
        longitude: longitude,
      };

      setCapturePhotoStatus(true)

      if (personalAttendanceDetails?.attendance_type === 6){
        await request({
          method: "post",
          url: `attendance/field-attendance/apply`,
          data: attendanceWithGeoLocation ? data : {},
        })
      }
      else{
        await request({
          method: "post",
          url: `attendance/employee/attendance/mannual/`,
          data: attendanceWithGeoLocation ? data : {},
        });
      }
      fetchData();
      fetchTableLogs();
      setCapturePhotoStatus(false)
      setMannualModel(false);
      setAttendanceError({
        ...attendanceError,
        isError: false,
      });
      {
        !personalAttendanceDetails?.is_checked_in_today ?
          toast.success("Clock In marked successfully", {
            position: "bottom-left",
          })
          :
          toast.success("Clock Out marked successfully", {
            position: "bottom-left",
          })
      }

      if (!personalAttendanceDetails?.is_checked_in_today) {
        let startTracking = "startTracking";
        if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
          window.ReactNativeWebView.postMessage(JSON.stringify({ startTracking }));
        } else {
          console.warn('ReactNativeWebView is not defined.');
        }
      }
      else {
        let stopTracking = "stopTracking";
        if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
          window.ReactNativeWebView.postMessage(JSON.stringify({ stopTracking }));
        } else {
          console.warn('ReactNativeWebView is not defined.');
        }
      }

      fetchData();
    } catch (error) {
      console.error("Error sending image to the server:", error);
      setCapturePhotoStatus(false)
      setAttendanceError({
        ...attendanceError,
        isError: true,
        message: error.response.data.message,
      });

      toast.error(
        "Could not mark your attendance, please try after some time",
        {
          position: "bottom-left",
        }
      );
    }
  };

  const handleMannualModalClose = () => {
    setAttendanceError({
      ...attendanceError,
      isError: false,
    });
    setMannualModel(false);
  };

  const getPopupContainer = (triggerNode) => {
    return triggerNode.parentNode;
  };

  const fetchDataWithRespectedMonth = async (data) => {
    const [calendarYear, calendarMonth] = dayjs(data)
      .format("YYYY-MM")
      .split("-");

    try {
      const personalAttendanceDetails = await request({
        method: "get",
        url: `attendance/employee/attendance/stats/monthly/?month=${calendarMonth}&year=${calendarYear}`,
      });
      let formattedTime;
      if (personalAttendanceDetails.data.average_working_duration === "0:00") {
        formattedTime = personalAttendanceDetails.data.average_working_duration;
      } else {
        const time = personalAttendanceDetails.data.average_working_duration;
        const [hours, minutes] = time.split(":").slice(0, 2);
        formattedTime = `${hours}:${minutes}`;
      }

      setIsLogin(personalAttendanceDetails.data.is_checked_in_today);
      setIsCheckOut(personalAttendanceDetails.data.is_checked_out_today);
      setPersonalAttendanceDetails({
        ...personalAttendanceDetails.data,
        average_working_duration: formattedTime,
      });
    } catch (error) {
      console.error("Error sending image to the server:", error);
    }
  };

  const fetchDataWithRespectedDateOfMyTeam = async (data) => {
    const date = dayjs(data).format("YYYY-MM-DD");
    try {
      const data = await request({
        method: "get",
        url: `attendance/my-team/widget/?date=${date}`,
      });

      setIsReporting(data.is_reporting);
      setMyTeamAttendanceDetails({
        ...myTeamAttendanceDetails,
        date: data.date,
        absent_count: data.absent_count,
        present_count: data.present_count,
        wfh_count: data.work_from_home,
      });
    } catch (error) {
      console.error("Error sending image to the server:", error);
    }
  };

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > moment().endOf("day");
  };

  const [specificPolicyDetails, setSpecificPolicyDetails] = useState({});

  const fetchSpecificPolicy = async () => {
    try {
      const data = await request({
        method: "get",
        url: `attendance/policy/details/?policy_id=${personalAttendanceDetails?.policy_id}`,
      });
      setSpecificPolicyDetails(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //     // fetchSpecificPolicy()
  // }, [])

  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const renderStandardWeeklyOff = (data) => {
    return (
      <>
        <div className="renderStandardWeeklyOffDiv">
          <p style={{ color: "#626262", fontSize: "13px", fontWeight: "400" }}>
            All Weeks
          </p>
          <div>
            {specificPolicyDetails?.week_off_entries
              ?.slice(0, 1)
              .map((weekDetail, dayIndex) => {
                return (
                  <div key={dayIndex}>
                    <div
                      style={{
                        display: "grid",
                        gap: "10px",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                      }}
                    >
                      {weekDays.map((day, index) => {
                        const selected =
                          weekDetail.week_off[index] === 1 ||
                          weekDetail.week_off[index] === 0.5;

                        return (
                          <div
                            className={
                              selected
                                ? weekDetail.week_off[index] === 0.5
                                  ? "selectedHalfDay"
                                  : "selectedFullDay"
                                : "disabledFullDay"
                            }
                          >
                            {day.slice(0, 2)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  };

  const renderCustomWeeklyOff = (data) => {
    return (
      <>
        {specificPolicyDetails?.week_off_entries?.map(
          (weekDetail, weekIndex) => (
            <div key={weekIndex}>
              <div className="renderCustomWeeklyOffContainerDiv">
                <p className="weekNumberText">Week {weekIndex + 1}</p>

                {/* <div className='selectWeeksContainer'> */}
                <div
                  style={{
                    display: "grid",
                    gap: "10px",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                  }}
                >
                  {weekDays.map((day, index) => {
                    const isDaySelected =
                      weekDetail.week_off[index] === 1 ||
                      weekDetail.week_off[index] === 0.5;

                    return (
                      <div
                        key={index}
                        className={
                          isDaySelected
                            ? weekDetail.week_off[index] === 0.5
                              ? "selectedHalfDay"
                              : "selectedFullDay"
                            : "disabledFullDay"
                        }
                      >
                        {day.slice(0, 2)}
                      </div>
                    );
                  })}
                </div>
                {/* </div> */}
              </div>
            </div>
          )
        )}
      </>
    );
  };

  const workPolicyContent = (
    <div>
      <p
        style={{
          color: "#2D2D2D",
          fontSize: "16px",
          fontWeight: "600",
          margin: "0px",
        }}
      >
        Working Policy
      </p>
      <p style={{ color: "#626262", fontSize: "13px", fontWeight: "400" }}>
        {specificPolicyDetails?.policy_name}
      </p>
      {/* <p style={{ color: "#2D2D2D", fontSize: "16px", fontWeight: "600", margin: "0px" }}>Work Location</p>
            <p style={{ color: "#626262", fontSize: "13px", fontWeight: "400" }}>Gurugram</p> */}
      <p
        style={{
          color: "#2D2D2D",
          fontSize: "16px",
          fontWeight: "600",
          margin: "0px",
        }}
      >
        Attendance Type
      </p>
      <p style={{ color: "#626262", fontSize: "13px", fontWeight: "400" }}>
        {specificPolicyDetails?.attendance_type_office === 0 &&
          "Face Attendance"}
        {specificPolicyDetails?.attendance_type_office === 1 &&
          "Manual Attendance"}
        {specificPolicyDetails?.attendance_type_office === 2 &&
          "Automatic Attendance"}
        {specificPolicyDetails?.attendance_type_office === 3 &&
          "Manual with Geolocation"}
        {specificPolicyDetails?.attendance_type_office === 4 &&
          "Face with Geolocation"}
        {specificPolicyDetails?.attendance_type_office === 5 &&
          "Biometric Attendance"}
        {specificPolicyDetails?.attendance_type_office === 6 &&
          "Field Attendance"}
        {specificPolicyDetails?.attendance_type_office === 7 &&
          "Field + Face Attendance"}
      </p>
      <p
        style={{
          color: "#2D2D2D",
          fontSize: "16px",
          fontWeight: "600",
          margin: "0px",
        }}
      >
        Shift Timing
      </p>
      <div style={{ display: "flex" }}>
        <p style={{ color: "#2D2D2D", fontSize: "13px", fontWeight: "600" }}>
          {/* S1 <span style={{ color: "#626262", fontSize: "13px", fontWeight: "400" }}> 9:00am to 7:00pm</span> */}
          {specificPolicyDetails?.is_standard_shift ? (
            // true
            <>
              {specificPolicyDetails?.shifts[0]?.timings?.map((item, index) => {
                return (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <span
                      style={{
                        color: "#626262",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      {item.start_time} to {item.end_time}
                    </span>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {specificPolicyDetails?.shifts?.map((item, index) => {
                return (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <p style={{ margin: "0px" }}>Week {index + 1}</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                      {item.timings?.map((el, elIndex) => {
                        return (
                          <div style={{ display: "flex", gap: "10px" }}>
                            <p
                              style={{
                                color: "#626262",
                                fontSize: "13px",
                                fontWeight: "400",
                              }}
                            >
                              {el.start_time} to {el.end_time}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </p>
      </div>
      <p
        style={{
          color: "#2D2D2D",
          fontSize: "16px",
          fontWeight: "600",
          margin: "0px",
        }}
      >
        Weekly Off
      </p>
      {specificPolicyDetails?.is_week_off_standard
        ? renderStandardWeeklyOff()
        : renderCustomWeeklyOff()}
    </div>
  );

  const markAttendance = () => {
    console.log("111.....", attendanceWithGeoLocation);
    if (personalAttendanceDetails.attendance_type === 0) {
      setFaceIdModel(true);
      setFaceError("");
    } else if (personalAttendanceDetails.attendance_type === 1) {
      setMannualModel(true);
    } else if (personalAttendanceDetails.attendance_type === 3) {
      setAttendanceWithGeoLocation(true);
      getLatAndLong();
      setMannualModel(true);
    } else if (personalAttendanceDetails.attendance_type === 4) {
      setAttendanceWithGeoLocation(true);
      getLatAndLong();
      setFaceIdModel(true);
      setFaceError("");
    } else if (personalAttendanceDetails.attendance_type === 6){
      setAttendanceWithGeoLocation(true);
      getLatAndLong();
      setMannualModel(true);
    } else if (personalAttendanceDetails.attendance_type === 7){
      setAttendanceWithGeoLocation(true);
      getLatAndLong();
      setFaceIdModel(true);
      setFaceError("");
    }

    console.log("attendanceWithGeoLocation.....", attendanceWithGeoLocation);
  };

  const getLatAndLong = () => {
    if (!navigator.geolocation) {
      // setError('Geolocation is not supported by your browser');
      return;
    }

    // Fetch the user's current position
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("sdfsdfdsfds");
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (error) => {
        console.log("error", error);
        //   setError(`Error getting location: ${error.message}`);
      }
    );
  };

  const [excemptionReason, setExcemptionReason] = useState("");

  const submitExcemptionRequest = async () => {
    if (excemptionAllowed) {
      try {
        await request({
          method: "post",
          url: `attendance/exemption/apply`,
          data: {
            // employee: employeeId,
            comment: excemptionReason,
          },
        });

        handleModalClose();
        setExcemptionAllowed(false);
        fetchTableLogs();
        setAttendanceWithGeoLocation(false);
        setAttendanceError({
          ...attendanceError,
          isError: false,
        });
        fetchData();
      }
      catch (error) {
        console.log(error);
        toast.error(
          "Could not mark your attendance, please try after some time",
          {
            position: "bottom-left",
          }
        );
      }

    }
  };

  // useEffect(() => {
  //     // Check if Geolocation is supported by the browser

  // }, [faceIdModel]);
  const ref = useRef(null);
  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: "instant" });
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    getLatAndLong();
  }, []);

  return (
    <div className="attendanceContainer" ref={ref}>
      {screenSize.width < 768 ? (
        // mobile view
        <>
          <div
            style={{
              marginBottom: "20px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 16px"
            }}
          >
            <DatePicker
              size="medium"
              picker="month"
              style={{
                borderColor: "#D5D9DD",
                borderWidth: 1,
                background: "#FFFFFF",
                fontSize: "14px",
                fontWeight: "600",
                color: "#000000",
                height: "36px",
              }}
              placeholder="Today"
              onChange={(e) => {
                fetchDataWithRespectedMonth(e);
              }}
              defaultValue={dayjs().startOf('month')}
              format={"MMMM YYYY"}
            />
            {/* <Dropdown menu={{ items }} placement="bottom"> */}
            {
              personalAttendanceDetails?.attendance_type !== 5
              &&
              <Button
                style={{ height: "36px", backgroundColor: "#008080" }}
                onClick={() => markAttendance()}
              >
                <div
                  style={{
                    fontWeight: "400px",
                    fontSize: "16px",
                    color: "white",
                  }}
                >
                  {
                    !personalAttendanceDetails?.is_checked_in_today ? "Clock In" : "Clock Out"
                  }
                </div>
              </Button>
            }

            {/* </Dropdown> */}
          </div>

          <div
            style={{
              justifyContent: "space-between",
              alignSelf: "center",
              marginTop: "10px",
              flexDirection: "row",
              display: "flex",
              padding: "0px 16px"
            }}
          >
            <div style={{ width: "45%" }}>
              <div
                style={{
                  backgroundColor: "#FAFAFA",
                  padding: "16px",
                  borderRadius: "4px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2
                    style={{
                      color: "#145757",
                      fontSize: "16px",
                    }}
                  >
                    {personalAttendanceDetails?.average_working_duration}hr
                  </h2>
                  <img
                    src={averageDuration}
                    alt="door"
                    style={{ height: "23px", width: "23px" }}
                  />
                </div>
                <p
                  style={{
                    color: "black",
                    fontSize: 12,
                    fontWeight: 400,
                    marginTop: 5,
                  }}
                >
                  Average Duration
                </p>
              </div>
            </div>

            <div style={{ width: "50%" }}>
              <div
                style={{
                  backgroundColor: "#FAFAFA",
                  padding: "16px",
                  borderRadius: "4px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2
                    style={{
                      color: "#145757",
                      fontSize: "16px",
                    }}
                  >
                    {personalAttendanceDetails.work_policy}
                  </h2>
                  <img
                    src={workingPolicy}
                    alt="workingPolicy"
                    style={{ height: "23px", width: "23px" }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "5px", marginTop: "5px" }}>
                  <p
                    style={{
                      color: "black",
                      fontSize: 12,
                      fontWeight: 400,
                      margin: "0px",
                    }}
                  >
                    Working Policy
                  </p>
                  <Popover content={workPolicyContent} trigger="click">
                    <InfoCircleOutlined
                      style={{ color: "#696969", fontSize: "12px" }}
                      onClick={() => {
                        fetchSpecificPolicy();
                      }}
                    />
                  </Popover>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              justifyContent: "space-between",
              alignSelf: "center",
              marginTop: "10px",
              flexDirection: "row",
              display: "flex",
              padding: "0px 16px"
            }}
          >
            <div style={{ width: "45%" }}>
              <div
                style={{
                  backgroundColor: "#FAFAFA",
                  padding: "16px",
                  borderRadius: "4px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2
                    style={{
                      color: "#145757",
                      fontSize: "16px",
                    }}
                  >
                    {personalAttendanceDetails.total_leaves}
                  </h2>
                  <img
                    src={totalLeaves}
                    alt="noimg"
                    style={{ height: "23px", width: "23px" }}
                  />
                </div>
                <p
                  style={{
                    color: "black",
                    fontSize: 12,
                    fontWeight: 400,
                    marginTop: 5,
                  }}
                >
                  Total Leaves
                </p>
              </div>
            </div>

            <div style={{ width: "50%" }}>
              <div
                style={{
                  backgroundColor: "#FAFAFA",
                  padding: "16px",
                  borderRadius: "4px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2
                    style={{
                      color: "#145757",
                      fontSize: "16px",
                    }}
                  >
                    {personalAttendanceDetails.total_present_days}
                  </h2>
                  <img
                    src={totalWorkingDays}
                    alt="weight"
                    style={{ height: "23px", width: "23px" }}
                  />
                </div>
                <p
                  style={{
                    color: "black",
                    fontSize: 12,
                    fontWeight: 400,
                    marginTop: 5,
                  }}
                >
                  Total Working Days
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "8px",
              backgroundColor: "#F7F8FC",
              marginTop: "20px",
            }}
          />
          {isReporting && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                  justifyContent: "space-between",
                  alignSelf: "center",
                  padding: "0px 16px",
                }}
              >
                <p
                  style={{
                    fontSize: "24px",
                    fontFamily: "Poppins-Medium",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161616"
                  }}
                >
                  My Team
                </p>
                <div>
                  <DatePicker
                    size="medium"
                    style={{
                      borderColor: "#D5D9DD",
                      borderWidth: 1,
                      background: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#000000",
                      width: "150px",
                    }}
                    placeholder="Today"
                    disabledDate={disabledDate}
                    onChange={(e) => {
                      fetchDataWithRespectedDateOfMyTeam(e);
                    }}
                  />
                </div>
                <img
                  src={arrowCircle}
                  alt="arrow"
                  style={{ width: "36px", height: "36px" }}
                  onClick={() => {
                    changeTabsValue("2");
                  }}
                />
              </div>
              <Row gutter={[32, 32]}>
                <Col span={24} lg={12}>
                  {isReporting && (
                    <div className="myTeam">
                      <div className="total" style={{ marginTop: "20px" }}>
                        <div className="totalAbsent" style={{ height: "69px" }}>
                          <p className="cardTitle">Absent</p>
                          <span className="cardValue">
                            {myTeamAttendanceDetails.absent_count}
                          </span>
                        </div>
                        <div className="totalPresent" style={{ height: "69px" }}>
                          <p className="cardTitle">Present</p>
                          <span className="cardValue">
                            {myTeamAttendanceDetails.present_count}
                          </span>
                        </div>
                        <div className="wfh" style={{ height: "69px" }}>
                          <p className="cardTitle">WFH</p>
                          <span className="cardValue">
                            {myTeamAttendanceDetails.wfh_count}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <div
                style={{
                  height: "8px",
                  backgroundColor: "#F7F8FC",
                  marginTop: "20px",
                }}
              />
            </>
          )}

          <div className="logsContainer">
            <div className="flexSpaceBetween">
              <div className="flexSpaceBetween">
                <span style={{ fontSize: "24px", fontWeight: "500", color: "#161616", marginRight: "20px" }}>Logs</span>
                <Switch checked={calendarView} onChange={handleSwitchChange} />
                <span className="calendarViewText">Calendar View</span>
              </div>
              <img
                src={fullView}
                alt="fullView"
                style={{ marginLeft: "20px", height: 36, width: 36 }}
                className="fullViewImg"
                onClick={() => changeTabsValue("3")}
              />
            </div>

            <div className="shiftContainer" style={{ marginTop: "15px" }} >
              {
                shiftData > 1
                &&
                <button
                  className={attendanceLogsShift === 1 ? "activeShiftBtn" : "shiftBtn"}
                  onClick={() => setAttendanceLogsShift(1)}
                >Shift 1</button>
              }
              {
                shiftData >= 2
                &&
                <button
                  className={attendanceLogsShift === 2 ? "activeShiftBtn" : "shiftBtn"}
                  onClick={() => setAttendanceLogsShift(2)}
                >Shift 2</button>
              }
              {
                shiftData >= 3
                &&
                <button className={attendanceLogsShift === 3 ? "activeShiftBtn" : "shiftBtn"}
                  onClick={() => setAttendanceLogsShift(3)}
                >Shift 3</button>
              }
            </div>

            <div className="flexSpaceBetween">
              <div
                style={{
                  marginTop: "20px",
                  fontSize: "16px",
                }}
              >
                {calendarView ? "Calendar View" : "List View"}
              </div>
              {!calendarView && (
                <Select
                  style={{ width: 150, height: 30, marginTop: "20px" }}
                  placeholder="Select an option"
                  onChange={handleSelectChange}
                  defaultValue={days ? days : selectedDateRange}
                >
                  <Option value={7}>Last 7 days</Option>
                  <Option value={14}>Last 14 days</Option>
                  <Option value={30}>Last 30 days</Option>
                  <Option value="custom">
                    <span
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      Custom Date
                    </span>
                  </Option>
                </Select>
              )}

              <Modal
                title="Select Date Range"
                centered
                open={open}
                onCancel={() => setOpen(false)}
                onOk={async () => {
                  const data = await request({
                    method: "get",
                    url: `attendance/employee/attendance/details/?start_date=${dateRange[0]?.format(
                      "YYYY-MM-DD"
                    )}&end_date=${dateRange[1]?.format("YYYY-MM-DD")}`,
                  });
                  console.log("data", data);
                  setTableData(data.data.attendance_data);

                  // setLoading(false)
                  setOpen(false);
                }}
              >
                <RangePicker
                  value={dateRange}
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                  style={{ color: "black !important" }}
                />
              </Modal>
            </div>

            <br />
            {calendarView ? (
              <CalendarScheduler
                data={calendarData}
                setCalendarMonth={setCalendarMonth}
                setCalendarYear={setCalendarYear}
                type="self"
              />

            ) : (
              <div>
                {tableData?.map((item, index) => {
                  return (
                    <>
                      <div>
                        <p
                          style={{
                            fontSize: "18px",
                            fontFamily: "Poppins-Medium",
                            color: "#161616",
                            marginTop: "5px",
                          }}
                        >
                          {item?.attendance_date}
                        </p>
                        {item?.attendance_logs[0]?.is_absent ||
                          item?.attendance_logs[0]?.is_holiday ||
                          item?.attendance_logs[0]?.is_weekly_holiday ||
                          item?.attendance_logs[0]?.on_leave ? (
                          <div>
                            <p>
                              {item?.attendance_logs[0]?.is_holiday ? (
                                item?.attendance_logs[0]?.is_holidayName
                              ) : item?.attendance_logs[0]?.is_weekly_holiday ? (
                                <div className="weeklyOff">
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "#008080",
                                      padding: "4px",
                                      fontWeight: 400,
                                      textAlign: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    Weekly Off
                                  </p>
                                </div>
                              ) : (
                                item?.attendance_logs[0]?.is_absent && <span style={{ color: "red" }}>Absent</span>
                              )}
                              {
                                item?.on_leave &&
                                <span style={{ color: item.leave_type_color }}>
                                  {item?.attendance_logs[0]?.leave_type}
                                </span>

                              }
                            </p>
                          </div>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <img src={time} alt="time" />
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: "Poppins-Regular",
                                    fontSize: "16px",
                                    margin: "0px",
                                    marginLeft: "10px",
                                    color: "#8A8A8A",
                                  }}
                                >
                                  {
                                    item.yet_to_checkin
                                      ?
                                      <span style={{ color: "rgb(246 154 11)" }}>Pending</span>
                                      : (
                                        item?.attendance_logs[0]?.check_in_time
                                      )
                                  }
                                  - - -
                                </p>
                                <p
                                  style={{
                                    fontFamily: "Poppins-Regular",
                                    fontSize: "16px",
                                    color: "#8A8A8A",
                                    margin: "0px",
                                  }}
                                >
                                  {
                                    item?.attendance_logs[0]?.check_out_time === null
                                      ?
                                      ("")
                                      :
                                      item?.attendance_logs[0]?.check_out_time === "-"
                                        ?
                                        (<span style={{ color: "rgb(246 154 11)" }}>Pending</span>)
                                        :
                                        item?.attendance_logs[0]?.check_out_time === "no_checkout"
                                          ?
                                          (<span style={{ color: "rgb(0, 96, 255)" }}>No Check Out</span>)
                                          :
                                          (
                                            item?.attendance_logs[0]?.check_out_time
                                          )
                                  }
                                </p>
                              </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px", padding: "10px 0px" }}>
                              <img src={department} alt="department" width={18} height={18} />
                              <p style={{ margin: "0px", fontSize: "16px", color: "#8A8A8A" }}>{item?.attendance_logs[0]?.working_duration}</p>
                            </div>
                            {
                              item?.attendance_logs[0]?.location !== "-"
                              &&
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <img
                                  alt="noimg"
                                  src={Vector}
                                  width={18}
                                  height={18}
                                />
                                <p
                                  style={{
                                    fontFamily: "Poppins-Regular",
                                    fontSize: "16px",
                                    color: "#8A8A8A",
                                    margin: "0px",
                                  }}
                                >
                                  {item?.attendance_logs[0]?.location}
                                </p>
                              </div>
                            }

                            {
                              item?.comment
                              &&
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Poppins-Regular",
                                    color: "black",
                                  }}
                                >
                                  Comment
                                </p>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Poppins-Regular",
                                    color: "#8A8A8A",
                                  }}
                                >
                                  {item?.comment}
                                </p>
                              </div>
                            }

                          </>
                        )}
                        <hr
                          style={{
                            height: "1px",
                            backgroundColor: "#F0F0F0",
                            border: "none",
                          }}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </div>
        </>
      ) : (
        // desktop view
        <>
          <div className="flexSpaceBetween">
            <div style={{ display: "flex", alignItems: "center", gap: "2vw" }}>
              <p className="heading" style={{ margin: "0px" }}>Attendance</p>
              <DatePicker
                size="large"
                picker="month"
                style={{
                  border: "none",
                  outline: "none",
                  background: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#000000",
                }}
                onChange={(e) => {
                  fetchDataWithRespectedMonth(e);
                }}
                // can we set default value as month name
                // defaultValue={moment().format("MMMM")}
                defaultValue={dayjs().startOf('month')}
                placeholder="Month"
                format={"MMMM YYYY"}
              />
            </div>
            <div>
              {/* <Dropdown menu={{ items }} placement="bottom"> */}
              {
                personalAttendanceDetails?.attendance_type !== 5
                &&
                <Button className="button" onClick={() => markAttendance()}>
                  Mark Attendance
                </Button>
              }
              {/* </Dropdown> */}
            </div>
          </div>
          {isReporting ? (
            <Row gutter={[32, 0]}>
              <Col xs={24} lg={12}>
                <Row gutter={[32, 0]}>
                  <Col xs={12} lg={12}>
                    <div className="personalInfo">
                      <div className="flexSpaceBetween">
                        <span className="apiData">
                          {personalAttendanceDetails?.average_working_duration}
                          hr
                        </span>

                        <img src={averageDuration} alt="" className="image" />
                      </div>
                      <p className="aboutCard">Average Duration</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={12}>
                    <div className="personalInfo">
                      <div className="flexSpaceBetween">
                        <span className="apiData">
                          {personalAttendanceDetails.work_policy}
                        </span>
                        <img
                          src={workingPolicy}
                          alt="workingPolicy"
                          className="image"
                        />
                      </div>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px", marginTop: "20px" }}>
                        <p className="aboutCard" style={{ margin: "0px" }}>
                          Working Policy
                        </p>
                        <Popover content={workPolicyContent} trigger="click">
                          <InfoCircleOutlined
                            style={{ color: "#696969" }}
                            onClick={() => {
                              fetchSpecificPolicy();
                            }}
                          />
                        </Popover>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <br /><br /> */}
                <Row gutter={[32, 0]}>
                  <Col xs={12} lg={12}>
                    <div className="personalInfo">
                      <div className="flexSpaceBetween">
                        <span className="apiData">
                          {personalAttendanceDetails.total_leaves}
                        </span>
                        <img
                          src={totalLeaves}
                          alt="totalLeave"
                          // className="image"
                          style={{ height: '23px', width: '23px' }}
                        />
                      </div>
                      <p className="aboutCard">Total Leaves</p>
                    </div>
                  </Col>
                  <Col xs={12} lg={12}>
                    <div className="personalInfo">
                      <div className="flexSpaceBetween">
                        <span className="apiData">
                          {personalAttendanceDetails.total_present_days}
                        </span>
                        <img
                          src={totalWorkingDays}
                          alt="workingDays"
                          // className="image"
                          style={{ height: '23px', width: '23px' }}
                        />
                      </div>
                      <p className="aboutCard">Total Working Days</p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} lg={12}>
                <div className="myTeam">
                  <div className="flexSpaceBetween">
                    <div className="title flexCenter">My Team</div>
                    <img
                      src={fullView}
                      alt="fullView"
                      width={35}
                      height={35}
                      onClick={() => {
                        changeTabsValue("2");
                      }}
                      className="fullViewImg"
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <DatePicker
                      size="large"
                      style={{
                        border: "none",
                        outline: "none",
                        background: "#EBEBEB",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#000000",
                      }}
                      placeholder="Today"
                      disabledDate={disabledDate}
                      onChange={(e) => {
                        fetchDataWithRespectedDateOfMyTeam(e);
                      }}
                    />
                  </div>
                  <div className="total" style={{ marginTop: "20px" }}>
                    <div className="totalAbsent">
                      <p className="cardTitle">Absent</p>
                      <span className="cardValue">
                        {myTeamAttendanceDetails.absent_count}
                      </span>
                    </div>
                    <div className="totalPresent">
                      <p className="cardTitle">Present</p>
                      <span className="cardValue">
                        {myTeamAttendanceDetails.present_count}
                      </span>
                    </div>
                    <div className="wfh">
                      <p className="cardTitle">WFH</p>
                      <span className="cardValue">
                        {myTeamAttendanceDetails.wfh_count}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <Row gutter={[32, 32]}>
              <Col xs={12} lg={6}>
                <div className="personalInfo">
                  <div className="flexSpaceBetween">
                    <span className="apiData">
                      {personalAttendanceDetails?.average_working_duration}hr
                    </span>

                    <img src={averageDuration} alt="" className="image" />
                  </div>
                  <p className="aboutCard">Average Duration</p>
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="personalInfo">
                  <div className="flexSpaceBetween">
                    <span className="apiData">
                      {personalAttendanceDetails.work_policy}
                    </span>
                    <img
                      src={workingPolicy}
                      alt="workingPolicy"
                      className="image"
                    />
                  </div>
                  <p className="aboutCard">
                    Working Policy{" "}
                    <Popover content={workPolicyContent} trigger="click">
                      <InfoCircleOutlined
                        style={{ color: "#696969" }}
                        onClick={() => {
                          fetchSpecificPolicy();
                        }}
                      />
                    </Popover>
                  </p>
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="personalInfo">
                  <div className="flexSpaceBetween">
                    <span className="apiData">
                      {personalAttendanceDetails.total_leaves}
                    </span>
                    <img src={totalLeave} alt="totalLeave" className="image" />
                  </div>
                  <p className="aboutCard">Total Leaves</p>
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="personalInfo">
                  <div className="flexSpaceBetween">
                    <span className="apiData">
                      {personalAttendanceDetails.total_present_days}
                    </span>
                    <img
                      src={totalWorkingDays}
                      alt="workingDays"
                      style={{ height: '23px', width: '23px' }}
                    />
                  </div>
                  <p className="aboutCard">Total Working Days</p>
                </div>
              </Col>
            </Row>
          )}

          {/* ############################################################################
                                                            log
                        ############################################################################ */}
          <div className=" logsContainer">
            <div className="flexSpaceBetween">
              <div className="flexSpaceBetween">
                <span className="logs">Logs</span>
                <Switch checked={calendarView} onChange={handleSwitchChange} />
                <span className="calendarViewText">Calendar View</span>
              </div>
              <>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <div className="shiftContainer" >
                    {
                      shiftData > 1
                      &&
                      <button
                        className={attendanceLogsShift === 1 ? "activeShiftBtn" : "shiftBtn"}
                        onClick={() => setAttendanceLogsShift(1)}
                      >Shift 1</button>
                    }
                    {
                      shiftData >= 2
                      &&
                      <button
                        className={attendanceLogsShift === 2 ? "activeShiftBtn" : "shiftBtn"}
                        onClick={() => setAttendanceLogsShift(2)}
                      >Shift 2</button>
                    }
                    {
                      shiftData >= 3
                      &&
                      <button className={attendanceLogsShift === 3 ? "activeShiftBtn" : "shiftBtn"}
                        onClick={() => setAttendanceLogsShift(3)}
                      >Shift 3</button>
                    }
                  </div>

                  {!calendarView && (
                    <Select
                      style={{ width: 300, height: 40 }}
                      placeholder="Select an option"
                      onChange={handleSelectChange}
                      defaultValue={days ? days : selectedDateRange}
                    >
                      <Option value={7}>Last 7 days</Option>
                      <Option value={14}>Last 14 days</Option>
                      <Option value={30}>Last 30 days</Option>
                      <Option value="custom">
                        <Popover
                          title=""
                          content={
                            <div className="modal-content">
                              <RangePicker
                                value={dateRange}
                                onChange={handleDateChange}
                                format="YYYY-MM-DD"
                                disabledDate={(current) =>
                                  current && current > moment().endOf("day")
                                }
                              />
                            </div>
                          }
                          getPopupContainer={getPopupContainer}
                          placement="right"
                        >
                          <p
                            style={{
                              cursor: "pointer",
                              width: "90%",
                              margin: "0px",
                            }}
                          >
                            Custom Date
                          </p>
                        </Popover>
                      </Option>
                    </Select>
                  )}

                  <img
                    src={fullView}
                    alt="fullView"
                    width={35}
                    height={35}
                    style={{ marginLeft: "20px" }}
                    onClick={() => changeTabsValue("3")}
                  />
                </div>
              </>
            </div>

            <br />
            <br />
            {calendarView ? (
              <CalendarScheduler
                data={calendarData}
                setCalendarMonth={setCalendarMonth}
                setCalendarYear={setCalendarYear}
                type="self"
              />
            ) : (
              <Table
                columns={columns}
                dataSource={tableData}
                // loading={loading}
                pagination={false}
              />
            )}
          </div>
        </>
      )}

      {/* 
                #####################################################################################################
                ###################################### Face Id Modal  ############################################
                #####################################################################################################
            */}
      <>
        {faceIdModel &&
          (personalAttendanceDetails.face_present ? (
            <Modal
              centered
              open={faceIdModel}
              footer={null}
              width={800}
              onCancel={handleModalClose}
              maskClosable={false}
              keyboard={false}
            >
              {screenSize.width < 768 ? (
                <>
                  {
                    <center>
                      <div
                        style={{
                          backgroundImage: `url(${faceStatus === "capture"
                            ? mobileCaptureFrame
                            : faceStatus === "success"
                              ? mobileSucessFrame
                              : mobileErrorFrame
                            })`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          height: "330px",
                          width: "auto",
                        }}
                      >
                        <div style={{}}>
                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            mirrored={true}
                            height={300}
                            width={220}
                            style={{
                              marginTop: "15px"
                            }}
                          />
                        </div>
                      </div>
                      {/* <p style={{ color: "red" }}>{faceError}</p> */}
                      {attendanceError.isError && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            border: "1px solid red",
                            padding: "10px",
                            borderRadius: "4px",
                            margin: "20px 0px",
                          }}
                        >
                          <WarningFilled
                            style={{ color: "red", fontSize: "20px" }}
                          />
                          <p style={{ color: "red", margin: "0px" }}>
                            {attendanceError.message}
                          </p>
                        </div>
                      )}
                      {
                        excemptionAllowed
                          ?
                          <>
                            <TextArea
                              rows={4}
                              value={excemptionReason}
                              onChange={(e) =>
                                setExcemptionReason(e.target.value)
                              }
                            />

                            <div style={{ display: "flex", gap: "10px", marginTop: "20px", justifyContent: "end" }}>
                              <button
                                style={{ background: "#008080", color: "white", border: "none", height: "40px", borderRadius: "5px", padding: "0px 16px" }}
                                onClick={() => submitExcemptionRequest(attendanceWithGeoLocation, latitude, longitude)}
                              >
                                Exemption Request
                              </button>
                              <button style={{ background: "#EEEEEE", color: "#545252", border: "none", height: "40px", borderRadius: "5px", padding: "0px 16px" }}
                                onClick={() => {
                                  handleModalClose()
                                  setExcemptionAllowed(false)
                                }}
                              >Cancel</button>
                            </div>
                          </>
                          :
                          <button
                            className="captureButton"
                            onClick={() =>
                              capture(attendanceWithGeoLocation, latitude, longitude)
                            }
                            disabled={capturePhotoStatus}
                            style={{ width: "170px" }}
                          >
                            {
                              capturePhotoStatus
                                ?
                                <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} />
                                :
                                "Capture Photo"
                            }
                          </button>
                      }
                    </center>
                  }
                </>

              ) : (
                <>
                  {
                    excemptionAllowed
                      ?
                      <Row>
                        <Col span={10}>
                          <div
                            style={{
                              backgroundImage: `url(${faceStatus === "capture"
                                ? cameraFaceCapture
                                : faceStatus === "success"
                                  ? cameraFaceSuccess
                                  : cameraFaceError
                                })`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "left",
                              height: "300px",
                              // display: "flex",
                              // justifyContent: "space-between",
                            }}
                          >
                            <div style={{ left: "6%", position: "absolute" }}>
                              <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                mirrored={true}
                                height={300}
                                width={220}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col span={14}>
                          <p style={{ fontSize: "28px", fontWeight: "400", margin: "0px" }}>Face towards frame</p>
                          <p style={{ fontSize: "20px", fontWeight: "400", color: "#717070", margin: "0px" }}>Face in the camera frame to mark your attendance</p>
                          {attendanceError.isError && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                border: "1px solid red",
                                padding: "10px",
                                borderRadius: "4px",
                                marginBottom: "20px",
                              }}
                            >
                              <WarningFilled
                                style={{ color: "red", fontSize: "20px" }}
                              />
                              <p style={{ color: "red", margin: "0px" }}>
                                {attendanceError.message}
                              </p>
                            </div>
                          )}
                          <TextArea
                            rows={4}
                            value={excemptionReason}
                            onChange={(e) =>
                              setExcemptionReason(e.target.value)
                            }
                          />

                          <div style={{ display: "flex", gap: "10px", marginTop: "20px", justifyContent: "end" }}>
                            <button
                              style={{ background: "#008080", color: "white", border: "none", height: "40px", borderRadius: "5px", padding: "0px 16px" }}
                              onClick={() => submitExcemptionRequest(attendanceWithGeoLocation, latitude, longitude)}
                            >
                              Exemption Request
                            </button>
                            <button style={{ background: "#EEEEEE", color: "#545252", border: "none", height: "40px", borderRadius: "5px", padding: "0px 16px" }}
                              onClick={() => {
                                handleModalClose()
                                setExcemptionAllowed(false)
                              }}
                            >Cancel</button>
                          </div>
                        </Col>
                      </Row>
                      :
                      <center>
                        <div
                          style={{
                            backgroundImage: `url(${faceStatus === "capture"
                              ? cameraFaceCapture
                              : faceStatus === "success"
                                ? cameraFaceSuccess
                                : cameraFaceError
                              })`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            height: "300px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ left: "36%", position: "absolute" }}>
                            <Webcam
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              mirrored={true}
                              height={300}
                              width={220}
                            />
                          </div>
                        </div>
                        {/* <p style={{ color: "red" }}>{faceError}</p> */}
                        {attendanceError.isError && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              border: "1px solid red",
                              padding: "10px",
                              borderRadius: "4px",
                              marginBottom: "20px",
                            }}
                          >
                            <WarningFilled
                              style={{ color: "red", fontSize: "20px" }}
                            />
                            <p style={{ color: "red", margin: "0px" }}>
                              {attendanceError.message}
                            </p>
                          </div>
                        )}
                        <button
                          className="captureButton"
                          onClick={() =>
                            capture(attendanceWithGeoLocation, latitude, longitude)
                          }
                          disabled={capturePhotoStatus}
                          style={{ width: "170px" }}
                        >
                          {
                            capturePhotoStatus
                              ?
                              <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} />
                              :
                              "Capture Photo"
                          }
                        </button>
                      </center>
                  }
                </>

              )}
            </Modal>
          ) : (
            <Modal
              centered
              open={faceIdModel}
              footer={null}
              width={900}
              onCancel={handleAddFaceModalClose}
              maskClosable={false}
              keyboard={false}
              closable={faceStatus === "success" ? false : true}
            >
              {firstModelAddFace ? (
                screenSize.width < 768 ? (
                  // mobile view
                  <div className="flexCenter">
                    <div style={{ padding: "10px 30px" }}>
                      <center>
                        <div>
                          <p style={{ fontSize: "20px" }}>
                            How to set Up Face ID
                          </p>
                          <p style={{ color: "#717070", fontSize: "14px" }}>
                            Look up into the camera till your face is getting completely captured.
                          </p>
                          <img
                            src={addFaceImg}
                            alt="addFace"
                            style={{ marginTop: "20px" }}
                          />
                        </div>
                        <button
                          className="button"
                          style={{ marginTop: "50px" }}
                          onClick={() => {
                            setFirstModelAddFace(false);
                          }}
                        >
                          Get Started
                        </button>
                      </center>
                    </div>
                  </div>
                ) : (
                  // desktop view
                  <div className="flexCenter" style={{ padding: "50px" }}>
                    <div style={{ paddingRight: "60px" }}>
                      <img src={addFaceImg} alt="addFace" />
                    </div>
                    <div>
                      <p style={{ fontSize: "28px" }}>How to set Up Face ID</p>
                      <p style={{ color: "#717070", fontSize: "18px" }}>
                        Look up into the camera till your face is getting completely captured.
                      </p>
                      <button
                        className="button"
                        onClick={() => {
                          setFirstModelAddFace(false);
                        }}
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                )
              ) : (
                <>
                  {secondModelAddFace && (
                    <>
                      <div>
                        {screenSize.width < 768 ? (
                          // mobile view
                          <>
                            <div>
                              {faceStatus === "capture" && (
                                <>
                                  <center>
                                    <p
                                      style={{
                                        fontSize: "20px",
                                        paddingTop: "0px",
                                      }}
                                    >
                                      Face towards frame
                                    </p>
                                    {/* <span
                                      style={{
                                        color: "#717070",
                                        fontSize: "14px",
                                      }}
                                    >
                                      First position your face in the camera
                                      frame. Then move your head ina a circle to
                                      show all the angles of your face
                                    </span> */}
                                    <div
                                      style={{
                                        backgroundImage: `url(${faceStatus === "capture"
                                          ? mobileCaptureFrame
                                          : faceStatus === "success"
                                            ? mobileSucessFrame
                                            : cameraFaceError
                                          })`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        height: "330px",
                                        marginBottom: "20px"
                                      }}
                                    >
                                      <Webcam
                                        audio={false}
                                        ref={webcamRef}
                                        screenshotFormat="image/jpeg"
                                        height={300}
                                        width={220}
                                        mirrored={true}
                                        style={{
                                          marginTop: "15px"
                                        }}
                                      />
                                    </div>
                                    <button
                                      className="button"
                                      onClick={() => addFace()}
                                    >
                                      Add Face
                                    </button>
                                  </center>
                                </>
                              )}
                              {faceStatus === "success" && (
                                <>
                                  <center>
                                    <p
                                      style={{
                                        fontSize: "20px",
                                        paddingTop: "0px",
                                      }}
                                    >
                                      {/* Face towards frame */}
                                      Face added successfully
                                    </p>
                                    {/* <span
                                      style={{
                                        color: "#717070",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Face added successfully
                                    </span> */}
                                    <div
                                      style={{
                                        backgroundImage: `url(${faceStatus === "capture"
                                          ? cameraFaceCapture
                                          : faceStatus === "success"
                                            ? mobileSucessFrame
                                            : cameraFaceError
                                          })`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        height: "330px",
                                        marginBottom: "20px"
                                      }}
                                    >
                                      {
                                        capturedImage
                                          ?
                                          <img src={capturedImage} alt="Captured" style={{ marginTop: "20px" }} />
                                          :
                                          <Webcam
                                            audio={false}
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            height={300}
                                            width={220}
                                            mirrored={true}
                                            style={{
                                              marginTop: "15px"
                                            }}
                                          />
                                      }
                                    </div>
                                    <button
                                      className="button"
                                      onClick={() => {
                                        setFaceIdModel(false);
                                        setFaceStatus("capture");
                                        fetchData();
                                      }}
                                    >
                                      Done
                                    </button>
                                  </center>
                                </>
                              )}
                              {faceStatus === "error" && (
                                <>
                                  <center>
                                    <p
                                      style={{
                                        fontSize: "20px",
                                        paddingTop: "0px",
                                      }}
                                    >
                                      Face towards frame
                                    </p>
                                    {/* <span
                                      style={{
                                        color: "#717070",
                                        fontSize: "14px",
                                        margin: "0px",
                                      }}
                                    >
                                      Your face must match an existing Face
                                      appearance.
                                    </span> */}
                                    <div
                                      style={{
                                        backgroundImage: `url(${faceStatus === "capture"
                                          ? cameraFaceCapture
                                          : faceStatus === "success"
                                            ? cameraFaceSuccess
                                            : mobileErrorFrame
                                          })`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        height: "330px",
                                        marginBottom: "20px"
                                      }}
                                    >
                                      <Webcam
                                        audio={false}
                                        ref={webcamRef}
                                        screenshotFormat="image/jpeg"
                                        height={300}
                                        width={220}
                                        mirrored={true}
                                        style={{
                                          marginTop: "15px"
                                        }}
                                      />
                                    </div>
                                    <button
                                      className="button"
                                      onClick={() => addFace()}
                                    >
                                      Add Face
                                    </button>
                                  </center>
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          // desktop view
                          <div
                            style={{
                              backgroundImage: `url(${faceStatus === "capture"
                                ? cameraFaceCapture
                                : faceStatus === "success"
                                  ? cameraFaceSuccess
                                  : cameraFaceError
                                })`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "left",
                              height: "300px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ width: "40%", marginLeft: "25px" }}>
                              {
                                capturedImage
                                  ?
                                  <img src={capturedImage} alt="Captured" style={{ marginTop: "70px" }} />
                                  :
                                  <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    height={300}
                                    width={220}
                                    mirrored={true}
                                  />
                              }
                            </div>
                            <div style={{ width: "60%" }}>
                              {faceStatus === "capture" && (
                                <>
                                  <p
                                    style={{
                                      fontSize: "28px",
                                      paddingTop: "0px",
                                    }}
                                  >
                                    Face towards frame
                                  </p>
                                  <span
                                    style={{
                                      color: "#717070",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Look up into the camera till your face is getting completely captured.
                                  </span>
                                  <br />
                                  <br />
                                  <button
                                    className="button"
                                    onClick={() => addFace()}
                                  >
                                    Add Face
                                  </button>
                                </>
                              )}
                              {faceStatus === "success" && (
                                <>
                                  <p
                                    style={{
                                      fontSize: "28px",
                                      paddingTop: "0px",
                                    }}
                                  >
                                    Face towards frame
                                  </p>
                                  <span
                                    style={{
                                      color: "#717070",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Face added successfully
                                  </span>
                                  <br />
                                  <br />
                                  <button
                                    className="button"
                                    onClick={() => {
                                      setFaceIdModel(false);
                                      setFaceStatus("capture");
                                      fetchData();
                                    }}
                                  >
                                    Done
                                  </button>
                                </>
                              )}
                              {faceStatus === "error" && (
                                <>
                                  <p
                                    style={{
                                      fontSize: "28px",
                                      paddingTop: "0px",
                                    }}
                                  >
                                    Face towards frame
                                  </p>
                                  <span
                                    style={{
                                      color: "#717070",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Your face must match an existing Face
                                    appearance.
                                  </span>
                                  <br />
                                  <br />
                                  <button
                                    className="button"
                                    onClick={() => addFace()}
                                  >
                                    Add Face
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </Modal>
          ))}
      </>

      {/* 
                #####################################################################################################
                ###################################### Manual Modal  ############################################
                #####################################################################################################
            */}
      <>
        {mannualModel && (
          <Modal
            centered
            open={mannualModel}
            footer={null}
            width={800}
            onCancel={handleMannualModalClose}
            maskClosable={false}
            keyboard={false}
          >
            {screenSize.width < 768 ? (
              // mobile view
              <>
                {isCheckOut ? (
                  <div>
                    <center>
                      <img
                        src={mannual}
                        alt="mannual"
                        width={250}
                        height={80}
                      />
                      <br />
                      <br />
                      <p>Allready Marked Attendance</p>
                    </center>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "50px",
                      paddingBottom: "50px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <center>
                        <p
                          style={{
                            fontSize: "20px",
                            margin: "0px",
                            marginBottom: "10px",
                          }}
                        >
                          Mark Attendance
                        </p>
                        <p
                          style={{
                            fontSize: "16px",
                            color: "#717070",
                            margin: "0px",
                            marginBottom: "40px",
                          }}
                        >
                          Click on button to mark
                          <br />
                          your
                          <span style={{ color: "#008080", fontWeight: "600" }}>
                            {isLogin ? " Out " : " In "}
                          </span>
                          Time
                        </p>
                        <img
                          src={mannual}
                          alt="mannual"
                          width={250}
                          height={80}
                        />

                        <div style={{ marginTop: "80px" }}>
                          {attendanceError.isError && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                border: "1px solid red",
                                padding: "10px",
                                borderRadius: "4px",
                                marginBottom: "20px",
                              }}
                            >
                              <WarningFilled
                                style={{ color: "red", fontSize: "20px" }}
                              />
                              <p style={{ color: "red", margin: "0px" }}>
                                {attendanceError.message}
                              </p>
                            </div>
                          )}
                          <button
                            className="captureButton"
                            onClick={() => captureMannualAttendance()}
                            disabled={capturePhotoStatus}
                          >
                            {
                              capturePhotoStatus
                                ?
                                <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} />
                                :
                                <>
                                  {isLogin ? "End" : "Start"}
                                </>
                            }
                          </button>
                        </div>
                      </center>
                    </div>
                  </div>
                )}
              </>
            ) : (
              // desktop view
              <>
                {isCheckOut ? (
                  <div>
                    <center>
                      <img
                        src={mannual}
                        alt="mannual"
                        width={250}
                        height={80}
                      />
                      <br />
                      <br />
                      <p>Allready Marked Attendance</p>
                    </center>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "50px",
                      paddingBottom: "50px",
                    }}
                  >
                    <div>
                      <img src={mannual} alt="mannual" />
                    </div>
                    <div style={{ paddingLeft: "20px" }}>
                      <p
                        style={{
                          fontSize: "28px",
                          margin: "0px",
                          marginBottom: "10px",
                        }}
                      >
                        Mark Attendance
                      </p>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#717070",
                          margin: "0px",
                          marginBottom: "40px",
                        }}
                      >
                        Click on button to mark your
                        <span style={{ color: "#008080", fontWeight: "600" }}>
                          {isLogin ? " Out " : " In "}
                        </span>
                        Time
                      </p>
                      {attendanceError.isError && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            border: "1px solid red",
                            padding: "10px",
                            borderRadius: "4px",
                            marginBottom: "20px",
                          }}
                        >
                          <WarningFilled
                            style={{ color: "red", fontSize: "20px" }}
                          />
                          <p style={{ color: "red", margin: "0px" }}>
                            {attendanceError.message}
                          </p>
                        </div>
                      )}

                      <button
                        className="captureButton"
                        onClick={() => captureMannualAttendance()}
                        disabled={capturePhotoStatus}
                      >
                        {
                          capturePhotoStatus
                            ?
                            <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} />
                            :
                            <>
                              {isLogin ? "End" : "Start"}
                            </>
                        }
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </Modal>
        )}
      </>


      {/* regularization request */}
      <Modal
        centered
        open={openModal}
        footer={null}
        onCancel={() => {
          setOpenModal(false);
        }}

      >
        <p style={{ fontSize: "20px", lineHeight: "30px", fontWeight: "500" }}>Regularisation Request</p>
        <Form
          form={regularizationForm}
          onFinish={regularizeRequest}
          layout="vertical"
          // fields={[
          //   {
          //     name: ['start_date'],
          //     value: GetJoiningDateForEmployee ? dayjs(GetJoiningDateForEmployee) : null
          //   }
          // ]}
          fields={[
            {
              name: ['check_in_time'],
              value: null
            },
            {
              name: ['check_out_time'],
              value: null
            },

          ]}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label="Check In"
                name="check_in_time"
                rules={[
                  { required: true, message: "Required" },
                ]}
              >
                <TimePicker
                  hourStep={1}
                  minuteStep={15}
                  format="HH:mm"
                  // placeholder="-ve Earliest Time Allowed"
                  size='large'
                  style={{ width: "100%" }}
                  onChange={(time, timeString) => {
                    console.log(time, "time.....................");
                    // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                    // const totalMinutes = hours * 60 + minutes;
                  }}
                />
              </Form.Item>

            </Col>
            <Col span={12}>
              <Form.Item
                label="Check Out"
                name="check_out_time"
              >
                <TimePicker
                  hourStep={1}
                  minuteStep={15}
                  format="HH:mm"
                  // placeholder="-ve Earliest Time Allowed"
                  size='large'
                  style={{ width: "100%" }}
                  onChange={(time, timeString) => {
                    console.log(time, "time.....................22222222222222");
                    // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                    // const totalMinutes = hours * 60 + minutes;
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please add Reason!",
                },
              ]}
            >
              <TextArea
                rows={5}
                placeholder="Enter Your Reason"
                className="leaveTextArea"
                onChange={(e) =>
                  "reason"
                  // handleFormChange("reason", e.target.value)
                }
              />
            </Form.Item>
          </Col>

          <Button
            htmlType="submit"
            className="forgotPasswordButton"
            style={{ fontSize: "14px" }}
          >
            Send Request
          </Button>
        </Form>
      </Modal>

    </div>
  );
}

export default AttendanceDashboardView;
