import React, { useEffect, useRef, useState } from "react";
import "./MyTeam.scss";
import { Col, DatePicker, Input, Modal, Popover, Progress, Row, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import DynamicTable from "../../../../component/TableWithPagination/TableWithPagination";
import getScreenSize from "../../../../utils/getScreenSize";
import profileImg from "../../../../assets/Leave/profileImg.svg";
import request from "../../../../utils/axios";
import CommonTable from "../../../../component/CommonTable/CommonTable";
import MyTeamSearchBar from "../MyTeamSearchBar/MyTeamSearchBar";
import searchImage from "../../../../assets/searchImage.svg";
import filter from "../../../../assets/filter.svg";
import back from "../../../../assets/Recruitment/back.svg"
import { useNavigate } from "react-router-dom";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import userProfileImg from "../../../../assets/user.png"

// import SearchBar from '../../SearchBar/SearchBar'

function MyTeamLeaveFullView() {
  const ref = useRef(null);
  const [employeeLeaveColumns, setEmployeeLeaveColumns] = useState([
    {
      title: "Name",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Total Leave",
      dataIndex: "total_leaves",
      key: "total_leaves",
    },
  ]);
  const { Option } = Select;
  const screenSize = getScreenSize();
  const [myTeamEmployeeDetails, setMyTeamEmployeeDetails] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Number of items per page
    total: 0,
  });
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const myTeamLeaveDetails = () => {
    return (
      <div className="myTeamLeaveDetailsContainer">
        {myTeamEmployeeDetails.map((item, index) => {
          return (
            <Row className="employeeDetailsContainer">
              <Col xs={24} md={6} style={{ display: "flex" }}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={
                      item.profile_image
                    }
                    alt="profileImg"
                    style={{ width: "60px", height: "60px" }}
                  />
                  <div>
                    <p className="employeeName">{item.employee_name}</p>
                    <p className="employeeDept">{item.designation}</p>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={18}>
                {item?.leves_details?.map((leavesItem, leavesIndex) => {
                  return (
                    <Row style={{ display: "flex", alignItems: "center" }}>
                      <Col xs={8} md={4}>
                        <p className="employeeLeaveName">
                          {leavesItem.leave_name}
                        </p>
                      </Col>
                      <Col xs={15} md={19}>
                        <Progress
                          percent={leavesItem.leave_taken_percentage}
                          size={["95%", 20]}
                          strokeWidth={"10%"}
                          showInfo={false}
                          strokeColor={leavesItem.color}
                        />
                      </Col>
                      <Col xs={1} md={1}>
                        {leavesItem.leave_taken}/{leavesItem.total_leave}
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          );
        })}
      </div>
    );
  };

  const fetchMyTeamEmployeeDetailsForMobile = async () => {
    try {
      const data = await request({
        method: "get",
        url: `leave/my-team/employee/stats/?page=1&page_size=10`,
      });

      setMyTeamEmployeeDetails(data.pagination_data.data);
      // return data
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   ref?.current?.scrollIntoView({ behavior: 'instant' })
  //   document.body.scrollTop = document.documentElement.scrollTop = 0;

  //   // fetchMyTeamEmployeeDetailsForMobile();
  // }, []);
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);



  const handleSelectChange = async (value) => {
    try {
      const response = await request({
        method: "get",
        url: `leave/employee/search/my-team/leave/details/?employement_number=${value}`,
      });

      // fetchLeaveReportData();
      setMyTeamEmployeeDetails([response]);
      setSearchEmployeeList([]);
    } catch (err) {
      console.log(err);
    }
  };

  const search = async (data) => {
    try {
      const response = await request({
        method: "get",
        url: `leave/search/employees/?employee_name=${data}&type=my_team`,
      });

      setSearchEmployeeList(response);
      if (data === "") {
        const data = await request({
          method: "get",
          url: `leave/admin/leave-report/all?page=1&page_size=$10`,
        });

        setMyTeamEmployeeDetails(data.pagination_data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState([]);
  const [myTeamFilter, setMyTeamFilter] = useState(7);
  const [attendanceReportCustomDate, setAttendanceReportCustomDate] = useState({
    start_date: "",
    end_date: ""
  });
  const [open, setOpen] = useState(false)
  const getPopupContainer = (triggerNode) => {
    return triggerNode.parentNode;
  };
  const fetchMyTeamEmployeeDetails = async (paginationParam, dataList, filterOption) => {
    const { current } = paginationParam || pagination;
    console.log("current", current, filterOption);
    try {
      let data;
      if (filterOption === "custom") {
        if (attendanceReportCustomDate?.start_date && attendanceReportCustomDate?.end_date) {
          data = await request({
            method: "get",
            url: `leave/my-team/employee/stats/?page=${current}&page_size=${pagination.pageSize}&start_date=${attendanceReportCustomDate?.start_date}&end_date=${attendanceReportCustomDate?.end_date}`,
          })
          setOpen(false)
          setAttendanceReportCustomDate({
            start_date: "",
            end_date: ""
          })

        }
      } else {
        data = await request({
          method: "get",
          url: `leave/my-team/employee/stats/?page=${current}&page_size=${pagination.pageSize}&days=${filterOption ? filterOption : myTeamFilter}`,
        })
      }
      if (data && data?.pagination_data && data?.pagination_data?.data) {
        if (paginationParam) {
          setMyTeamEmployeeDetails([...dataList, ...data.pagination_data.data]);
        }
        else {
          setMyTeamEmployeeDetails([...myTeamEmployeeDetails, ...data.pagination_data.data]);
        }
        setPagination({
          ...pagination,
          current: current + 1,
          total: data?.pagination_data?.total
        })
        if (data?.pagination_data?.next === null) {
          setHasMore(false);
          return;
        }
      }
    } catch (error) {
      console.log(error);
      setHasMore(false);
    }
  };

  const handleDaysChange = (value) => {
    console.log("value", value);
    const pagination = {
      current: 1,
      pageSize: 10,
      total: 0
    }
    const dataList = [];
    if (value === "custom") {
      // setShowDatePicker(true)
      // setMyTeamFilter(value);
      setOpen(true);
      // fetchMyTeamEmployeeDetails(pagination, dataList, value);
    } else {
      // setShowDatePicker(false)

      setMyTeamFilter(value);
      fetchMyTeamEmployeeDetails(pagination, dataList, value);
    }
  };

  const handleDateChange = async (value) => {
    const start_date = value[0]?.format("YYYY-MM-DD");
    const end_date = value[1]?.format("YYYY-MM-DD");
    console.log("start_date", start_date, "end_date", end_date);
    setDateRange(value);
    setAttendanceReportCustomDate({ ...attendanceReportCustomDate, start_date, end_date })
  };

  useEffect(() => {
    if (attendanceReportCustomDate?.start_date && attendanceReportCustomDate?.end_date) {
      const pagination = {
        current: 1,
        pageSize: 10,
        total: 0
      }
      const dataList = [];
      fetchMyTeamEmployeeDetails(pagination, dataList, "custom");
    }
  }, [attendanceReportCustomDate]);



  useEffect(() => {
    fetchMyTeamEmployeeDetails();
  }, []);


  return (
    <div className="myTeamLeaveFullViewContainer" ref={ref}>
      {screenSize.width < 786 ?
        // mobile
        <div>
          <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px" }}>
            <div className="myTeamTextNew">My Team</div>
            <div className="imageContainerFull">
              <img src={searchImage} alt="search" className="searchImage" />
              <img src={filter} alt="filter" className="filterImage" />
            </div>
          </div>

          <div
            id="scrollableDiv"
            style={{
              height: "auto",
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            <InfiniteScroll
              dataLength={pagination.total - (myTeamEmployeeDetails.length * pagination.current)}
              next={fetchMyTeamEmployeeDetails}
              hasMore={hasMore}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              loader={
                <p style={{ textAlign: 'center' }}>
                  <b>Loading...</b>
                </p>
              }
              scrollableTarget="scrollableDiv"
              height="70vh"
            >
              {myTeamEmployeeDetails.map((item, index) => {
                return (
                  <div>
                    <div className="teamPersonContainer">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "20px",
                          alignItems: "center",
                        }}
                      >
                        {/* <div className="teamPersonImage"></div> */}
                        <img
                          src={item.profile_image ? item.profile_image : userProfileImg}
                          alt="profileImg"
                          style={{ width: "25px", height: "25px" }}
                        />
                        <p className="teamPerson">{item?.employee_name}</p>
                      </div>

                      {item?.leves_details?.map((leaveItem, leaveIndex) => {
                        return (
                          <div className="myLeaveTextContainer">
                            <p className="myTeamLeaveText">{leaveItem?.leave_name}</p>
                            <p className="myLeaveValue">
                              {leaveItem?.leave_taken}/{leaveItem?.total_leave}
                            </p>
                          </div>
                        );
                      })}

                    </div>
                    {index !== myTeamEmployeeDetails?.length - 1 && (
                      <hr className="divider" />
                    )}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
        :
        <div className="leaveContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", gap: "50px" }}
            >
              <p className="myTeamText">My Team</p>
              <MyTeamSearchBar
                setTableDataForSearchEmployee={setMyTeamEmployeeDetails}
                fetchTableDataFromApi={fetchMyTeamEmployeeDetails}
                placeholderText="Search for employees"
                empType="my_team"
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Select
                style={{ width: "150px", height: "40px" }}
                placeholder="Select an option"
                onChange={handleDaysChange}
                dafaultValue={myTeamFilter}
              // defaultValue={days ? days : selectedDateRange}
              >
                <Option value={7}>Last 7 days</Option>
                <Option value={14}>Last 14 days</Option>
                <Option value={30}>Last 30 days</Option>
                <Option value="custom">
                  {/* <Popover
                    title=""
                    content={
                      <div className="modal-content">
                        <RangePicker
                          value={dateRange}
                          onChange={handleDateChange}
                          format="YYYY-MM-DD"
                          disabledDate={(current) =>
                            current && current > moment().endOf("day")
                          }
                        />
                      </div>
                    }
                    getPopupContainer={getPopupContainer}
                    placement="right"
                  >
                    <p
                      style={{
                        cursor: "pointer",
                        width: "90%",
                        margin: "0px",
                      }}
                    >
                      Custom Date
                    </p>
                  </Popover> */}
                  <span
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Custom Date
                  </span>
                </Option>
              </Select>
            </div>
          </div>
          {/* {myTeamLeaveDetails()} */}
          <div
            id="scrollableDiv"
            style={{
              height: "auto",
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            <InfiniteScroll
              dataLength={pagination.total - (myTeamEmployeeDetails.length * pagination.current)}
              next={fetchMyTeamEmployeeDetails}
              hasMore={hasMore}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              loader={
                <p style={{ textAlign: 'center' }}>
                  <b>Loading...</b>
                </p>
              }
              scrollableTarget="scrollableDiv"
              height="70vh"
            >
              {myTeamLeaveDetails()}
            </InfiniteScroll>
          </div>
        </div>
      }

      <Modal
        title="Select Date Range"
        centered
        open={open}
        onCancel={() => setOpen(false)}
        onOk={async () => {

          setOpen(false);
        }}
        footer={null}
      >
        <RangePicker
          value={attendanceReportCustomDate}
          onChange={handleDateChange}
          format="YYYY-MM-DD"
          disabledDate={(current) =>
            current && current > moment().endOf("day")
          }
          style={{ color: "black !important" }}
        />
      </Modal>

    </div>
  );
}

export default MyTeamLeaveFullView;
