import { Checkbox, Col, Form, Input, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import request from "../../../../utils/axios";
import videoIcon from "../../../../assets/lms/videoIcon.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import getScreenSize from "../../../../utils/getScreenSize";

function TrainingForm() {
  const { TextArea } = Input;
  const navigate = useNavigate()
  const screenSize = getScreenSize();
  const [trainingFormData, setTrainingFormData] = useState({
    title: "",
    video: null,
    quiz: [],
  });

  const [quizStatus, setQuizStatus] = useState(false);

  const quizSwitch = (value) => {
    setQuizStatus(value);
    setTrainingFormData({
      ...trainingFormData,
      quiz: [],
    })
  };

  const onHandleQuizChange = (value, name, index) => {
    const updatedQuiz = update(trainingFormData, {
      quiz: {
        [index]: {
          [name]: { $set: value },
        },
      },
    });
    setTrainingFormData(updatedQuiz);
  };

  const onHandleOptionsChange = (value, name, index) => {
    const updatedQuiz = update(trainingFormData, {
      quiz: {
        [index]: {
          options: {
            [name]: { $set: value },
          },
        },
      },
    });
    setTrainingFormData(updatedQuiz);
  };

  useEffect(() => {
    console.log("trainingFormData", trainingFormData);
  }, [trainingFormData]);

  const addQuestion = () => {
    setTrainingFormData({
      ...trainingFormData,
      quiz: [
        ...trainingFormData.quiz,
        {
          question: "",
          options: {
            option1: "",
            option2: "",
            option3: "",
            option4: "",
          },
        },
      ],
    });
  };

  const onFinish = async () => {
    try {
      const formData = new FormData();
      formData.append("title", trainingFormData.title);
      formData.append("video", trainingFormData.video);
      formData.append("quiz", JSON.stringify(trainingFormData.quiz));
      formData.append("is_quiz_available", quizStatus);
      const response = await request({
        method: "post",
        url: `/lms/admin/training-videos/add`,
        data: formData,
      });
      navigate("/admin/lms/list", { replace: true });
      toast.success("Training video added successfully", {
        position: "bottom-left",
      });
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "bottom-left",
      })
      console.log(error);
    }
  };


  return (
    <div>
      {
        screenSize.width < 768
          ?
          <div>
            <div style={{ padding: "0px 16px" }} >
              <p style={{ fontSize: "24px", fontWeight: "500", marginTop: "80px" }}>
                Add Training video
              </p>
              <div>
                <Form
                  name="validateOnly"
                  layout="vertical"
                  autoComplete="off"
                  onFinish={onFinish}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Training Title"
                        name="Training Title"
                        rules={[{ required: true }]}
                      >
                        <Input
                          placeholder="Enter Training Title"
                          size="large"
                          onChange={(e) =>
                            setTrainingFormData({
                              ...trainingFormData,
                              title: e.target.value,
                            })
                          }
                          value={trainingFormData.title}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Upload Video"
                    name="file"
                    rules={[
                      {
                        required: true,
                        message: 'Please Upload Video',
                      },
                    ]}
                  >
                    <div
                      className="file-upload-container"
                      style={{ height: "150px" }}
                    >
                      <input
                        type="file"
                        id="file-upload"
                        className="input-file"
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          const maxSizeInBytes = 5 * 1024 * 1024;
                          {
                            const selectedFile = e.target.files[0];
                            if (selectedFile) {
                              setTrainingFormData({
                                ...trainingFormData,
                                video: selectedFile,
                              });
                            }
                          }
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <label
                          htmlFor="file-upload"
                          className="file-upload-label"
                          style={{ height: "150px" }}
                        >
                          <div
                            style={{
                              zIndex: 1,
                              margin: "0px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "150px",
                            }}
                          >
                            <div>
                              <center>
                                {
                                  trainingFormData?.video
                                    ?
                                    <>
                                      <p>{trainingFormData?.video?.name}</p>
                                    </>
                                    :
                                    <>
                                      <img
                                        src={videoIcon}
                                        alt={videoIcon}
                                        width={34}
                                        height={34}
                                      />
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          margin: "0px",
                                        }}
                                      >
                                        Select a video or drag and drop here
                                      </p>
                                    </>
                                }

                              </center>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </Form.Item>
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <span style={{ fontSize: "24px", fontWeight: "600" }}>Quiz</span>
                    <Switch onChange={quizSwitch} />
                  </div>

                  {quizStatus && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ fontSize: "24px", fontWeight: "600" }}>
                          Quiz Question
                        </p>

                        <button
                          type="button"
                          style={{
                            background: "#008080",
                            color: "white",
                            padding: "7px 15px",
                            borderRadius: "5px",
                            border: "none",
                          }}
                          onClick={() => addQuestion()}
                        >
                          Add
                        </button>
                      </div>

                      {trainingFormData?.quiz?.map((item, index) => {
                        return (
                          <Row gutter={[32, 0]}>
                            <Col span={24}>
                              <Form.Item
                                label={`Question ${index + 1}`}
                                rules={[{ required: true }]}
                              >
                                <TextArea
                                  size="large"
                                  rows={3}
                                  onChange={(e) =>
                                    onHandleQuizChange(
                                      e.target.value,
                                      "question",
                                      index
                                    )
                                  }
                                  value={item.question}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Option 1"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "option1",
                                      index
                                    )
                                  }
                                  value={trainingFormData?.quiz[index]?.options?.option1}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Option 2"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "option2",
                                      index
                                    )
                                  }
                                  // value={item.option2}
                                  value={trainingFormData?.quiz[index]?.options?.option2}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Option 3"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "option3",
                                      index
                                    )
                                  }
                                  value={trainingFormData?.quiz[index]?.options?.option3}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Option 4"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "option4",
                                      index
                                    )
                                  }
                                  value={trainingFormData?.quiz[index]?.options?.option4}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item
                                label="Answer"
                                rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Answer"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "correct_option",
                                      index
                                    )
                                  }
                                  value={trainingFormData?.quiz[index]?.options?.correct_option}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  )}

                  <Form.Item>
                    <button
                      type="submit"
                      style={{
                        background: "#008080",
                        color: "white",
                        padding: "10px 35px",
                        borderRadius: "5px",
                        border: "none",
                        width: "100%",
                      }}
                    >
                      Upload
                    </button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
          :
          <div style={{ padding: "50px" }}>
            <div
              style={{ padding: "30px", background: "white", borderRadius: "10px" }}
            >
              <p style={{ fontSize: "32px", fontWeight: "500" }}>
                Add Training video
              </p>
              <div>
                <Form
                  name="validateOnly"
                  layout="vertical"
                  autoComplete="off"
                  onFinish={onFinish}
                >
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label="Training Title"
                        name="Training Title"
                        rules={[{ required: true }]}
                      >
                        <Input
                          placeholder="Enter Training Title"
                          size="large"
                          onChange={(e) =>
                            setTrainingFormData({
                              ...trainingFormData,
                              title: e.target.value,
                            })
                          }
                          value={trainingFormData.title}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Upload Video"
                    name="file"
                    rules={[
                      {
                        required: true,
                        message: 'Please Upload Video',
                      },
                    ]}
                  >
                    <div
                      className="file-upload-container"
                      style={{ height: "300px" }}
                    >
                      <input
                        type="file"
                        id="file-upload"
                        className="input-file"
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          const maxSizeInBytes = 5 * 1024 * 1024;
                          {
                            console.log("e.target.files[0]", e.target.files[0]);
                            const selectedFile = e.target.files[0];
                            console.log("selectedFile", selectedFile);
                            if (selectedFile) {
                              setTrainingFormData({
                                ...trainingFormData,
                                video: selectedFile,
                              });

                              // const reader = new FileReader();
                              // reader.onloadend = () => {
                              //   setSelectedVideo(reader.result);
                              // };
                              // reader.readAsDataURL(selectedFile);
                            }
                          }
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <label
                          htmlFor="file-upload"
                          className="file-upload-label"
                          style={{ height: "300px" }}
                        >
                          <div
                            style={{
                              zIndex: 1,
                              margin: "0px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "300px",
                            }}
                          >
                            <div>
                              <center>
                                {
                                  trainingFormData?.video
                                    ?
                                    <>
                                      <p>{trainingFormData?.video?.name}</p>
                                    </>
                                    :
                                    <>
                                      <img
                                        src={videoIcon}
                                        alt={videoIcon}
                                        width={100}
                                        height={100}
                                      />
                                      <p
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "400",
                                          margin: "0px",
                                        }}
                                      >
                                        Select a video or drag and drop here
                                      </p>
                                    </>
                                }

                              </center>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </Form.Item>
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <span style={{ fontSize: "24px", fontWeight: "600" }}>Quiz</span>
                    <Switch onChange={quizSwitch} />
                  </div>

                  {quizStatus && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ fontSize: "24px", fontWeight: "600" }}>
                          Quiz Question
                        </p>

                        <button
                          type="button"
                          style={{
                            background: "#008080",
                            color: "white",
                            padding: "7px 15px",
                            borderRadius: "5px",
                            border: "none",
                          }}
                          onClick={() => addQuestion()}
                        >
                          Add
                        </button>
                      </div>

                      {trainingFormData?.quiz?.map((item, index) => {
                        return (
                          <Row gutter={[32, 0]}>
                            <Col span={24}>
                              <Form.Item
                                label={`Question ${index + 1}`}
                                rules={[{ required: true }]}
                              >
                                <TextArea
                                  size="large"
                                  rows={3}
                                  onChange={(e) =>
                                    onHandleQuizChange(
                                      e.target.value,
                                      "question",
                                      index
                                    )
                                  }
                                  value={item.question}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Option 1"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "option1",
                                      index
                                    )
                                  }
                                  value={trainingFormData?.quiz[index]?.options?.option1}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Option 2"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "option2",
                                      index
                                    )
                                  }
                                  // value={item.option2}
                                  value={trainingFormData?.quiz[index]?.options?.option2}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Option 3"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "option3",
                                      index
                                    )
                                  }
                                  value={trainingFormData?.quiz[index]?.options?.option3}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Option 4"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "option4",
                                      index
                                    )
                                  }
                                  value={trainingFormData?.quiz[index]?.options?.option4}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={12}>
                              <Form.Item
                                label="Answer"
                                rules={[{ required: true }]}>
                                <Input
                                  size="large"
                                  placeholder="Answer"
                                  onChange={(e) =>
                                    onHandleOptionsChange(
                                      e.target.value,
                                      "correct_option",
                                      index
                                    )
                                  }
                                  value={trainingFormData?.quiz[index]?.options?.correct_option}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  )}

                  <Form.Item>
                    <button
                      type="submit"
                      style={{
                        background: "#008080",
                        color: "white",
                        padding: "10px 35px",
                        borderRadius: "5px",
                        border: "none",
                      }}
                    >
                      Upload
                    </button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
      }
    </div>
  );
}

export default TrainingForm;
