import React, { useEffect, useRef, useState } from 'react'
import useScreenSize from '../../../../utils/getScreenSize';
import { useNavigate } from 'react-router-dom';
import back from "../../../../assets/Recruitment/back.svg"
import { Button, Modal, Table } from 'antd';
import request from '../../../../utils/axios';
import location from "../../../../assets/location.svg"
import edit from "../../../../assets/edit.svg"
import "./AttendancePolicyFullView.scss"
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination';
import cutomIcon from "../../../../assets/Attendance/cutomIcon.svg"
import mobileStyles from "./MobileAttendancePolicyFullView.module.scss"
import clone_icon from "../../../../assets/clone_icon.svg"
import add from "../../../../assets/Org/add.svg"
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
import time from "../../../../assets/time.svg"
import info from "../../../../assets/info.png"
import duration from "../../../../assets/Dashboard/Admin/duration.png";
import weekOff from "../../../../assets/weekOff.png";

function AttendancePolicyFullView() {
    const ref = useRef(null);
    const [standardShitTimeViewModal, setStandardShitTimeViewModal] = useState(false)
    const [viewStandardShiftTimeData, setViewStandardShiftTimeData] = useState(null)
    const [customeShitTimeViewModal, setCustomeShitTimeViewModal] = useState(false)
    const [viewCustomeShiftTimeData, setViewCustomeShiftTimeData] = useState(null)

    const attendancePolicyColumns = [
        {
            title: 'Policy',
            dataIndex: 'policy_name',
            key: 'policy_name',
        },
        {
            title: ' Working Policy',
            dataIndex: 'working_policy',
            key: 'working_policy',
        },
        {
            title: 'Shift Time',
            dataIndex: 'shifts',
            key: 'shifts',
            render: (text, record) => <>

                {

                    // is_standard_shift
                    record?.is_standard_shift
                        // true
                        ?
                        <>
                            <div style={{ display: "flex", gap: "10px" }}>
                                {
                                    record?.shifts[0]?.timings[0].start_time
                                }
                                {" "}to {" "}
                                {
                                    record?.shifts[0]?.timings[0].end_time
                                }

                                {
                                    record?.shifts[0]?.timings?.length > 1
                                        ?
                                        <div
                                            style={{ borderRadius: "50%", height: "25px", width: "25px", background: "#F1EEEE", color: "#6D6D6D", alignItems: "center", justifyContent: "center", display: "flex" }}
                                            onClick={() => {
                                                setStandardShitTimeViewModal(true)
                                                setViewStandardShiftTimeData(record)
                                            }}
                                        >
                                            {"+"}{record?.shifts[0]?.timings.length - 1}
                                        </div>
                                        :
                                        ""
                                }

                            </div>
                            {standardShitTimeViewModal && renderStandardShiftTime(record)}
                        </>
                        :
                        <>
                            <button
                                onClick={() => {
                                    setCustomeShitTimeViewModal(true)
                                    setViewCustomeShiftTimeData(record)
                                }}
                                style={{ padding: "5px 10px", borderRadius: "5px", border: "none", color: "#9b9797", background: "none" }}
                            >Custom <img src={cutomIcon} alt="" /></button>

                            {
                                customeShitTimeViewModal && renderCustomShiftTime(record)
                            }

                        </>


                }
            </>
        },
        {
            title: 'Weekly Off',
            dataIndex: 'week_off',
            key: 'week_off',
            render: (text, record) => <>
                <div className='renderCustomWeeklyOffOutterContainer'>
                    {
                        record?.is_week_off_standard
                            ?
                            <>
                                <button
                                    onClick={() => {
                                        setStandardViewModal(true)
                                        setSetPolicyId(record.policy_id)
                                    }}
                                    style={{ padding: "5px 10px", borderRadius: "5px", border: "none", color: "#9b9797", background: "none" }}
                                >
                                    {record?.week_off_entries[0]?.total_leaves} Weekly off  <img src={cutomIcon} alt="" />
                                </button>
                                {
                                    standardViewModal && renderStandardWeeklyOff(record)
                                }
                            </>
                            :
                            <>
                                <button
                                    onClick={() => {
                                        setCustomeViewModal(true)
                                        setSetPolicyId(record.policy_id)
                                    }}
                                    style={{ padding: "5px 10px", borderRadius: "5px", border: "none", color: "#9b9797", background: "none" }}
                                >Custom <img src={cutomIcon} alt="" /></button>

                                {
                                    customeViewModal && renderCustomWeeklyOff(record)
                                }
                            </>
                    }
                </div>
            </>
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, record) => <>
                <img src={edit} alt="edit" style={{ cursor: "pointer", marginRight: "20px" }} onClick={() => { navigate(`/admin/attendance/dashboard/policy-form/${record.policy_id}`, { replace: true }) }} />
                <img src={clone_icon} alt="clone" style={{ cursor: "pointer" }} onClick={() => { navigate(`/admin/attendance/dashboard/policy-form/${record.policy_id}/clone`, { replace: true }) }} />
                {/* <button onClick={() => >Clone</button> */}
            </>
        }
    ]

    const renderStandardShiftTime = (record) => {
        console.log("record", record);
        return (
            <>
                <Modal
                    centered
                    open={standardShitTimeViewModal}
                    onOk={() => setStandardShitTimeViewModal(false)}
                    onCancel={() => setStandardShitTimeViewModal(false)}
                    footer={null}
                >

                    {
                        viewStandardShiftTimeData?.shifts[0]?.timings.map((item, index) => {
                            return (
                                <div style={{ display: "flex", gap: "50px" }}>
                                    {
                                        index > 0
                                        &&
                                        <>
                                            <p>Shift {index + 1}</p>
                                            <p>
                                                {item.start_time} to {item.end_time}
                                            </p>
                                        </>

                                    }
                                </div>
                            )

                        })
                    }
                </Modal>
            </>
        )
    }

    const renderCustomShiftTime = (record) => {
        return (
            <>
                <Modal
                    centered
                    open={customeShitTimeViewModal}
                    onOk={() => setCustomeShitTimeViewModal(false)}
                    onCancel={() => setCustomeShitTimeViewModal(false)}
                    footer={null}
                    width={600}
                >
                    {
                        viewCustomeShiftTimeData.shifts.map((item, index) => {

                            return (
                                <div style={{ marginBottom: "5px", display: "flex", gap: "50px" }}>
                                    week {item.week_number}
                                    {
                                        item?.timings.map((time, timeIndex) => {

                                            return (
                                                <div>
                                                    {time.start_time} to {time.end_time}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </Modal>

            </>
        );
    };

    const screenSize = useScreenSize();
    const navigate = useNavigate();
    const [addendancePolicy, setAddendancePolicy] = useState([]);
    const [setPolicyId, setSetPolicyId] = useState(null);
    const [specificPolicyDetails, setSpecificPolicyDetails] = useState({});
    const [shiftModel, setShiftModel] = useState(false)
    const fetchSpecificPolicy = async () => {
        try {
            const data = await request({
                method: "get",
                url: `attendance/policy/details/?policy_id=${setPolicyId}`,
            })
            setSpecificPolicyDetails(data.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (setPolicyId) {
            fetchSpecificPolicy()
        }
    }, [setPolicyId])

    const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ]
    const [page, setPage] = useState(1);
    const fetchAttendancePolicy = async () => {
        const data = await request({
            method: "get",
            url: `attendance/policies/all/?page=${page}&page_size=20`,
        })
        setAddendancePolicy([
            ...addendancePolicy,
            ...data?.pagination_data?.data
        ])

        setPage(prevPage => prevPage + 1);
        // return data;
    }
    useEffect(() => {
        fetchAttendancePolicy()
    }, [])


    // useEffect(() => {
    //     console.log("addendancePolicy", addendancePolicy)
    // }, [addendancePolicy])

    const [standardViewModal, setStandardViewModal] = useState(false)

    const renderStandardWeeklyOff = (data) => {
        return (
            <>
                <Modal
                    centered
                    open={standardViewModal}
                    onOk={() => setStandardViewModal(false)}
                    onCancel={() => setStandardViewModal(false)}
                    footer={null}
                >
                    <div className='renderStandardWeeklyOffOuterDiv'>
                        <p>All Weeks</p>
                        <div>
                            {
                                specificPolicyDetails?.week_off_entries?.slice(0, 1).map((weekDetail, dayIndex) => {
                                    return (
                                        <div className='renderStandardWeeklyOffContainer' key={dayIndex}>
                                            <div className='renderStandardWeeklyDaysContainer'>
                                                {
                                                    days.map((day, index) => {
                                                        const selected = (weekDetail.week_off[index] === 1 || weekDetail.week_off[index] === 0.5);

                                                        return (
                                                            <div>
                                                                <div
                                                                    className={selected ? weekDetail.week_off[index] === 0.5 ? "selectedHalfDay" : "selectedFullDay" : "disabledFullDay"}
                                                                >
                                                                    {day.slice(0, 2)}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Modal>
            </>
        )
    }

    const [customeViewModal, setCustomeViewModal] = useState(false)
    const renderCustomWeeklyOff = (data) => {
        return (
            <>
                <Modal
                    centered
                    open={customeViewModal}
                    onOk={() => setCustomeViewModal(false)}
                    onCancel={() => setCustomeViewModal(false)}
                    footer={null}
                >
                    {specificPolicyDetails?.week_off_entries?.map((weekDetail, weekIndex) => (
                        <div key={weekIndex} >

                            <div className='renderCustomWeeklyOffContainer'>

                                <div className='selectNoOfDaysContainer'>
                                    <div className='selectNoOfDaysInnerContainer'>
                                        <p className='weekNumberText'>Week {weekIndex + 1}</p>
                                    </div>
                                </div>
                                <div className='selectWeeksContainer'>
                                    <div style={{ display: "flex", gap: 20 }}>
                                        {days.map((day, index) => {
                                            const isDaySelected = weekDetail.week_off[index] === 1 || weekDetail.week_off[index] === 0.5;
                                            return (
                                                <div
                                                    key={index}
                                                    className={isDaySelected ? weekDetail.week_off[index] === 0.5 ? "selectedHalfDay" : "selectedFullDay" : "disabledFullDay"}
                                                >
                                                    {day.slice(0, 2)}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))}
                </Modal>

            </>
        );
    };

    // useEffect(() => {
    //     ref?.current?.scrollIntoView({ behavior: 'instant' })
    //     document.body.scrollTop = document.documentElement.scrollTop = 0;
    // }, []);

    const renderUI = (dataList) => {

        return dataList?.map((item, index) => {
            return (
                <>
                    <div className={mobileStyles.cardContainer}>
                        <div className={mobileStyles.box} >
                            <div className="flexSpaceBetween">
                                <p className={mobileStyles.policyName} >{item?.policy_name}</p>
                                <img src={edit} alt="edit" onClick={() => { navigate(`/admin/attendance/dashboard/policy-form/${item.policy_id}`, { replace: true }) }} />
                            </div>
                        </div>

                        {/* shift timings */}
                        <div className={mobileStyles.box}>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px" }}>
                                <img src={time} alt="time" />
                                {
                                    item?.is_standard_shift
                                        ?
                                        <p style={{ fontSize: "16px", color: "#8A8A8A", margin: "0px" }}>Standard Shift</p>
                                        :
                                        <p style={{ fontSize: "16px", color: "#8A8A8A", margin: "0px" }}>Custome Shift</p>
                                }
                                <img src={info} alt="info" width={18} height={18}
                                    onClick={() => {
                                        setShiftModel(true)
                                        setSetPolicyId(item.policy_id)
                                    }}
                                />
                            </div>
                        </div>

                        {/* work policy */}
                        <div style={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px" }}>
                            <img src={duration} alt="duration" />
                            <p style={{ fontSize: "16px", color: "#8A8A8A", margin: "0px" }}>{item?.working_policy}</p>
                        </div>

                        {/* location */}
                        <div>
                            {
                                item.policy_location
                                &&
                                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                    <img src={location} alt="location" />
                                    <p style={{ fontSize: "16px", color: "#8A8A8A", margin: "0px" }}>{item?.policy_location}</p>
                                </div>
                            }
                        </div>

                        {/* weekOff */}
                        <div className={mobileStyles.box}>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px" }}>
                                <img src={weekOff} alt="weekOff" width={15} height={15} />
                                {
                                    item.is_week_off_standard
                                        ?
                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ fontSize: "16px", color: "#8A8A8A", margin: "0px" }}>Standard week off</p>
                                            <img src={info} alt="info" width={18} height={18}
                                                onClick={() => {
                                                    setStandardViewModal(true)
                                                    setSetPolicyId(item.policy_id)
                                                }}
                                            />
                                        </div>
                                        :
                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ fontSize: "16px", color: "#8A8A8A", margin: "0px" }}>Custom week off</p>
                                            <img src={info} alt="info" width={18} height={18}
                                                onClick={() => {
                                                    setCustomeViewModal(true)
                                                    setSetPolicyId(item.policy_id)
                                                }}
                                            />
                                        </div>
                                }

                            </div>
                        </div>


                    </div>
                    <hr style={{ border: "1px solid #F0F0F0", width: "100%" }} />
                </>
            )
        })
    }

    const renderUIDesktop = (dataList) => {
        return <Table
            columns={attendancePolicyColumns}
            dataSource={dataList}
            pagination={false}
            sticky={{ offsetHeader: 0 }}
        // onRow={record => ({
        //     onClick: () => {
        //         navigate(`/admin/attendance/dashboard/policy-form/${record.policy_id}`, { replace: true })
        //     }
        // })}
        />

    }

    return (
        <div ref={ref}>
            <div className='attendancePolicyFullViewContainer'>
                {
                    screenSize.width < 768
                        ?
                        // mobile view

                        <div >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px", padding: "0 16px" }}>
                                <p className={mobileStyles.heading} >Attendance Policy</p>
                                <img src={add} alt=""
                                    width={25} height={25}
                                    onClick={() => navigate('/admin/attendance/dashboard/policy-form', { replace: true })}
                                />

                            </div>


                            <div className={mobileStyles.cardListContainer}>
                                <InfiniteScrollComponent
                                    apiEndpoint="attendance/policies/all/"
                                    renderUI={renderUI}
                                    initialPage={1}
                                />
                            </div>
                        </div>


                        :
                        // Desktop View
                        <>
                            {/* <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                            <img src={back} alt="back" />
                            <span style={{ paddingLeft: "10px" }}>Back</span>
                        </p> */}
                            {/* <h1>Attendance</h1> */}
                            <div style={{ background: "white", padding: "20px" }}>
                                <div className='flexSpaceBetween' style={{ marginBottom: "20px" }}>
                                    <p
                                        style={{ fontSize: "20px", fontWeight: "600" }}
                                    >Attendance Policy</p>
                                    <Button
                                        className='addNewPolicy'
                                        onClick={() => navigate('/admin/attendance/dashboard/policy-form', { replace: true })}>
                                        Add New Policy
                                    </Button>
                                </div>
                                <InfiniteScrollComponent
                                    apiEndpoint="attendance/policies/all/"
                                    renderUI={renderUIDesktop}
                                    initialPage={1}
                                />
                                {/* <>
                                {
                                    <DynamicTable
                                        fetchData={fetchAttendancePolicy}
                                        columns={attendancePolicyColumns}
                                        handleRowClick={() => { }}
                                        pageSize={5}
                                        showPagination={true}
                                    />
                                }
                            </> */}
                                {/* <LazyLoadTable
                                columns={attendancePolicyColumns}
                                data={addendancePolicy}
                                loadMoreData={fetchAttendancePolicy}
                                handleRowClick={() => { }}
                            // showPagination={true}
                            // totalCount={totalCount}
                            /> */}
                            </div>
                        </>

                }

                <Modal
                    centered
                    open={shiftModel}
                    onOk={() => setShiftModel(false)}
                    onCancel={() => {
                        setShiftModel(false)
                        setSpecificPolicyDetails({})
                        setSetPolicyId("")
                    }}
                    footer={null}
                >
                    {
                        specificPolicyDetails?.is_standard_shift
                            ?
                            <div>
                                <p style={{ fontSize: "18px", color: "#161616" }}>Shifts</p>
                                <p style={{ fontSize: "16px", color: "#161616", margin: "0px", marginBottom: "5px" }}>All Weeks</p>
                                {
                                    specificPolicyDetails?.shifts[0]?.timings?.map((item, index) => {
                                        return (
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <p style={{ color: "#8A8A8A", fontSize: "14px", margin: "0px", marginBottom: "5px" }}>Shift {index + 1}</p>
                                                <p style={{ color: "#333333", fontSize: "14px", margin: "0px", marginBottom: "5px" }}>
                                                    {item.start_time} ---- {item.end_time}
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div>
                                <p style={{ fontSize: "18px", color: "#161616" }}>Shifts</p>
                                {
                                    specificPolicyDetails?.shifts?.map((weekItem, index) => {
                                        return (
                                            <>
                                                <p style={{ fontSize: "16px", color: "#161616", margin: "0px", marginBottom: "5px" }}>Week {index + 1}</p>
                                                {
                                                    weekItem?.timings?.map((timingItem, index) => {
                                                        return (
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <p style={{ color: "#8A8A8A", fontSize: "14px", margin: "0px", marginBottom: "5px" }}>Shift {index + 1}</p>
                                                                <p style={{ color: "#333333", fontSize: "14px", margin: "0px", marginBottom: "5px" }}>
                                                                    {timingItem?.start_time} ---- {timingItem?.end_time}
                                                                </p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    })
                                }
                            </div>
                    }
                </Modal>

                <Modal
                    centered
                    open={customeViewModal}
                    onOk={() => setCustomeViewModal(false)}
                    onCancel={() => setCustomeViewModal(false)}
                    footer={null}
                >
                    {specificPolicyDetails?.week_off_entries?.map((weekDetail, weekIndex) => (
                        <div key={weekIndex} >

                            <div className='renderCustomWeeklyOffContainer'>

                                <div className='selectNoOfDaysContainer'>
                                    <div className='selectNoOfDaysInnerContainer'>
                                        <p className='weekNumberText'>Week {weekIndex + 1}</p>
                                    </div>
                                </div>
                                <div className='selectWeeksContainer'>
                                    <div style={{ display: "flex", gap: 20 }}>
                                        {days.map((day, index) => {
                                            const isDaySelected = weekDetail.week_off[index] === 1 || weekDetail.week_off[index] === 0.5;
                                            return (
                                                <div
                                                    key={index}
                                                    className={isDaySelected ? weekDetail.week_off[index] === 0.5 ? "selectedHalfDay" : "selectedFullDay" : "disabledFullDay"}
                                                >
                                                    {day.slice(0, 2)}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))}
                </Modal>

                <Modal
                    centered
                    open={standardViewModal}
                    onOk={() => setStandardViewModal(false)}
                    onCancel={() => setStandardViewModal(false)}
                    footer={null}
                >
                    <div className='renderStandardWeeklyOffOuterDiv'>
                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>Week Off</p>
                        <p>All Weeks</p>
                        <div>
                            {
                                specificPolicyDetails?.week_off_entries?.slice(0, 1).map((weekDetail, dayIndex) => {
                                    return (
                                        <div className='renderStandardWeeklyOffContainer' key={dayIndex}>
                                            <div className='renderStandardWeeklyDaysContainer'>
                                                {
                                                    days.map((day, index) => {
                                                        const selected = (weekDetail.week_off[index] === 1 || weekDetail.week_off[index] === 0.5);

                                                        return (
                                                            <div>
                                                                <div
                                                                    className={selected ? weekDetail.week_off[index] === 0.5 ? "selectedHalfDay" : "selectedFullDay" : "disabledFullDay"}
                                                                >
                                                                    {day.slice(0, 2)}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default AttendancePolicyFullView