// api.js

import axios from 'axios';
import { useSelector } from 'react-redux';

const base_url = process.env.REACT_APP_BASE_URL;
const frontend_base_url = process.env.REACT_APP_FRONT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: base_url,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    const companyName = window.location.href.split(frontend_base_url)[1].split("/")[1];
    const childCompanyName = localStorage.getItem("childCompany");


    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }

    config.headers['Origin'] = base_url;
    if (companyName) {
      config.headers['Tenant-Header'] = companyName.toLocaleLowerCase();
    }

    if (childCompanyName) {
      config.headers['Org-Header'] = childCompanyName;
    }



    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const request = async (options) => {
  try {
    const response = await axiosInstance(options);
    return response.data;
  } catch (error) {
    const companyName = window.location.href.split(frontend_base_url)[1].split("/")[1];
    const token = localStorage.getItem('token');
    if (error.response.status === 401) {
      const isLogin = window.location.href.split(frontend_base_url)[1].split("/")[2] === "login";

      if (!isLogin) {
        localStorage.clear();
        window.location.href = `/${companyName}/login`;
      }
    }
    if (error.response.status === 406) {
      localStorage.clear();
      window.location.href = `/app`;
    }
    throw error;
  }
};
export default request;
// export default axiosInstance;




