import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import "./PersonalDetails.scss"

import { useSelector, useDispatch } from 'react-redux'
import { setPersonalDetails } from "../../../redux/reducer/EmployeeReducer"
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import getScreenSize from "../../../utils/getScreenSize";

function PersonalDetails({ next }) {
    let personalDetails = useSelector((state) => state.employee.personalDetails)
    let org = useSelector((state) => state.employee.org)
    const dispatch = useDispatch()
    const ref = useRef(null);
    const navigate = useNavigate();
    const screenSize = getScreenSize();

    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [userPersonalDetail, setUserPersonalDetail] = useState({
        employee_name: "",
        personal_email: "",
        contact: "",
        address: "",
        dob: "",
        gender: "",
        marital_status: "",
        anniversary: ""
    })


    const onHandleChange = (key, value) => {
        if (key == "marital_status" && value == "1") {
            setUserPersonalDetail({ ...userPersonalDetail, [key]: value, anniversary: "" })
            dispatch(setPersonalDetails({ ...userPersonalDetail, [key]: value, anniversary: "" }));
        }
        else {
            setUserPersonalDetail({ ...userPersonalDetail, [key]: value })
            dispatch(setPersonalDetails({ ...userPersonalDetail, [key]: value }));
        }
    }

    // const handleMaritalStatusChange = (maritalStatus) => {
    //     if (maritalStatus == '0') {
    //         setShow(true)
    //     } else {
    //         setShow(false)
    //     }
    // };
    const onFinish = (values) => {

        dispatch(setPersonalDetails(values));

        next();
    };



    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        setUserPersonalDetail({ ...userPersonalDetail, ...personalDetails })
    }, []);

    return (
        <div className='boxShadow personalFormContainer' ref={ref}>
            <div>

                {/* <hr /> */}
                <Form
                    form={form}
                    layout="vertical"
                    // initialValues={personalDetails}
                    fields={[
                        {
                            name: ["personal_email"],
                            value: personalDetails?.personal_email
                        },
                        {
                            name: ["employee_name"],
                            value: personalDetails?.employee_name
                        },
                        {
                            name: ["dob"],
                            value: personalDetails?.dob
                        },
                        {
                            name: ["gender"],
                            value: personalDetails?.gender
                        },
                        {
                            name: ["marital_status"],
                            value: personalDetails?.marital_status
                        },
                        {
                            name: ["anniversary"],
                            value: personalDetails?.anniversary
                        },
                        {
                            name: ["contact"],
                            value: personalDetails?.contact
                        },
                        {
                            name: ["address"],
                            value: personalDetails?.address
                        }
                    ]}
                    onFinish={onFinish}
                    autoComplete="true"
                >
                    <Row>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Name"
                                name="employee_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Employee Name',
                                    },
                                ]}
                            >
                                <Input
                                    className='inputField'
                                    onChange={(e) => onHandleChange("employee_name", e.target.value)}
                                    placeholder="Name"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Personal Email"
                                name="personal_email"
                            >
                                <Input
                                    className='inputField'
                                    onChange={(e) => onHandleChange("personal_email", e.target.value)}
                                    placeholder='Personal Email'
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Date of Birth"
                                name="dob"
                            >
                                <DatePicker
                                    format={"YYYY-MM-DD"}
                                    className='inputField'
                                    // disabledDate={(current) => current && current > dayjs().endOf('day')}
                                    onChange={(date, dateString) => onHandleChange("dob", date)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Gender"
                                name="gender"
                            >
                                <Select
                                    className='dropDown'
                                    onChange={(value) => onHandleChange("gender", value)}
                                    placeholder="Select Gender"
                                >
                                    <Select.Option value={0}>Male</Select.Option>
                                    <Select.Option value={1}>Female</Select.Option>
                                    <Select.Option value={2}>Other</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Contact No."
                                name="contact"
                                rules={[
                                    {
                                        pattern: /^[0-9]{10}$/, // A regular expression that matches a 10-digit contact number
                                        message: 'Please enter a valid 10-digit contact number!',
                                    },
                                ]}
                            >
                                <Input
                                    type='number'
                                    placeholder='Enter Contact No.'
                                    className='inputField'
                                    onChange={(e) => onHandleChange("contact", e.target.value)}
                                />
                            </Form.Item>
                            {/* </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}> */}
                            <Form.Item
                                label="Marital Status"
                                name="marital_status"
                            >
                                <Select
                                    className='dropDown'
                                    onChange={(value) => onHandleChange("marital_status", value)}
                                    placeholder="Select Marital Status"
                                >
                                    <Select.Option value={0}>Married</Select.Option>
                                    <Select.Option value={1}>Single</Select.Option>
                                </Select>
                            </Form.Item>


                        </Col>
                        {/* visible for mobile */}
                        {
                            screenSize.width < 768 &&
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                {
                                    (userPersonalDetail.marital_status == "0" || personalDetails?.marital_status == "0") && <Form.Item
                                        label="Anniversary"
                                        name="anniversary"
                                    >
                                        <DatePicker
                                            className='inputField'
                                            // disabledDate={(current) => current && current > dayjs().endOf('day')}
                                            onChange={(date, dateString) => onHandleChange("anniversary", date)}
                                            placeholder="Anniversary"
                                        />
                                    </Form.Item>
                                }
                            </Col>
                        }
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Address"
                                name="address"
                            >
                                <TextArea
                                    rows={5}
                                    // className='inputField
                                    size='large'
                                    onChange={(e) => onHandleChange("address", e.target.value)}
                                    placeholder="Address"
                                />
                            </Form.Item>
                        </Col>


                        {/* visible for desktop */}
                        {
                            screenSize.width > 768 &&
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                {
                                    (userPersonalDetail.marital_status == "0" || personalDetails?.marital_status == "0") && <Form.Item
                                        label="Anniversary"
                                        name="anniversary"
                                    >
                                        <DatePicker
                                            className='inputField'
                                            // disabledDate={(current) => current && current > dayjs().endOf('day')}
                                            onChange={(date, dateString) => onHandleChange("anniversary", date)}
                                        />
                                    </Form.Item>
                                }
                            </Col>
                        }




                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>

                        </Col>
                    </Row>


                    <div className="buttonContainer">
                        <Button className='button' htmlType='submit' >Next</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default PersonalDetails