import React from 'react'
import ApplyJob from '../../container/Recruitment/User/ApplyJob/ApplyJob';

import calendar from "../../assets/calendar.svg";
import location from "../../assets/location.svg";
import moment from 'moment';

function ApplyReferCardList({ item, index, btnContent, loginUserData }) {
    return (
        <div className='mobileContainer'>
            <div key={index} className='adminJobListCard'>
                <div className='headingContainer'>
                    <span className='title'>{item?.job_title}</span >{ }
                    {
                        item?.id && <ApplyJob title={"Apply"} jobId={item?.id} btnContent={btnContent} loginUserData={loginUserData} />
                    }
                </div>
                <span className='function'>{item?.industry}</span >
                <div className='adminJobListCardFooter'>
                    <div>
                        <span style={{ display: "flex", alignItems: "center" }}>
                            <img src={calendar} alt="" style={{ height: "14px" }} />
                            <span className='date'>{moment(item?.posting_date).format('DD-MM-YYYY')}</span>
                        </span>
                        <span style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                            <img src={location} alt="" /> {" "}
                            <span className='location'>
                                {
                                    item?.location.map((el, index) => {
                                        return (
                                            <span key={index}>
                                                <span>{el}</span>
                                                {
                                                    item?.location?.length - 1 !== index && <span> , </span>
                                                }
                                            </span>

                                        )
                                    })
                                }
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplyReferCardList