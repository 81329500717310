import React, { useEffect, useState } from 'react'
import request from '../../../../utils/axios';
import "./RecruiterSearchBar.scss"

function RecruiterSearchBar({
    setExternalRecruiterList, // set table data for search employee 
    fetchTableDataFromApi, // if input field get empty then setTableData function call api and set data in table
}) {
    const [searchRecruiterList, setSearchRecruiterList] = useState([])
    const handleSelectChange = async (value) => {
        try {
            const response = await request({
                method: "get",
                url: `recruiter/details?user_id=${value}`,
            })

            console.log("response", response.data);

            setExternalRecruiterList([response.data])
            console.log("setLatestJobList ....................");
            setSearchRecruiterList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const search = async (data) => {
        try {
            const response = await request({
                method: "get",
                url: `recruitment/recruiter/search?name=${data}`,
               
            })

            setSearchRecruiterList(response)
            if (data === "") {
                fetchTableDataFromApi()
            }
        }
        catch (err) {
            console.log(err);
        }
    }


    return (
        <div className="search-container">
            <input
                type="text"
                placeholder="Search Recruiters"
                onChange={(e) => search(e.target.value)}
            />
            {searchRecruiterList.length > 0 && (
                <ul>
                    {searchRecruiterList.map((item, index) => (
                        <li key={index} onClick={() => handleSelectChange(item.id)}>
                            {item.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default RecruiterSearchBar