import { Col, Input, Row, Select, Form } from 'antd'
import React from 'react'
import desktopStyle from "./EditFeed.module.scss"
import getScreenSize from "../../../../utils/getScreenSize";

function EditFeed() {
    const { TextArea } = Input;
    const screenSize = getScreenSize();
    return (
        <div>
            {
                screenSize.width < 768
                    // mobile
                    ?
                    <div style={{ padding: "0px 16px", marginTop: "80px" }}>
                        <Row>
                            <Col span={24}>
                                <p style={{ fontSize: "24px", fontWeight: "500" }}>Post Preview</p>
                            </Col>
                            <Col span={24}>
                                <p style={{ fontSize: "24px", fontWeight: "500" }}>Edit Post</p>
                                <Form
                                    layout="vertical"
                                    // onFinish={(values) => console.log(values)}
                                    style={{ marginTop: "20px" }}
                                // fields={[
                                //     // {
                                //     //     name: "title",
                                //     //     value: postDetails.title
                                //     // },
                                //     // {
                                //     //     name: "choseTitle",
                                //     //     value: postDetails.choseTitle
                                //     // },
                                //     // {
                                //     //     name: "text",
                                //     //     value: postDetails.text
                                //     // },
                                //     // {
                                //     //     name: "uploadType",
                                //     //     value: postDetails.uploadType
                                //     // },
                                // ]}
                                >
                                    <Form.Item
                                        label="Edit Post Visible"
                                        name="title"
                                    >
                                        <Input
                                            placeholder="Appreciation Post "
                                            size='large'
                                        // onChange={(e) => setPostDetails({
                                        //     ...postDetails,
                                        //     title: e.target.value
                                        // })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Edit Employee"
                                        name="choseTitle"
                                    >
                                        <Select
                                            size='large'
                                            style={{ width: "100%" }}
                                            placeholder="Select Category"
                                        // value={postDetails.uploadType}
                                        // onChange={(value) => setPostDetails({
                                        //     ...postDetails,
                                        //     uploadType: value
                                        // })}
                                        >
                                            <Select.Option value="1">Option 1</Select.Option>
                                            <Select.Option value="2">Option 2</Select.Option>
                                            <Select.Option value="3">Option 3</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Edit Text"
                                        name="text"
                                    >
                                        <TextArea
                                            placeholder="Write text here"
                                            size='large'
                                            rows={4}
                                        // onChange={(e) => setPostDetails({
                                        //     ...postDetails,
                                        //     text: e.target.value
                                        // })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Edit image"
                                        name="uploadType"
                                    >
                                        <Select
                                            size='large'
                                            style={{ width: "100%" }}
                                            placeholder="Select Category"
                                        // value={postDetails.uploadType}
                                        // onChange={(value) => setPostDetails({
                                        //     ...postDetails,
                                        //     uploadType: value
                                        // })}
                                        >
                                            <Select.Option value="1">Default Image</Select.Option>
                                            <Select.Option value="2">Upload Image</Select.Option>
                                            <Select.Option value="3">None</Select.Option>
                                        </Select>
                                    </Form.Item>

                                    {/* {
                                    postDetails.uploadType === "1" &&
                                    <div style={{ marginBottom: "20px" }}>
                                        <img src="https://picsum.photos/200" alt="" width={100} height={100} />
                                    </div>
                                } */}

                                    {
                                        // postDetails.uploadType === "2" &&
                                        // <Form.Item
                                        //     // label="Upload File"
                                        //     name="document"

                                        // >
                                        //     <div className={desktopStyle.fileUploadContainer}>
                                        //         <input
                                        //             type="file"
                                        //             id="file-upload"
                                        //             className={desktopStyle.inputFile}
                                        //         // onChange={(e) => {
                                        //         //     const selectedFile = e.target.files[0];
                                        //         //     const maxSizeInBytes = 5 * 1024 * 1024;

                                        //         //     const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', "image/jpg"];

                                        //         //     // Check if the selected file is an allowed image type
                                        //         //     if (allowedImageTypes.includes(selectedFile.type)) {
                                        //         //         // Check if the file size is within the allowed limit
                                        //         //         if (selectedFile.size <= maxSizeInBytes) {
                                        //         //             setPostDetails({
                                        //         //                 ...postDetails,
                                        //         //                 image: selectedFile
                                        //         //             })
                                        //         //         } else {
                                        //         //             // File size exceeds the limit
                                        //         //             // setFile(null);
                                        //         //             setPostDetails({
                                        //         //                 ...postDetails,
                                        //         //                 image: ""
                                        //         //             })
                                        //         //             alert('File size exceeds the limit of 5MB.');
                                        //         //         }
                                        //         //     } else {
                                        //         //         // File is not an allowed image type
                                        //         //         setPostDetails({
                                        //         //             ...postDetails,
                                        //         //             image: ""
                                        //         //         })
                                        //         //         alert('Please upload a valid image file (JPEG, PNG, GIF).');
                                        //         //     }
                                        //         // }
                                        //         // }
                                        //         />
                                        //         <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                        //             <label htmlFor="file-upload" className={desktopStyle.fileUploadLabel}>

                                        //             </label>
                                        //             {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                        //             {/* <p style={{ zIndex: 1, margin: "10px" }}>{postDetails?.image ? postDetails?.image.name : "Select a image or drag and drop here"}</p> */}
                                        //         </div>
                                        //     </div>
                                        // </Form.Item>
                                    }

                                    <button
                                        style={{ border: "none", background: "#008080", color: "white", height: "40px", width: "150px", borderRadius: "5px" }}
                                    >Post</button>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                    :
                    // desktop
                    <div>
                        <p style={{ fontSize: "32px", fontWeight: "500" }}>Feeds</p>
                        <div>
                            <Row>
                                <Col span={12}>
                                    <p style={{ fontSize: "24px", fontWeight: "500" }}>Edit Post</p>
                                    <Form
                                        layout="vertical"
                                        // onFinish={(values) => console.log(values)}
                                        style={{ marginTop: "20px" }}
                                    // fields={[
                                    //     // {
                                    //     //     name: "title",
                                    //     //     value: postDetails.title
                                    //     // },
                                    //     // {
                                    //     //     name: "choseTitle",
                                    //     //     value: postDetails.choseTitle
                                    //     // },
                                    //     // {
                                    //     //     name: "text",
                                    //     //     value: postDetails.text
                                    //     // },
                                    //     // {
                                    //     //     name: "uploadType",
                                    //     //     value: postDetails.uploadType
                                    //     // },
                                    // ]}
                                    >
                                        <Form.Item
                                            label="Edit Post Visible"
                                            name="title"
                                        >
                                            <Input
                                                placeholder="Appreciation Post "
                                                size='large'
                                            // onChange={(e) => setPostDetails({
                                            //     ...postDetails,
                                            //     title: e.target.value
                                            // })}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Edit Employee"
                                            name="choseTitle"
                                        >
                                            <Select
                                                size='large'
                                                style={{ width: "100%" }}
                                                placeholder="Select Category"
                                            // value={postDetails.uploadType}
                                            // onChange={(value) => setPostDetails({
                                            //     ...postDetails,
                                            //     uploadType: value
                                            // })}
                                            >
                                                <Select.Option value="1">Option 1</Select.Option>
                                                <Select.Option value="2">Option 2</Select.Option>
                                                <Select.Option value="3">Option 3</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Edit Text"
                                            name="text"
                                        >
                                            <TextArea
                                                placeholder="Write text here"
                                                size='large'
                                                rows={4}
                                            // onChange={(e) => setPostDetails({
                                            //     ...postDetails,
                                            //     text: e.target.value
                                            // })}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Edit image"
                                            name="uploadType"
                                        >
                                            <Select
                                                size='large'
                                                style={{ width: "100%" }}
                                                placeholder="Select Category"
                                            // value={postDetails.uploadType}
                                            // onChange={(value) => setPostDetails({
                                            //     ...postDetails,
                                            //     uploadType: value
                                            // })}
                                            >
                                                <Select.Option value="1">Default Image</Select.Option>
                                                <Select.Option value="2">Upload Image</Select.Option>
                                                <Select.Option value="3">None</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        {/* {
                                        postDetails.uploadType === "1" &&
                                        <div style={{ marginBottom: "20px" }}>
                                            <img src="https://picsum.photos/200" alt="" width={100} height={100} />
                                        </div>
                                    } */}

                                        {
                                            // postDetails.uploadType === "2" &&
                                            // <Form.Item
                                            //     // label="Upload File"
                                            //     name="document"

                                            // >
                                            //     <div className={desktopStyle.fileUploadContainer}>
                                            //         <input
                                            //             type="file"
                                            //             id="file-upload"
                                            //             className={desktopStyle.inputFile}
                                            //         // onChange={(e) => {
                                            //         //     const selectedFile = e.target.files[0];
                                            //         //     const maxSizeInBytes = 5 * 1024 * 1024;

                                            //         //     const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', "image/jpg"];

                                            //         //     // Check if the selected file is an allowed image type
                                            //         //     if (allowedImageTypes.includes(selectedFile.type)) {
                                            //         //         // Check if the file size is within the allowed limit
                                            //         //         if (selectedFile.size <= maxSizeInBytes) {
                                            //         //             setPostDetails({
                                            //         //                 ...postDetails,
                                            //         //                 image: selectedFile
                                            //         //             })
                                            //         //         } else {
                                            //         //             // File size exceeds the limit
                                            //         //             // setFile(null);
                                            //         //             setPostDetails({
                                            //         //                 ...postDetails,
                                            //         //                 image: ""
                                            //         //             })
                                            //         //             alert('File size exceeds the limit of 5MB.');
                                            //         //         }
                                            //         //     } else {
                                            //         //         // File is not an allowed image type
                                            //         //         setPostDetails({
                                            //         //             ...postDetails,
                                            //         //             image: ""
                                            //         //         })
                                            //         //         alert('Please upload a valid image file (JPEG, PNG, GIF).');
                                            //         //     }
                                            //         // }
                                            //         // }
                                            //         />
                                            //         <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                            //             <label htmlFor="file-upload" className={desktopStyle.fileUploadLabel}>

                                            //             </label>
                                            //             {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                            //             {/* <p style={{ zIndex: 1, margin: "10px" }}>{postDetails?.image ? postDetails?.image.name : "Select a image or drag and drop here"}</p> */}
                                            //         </div>
                                            //     </div>
                                            // </Form.Item>
                                        }

                                        <button
                                            style={{ border: "none", background: "#008080", color: "white", height: "40px", width: "150px", borderRadius: "5px" }}
                                        >Post</button>
                                    </Form>
                                </Col>
                                <Col span={12}>
                                    <p style={{ fontSize: "24px", fontWeight: "500" }}>Post Preview</p>
                                </Col>
                            </Row>
                        </div>
                    </div>
            }
        </div>

    )
}

export default EditFeed