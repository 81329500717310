import { createSlice } from '@reduxjs/toolkit'

const initialState = []
const selectedEmployeePayrollProcessingSlice = createSlice({
    name: 'selectedEmployeePayrollProcessing',
    initialState,
    reducers: {
        setSelectedEmployeePayrollProcessing: (state, action) => {
            return action.payload
        },
    },
})
export const { setSelectedEmployeePayrollProcessing } = selectedEmployeePayrollProcessingSlice.actions;

export default selectedEmployeePayrollProcessingSlice.reducer;
