import React, { useEffect, useState } from 'react';
import company from "../../../assets/Recruitment/recruitmentLogin.svg"
import { useParams } from 'react-router-dom';
import request from '../../../utils/axios';
import getScreenSize from '../../../utils/getScreenSize';

function RecruitmentHeader() {
    const params = useParams()
    const screenSize = getScreenSize();
    const [companyDetails, setCompanyDetails] = useState({})

    const fetchCompanyDetails = async () => {
        try {
            if (params.company_name) {
            const data = await request({
                method: "get",
                url: `recruitment/external/company/details?name=${params.company_name}`,
            });
            setCompanyDetails(data);
            }
            else {
                const data = await request({
                    method: "get",
                    url: `recruitment/external-recruiter/company/details`,
                });
                setCompanyDetails(data);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        fetchCompanyDetails()
    }, [])

    return (
        <div style={{ borderBottom: "1px solid #D9D9D9" }}>
            {
                screenSize.width < 768
                    ?
                    <div style={{ padding: "20px 16px" }}>
                        <img src={companyDetails?.company_logo} alt="companyImage" width={40} height={40} style={{ marginRight: "10px" }} />
                        <span>{companyDetails?.org_name}</span>
                    </div>
                    :
                    <div style={{ padding: "20px 10vw" }}>
                        <img src={companyDetails?.company_logo} alt="companyImage" width={40} height={40} style={{ marginRight: "10px" }} />
                        <span>{companyDetails?.org_name}</span>
                    </div>
            }

        </div>
    )
}

export default RecruitmentHeader