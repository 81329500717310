import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import DynamicTable from '../../../component/TableWithPagination/TableWithPagination';
import request from '../../../utils/axios';
import useScreenSize from '../../../utils/getScreenSize';
import calendar from "../../../assets/calendar.svg"
import location from "../../../assets/location.svg"
import InfiniteScroll from '../../../component/InfiniteScroll/InfiniteScroll';
import { useNavigate } from 'react-router-dom';
import back from "../../../assets/Recruitment/back.svg"
// import "../ReferralOpening/ReferralOpening.scss"
import "../../Recruitment/User/ReferralOpening/ReferralOpening.scss"
import CommonTable from '../../../component/CommonTable/CommonTable';
import { toast } from 'react-toastify';
import { Button, Col, DatePicker, Input, Modal, Popover, Row, Select, Switch, Form } from 'antd';
import styles from "../Org.module.scss"
import edit from "../../../assets/edit.svg";
import add from "../../../assets/Org/add.svg"
import AddOrEditAdmin from '../AddOrEditAdmin/AddorEditAdmin';

function OrgAdminFullView() {
    const ref = useRef(null);
    const [form] = Form.useForm()
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [adminList, setAdminList] = useState(null)
    const [reload, setReload] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })
    const [selectedOrganisation, setSelectedOrganisation] = useState(null);


    const adminCol = [
        {
            title: 'Name',
            dataIndex: 'employee',
            key: 'employee',
        },
        {
            title: 'Access',
            dataIndex: 'role',
            key: 'role',
            render: (text, record) =>
                <span style={{ display: "flex", gap: "15px" }}>
                    {
                        record?.role?.map((item, index) => {
                            return (
                                <span key={index} style={{ padding: "5px 25px", border: "1px solid #008080", color: "#008080", borderRadius: "16px" }}>{item}</span>
                            )
                        })}
                </span>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <img src={edit} alt="" width={18} height={18} style={{ cursor: "pointer" }}
                    onClick={() => {
                        setAdminOrgDetailId(record.id);
                        setAminModal(true)
                        setType("Update")
                    }}
                />
        }
    ]

    const screenSize = useScreenSize();

    const [AllAdminList, setAllAdminList] = useState([])
    const fetchAdminData = async (page, page_size) => {
        const data = await request({
            method: "get",
            url: `org/admin/all?page=${pagination.current}&page_size=${pagination.pageSize}${selectedOrganisation ? `&org=${selectedOrganisation}` : ""}`,
        })
        setAllAdminList(data.pagination_data.data)
        setPagination({
            ...pagination,
            total: data?.pagination_data?.total
        })
        return data
    }
    const apiCallForMobileView = async () => {
        const admin = await fetchAdminData(1, 20)
        // setAdminList(admin.pagination_data.data)
    }

    const [organisationList, setOrganisationList] = useState([]);
    const fetchOrganisationList = async () => {
        try {
            const data = await request({
                method: "get",
                url: `org/all`,
            });
            setOrganisationList(data.data);
        } catch (error) {
            console.log(error);
        } finally {
            // setReload(false)
        }
    };

    useEffect(() => {
        if (screenSize.width < 768) {
            apiCallForMobileView()
        }
    }, [reload])

    useEffect(() => {
        fetchAdminData()
    }, [pagination.current, selectedOrganisation])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        fetchOrganisationList()
    }, [])

    // const { useForm } = Form;
    // const [form] = useForm();
    const [adminModal, setAminModal] = useState(false);

    const [employeeList, setEmployeeList] = useState([])
    const [moduleList, setModuleList] = useState([])
    const [updatesMade, setUpdatesMade] = useState(false);
    const [editAdminDetailId, setAdminOrgDetailId] = useState(null);
    const [type, setType] = useState(null);
    const getEmployeeList = async () => {

        try {
            let data
            if (selectedOrganisation) {
                console.log(selectedOrganisation, "selectedOrganisation................................");
                data = await request({
                    method: "get",
                    url: `get-all-employees?org=${selectedOrganisation}`,
                })

            } else {
                data = await request({
                    method: "get",
                    url: `get-all-employees`,
                })
            }
            setEmployeeList(data.data)
        }
        catch (err) {
            console.log(err);
        }
    }

    const getModuleList = async () => {
        let data = await request({
            method: "get",
            url: `module/all`,
        })
        setModuleList(data.data)
    }

    const getAdminDetail = async () => {
        let data = await request({
            method: "get",
            url: `org/admin/${editAdminDetailId}/details`,
        })
        form.setFieldsValue(data.data.data)
    }
    const onFinishAdminForm = async (values) => {
        console.log("values", values);

        if (type === "Add") {
            await request({
                method: "post",
                url: `org/admin/create`,
                data: values
            })
        }
        else {
            await request({
                method: "put",
                url: `org/admin/${editAdminDetailId}/update/`,
                data: values
            })

        }
        setReload(!reload)
        setAminModal(false)
        fetchAdminData(1, 5)
        setAdminOrgDetailId(null)
        form.resetFields();
    }

    useEffect(() => {
        getEmployeeList()
        getModuleList()

    }, [])

    useEffect(() => {
        if (type === "Update" && editAdminDetailId) {
            getAdminDetail()
        }
    }, [editAdminDetailId])

    useEffect(() => {
        console.log(employeeList, "employeeList");
    }, [employeeList])

    useEffect(() => {
        getEmployeeList()
    }, [selectedOrganisation])
    const handleFormChange = () => {
        setUpdatesMade(true)
    }

    const renderPosts = (posts) => (
        <>
            {posts.map((item, index) => (
                <div className='mobileContainer'>
                    <div key={index} className='adminJobListCard'>
                        <div className='headingContainer'>
                            <span className='title'>{item.candidate_name}</span >
                            <div style={{ display: "flex" }}>
                                <button style={{ backgroundColor: "#28B15F", color: "white", padding: "4px 12px", border: "none", fontSize: "13px", borderRadius: "2px" }}>Selected</button>
                            </div>
                        </div>
                        <span className='function'>{item.job}</span ><br />
                        <span className='function'>{item.job}</span ><br />
                        <span className='function'>{item.job}</span >
                        <div className='adminJobListCardFooter'>
                            {/* <div> */}


                            <span style={{ display: "flex", alignItems: "center" }}>
                                <img src={location} alt="" /> {" "}
                                <span className='location'>
                                    {
                                        item.location.map((el, index) => {
                                            return (
                                                <span key={index}>
                                                    <span>{el}</span>
                                                    {
                                                        item?.location?.length - 1 !== index && <span> , </span>
                                                    }
                                                </span>
                                            )
                                        })
                                    }
                                </span>
                            </span>
                            {/* </div> */}

                        </div>
                    </div>

                </div>
            ))}
        </>
    );

    return (
        <div className='Container' ref={ref}>
            {
                screenSize.width < 768 ?  // mobile view

                    <div style={{ marginTop: "2vh", }}>
                        <div className={styles.card} style={{ marginBottom: "40px" }}>
                            <div className='flexSpaceBetween '>
                                <p className={styles.cardHeading}>Admin</p>
                                <img src={add} alt="" style={{ cursor: "pointer" }} width={32} height={32}
                                    onClick={() => navigate("/admin/org/admin/add", { replace: true })}
                                />
                            </div>
                            <div style={{ margin: "20px 0px" }}>
                                <Select
                                    size="large"
                                    placeholder="Select Organisation"
                                    style={{ width: "100%" }}
                                    value={selectedOrganisation}
                                    onChange={(value) => {
                                        setSelectedOrganisation(value);
                                    }}
                                >
                                    {organisationList?.map((item, index) => {
                                        return (
                                            <Select.Option key={index} value={item.id}>
                                                {item.org_name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </div>
                            {
                                AllAdminList?.map((item, index) => (
                                    <div className='cardTextBox'>
                                        <div className='flexSpaceBetween'>
                                            <p className={styles.otherDetails}>{item?.employee}</p>
                                            <img src={edit} alt="" width={18} height={18} style={{ cursor: "pointer" }}
                                                onClick={() => navigate("/admin/org/admin/edit/" + item?.id, { replace: true })}
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexWrap: "wrap", overflow: "auto" }}>
                                            {
                                                item?.role?.map((el, index) => {
                                                    return (
                                                        <span key={index} style={{ fontSize: "12px", padding: "5px 25px", border: "1px solid #008080", color: "#008080", borderRadius: "16px", marginRight: "10px", marginBlock: "5px" }}>{el}</span>
                                                    )
                                                })}
                                        </div>
                                        <hr />
                                    </div>
                                ))
                            }

                        </div>
                        <div style={{ height: "8px", background: "#F7F8FC" }}></div>
                    </div>

                    :

                    <>
                        <div>
                            <div style={{ background: "white", padding: "30px" }}>

                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "16px", marginBottom: "20px", width: "100%" }}>
                                    <h1>Admin</h1>
                                    <div style={{ display: "flex", alignItems: "center", gap: "18px" }}>
                                        <Select
                                            size="large"
                                            placeholder="Select Organisation"
                                            style={{ width: "250px" }}
                                            value={selectedOrganisation}
                                            onChange={(value) => {
                                                setSelectedOrganisation(value);
                                            }}
                                        >
                                            {organisationList?.map((item, index) => {
                                                return (
                                                    <Select.Option key={index} value={item.id}>
                                                        {item.org_name}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                        {/* <AddOrEditAdmin
                                            btn={<Button type="primary" style={{ background: "#008080" }}>Add</Button>}
                                            type="Add"
                                            reload={reload}
                                            setReload={setReload}
                                        /> */}
                                        <Button type="primary" style={{ background: "#008080", height: "40px" }}
                                            onClick={() => {
                                                // setAdminOrgDetailId(record.id);
                                                setAminModal(true)
                                                setType("Add")
                                            }}
                                        >Add</Button>
                                    </div>

                                </div>

                                <CommonTable
                                    columns={adminCol}
                                    data={AllAdminList}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    handleRowClick={() => { }}
                                />

                            </div>

                            {/* <DynamicTable
                                fetchData={fetchAdminData}
                                columns={adminCol}
                                handleRowClick={() => { }}
                                pageSize={5}
                                showPagination={false}
                                reload={reload}
                            /> */}
                        </div>

                    </>

            }

            <Modal
                centered
                open={adminModal}
                footer={null}
                width={1000}
                onCancel={() => setAminModal(false)}
            >
                <div className='addOrEditAdmin'>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onFinishAdminForm}
                        onValuesChange={handleFormChange}
                    >
                        <Row gutter={[32, 32]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Employee Name"
                                    name="employee"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your employee name!',
                                        },
                                    ]}
                                >
                                    {
                                        type === "Update"
                                            ?
                                            <Input disabled className='inputField' />
                                            :
                                            <Select
                                                className='dropDown'
                                                disabled={type === "Update"}
                                            >
                                                {
                                                    employeeList.map((item, index) => {
                                                        return (
                                                            <Select.Option key={index} value={item.employement_number}>{item.employee_name}</Select.Option>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Select>
                                    }

                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Module"
                                    name="role"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your role!',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        className='multiSelectDropDown'
                                        showSearch={false}
                                        size='large'
                                    >
                                        {
                                            moduleList.map((item, index) => {
                                                return (
                                                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                                )
                                            }
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Button
                            className='button'
                            htmlType='submit'
                            disabled={type === "Update" && !updatesMade}
                        >
                            {type}
                        </Button>

                    </Form>
                </div>

            </Modal>
        </div>
    )
}

export default OrgAdminFullView 