import { Col, Radio, Row } from "antd";
import React, { useEffect, useState } from "react";
import desktopStyle from "./LmsQuizDesktop.module.scss";
import request from "../../../../utils/axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import getScreenSize from "../../../../utils/getScreenSize";

function LmsQuiz() {
  const params = useParams();
  const screenSize = getScreenSize();

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questionList, setQuestionList] = useState("");
  const [completionTime, setCompletionTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);

  const fetchQuestionList = async () => {
    try {
      const response = await request({
        method: "get",
        url: `/lms/training-videos/quiz/question?id=${params.id}`,
      });
      const data = response?.data?.questions?.map((item, index) => {
        return {
          ...item,
          response_recieved: "",
        };
      });
      setQuestionList(data);
      setTimeLeft(response?.data?.completion_time * 60);
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleRadioChange = (e, index) => {
    const updatedData = [...questionList];
    updatedData[index].response_recieved = e.target.value;
    setQuestionList(updatedData);
  };

  const onHandleSubmit = async () => {
    try {
      const data = questionList.map((item, index) => {
        return {
          question: item.question_id,
          response_recieved: item.response_recieved,
        };
      });
      await request({
        method: "post",
        url: `/lms/employee/quiz/response?id=${params.id}`,
        data: data,
      });
      toast.success("Quiz submitted successfully", {
        position: "bottom-left",
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "bottom-left",
      });
    }
  };

  // Function to format time as mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    fetchQuestionList();
  }, []);

  useEffect(() => {
    // Exit early when the timer reaches 0
    if (timeLeft === 0) {
      onHandleSubmit();
      return;
    }

    // Set up the interval to decrement the time left
    const timerId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timerId);
  }, [timeLeft]);

  return (
    <div>
      {screenSize.width < 768 ? (
        <div>
          <div style={{ padding: "0px 16px" }}>
            <p
              style={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#4A4A4A",
                marginTop: "70px",
              }}
            >
              Personal development- Quiz
            </p>
          </div>
          <div style={{ padding: "0px 16px" }}>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#161616",
              }}
            >
              {questionList[currentQuestion]?.question}
            </p>
            <div>
              <Radio.Group
                onChange={(e) => onHandleRadioChange(e, currentQuestion)}
                value={questionList[currentQuestion]?.response_recieved}
                style={{ width: "100%" }}
              >
                <Row gutter={[32, 16]}>
                  <Col span={24}>
                    <div className={desktopStyle.radioBox}>
                      <Radio
                        value={questionList[currentQuestion]?.options.option_1}
                      >
                        {questionList[currentQuestion]?.options.option_1}
                      </Radio>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className={desktopStyle.radioBox}>
                      <Radio
                        value={questionList[currentQuestion]?.options.option_2}
                      >
                        {questionList[currentQuestion]?.options.option_2}
                      </Radio>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className={desktopStyle.radioBox}>
                      <Radio
                        value={questionList[currentQuestion]?.options.option_3}
                      >
                        {questionList[currentQuestion]?.options.option_3}
                      </Radio>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className={desktopStyle.radioBox}>
                      <Radio
                        value={questionList[currentQuestion]?.options.option_4}
                      >
                        {questionList[currentQuestion]?.options.option_4}
                      </Radio>
                    </div>
                  </Col>
                </Row>
              </Radio.Group>
            </div>

            {currentQuestion !== 0 && (
              <button
                style={{
                  width: "92%",
                  height: "40px",
                  borderRadius: "5px",
                  border: "none",
                  color: "white",
                  backgroundColor: "#008080",
                  cursor: "pointer", // Change cursor if disabled
                  position: "absolute",
                  bottom: "90px",
                }}
                onClick={() => setCurrentQuestion(currentQuestion - 1)}
              >
                Previous
              </button>
            )}
            {currentQuestion !== questionList.length - 1 && (
              <button
                style={{
                  width: "92%",
                  height: "40px",
                  borderRadius: "5px",
                  border: "none",
                  color: "white",
                  backgroundColor: "#008080",
                  cursor: "pointer", // Change cursor if disabled
                  position: "absolute",
                  bottom: "90px",
                }}
                onClick={() => setCurrentQuestion(currentQuestion + 1)}
              >
                Next
              </button>
            )}
            {currentQuestion === questionList.length - 1 && (
              <>
                <button
                  style={{
                    width: "92%",
                    height: "40px",
                    borderRadius: "5px",
                    border: "none",
                    color: "white",
                    backgroundColor: "#008080",
                    cursor: "pointer", // Change cursor if disabled
                    position: "absolute",
                    bottom: "140px",
                  }}
                  onClick={() => setCurrentQuestion(currentQuestion - 1)}
                >
                  Previous
                </button>
                <button
                  style={{
                    width: "92%",
                    height: "40px",
                    borderRadius: "5px",
                    border: "none",
                    backgroundColor: "#008080",
                    color: "white",
                    cursor: "pointer", // Change cursor if disabled
                    position: "absolute",
                    bottom: "90px",
                  }}
                  onClick={() => onHandleSubmit()}
                >
                  Submit
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={desktopStyle.container}>
          <div className={desktopStyle.subContainer}>
            <div className={desktopStyle.headerContainer}>
              <p className={desktopStyle.heading}>Personal development- Quiz</p>

              <div className={desktopStyle.btnBox}>
                <button
                  className={desktopStyle.btn}
                  onClick={() => onHandleSubmit()}
                >
                  Submit Quiz
                </button>
                <div className={desktopStyle.timerBox}>
                  <p className={desktopStyle.time}>{formatTime(timeLeft)}</p>
                  <p className={desktopStyle.timeleft}>(min left)</p>
                </div>
              </div>
            </div>

            <div className={desktopStyle.questionBox}>
              <p>
                <span className={desktopStyle.questionNumber}>
                  Question {currentQuestion + 1}:
                </span>
                <span className={desktopStyle.questionText}>
                  {questionList[currentQuestion]?.question}
                </span>
              </p>
              <div>
                <Radio.Group
                  onChange={(e) => onHandleRadioChange(e, currentQuestion)}
                  value={questionList[currentQuestion]?.response_recieved}
                  style={{ width: "100%" }}
                >
                  <Row gutter={[32, 16]}>
                    <Col md={12}>
                      <div className={desktopStyle.radioBox}>
                        <Radio
                          value={
                            questionList[currentQuestion]?.options.option_1
                          }
                        >
                          {questionList[currentQuestion]?.options.option_1}
                        </Radio>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className={desktopStyle.radioBox}>
                        <Radio
                          value={
                            questionList[currentQuestion]?.options.option_2
                          }
                        >
                          {questionList[currentQuestion]?.options.option_2}
                        </Radio>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className={desktopStyle.radioBox}>
                        <Radio
                          value={
                            questionList[currentQuestion]?.options.option_3
                          }
                        >
                          {questionList[currentQuestion]?.options.option_3}
                        </Radio>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className={desktopStyle.radioBox}>
                        <Radio
                          value={
                            questionList[currentQuestion]?.options.option_4
                          }
                        >
                          {questionList[currentQuestion]?.options.option_4}
                        </Radio>
                      </div>
                    </Col>
                  </Row>
                </Radio.Group>
              </div>

              {currentQuestion !== 0 && (
                <button
                  className={desktopStyle.questionBtn}
                  onClick={() => setCurrentQuestion(currentQuestion - 1)}
                >
                  Previous
                </button>
              )}
              {currentQuestion !== questionList.length - 1 && (
                <button
                  className={desktopStyle.questionBtn}
                  onClick={() => setCurrentQuestion(currentQuestion + 1)}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LmsQuiz;
