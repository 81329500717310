import React, { useState } from 'react'
import CommonTable from '../../../../component/CommonTable/CommonTable'

function MyTeam() {
    const myTeamColumns = [
        {
            title: 'Employee Name',
            dataIndex: 'employee_id',
            key: 'employee_id',
        },
        {
            title: 'New Goal Plan Staus',
            dataIndex: 'attendance_date',
            key: 'attendance_date',
        },
        {
            title: 'Appraisal Status',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'MSF Status',
            dataIndex: 'role',
            key: 'role',
            render: (text, record) => <>
                {
                    record.designation + " / " + record.department
                }
            </>
        },
    ]

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    return (
        <div style={{ padding: "50px" }}>
            <div style={{ background: "white", padding: "30px", borderRadius: "5px" }}>
                <div>
                    <p style={{ fontSize: "24px", fontWeight: "600", color: "#161616" }}>My Team</p>
                </div>
                <div>
                    <CommonTable
                        columns={myTeamColumns}
                        data={[]}
                        pagination={pagination}
                        setPagination={setPagination}
                        handleRowClick={() => { }}
                    />
                </div>
            </div>
        </div>
    )
}

export default MyTeam