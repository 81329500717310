import React, { useState } from 'react'
import {
    Tabs,
} from "antd";
import PaySchedule from '../PaySchedule/PaySchedule';
import Form24Setting from '../24QSetting/Form24Setting';

import getScreenSize from "../../../../../utils/getScreenSize";

function PayrollSettingTabs() {
    const items = [
        {
            key: '1',
            label: 'Pay Schedule',
            children: <PaySchedule />,
        },
        {
            key: '2',
            label: '24Q settings',
            children: <Form24Setting />
        },
    ];
    const screenSize = getScreenSize();
    const changeTabsValue = (e) => {
        setTabsValue(e)
    }

    const [tabsValue, setTabsValue] = useState("1")


    return (
        <>
            {
                screenSize.width < 768
                    ?
                    <>
                        <div style={{ background: "white" }}>
                            <div style={{ padding: "0px 16px", display: "flex", alignItems: "center", flexWrap: "nowrap", overflow: "auto" }}>
                                {
                                    items.map((item) => {
                                        return (
                                            <div key={item.key} style={{
                                                padding: "5px 10px",
                                                cursor: "pointer",
                                                border: item.key === tabsValue ? "1px solid #008080" : "1px solid #C3C3C3",
                                                width: "fit-content",
                                                borderRadius: "21px",
                                                fontSize: "14px",
                                                color: item.key === tabsValue ? "#008080" : "#C3C3C3",
                                                float: "left",
                                                marginRight: "8px",
                                                marginBottom: "8px",
                                                fontSize: "14px",
                                                whiteSpace: "nowrap",
                                            }}
                                                onClick={() => setTabsValue(item.key)}
                                            >
                                                {item.label}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div>
                                {
                                    items[tabsValue - 1].children
                                }
                            </div>
                        </div>
                    </>
                    :
                    <div style={{ padding: "50px" }}>
                        <div style={{ padding: "20px", background: "white" }}>
                            <Tabs
                                activeKey={tabsValue}
                                items={items}
                                onChange={changeTabsValue}
                            />
                        </div>
                    </div>
            }
        </>

    )
}

export default PayrollSettingTabs