import React, { useEffect, useRef, useState } from 'react'
import useScreenSize from '../../../../../utils/getScreenSize';
import { useNavigate } from 'react-router-dom';
import back from "../../../../../assets/Recruitment/back.svg"
import { Col, Row, Table } from 'antd';
import request from '../../../../../utils/axios';
import edit from "../../../../../assets/edit.svg"
import CommonTable from '../../../../../component/CommonTable/CommonTable';
import InfiniteScrollComponent from '../../../../../component/InfiniteScroll/InfiniteScroll';

function DetailFullView() {
    const detailsColumns = [
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Attendance Type",
            dataIndex: "work_mode",
            key: "work_mode",
        },
        {
            title: "On Time %",
            dataIndex: "on_time_percent",
            key: "on_time_percent",
        },
        {
            title: "Leaves",
            dataIndex: "total_absent_days",
            key: "total_absent_days",
        },
    ]
    const ref = useRef(null);
    const navigate = useNavigate();
    const screenSize = useScreenSize();
    // const [loading, setLoading] = useState(true);
    const [logsDetails, setLogsDetails] = useState();
    // const [page, setPage] = useState(1)
    // const page_size = 2
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })

    const fetchEmployeeList = async () => {
        const data = await request({
            method: "get",
            url: `attendance/team/employees/all/?page=${pagination.current}&page_size=${pagination.pageSize}`,
        })

        setLogsDetails(data.pagination_data.data)
        setPagination({
            ...pagination,
            total: data?.pagination_data?.total

        })

        // setLoading(false)
    }

    useEffect(() => {
        fetchEmployeeList()
    }, [pagination.current])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    const renderUI = (dataList) => {
        return dataList?.map((item, index) => {
            return (
                <>
                    <div
                        style={{
                            alignSelf: "center",
                            marginTop: "15px",
                            padding: "0px 16px"
                        }}
                    >
                        <h2
                            style={{
                                fontSize: 18,
                                color: "black",
                            }}
                        >
                            {item?.employee_name}
                        </h2>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: 16,
                                    color: "black",
                                }}
                            >
                                Attendance Policy
                            </p>
                            <p
                                style={{
                                    fontFamily: "Poppins-Regular",
                                    fontSize: 16,
                                    color: "#8A8A8A",
                                }}
                            >
                                {item?.work_mode}
                            </p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                marginTop: "1px",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: 16,
                                    color: "black",
                                }}
                            >
                                On Time %
                            </p>
                            <p
                                style={{
                                    fontSize: 16,
                                    color: "#8A8A8A",
                                }}
                            >
                                {item?.on_time_percent}%
                            </p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "1px",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: 16,
                                    color: "black",
                                }}
                            >
                                Leaves
                            </p>
                            <p
                                style={{
                                    fontSize: 16,
                                    color: "#8A8A8A",
                                }}
                            >
                                {item?.total_leaves_taken}
                            </p>
                        </div>
                    </div>
                    {
                        index !== dataList?.length - 1 && <hr />
                    }
                </>
            );
        })
    }

    return (
        <div className='Container'>
            {
                screenSize.width < 768
                    ?
                    // mobile view
                    <div
                        style={{
                            // padding: "10px",
                            backgroundColor: "white",
                            marginTop: "80px",

                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0px 16px"
                            }}
                        >
                            <p style={{ fontSize: "20px", fontWeight: "600", margin: "0px" }}>Attendance Details</p>
                        </div>

                        <InfiniteScrollComponent
                            apiEndpoint="attendance/team/employees/all/"
                            renderUI={renderUI}
                            initialPage={1}
                        />
                    </div>

                    :
                    // desktop view
                    <>
                        {/* <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                            <img src={back} alt="back" />
                            <span style={{ paddingLeft: "10px" }}>Back</span>
                        </p> */}
                        <h1>Attendance</h1>
                        <div className='referralSubContainer' style={{ background: "white" }}>
                            <CommonTable
                                columns={detailsColumns}
                                data={logsDetails}
                                pagination={pagination}
                                setPagination={setPagination}
                                handleRowClick={() => { }}
                            />
                        </div>
                    </>
            }

        </div>
    )
}


export default DetailFullView