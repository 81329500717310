import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
// import "./ApplyJob.scss"
import request from '../../../../utils/axios';
import { useParams } from 'react-router-dom'
import "./GiveFeedBack.scss"
import giveFeedBack from "../../../../assets/giveFeedBack.svg"
import { toast } from 'react-toastify';
function FeedBack({ feedback_for, btnContent, setReload, reload }) {
    const params = useParams();
    const [form] = Form.useForm()
    const [open, setOpen] = useState(false);

    const { TextArea } = Input;

    const onFinish = async (values) => {
        console.log("values", values, rate, feedback_for);
        if (rate === null) {
          toast.error("Please select rating", {
              position: "bottom-left",
          })  
        } 
        else{
            await request({
                method: "post",
                url: `recruitment/feedback/create/`,
                data: {
                    ...values,
                    ratings: rate,
                    feedback_for
                }
            })
    
            setReload(!reload)
            setOpen(false)
            form.resetFields();
        }

        
    }

    const [resumeFile, setResumeFile] = useState(null);

    const [roundList, setRoundList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

    const ratingList = [
        {
            id: 1,
            value: 1,
            color: "#FF5757"
        },
        {
            id: 2,
            value: 2,
            color: "#FE9494"
        },
        {
            id: 3,
            value: 3,
            color: "#FFD90F"
        },
        {
            id: 4,
            value: 4,
            color: "#B0DC00"
        },
        {
            id: 5,
            value: 5,
            color: "#91B501"
        }
    ]

    const [rate, setRate] = useState(null)
    return (
        <div onClick={(e) => { e.stopPropagation() }} >
            <div onClick={() => setOpen(true)}>
                {btnContent}
            </div>

            <Modal
                centered
                open={open}
                footer={null}
                width={1000}
                onCancel={() => setOpen(false)}
            >
                <div className='giveFeedBackContainer'>
                    <p style={{ fontSize: "20px", fontWeight: "600" }} >Feedback</p>
                    <div className='ratingContainer' >
                        {
                            ratingList.map((item) => {
                                return (
                                    <div key={item.id} style={{ marginRight: "5vw" }}>
                                        <div
                                            style={{ backgroundColor: item.color, }}
                                            className={rate === item.value ? "rating active" : "rating"}
                                            onClick={() => setRate(item.value)}
                                        >
                                            <span>{item.value}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        form={form}
                    >
                        <Row gutter={[32, 32]}>
                            <Col xs={{ span: 24 }} >
                                <Form.Item
                                    label="Round"
                                    name="round"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your round!',
                                        },
                                    ]}
                                >
                                    <Select
                                        className='dropDown'
                                        showSearch={false}
                                    >
                                        {
                                            roundList.map((item, index) => {
                                                return (
                                                    <Select.Option key={index} value={item}>{item}</Select.Option>
                                                )
                                            }
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[32, 32]}>
                            <Col xs={{ span: 24 }} >
                                <Form.Item
                                    name="feedback"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your feedback!',
                                        },
                                    ]}
                                >
                                    <TextArea
                                        rows={5}
                                        placeholder="Please enter your feedback"
                                        className='textArea'
                                    />
                                </Form.Item>
                            </Col>


                        </Row>
                            <Button
                                className='button'
                                htmlType='submit'
                            >Submit</Button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}

export default FeedBack

