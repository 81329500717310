import { LinkOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from "./HouseProperty.module.scss"
import request from '../../../../../utils/axios';
import update from 'immutability-helper';
import {
    // setHousePropertySection24SelfOccupiedProperty,
    // setHousePropertySection24LetOutProperty,
    setHouseProperty

} from "../../../../../redux/reducer/TaxDeclarationReducer"
import { useDispatch, useSelector } from 'react-redux';
function HouseProperty({ windowOpen }) {
    const dispatch = useDispatch()
    const houseProperty = useSelector(state => state.taxDeclaration.houseProperty)

    const [housePropertySectionList, setHousePropertySectionList] = useState([])
    const fetchHousePropertyDeclarationData = async () => {
        try {
            const data = await request({
                method: "GET",
                url: `regim_tax_rules/get-all-house-property-declaration`,
            })
            setHousePropertySectionList([...data.data])

            dispatch(setHouseProperty(data.data))
        }
        catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        fetchHousePropertyDeclarationData()
    }, [])


    const onHandleChange = (section, key, value, index) => {

        if (key === "attachment") {

            const reader = new FileReader();
            reader.readAsDataURL(value);
            reader.onload = () => {
                const updatedData = update(housePropertySectionList, {
                    [index]: { [key]: { $set: reader.result }, file_name: { $set: value.name } }
                });

                setHousePropertySectionList(updatedData)

                dispatch(setHouseProperty(updatedData))
            };
            reader.onerror = (error) => {
                console.error('Error uploading image: ', error);
            };
        }
        else {
            const updatedData = update(housePropertySectionList, {
                [index]: { [key]: { $set: value } }
            });

            setHousePropertySectionList(updatedData)

            dispatch(setHouseProperty(updatedData))
        }
    }


    return (
        <div className={styles.houseProperty}>
            <p className={styles.housePropertyText}>House Property</p>
            <div>
                <Form>
                    <Row gutter={32}>
                        {
                            housePropertySectionList?.map((item, index) => {
                                return (
                                    <div>
                                        <Col span={24}>
                                            <div className={styles.section24TextBox}>
                                                <p className={styles.section24Text}>{item?.title}</p>
                                            </div>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item>
                                                <Select
                                                    size='large'
                                                    onChange={(e) => onHandleChange("section24SelfOccupiedProperty", "choice", e, index)}
                                                    value={houseProperty[index]?.choice}
                                                    disabled={windowOpen}
                                                >
                                                    {item?.choiceList.map((item, index) => (
                                                        <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={18}></Col>
                                        <Col span={24}>
                                            {
                                                houseProperty[index]?.choice !== null &&
                                                <Row gutter={32}>
                                                    <Col span={6}>
                                                        <Form.Item>
                                                            <Input size='large'
                                                                placeholder='Enter Amount'
                                                                onChange={(e) =>
                                                                    onHandleChange("section24SelfOccupiedProperty", "declared_amount", e.target.value, index)
                                                                }
                                                                value={houseProperty[index]?.declared_amount}
                                                                disabled={windowOpen}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={10}>
                                                        <Form.Item
                                                        // label="Attach File"
                                                        // name="file"
                                                        >
                                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                <div
                                                                    className={styles.attachFileBox}
                                                                >
                                                                    <input
                                                                        type="file"
                                                                        id="file-upload"
                                                                        className={styles.inputFile}
                                                                        onChange={(e) =>
                                                                        // setFile(e.target.files && e.target.files[0])
                                                                        {
                                                                            console.log("e.target.files[0]", e.target.files[0])

                                                                            const selectedFile = e.target.files[0];
                                                                            // const maxSizeInBytes = 5 * 1024 * 1024;

                                                                            if (selectedFile) {
                                                                                onHandleChange("section24SelfOccupiedProperty", "attachment", selectedFile, index)
                                                                            }
                                                                        }
                                                                        }
                                                                        disabled={windowOpen}
                                                                    />
                                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>

                                                                        <label htmlFor="file-upload" >

                                                                        </label>
                                                                        {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                                                        <p className={styles.attachFileLabel} style={{ marginTop: "7px" }}>
                                                                            {/* {HRAData[index]?.file ? HRAData[index]?.file.name : */}
                                                                            {/* <> */}
                                                                            <LinkOutlined /> {" "}Attach File
                                                                            {/* </> */}
                                                                            {/* } */}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <p style={{ margin: '0' }}>{item?.file_name}</p>
                                                            </div>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                    </div>
                                )
                            })
                        }

                    </Row>
                </Form>
            </div>
        </div >
    )
}

export default HouseProperty