import React, { useEffect, useState } from 'react';
// import styles from "./EditBankDetails.module.scss";
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import request from '../../../utils/axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
function EditBankDetails() {
    const params = useParams();
    const [salaryDetails, setSalaryDetails] = useState({
        bank_name: '',
        ifsc_code: '',
        account_number: '',
        payment_mode: '',
        pan_no: '',
        payment_mode_id: '',
        uan_no: '',
      })
    const onFinish = async (values) => {
        try {
          const data = await request({
            method: "post",
            url: `/profile/bank/details/update?id=${salaryDetails.id}`,
            data: values
          })
          console.log(data)
        //   setIsEdit(false)
          fetchBankDetails()
          toast.success("Bank Details Updated Successfully", {
            position: "bottom-left",
        });
        }
        catch (err) {
          console.log(err)
          toast.error("Bank Details could not be updated, please try after some time", {
            position: "bottom-left",
        })
        }
      }
      const fetchBankDetails = async () => {
        try {
    
          let data;
          if (params?.id) {
            // console.log("on_admin_click")
            data = await request({
              method: "get",
              url: `/profile/bank/details/`,
            })
    
          }
          else {
            data = await request({
              method: "get",
              url: `/profile/bank/details/`,
            })
    
          }
    
          setSalaryDetails(data.data.data)
        }
        catch (error) {
          console.log("error", error);
        }
      }
    
      useEffect(() => {
        fetchBankDetails();
      }, [])
    return (
        <div>
            <div style={{ padding: "16px", background: "white" }}>
            <Form
                    layout="vertical"
                    onFinish={onFinish}
                    // onValuesChange={handleFormChange}
                    initialValues={salaryDetails}
                    fields={[
                      {
                          name: ["bank_name"],
                          value: salaryDetails.bank_name
                      },
                      {
                          name: ["ifsc_code"],
                          value: salaryDetails.ifsc_code
                      },
                      {
                        name: ["payment_mode"],
                        value: salaryDetails.payment_mode_id
                    },
                      {
                          name: ["account_number"],
                          value: salaryDetails.account_number
                      },
                      {
                          name: ["pan_no"],
                          value: salaryDetails.pan_no
                      },
                      {
                          name: ["uan_no"],
                          value: salaryDetails.uan_no,
                      }
                    ]}

                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Bank Name"
                                name="bank_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Bank Name!',
                                    },
                                ]}
                            >
                                <Input
                                  size='large'
                                  style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="IFSC Code"
                                name="ifsc_code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your IFSC code!!',
                                    },
                                ]}
                            >
                               <Input
                                            size='large'
                                            style={{ width: "80%" }}
                                        />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Account No."
                                name="account_number"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Account No.!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                        <Form.Item
                            label="Mode Of Payment"
                            name="payment_mode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your Mode Of Payment!',
                                },
                            ]}
                        >
                            <Select
                                size='large'
                            // onChange={handleMaritalStatusChange}
                            >
                                <Select.Option value={0}>Bank Transfer</Select.Option>
                                <Select.Option value={1}>Cheques</Select.Option>
                                <Select.Option value={2}>Cash</Select.Option>
                            </Select>
                        </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Pan No."
                                name="pan_no"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Pan No.!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="UAN No."
                                name="uan_no"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your UAN No.!',
                                    },
                                ]}
                            >
                                <Input
                                    size='large'
                                    style={{ width: "80%" }}
                                />
                            </Form.Item>
                        </Col>
                        
                    </Row>

                    <Button
                        htmlType='submit'
                        style={{ width: "100%", height: "47px", background: "#008080", color: "white" }}
                    >
                        Save
                    </Button>
                </Form>

            </div>
        </div>
    )
}

export default EditBankDetails