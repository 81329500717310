import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination';
import request from '../../../../utils/axios';
import useScreenSize from '../../../../utils/getScreenSize';
import calendar from "../../../../assets/calendar.svg"
import location from "../../../../assets/location.svg"
import InfiniteScroll from '../../../../component/InfiniteScroll/InfiniteScroll';
import { useNavigate } from 'react-router-dom';
import back from "../../../../assets/Recruitment/back.svg"
// import "../ReferralOpening/ReferralOpening.scss"
import "../../User/ReferralOpening/ReferralOpening.scss"
import CommonTable from '../../../../component/CommonTable/CommonTable';
import { toast } from 'react-toastify';
import { Button, Col, DatePicker, Input, Modal, Popover, Row, Select, Switch, Form } from 'antd';
import email from "../../../../assets/email.png"
import passoword from "../../../../assets/password.png";
import add from "../../../../assets/Org/add.svg"
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';

function RecruiterFullView() {
    const [form] = Form.useForm()
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })
    const ref = useRef(null);
    const onHandleChangeForRecruiters = async (value, id) => {
        if (value === 1) {
            const data = await request({
                url: `recruiter/status/update?user_id=${id}`,
                method: "PUT",
                data: {
                    status: 1
                }
            });
            getallexternalrecruiters();
            toast.success("Recruiter Activated successfully", {
                position: "bottom-left",
            });
        }

        if (value === 2) {
            const data = await request({
                url: `recruiter/status/update?user_id=${id}`,
                method: "PUT",
                data: {
                    status: 2
                }
            });
            getallexternalrecruiters();
            toast.success("Recruiter De-Activated successfully", {
                position: "bottom-left",
            });
        }


    }

    const externalrecruiterscolumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Password',
            dataIndex: 'recruiter_pass',
            key: 'recruiter_pass',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Select
                    value={record.status === null ? 0 : record.status}
                    onChange={(value) => onHandleChangeForRecruiters(value, record.id)}
                    style={{ width: 150 }}
                >
                    {/* <Select.Option value={0}>Select</Select.Option> */}
                    <Select.Option value={1} >Active</Select.Option>
                    <Select.Option value={2}>In-Active</Select.Option>
                </Select>
            ),
        },


    ]

    const screenSize = useScreenSize();

    const [externalRecruiterList, setExternalRecruiterList] = useState([])
    const getallexternalrecruiters = async () => {
        const data = await request({
            method: "get",
            url: `recruiter/all?page=1&page_size=4`,
        })
        setExternalRecruiterList(data?.pagination_data.data)
        // return data
        setPagination({
            ...pagination,
            total: data?.pagination_data?.total
        })
    }
    const onFinish = async (values) => {
        console.log('Success:', values);
        const data = await request({
            method: "post",
            url: "create/recruiter",
            data: values
        })
        toast.success("Recruiter Added successfully", {
            position: "bottom-left",
        })
        setOpenModal(false)
        form.resetFields();

        // Refetch the data after successful submission
        getallexternalrecruiters();

    }
    useEffect(() => {
        getallexternalrecruiters()
    }, [pagination.current])

    const renderPosts = (posts) => (
        <>
            {posts.map((item, index) => (
                <div className='mobileContainer'>
                    <div key={index} className='adminJobListCard'>
                        <div className='headingContainer'>
                            <span className='title'>{item.candidate_name}</span >
                            <div style={{ display: "flex" }}>
                                <button style={{ backgroundColor: "#28B15F", color: "white", padding: "4px 12px", border: "none", fontSize: "13px", borderRadius: "2px" }}>Selected</button>
                            </div>
                        </div>
                        <span className='function'>{item.job}</span ><br />
                        <span className='function'>{item.job}</span ><br />
                        <span className='function'>{item.job}</span >
                        <div className='adminJobListCardFooter'>
                            {/* <div> */}


                            <span style={{ display: "flex", alignItems: "center" }}>
                                <img src={location} alt="" /> {" "}
                                <span className='location'>
                                    {
                                        item.location.map((el, index) => {
                                            return (
                                                <span key={index}>
                                                    <span>{el}</span>
                                                    {
                                                        item?.location?.length - 1 !== index && <span> , </span>
                                                    }
                                                </span>
                                            )
                                        })
                                    }
                                </span>
                            </span>
                            {/* </div> */}

                        </div>
                    </div>

                </div>
            ))}
        </>
    );

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    const renderUI = (dataList) => {

        return dataList?.map((item, index) => {
            return (
                <>
                    <div key={index} style={{ padding: "0px 16px" }} >

                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }} >
                                    {item.name}
                                </p>
                                <Select
                                    value={item.status === null ? 0 : item.status}
                                    onChange={(value) => onHandleChangeForRecruiters(value, item.id)}
                                    style={{ width: 150 }}
                                >
                                    {/* <Select.Option value={0}>Select</Select.Option> */}
                                    <Select.Option value={1} >Active</Select.Option>
                                    <Select.Option value={2}>In-Active</Select.Option>
                                </Select>
                            </div>
                            <p style={{ fontSize: "12px", fontWeight: "400", color: "#616161" }}>
                                <img src={email} alt="email" width={20} height={20} />{" "}
                                {item.email}</p>
                            <p style={{ fontSize: "12px", fontWeight: "400", color: "#616161" }}>
                                <img src={passoword} alt="rupee" width={20} height={20} />{" "}
                                {item.recruiter_pass}
                            </p>

                        </div>
                    </div>
                    {
                        index !== externalRecruiterList.length - 1 && <hr />
                    }
                </>
            )
        })
    }

    return (
        <div className='Container' ref={ref}>
            {
                screenSize.width < 768 ?  // mobile view


                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 16px", margin: "10px 0px" }}>
                            <p style={{ fontSize: "24px", fontWeight: "500", margin: "0px" }}>External Recruiters</p>
                            <img src={add} alt="add" onClick={() => setOpenModal(true)} />
                        </div>

                        <InfiniteScrollComponent
                            apiEndpoint="recruiter/all"
                            renderUI={renderUI}
                            initialPage={1}
                        />

                    </div>


                    :

                    <>
                        <div>

                            <div className='referralSubContainer'>
                                <div style={{ display: "flex", justifyContent: "space-between", gap: "30px" }}>
                                    <h1>External Recruiters</h1>

                                    <Button
                                        onClick={() => {
                                            setOpenModal(true)
                                        }}
                                        className='postJobBtn' style={{ width: "135px", marginBottom: "10px", marginRight: "10px" }}>
                                        Add Recruiter
                                    </Button>
                                </div>
                                <div style={{ marginTop: "2vh" }}>
                                    <CommonTable
                                        columns={externalrecruiterscolumns}
                                        data={externalRecruiterList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        handleRowClick={() => { }}
                                    />
                                </div>
                            </div>
                        </div>

                    </>

            }
            <Modal
                open={openModal}
                onOk={() => { }}
                onCancel={() => {
                    setOpenModal(false)
                    form.resetFields();
                }}
                footer={null}
                centered
                width={540}
            >
                <p className='leavePolicyModelHeading'>New Recruiter</p>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                // onValuesChange={onValuesChange}
                >
                    <Row gutter={[16, 16]} className="mb-3">
                        <Col xs={24}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter recruiter Name!',
                                    },
                                ]}
                            >
                                <Input className="form-control" placeholder="Name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="mb-3">
                        <Col xs={24}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter recruiter email!',
                                    },
                                ]}
                            >
                                <Input className="form-control" placeholder="Email" />
                            </Form.Item>
                        </Col>
                    </Row>



                    <Button
                        htmlType='submit'
                        style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                    >
                        Submit
                    </Button>
                </Form>
            </Modal>


        </div>
    )
}

export default RecruiterFullView 