import { Button, Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import request from '../../../../utils/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

function RequisitionForm() {
    const [form] = Form.useForm()
    const navigate = useNavigate();
    const ref = useRef(null);
    
    const onFinish = async (values) => {

        try {

            await request({
                method: "post",
                url: `recruitment/requisitions/create`,
                data: values
            })

            // setOpen(false)
            form.resetFields();
            // setReload(!reload)

            toast.success("Requisition request submitted successfully", {
                position: "bottom-left",
            })

            navigate("/recruitment", { replace: true })

        } catch (err) {
            console.log(err)
            toast.error("Requisition request could not be submitted, please try after some time", {
                position: "bottom-left",
            })
        }

    }

    const [industryList, setIndustryList] = useState([]);
    const getIndustryList = async () => {
        const data = await request({
            method: "get",
            url: `/recruitment/industry/all/`,
        })

        setIndustryList(data.data)
    }


    const [cityList, setCityList] = useState([]);
    const getCityList = async () => {

        const data = await request({
            method: "get",
            url: `/recruitment/location/all/`,
        })
        setCityList(data.data)
    }


    const [employeeFunctionList, setEmployeeFunctionList] = useState([]);
    const getEmployeeFunctionList = async () => {
        const data = await request({
            method: "get",
            url: `/recruitment/employee_types/`,
        })
        setEmployeeFunctionList(data.data)
    }


    const [jobTypeList, setJobTypeList] = useState([]);
    const getJobTypeList = async () => {
        const data = await request({
            method: "get",
            url: `/recruitment/job_types/`,
        })
        setJobTypeList(data.data)
    }


    useEffect(() => {
        getIndustryList();
        getEmployeeFunctionList();
        getJobTypeList();
        getCityList()
    }, [])

    return (
        <div style={{ padding: "0px 16px" }}>
            <p style={{ fontSize: "20px", fontWeight: "600", marginTop: "80px" }}>Add Rquisition</p>
            <div className='addRequisition'>
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    form={form}
                >

                    <Row gutter={[32, 12]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Job Title"
                                name="job_title"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter job title!',
                                    },
                                ]}
                            >
                                <Input
                                    className='inputField'
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Salary range Minimum"
                                name="min_salary"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter max salary!',
                                    },
                                ]}
                            >
                                <Input
                                    className='inputField'
                                    type='number'
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Salary range Maximum"
                                name="max_salary"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Current  Company!',
                                    }
                                ]}
                            >
                                <Input
                                    className='inputField'
                                    type='number'
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Job Type"
                                name="job_type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter job type!',
                                    },
                                ]}
                            >
                                <Select
                                    className='dropDown'
                                >
                                    {
                                        jobTypeList.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                            )
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Location"
                                name="location"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter location!',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    className='multiSelectDropDown'
                                    showSearch={false}
                                >
                                    {
                                        cityList.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                            )
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Industry"
                                name="industry"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter industry!',
                                    },
                                ]}
                            >
                                <Select
                                    className='dropDown'
                                >
                                    {
                                        industryList.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                            )
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Function"
                                name="job_function"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter the function!',
                                    },
                                ]}
                            >
                                <Input
                                    className='inputField'
                                    style={{ width: "100% " }}
                                />
                            </Form.Item>
                        </Col>


                    </Row>

                    <Button
                        style={{
                            width: "100%",
                            height: "40px",
                            backgroundColor: "#008080",
                            color: "white",
                            border: "none",
                            fontSize: "16px",
                            borderRadius: "2px",
                        }}
                        htmlType='submit'
                    >Add</Button>

                </Form>
            </div>
        </div>
    )
}

export default RequisitionForm