import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import "./AddRequisition.scss"
import request from '../../../../utils/axios';
import { toast } from 'react-toastify';

function AddRequisition({ jobId, btnContent, loginUserData, reload, setReload }) {
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm()


    const [industryList, setIndustryList] = useState([]);
    const getIndustryList = async () => {
        const data = await request({
            method: "get",
            url: `/recruitment/industry/all/`,
        })

        setIndustryList(data.data)
    }


    const [cityList, setCityList] = useState([]);
    const getCityList = async () => {

        const data = await request({
            method: "get",
            url: `/recruitment/location/all/`,
        })
        setCityList(data.data)
    }


    const [employeeFunctionList, setEmployeeFunctionList] = useState([]);
    const getEmployeeFunctionList = async () => {
        const data = await request({
            method: "get",
            url: `/recruitment/employee_types/`,
        })
        setEmployeeFunctionList(data.data)
    }


    const [jobTypeList, setJobTypeList] = useState([]);
    const getJobTypeList = async () => {
        const data = await request({
            method: "get",
            url: `/recruitment/job_types/`,
        })
        setJobTypeList(data.data)
    }

    useEffect(() => {
        getIndustryList();
        getEmployeeFunctionList();
        getJobTypeList();
        getCityList()
    }, [])

    const onFinish = async (values) => {

        try {

            await request({
                method: "post",
                url: `recruitment/requisitions/create`,
                data: values
            })

            setOpen(false)
            form.resetFields();
            setReload(!reload)

            toast.success("Requisition request submitted successfully", {
                position: "bottom-left",
            })

        } catch (err) {
            console.log(err)
            toast.error("Requisition request could not be submitted, please try after some time", {
                position: "bottom-left",
            })
        }

    }

    return (
        <div onClick={(e) => { e.stopPropagation() }} >
            <Button
                style={{ background: "#008080", color: "white", height: "40px",  border: "1px solid #008080", fontSize: "16px", borderRadius: "5px" }}
                onClick={() => setOpen(true)}
            >
                Add Requisition
            </Button>
            <Modal
                centered
                open={open}
                footer={null}
                width={1000}
                onCancel={() => setOpen(false)}
            >
                <p className='modelHeading'>Requisition Form</p>
                <div className='addRequisition'>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        form={form}
                    >

                        <Row gutter={[32, 32]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Job Title"
                                    name="job_title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your job_title!',
                                        },
                                    ]}
                                >
                                    <Input
                                        className='inputField'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Location"
                                    name="location"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your location!',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        className='multiSelectDropDown'
                                        showSearch={false}
                                    >
                                        {
                                            cityList.map((item, index) => {
                                                return (
                                                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                                )
                                            }
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[32, 32]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Salary Range (Min)"
                                    name="min_salary"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your max_salary!',
                                        },
                                    ]}
                                >
                                    <Input
                                        className='inputField'
                                        type='number'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Salary Range (Max)"
                                    name="max_salary"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your Current  Company!',
                                        }
                                    ]}
                                >
                                    <Input
                                        className='inputField'
                                        type='number'
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={[32, 32]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Industry"
                                    name="industry"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your industry!',
                                        },
                                    ]}
                                >
                                    <Select
                                        className='dropDown'
                                    >
                                        {
                                            industryList.map((item, index) => {
                                                return (
                                                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                                )
                                            }
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Function"
                                    name="job_function"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your linkedin_url!',
                                        },
                                    ]}
                                >
                                    <Select
                                        className='dropDown'
                                    >
                                        {
                                            employeeFunctionList.map((item, index) => {
                                                return (
                                                    <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                                )
                                            }
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Job Type"
                                    name="job_type"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your job_type!',
                                        },
                                    ]}
                                >
                                    <Select
                                        className='dropDown'
                                    >
                                        {
                                            jobTypeList.map((item, index) => {
                                                return (
                                                    <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                                                )
                                            }
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Button
                            className='button'
                            htmlType='submit'
                        >Add</Button>

                    </Form>
                </div>

            </Modal>
        </div>
    );
}

export default AddRequisition

