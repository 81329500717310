import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import "./Comment.scss"
import request from '../../../../utils/axios';
import edit from "../../../../assets/edit.svg"

function Comment({ jobId, btnType, data, date, setReload, reload }) {
    const { TextArea } = Input;
    const [form] = Form.useForm()
    const [open, setOpen] = useState(false);
    const [comment, setComment] = useState("")

    const onFinish = async (values) => {

        await request({
            method: "post",
            url: `attendance/employees/attendance/comment/update/?date=${date}`,
            data: values
        })

        setOpen(false)
        setReload(!reload)
        form.resetFields();
    }


    return (
        <div onClick={(e) => { e.stopPropagation() }} >
            {
                btnType === "Add" ?
                    <Button type="primary" style={{ color: "#3E3D3D", backgroundColor: "#EBEBEB", borderRadius: "3px", fontSize: "13px" }} onClick={() => setOpen(true)}>
                        Add Comment
                    </Button>
                    :
                    <img src={edit} alt="edit"
                        style={{ cursor: "pointer" }}
                        onClick={() =>{
                            setOpen(true)
                            setComment(data)
                        }} />
            }

            <Modal
                centered
                open={open}
                footer={null}
                width={700}
                onCancel={() => setOpen(false)}
            >
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={comment ? comment : data}
                    form={form}
                    fields={[
                        {
                            name: ["comment"],
                            value: comment ? comment : data
                        },

                    ]}

                >
                    <Row gutter={[32, 32]}>
                        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                            <Form.Item
                                name="comment"
                                label="Comment"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please add comment!',
                                    },
                                ]}
                            >
                                <TextArea
                                    rows={5}
                                    placeholder="Please enter your comment"
                                    className='textArea'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button
                        className='button'
                        htmlType='submit'
                    >{btnType}</Button>
                </Form>
            </Modal>
        </div>
    );
}

export default Comment