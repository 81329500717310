import React, { useState } from 'react'
import { Form, Input, Button, ConfigProvider } from 'antd';
import { useNavigate } from 'react-router-dom';

import "./ExternalRecruitmentLogin.scss"
// import login from "../../assets/login.png";
import login from "../../../assets/Recruitment/recruitmentLogin.svg";

import getScreenSize from "../../../utils/getScreenSize";

import request from "../../../utils/axios"
import ErrorImg from '../../../assets/Error.svg'
import desktopEngageLoginLogo from "../../../assets/desktopEngageLogin.svg"
function ExternalRecruitmentLogin() {
    const screenSize = getScreenSize();
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState(false)

    const onFinish = async (values) => {
        let deviceType;
        if (screenSize.width < 768) {
            // mobile
            deviceType = 0
        }
        else if (screenSize.width < 1440) {
            // laptop
            deviceType = 1
        }
        else {
            // desktop
            deviceType = 2
        }
        console.log('Success:', values);
        const data = await request({
            method: "post",
            url: `/users/recruiter/login`,
            data: {
                ...values,
                device_type: deviceType
            }
        })
        // localStorage.setItem("employee_number", data.data.employee_number);
        // localStorage.setItem("on_boarding", data.data.on_boarding);
        localStorage.setItem("user_id", data.data.user_id)
        localStorage.setItem("token", data.data.token);
        // localStorage.setItem("parent_company_id", data.data.parent_company_id)

        if (data.data.is_recruiter) {
            navigate('/recruitment/external-recruitment', { replace: true });
        }
        else {
            navigate('/recruitment/external-recruitment/login', { replace: true });
        }
    };

    return (
        <div className="container">
            <img src={desktopEngageLoginLogo} alt="" className='engageLogo' />
            <center>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                    <div className="leftContainer">
                        <img
                            src={login}
                            alt="login_image"
                            className='loginImg'
                        />
                    </div>
                    <div className="rightContainer">
                        <div>
                            <h1 className='signInHeading'>Recruiter's Login</h1>
                            <p className='engageHeading'>Engage</p>
                            <p className='welcomeHeading'>Welcome to Engage</p>
                            {
                                loginError &&
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <div
                                        className='errorMsgContainer'
                                    >
                                        <div style={{
                                            display: "flex",

                                            gap: "20px",

                                            padding: "10px 10px",
                                        }}>
                                            <div>
                                                <img src={ErrorImg} alt={ErrorImg} />
                                            </div>
                                            <div>
                                                <p style={{
                                                    fontWeight: "700",
                                                    fontSize: "16px",
                                                    margin: "0px",
                                                    padding: "0px"
                                                }}>Incorrect email or password.</p>
                                                <p style={{
                                                    fontWeight: "400",
                                                    fontSize: "16px",
                                                    margin: "0px",
                                                    padding: "0px"
                                                }}>
                                                    Either the email or password you entered is incorrect.
                                                    Please try again or try to
                                                    <span style={{ color: "#3354F4", fontWeight: "700", }}> contact support </span> if you are unable to access your account.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }

                            <ConfigProvider
                                theme={{
                                    token: {
                                        // fontSize: 20
                                    },
                                }}
                            >
                                <Form
                                    layout="vertical"
                                    onFinish={onFinish}
                                    autoComplete="on"
                                >
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your email!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            className='inputField'
                                            placeholder='Email'
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your password!',
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            type="password"
                                            className='inputField'
                                            placeholder='Password'
                                            autoComplete='on'
                                        />
                                    </Form.Item>

                                    <div className='forgotPasswordContainer'>
                                        <span className='forgotPassword'
                                            onClick={() => { alert('abc') }}>
                                            Forgot Password
                                        </span>
                                    </div>

                                    <Form.Item
                                        className='signInBtnContainer'
                                    >
                                        <Button
                                            htmlType="Sign In"
                                            className="signInBtn"
                                        >
                                            Sign In
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </ConfigProvider>
                        </div>
                    </div>
                </div>
            </center>

        </div>

    )
}
export default ExternalRecruitmentLogin

