import { Col, Form, Row, Select } from 'antd'
import Input from 'antd/es/input/Input'
import React, { useEffect, useState } from 'react'
import request from '../../../../../utils/axios'
import styles from "./HRA.module.scss"
import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons'
import HRAForm from './HRAForm/HRAForm'
import { useDispatch } from 'react-redux'
import { setHRADetails } from '../../../../../redux/reducer/TaxDeclarationReducer';

function HRA({ windowOpen }) {
    const dispatch = useDispatch();
    const monthsName = [
        {
            value: 1,
            label: "April"
        },
        {
            value: 2,
            label: "May"
        },
        {
            value: 3,
            label: "June"
        },
        {
            value: 4,
            label: "July"
        },
        {
            value: 5,
            label: "August"
        },
        {
            value: 6,
            label: "September"
        },
        {
            value: 7,
            label: "October"
        },
        {
            value: 8,
            label: "November"
        },
        {
            value: 9,
            label: "December"
        },
        {
            value: 10,
            label: "January"
        },
        {
            value: 11,
            label: "February"
        },
        {
            value: 12,
            label: "March"
        }
    ]


    const fetchHraData = async () => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        try {
            const data = await request({
                method: "get",
                url: "/regim_tax_rules/get-all-hra-declarations-amount",
            })

            for (const item of data.data) {

                dispatch(setHRADetails({
                    address: item.address,
                    metro_city: item.metro_city,
                    declared_amount: item.declared_amount,
                    owner_name: item.owner_name,
                    owner_pan_no: item.owner_pan_card,
                    month: item.assessment_month,
                    attachment: item.attachment,
                    monthName: months[item.assessment_month - 1],
                    file_name: item.file_name
                }));
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchHraData();
    }, [])


    return (
        <div className={styles.HRA}>
            <p className={styles.HRAHeaderText}>House Rent Allowance <InfoCircleOutlined /></p>
            {
                monthsName.map((item, index) => {
                    return (
                        <>
                            <HRAForm monthName={item.label} windowOpen={windowOpen} />
                        </>
                    )
                })
            }

        </div>
    )
}

export default HRA