import { Col, DatePicker, Form, Input, Modal, Row, Select, Table, TimePicker } from 'antd'
import React, { useState } from 'react';
import desktopStyle from "./SurveyAnalysis.module.scss";
import dayjs from 'dayjs';
import getScreenSize from "../../../../utils/getScreenSize";
import activeDownload from "../../../../assets/activeDownload.png";
import mobileStyle from "./MobileSurveyAnalysis.module.scss";

function SurveyAnalysis() {
    const screenSize = getScreenSize();
    const { TextArea } = Input;
    const [addPostModal, setAddPostModal] = useState(false)
    const [postDetails, setPostDetails] = useState({
        title: "",
        choseTitle: "",
        text: "",
        uploadType: "",
        image: "",
    })

    const surveyAnalysisColumns = [
        {
            title: 'Survey/Polls',
            dataIndex: 'survey',
            key: 'survey',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Result',
            dataIndex: 'result',
            key: 'result',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            // render: (text, record) => <img src={fullView} alt="view" />
        }
    ]

    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    return (
        <div>
            {
                screenSize.width < 768
                    // mobile
                    ?
                    <div>
                        <div className={mobileStyle.surveyAnalysisContainer} style={{ marginTop: "15px" }}>
                            <div className={mobileStyle.flexSpaceBetween}>
                                <p className={mobileStyle.heading}>Survey Analysis</p>
                                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                    <DatePicker
                                        size='medium'
                                        style={{ width: "150px" }}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className={mobileStyle.surveyAnalysisCard}>
                                <div className={mobileStyle.flexSpaceBetween}>
                                    <p className={mobileStyle.surveyAnalysisHeading}>Survey 1</p>
                                    <img src={activeDownload} alt="activeDownload" />
                                </div>
                                <div className={mobileStyle.flexSpaceBetween}>
                                    <p className={mobileStyle.status}>Status</p>
                                    <button className={mobileStyle.onGoing}>View Details</button>
                                </div>
                                <div className={mobileStyle.flexSpaceBetween} style={{ marginTop: "15px" }}>
                                    <p className={mobileStyle.status}>Results</p>
                                    <button className={mobileStyle.resultBtn}>View Details</button>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    // desktop
                    :
                    <div style={{ padding: "50px" }}>
                        <div style={{ background: "white", padding: "30px", borderRadius: "10px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                    <p style={{ fontSize: "24px", fontWeight: "600", margin: "0" }}>Survey Analysis</p>
                                    <button style={{ background: "#008080", color: "white", padding: "7px 15px", borderRadius: "5px", border: "none" }}
                                        onClick={() => setAddPostModal(true)}
                                    >Add Post</button>
                                </div>
                                <DatePicker
                                    size='large'
                                    onChange={onChange}
                                />
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <Table
                                    columns={surveyAnalysisColumns}
                                    dataSource={[]}
                                    pagination={false}
                                />
                            </div>
                        </div>

                        <Modal
                            centered
                            open={addPostModal}
                            onOk={() => setAddPostModal(false)}
                            onCancel={() => {
                                setAddPostModal(false);
                                setPostDetails({
                                    title: "",
                                    searchEmployee: "",
                                    text: "",
                                    uploadType: "",
                                    image: "",
                                })
                            }}
                            footer={null}
                        >
                            <div>
                                <p style={{ color: "#161616", fontSize: "24px", fontWeight: "500", lineHeight: "27px" }}>Add Post</p>
                                <Form
                                    layout="vertical"
                                    onFinish={(values) => console.log(values)}
                                    style={{ marginTop: "20px" }}
                                    fields={[
                                        {
                                            name: "title",
                                            value: postDetails.title
                                        },
                                        {
                                            name: "searchEmployee",
                                            value: postDetails.searchEmployee
                                        },
                                        {
                                            name: "text",
                                            value: postDetails.text
                                        },
                                        {
                                            name: "uploadType",
                                            value: postDetails.uploadType
                                        },
                                    ]}
                                >
                                    <Form.Item
                                        label="Post Visible"
                                        name="postVisible"
                                    >
                                        <Input
                                            placeholder="Appreciation Post "
                                            size='large'
                                            onChange={(e) => setPostDetails({
                                                ...postDetails,
                                                title: e.target.value
                                            })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Choose Title"
                                        name="chooseTitle"
                                    >
                                        <Select
                                            size='large'
                                            style={{ width: "100%" }}
                                            placeholder="Select Category"
                                            value={postDetails.uploadType}
                                            onChange={(value) => setPostDetails({
                                                ...postDetails,
                                                uploadType: value
                                            })}
                                        >
                                            <Select.Option value="1">Option 1</Select.Option>
                                            <Select.Option value="2">Option 2</Select.Option>
                                            <Select.Option value="3">Option 3</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Question"
                                        name="question"
                                    >
                                        <TextArea
                                            placeholder="Write text here"
                                            size='large'
                                            rows={2}
                                            onChange={(e) => setPostDetails({
                                                ...postDetails,
                                                text: e.target.value
                                            })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        // label="Post Visible"
                                        name="postVisible"
                                    >
                                        <Input
                                            placeholder="Option 1"
                                            size='large'
                                            onChange={(e) => setPostDetails({
                                                ...postDetails,
                                                title: e.target.value
                                            })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        // label="Post Visible"
                                        name="postVisible"
                                    >
                                        <Input
                                            placeholder="Option 2"
                                            size='large'
                                            onChange={(e) => setPostDetails({
                                                ...postDetails,
                                                title: e.target.value
                                            })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        // label="Post Visible"
                                        name="postVisible"
                                    >
                                        <Input
                                            placeholder="Option 3"
                                            size='large'
                                            onChange={(e) => setPostDetails({
                                                ...postDetails,
                                                title: e.target.value
                                            })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        // label="Post Visible"
                                        name="postVisible"
                                    >
                                        <Input
                                            placeholder="Option 4"
                                            size='large'
                                            onChange={(e) => setPostDetails({
                                                ...postDetails,
                                                title: e.target.value
                                            })}
                                        />
                                    </Form.Item>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Last Date"
                                                name="lastDate"
                                            >
                                                <DatePicker
                                                    size='large'
                                                    onChange={onChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Time"
                                                name="time"
                                            >
                                                <TimePicker
                                                    onChange={onChange}
                                                    value={dayjs('00:00', 'HH:mm')}
                                                    style={{ width: '100%' }}
                                                    size='large'
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <button
                                        style={{ border: "none", background: "#008080", color: "white", height: "40px", width: "150px", borderRadius: "5px" }}
                                    >Post</button>
                                </Form>
                            </div>
                        </Modal>
                    </div>
            }
        </div>

    )
}

export default SurveyAnalysis