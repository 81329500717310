import { Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import styles from "./AddPost.module.scss"
import request from '../../../../utils/axios';


function AddPost() {
  const { TextArea } = Input;

  const [postDetails, setPostDetails] = useState({
    title: "",
    searchEmployee: "",
    text: "",
    uploadType: "",
    image: "",
  })

  const onFinish = async (values) => {
    try {
      console.log("Success:", values);
      await request({
        method: "post",
        url: "/post",
        data: {
          title: postDetails.title,
          searchEmployee: postDetails.searchEmployee,
          text: postDetails.text,
          uploadType: postDetails.uploadType,
          image: postDetails.image
        },
      })
    }
    catch (error) {
      console.log("error", error);
    }
  };

  const [employeeList, setEmployeeList] = useState([])

  const getEmployeeList = async () => {
    let data = await request({
      method: "get",
      url: `get-all-employees`,
    })
    setEmployeeList(data.data)
  }

  useEffect(() => {
    getEmployeeList()
  }, [])

  return (
    <div>
      <div style={{ padding: "0px 16px", marginTop: "80px" }}>
        <p style={{ color: "#161616", fontSize: "24px", fontWeight: "500", lineHeight: "27px" }}>Add Post</p>
        <Form
          layout="vertical"
          onFinish={onFinish}
          style={{ marginTop: "20px" }}
          fields={[
            {
              name: "title",
              value: postDetails.title
            },
            {
              name: "searchEmployee",
              value: postDetails.searchEmployee
            },
            {
              name: "text",
              value: postDetails.text
            },
            {
              name: "uploadType",
              value: postDetails.uploadType
            },
          ]}
        >
          <Form.Item
            label="Title"
            name="title"
          >
            <Input
              placeholder="Appreciation Post "
              size='large'
              onChange={(e) => setPostDetails({
                ...postDetails,
                title: e.target.value
              })}
            />
          </Form.Item>
          {/* <Form.Item
            label="Search Employee"
            name="searchEmployee"
          >
            <Select
              size='large'
              style={{ width: "100%" }}
              placeholder="Select Employee"
              onChange={(value) => setPostDetails({
                ...postDetails,
                searchEmployee: value
              })}
            >
              <Select.Option value="jack">Jack</Select.Option>
              <Select.Option value="lucy">Lucy</Select.Option>
              <Select.Option value="Yiming">yiming</Select.Option>
            </Select>
          </Form.Item> */}
          <Form.Item
            label="Search Employee"
            name="searchEmployee"
          >
            <Select
              size='large'
              style={{ width: "100%" }}
              placeholder="Select Employee"
              onChange={(value) => setPostDetails({
                ...postDetails,
                searchEmployee: value
              })}
            >
              {
                employeeList?.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.employement_number}>{item.employee_name}</Select.Option>
                  )
                }
                )
              }
            </Select>
          </Form.Item>
          <Form.Item
            label="Text"
            name="text"
          >
            <TextArea
              placeholder="Write text here"
              size='large'
              rows={8}
              onChange={(e) => setPostDetails({
                ...postDetails,
                text: e.target.value
              })}
            />
          </Form.Item>
          <Form.Item
            label="Upload image"
            name="uploadType"
          >
            <Select
              size='large'
              style={{ width: "100%" }}
              placeholder="Select Category"
              value={postDetails.uploadType}
              onChange={(value) => setPostDetails({
                ...postDetails,
                uploadType: value
              })}
            >
              <Select.Option value="1">Default Image</Select.Option>
              <Select.Option value="2">Upload Image</Select.Option>
              <Select.Option value="3">None</Select.Option>
            </Select>
          </Form.Item>

          {
            postDetails.uploadType === "1" &&
            <div style={{ marginBottom: "20px" }}>
              <img src="https://picsum.photos/200" alt="" width={100} height={100} />
            </div>
          }

          {
            postDetails.uploadType === "2" &&
            <Form.Item
              // label="Upload File"
              name="document"

            >
              <div className={styles.fileUploadContainer}>
                <input
                  type="file"
                  id="file-upload"
                  className={styles.inputFile}
                  onChange={(e) => {
                    const selectedFile = e.target.files[0];
                    const maxSizeInBytes = 5 * 1024 * 1024;

                    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', "image/jpg"];

                    // Check if the selected file is an allowed image type
                    if (allowedImageTypes.includes(selectedFile.type)) {
                      // Check if the file size is within the allowed limit
                      if (selectedFile.size <= maxSizeInBytes) {
                        setPostDetails({
                          ...postDetails,
                          image: selectedFile
                        })
                      } else {
                        // File size exceeds the limit
                        // setFile(null);
                        setPostDetails({
                          ...postDetails,
                          image: ""
                        })
                        alert('File size exceeds the limit of 5MB.');
                      }
                    } else {
                      // File is not an allowed image type
                      setPostDetails({
                        ...postDetails,
                        image: ""
                      })
                      alert('Please upload a valid image file (JPEG, PNG, GIF).');
                    }
                  }
                  }
                />
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                  <label htmlFor="file-upload" className={styles.fileUploadLabel}>

                  </label>
                  {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                  <p style={{ zIndex: 1, margin: "10px" }}>{postDetails?.image ? postDetails?.image.name : "Select a image or drag and drop here"}</p>
                </div>
              </div>
            </Form.Item>
          }

          <button
            style={{ border: "none", background: "#008080", color: "white", height: "40px", width: "100%", borderRadius: "5px", marginTop: "20px" }}
          >Post</button>
        </Form>
      </div>
    </div>
  )
}

export default AddPost