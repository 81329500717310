import React, { useEffect, useState } from 'react';
import styles from "./Documentation.module.scss"
import request from '../../../utils/axios';
import { useNavigate } from 'react-router-dom';
import getScreenSize from "../../../utils/getScreenSize";
import InfiniteScrollComponent from '../../../component/InfiniteScroll/InfiniteScroll';

function Documentation() {
  const navigate = useNavigate();
  const screenSize = getScreenSize();
  const [newDocumentForSigning, setnewDocumentForSigning] = useState([]);

  const fetchnewDocumentForSigning = async () => {
    const data = await request({
      method: "get",
      url: `/inbox/documents/unsigned?page=1&page_size=3`,
    });

    if (data && data?.pagination_data && data?.pagination_data?.data) {
      setnewDocumentForSigning(data?.pagination_data?.data);
    }
  };

  useEffect(() => {
    fetchnewDocumentForSigning();
  }, [])

  const renderUI = (dataList) => {
    return dataList?.map((item, index) => (
      <>
        <div style={{
          padding: "0px 16px"
        }}>
          <div onClick={() => navigate(`/inbox/documentation/detail/` + item.id, { replace: true })}>

            <div style={{
              display: "flex",
              justifyContent: "space-between"
            }}>
              <p style={{
                fontSize: "18px",
                color: "#1a1a1a",
                fontWeight: "600"
              }}>{item.name}</p>
              <p
                style={{
                  fontSize: "14px",
                  color: "#2B2B2B",
                  fontWeight: "400"
                }}
              >{item.created_at}</p>
            </div>
            <p
              style={{
                marginTop: "10px",
                fontWeight: "400",
                fontSize: "16px",
                color: "#484848 "
              }}
            >
              {item.file_type}
            </p>


          </div>



        </div>
        <hr style={{ border: "1px solid #DEDEDE" }} />
      </>
    ))
  }

  const renderUIDesktop = (dataList) => {
    return dataList?.map((item, index) => (
      <>
        <div>
          <div onClick={() => navigate(`/inbox/documentation/detail/` + item.id, { replace: true })}>

            <div style={{
              display: "flex",
              justifyContent: "space-between"
            }}>
              <p style={{
                fontSize: "18px",
                color: "#1a1a1a",
                fontWeight: "600"
              }}>{item.name}</p>
              <p
                style={{
                  fontSize: "14px",
                  color: "#2B2B2B",
                  fontWeight: "400"
                }}
              >{item.created_at}</p>
            </div>
            <p
              style={{
                marginTop: "10px",
                fontWeight: "400",
                fontSize: "16px",
                color: "#484848 "
              }}
            >
              {item.file_type}
            </p>


          </div>



        </div>
        <hr style={{ border: "1px solid #DEDEDE" }} />
      </>
    ))
  }

  return (
    <div>

      {
        screenSize.width < 768 ?
          <div>
            <InfiniteScrollComponent
              apiEndpoint="/inbox/documents/unsigned"
              renderUI={renderUI}
              initialPage={1}
            />
            {/* {
              newDocumentForSigning?.map((item, index) => (
                <>
                  <div style={{
                    padding: "0px 16px"
                  }}>
                    <div onClick={() => navigate(`/inbox/documentation/detail/` + item.id, { replace: true })}>

                      <div style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}>
                        <p style={{
                          fontSize: "18px",
                          color: "#1a1a1a",
                          fontWeight: "600"
                        }}>{item.name}</p>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#2B2B2B",
                            fontWeight: "400"
                          }}
                        >{item.created_at}</p>
                      </div>
                      <p
                        style={{
                          marginTop: "10px",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#484848 "
                        }}
                      >
                        {item.file_type}
                      </p>


                    </div>



                  </div>
                  <hr style={{ border: "1px solid #DEDEDE" }} />
                </>
              ))
            } */}


          </div>
          :
          <div>
             <InfiniteScrollComponent
              apiEndpoint="/inbox/documents/unsigned"
              renderUI={renderUIDesktop}
              initialPage={1}
            />


          </div>
      }


    </div>
  )
}

export default Documentation