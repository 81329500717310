import React, { useEffect, useState } from 'react'
import request from '../../../../utils/axios';

import { useParams, useNavigate } from 'react-router-dom'
import "./UserJobDetails.scss"
import ExternalUserApplyJob from '../ExternalUserApplyJob/ExternalUserApplyJob';
import { Button, Col, Form, Input, Modal, Row } from 'antd';

import casualleaves from "../../../../assets/casualleaves.svg"
import RecruitmentHeader from '../../RecruitmentHeader/RecruitmentHeader';
import useScreenSize from '../../../../utils/getScreenSize';

function UserJobDetails() {
  const params = useParams();
  const screenSize = useScreenSize();
  const [jobDetails, setJobDetails] = useState();

  const fetchData = async () => {
    const data = await request({
      method: "get",
      url: `recruitment/job-detail/${params.id}`,
    })


    setJobDetails(data.data.data)
  }
  const [companyDetails, setCompanyDetails] = useState({})

  const fetchCompanyDetails = async () => {
      try {
          const data = await request({
              method: "get",
              url: `recruitment/external/company/details?name=${params.company_name}`,
          });
          setCompanyDetails(data);
      } catch (error) {
          console.log("error", error);
      }
  };
  useEffect(() => {
    fetchData()
    fetchCompanyDetails()
  }, [])

  const [form] = Form.useForm()
  const [open, setOpen] = useState(false);
  const [userDetail, setUserDetail] = useState({
    candidate_name: "",
    candidate_email: "",
    candidate_contact: "",
    current_company: "",
    linkedin_url: "",
    candidate_resume: "",
  })

  const onFinish = async (values) => {
    const formData = new FormData();
    formData.append('candidate_name', values.candidate_name);
    formData.append('candidate_email', values.candidate_email);
    formData.append('candidate_contact', values.candidate_contact);
    formData.append('current_company', values.current_company);
    formData.append('linkedin_url', values.linkedin_url);
    formData.append('candidate_resume', resumeFile);
    if (params.id) {
      formData.append('job', params.id);
    }
    else {
      formData.append('job', params.id);
    }

    await request({
      method: "post",
      url: `recruitment/external/applicant/create`,
      data: formData
    })

    setUserDetail({
      candidate_name: "",
      candidate_email: "",
      candidate_contact: "",
      current_company: "",
      linkedin_url: "",
      candidate_resume: "",
    })
    setOpen(false)
    setResumeFile(null);
    form.resetFields();
  }


  const handleFormChange = (changedValues, allValues) => {
    if (!changedValues?.candidate_resume) {
      if (!userDetail.candidate_resume) {
        setUserDetail({ ...userDetail, ...changedValues, candidate_resume: undefined });
      }
      else {
        setUserDetail({ ...userDetail, ...changedValues });
      }
    }
  }

  const [resumeFile, setResumeFile] = useState(null);



  return (
    <>
      {
        screenSize.width < 768 ?
          <>
            <RecruitmentHeader />
            <div className='userJobContainer' style={{ marginTop: '20px' }}>
              <div className='userJobCard'>
                <Row >
                  <Col span={4}>
                    <img src={companyDetails?.company_logo} alt="casualleaves" width={47} height={43} style={{ marginTop: "10px" }} />
                  </Col>
                  <Col span={20} style={{ margin: '0px' }}  >

                    <p style={{ margin: "0px", fontSize: "16px", fontWeight: "600", color: "#161616" }}>{jobDetails?.job_title}</p>

                    <div className='secondRow' style={{ marginTop: "0px" }}>
                      <p>
                        {jobDetails?.location.map((item, index) => {
                          return (
                            <span>
                              <span style={{ color: "#535151", fontSize: "12px" }}>{item}</span>
                              {
                                jobDetails?.location?.length - 1 !== index && <span> , </span>
                              }
                            </span>
                          )
                        })}
                      </p>
                      <p style={{ marginTop: "0px", color: "#535151", fontSize: "12px" }}>
                        {jobDetails?.industry} / {jobDetails?.employee_type} / {jobDetails?.job_type}
                      </p>

                    </div>
                  </Col>
                </Row>
                <div style={{ marginTop: "15px" }}>
                  <Button type="primary" style={{ backgroundColor: "#008080", width: "100%" }} onClick={() => setOpen(true)}>
                    Apply
                  </Button>
                </div>
                <div>
                  <div dangerouslySetInnerHTML={{ __html: jobDetails?.job_description }} />
                </div>
              </div>
            </div>
          </>
          :
          <>
            <RecruitmentHeader />
            <div className='userJobContainer' style={{ marginTop: '20px' }}>
              <div className='userJobCard'>
                <Row >
                  <Col span={2}>
                    <img src={companyDetails?.company_logo} alt="casualleaves" width={100} height={94} style={{ marginTop: "10px" }} />
                  </Col>
                  <Col span={20} style={{ margin: '0px' }}  >
                    <div className='firstRow' >
                      <p className='jobTitle' style={{ margin: "0px" }}>{jobDetails?.job_title}</p>
                      <ExternalUserApplyJob jobId={params.id} btnContent="Apply" companyName={params.company_name} />
                    </div>
                    <div className='secondRow' style={{ marginTop: "0px" }}>
                      <p>
                        {jobDetails?.location.map((item, index) => {
                          return (
                            <span>
                              <span>{item}</span>
                              {
                                jobDetails?.location?.length - 1 !== index && <span> , </span>
                              }
                            </span>
                          )
                        })}
                      </p>
                      <p>
                        {jobDetails?.industry} / {jobDetails?.employee_type} / {jobDetails?.job_type}
                      </p>
                    </div>
                  </Col>
                </Row>

                <div className='thirdRow'>
                  <div dangerouslySetInnerHTML={{ __html: jobDetails?.job_description }} />
                </div>
              </div>
            </div>
          </>
      }
      <Modal
        centered
        open={open}
        footer={null}
        width={1000}
        onCancel={() => setOpen(false)}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={userDetail}
          onValuesChange={handleFormChange}
          form={form}
          fields={[
            {
              name: ["candidate_name"],
              value: userDetail?.candidate_name
            },
            {
              name: ["candidate_email"],
              value: userDetail?.candidate_email
            },
            {
              name: ["candidate_contact"],
              value: userDetail?.candidate_contact
            },
            {
              name: ["current_company"],
              value: userDetail?.current_company
            },
            {
              name: ["linkedin_url"],
              value: userDetail?.linkedin_url
            },
          ]}
        >
          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="candidate_resume"
                label="Resume"
                rules={[
                  {
                    required: true,
                    message: 'Please upload resume!',
                  },
                ]}
              >
                <input
                  type='file'
                  className='inputField'
                  name='candidate_resume'
                  onChange={(e) => setResumeFile(e.target.files && e.target.files[0])}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Name"
                name="candidate_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your name!',
                  },
                ]}
              >
                <Input
                  className='inputField'
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Email Id"
                name="candidate_email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email!',
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email address!',
                  },
                ]}
              >
                <Input
                  className='inputField'
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Phone no."
                name="candidate_contact"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your contact!',
                  },
                  {
                    pattern: /^[0-9]{10}$/, // A regular expression that matches a 10-digit contact number
                    message: 'Please enter a valid 10-digit contact number!',
                  },
                ]}
              >
                <Input
                  className='inputField'
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Current Company"
                name="current_company"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Current  Company!',
                  }
                ]}
              >
                <Input
                  className='inputField'
                />
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                label="LinkedIn Url"
                name="linkedin_url"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your linkedin_url!',
                  },
                ]}
              >
                <Input
                  className='inputField'
                  style={{ width: "100% " }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Button
            className='button'
            htmlType='submit'
          >Apply</Button>
        </Form>
      </Modal>
    </>
  )
}

export default UserJobDetails