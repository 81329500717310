import React, {useState, useEffect} from 'react';
import {useMemo} from 'react';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';

// import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import duration from '../../../../assets/Attendance/Admin/duration.svg';
import mylocation from '../../../../assets/location.svg';
import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Search from '../../SearchBar/Search';
import request from '../../../../utils/axios';
function Location() {
  const [employeeList, setEmployeeList] = useState([]);
  const containerStyle = {
    width: '100%',
    height: '400px',
  };
  const customIcon = new Leaflet.Icon({
    iconUrl: mylocation, // Replace with your image path
    iconSize: [25, 41], // Adjust the size of the marker
    iconAnchor: [12, 41], // The point of the icon which will correspond to the marker's location
    popupAnchor: [1, -34], // Position of the popup relative to the marker
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'), // Optional shadow for marker
    shadowSize: [41, 41],
  });
  useEffect(() => {
    loadData();
  }, []);
  const loadData = async () => {
    const apiUrl = `tracking/admin/employee/tracking-list`;
    try {
      const res = await request({
        method: 'get',
        url: apiUrl,
      });
      console.log(res.data);
      setEmployeeList(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const peopleLocations = [
    {name: 'Pintu Kumar', lat: 28.7041, lng: 77.1025, role: 'Sales Manager'},
    {
      name: 'Ravi Sharma',
      lat: 28.5355,
      lng: 77.391,
      role: 'Marketing Manager',
    },
    {
      name: 'Amit Singh',
      lat: 28.4595,
      lng: 77.0266,
      role: 'Software Engineer',
    },
  ];

  return (
    <div style={{background: 'white'}}>
      <div style={{display: 'flex'}}>
        <div
          style={{
            padding: '30px',
            borderRadius: '5px',

            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}>
          <div style={{fontSize: '24px', fontWeight: '600', color: '#161616'}}>
            Employees
          </div>
          <Search />
          {employeeList.map((item, index) => {
            return (
              <div key={index} style={{width: '25vw'}}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#161616',
                      margin: '0',
                    }}>
                    {item.employee_name}
                  </p>
                  <button
                    style={{
                      border: '1px solid #008080',
                      background: '#008080',
                      cursor: 'pointer',
                      color: 'white',
                      height: '36px',
                      width: '80px',
                      borderRadius: '5px',
                    }}>
                    Track
                  </button>
                </div>
                <div>
                  <img
                    src={duration}
                    alt="duration"
                    width={18}
                    height={18}
                    style={{marginRight: '10px'}}
                  />
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      color: '#616161',
                    }}>
                    {item.designation}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginTop: '10px',
                  }}>
                  <img
                    src={mylocation}
                    alt="mylocation"
                    width={18}
                    height={18}
                    style={{marginRight: '10px'}}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      color: '#616161',
                    }}>
                    {item.location}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <MapContainer
          center={[peopleLocations[0].lat, peopleLocations[0].lng]}
          zoom={8}
          style={containerStyle}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {peopleLocations.map((person, index) => (
            <Marker
              key={index}
              position={[person.lat, person.lng]}
              icon={customIcon}>
              <Popup>
                <strong>{person.name}</strong>
                <br />
                Role: {person.role}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
        {/* <LoadScript googleMapsApiKey="AIzaSyBA1nJCLtx3IYdKMgR4_SHM55Q-EJNiSTA">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}>
            <Marker position={{lat: 28.7041, lng: 77.1025}} icon={mylocation} />
          </GoogleMap>
        </LoadScript> */}
      </div>
    </div>
  );
}

export default Location;
