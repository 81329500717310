import React, { useEffect, useState } from 'react';
import mobileStyle from "./ReimbursementDetail.module.scss"
import { Col, Row, Select } from 'antd';
import activeDocumentImg from "../../../../assets/activeDocumentImg.svg"
import view from "../../../../assets/view.png";
import activeDownload from "../../../../assets/activeDownload.png";
import { saveAs } from 'file-saver';
import request from '../../../../utils/axios';
import { useParams } from 'react-router-dom';

function ReimbursementDetail() {
  const [selectedReimbursementData, setSelectedReimbursementData] = useState();
  const params = useParams();
  const [approvedModal, setApprovedModal] = useState(false)
  const [selectedOptionEmployeeId, setSelectedOptionEmployeeId] = useState("")

  const fetchReimbursementData = async () => {
    try {
      const data = await request({
        method: "get",
        url: `payroll/admin/get-employee-reimbursement-details?id=${params.id}`,
      })
      setSelectedReimbursementData(data.data)
    }
    catch (err) {
      console.log(err)
    }
  }

  const onHandleChangeForReimbursementAmount = (value, id) => {
    //   partially approved
    if (value === 1) {
      setApprovedModal(true)
      setSelectedOptionEmployeeId(id)
    }
    // approved
    else if (value === 2) {
      approveEmployeeReimbursementAmount(id)
    }
  }

  const approveEmployeeReimbursementAmount = async (id) => {
    try {
      await request({
        method: "post",
        url: `payroll/admin/reimbursement-approved`,
        data: {
          id
        }
      })
      fetchReimbursementData();
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchReimbursementData()
  }, [])

  return (
    <div style={{ padding: "0px 16px", marginTop: "80px" }}>
      <p className={mobileStyle.reimbursementModelTitle}>Reimbursement Details</p>
      <Row gutter={[32, 12]}>
        <Col span={24}>
          <div className={mobileStyle.reimbursementModelKeyValueContainer}>
            <p className={mobileStyle.name}>{selectedReimbursementData?.employee_name}</p>
            <div style={{ alignItems: "center", display: "flex", justifyContent: "flex-end" }}>
              <Select 
                value={selectedReimbursementData?.approved === null ? 0 : selectedReimbursementData?.approved}
                onChange={(value) => onHandleChangeForReimbursementAmount(value, selectedReimbursementData.id)}
                style={{ width: 150}}
                size='middle'
              >
                <Select.Option value={0}>Select</Select.Option>
                <Select.Option value={1}>Partially Approved</Select.Option>
                <Select.Option value={2} >Approved</Select.Option>
              </Select>
            </div>
          </div>
          <div className={mobileStyle.reimbursementModelKeyValueContainer}>
            <p className={mobileStyle.reimbursementModelKey}>Title</p>
            <p className={mobileStyle.reimbursementModelValue}>{selectedReimbursementData?.title}</p>
          </div>
          <div className={mobileStyle.reimbursementModelKeyValueContainer}>
            <p className={mobileStyle.reimbursementModelKey}>Claimed On</p>
            <p className={mobileStyle.reimbursementModelValue}>{selectedReimbursementData?.date}</p>
          </div>
          <div className={mobileStyle.reimbursementModelKeyValueContainer}>
            <p className={mobileStyle.reimbursementModelKey}>Amount Claimed</p>
            <p className={mobileStyle.reimbursementModelValue}>₹{selectedReimbursementData?.claim_amount}</p>
          </div>
          <div className={mobileStyle.reimbursementModelKeyValueContainer}>
            <p className={mobileStyle.reimbursementModelKey}>Amount Approved</p>
            <p className={mobileStyle.reimbursementModelValue}>{selectedReimbursementData?.approved_amount && `₹${selectedReimbursementData?.approved_amount}`}</p>
          </div>
          <div className={mobileStyle.reimbursementModelKeyValueContainer}>
            <p className={mobileStyle.reimbursementModelKey}>Invoice ID</p>
            <p className={mobileStyle.reimbursementModelValue}>{selectedReimbursementData?.invoice_id}</p>
          </div>
          <div className={mobileStyle.reimbursementModelKeyValueContainer}>
            <p className={mobileStyle.reimbursementModelKey}>Merchant Name</p>
            <p className={mobileStyle.reimbursementModelValue}>{selectedReimbursementData?.merchant_name}</p>
          </div>

        </Col>
        <Col span={24}>
          <p className={mobileStyle.reimbursementModelKey}>Description</p>
          <div style={{ border: "1px solid #DDDDDD", background: "#F8F8F8", padding: "10px" }}>
            <p className={mobileStyle.description}>{selectedReimbursementData?.description}</p>
          </div>
        </Col>
        <Col span={24}>
          <p className={mobileStyle.reimbursementModelKey}>Download documents</p>


          {
            selectedReimbursementData?.reimbursement_files?.map((item, index) => {
              return (
                <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "space-between", padding: "10px", background: "#F8F8F8", width: "100%", marginBottom: "10px" }}>
                  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <img src={activeDocumentImg} alt="activeDocumentImg" width={18} height={18} />
                    <p style={{ margin: "0px", color: "#505050", fontWeight: "600", fontSize: "14px" }}>{item?.file_name}</p>
                  </div>
                  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <img src={view} alt="view" width={18} height={18}
                      onClick={() => {
                        window.open(item?.file_upload)
                      }}
                    />
                    {/* download  pdf*/}

                    <a href={item.file_upload} target="_blank" download>
                      <img src={activeDownload} alt="activeDownload" width={18} height={18}
                        onClick={async () => {

                          try {
                            const response = await fetch(item.file_upload);
                            const blob = await response.blob();
                            saveAs(blob, `${item.file_name}.pdf`);
                          } catch (error) {
                            console.error('Error downloading PDF:', error);
                          }
                        }}
                      />
                    </a>
                  </div>
                </div>
              )
            })
          }
        </Col>
      </Row>
    </div>
  )
}

export default ReimbursementDetail