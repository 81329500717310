import React, { useEffect, useState } from "react";
import { Button, Radio, Table } from "antd";

import "./ReferralOpening.scss";
import DynamicTable from "../../../../component/TableWithPagination/TableWithPagination";
import request from "../../../../utils/axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import back from "../../../../assets/Recruitment/back.svg";
import useScreenSize from "../../../../utils/getScreenSize";
import calendar from "../../../../assets/calendar.svg";
import location from "../../../../assets/location.svg";
import ApplyJob from "../ApplyJob/ApplyJob";
import InfiniteScroll from "../../../../component/InfiniteScroll/InfiniteScroll";
// import Opening from '../../Opening/Opening';
// import Referral from '../../Referral/Referral';
import mobileStyle from "./MobileReferralOpening.module.scss";
import dayjs from "dayjs";
import InfiniteScrollComponent from "../../../../component/InfiniteScroll/InfiniteScroll";

function ReferralOpeningList() {
  const referralColumns = [
    {
      title: "Date",
      dataIndex: "applied_on",
      key: "applied_on",
      render: (text, record) => (
        <span>{moment(text).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Candidate Name",
      dataIndex: "candidate_name",
      key: "candidate_name",
    },
    {
      title: "Job Title",
      dataIndex: "job",
      key: "job",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text, record) => (
        <div>
          {record.location.map((item, index) => {
            return (
              <span key={index}>
                <span>{item}</span>
                {record?.location?.length - 1 !== index && <span> , </span>}
              </span>
            );
          })}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <button className={`${text}StatusButton`}>{text}</button>
      ),
    },
  ];

  const openingColumns = [
    {
      title: "Date",
      dataIndex: "posting_date",
      key: "posting_date",
      render: (text, record) => (
        <span>{moment(text).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <ApplyJob
          title={"Refer a Candidate"}
          jobId={record.id}
          btnContent="Refer"
        />
      ),
    },
  ];

  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const [refferalList, setRefferalList] = useState([]);

  const fetchRowMyReferralData = async (page, page_size) => {
    const data = await request({
      method: "get",
      url: `recruitment/employee/my-referrals?page=${page}&page_size=${page_size}`,
    });
    setRefferalList([...data.pagination_data.data]);

    return data;
  };

  const [openingList, setOpeningList] = useState([]);

  const fetchRowOpeningData = async (page, page_size) => {
    console.log("aaaaaaaaa");
    const data = await request({
      method: "get",
      url: `recruitment/referral-jobs?page=${page}&page_size=${page_size}`,
    });
    setOpeningList([...data.pagination_data.data]);
    return data;
  };

  const [view, setView] = useState("opening");
  const [dataList, setDataList] = useState([]);
  const fetchDataForMobileView = async (page, page_size) => {
    if (view === "referral") {
      const data = await fetchRowMyReferralData(page, page_size);
      setDataList(data.pagination_data.data);
      return data;
    } else {
      const data = await await fetchRowOpeningData(page, page_size);
      setDataList(data.pagination_data.data);
      return data;
    }
  };

  useEffect(() => {
    fetchRowMyReferralData(1, 10);
    fetchRowOpeningData(1, 10);
  }, []);

  const referralRenderUI = (dataList) => {
    return dataList.map((item, index) => {
      return (
        <>
          <div key={index} className={mobileStyle.refferalContainer}>
            <p className={mobileStyle.refferalName}>
              {item.candidate_name}
              <span
                className={mobileStyle.refferalStatus}
                style={{
                  color:
                    item.status === ("Shortlisted" || "Selected")
                      ? "green"
                      : item.status === "Interview"
                      ? "purple"
                      : item.status === "Rejected"
                      ? "red"
                      : "gray",
                  border:
                    item.status === ("Shortlisted" || "Selected")
                      ? "1px solid green"
                      : item.status === "Interview"
                      ? "1px solid purple"
                      : item.status === "Rejected"
                      ? "1px solid red"
                      : "1px solid gray",
                }}
              >
                {item.status}
              </span>
            </p>
            <p className={mobileStyle.refferalJob}>{item.job}</p>
            <p className={mobileStyle.refferalDate}>
              <img src={calendar} alt="calendar" width={20} height={20} />{" "}
              {dayjs(item.applied_on).format("DD-MM-YYYY")}
            </p>
            <p className={mobileStyle.refferalLocation}>
              <img src={location} alt="location" width={20} height={20} />{" "}
              {item?.location[0]}
            </p>
          </div>
          <hr />
        </>
      );
    });
  };

  const openingRenderUI = (dataList) => {
    return dataList.map((item, index) => {
      return (
        <>
          <div key={index} className={mobileStyle.openingContainer}>
            <div>
              <p className={mobileStyle.jobTitle}>{item.job_title}</p>
              <p className={mobileStyle.industry}>{item.industry}</p>
              <p className={mobileStyle.date}>
                <img src={calendar} alt="calendar" width={20} height={20} />{" "}
                {dayjs(item.posting_date).format("DD-MM-YYYY")}
              </p>
              <p className={mobileStyle.location}>
                <img src={location} alt="location" width={20} height={20} />{" "}
                {item?.location[0]}
              </p>
            </div>
            <button
              className={mobileStyle.applyBtn}
              onClick={() =>
                navigate(`/recruitment/refer-form/${item.id}`, {
                  replace: true,
                })
              }
            >
              Refer
            </button>
          </div>
          <hr />
        </>
      );
    });
  };

  const renderUIDesktopReferral = (dataList) => {
    return (
      <Table
        columns={referralColumns}
        dataSource={dataList}
        pagination={false}
      />
    );
  };

  const renderUIDesktopOpening = (dataList) => {
    return (
      <Table
        columns={openingColumns}
        dataSource={dataList}
        pagination={false}
      />
    );
  };
  return (
    <div>
      {screenSize.width < 768 ? (
        <div>
          <div className={mobileStyle.refferalHeadingContainer}>
            <div className={mobileStyle.refferalHeadingWrapper}>
              <p className={mobileStyle.refferalHeading}>Referrals</p>
              {/* <img src={fullView} alt="fullView"
                                    onClick={() => navigate("/recruitment/referral")}
                                /> */}
            </div>
            <center>
              <Radio.Group
                defaultValue={view}
                buttonStyle="solid"
                size="large"
                onChange={(e) => setView(e.target.value)}
                style={{
                  width: "100%",
                }}
              >
                <Radio.Button
                  value="opening"
                  style={{
                    width: "50%",
                  }}
                >
                  Openings
                </Radio.Button>
                <Radio.Button
                  value="referral"
                  style={{
                    width: "50%",
                  }}
                >
                  My Refers
                </Radio.Button>
              </Radio.Group>
            </center>
          </div>
          {view === "referral" ? (
            <InfiniteScrollComponent
              apiEndpoint="recruitment/employee/my-referrals"
              renderUI={referralRenderUI}
              initialPage={1}
            />
          ) : (
            <InfiniteScrollComponent
              apiEndpoint="recruitment/referral-jobs"
              renderUI={openingRenderUI}
              initialPage={1}
            />
          )}
        </div>
      ) : (
        <div className="Container">
          {/* <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                            <img src={back} alt="back" />
                            <span style={{ paddingLeft: "10px" }}>Back</span>
                        </p>
                        <h1>{view === "referral" ? "Referrals" : "Openings"}</h1> */}
          <div className="referralSubContainer">
            <Button
              className={
                view === "opening"
                  ? "Btn openingsBtn active"
                  : "Btn openingsBtn"
              }
              onClick={() => setView("opening")}
            >
              Openings
            </Button>

            <Button
              className={view === "referral" ? "Btn active" : "Btn"}
              onClick={() => setView("referral")}
            >
              My Refers
            </Button>

            <div style={{ marginTop: "2vh" }}>
              {
                view === "referral" && (
                  <InfiniteScrollComponent
                    apiEndpoint="recruitment/employee/my-referrals"
                    renderUI={renderUIDesktopReferral}
                    initialPage={1}
                  />
                )
                // <DynamicTable
                //     fetchData={fetchRowMyReferralData}
                //     columns={referralColumns}
                //     handleRowClick={() => { }}
                //     pageSize={5}
                //     showPagination={true}
                // />
              }
              {view === "opening" && (
                // <DynamicTable
                //   fetchData={fetchRowOpeningData}
                //   columns={openingColumns}
                //   handleRowClick={() => {}}
                //   pageSize={5}
                //   showPagination={true}
                // />
                <InfiniteScrollComponent
                  apiEndpoint="recruitment/referral-jobs"
                  renderUI={renderUIDesktopOpening}
                  initialPage={1}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReferralOpeningList;
