import React, { useEffect, useState } from 'react';
import styles from "./Documents.module.scss"
import request from '../../../utils/axios';
import { DownloadOutlined, EyeFilled, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import view from "../../../assets/view.png"
import activeDownload from "../../../assets/activeDownload.png"

function Documents() {
    const [documentList, setDocumentList] = useState([])
    const fetchDocuments = async () => {
        try {

            const data = await request({
                method: "get",
                url: `/profile/policy/document/?page=1&page_size=3`,
            })

            console.log("data", data.pagination_data.data);
            setDocumentList(data.pagination_data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    function capitalizeFirstLetter(line) {
        return line.split(' ').map(word => {
            const lowercaseWord = word.toLowerCase();
            return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
        }).join(' ');
    }

    useEffect(() => {
        fetchDocuments()
    }, [])
    return (
        <div className={styles.documentsDetailsContainer}>
            <div className={styles.documentsDetailsInnerContainer}>
                <p className={styles.heading}>Policies</p>
                {
                    documentList?.map((document, index) => {
                        return (
                            <div style={{ display: "flex", justifyContent: "space-between", width: "50%" }}>
                                <p style={{
                                    fontSize: "16px",
                                    color: "#161616",
                                    fontWeight: "400"
                                }}>{capitalizeFirstLetter(document.name)}</p>

                                <div style={{ display: "flex", gap: "30px" }}>
                                    <img src={view} alt="view" width={25} height={25}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            window.open(document.document, "_blank");
                                        }}
                                    />
                                    <img src={activeDownload} alt="activeDownload" width={25} height={25}
                                        style={{ cursor: "pointer" }}
                                        onClick={async () => {
                                            const documentUrl = document.document;

                                            try {
                                                const response = await fetch(documentUrl);
                                                const blob = await response.blob();
                                                saveAs(blob, `${document.name}.pdf`);
                                            } catch (error) {
                                                console.error('Error downloading PDF:', error);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default Documents