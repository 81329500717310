import { Popover } from 'antd'
import React, { useEffect, useState } from 'react'
import like from "../../../../../assets/Feeds/like.png";
import comment from "../../../../../assets/Feeds/comment.png";
import heart from "../../../../../assets/Feeds/heart.png";
import good from "../../../../../assets/Feeds/good.png";
import party from "../../../../../assets/Feeds/party.png";
import funny from "../../../../../assets/Feeds/funny.png";
import getScreenSize from "../../../../../utils/getScreenSize";
import { useParams } from 'react-router-dom';
import request from '../../../../../utils/axios';

function DisplayPost() {
    const screenSize = getScreenSize();
    const params = useParams();

    const likeContent = (
        <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <img src={heart} alt="heart" width={25} height={25} />
            <img src={good} alt="good" width={25} height={25} />
            <img src={party} alt="party" width={25} height={25} />
            <img src={funny} alt="funny" width={25} height={25} />
        </div>
    )

    const [showComment, setShowComment] = useState(false)

    const [postDetails, setPostDetails] = useState({
        added_by: "",
        designation: "",
        employee_image: "",
        text: "",
        image: "",
        reaction_count: 0,
        total_comments: 0,
    })

    const reactOnFeed = async () => {
        try {
            const response = await request({
                method: "get",
                url: `feeds/admin/particular-post/details?id=${params.id}`,
            })


            setPostDetails({
                ...postDetails,
                added_by: response.data.added_by,
                designation: response.data.designation,
                employee_image: response.data.employee_image,
                text: response.data.text,
                image: response.data.image,
                reaction_count: 0,
            })

        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        reactOnFeed()
    }, [])

    return (
        <div>
            {
                screenSize.width < 768
                    ?
                    <div style={{ padding: "0px 16px", marginTop: "20px" }}>
                        <div style={{ border: "1px solid #F2F2F2", padding: "7px" }}>
                            <div style={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
                                <img src="https://picsum.photos/200" alt="" width={50} height={50} style={{ borderRadius: "5px" }} />
                                <div>
                                    <p style={{ color: "#008080", fontSize: "18px", fontWeight: "600", margin: "0px", marginBottom: "5px" }}>{postDetails.added_by}</p>
                                    <p style={{ fontSize: "16px", color: "#A8A8A8", margin: "0px" }}>{postDetails.designation}</p>
                                </div>
                            </div>
                            <p
                                style={{
                                    color: "#727272", fontSize: "12px", fontWeight: "400", lineHeight: "18px", marginTop: "20px"
                                }}
                            >{postDetails.text}</p>

                            <img src={postDetails.image} alt="" height={300} style={{ width: "100%", borderRadius: "5px" }} />

                        </div>
                        <div style={{ border: "1px solid #F2F2F2", display: "flex", justifyContent: "space-around", padding: "10px 0px" }}>
                            <Popover placement="top" content={likeContent} arrow={false}>
                                <p style={{ fontSize: "16px", color: "#B0B0B0", fontWeight: "400", margin: "0px" }}><img src={like} alt="like" width={16} height={14} /> Like</p>
                            </Popover>

                            <p style={{ fontSize: "16px", color: "#B0B0B0", fontWeight: "400", margin: "0px" }}
                                onClick={() => setShowComment(!showComment)}
                            ><img src={comment} alt="comment" width={16} height={14} />Comment</p>
                        </div>

                        {/* comment */}
                        {
                            showComment
                            &&
                            <div style={{ border: "1px solid #F2F2F2", padding: "20px" }}>
                                <div style={{ display: "flex", gap: "10px", alignItems: "flex-start", width: "100%" }}>
                                    <img src="https://picsum.photos/200" alt="" width={22} height={22} style={{ borderRadius: "5px" }} />
                                    <div style={{ width: "100%", border: "1px solid #F3F3F3", padding: "10px", background: "#FAFAFA" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", }}>
                                            <p style={{ color: "#008080", fontSize: "14px", fontWeight: "500", margin: "0", marginBottom: "5px" }}>Davis Curtis</p>
                                            <p style={{ color: "#A8A8A8", fontSize: "12px", margin: "0px" }}>1 Hr</p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: "12px", color: "#A8A8A8", margin: "0px", marginBottom: "5px" }}>HR Manager</p>
                                            <p style={{ fontSize: "12px", color: "#727272", margin: "0px" }}>Lorem ipsum dolor sit amet consectetur. Fermentum nunc auctor imperdiet ullamcorper proin sed sociis. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div style={{ padding: "50px" }}>
                        <div style={{ padding: "30px", background: "white", borderRadius: "10px" }}>
                            <div style={{ width: "700px" }}>
                                <div style={{ border: "1px solid #F2F2F2", padding: "20px" }}>
                                    <div style={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
                                        <img src={postDetails.employee_image} alt="" width={66} height={66} style={{ borderRadius: "5px" }} />
                                        <div>
                                            <p style={{ color: "#008080", fontSize: "22px", fontWeight: "600", margin: "0px", marginBottom: "5px" }}>{postDetails.added_by}</p>
                                            <p style={{ fontSize: "16px", color: "#A8A8A8", margin: "0px" }}>{postDetails.designation}</p>
                                        </div>
                                    </div>
                                    <p
                                        style={{
                                            color: "#727272", fontSize: "18px", fontWeight: "400", lineHeight: "27px", marginTop: "20px"
                                        }}
                                    >{postDetails.text}</p>

                                    <img src={postDetails.image} alt="" width={600} height={400} style={{ borderRadius: "5px" }} />

                                </div>
                                <div style={{ border: "1px solid #F2F2F2", display: "flex", justifyContent: "space-around", padding: "20px" }}>
                                    <Popover placement="top" content={likeContent} arrow={false}>
                                        <p style={{ fontSize: "20px", color: "#B0B0B0", fontWeight: "400", margin: "0px" }}><img src={like} alt="like" width={25} height={25} /> Like</p>
                                    </Popover>

                                    <p style={{ fontSize: "20px", color: "#B0B0B0", fontWeight: "400", margin: "0px" }}
                                        onClick={() => setShowComment(!showComment)}
                                    ><img src={comment} alt="comment" width={25} height={25} /> Comment</p>
                                </div>
                                {/* comment */}
                                {
                                    showComment
                                    &&
                                    <div style={{ border: "1px solid #F2F2F2", padding: "20px" }}>
                                        <div style={{ display: "flex", gap: "10px", alignItems: "flex-start", width: "100%" }}>
                                            <img src="https://picsum.photos/200" alt="" width={34} height={34} style={{ borderRadius: "5px" }} />
                                            <div style={{ width: "100%", border: "1px solid #F3F3F3", padding: "10px", background: "#FAFAFA" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", }}>
                                                    <p style={{ color: "#008080", fontSize: "20px", fontWeight: "500", margin: "0", marginBottom: "5px" }}>Davis Curtis</p>
                                                    <p style={{ color: "#A8A8A8", fontSize: "16px", margin: "0px" }}>1 Hr</p>
                                                </div>
                                                <div>
                                                    <p style={{ fontSize: "16px", color: "#A8A8A8" }}>HR Manager</p>
                                                    <p style={{ fontSize: "16px", color: "#727272", margin: "0px" }}>Lorem ipsum dolor sit amet consectetur. Fermentum nunc auctor imperdiet ullamcorper proin sed sociis. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
            }
        </div>

    )
}

export default DisplayPost