import { Checkbox, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import styles from './Preferences.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import request from '../../../../../../utils/axios';
import { setToggleOption } from "../../../../../../redux/reducer/Form24SettingReducer";
import { setLoader } from '../../../../../../redux/reducer/LoaderReducer';

function Preferences({ prev }) {
  const dispatch = useDispatch();
  let selectedOptions = useSelector(state => state.form24Setting.toggleOption);
  let personResponsibleForDeductions = useSelector((state) => state.form24Setting.personResponsibleForDeduction)
  let particularsOfReturn = useSelector((state) => state.form24Setting.particularsOfReturn)
  let particularsOfDeductor = useSelector((state) => state.form24Setting.particularsOfDeductor)

  const [options, setOptions] = useState([
    { label: 'Suppress Zero Gross Income', value: 1, selected: false },
    { label: 'Suppress Zero IT in Annexure 1', value: 2, selected: false },
    { label: 'Suppress Zero IT in Annexure 2', value: 3, selected: false },
    { label: 'Suppress Address IT in Annexure 1', value: 4, selected: false },
    { label: 'Exclude Employees with No PAN card in eTDS', value: 5, selected: false },
    { label: 'Form 24Q', value: 6, selected: false },
    { label: 'Form 24Q Annexure 1 ', value: 7, selected: false },
    { label: 'Form 24Q Annexure 2 ', value: 8, selected: false },
    { label: 'Form 27A', value: 9, selected: false },
    { label: 'Include Zero IT in eTDS', value: 10, selected: false },
  ]);


  // const params = useParams();

  const [preferencesList, setPreferencesList] = useState([]);


  const submit = async (values) => {
    dispatch(setLoader({ loading: true }))
    try {
      const data = await request({
        method: "post",
        url: `tax-management/admin/create-form24Q`,
        data: {
          particularsOfReturn,
          particularsOfDeductor,
          personResponsibleForDeductions,
          preferencesList,
        }
      })
    }
    catch (err) {
      console.log("err...", err)
    }
    dispatch(setLoader({ loading: false }))
  };


  function onChange(checkedValues) {
    console.log('checked = ', checkedValues);
    setPreferencesList(checkedValues)

  }

  useEffect(() => {
    setPreferencesList([...selectedOptions])
  
     const data = options.map((item) => {
        if (selectedOptions.includes(item.value)) {
          item.selected = true
          return item
        } else {
          item.selected = false
          return item
        }
      })

      setOptions(data)

  }, [])


  return (
    <div className={styles.container}>
      {/* <p className={styles.heading}>24Q Parameters</p> */}
      {/* <div className={styles.subContainer}>
        <p className={styles.subHeading}>Preferences</p>
      </div> */}

      <div>

        <Checkbox.Group style={{ width: '100%' }} value={preferencesList}  onChange={onChange}>
          <Row gutter={[40, 16]}>
            {
              options.map((item, index) => {
                return (
                  <Col xs={24} span={12} key={index}>
                    <Checkbox 
                    checked={true} 
                    value={item.value} 
                    style={{ lineHeight: '32px'
                     }} >{item.label}{item.selected}</Checkbox>
                  </Col>
                )
              })
            }
          </Row>
        </Checkbox.Group>
        <button onClick={() => prev()}
          className={styles.prevBtn}
        > prev</button>
        <button onClick={() => submit()}
          className={styles.nextBtn}
        > Submit</button>
      </div>
    </div>
  )
}

export default Preferences