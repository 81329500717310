import React, { useState, useEffect } from 'react';
import { Select, Checkbox } from 'antd';

const { Option } = Select;

const MultiSelectCheckboxDropdown = ({ options, onChange, placeholder }) => {
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        onChange(selectedItems);
    }, [selectedItems, onChange]);

    const handleCheckboxChange = (optionValue, checked) => {
        const newSelectedItems = checked
            ? [...selectedItems, optionValue]
            : selectedItems.filter(item => item !== optionValue);
        setSelectedItems(newSelectedItems);
    };

    return (
        <Select
            style={{ width: '100%' }}
            placeholder={placeholder}
            value={selectedItems.length > 0 ? `${selectedItems.length} item selected` : <span style={{ color: 'gray' }}>{placeholder}</span>}
            onChange={setSelectedItems}
            size='large'
            dropdownRender={(menu) => (
                <div style={{ height: `250px`, width: "100%", overflowY: 'auto' }}>
                    {options.map(option => (
                        <div key={option.id} style={{ display: 'flex', alignItems: 'center', padding: '4px 8px' }}>
                            <Checkbox
                                checked={selectedItems.includes(option.id)}
                                onChange={(e) => handleCheckboxChange(option.id, e.target.checked)}
                            >
                                {option.name}
                            </Checkbox>
                        </div>
                    ))}
                </div>
            )}
        >
            {options.map(option => (
                <Option key={option.id} value={option.id}>
                    {option.name}
                </Option>
            ))}
        </Select>
    );
};

export default MultiSelectCheckboxDropdown;

