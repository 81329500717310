import React, {useState, useEffect, useRef} from 'react';
import {
  TimePicker,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Spin,
} from 'antd';
import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import mapLocation from '../../../assets/location.svg';
import './meeting.scss';
import request from '../../../utils/axios';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  Marker,
} from '@react-google-maps/api';
import dayjs from 'dayjs';
import {
  InfoCircleOutlined,
  LoadingOutlined,
  WarningFilled,
} from '@ant-design/icons';

const MAP_API_KEY = process.env.GOOGLE_MAP_API_KEY;

const containerStyle = {
  width: '100%',
  height: '400px',
};

const inputStyle = {
  width: '100%',
  padding: '0.5rem',
  boxSizing: 'border-box',
  marginBottom: '10px',
  borderRadius: '8px',
  borderColor: '#d9d9d9', // Corrected property name
};

const center = {
  lat: -3.745,
  lng: -38.523,
};
const MeetingForm = ({MeetingType}) => {
  const format = 'HH:mm'; // Define the time format for TimePicker
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {TextArea} = Input;
  const autocompleteRef = useRef(null);
  const [holdSalaryDetail, setHoldSalaryDetail] = useState({
    employement_number: '',
    name: '',
  });
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const [EmployeeDetails, setEmployeeDetails] = useState({
    employement_number: '',
    ClientName: '',
    AddLocation: '',
    date: '',
    endTime: '',
    startTime: '',
    description: '',
    latitude: '',
    longitude: '',
  });
  const [selectedAddress, setSelectedAddress] = useState('');
  const [markerPosition, setMarkerPosition] = useState(center);
  const [loading, setLoading] = useState(false);
  const onFinish = async values => {
    console.log(values);
    setLoading(true);
    const payload = {
      employement_number: holdSalaryDetail.employement_number,
      client_name: values.ClientName,
      location: selectedAddress,
      description: values.description,
      date: extractDateString(values.date),
      from_meeting_time: extractTimeString(values.startTime),
      to_meeting_time: extractTimeString(values.endTime),
      longitude: markerPosition.lng,
      latitude: markerPosition.lat,
    };

    const apiUrl = `tracking/employee/employee-meeting/add`;

    try {
      const res = await request({
        method: 'post',
        url: apiUrl,
        data: payload,
      });
      //   setDataList(res.data);
      setLoading(false);
      navigate('/tracking', {replace: true});

      // console.log(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }

    // Navigation on success
  };

  const handleFormChange = (changedValues, allValues) => {
    console.log(allValues);
    setEmployeeDetails(allValues);
  };

  const extractDateString = obj => {
    // Extract year, month, and day from the object
    const year = obj.$y;
    const month = obj.$M + 1; // Months are 0-based in JavaScript, so add 1
    const day = obj.$D;

    // Format the month and day to ensure they are always two digits
    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = String(day).padStart(2, '0');

    // Return the formatted date string
    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const extractTimeString = obj => {
    // Extract hours and minutes from the object
    const hours = obj.$H;
    const minutes = obj.$m;

    // Format hours and minutes to ensure they are always two digits
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Return the formatted time string
    return `${formattedHours}:${formattedMinutes}`;
  };
  const searchForHoldSalary = async data => {
    setHoldSalaryDetail({
      ...holdSalaryDetail,
      name: data,
    });

    try {
      const response = await request({
        method: 'get',
        url: `leave/search/employees/?employee_name=${data}&type=admin`,
      });

      setSearchEmployeeList(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectChangeForHoldSalary = async (employement_number, name) => {
    try {
      setHoldSalaryDetail({
        ...holdSalaryDetail,
        employement_number,
        name,
      });

      setSearchEmployeeList([]);
    } catch (err) {
      console.log(err);
    }
  };

  // console.log('selected address', selectedAddress, markerPosition);
  const onPlaceChanged = () => {
    const autocomplete = autocompleteRef.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setMarkerPosition({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        setSelectedAddress(place.formatted_address);
      }
    }
  };
  return (
    <div style={{padding: '50px', background: 'white'}}>
      <div>
        <Form
          onFinish={onFinish}
          layout="vertical"
          autoComplete="true"
          onValuesChange={handleFormChange}
          form={form}>
          {MeetingType == 'TeamMeeting' && (
            <Form.Item
              label="Employee Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter employee number!',
                },
              ]}>
              <div className="searchContainer">
                <Input
                  size="large"
                  className="searchInput"
                  value={holdSalaryDetail.name}
                  type="text"
                  placeholder="Enter Employee Name"
                  onChange={e => searchForHoldSalary(e.target.value)}
                />
                {searchEmployeeList.length > 0 && (
                  <ul>
                    {searchEmployeeList.map((item, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleSelectChangeForHoldSalary(
                            item.employement_number,
                            item.employee_name,
                          )
                        }>
                        {item.employee_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </Form.Item>
          )}

          <Row gutter={[32, 32]}>
            <Col xs={{span: 24}}>
              <Form.Item
                label={
                  <span style={{fontWeight: '500', fontSize: '16px'}}>
                    Client Name
                  </span>
                }
                name="ClientName"
                rules={[
                  {required: true, message: 'Please enter Client Name !'},
                ]}>
                <Input
                  size="large"
                  placeholder="Please enter Client Name"
                  value={EmployeeDetails.ClientName}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={{span: 24}}>
              <Form.Item
                label="Description"
                name="description"
                rules={[{required: true, message: 'Enter Description!'}]}>
                <TextArea
                  rows={4}
                  placeholder="maxLength is 100"
                  maxLength={100}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 32]}>
            <Col>
              <Form.Item
                label={
                  <span style={{fontWeight: '500', fontSize: '16px'}}>
                    Date
                  </span>
                }
                name="date"
                rules={[{required: true, message: 'Please enter date!'}]}>
                <DatePicker />
              </Form.Item>
            </Col>

            {/* Start Time */}
            <Col>
              <Form.Item
                label={
                  <span style={{fontWeight: '500', fontSize: '16px'}}>
                    Start Time
                  </span>
                }
                name="startTime"
                rules={[
                  {required: true, message: 'Please select Start Time!'},
                ]}>
                <TimePicker format={format} />
              </Form.Item>
            </Col>

            {/* End Time */}
            <Col>
              <Form.Item
                label={
                  <span style={{fontWeight: '500', fontSize: '16px'}}>
                    End Time
                  </span>
                }
                name="endTime"
                rules={[{required: true, message: 'Please select End Time!'}]}>
                <TimePicker
                  // defaultValue={dayjs('12:00', format)}
                  format={format}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={{span: 24}}>
              <Form.Item
                label={
                  <span style={{fontWeight: '500', fontSize: '16px'}}>
                    Add location
                  </span>
                }
                name="ClientName"
                rules={[{required: true, message: 'Please enter Location !'}]}>
                <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                  libraries={['places']}>
                  <div style={{position: 'relative'}}>
                    <Autocomplete
                      onLoad={autocomplete => {
                        autocompleteRef.current = autocomplete;
                      }}
                      onPlaceChanged={onPlaceChanged}>
                      <Input
                        type="text"
                        placeholder="Search for an address"
                        style={inputStyle}
                        value={selectedAddress}
                        onChange={e => {
                          setSelectedAddress(e.target.value);
                        }}
                      />
                    </Autocomplete>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={markerPosition}
                      zoom={15}>
                      <Marker position={markerPosition} Icon={mapLocation} />
                    </GoogleMap>
                  </div>
                </LoadScript>
              </Form.Item>
            </Col>
          </Row>

          <div className="btncontainer">
            <Button className="button" htmlType="submit">
              {loading ? (
                <Spin
                  indicator={<LoadingOutlined spin style={{color: 'white'}} />}
                />
              ) : (
                'Done'
              )}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default MeetingForm;
