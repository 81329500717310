// libaries
import { Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

// components
import DashboardView from './Dashboard/Dashboard';
import PayRollSummaryFullView from './PayRollSummaryFullView/PayRollSummaryFullView';
import TaxSummaryFullView from './TaxSummary/TaxSummaryFullView';
import TaxDeclarationForm from './TaxDeclarationForm/TaxDeclarationForm';
import ReimbursementFullView from './Reimbursement/Reimbursement';
import LoanFullView from './Loan/Loan';

function Dashboard() {
  const ref = useRef(null);
  const [tabsValue, setTabsValue] = useState("1")

  const changeTabsValue = (e) => {
    setTabsValue(e)
  }

  const items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <DashboardView changeTabsValue={changeTabsValue} />,
    },
    {
      key: '2',
      label: 'Payroll Summary',
      children: <PayRollSummaryFullView />
    },
    {
      key: '3',
      label: 'Tax Summary',
      children: <TaxSummaryFullView />,
    },
    {
      key: '4',
      label: 'Tax Declaration',
      children: <TaxDeclarationForm />,
    },
    {
      key: '5',
      label: 'Reimbursement',
      children: <ReimbursementFullView />,
    },
    {
      key: '6',
      label: 'Loan',
      children: <LoanFullView />,
    },
  ];

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div ref={ref}>
      <Tabs
        activeKey={tabsValue}
        items={items}
        onChange={changeTabsValue}
      />
    </div >
  )
}

export default Dashboard