import React, {useState, useEffect, useRef} from 'react';
import {
  TimePicker,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Spin,
} from 'antd';
import 'leaflet/dist/leaflet.css';
import mapLocation from '../../../assets/location.svg';
import './meeting.scss';
import request from '../../../utils/axios';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  Marker,
} from '@react-google-maps/api';
import dayjs from 'dayjs';
import {
  InfoCircleOutlined,
  LoadingOutlined,
  WarningFilled,
} from '@ant-design/icons';

const MAP_API_KEY = 'AIzaSyBA1nJCLtx3IYdKMgR4_SHM55Q-EJNiSTA';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const inputStyle = {
  width: '100%',
  padding: '0.5rem',
  boxSizing: 'border-box',
  marginBottom: '10px',
  // Corrected property name
};

const EditMeetingForm = ({MeetingType, record}) => {
  console.log(record);

  const format = 'HH:mm';
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {TextArea} = Input;
  const autocompleteRef = useRef(null);
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);

  const [Employee, setEmployee] = useState({
    employement_number: '',
    name: '',
  });
  const [markerPosition, setMarkerPosition] = useState({});
  const [EmployeeDetails, setEmployeeDetails] = useState({
    id: '',
    ClientName: '',
    Location: '',
    date: '',
    endTime: '',
    startTime: '',
    description: '',
    latitude: '',
    longitude: '',
  });

  // useEffect(() => {
  //   console.log(EmployeeDetails);
  // }, [EmployeeDetails]);

  useEffect(() => {
    if (record) {
      const meetingArray = record.meeting_time.split('---');
      const start_time = meetingArray[0].split(' ')[0];
      const end_time = meetingArray[1].split(' ')[0];
      setEmployeeDetails({
        id: record.id,
        employee_name: record.employee_name,
        ClientName: record.client_name,
        Location: address,
        date: record.date ? dayjs(record.date) : '',
        endTime: dayjs(end_time, 'HH:mm'),
        startTime: dayjs(start_time, 'HH:mm'),
        description: record.description || '',
        latitude: record.latitude,
        longitude: record.longitude,
      });
      setMarkerPosition({
        lat: record.latitude,
        lng: record.longitude,
      });
      setAddress(record.location);
      setEmployee(prevState => ({
        ...prevState,
        name: record.employee_name,
      }));
    }
  }, [record]);
  // console.log(EmployeeDetails);
  // console.log('selected address', selectedAddress, markerPosition);

  const onFinish = async values => {
    console.log(values);
    setLoading(true);
    const payload = {
      id: record.id,
      employement_number: Employee.employement_number,
      client_name: values.ClientName,
      location: address,
      description: values.description,
      date: extractDateString(values.date),
      from_meeting_time: extractTimeString(values.startTime),
      to_meeting_time: extractTimeString(values.endTime),
      longitude: markerPosition.lng,
      latitude: markerPosition.lat,
    };
    console.log(payload);
    const apiUrl = `tracking/employee/employee-meeting/edit-meeting`;

    try {
      const res = await request({
        method: 'post',
        url: apiUrl,
        data: payload,
      });
      //   setDataList(res.data);
      setLoading(false);
      navigate('/tracking', {replace: true});

      // console.log(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }

    // Navigation on success
  };

  const handleFormChange = (changedValues, allValues) => {
    console.log(allValues);
    console.log(changedValues);
    setEmployeeDetails(allValues);
  };

  const extractDateString = obj => {
    // Extract year, month, and day from the object
    const year = obj.$y;
    const month = obj.$M + 1; // Months are 0-based in JavaScript, so add 1
    const day = obj.$D;

    // Format the month and day to ensure they are always two digits
    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = String(day).padStart(2, '0');

    // Return the formatted date string
    return `${year}-${formattedMonth}-${formattedDay}`;
  };
  const extractTimeString = obj => {
    // Extract hours and minutes from the object
    const hours = obj.$H;
    const minutes = obj.$m;

    // Format hours and minutes to ensure they are always two digits
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Return the formatted time string
    return `${formattedHours}:${formattedMinutes}`;
  };
  const searchEmployee = async data => {
    setEmployee({
      ...Employee,
      name: data,
    });

    try {
      const response = await request({
        method: 'get',
        url: `leave/search/employees/?employee_name=${data}&type=admin`,
      });

      setSearchEmployeeList(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectChangeEmployee = async (employement_number, name) => {
    try {
      setEmployee({
        ...Employee,
        employement_number,
        name,
      });

      setSearchEmployeeList([]);
    } catch (err) {
      console.log(err);
    }
  };

  const onPlaceChanged = () => {
    const autocomplete = autocompleteRef.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setMarkerPosition({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        setAddress(place.formatted_address);
      }
    }
  };
  return (
    <div style={{padding: '50px', background: 'white'}}>
      <div>
        <Form
          onFinish={onFinish}
          layout="vertical"
          autoComplete="true"
          onValuesChange={handleFormChange}
          form={form}
          fields={[
            {
              name: ['employee_name'],
              value: EmployeeDetails.employee_name,
            },
            {
              name: ['ClientName'],
              value: EmployeeDetails.ClientName,
            },
            {
              name: ['description'],
              value: EmployeeDetails.description,
            },
            {
              name: ['location'],
              value: address,
            },
            {
              name: ['date'],
              value: EmployeeDetails.date,
            },
            {
              name: 'startTime',
              value: EmployeeDetails.startTime,
            },
            {
              name: 'endTime',
              value: EmployeeDetails.endTime,
            },
          ]}>
          {MeetingType == 'TeamMeeting' && (
            <Form.Item
              label="Employee Name"
              name="employee_name"
              rules={[
                {
                  required: true,
                  message: 'Please enter employee number!',
                },
              ]}>
              <div className="searchContainer">
                <Input
                  size="large"
                  className="searchInput"
                  type="text"
                  name="employee_name"
                  disabled={true}
                  placeholder="Enter Employee Name"
                  value={Employee.name}
                  onChange={e => searchEmployee(e.target.value)}
                />
                {searchEmployeeList.length > 0 && (
                  <ul>
                    {searchEmployeeList.map((item, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleSelectChangeEmployee(
                            item.employement_number,
                            item.employee_name,
                          )
                        }>
                        {item.employee_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </Form.Item>
          )}
          <Row gutter={[32, 32]}>
            <Col xs={{span: 24}}>
              <Form.Item
                label={
                  <span style={{fontWeight: '500', fontSize: '16px'}}>
                    Client Name
                  </span>
                }
                name="ClientName"
                rules={[
                  {required: true, message: 'Please enter Client Name !'},
                ]}>
                <Input
                  size="large"
                  placeholder="Please enter Client Name"
                  name="ClientName"

                  // value={EmployeeDetails.ClientName}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={{span: 24}}>
              <Form.Item
                label="Description"
                name="description"
                rules={[{required: true, message: 'Enter Description!'}]}>
                <TextArea
                  rows={4}
                  placeholder="maxLength is 100"
                  maxLength={100}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 32]}>
            <Col>
              <Form.Item
                label={
                  <span style={{fontWeight: '500', fontSize: '16px'}}>
                    Date
                  </span>
                }
                name="date"
                rules={[{required: true, message: 'Please enter date!'}]}>
                <DatePicker value={'12-02-14'} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>

            {/* Start Time */}
            <Col>
              <Form.Item
                label={
                  <span style={{fontWeight: '500', fontSize: '16px'}}>
                    Start Time
                  </span>
                }
                name="startTime"
                rules={[
                  {required: true, message: 'Please select Start Time!'},
                ]}>
                <TimePicker
                  defaultValue={dayjs(EmployeeDetails.startTime, format)}
                  format={format}
                  // onChange={(time, timeString) => {
                  //   setEmployeeDetails({
                  //     ...EmployeeDetails,
                  //     endTime: timeString,
                  //   });
                  // }}
                />
              </Form.Item>
            </Col>

            {/* End Time */}
            <Col>
              <Form.Item
                label={
                  <span style={{fontWeight: '500', fontSize: '16px'}}>
                    End Time
                  </span>
                }
                name="endTime"
                rules={[{required: true, message: 'Please select End Time!'}]}>
                <TimePicker
                  defaultValue={dayjs('12:00', format)}
                  format={format}
                  // onChange={(time, timeString) => {
                  //   setEmployeeDetails({
                  //     ...EmployeeDetails,
                  //     startTime: timeString,
                  //   });
                  // }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={{span: 24}}>
              <Form.Item
                label={
                  <span style={{fontWeight: '500', fontSize: '16px'}}>
                    Add location
                  </span>
                }
                name="location"
                rules={[{required: true, message: 'Please enter Location !'}]}>
                <LoadScript
                  googleMapsApiKey={MAP_API_KEY}
                  libraries={['places']}>
                  <div style={{position: 'relative'}}>
                    <Autocomplete
                      onLoad={autocomplete => {
                        autocompleteRef.current = autocomplete;
                      }}
                      onPlaceChanged={onPlaceChanged}>
                      <Input
                        type="text"
                        placeholder="Search for an address"
                        style={inputStyle}
                        name="location"
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                      />
                    </Autocomplete>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={markerPosition}
                      zoom={10}>
                      <Marker position={markerPosition} Icon={mapLocation} />
                    </GoogleMap>
                  </div>
                </LoadScript>
              </Form.Item>
            </Col>
          </Row>
          <div className="btncontainer">
            <Button className="button" htmlType="submit">
              {loading ? (
                <Spin
                  indicator={<LoadingOutlined spin style={{color: 'white'}} />}
                />
              ) : (
                'Update'
              )}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EditMeetingForm;
