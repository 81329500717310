import { Progress } from 'antd'
import React, { useEffect, useState } from 'react'
import arrowDown from "../../../../assets/arrowDown.png"
import edit from "../../../../assets/edit.svg"
import goal from "../../../../assets/Goal.png"

function Goal() {
    const [goalList, setGoalList] = useState([
        {
            "title": "Goal 1",
            "description": "API TESTING.......",
            "start_date": "2024-06-01",
            "end_date": "2026-06-01",
            "open": true,
            "sub_goals": [
                {
                    "title": "Sub Goal 1",
                    "description": "API TESTING FOR Sub Goal",
                    "start_date": "2024-06-01",
                    "end_date": "2024-10-31",
                    "status": 1
                },
                {
                    "title": "Sub Goal 2",
                    "description": "API TESTING FOR Sub Goal.....2",
                    "start_date": "2024-11-01",
                    "end_date": "2025-01-01",
                    "status": 1
                },
                {
                    "title": "Sub Goal 3",
                    "description": "API TESTING FOR Sub Goal........3",
                    "start_date": "2025-01-02",
                    "end_date": "2026-06-01",
                    "status": 1
                }
            ]
        },
        {
            "title": "Goal 1",
            "description": "API TESTING.......",
            "start_date": "2024-06-01",
            "end_date": "2026-06-01",
            "open": false,
            "sub_goals": [
                {
                    "title": "Sub Goal 1",
                    "description": "API TESTING FOR Sub Goal",
                    "start_date": "2024-06-01",
                    "end_date": "2024-10-31",
                    "status": 1
                },
                {
                    "title": "Sub Goal 2",
                    "description": "API TESTING FOR Sub Goal.....2",
                    "start_date": "2024-11-01",
                    "end_date": "2025-01-01",
                    "status": 1
                },
                {
                    "title": "Sub Goal 3",
                    "description": "API TESTING FOR Sub Goal........3",
                    "start_date": "2025-01-02",
                    "end_date": "2026-06-01",
                    "status": 1
                }
            ]
        }
    ])

    const showMoreOrLess = (key, index, value) => {
        let updatedGoal = {
            ...goalList[index],
            [key]: value
        };

        let newGoalList = [...goalList];
        newGoalList[index] = updatedGoal;
        
        setGoalList(newGoalList);
    }

    useEffect(() => {
        console.log(goalList)
    }, [goalList])
    return (
        <div style={{ padding: "50px" }}>
            <p style={{ fontSize: "24px", fontWeight: "600", color: "#161616" }}>My Goal</p>
            <div style={{ background: "white", padding: "30px", borderRadius: "5px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0px" }}>Goal Overview</p>
                    <button style={{ background: "#008080", color: "white", border: "1px solid #008080", height: "40px", padding: "0 20px", fontSize: "18px", borderRadius: "5px" }}>Add New Goal</button>
                </div>
                <div style={{ display: "flex", gap: "40px", alignItems: "center", marginTop: "20px" }}>
                    <div style={{ padding: "15px", background: "#FAFAFA", borderRadius: "5px", width: "250px", height: "100px" }}>
                        <p style={{ fontSize: "24px", fontWeight: "600", color: "#145757" }}>3</p>
                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>No. of Goals</p>
                    </div>
                    <div style={{ padding: "15px", background: "#FAFAFA", borderRadius: "5px", width: "450px", height: "100px" }}>
                        <Progress percent={30} />
                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616", marginTop: "18px" }}>Achievement</p>
                    </div>
                </div>

                <div>
                    {
                        goalList?.map((item, index) => {
                            return (
                                <div style={{ border: "1px solid #ECECEC", borderRadius: "5px", marginTop: "30px" }}>
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", marginTop: "20px", padding: "15px 20px", borderRadius: "5px" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                                            <img src={arrowDown} alt="arrowDown"
                                                onClick={() => showMoreOrLess("open", index, !item.open)}
                                            />
                                            <div style={{ width: "90%" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>Goal 1</p>
                                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>Successful Up Sales This Year</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>Weightage</p>
                                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616" }}>20%</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                                            <div style={{ width: "90%" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>Achievement</p>
                                                <Progress percent={30} />
                                            </div>
                                            <img src={edit} alt="edit" />
                                        </div>
                                    </div>
                                    {
                                        item?.open
                                        &&
                                        <>
                                            {
                                                item?.sub_goals?.map((subItem, subIndex) => {
                                                    return (
                                                        <div>
                                                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", padding: "15px 20px" }}>
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                                                                    <img src={goal} alt="goal" />
                                                                    <div style={{ width: "90%" }}>
                                                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616", margin: "0px" }}>Successful Up Sales This Year</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616", margin: "0px" }}>20%</p>
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                                                                    <div style={{ width: "90%" }}>
                                                                        <Progress percent={30} />
                                                                    </div>
                                                                    {/* <img src={edit} alt="edit" /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                    }

                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div >
    )
}

export default Goal