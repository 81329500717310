import React, { useState } from 'react'
import Dashboard from '../DashBoard/Dashboard';
import { Tabs } from 'antd';
import MyTeam from '../MyTeam/MyTeam';
import Feedback360 from '../360Feedback/360Feedback';
import Feedback360Dashboard from '../360FeedbackDashboard/360FeedbackDashboard';

function PerformanceTabs() {

  const items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <Dashboard />,
    },
    {
      key: '2',
      label: 'My team',
      children: <MyTeam />,
    },
    {
      key: '3',
      label: '360 Feedback',
      children: <Feedback360Dashboard />,
    },
    // {
    //   key: '4',
    //   label: 'Team Leave Requests',
    //   children: <TeamLeaveRequests />,
    // }
  ];

  const [tabsValue, setTabsValue] = useState("1")

  const changeTabsValue = (e) => {
    setTabsValue(e)
  }



  return (
    <div>
      <Tabs
        activeKey={tabsValue}
        items={items}
        onChange={changeTabsValue}
      />
    </div>
  )
}

export default PerformanceTabs