import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import request from '../../../utils/axios';
import './AddOrEditOrg.scss'
function AddOrEditOrg({ btn, type, detail, reload, setReload }) {
    const [open, setOpen] = useState(false);

    const onFinish = async (values) => {

        if (type === "Add") {
            const parent_company_id = localStorage.getItem("parent_company_id")
            await request({
                method: "post",
                url: `child-organization/create`,
                data: {
                    ...values,
                    parent_name: parent_company_id
                }
            })
        }
        else {
            await request({
                method: "put",
                url: `org/${detail.id}/update/`,
                data: values
            })
        }

        setReload(!reload)
        setOpen(false)
    }

    return (
        <div onClick={(e) => { e.stopPropagation() }} >
            <div onClick={() => setOpen(true)}>
                {btn}
            </div>
            <Modal
                centered
                open={open}
                footer={null}
                width={1000}
                onCancel={() => setOpen(false)}
            >
                <div style={{ padding: "50px 0px 50px 0px" }}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        fields={[
                            {
                                name: ["org_name"],
                                value: detail?.org_name
                            },
                            {
                                name: ["domain_name"],
                                value: detail?.domain_name
                            },
                            {
                                name: ["address"],
                                value: detail?.address
                            },
                            {
                                name: ["gst_details"],
                                value: detail?.gst_details
                            },
                            {
                                name: ["fixed_emp_code"],
                                value: detail?.fixed_emp_code
                            },
                            {
                                name: ["variable_emp_code"],
                                value: detail?.variable_emp_code
                            }
                        ]}
                    >
                        <Row gutter={[32, 32]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Company Name"
                                    name="org_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Company Name!',
                                        },
                                    ]}
                                >
                                    <Input
                                        // className='inputField'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Domain Name"
                                    name="domain_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Domain Name!',
                                        },
                                    ]}
                                >
                                    <Input
                                        // className='inputField'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[32, 32]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Address"
                                    name="address"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Address!',
                                        }
                                    ]}
                                >
                                    <Input
                                        // className='inputField'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="GST Details"
                                    name="gst_details"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter GST Details!',
                                        }
                                    ]}
                                >
                                    <Input
                                        // className='inputField'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                        <p style={{ fontWeight: "600" }}>Employee Code Sequence</p>
                        <Row gutter={[32, 32]}>

                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Form.Item
                                        label="Fixed"
                                        name="fixed_emp_code"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Fixed!',
                                            }
                                        ]}
                                    >
                                        <Input
                                            // className='inputField'
                                            size='large'
                                            style={{ width: "90%" }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Variable"
                                        name="variable_emp_code"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Variable!',
                                            }
                                        ]}
                                    >
                                        <Input
                                            type="number" // Set the type attribute to "number"
                                            // className='inputField'
                                            size='large'
                                            placeholder='Numbers Only'
                                            style={{ width: "100%" }}
                                            disabled={type === "Update"}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>


                        </Row>
                        <Button
                            className='button'
                            htmlType='submit'
                        >
                            {type}
                        </Button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}

export default AddOrEditOrg

