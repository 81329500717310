import React, {useEffect, useState} from 'react';
import request from '../../../utils/axios';
import './Search.scss';
import {Input} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
function Search({
  setTableDataForSearchEmployee, // set table data for search employee
  fetchTableDataFromApi, // if input field get empty then setTableData function call api and set data in table
  placeholderText,
  empType,
}) {
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const handleSelectChange = async value => {
    try {
      const response = await request({
        method: 'get',
        url: `attendance/admin/employee/attendance/details/?employement_number=${value}`,
      });

      setTableDataForSearchEmployee([...response]);
      setSearchEmployeeList([]);
    } catch (err) {
      console.log(err);
    }
  };

  const search = async data => {
    try {
      const response = await request({
        method: 'get',
        url: `leave/search/employees/?employee_name=${data}&type=${empType}`,
      });
      setSearchEmployeeList(response);
      if (data === '') {
        fetchTableDataFromApi();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Input
      size="large"
      placeholder="Search for employees"
      prefix={<SearchOutlined />}
      allowClear
      style={{width: '100%'}}
      onChange={e => search(e.target.value)}
    />
  );
}

export default Search;
