// import "./DocumentsDashboard.scss"
// import signature from "../../../assets/signature.svg"
import { Button, Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react'
import request from '../../../../utils/axios'
import { useNavigate } from 'react-router-dom';
// import TableWithPagination from '../../../../component/TableWithPagination/TableWithPagination';
import edit from "../../../../assets/edit.svg";
import share from "../../../../assets/share.svg"
import fullView from "../../../../assets/fullView.svg";
import add from "../../../../assets/Org/add.svg"

import "./DashboardView.scss"
import { toast } from 'react-toastify';
// import useScreenSize from "../../../../utils/getScreenSize"
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination';
import moment from 'moment';
import CommonTable from '../../../../component/CommonTable/CommonTable';
import JobSearchBar from '../JobSearchBar/JobSearchBar';
import RecruiterSearchBar from '../RecruiterSearchBar/RecruiterSearchBar';
import getScreenSize from '../../../../utils/getScreenSize';
import calendar from "../../../../assets/calendar.svg";
import rupee from "../../../../assets/activePayrollImg.svg"
import time from "../../../../assets/time.svg"
import workMode from "../../../../assets/workMode.png"
import location from "../../../../assets/location.svg";
import email from "../../../../assets/email.png";
import passoword from "../../../../assets/password.png";
import mobileStyle from "./MobileAdminJobList.module.scss"
import dayjs from 'dayjs';
function AdminRecruitmentDashboardView({ changeTabsValue }) {
    const [form] = Form.useForm()
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    const [openShareModal, setOpenShareModal] = useState(false);
    const [RecruiterList, setRecruiterList] = useState([])
    const [shareJobId, setShareJobId] = useState(null)
    // const [openModal, setOpenModal] = useState(false);
    // const [modelFor, setModelFor] = useState("")
    const frontend_base_url = process.env.REACT_APP_FRONT_APP_BASE_URL;

    const screenSize = getScreenSize();
    const ref = useRef();

    const latestJobListColumn = [
        {
            title: 'Date',
            dataIndex: 'posting_date',
            key: 'posting_date',
            render: (text, record) => <span>
                {
                    moment(text).format('DD-MM-YYYY')
                }
            </span>
        },
        {
            title: 'Job Title',
            dataIndex: 'job_title',
            key: 'job_title',
        },
        {
            title: 'Posted By',
            dataIndex: 'posted_by',
            key: 'posted_by',
        },
        {
            title: 'Industry',
            dataIndex: 'industry',
            key: 'industry',
        },
        {
            title: 'Function',
            dataIndex: 'job_function',
            key: 'job_function',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (text, record) =>
                <div>
                    {
                        record.location.map((item, index) => {
                            return (
                                <span key={index}>
                                    <span>{item}</span>
                                    {
                                        record?.location?.length - 1 !== index && <span> , </span>
                                    }
                                </span>

                            )
                        })
                    }
                </div>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Select
                    value={record.is_active}
                    onChange={(value) => {
                       
                        onHandleChangeForJobs(value, record.id)}
                    }
                    onClick={(e)=> e.stopPropagation()}
                    style={{ width: 150 }}
                >
                    {/* <Select.Option value={0}>Select</Select.Option> */}
                    <Select.Option value={1} >Active</Select.Option>
                    <Select.Option value={2}>In-Active</Select.Option>
                </Select>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => <div style={{ display: "flex" }}>
                <img alt="edit" src={edit} width={50} height={20}
                    style={{ borderRadius: "50%", marginRight: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/admin/recruitment/post-job/${record.id}`, { replace: true });
                    }}
                />
                <img alt="edit" src={share} width={50} height={25}
                    style={{ borderRadius: "50%", marginRight: "10px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setShareJobId(record.id);
                        console.log("record.id", shareJobId)
                        setOpenShareModal(true);
                    }}
                />
            </div>
        },
    ]

    const onHandleChangeForJobs = async (value, id) => {
        let is_active
        if (value === 1) {
            is_active = true
        }
        if (value === 2) {

            is_active = false 
        }
        const data = await request({
            url: `recruitment/admin/job/action?id=${id}`,
            method: "POST",
            data: {
                is_active
            }
        })

        fetchJobData()

        if (value === 1) {
            toast.success("Job Activated successfully", {
                position: "bottom-left",
            })
        }

        if (value === 2) {
            toast.success("Job In-Activated successfully", {
                position: "bottom-left",
            })
        }
    }
    const onHandleChangeForRequisition = async (value, id) => {
        //  approved
        if (value === 1) {
            // setApprovedModal(true)
            navigate(`/admin/recruitment/requisition/job-form/${id}`, { replace: true });
        }
        // Rejected
        if (value === 3) {
            const data = await request({
                url: `recruitment/requisitions/status/update?id=${id}`,
                method: "PUT",
                data: {
                    status: "REJECTED"
                }
            })
            toast.success("Requisition Rejected successfully", {
                position: "bottom-left",
            })

        }
        if (value === 2) {
            console.log("value, id")
            toast.success("Requisition Changed to pending successfully", {
                position: "bottom-left",
            })
        }
    }

    const requisitionColumns = [
        {
            title: 'Job Ttile',
            dataIndex: 'job_title',
            key: 'job_title',
        },
        {
            title: 'Raised By',
            dataIndex: 'raised_by',
            key: 'raised_by',
        },
        {
            title: 'Salary Range',
            dataIndex: 'salary_range',
            key: 'salary_range',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (text, record) =>
                <div>
                    {
                        record.location.map((item, index) => {
                            return (
                                <span key={index}>
                                    <span>{item}</span>
                                    {
                                        record?.location?.length - 1 !== index && <span> , </span>
                                    }
                                </span>

                            )
                        })
                    }
                </div>
        },
        {
            title: 'Function',
            dataIndex: 'job_function',
            key: 'job_function',
        },
        {
            title: 'Industry',
            dataIndex: 'industry',
            key: 'industry',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Select
                    value={record.status === null ? 2 : record.status}
                    onChange={(value) => {
                        onHandleChangeForRequisition(value, record.id)
                    }}
                    style={{ width: 150 }}
                >
                    {/* <Select.Option value={0}>Select</Select.Option> */}
                    <Select.Option value={1} >Approved</Select.Option>
                    <Select.Option value={2}>Pending</Select.Option>
                    <Select.Option value={3} >Rejected</Select.Option>
                </Select>
            ),
        },
    ]

    const getRecruiterList = async () => {
        let data = await request({
            method: "get",
            url: `get-all-recruiters`,
        })
        setRecruiterList(data.data)
    }

    const onHandleChangeForRecruiters = async (value, id) => {
        if (value === 1) {
            const data = await request({
                url: `recruiter/status/update?user_id=${id}`,
                method: "PUT",
                data: {
                    status: 1
                }
            });
            getallexternalrecruiters()
            toast.success("Recruiter Activated successfully", {
                position: "bottom-left",
            });
        }

        if (value === 2) {
            const data = await request({
                url: `recruiter/status/update?user_id=${id}`,
                method: "PUT",
                data: {
                    status: 2
                }
            });
            getallexternalrecruiters()
            toast.success("Recruiter De-Activated successfully", {
                position: "bottom-left",
            });
        }


    }

    const externalrecruiterscolumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Password',
            dataIndex: 'recruiter_pass',
            key: 'recruiter_pass',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Select
                    value={record.status === null ? 0 : record.status}
                    onChange={(value) => onHandleChangeForRecruiters(value, record.id)}
                    style={{ width: 150 }}
                >
                    {/* <Select.Option value={0}>Select</Select.Option> */}
                    <Select.Option value={1} >Active</Select.Option>
                    <Select.Option value={2}>In-Active</Select.Option>
                </Select>
            ),
        },


    ]

    const [CompanyName, setCompanyName] = useState([])
    const [LatestJobList, setLatestJobList] = useState([])
    const fetchJobData = async () => {
        const data = await request({
            method: "get",
            url: `recruitment/all-jobs?page=1&page_size=4`,
        })
        setLatestJobList(data?.pagination_data.data)
        setCompanyName(data?.pagination_data?.org_name)

    }
    const [requisitionList, setRequisitionList] = useState([])
    const getallrequistion = async () => {
        const data = await request({
            method: "get",
            url: `recruitment/requisitions/all?page=1&page_size=4`,
        })
        setRequisitionList(data?.pagination_data.data)
        // return data
    }
    const [externalRecruiterList, setExternalRecruiterList] = useState([])
    const getallexternalrecruiters = async () => {
        const data = await request({
            method: "get",
            url: `recruiter/all?page=1&page_size=4`,
        })
        setExternalRecruiterList(data?.pagination_data.data)
        // return data
    }


    const [search, setSearch] = useState("");
    useEffect(() => {

        let timer = setTimeout(() => {
            console.log("seaching....", search);
            // fetchData();
        }, 2000)

        return () => clearTimeout(timer)
    }, [search])

    // const { TabPane } = Tabs;

    // CSS style object to add space between tabs
    const tabStyle = {
        marginRight: '100px', // Adjust the margin as per your preference
    };

    const handleRowClick = (record) => {
        // Navigate to a new component with the selected record data
        navigate("/admin/recruitment/details/" + record.id, { replace: true });
    };

    const onFinish = async (values) => {
        try {
            console.log('Success:', values);
            const data = await request({
                method: "post",
                url: "create/recruiter",
                data: values
            })
            toast.success("Recruiter Added successfully", {
                position: "bottom-left",
            })
            setOpenModal(false)
            form.resetFields();

            // Refetch the data after successful submission
            fetchJobData();
            getallrequistion();
            getallexternalrecruiters();
            getRecruiterList();
        } catch {
            toast.error("Recruiter could not be added. Please try after some time", {
                position: "bottom-left",
            })
        }

    }

    const onShareFinish = async (values) => {
        const data = await request({
            method: "post",
            url: `recruitment/job/share?id=${shareJobId}`,
            data: values
        })
        toast.success("Job Shared Successfully", {
            position: "bottom-left",
        })
        setOpenShareModal(false)
        form.resetFields();
    }

    const policiesSelectOption = [
        {
            key: '1',
            label:
                <a>
                    1st menu item
                </a>

        },
        {
            key: '2',
            label:
                <a>
                    1st menu item
                </a>

        },
    ]

    useEffect(() => {
        getRecruiterList()
        fetchJobData()
        getallrequistion()
        getallexternalrecruiters()

    }, [])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);


    return (
        <div ref={ref}>
            {
                screenSize.width < 768
                    ?
                    // mobile view
                    <div>
                        {/* Latest Jobs */}
                        <div className={mobileStyle.internalJobOpening}>
                            <div className={mobileStyle.headerContainer}>
                                <p className={mobileStyle.heading}>Latest Jobs</p>
                                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                    <img src={add} alt="add"
                                        onClick={() => navigate("/admin/recruitment/post-job", { replace: true })}
                                        width={30} height={30}
                                    />
                                    <img src={fullView} alt="fullView"
                                        onClick={() => changeTabsValue("2")}
                                    />
                                </div>
                            </div>

                            {
                                LatestJobList?.map((item, index) => {
                                    return (
                                        <>
                                            <div key={index} className={mobileStyle.internalJobOpeningContainer}>

                                                <div style={{ width: "100%" }}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 0px" }}>
                                                        <p className={mobileStyle.candidate}>
                                                            {item.job_title}
                                                        </p>
                                                        <button
                                                            style={{ background: "#008080", color: "white", border: "none", borderRadius: "5px", padding: "5px 10px" }}
                                                            onClick={() => navigate(`/admin/recruitment/post-job/${item.id}`, { replace: true })}
                                                        >Edit</button>
                                                    </div>
                                                    <p className={mobileStyle.jobTitle}>{item.industry}</p>
                                                    <p className={mobileStyle.date}>
                                                        <img src={calendar} alt="calendar" width={20} height={20} />{" "}
                                                        {dayjs(item.posting_date).format("DD-MM-YYYY")}
                                                    </p>
                                                    <p className={mobileStyle.time}>
                                                        <img src={workMode} alt="workMode" width={20} height={20} />{" "}
                                                        {item?.job_function}
                                                    </p>
                                                    <p className={mobileStyle.location}>
                                                        <img src={location} alt="location" width={20} height={20} />{" "}
                                                        {
                                                            item.location.map((el, elIndex) => {
                                                                return <span key={elIndex}>{el}
                                                                    {
                                                                        item?.location?.length - 1 !== elIndex && <span> , </span>
                                                                    }
                                                                </span>
                                                            })
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                index !== LatestJobList.length - 1 && <hr />
                                            }
                                        </>
                                    )
                                })
                            }
                        </div>

                        {/* divider */}
                        <div
                            style={{
                                height: "8px",
                                backgroundColor: "#F7F8FC",
                                marginTop: "10px",
                            }}
                        >
                        </div>

                        {/* Requisitions */}
                        <div className={mobileStyle.internalJobOpening}>
                            <div className={mobileStyle.headerContainer}>
                                <p className={mobileStyle.heading}>Requisitions</p>
                                <img src={fullView} alt="fullView"
                                    onClick={() => changeTabsValue("3")}
                                />
                            </div>

                            {
                                requisitionList?.map((item, index) => {
                                    return (
                                        <>
                                            <div key={index} className={mobileStyle.internalJobOpeningContainer}>

                                                <div>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <p className={mobileStyle.candidate}>
                                                            {item.job_title}
                                                        </p>
                                                        <Select
                                                            value={item.status === null ? 2 : item.status}
                                                            onChange={(value) => {
                                                                onHandleChangeForRequisition(value, item.id)
                                                            }}
                                                            style={{ width: 150 }}
                                                        >
                                                            {/* <Select.Option value={0}>Select</Select.Option> */}
                                                            <Select.Option value={1} >Approved</Select.Option>
                                                            <Select.Option value={2}>Pending</Select.Option>
                                                            <Select.Option value={3} >Rejected</Select.Option>
                                                        </Select>
                                                    </div>
                                                    <p className={mobileStyle.jobTitle}>{item.industry}</p>
                                                    <p className={mobileStyle.date}>
                                                        <img src={rupee} alt="rupee" width={20} height={20} />{" "}
                                                        {item.salary_range}
                                                    </p>
                                                    <p className={mobileStyle.time}>
                                                        <img src={workMode} alt="workMode" width={20} height={20} />{" "}
                                                        {item?.job_function}
                                                    </p>
                                                    <p className={mobileStyle.location}>
                                                        <img src={location} alt="location" width={20} height={20} />{" "}
                                                        {
                                                            item.location.map((el, elIndex) => {
                                                                return <span key={elIndex}>{el}
                                                                    {
                                                                        item?.location?.length - 1 !== elIndex && <span> , </span>
                                                                    }
                                                                </span>
                                                            })
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                index !== requisitionList.length - 1 && <hr />
                                            }
                                        </>
                                    )
                                })
                            }

                            {/* {
                                externalRecruiterList?.map((item, index) => {
                                    return (
                                        <>
                                            <div key={index} className={mobileStyle.internalJobOpeningContainer}>

                                                <div>
                                                    <p className={mobileStyle.candidate}>
                                                        {item.job_title}
                                                    </p>
                                                    <p className={mobileStyle.jobTitle}>{item.industry}</p>
                                                    <p className={mobileStyle.date}>
                                                        <img src={rupee} alt="rupee" width={20} height={20} />{" "}
                                                        {dayjs(item.salary_range).format("DD-MM-YYYY")}
                                                    </p>
                                                    <p className={mobileStyle.time}>
                                                        <img src={workMode} alt="workMode" width={20} height={20} />{" "}
                                                        {item?.job_function}
                                                    </p>
                                                    <p className={mobileStyle.location}>
                                                        <img src={location} alt="location" width={20} height={20} />{" "}
                                                        {
                                                            item.location.map((el, elIndex) => {
                                                                return <span key={elIndex}>{el}
                                                                    {
                                                                        item?.location?.length - 1 !== elIndex && <span> , </span>
                                                                    }
                                                                </span>
                                                            })
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                index !== externalRecruiterList.length - 1 && <hr />
                                            }
                                        </>
                                    )
                                })
                            } */}
                        </div>

                        {/* divider */}
                        <div
                            style={{
                                height: "8px",
                                backgroundColor: "#F7F8FC",
                                marginTop: "10px",
                            }}
                        >
                        </div>

                        {/* External Recruiters */}
                        <div className={mobileStyle.internalJobOpening}>
                            <div className={mobileStyle.headerContainer}>
                                <p className={mobileStyle.heading}>External Recruiters</p>
                                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                    <img src={add} alt="add" onClick={() => setOpenModal(true)} width={30} height={30} />
                                    <img src={fullView} alt="fullView"
                                        onClick={() => changeTabsValue("4")}
                                        width={35} height={35}
                                    />
                                </div>
                            </div>

                            {
                                externalRecruiterList?.map((item, index) => {
                                    return (
                                        <>
                                            <div key={index} className={mobileStyle.internalJobOpeningContainer}>

                                                <div>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <p className={mobileStyle.candidate}>
                                                            {item.name}
                                                        </p>
                                                        <Select
                                                            value={item.status === null ? 0 : item.status}
                                                            onChange={(value) => onHandleChangeForRecruiters(value, item.id)}
                                                            style={{ width: 150 }}
                                                        >
                                                            {/* <Select.Option value={0}>Select</Select.Option> */}
                                                            <Select.Option value={1} >Active</Select.Option>
                                                            <Select.Option value={2}>In-Active</Select.Option>
                                                        </Select>
                                                    </div>
                                                    <p className={mobileStyle.jobTitle}>
                                                        <img src={email} alt="email" width={20} height={20} />{" "}
                                                        {item.email}</p>
                                                    <p className={mobileStyle.date}>
                                                        <img src={passoword} alt="rupee" width={20} height={20} />{" "}
                                                        {item.recruiter_pass}
                                                    </p>

                                                </div>
                                            </div>
                                            {
                                                index !== requisitionList.length - 1 && <hr />
                                            }
                                        </>
                                    )
                                })
                            }
                        </div>

                    </div>
                    :
                    // desktop view
                    <div style={{
                        padding: "50px"
                    }}>
                        {/* <p className='heading'>Jobs</p> */}
                        <div style={{
                            background: "white",
                            padding: "40px"
                        }}>
                            <div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                    <div style={{ display: "flex", gap: "30px", alignItems: 'center' }}>
                                        <p style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>Latest Jobs</p>
                                        <JobSearchBar
                                            setLatestJobList={setLatestJobList}
                                            fetchTableDataFromApi={fetchJobData}
                                        />
                                    </div>
                                    <div style={{ display: "flex", gap: "40px" }}>
                                        {/* <Select className='filterSelect'
                                            placeholder="Filter"
                                        >
                                            <Select.Option value="0">HOME</Select.Option>
                                            <Select.Option value="1">OFFICE</Select.Option>
                                            <Select.Option value="2">HYBRID</Select.Option>
                                        </Select> */}
                                          <Button
                                            onClick={() => {
                                                // const companyName=LatestJobList
                                                console.log("companyName",CompanyName)
                                                navigate(`/${CompanyName}/careers/jobs`, { replace: true })}}
                                            className='postJobBtn' style={{width:"190px"}}>
                                           Go To Careers Page
                                        </Button>
                                        <Button
                                            onClick={() => navigate("/admin/recruitment/post-job", { replace: true })}
                                            className='postJobBtn'>
                                            Post a Job
                                        </Button>
                                        <img src={fullView} alt="fullView" width={35} height={35}
                                            onClick={() => changeTabsValue("2")}
                                        />
                                    </div>
                                </div>

                                <div style={{ marginTop: "20px" }}>

                                    <Table
                                        columns={latestJobListColumn}
                                        dataSource={LatestJobList}
                                        // handleRowClick={()=>{
                                        //     navigate("/admin/dashboard")
                                        // }}
                                        pagination={false}
                                        onRow={record => ({
                                            onClick: () => {
                                                handleRowClick(record)
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{
                            background: "white",
                            padding: "40px",
                            marginTop: "30px"
                        }}>
                            <div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                    <div style={{ display: "flex", gap: "30px", alignItems: 'center' }}>
                                        <p style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>Requisitions</p>
                                        {/* <Input
                                        prefix={<SearchOutlined />}
                                        placeholder="Search for requisitions or action"
                                        style={{ width: "30vw", height: "40px", borderRadius: "21px" }}
                                        onChange={(e) => setSearch(e.target.value)}
                                    /> */}
                                    </div>
                                    {/* <div style={{ display: "flex", gap: "40px" }}>
                                    <Select className='filterSelect'
                                        placeholder="Filter"
                                    >
                                        <Select.Option value="0">HOME</Select.Option>
                                        <Select.Option value="1">OFFICE</Select.Option>
                                        <Select.Option value="2">HYBRID</Select.Option>
                                    </Select>
                                </div> */}

                                    <img src={fullView} alt="fullView" width={35} height={35}
                                        onClick={() => changeTabsValue("3")}
                                    />
                                </div>

                                <div style={{ marginTop: "20px" }}>
                                    {/* <DynamicTable
                                    columns={requisitionColumns}
                                    fetchData={getallrequistion}
                                    pagination={false}
                                    handleRowClick={() => { }}
                                /> */}
                                    <Table
                                        columns={requisitionColumns}
                                        dataSource={requisitionList}
                                        // handleRowClick={()=>{
                                        //     navigate("/admin/dashboard")
                                        // }}
                                        pagination={false}
                                    // onRow={record => ({
                                    //     onClick: () => {
                                    //         handleRowClick(record)
                                    //     }
                                    // })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{
                            background: "white",
                            padding: "40px",
                            marginTop: "30px"
                        }}>
                            <div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}>
                                    <div style={{ display: "flex", gap: "30px", alignItems: 'center' }}>
                                        <p style={{ fontSize: "20px", fontWeight: "500", margin: "0px" }}>External Recruiters</p>
                                        <RecruiterSearchBar
                                            setExternalRecruiterList={setExternalRecruiterList}
                                            fetchTableDataFromApi={getallexternalrecruiters}
                                        />
                                    </div>
                                    <div style={{ display: "flex", gap: "30px" }}>
                                        <Button
                                            onClick={() => {
                                                setOpenModal(true)
                                            }}
                                            className='postJobBtn' style={{ width: "135px" }}>
                                            Add Recruiter
                                        </Button>
                                        <img src={fullView} alt="fullView" width={35} height={35}
                                            onClick={() => changeTabsValue("4")}
                                        />
                                    </div>
                                </div>

                                <div style={{ marginTop: "20px" }}>
                                    {/* <DynamicTable
                                    columns={externalrecruiterscolumns}
                                    fetchData={getallexternalrecruiters}
                                    pagination={false}
                                    handleRowClick={() => { }}
                                /> */}
                                    <Table
                                        columns={externalrecruiterscolumns}
                                        dataSource={externalRecruiterList}
                                        // handleRowClick={()=>{
                                        //     navigate("/admin/dashboard")
                                        // }}
                                        pagination={false}
                                    // onRow={record => ({
                                    //     onClick: () => {
                                    //         handleRowClick(record)
                                    //     }
                                    // })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <Modal
                open={openModal}
                onOk={() => { }}
                onCancel={() => {
                    setOpenModal(false)
                    form.resetFields();
                }}
                footer={null}
                centered
                width={540}
            >
                <p className='leavePolicyModelHeading'>New Recruiter</p>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                // onValuesChange={onValuesChange}
                >
                    <Row gutter={[16, 16]} className="mb-3">
                        <Col xs={24}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter recruiter Name!',
                                    },
                                ]}
                            >
                                <Input className="form-control" placeholder="Name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="mb-3">
                        <Col xs={24}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter recruiter email!',
                                    },
                                ]}
                            >
                                <Input className="form-control" placeholder="Email" />
                            </Form.Item>
                        </Col>
                    </Row>



                    <Button
                        htmlType='submit'
                        style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "30px" }}
                    >
                        Submit
                    </Button>
                </Form>
            </Modal>

            <Modal
                open={openShareModal}
                onOk={() => { }}
                onCancel={() => {
                    setOpenShareModal(false)
                    form.resetFields();
                }}
                footer={null}
                centered
                width={540}
            >
                <p className='leavePolicyModelHeading'>Share With</p>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onShareFinish}
                // onValuesChange={onValuesChange}
                >
                    <Row gutter={[16, 16]} className="mb-3">
                        <Col xs={24}>
                            <Form.Item
                                label="Recruiter"
                                name="to_be_shared_by"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select Recruiter!',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    className='multiSelectDropDown'
                                    showSearch={false}
                                >
                                    {
                                        RecruiterList.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                            )
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button
                        htmlType='submit'
                        style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                    >
                        Share
                    </Button>
                </Form>
            </Modal>

        </div>
    )
}

export default AdminRecruitmentDashboardView;