import React, { useEffect, useState } from 'react';
import desktopStyle from "./Dashboard.module.scss"
import mobileStyle from "./MobileDashboard.module.scss";
import { DatePicker, Table } from 'antd';
import cardIcon from "../../../../assets/Feeds/Admin/cardIcon.png";
import fullView from "../../../../assets/fullView.svg";
import view from "../../../../assets/view.png";
import like from "../../../../assets/Feeds/Admin/like.png";
import comment from "../../../../assets/Feeds/Admin/message.png";
import activeDownload from "../../../../assets/activeDownload.png";
import getScreenSize from "../../../../utils/getScreenSize";
import request from '../../../../utils/axios';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

function Dashboard({ setCurrentTab }) {
    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const postColumns = [
        {
            title: 'Post Title',
            dataIndex: 'post_type',
            key: 'post_type',
        },
        {
            title: 'Reaction',
            dataIndex: 'reaction',
            key: 'reaction',
            render: (text, record) => <div>
                <img src={like} alt="like" width={50} />
                <span style={{ fontWeight: "600", marginLeft: "5px" }}>{record.total_reaction}</span>
                <img src={comment} alt="comment" width={20} style={{ marginLeft: "15px" }} />
                <span style={{ fontWeight: "600", marginLeft: "5px" }}>{record.total_comments}</span>
            </div>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => <img src={view} alt="view" width={25}
                onClick={() => {
                    navigate(`/admin/feeds/display-post/${record.id}`)
                    // admin/feeds/display-post/d
                }}
            />
        }
    ]

    const surveyAnalysisColumns = [
        {
            title: 'Survey/Polls',
            dataIndex: 'survey',
            key: 'survey',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Result',
            dataIndex: 'result',
            key: 'result',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => <img src={fullView} alt="view" />
        }
    ]
    const [summayDateFilter, setSummayDateFilter] = useState({
        month: dayjs(new Date()).format("MM"),
        year: dayjs(new Date()).format("YYYY"),
    })
    const onSummaryDateChange = (date, dateString) => {
        setSummayDateFilter({
            ...summayDateFilter,
            month: dayjs(date).format("MM"),
            year: dayjs(date).format("YYYY"),
        })
    };

    const [postDateFilter, setPostDateFilter] = useState({
        month: dayjs(new Date()).format("MM"),
        year: dayjs(new Date()).format("YYYY"),
    })

    const onPostDateChange = (date, dateString) => {
        setPostDateFilter({
            ...postDateFilter,
            month: dayjs(date).format("MM"),
            year: dayjs(date).format("YYYY"),
        })
    };

    const [surveyAnalysisDateFilter, setSurveyAnalysisDateFilter] = useState({
        month: dayjs(new Date()).format("MM"),
        year: dayjs(new Date()).format("YYYY"),
    })


    const onSurveyAnalysisDateChange = (date, dateString) => {
        setSurveyAnalysisDateFilter({
            ...surveyAnalysisDateFilter,
            month: dayjs(date).format("MM"),
            year: dayjs(date).format("YYYY"),
        })
    };

    const [postSummaryCardDetails, setPostSummaryCardDetails] = useState({
        totalPost: "",
        appreciationPosts: "",
        rewardsPosts: "",
        celebrationPosts: "",
        festivePosts: "",
        Polls_Survey: ""
    })

    const postSummaryCard = [
        {
            title: "Total Posts",
            value: postSummaryCardDetails.totalPost,
            image: cardIcon
        },
        {
            title: "Appreciation Posts",
            value: postSummaryCardDetails.appreciationPosts,
            image: cardIcon
        },
        {
            title: "Rewards Posts",
            value: postSummaryCardDetails.rewardsPosts,
            image: cardIcon
        },
        {
            title: "Celebration Posts",
            value: postSummaryCardDetails.celebrationPosts,
            image: cardIcon
        },
        {
            title: "Festive Posts",
            value: postSummaryCardDetails.festivePosts,
            image: cardIcon
        },
        {
            title: "Polls/Survey",
            value: postSummaryCardDetails.Polls_Survey,
            image: cardIcon
        }
    ]
    const fetchSummaryDetails = async () => {
        try {
            const response = await request({
                method: "get",
                url: `feeds/admin/employees-post/summary?month=${summayDateFilter.month}&year=${summayDateFilter.year}`,
            })
            console.log("response", response.data);
            setPostSummaryCardDetails({
                ...postSummaryCardDetails,
                totalPost: response.data.total_post,
                appreciationPosts: response.data.appreciation_posts,
                rewardsPosts: response.data.rewards_posts,
                celebrationPosts: response.data.celebration_posts,
                festivePosts: response.data.festive_posts,
                Polls_Survey: response.data.polls_survey
            })
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const [postList, setPostList] = useState([])
    const fetchPostList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `feeds/admin/posts/all?month=${postDateFilter.month}&year=${postDateFilter.year}`,
            })
            setPostList(response.data)
        }
        catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        fetchPostList();
    }, [postDateFilter])

    useEffect(() => {
        fetchSummaryDetails()
    }, [summayDateFilter])




    return (
        <div>
            {
                screenSize.width < 768
                    // mobile
                    ?
                    <div className={mobileStyle.container}>
                        <div className={mobileStyle.postSummaryContainer}>
                            <div className={mobileStyle.flexSpaceBetween} style={{ margin: "15px 0px" }}>
                                <p className={mobileStyle.heading}>Post Summary</p>
                                <DatePicker
                                    size='medium'
                                    style={{ width: "150px" }}
                                    onChange={onSummaryDateChange}
                                />
                            </div>
                            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                <button className={mobileStyle.activeBtn}>Admin Posts</button>
                                <button className={mobileStyle.inactiveBtn}>Employee Posts</button>
                            </div>
                            <div className={mobileStyle.cardContainer}>
                                {
                                    postSummaryCard?.map((item, index) => {
                                        return (
                                            <div className={mobileStyle.card}>
                                                <div className={mobileStyle.flexSpaceBetween}>
                                                    <p className={mobileStyle.value}>{item.value}</p>
                                                    <img src={item.image} alt="" width={22} height={22} />
                                                </div>
                                                <p className={mobileStyle.title}>{item.title}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        {/* divider */}
                        <div className={mobileStyle.divider}>
                        </div>

                        <div className={mobileStyle.postContainer}>
                            <div className={mobileStyle.flexSpaceBetween}>
                                <p className={mobileStyle.heading}>Posts</p>

                                <div style={{ display: "flex", gap: "20px", alignItems: "center", padding: "0px 16px" }}>
                                    <DatePicker
                                        placeholder="Select Date"
                                        size='medium'
                                        style={{ width: "150px" }}
                                        onChange={onPostDateChange}
                                    />
                                    <img src={fullView} alt="view"
                                        onClick={() => setCurrentTab("2")}
                                    />
                                </div>
                            </div>
                            {/* use map */}
                            <div>
                                {
                                    postList?.map((item) => {
                                        return (
                                            <div>
                                                <div className={mobileStyle.postCard}>
                                                    <p className={mobileStyle.postHeading}>{item.post_type}</p>
                                                    <div className={mobileStyle.flexSpaceBetween}>
                                                        <div>
                                                            <span className={mobileStyle.countText}>Reaction</span>
                                                            <span className={mobileStyle.countValue}>{item.total_reaction}</span>
                                                        </div>
                                                        <div>
                                                            <span className={mobileStyle.countText}>Comment</span>
                                                            <span className={mobileStyle.countValue}>{item?.total_comments}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        {/* divider */}
                        <div className={mobileStyle.divider}>
                        </div>

                        <div className={mobileStyle.surveyAnalysisContainer}>
                            <div className={mobileStyle.flexSpaceBetween}>
                                <p className={mobileStyle.heading}>Survey Analysis</p>
                                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                    <DatePicker
                                        size='medium'
                                        style={{ width: "150px" }}
                                        onChange={onSurveyAnalysisDateChange}
                                    />
                                    <img src={fullView} alt="fullView"
                                        onClick={() => setCurrentTab("3")}
                                    />
                                </div>
                            </div>
                            <div className={mobileStyle.surveyAnalysisCard}>
                                <div className={mobileStyle.flexSpaceBetween}>
                                    <p className={mobileStyle.surveyAnalysisHeading}>Survey 1</p>
                                    <img src={activeDownload} alt="activeDownload" />
                                </div>
                                <div className={mobileStyle.flexSpaceBetween}>
                                    <p className={mobileStyle.status}>Status</p>
                                    <button className={mobileStyle.onGoing}>View Details</button>
                                </div>
                                <div className={mobileStyle.flexSpaceBetween} style={{ marginTop: "15px" }}>
                                    <p className={mobileStyle.status}>Results</p>
                                    <button className={mobileStyle.resultBtn}>View Details</button>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    :
                    // desktop
                    <div className={desktopStyle.container}>

                        <div className={desktopStyle.postSummaryContainer}>
                            <p className={desktopStyle.heading}>Posts Summary</p>
                            <div className={desktopStyle.flexSpaceBetween}>
                                <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                                    <div className={desktopStyle.activeBtn}>
                                        <p>Admin Posts</p>
                                    </div>
                                    <div className={desktopStyle.inactiveBtn}>
                                        <p>Employee Posts</p>
                                    </div>
                                </div>
                                <DatePicker
                                    size='large'
                                    onChange={onSummaryDateChange}
                                    picker="month"
                                    placeholder='Select'
                                />
                            </div>
                            <div className={desktopStyle.cardContainer}>
                                {
                                    postSummaryCard?.map((item, index) => {
                                        return (
                                            <div className={desktopStyle.card}>
                                                <div className={desktopStyle.flexSpaceBetween}>
                                                    <p className={desktopStyle.value}>{item.value}</p>
                                                    <img src={item.image} alt="" width={40} height={40} />
                                                </div>
                                                <p className={desktopStyle.title}>{item.title}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>


                        <div className={desktopStyle.postContainer}>
                            <div className={desktopStyle.flexSpaceBetween}>
                                <p className={desktopStyle.heading}>Post</p>
                                <div style={{ display: "flex", gap: "15px" }}>
                                    <DatePicker
                                        size='large'
                                        onChange={onPostDateChange}
                                    />
                                    <img src={fullView} alt="fullView" />
                                </div>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <Table
                                    columns={postColumns}
                                    dataSource={postList}
                                    pagination={false}
                                />
                            </div>
                        </div>

                        <div className={desktopStyle.surveyAnalysisContainer}>
                            <div className={desktopStyle.flexSpaceBetween}>
                                <p className={desktopStyle.heading}>Survey Analysis</p>
                                <DatePicker
                                    size='large'
                                    onChange={onSurveyAnalysisDateChange}
                                />
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <Table
                                    columns={surveyAnalysisColumns}
                                    dataSource={[]}
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </div>
            }
        </div>

    )
}

export default Dashboard