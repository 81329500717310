import React, { useEffect, useState } from 'react'
import request from '../../../../utils/axios';
// import "./AttendanceReportSearchBar.scss"

function AttendanceCalendarReportSearchBar({
    setTableDataForSearchEmployee, // set table data for search employee 
    fetchTableDataFromApi, // if input field get empty then setTableData function call api and set data in table
    placeholderText,
    empType,
    setEmployeeId
}) {
    const [searchEmployeeList, setSearchEmployeeList] = useState([])
    const handleSelectChange = async (value) => {
        try {
            setEmployeeId(value)
            const response = await request({
                method: "get",
                url: `attendance/admin/employee/calender/monthly/?employement_number=${value}`,
              })

            setTableDataForSearchEmployee([...response.data.monthly_attendance])
            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const search = async (data) => {
        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=${empType}`,
               
            })

            setSearchEmployeeList(response)
            if (data === "") {
                fetchTableDataFromApi()
            }
        }
        catch (err) {
            console.log(err);
        }
    }


    return (
        <div className="search-container">
            <input
                type="text"
                placeholder={placeholderText}
                onChange={(e) => search(e.target.value)}
            />
            {searchEmployeeList.length > 0 && (
                <ul>
                    {searchEmployeeList.map((item, index) => (
                        <li key={index} onClick={() => handleSelectChange(item.employement_number)}>
                            {item.employee_name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default AttendanceCalendarReportSearchBar