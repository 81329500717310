import { Input, Modal, Radio, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import request from '../../../../../utils/axios';
import { toast } from 'react-toastify';
// import Selective from './Selective/Selective';

function EveryOne() {
    const everyoneColumns = [
        {
            title: "ID",
            dataIndex: "employement_number",
            key: "employement_number"
        },
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Work Policy",
            dataIndex: "work_policy",
            key: "work_policy",
        },
    ]

    const [bulkUploadType, setBulkUploadType] = useState("everyone");
    const [selectPolicyModal, setSelectPolicyModal] = useState(false);
    const [selectiveDataList, setSelectiveDataList] = useState([]);
    const [selectedSelectiveRowKeys, setSelectedSelectiveRowKeys] = useState([]);
    const [selectedEveryOneRowKeys, setSelectedEveryOneRowKeys] = useState([]);
    const [everyoneDataList, setEveryoneDataList] = useState([]);


    const rowSelectionEveryOne = {
        selectedEveryOneRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedEveryOneRowKeys(selectedRowKeys)
        }
    }


    const [changeByDropdownList, setChangeByDropdownList] = useState([]);
    const [selectedChangeBy, setSelectedChangeBy] = useState('');


    const fetchEmployeesDetailList = async () => {
        try {

            let param = '';
            if (selectedChangeBy === 1) {
                param = 'departments'
            }
            else if (selectedChangeBy === 2) {
                param = 'designations'
            }
            else if (selectedChangeBy === 3) {
                param = 'functions'
            }
            else if (selectedChangeBy === 4) {
                param = 'grades'
            }
            else if (selectedChangeBy === 5) {
                param = 'work_locations'
            }
            const data = await request({
                method: "get",
                url: `attendance/admin/attendance/get-bulk-details?select_by=${bulkUploadType}&${param && `${param}=${selectedSelectBy}`}`,
            })

            setEveryoneDataList(data.data)

        }
        catch (error) {
            console.log("error", error)
        }
    }

    const fetchChangeByDropdownList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `admin/bulk-update/dropdown`,
            });

            setChangeByDropdownList(response.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const [selectByDropdownList, setSelectByDropdownList] = useState([]);
    const [selectedSelectBy, setSelectedSelectBy] = useState([]);

    const fetchSelectByDropdownList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `admin/bulk-update/select-by/dropdown?id=${selectedChangeBy}`,
            });

            setSelectByDropdownList(response.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const [policyList, setPolicyList] = useState([]);
    const [policyId, setPolicyId] = useState('');
    const fetchPolicyList = async () => {
        try {
            const response = await request({
                method: "get",
                url: `get-all-attendance-policy/`,
            });

            setPolicyList(response.data);
        }
        catch (error) {
            console.log("error", error);
        }
    }
    useEffect(() => {
        fetchEmployeesDetailList();
        fetchChangeByDropdownList();
        fetchPolicyList();
    }, [])

    useEffect(() => {
        if (selectedChangeBy) {
            fetchSelectByDropdownList();
        }
    }, [selectedChangeBy])

    useEffect(() => {
        if (bulkUploadType === 'everyone') {
            fetchEmployeesDetailList()
        }
    }, [selectedSelectBy])

    useEffect(() => {
        setSelectiveDataList([]);
        fetchEmployeesDetailList();
    }, [bulkUploadType])

    const updatePolicy = async () => {
        try {
            const data = await request({
                method: "post",
                url: `attendance/admin/attendance/bulk-update`,
                data: {
                    policy: policyId,
                    employees: selectedEveryOneRowKeys
                }
            })
            setSelectPolicyModal(false)
            fetchEmployeesDetailList();
            toast.success("Attendance Policy Updated", {
                position: "bottom-left",
            });
        }
        catch (error) {
            console.log("error", error)
            toast.error(
                "Could not update attendance policy, please try after some time",
                {
                    position: "bottom-left",
                }
            );
        }
    }




    return (
        <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: "20px", width: "100%" }}>
                    <Select
                        size='large'
                        style={{ width: "30%" }}
                        onChange={(e) => {
                            console.log("e....", e);
                            setSelectedChangeBy(e)
                        }}
                        placeholder="Select Change By"
                    >
                        {
                            changeByDropdownList.map((item, index) => {
                                return <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                    <Select
                        mode='multiple'
                        size='large'
                        style={{ width: "30%" }}
                        onChange={(e) => setSelectedSelectBy([...e])}
                        placeholder="Select Select By"
                    >
                        {
                            selectByDropdownList?.map((item, index) => {
                                return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </div>
                <button
                    style={{
                        border: "none",
                        background: "#008080",
                        color: "white",
                        cursor: "pointer",
                        borderRadius: "4px",
                        height: "40px",
                        width: "150px",
                        marginLeft: "20px"
                    }}
                    onClick={() => setSelectPolicyModal(true)}
                >Update Policy</button>
            </div>
            <div style={{ marginTop: "20px" }}>
                <Table
                    // rowKey={(record) => `everyone-${record.key}`}
                    rowSelection={rowSelectionEveryOne}
                    columns={everyoneColumns}
                    dataSource={everyoneDataList}
                />
            </div>

            <Modal
                centered
                open={selectPolicyModal}
                onOk={() => setSelectPolicyModal(false)}
                onCancel={() => setSelectPolicyModal(false)}
                footer={null}
                width={500}
            >
                <p style={{ fontSize: "24px" }}>Bulk Update in Policy</p>
                <Select
                    size='large'
                    style={{ width: "100%" }}
                    onChange={(e) => {
                        setPolicyId(e)
                    }}
                >
                    {
                        policyList?.map((item, index) => {
                            return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                        })
                    }
                </Select>
                <button style={{ color: "white", background: "#008080", border: "none", height: "40px", width: "100px", borderRadius: "5px", marginTop: "20px" }}
                    onClick={() => updatePolicy()}
                >Done</button>
            </Modal>
        </div>
    )
}

export default EveryOne