import {
  DownOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  ColorPicker,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tabs,
  theme,
  Tooltip,
  TreeSelect,
} from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import fullView from "../../../../assets/fullView.svg";
import edit from "../../../../assets/edit.svg";

import "./Dashboard.scss";
import { useNavigate } from "react-router-dom";
import downloadImg from "../../../../assets/downlaod.svg";
import getScreenSize from "../../../../utils/getScreenSize";
import request from "../../../../utils/axios";
import dayjs from "dayjs";
import update from "immutability-helper";
import moment from "moment";
import date from "../../../../assets/Attendance/Admin/date.svg";
import LeaveReportSearchBar from "../LeaveReportSearchBar/LeaveReportSearchBar";
import { toast } from "react-toastify";

// leave type form,
import annual from "../../../../assets/Leave/leaveType/annual.svg";
import approvalRequired from "../../../../assets/Leave/leaveType/approvalRequired.svg";
import contractual from "../../../../assets/Leave/leaveType/contractual.svg";
import coolingOffPeriod from "../../../../assets/Leave/leaveType/coolingOffPeriod.svg";
import disallowed from "../../../../assets/Leave/leaveType/disallowed.svg";
import documentUpload from "../../../../assets/Leave/leaveType/documentUpload.svg";
import encashment from "../../../../assets/Leave/leaveType/encashment.svg";
import female from "../../../../assets/Leave/leaveType/female.svg";
import halfDay from "../../../../assets/Leave/leaveType/halfDay.svg";
import male from "../../../../assets/Leave/leaveType/male.svg";
import married from "../../../../assets/Leave/leaveType/married.svg";
import maxAllowed from "../../../../assets/Leave/leaveType/maxAllowed.svg";
import month from "../../../../assets/Leave/leaveType/month.svg";
import newJoinee from "../../../../assets/Leave/leaveType/newJoinee.svg";
import notice from "../../../../assets/Leave/leaveType/notice.svg";
import onceDuringEmployment from "../../../../assets/Leave/leaveType/onceDuringEmployment.svg";
import other from "../../../../assets/Leave/leaveType/other.svg";
import permanent from "../../../../assets/Leave/leaveType/permanent.svg";
import proRata from "../../../../assets/Leave/leaveType/proRata.svg";
import single from "../../../../assets/Leave/leaveType/single.svg";
import waitingPeriod from "../../../../assets/Leave/leaveType/waitingPeriod.svg";
import weeklyOff from "../../../../assets/Leave/leaveType/weeklyOff.svg";
import casualleaves from "../../../../assets/casualleaves.svg";
import Dragger from "antd/es/upload/Dragger";
import arrowCircle from "../../../../assets/fullView.svg";
import add from "../../../../assets/Org/add.svg";
import searchImage from "../../../../assets/searchImage.svg";
import time from "../../../../assets/time.svg";
import clone_icon from "../../../../assets/clone_icon.svg";
import LeaveDashboardView from "../DashboardView/DashboardView";
import EmployeeLeavesFullView from "../EmployeeLeavesFullView/EmployeeLeavesFullView";
import LeavePolicyFullView from "../LeavePolicyFullView/LeavePolicyFullView";
import LeaveReportFullView from "../LeaveReportFullView/LeaveReportFullView";
import LeaveTypeFullView from "../LeaveTypeFullView/LeaveTypeFullView";
import HolidayFullView from "../HolidayFullView/HolidayFullView";
import LeavePolicyManagerFullView from "../LeavePolicyManagerFullView/EmployeeLeavesFullView";
import CompOffFullView from "../CompOffFullView/CompOffFullView";
import AdminLeaveTypeFullView from "../AdminLeaveTypeFullView/AdminLeaveTypeFullView";
import LeaveTypeForm from "../LeaveTypeForm/LeaveTypeForm";

function Dashboard() {
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const screenSize = getScreenSize();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [openHolidayEditModal, setOpenHolidayEditModal] = useState(false)
  const [editHolidayDetails, setEditHolidayDetails] = useState(null);
  const changeTabsValue = (e) => {
    console.log("e", e);
    setTabsValue(e)
  }
  const items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <LeaveDashboardView changeTabsValue={changeTabsValue} />,
    },
    {
      key: '2',
      label: 'Employee Leaves',
      children: <EmployeeLeavesFullView />
    },
    {
      key: '3',
      label: 'Leave policy',
      children: <LeavePolicyFullView />,
    },
    {
      key: '4',
      label: 'Leave report',
      children: <LeaveReportFullView />,
    },
    {
      key: '5',
      label: 'Employee Leave Manager',
      children: <LeavePolicyManagerFullView />,
    },
    {
      key: '6',
      label: 'Holidays',
      children: <HolidayFullView />,
    },
    {
      key: '7',
      label: 'Compensatory Leaves',
      children: <CompOffFullView />,
    },
    {
      key: '8',
      label: 'Leave types',
      children: <LeaveTypeForm changeTabsValue={changeTabsValue} />
    }
    // {
    //   key: '7',
    //   label: 'Leave types',
    //   children: <LeaveTypeFullView />,
    // },
  ];
  const mweb_items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <LeaveDashboardView changeTabsValue={changeTabsValue} />,
    },
    {
      key: '2',
      label: 'Employee Leaves',
      children: <EmployeeLeavesFullView />
    },
    {
      key: '3',
      label: 'Leave policy',
      children: <LeavePolicyFullView />,
    },
    {
      key: '4',
      label: 'Leave report',
      children: <LeaveReportFullView />,
    },
    {
      key: '5',
      label: 'Employee Leave Manager',
      children: <LeavePolicyManagerFullView />,
    },
    {
      key: '6',
      label: 'Holidays',
      children: <HolidayFullView />,
    },
    {
      key: '7',
      label: 'Comp Off',
      children: <CompOffFullView />,
    },
    {
      key: '8',
      label: 'Leave types',
      children: <AdminLeaveTypeFullView />,
    },
  ];
  const [tabsValue, setTabsValue] = useState("1")
  // Leave Policy
  // ##############################################################################################################################################################################
  // ##############################################################################################################################################################################
  let status = "Approved";
  let textColor = "red";
  let leaveDetail = [
    {
      date: "2022-01-01",
      type: "full_day",
    },
    {
      date: "2022-01-02",
      type: "full_day",
    },
    {
      date: "2022-01-03",
      type: "full_day",
    },
  ];
  const onClickHandler = () => {
    navigate("/admin/leave/leave-type-form", { replace: true });
  };
  const employeeLeavePopUp = (record) => {
    return (
      <div>
        <div style={{ display: "flex", gap: "10px" }}>
          {record.leave_dates.map((item, index) => {
            return (
              <>
                <span>
                  {item.leave_for === "full_day" ? (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "white",
                        padding: "3px",
                        borderRadius: "50%",
                        background: "#008080",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                      }}
                    >
                      {dayjs(item.date).format("DD")}
                    </div>
                  ) : record.from_second_half_start_date ? (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "black",
                        padding: "3px",
                        borderRadius: "50%",
                        background:
                          "linear-gradient(to left, #008080, #008080 50%, #DDDDDD 50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                      }}
                    >
                      {dayjs(item.date).format("DD")}
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "black",
                        padding: "3px",
                        borderRadius: "50%",
                        background:
                          "linear-gradient(to right, #008080, #008080 50%, #DDDDDD 50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                      }}
                    >
                      {dayjs(item.date).format("DD")}
                    </div>
                  )}
                </span>
              </>
            );
          })}
        </div>
      </div>
    );
  };
  const employeeLeaveColumn = [
    {
      title: "Name",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "No. Of Leaves",
      dataIndex: "leave_day",
      key: "leave_day",
      render: (text, record) => (
        <span>
          {text}
          <Popover
            placement="top"
            title="Leaves"
            content={employeeLeavePopUp(record)}
          >
            <InfoCircleOutlined />
          </Popover>
        </span>
      ),
    },
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      key: "leave_type",
    },
    {
      title: "Leave From",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Leave To",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <button className={`${text}LeaveButton`}>{text}</button>
      ),
    },
  ];

  const [isClone, setIsClone] = useState(false)
  const leavePolicyColumns = [
    {
      title: "Policy",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "From",
      dataIndex: "start_time",
      key: "start_time",
      width: 150,
    },
    {
      title: "To",
      dataIndex: "end_time",
      key: "end_time",
      width: 150,
    },
    {
      title: "Leave Types",
      dataIndex: "leave_type_details",
      key: "leave_type_details",
      render: (text, record) => (
        <span>
          {record.leave_type_details.map((item, index) => {
            return (
              <>
                {
                  <span>
                    {item.name} ({item.leaves_numbers})
                    {record.leave_type_details?.length - 1 !== index && (
                      <span> , </span>
                    )}
                  </span>
                }
              </>
            );
          })}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <img
            src={edit}
            alt="edit"
            style={{ cursor: "pointer", marginRight: "15px" }}
            onClick={() => {
              setOpenLeavePolicy(true);
              setLeavePolicyId(record.id);
            }}
          />
          <img
            src={clone_icon}
            alt="clone"
            onClick={() => {
              setOpenLeavePolicy(true);
              setLeavePolicyId(record.id);
              setIsClone(true)
            }}
          />
          {/* <Button
            style={{
              background: "#008080",
              color: "white",
              border: "none",
              padding: "5px 10px",
              borderRadius: "5px",
            }}

            onClick={() => {
              setOpenLeavePolicy(true);
              setLeavePolicyId(record.id);
              setIsClone(true)

            }}
          >
            Clone
          </Button> */}
        </div>
      ),
    },
  ];

  const compOffColumn = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      key: "employee",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Compensatory Off",
      dataIndex: "leaves_number",
      key: "leaves_number",
    },
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      key: "leave_type",
    },
  ];

  const holidayColumns = [
    {
      title: "Year",
      dataIndex: "annual_year",
      key: "annual_year",
    },
    {
      title: "Policy",
      dataIndex: "leave_policy",
      key: "leave_policy",
      render: (text, record) => (
        <span>
          {text}
          {/* <span style={{ padding: "5px", background: "#E6F2FF", borderRadius: "50%", color: "#0043FF", marginLeft: "10px" }}>+3</span > */}
        </span>
      ),
    },
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "Action",
      dataIndex: "holiday_file",
      key: "holiday_file",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <img
            src={edit}
            alt="edit"
            onClick={() => {
              setOpenHolidayEditModal(true);
              setEditHolidayDetails({
                leave_policy: record.policy_id,
                annual_year: record.annual_year,
              });
            }}
          />
          <p
            style={{
              borderRadius: "15px",
              textAlign: "center",
              cursor: "pointer",
              margin: "0px"
            }}
            onClick={async () => {
              const response = await request({
                method: "get",
                url: `${text}/`,
                responseType: "text", // Change response type to text
              });

              const csvData = new Blob([response], { type: "text/csv" }); // Set MIME type to text/csv
              const url = window.URL.createObjectURL(csvData);
              const link = document.createElement("a");
              link.href = url;
              link.download = "file.csv"; // Change filename to .csv
              link.click();
            }}
          >
            <img src={downloadImg} alt="downloadImg" />
          </p>
        </div>
      ),
    },
  ];

  const searchTry = [
    {
      employee_name: "abc",
      employement_number: "abc",
    },
  ];

  const [searchLoading, setSearchLoading] = useState(false);
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const handleSelectChange = async (value) => {
    console.log(`selected ${value}`);
    try {
      const response = await request({
        method: "get",
        url: `leave/employee/search/my-team/leave/details/?employement_number=${value}`,
      });
      console.log("response 11", response);
      setLeaveReportList([response]);
      setSearchEmployeeList([]);
    } catch (err) {
      console.log(err);
    }
  };

  const employeeLeavePolicyMangerColumns = [
    {
      title: 'Id',
      dataIndex: 'employement_number',
      key: 'employement_number',
    },
    {
      title: 'Employee Name',
      dataIndex: 'employee_name',
      key: 'employee_name',
    },
    {
      title: 'Leave Policy',
      dataIndex: 'leave_policy',
      key: 'leave_policy',
    },
    {
      title: 'Action',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => <img src={edit} alt="edit" onClick={() => {
        setSelectedEmployeeLeavePolicyManager({
          ...selectedEmployeeLeavePolicyManager,
          user_id: record.employement_number,
          policy_id: +record.policy_id
        })
        setChangeLeavePolicyModel(true)
      }} />
    }
  ]

  const [leavePolicyId, setLeavePolicyId] = useState(null);

  const [leavePolicyDataList, setLeavePolicyDataList] = useState([]);
  const [holidayLeavePolicyList, setHolidayLeavePolicyList] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectAll = () => {
    setSelectedOptions(
      holidayLeavePolicyList.map((item) => {
        return item.value;
      })
    );
  };
  const [changeLeavePolicyModel, setChangeLeavePolicyModel] = useState(false)
  const [selectedEmployeeLeavePolicyManager, setSelectedEmployeeLeavePolicyManager] = useState({
    user_id: "",
    policy_id: ""
  })

  const [holidayListFile, setHolidayListFile] = useState(null);
  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.leave_policy) {
      if (changedValues?.leave_policy.includes("all")) {
        form.setFieldsValue({
          leave_policy: holidayLeavePolicyList.map((item) => {
            return item.value;
          }),
        });
      }
    }
    console.log("changedValues", changedValues);
  };

  const fetchAllLeavePolicy = async () => {
    try {
      const data = await request({
        method: "get",
        url: "leave/leave_policy/all/?page=1&page_size=2",
      });

      setLeavePolicyDataList(data.pagination_data.data);
      const holidayLeavePolicyList = data.pagination_data.data.map(
        (item, index) => {
          return {
            label: item.name,
            value: item.id,
          };
        }
      );

      setHolidayLeavePolicyList(holidayLeavePolicyList);
    } catch (err) {
      console.log(err);
    }
  };

  const [openLeavePolicy, setOpenLeavePolicy] = useState(false);
  const [leavePolicyForm, setLeavePolicyForm] = useState({
    name: "",
    start_time: "",
    end_time: "",
    leave_type_details: [],
  });

  const fileInputRef = useRef(null);

  const onFinishPolicyForm = async (values) => {
    // console.log('Success:', leavePolicyForm);

    try {
      const start_time = dayjs(leavePolicyForm.start_time).format("YYYY-MM-DD");
      const end_time = dayjs(leavePolicyForm.end_time).format("YYYY-MM-DD");
      // values = { ...values, dob }

      if (leavePolicyId) {
        if (isClone) {
          await request({
            method: "post",
            url: `leave/admin/leave-policy/clone`,
            data: {
              ...leavePolicyForm,
              start_time: start_time,
              end_time: end_time
            }
          })
        }
        else {
          await request({
            method: "put",
            url: `leave/leave_policy/update/?leave_id=${leavePolicyId}`,
            data: {
              ...leavePolicyForm,
              start_time: start_time,
              end_time: end_time,
            },
          });

          toast.success("Leave Policy updated", {
            position: "bottom-left",
          });
        }

      } else {
        await request({
          method: "post",
          url: "leave/leave_policy/create/",
          data: {
            ...leavePolicyForm,
            start_time: start_time,
            end_time: end_time,
          },
        });
        toast.success("New Leave Policy added", {
          position: "bottom-left",
        });
      }
      setLeavePolicyId(null);
      setOpenLeavePolicy(false);
      setTimeout(() => {
        fetchAllLeavePolicy();
      }, 1000);
      // fetchAllLeavePolicy()
      navigate("/admin/leave/leave-policy", { replace: true });
    } catch (err) {
      console.log(err);
      if (leavePolicyId) {
        toast.error(
          "Leave Type could not be updated, please try after some time",
          {
            position: "bottom-left",
          }
        );
      } else {
        toast.error(
          "New Leave Policy could not be added, please try after some time",
          {
            position: "bottom-left",
          }
        );
      }
    }
  };

  const fetchLeaveType = async () => {
    try {
      const data = await request({
        method: "get",
        url: "leave/leave_types/all/",
      });

      setLeavePolicyForm({
        name: "",
        start_time: "",
        end_time: "",
        leave_type_details: data.map((item) => ({
          ...item,
          leaves_numbers: null,
        })),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSpecficLeavePolicy = async () => {
    try {
      const data = await request({
        method: "get",
        url: `leave/leave_policy/details/?leave_id=${leavePolicyId}`,
      });

      const start_date = new Date(data.data.start_time).toISOString();
      const end_date = new Date(data.data.end_time).toISOString();

      const mergedArray = leavePolicyForm.leave_type_details.map((item1) => {
        const item2 = data.data.leave_type_details.find((i) => {
          return i.name === item1.name;
        });

        return { ...item1, ...item2 };
      });

      setLeavePolicyForm({
        ...leavePolicyForm,
        name: data.data.name,
        start_time: dayjs(start_date),
        end_time: dayjs(end_date),
        leave_type_details: mergedArray,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // ##############################################################################################################################################################################
  // ##############################################################################################################################################################################

  // Leave Report
  // ##############################################################################################################################################################################
  // ##############################################################################################################################################################################
  const [leaveReportColums, setLeaveReportColums] = useState([
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "ID",
      dataIndex: "employement_number",
      key: "employement_number",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ]);

  const [mobileLeaveColumList, setMobileLeaveColumList] = useState([]);
  const fetchLeaveRoportColumn = async () => {
    try {
      const data = await request({
        method: "get",
        url: "leave/leave_type/columns/",
      });
      setMobileLeaveColumList(data.data);
      setLeaveReportColums([...leaveReportColums, ...data.data]);
      fetchLeaveReportData();
    } catch (err) {
      console.log(err);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [leaveReportList, setLeaveReportList] = useState([]);
  const fetchLeaveReportData = async () => {
    try {
      const data = await request({
        method: "get",
        url: "leave/admin/leave-report/all?page=1&page_size=4",
      });

      setLeaveReportList(data.pagination_data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const [filter, setFilter] = useState("");

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
  };
  // ##############################################################################################################################################################################
  // ##############################################################################################################################################################################

  // Leave Type
  // ##############################################################################################################################################################################
  // ##############################################################################################################################################################################
  const [leavTypeForm, setLeaveTypeForm] = useState({
    name: "",
    description: "",
    is_predefined: false,
    color: "",
    image: null,
  });
  const bgColor = useMemo(
    () =>
      typeof leavTypeForm.color === "string"
        ? leavTypeForm.color
        : leavTypeForm?.color?.toHexString(),
    [leavTypeForm.color]
  );
  const btnStyle = {
    backgroundColor: bgColor,
  };
  const [showLeaveTypeModal, setShowLeaveTypeModal] = useState(false);

  const [leaveTypesList, setLeaveTypesList] = useState([]);
  const fetchLeaveTypeList = async () => {
    try {
      const data = await request({
        method: "get",
        url: "leave/admin/leave_types/all/",
      });
      setLeaveTypesList(
        data.data.map((item) => {
          return {
            ...item,
            isCollapsed: true
          }
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const [editLeaveTypeId, setEditLeaveTypeId] = useState(null);

  const fetchSpecficLeaveType = async () => {
    try {
      const data = await request({
        method: "get",
        url: `leave/leave_type/detail/?id=${editLeaveTypeId}`,
      });

      setLeaveTypeForm({
        name: data.name,
        description: data.description,
        is_predefined: data.is_predefined,
        color: data.color,
        image: "",
      });
    } catch (err) {
      console.log(err);
    }
  };

  // const onFinish = async (values) => {
  //   const formData = new FormData();
  //   formData.append("name", leavTypeForm.name);
  //   formData.append("description", leavTypeForm.description);
  //   formData.append("is_predefined", leavTypeForm.is_predefined);
  //   formData.append("color", leavTypeForm.color);
  //   formData.append("image", leavTypeForm.image);

  //   try {
  //     if (editLeaveTypeId) {
  //       formData.append("id", editLeaveTypeId);
  //       await request({
  //         method: "put",
  //         url: `leave/leave_type/update/?id=${editLeaveTypeId}`,
  //         data: formData,
  //       });
  //     } else {
  //       await request({
  //         method: "post",
  //         url: "leave/leave_type/create/",
  //         data: formData,
  //       });
  //     }

  //     setEditLeaveTypeId(null);
  //     setShowLeaveTypeModal(false);
  //     setLeaveTypeForm({
  //       name: "",
  //       description: "",
  //       is_predefined: false,
  //       color: "",
  //       image: "",
  //     });
  //     fetchLeaveTypeList();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // ##############################################################################################################################################################################
  // ##############################################################################################################################################################################
  const handleFormChange = (changedValues, allValues) => {
    if (!changedValues.image) {
      if (changedValues?.color) {
        setLeaveTypeForm({
          ...leavTypeForm,
          ...changedValues,
          color: changedValues?.color?.toHexString().substring(0, 7),
        });
      } else {
        setLeaveTypeForm({ ...leavTypeForm, ...changedValues });
      }
    }
  };

  const downloadReport = async () => {
    if (leaveReportDays === "custom") {
      const response = await request({
        method: "get",
        url: `leave/admin/leave-report/download?start_date=${leaveReportCustomDate.start_date}&end_date=${leaveReportCustomDate.end_date}`,
        responseType: "arraybuffer",
      });
      const file = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = "file.pdf";
      link.click();
    }
    else {
      const response = await request({
        method: "get",
        url: `leave/admin/leave-report/download?days=${leaveReportDays}`,
        responseType: "arraybuffer",
      });
      const file = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = "file.pdf";
      link.click();
    }
  };

  const [employeeLeaveDetailList, setEmployeeLeaveDetailList] = useState([]);

  const fetchEmployeeLeaveDetail = async () => {
    try {
      const response = await request({
        method: "get",
        url: "leave/employees/leave-application/all/details/?page=1&page_size=4",
      });
      setEmployeeLeaveDetailList(response.pagination_data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const [compOffList, setCompOffList] = useState([]);
  const fetchCompOffList = async () => {
    try {
      const response = await request({
        method: "get",
        url: "leave/admin/comp-off/leaves/all/?page=1&page_size=4",
      });

      setCompOffList(response.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchLeaveTypeList();
    fetchAllLeavePolicy();
    fetchLeaveType();
    fetchLeaveRoportColumn();
    fetchEmployeeLeaveDetail();
    fetchCompOffList();
  }, []);

  useEffect(() => {
    if (editLeaveTypeId) {
      fetchSpecficLeaveType();
    }
    if (leavePolicyId) {
      fetchSpecficLeavePolicy();
    }
  }, [editLeaveTypeId, leavePolicyId]);

  const search = async (data) => {
    setSearchLoading(true);
    try {
      const response = await request({
        method: "get",
        url: `leave/admin/search/employees/?employee_name=${data}`,
      });

      console.log("response", response);
      setSearchEmployeeList(response);
      if (data === "") {
        fetchLeaveReportData();
      }
    } catch (err) {
      console.log(err);
    }
    setSearchLoading(false);
  };

  const [isCollapsed, setIsCollapsed] = useState(true);
  const defaultDisplayLimit = 8;

  const handleToggleCollapse = (index) => {
    let updatedValue = update(leaveTypesList, {
      [index]: {
        isCollapsed: { $set: !leaveTypesList[index].isCollapsed },
      }
    });
    setLeaveTypesList(updatedValue)
  };

  const iconLabelList = [
    {
      value: "Male",
      name: "is_for_male",
    },
    {
      value: "Female",
      name: "is_for_female",
    },
    {
      value: "Other",
      name: "is_for_others",
    },
    {
      value: "Permanent",
      name: "is_for_full_time",
    },
    {
      value: "Part Time",
      name: "is_for_part_time",
    },
    {
      value: "Contractual",
      name: "is_for_contractual",
    },
    {
      value: "Internship",
      name: "is_for_internship",
    },
    {
      value: "Married",
      name: "is_for_married",
    },
    {
      value: "Single",
      name: "is_for_single",
    },
    {
      value: "Pro Rata",
      name: "is_pro_rata",
    },
    {
      value: "Once During Employement",
      name: "is_once_during_employement_year",
    },
    {
      value: "Document Required",
      name: "is_document_required",
    },
    {
      value: "Allowed During Notice Period",
      name: "is_allowed_during_notice_period",
    },
    {
      value: "Half Day Interval",
      name: "is_half_day_interval_allowed",
    },
    {
      value: "Waiting Period",
      name: "is_waiting_period",
    },
    {
      value: "Weekly Off",
      name: "is_weekly_off_inclusion",
    },
    {
      value: "Back Dated Allowed",
      name: "is_back_dated_allowed",
    },
    {
      value: "Carry Forward Leaves",
      name: "is_carry_forward_leaves",
    },
    {
      value: "Encashement",
      name: "is_encashement",
    },
    {
      value: "Cooling Off Period",
      name: "is_cooling_off_period",
    },
    {
      value: "New Joinee",
      name: "is_applicable_for_new_joinee",
    },
    {
      value: "Disallowed",
      name: "is_disallowed",
    },
    {
      value: "Max Allowed",
      name: "is_max_allowed",
    },
    {
      value: "Min Allowed",
      name: "is_minimum_allowed",
    },
  ];

  const [openHolidayModal, setOpenHolidayModal] = useState(false);

  const onFinishHoliday = async (values) => {
    try {
      const formData = new FormData();
      formData.append("annual_year", values.year);
      formData.append("leave_policy", values.leave_policy);
      formData.append("holiday_file", holidayListFile);

      const response = await request({
        method: "post",
        url: `leave/leave_policy/holiday/upload`,
        data: formData,
      });

      setOpenHolidayModal(false);
      console.log("onFinishHoliday", response);
      toast.success("Holiday list uploaded ", {
        position: "bottom-left",
      });
      form.resetFields();
    } catch (error) {
      console.log("error", error);
      toast.error("Could not upload Holiday list, please try after some time", {
        position: "bottom-left",
      });
    }
  };

  const editHoliday = async () => {
    try {
      const formData = new FormData();
      formData.append("holiday_file", holidayListFile);
      formData.append("leave_policy", editHolidayDetails.leave_policy);
      formData.append("annual_year", editHolidayDetails.annual_year);

      const response = await request({
        method: "post",
        url: `leave/holiday/list/update/`,
        data: formData,
      });

      setOpenHolidayEditModal(false);
      console.log("onFinishHoliday", response);
      setHolidayListFile(null);
      toast.success("Holiday Updated ", {
        position: "bottom-left",
      });
      // form.resetFields();
    }
    catch (error) {
      console.log("error", error);
      toast.error("Could not upload Holiday list, please try after some time", {
        position: "bottom-left",
      });
    }
  }

  const [holidayList, setHolidayList] = useState([]);
  const fetchHolidayList = async () => {
    try {
      const response = await request({
        method: "get",
        url: `leave/admin/holiday/list/details/?page=1&page_size=4`,
      });
      console.log("fetchHolidayList", response);
      setHolidayList(response?.pagination_data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const [compOffLeaveTypeList, setCompOffLeaveTypeList] = useState([]);
  const fetchCompOffLeaveTypeList = async () => {
    try {
      const response = await request({
        method: "get",
        url: `leave/admin/comp-off/leaves-types/all`,
      });

      setCompOffLeaveTypeList(response?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const [employeeList, setEmployeeList] = useState([]);

  const fetchEmployeeList = async () => {
    try {
      const response = await request({
        method: "get",
        url: `/get-all-employees`,
      });

      setEmployeeList(response?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const [employeeLeavePolicyMangerList, setEmployeeLeavePolicyMangerList] = useState([])
  const fetchEmployeeLeavePolicyManger = async () => {
    try {
      const data = await request({
        method: "get",
        url: `leave/admin/leave-policy-management/?page=1&page_size=4`,
      })
      setEmployeeLeavePolicyMangerList(data.pagination_data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const [leavePolicy, setLeavePolicy] = useState([])
  const getLeavePolicy = async () => {
    let data = await request({
      method: "get",
      url: `leave/leave/policy/all/dropdown`,
    })
    setLeavePolicy(data.data)
  }


  useEffect(() => {
    fetchHolidayList();
    fetchEmployeeList();
    fetchCompOffLeaveTypeList();
    fetchEmployeeLeavePolicyManger();
    getLeavePolicy();
  }, []);

  const onFinishCompOff = async (values) => {
    try {
      const response = await request({
        method: "post",
        url: `leave/employee/leave-type/add/`,
        data: values
      });

      setAddCompOffModal(false);
      fetchCompOffList();
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateEmployeeLeavePolicy = async (values) => {
    try {
      const response = await request({
        method: "post",
        url: `leave/admin/employee/leave-policy/update?employement_number=${selectedEmployeeLeavePolicyManager.user_id}`,
        data: {
          policy_id: values.leave_policy
        }
      });
      setChangeLeavePolicyModel(false)
      fetchEmployeeLeavePolicyManger();
    }
    catch (err) {
      console.log("err", err);
    }
  }


  const [addCompOffModal, setAddCompOffModal] = useState(false)
  const [open, setOpen] = useState(false)

  const [dateRange, setDateRange] = useState();
  const [leaveReportDays, setLeaveReportDays] = useState(7);
  const [leaveReportCustomDate, setLeaveReportCustomDate] = useState({
    start_date: "",
    end_date: ""
  });

  const handleDateChange = async (value) => {
    const start_date = value[0]?.format("YYYY-MM-DD");
    const end_date = value[1]?.format("YYYY-MM-DD");
    setDateRange(value);
    setLeaveReportCustomDate({ ...leaveReportCustomDate, start_date, end_date })
  };

  const getPopupContainer = (triggerNode) => {
    return triggerNode.parentNode;
  };

  const handleDaysChange = (value) => {
    console.log("value", value);
    if (value === "custom") {
      // setShowDatePicker(true)
      setLeaveReportDays(value);
      setOpen(true);
    } else {
      // setShowDatePicker(false)
      setLeaveReportDays(value);
    }
  };

  const [downloadModal, setDownloadModal] = useState(false)

  const ref = useRef(null);
  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="" ref={ref}>
      {screenSize.width < 768 ? (
        // Mobile View
        <Tabs
          activeKey={tabsValue}
          items={mweb_items}
          onChange={changeTabsValue}
        />
      ) : (
        <>
          <Tabs
            activeKey={tabsValue}
            items={items}
            onChange={changeTabsValue}
          />
        </>
      )}

      {/* Leave Policy Form */}
      <Modal
        open={openLeavePolicy}
        onOk={() => { }}
        onCancel={() => {
          setOpenLeavePolicy(false);
          setLeavePolicyId(null);
          fetchLeaveType();
          setLeavePolicyForm({
            name: "",
            start_time: "",
            end_time: "",
            leave_type_details: [],
          });
        }}
        footer={null}
        centered
        width={1000}
      >
        <p className="leavePolicyModelHeading">Leave Policy</p>
        <Form
          layout="vertical"
          onFinish={onFinishPolicyForm}
          fields={[
            {
              name: ["name"],
              value: leavePolicyForm.name,
            },
            {
              name: ["start_time"],
              value: leavePolicyForm.start_time,
            },
            {
              name: ["end_time"],
              value: leavePolicyForm.end_time,
            },
          ]}
        >
          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }}>
              <Form.Item
                label="Policy Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Policy Name!",
                  },
                ]}
              >
                <Input
                  className="leavePolicyInputField"
                  // placeholder='Enter Policy Name'
                  onChange={(e) =>
                    setLeavePolicyForm({
                      ...leavePolicyForm,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          {screenSize.width < 768 ? (
            // mobile view
            <Row gutter={[0, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Date (From)"
                  name="start_time"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Date (From)!",
                    },
                  ]}
                >
                  <DatePicker
                    className="leavePolicyInputField"
                    onChange={(e) =>
                      setLeavePolicyForm({ ...leavePolicyForm, start_time: e })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Date (To)"
                  name="end_time"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Date (To)!",
                    },
                  ]}
                >
                  <DatePicker
                    className="leavePolicyInputField"
                    onChange={(e) =>
                      setLeavePolicyForm({ ...leavePolicyForm, end_time: e })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            // desktop view
            <Row gutter={[32, 32]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Date (From)"
                  name="start_time"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Date (From)!",
                    },
                  ]}
                >
                  <DatePicker
                    className="leavePolicyInputField"
                    onChange={(e) =>
                      setLeavePolicyForm({ ...leavePolicyForm, start_time: e })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  label="Date (To)"
                  name="end_time"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Date (To)!",
                    },
                  ]}
                >
                  <DatePicker
                    className="leavePolicyInputField"
                    onChange={(e) =>
                      setLeavePolicyForm({ ...leavePolicyForm, end_time: e })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <p className="noOfLeaveDaysHeadingModel">No. of leave Days</p>
              {leavePolicyForm?.leave_type_details?.map((item, index) => (
                <div key={index}>
                  <Row>
                    <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                      <p className="leaveNameModel">{item.name}</p>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 19 }}>
                      <Form.Item style={{ margin: "0px" }}>
                        <Select
                          size="default"
                          className="selectNoOfLeave"
                          onChange={(value) => {
                            if (
                              leavePolicyForm.leave_type_details[index]
                                .leaves_numbers === null
                            ) {
                              setLeavePolicyForm(
                                update(leavePolicyForm, {
                                  leave_type_details: {
                                    [index]: {
                                      id: {
                                        $set: "",
                                      },
                                      leave_type_id: {
                                        $set: leavePolicyForm
                                          .leave_type_details[index].id,
                                      },
                                      leaves_numbers: {
                                        $set: value,
                                      },
                                    },
                                  },
                                })
                              );
                            } else {
                              setLeavePolicyForm(
                                update(leavePolicyForm, {
                                  leave_type_details: {
                                    [index]: {
                                      leaves_numbers: {
                                        $set: value,
                                      },
                                    },
                                  },
                                })
                              );
                            }
                          }}
                          value={item.leaves_numbers}
                        >
                          {[...Array(51)].map((_, i) => (
                            <Select.Option key={i} value={i}>
                              {i}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
            </Col>
          </Row>
          {
            isClone ?
              <Button htmlType="submit" className="addNewPolicyFormButton">
                Clone Policy
              </Button>
              :
              <Button htmlType="submit" className="addNewPolicyFormButton">
                {leavePolicyId ? "Update Policy" : "Add New Policy"}
              </Button>
          }

        </Form>
      </Modal>

      {/* Comp Off */}

      <Modal
        open={addCompOffModal}
        onOk={() => { }}
        onCancel={() => {
          setAddCompOffModal(false);
        }}
        footer={null}
        centered
        width={540}
      >
        <p className="leavePolicyModelHeading">Comp Off</p>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishCompOff}
        >
          <Form.Item
            label="Employee Name"
            name="employee"
            rules={[
              {
                required: true,
                message: "Please enter your Employee Name!",
              },
            ]}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
            >
              {employeeList.map((option, index) => (
                <Select.Option key={index} value={option.employement_number}>
                  {option.employee_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Leave Type"
            name="leave_type"
            rules={[
              {
                required: true,
                message: "Please enter your Leave Type!",
              },
            ]}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
            >
              {compOffLeaveTypeList.map((option, index) => (
                <Select.Option key={index} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Number Of Comp Off"
            name="leaves_number"
            rules={[
              {
                required: true,
                message: "Please enter your Number Of Comp Off!",
              },
            ]}
          >
            <Input
              size="large"
            />
          </Form.Item>
          <Button
            htmlType="submit"
            style={{
              width: "100%",
              color: "white",
              background: "#008080",
              height: "44px",
              marginTop: "40px",
            }}
          >
            Submit
          </Button>
        </Form>
      </Modal>

      {/* add holiday in list */}
      <Modal
        open={openHolidayModal}
        onOk={() => { }}
        onCancel={() => {
          setOpenHolidayModal(false);
          form.resetFields();
        }}
        footer={null}
        centered
        width={540}
      >
        <p className="leavePolicyModelHeading">Holiday</p>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishHoliday}
          onValuesChange={onValuesChange}
        >
          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }}>
              <Form.Item
                label="Year"
                name="year"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Year!",
                  },
                ]}
              >
                <Select
                  size="large"
                  onChange={(e) =>
                    setLeavePolicyForm({ ...leavePolicyForm, start_time: e })
                  }
                >
                  <Select.Option value={new Date().getFullYear()}>
                    {new Date().getFullYear()}
                  </Select.Option>
                  <Select.Option value={new Date().getFullYear() + 1}>
                    {new Date().getFullYear() + 1}
                  </Select.Option>
                  <Select.Option value={new Date().getFullYear() + 2}>
                    {new Date().getFullYear() + 2}
                  </Select.Option>
                  <Select.Option value={new Date().getFullYear() + 3}>
                    {new Date().getFullYear() + 3}
                  </Select.Option>
                  <Select.Option value={new Date().getFullYear() + 4}>
                    {new Date().getFullYear() + 4}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Col span={24}>
            <Form.Item
              label="Leave Policy"
              name="leave_policy"
              rules={[
                {
                  required: true,
                  message: "Please enter your Leave Policy!",
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                value={selectedOptions}
                name="leave_policy"
                onSelect={handleSelectAll}
              >
                <Select.Option value="all">All</Select.Option>
                {holidayLeavePolicyList.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <p>Upload File</p>
            <Form.Item
              label="Upload File"
              name="end_time"
              rules={[
                {
                  required: true,
                  message: "Please enter your Date (To)!",
                },
              ]}
            >
              <div className="file-upload-container">
                <input
                  type="file"
                  id="file-upload"
                  className="input-file"
                  onChange={(e) => {
                    const selectedFile = e.target.files[0];
                    const maxSizeInBytes = 5 * 1024 * 1024;

                    if (selectedFile && selectedFile.type === "text/csv") {
                      if (selectedFile.size <= maxSizeInBytes) {
                        setHolidayListFile(e.target.files && selectedFile);
                      } else {
                        setHolidayListFile(null);
                        alert("File size exceeds the limit of 5MB.");
                      }
                    } else {
                      // Reset the file state and show an error message
                      setHolidayListFile(null);
                      alert("Please upload a CSV file.");
                    }
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label
                    htmlFor="file-upload"
                    className="file-upload-label"
                  ></label>
                  {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                  <p style={{ zIndex: 1, margin: "0px" }}>
                    {holidayListFile ? holidayListFile.name : "Choose a file"}
                  </p>
                </div>
              </div>
            </Form.Item>
          </Col>

          <Button
            htmlType="submit"
            style={{
              width: "100%",
              color: "white",
              background: "#008080",
              height: "44px",
              marginTop: "40px",
            }}
          >
            Submit
          </Button>
        </Form>
      </Modal>

      {/* edit holiday */}
      <Modal
        title="Edit Holiday"
        centered
        open={openHolidayEditModal}
        onOk={() => setOpenHolidayEditModal(false)}
        onCancel={() => {
          setOpenHolidayEditModal(false)
          setHolidayListFile(null)
        }}
        footer={null}
      >
        <Form
          layout="vertical"
          // initialValues={policies}
          onFinish={editHoliday}
        >
          <Row>
            <Col span={24}>
              <p>Upload File</p>
              <Form.Item
                label="Upload File"
                name="holiday_file"
              >
                <div className="file-upload-container">
                  <input
                    type="file"
                    id="file-upload"
                    className="input-file"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      const maxSizeInBytes = 5 * 1024 * 1024;

                      if (selectedFile && selectedFile.type === "text/csv") {
                        if (selectedFile.size <= maxSizeInBytes) {
                          setHolidayListFile(e.target.files && selectedFile);
                        } else {
                          setHolidayListFile(null);
                          alert("File size exceeds the limit of 5MB.");
                        }
                      } else {
                        // Reset the file state and show an error message
                        setHolidayListFile(null);
                        alert("Please upload a CSV file.");
                      }
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label
                      htmlFor="file-upload"
                      className="file-upload-label"
                    ></label>
                    {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                    <p style={{ zIndex: 1, margin: "0px" }}>
                      {holidayListFile ? holidayListFile.name : "Choose a file"}
                    </p>
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Button htmlType="submit" style={{ background: "#008080", color: "white", marginTop: "40px", height: "40px" }}>Update</Button>
        </Form>
      </Modal>

      {/* Change Leave Policy */}
      <Modal
        title="Change Leave Policy"
        centered
        open={changeLeavePolicyModel}
        onOk={() => setChangeLeavePolicyModel(false)}
        onCancel={() => setChangeLeavePolicyModel(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          // initialValues={policies}
          onFinish={updateEmployeeLeavePolicy}
          fields={[
            {
              name: "leave_policy",
              value: selectedEmployeeLeavePolicyManager.policy_id
            }
          ]}
        >
          <Form.Item
            label="Leave Policy"
            name="leave_policy"
            rules={[
              {
                required: true,
                message: 'Please Select Leave Policy!',
              },
            ]}
          >
            <Select
              size='large'
            >
              {
                leavePolicy.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                  )
                }
                )
              }
            </Select>

          </Form.Item>
          <Button htmlType="submit" style={{ background: "#008080", color: "white" }}>Update</Button>
        </Form>
      </Modal>

      {/* download leave report modal */}
      <Modal
        title="Download Report"
        centered
        open={downloadModal}
        // onOk={() => setReviseSalary(false)}
        onCancel={() => setDownloadModal(false)}
        width={800}
        footer={null}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
          <Select
            style={{ width: "100%", marginTop: "20px" }}
            placeholder="Select an option"
            onChange={handleDaysChange}
            defaultValue={leaveReportDays}
            size='large'
          >
            <Select.Option value={7}>Last 7 days</Select.Option>
            <Select.Option value={14}>Last 14 days</Select.Option>
            <Select.Option value={30}>Last 30 days</Select.Option>
            <Select.Option value="custom">
              <span
                onClick={() => {
                  setOpen(true);
                }}
              >
                Custom Date
              </span>
            </Select.Option>
          </Select>

          <Modal
            title="Select Date Range"
            centered
            open={open}
            onCancel={() => setOpen(false)}
            onOk={async () => {

              setOpen(false);
            }}
          >
            <RangePicker
              value={dateRange}
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              style={{ color: "black !important" }}
            />
          </Modal>


        </div>

        <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }}
          onClick={() => downloadReport()}
        >
          Submit
        </Button>

      </Modal>
    </div>
  );
}

export default Dashboard;
