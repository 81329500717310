import React, { useEffect, useRef, useState } from 'react';
import styles from "./Dashboard.module.scss";

import attendancePolicy from "../../../assets/attendancePolicy.png";
import leavePolicy from "../../../assets/leavePolicy.png";
import salaryManager from "../../../assets/Dashboard/Admin/salaryManager.png";
import runPayroll from "../../../assets/runPayroll.png";
import date from "../../../assets/Attendance/Admin/date.svg";
import leave from "../../../assets/Attendance/Admin/leave.svg"
import duration from "../../../assets/Attendance/Admin/duration.svg"
import total from "../../../assets/Attendance/Admin/total.svg"
import absent from "../../../assets/Attendance/Admin/absent.svg"
import Early from "../../../assets/Attendance/Admin/Early.svg"
import onTime from "../../../assets/Attendance/Admin/onTime.svg"
import lateArrival from "../../../assets/Attendance/Admin/lateArrival.svg"
import timeOff from "../../../assets/Attendance/Admin/timeOff.svg"
import fullView from "../../../assets/fullView.svg"
import edit from "../../../assets/edit.svg"
import share from "../../../assets/share.svg";
import dateImg from "../../../assets/Dashboard/Admin/date.png";
import timeduration from "../../../assets/Dashboard/Admin/duration.png";
import location from "../../../assets/location.svg"
import download from "../../../assets/download.png"
import add from "../../../assets/Org/add.svg"
import calendar from "../../../assets/calendar.svg";
import workMode from "../../../assets/workMode.png";
import Reimbursement from "../../../assets/Dashboard/Admin/Reimbursement.png";





import { Button, Col, DatePicker, Form, Input, Modal, Popover, Radio, Row, Select, Switch, Table } from 'antd';
import request from '../../../utils/axios';
import AttendanceReportSearchBar from '../../Attendance/Admin/AttendanceReportSearchBar/AttendanceReportSearchBar';
import { DownloadOutlined } from '@ant-design/icons';
import AttendanceCalendarReportSearchBar from '../../Attendance/Admin/AttendanceCalendarReportSearchBar/AttendanceCalendarReportSearchBar';
import CalendarScheduler from '../../../component/CalendarScheduler/CalendarScheduler';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ApplyJob from '../../Recruitment/User/ApplyJob/ApplyJob';
import arrowCircle from "../../../assets/fullView.svg";
import getScreenSize from '../../../utils/getScreenSize';
import { toast } from 'react-toastify';

function Dashboard() {
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const role_list = localStorage.getItem("role_list");
  const screenSize = getScreenSize();

  const [downloadModal, setDownloadModal] = useState(false)


  const quickLinks = [
    {
      label: "Attendance Policy",
      image: attendancePolicy,
      key: 1,
      model: "attendance",
      path: "/admin/attendance/dashboard/attendance-policy"
    },
    {
      label: "Leave Policy",
      image: leavePolicy,
      key: 2,
      model: "leave",
      path: "/admin/leave/leave-policy"
    },
    // {
    //   label: "Salary Manager",
    //   image: salaryManager,
    //   key: 3,
    //   model: "payroll",
    //   path: "/admin/payroll/employee-salary-manager"
    // },
    {
      label: "Run Payroll",
      image: runPayroll,
      key: 4,
      model: "payroll",
      path: "/admin/payroll/processing"
    },
    {
      label: "Reimbursement",
      image: Reimbursement,
      key: 5,
      model: "payroll",
      path: "/admin/payroll/9"
    }
  ]

  const attendanceReportColumns = [
    {
      title: 'Id',
      dataIndex: 'employee_id',
      key: 'employee_id',
    },
    {
      title: ' Date',
      dataIndex: 'attendance_date',
      key: 'attendance_date',
    },
    {
      title: 'Employee',
      dataIndex: 'employee_name',
      key: 'employee_name',
    },
    {
      title: 'Role/Department',
      dataIndex: 'role',
      key: 'role',
      render: (text, record) => <>
        {
          record.designation + " / " + record.department
        }
      </>
    },
    {
      title: ' Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <>
        {
          text === "Present" && <span style={{ backgroundColor: "#008080", padding: "5px 10px", color: "white", borderRadius: "5px" }}>{text}</span>
        }
        {
          text === "Absent" && <span style={{ backgroundColor: "#F9A1B5", padding: "5px 10px", color: "white", borderRadius: "5px" }}>{text}</span>
        }
        {
          text === "On Leave" && <span style={{ backgroundColor: record.color, padding: "5px 10px", color: "white", borderRadius: "5px" }}>{record.leave_type}</span>
        }
        {
          text === "Weekly Off" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "5px" }}>{text}</span>
        }
        {
          text === "Yet to checkin" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "5px" }}>Pending</span>
        }
        {
          text === "Holiday" && <span style={{ backgroundColor: "#33b249", color: "white", padding: "5px 10px", borderRadius: "5px" }}>{record.holiday_name}</span>
        }
        {
          text === "Late ClockIn" && <span style={{ backgroundColor: "#f53444", padding: "5px 10px", color: "white", borderRadius: "5px" }}>{text}</span>
        }
        {
          text === "Early Departure" && <span style={{ backgroundColor: "rgb(0, 96, 255)", padding: "5px 10px", color: "rgb(233, 241, 255)", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>Short Duration</span>
        }

      </>
    },
    {
      title: 'Check-in',
      dataIndex: 'check_in_time',
      key: 'check_in_time',
    },
    {
      title: 'Check-out',
      dataIndex: 'check_out_time',
      key: 'check_out_time',
      render: (text, record) => <span>
        {
          text === null ? "Pending..." : text
        }
      </span>
    },
    {
      title: 'Work hours',
      dataIndex: 'working_duration',
      key: 'working_duration',
    }
  ]

  const requisitionColumns = [
    {
      title: 'Job Ttile',
      dataIndex: 'job_title',
      key: 'job_title',
    },
    {
      title: 'Raised By',
      dataIndex: 'raised_by',
      key: 'raised_by',
    },
    {
      title: 'Salary Range',
      dataIndex: 'salary_range',
      key: 'salary_range',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record) =>
        <div>
          {
            record.location.map((item, index) => {
              return (
                <span key={index}>
                  <span>{item}</span>
                  {
                    record?.location?.length - 1 !== index && <span> , </span>
                  }
                </span>

              )
            })
          }
        </div>
    },
    {
      title: 'Function',
      dataIndex: 'job_function',
      key: 'job_function',
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
  ]

  const latestJobsColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => <span>{moment(record.date).format("DD-MM-YYYY")}</span>
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title',
    },
    {
      title: 'Posted By',
      dataIndex: 'posted_by',
      key: 'posted_by',
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: 'Funtion',
      dataIndex: 'job_function',
      key: 'job_function',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => <div>
        <img src={edit} alt="" style={{ marginRight: "20px", cursor: "pointer" }}
          onClick={() => navigate(`/admin/recruitment/post-job/${record.id}`, { replace: true })}
        />
        <img src={share} alt="" style={{ cursor: "pointer" }}
          onClick={() => navigate(`/admin/recruitment/details/${record.id}`, { replace: true })}
        />
      </div>
    }
  ]

  const [adminWidget, setAdminWidget] = useState({
    total_count: "0",
    on_time: "0",
    on_late: "0",
    absent_count: "0",
  })

  const EmployeeSalaryColumn = [
    {
      title: 'Employee Name',
      dataIndex: 'employee_name',
      key: 'employee_name',
    },
    {
      title: 'Employee Code',
      dataIndex: 'employement_number',
      key: 'employement_number',
    },
    {
      title: 'Salary Structure',
      dataIndex: 'structure_name',
      key: 'structure_name',
    },
    {
      title: 'CTC',
      dataIndex: 'ctc',
      key: 'ctc',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) =>
        <button
          onClick={() => {
            setselectedEmployeeSalaryId({
              ...selectedEmployeeSalary,
              id: record.employement_number,
              name: record.employee_name
            })
            setReviseSalary(true)

          }}
          style={{
            background: "#F2F2F2",
            border: "none",
            color: "#8A8A8A",
            padding: "2px 10px"
          }}
        >
          Revise Salary
        </button>
    },
  ]
  const [calendarView, setCalendarView] = useState(false);
  const [attendanceReport, setAttendanceReport] = useState([]);
  const [attendanceReportDays, setAttendanceReportDays] = useState(7);
  const [open, setOpen] = useState(false)
  const [dateRange, setDateRange] = useState([]);
  const [attendanceReportCustomDate, setAttendanceReportCustomDate] = useState({
    start_date: "",
    end_date: ""
  });
  const [calendarData, setCalendarData] = useState([])
  const [employeeId, setEmployeeId] = useState('');
  const [employeeList, setEmployeeList] = useState([]);
  const [calendarMonth, setCalendarMonth] = useState('');
  const [calendarYear, setCalendarYear] = useState('');
  const [requisitionList, setRequisitionList] = useState([])
  const [latestJobList, setLatestJobList] = useState([])
  const [selectedEmployeeSalary, setselectedEmployeeSalaryId] = useState({
    id: "",
    name: "",
    appraisal_type: "",
    component_choice: "",
    amount: ""

  })
  const [reviseSalary, setReviseSalary] = useState(false)

  const fetchAdminWidget = async () => {
    try {
      const data = await request({
        method: "get",
        url: `attendance/admin/widget/`,
      })
      setAdminWidget({ ...data })
    } catch (error) {
      console.log(error)
    }
  }

  const handleSwitchChange = (checked) => {
    setCalendarView(checked);
  }

  const fetchAttendanceReport = async () => {
    try {
      const data = await request({
        method: "get",
        url: `attendance/admin/employees/attendance/details/?page=1&page_size=4`,
      })
      setAttendanceReport(data?.pagination_data.data)
      // return data
    } catch (error) {
      console.log(error)
    }
  }

  const handleDaysChange = (value) => {
    console.log("value", value);
    if (value === "custom") {
      // setShowDatePicker(true)
      setAttendanceReportDays(value);
      setOpen(true);
    } else {
      // setShowDatePicker(false)
      setAttendanceReportDays(value);
    }
  };

  const handleDateChange = async (value) => {
    const start_date = value[0]?.format("YYYY-MM-DD");
    const end_date = value[1]?.format("YYYY-MM-DD");
    setAttendanceReportCustomDate({ ...attendanceReportCustomDate, start_date, end_date })
  };

  const getPopupContainer = (triggerNode) => {
    return triggerNode.parentNode;
  };

  const downloadReport = async () => {
    if (attendanceReportDays === "custom") {
      const response = await request({
        method: "get",
        url: `attendance/admin/attendance-report-by-date/download?start_date=${attendanceReportCustomDate.start_date}&end_date=${attendanceReportCustomDate.end_date}`,
        responseType: "arraybuffer",
      });
      const file = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = "file.pdf";
      link.click();
    }
    else {
      const response = await request({
        method: "get",
        url: `attendance/admin/attendance-report-by-date/download?days=${attendanceReportDays}`,
        responseType: "arraybuffer",
      });
      const file = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = "file.pdf";
      link.click();
    }

  };

  const handleSelectChange = (value) => {
    setEmployeeId(value)
  }

  const fetchEmployeeList = async () => {
    const data = await request({
      method: "get",
      url: `get-all-employees`,
    })

    setEmployeeList(data.data)
    // setLoading(false)
  }

  const fetchRequisitionList = async () => {
    try {
      const data = await request({
        method: "get",
        url: `recruitment/requisitions/all?page=1&page_size=5`,
      })
      setRequisitionList(data?.pagination_data.data)

    }
    catch (err) {
      console.log(err)
    }
  }


  const fetchLatestJobsList = async (page, page_size) => {
    try {
      const data = await request({
        method: "get",
        url: `recruitment/all-jobs?page=1&page_size=5`,
      })
      setLatestJobList(data?.pagination_data.data)

    }
    catch (err) {
      console.log(err)
    }
  }

  const [employeeSalaryManagerData, setEmployeeSalaryManagerComponentData] = useState([])
  const fetchEmployeeSalaryManagerList = async () => {
    const data = await request({
      method: "get",
      url: `payroll/admin/get-all-employees/?page=1&page_size=5`,
    })
    console.log(data)
    setEmployeeSalaryManagerComponentData(data?.pagination_data.data)

  }

  useEffect(() => {
    fetchAdminWidget();
    fetchAttendanceReport();
    fetchEmployeeList();
    fetchRequisitionList();
    fetchLatestJobsList();
    fetchEmployeeSalaryManagerList();
  }, [])

  const fetchCalendarData = async () => {
    try {
      const data = await request({
        method: "get",
        url: `attendance/admin/employee/calender/monthly/?employement_number=${employeeId}&month=${calendarMonth}&year=${calendarYear}`,
      })
      setCalendarData(data.data.monthly_attendance)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (employeeId) {
      fetchCalendarData();
    }
  }, [employeeId, calendarMonth, calendarYear])



  // const onFinish = async (values) => {
  //   try {
  //     await request({
  //       method: "post",
  //       url: `payroll/admin/revised-employee-salary/?id=${selectedEmployeeSalary.id}`,
  //       data: values

  //     })
  //   }
  //   catch (error) {
  //     console.log(error)
  //   }
  // }
  const [revisedEmployeeData, setrevisedEmployeeData] = useState([])
  const onFinish = async (values) => {
    try {
      console.log("values : ", values)
      const data = await request({
        method: "post",
        url: `payroll/admin/revised-employee-salary/?id=${selectedEmployeeSalary.id}`,
        data: values

      })

      setReviseSalary(false)
      toast.success("Salary revised successfully", {
        position: "bottom-left",
      })

    } catch (err) {
      console.log(err)
      setReviseSalary(false)
      toast.error("Salary could not be updated, please try after some time", {
        position: "bottom-left",
      })
    }
    // console.log("data : ", data)
    // setrevisedEmployeeData([...data])
  }

  const ref = useRef(null);
  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const attendanceReportDownloadPopover = (
    <>
      <Select
        style={{ width: 300, height: 40, marginBottom: "15px" }}
        placeholder="Select an option"
        onChange={handleDaysChange}
        dafaultValue={attendanceReportDays}
      >
        <Select.Option value={7}>Last 7 days</Select.Option>
        <Select.Option value={14}>Last 14 days</Select.Option>
        <Select.Option value={30}>Last 30 days</Select.Option>
        <Select.Option value="custom">
          <Popover
            title=""
            content={
              <div className="modal-content">
                <RangePicker
                  value={dateRange}
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                  disabledDate={(current) =>
                    current && current > moment().endOf("day")
                  }
                />
              </div>
            }
            getPopupContainer={getPopupContainer}
            placement="right"
          >
            <p
              style={{
                cursor: "pointer",
                width: "90%",
                margin: "0px",
              }}
            >
              Custom Date
            </p>
          </Popover>
        </Select.Option>
      </Select>
      <br />
      <button
        style={{
          border: "none",
          color: "white",
          background: "#008080",
          borderRadius: "4px",
          height: "36px",
          padding: "0px 10px"
        }}
        onClick={() => downloadReport()}
      >
        Download
      </button>
    </>
  )

  return (
    <div className={styles.adminDashBoard} ref={ref}>
      {
        screenSize.width < 768
          // mobile view
          ?
          <div className={styles.container}>



            {/* <div className={styles.quickLinks}>

              {

                quickLinks.map((link, index) => (
                  <div key={index} onClick={() => navigate(link.path)}>
                    <center>
                      <img src={link.image} alt={link.label} style={{ height: "80px", width: "80px" }} />
                      <p className={styles.label}>{link.label}</p>
                    </center>
                  </div>
                ))

              }
            </div> */}

            <Row style={{ marginTop: "80px", padding: "0px 16px" }}>
              {

                quickLinks.map((link, index) => (
                  <Col span={6} key={index} onClick={() => navigate(link.path)}>
                    <center>
                      {/* <div className={styles.quickLink}> */}
                      <img src={link.image} alt={link.label} style={{ height: "52px", width: "52px" }} />
                      <p style={{ fontSize: "12px", color: "#161616" }}>{link.label}</p>
                      {/* </div> */}
                    </center>
                  </Col>
                ))

              }
            </Row>





            <div className={styles.divider}></div>
            {
              role_list?.includes("attendance")
              &&
              <div className={styles.attendanceSummary}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                  <p className={styles.heading} style={{ margin: "0px" }}>Attendance Summary</p>
                  <img
                    onClick={() => navigate("/admin/attendance/dashboard", { replace: true })}
                    src={arrowCircle}
                    alt="arrow"
                    className="employeeArrowImage"
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div className={styles.card}>
                      <div className='flexSpaceBetween'>
                        <p className={styles.data}>{adminWidget.total_count}</p>
                        <img src={total} alt="" className='cardImage' />
                      </div>
                      <p className={styles.title}>Total Employees</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.card}>
                      <div className='flexSpaceBetween'>
                        <p className={styles.data}>{adminWidget.on_time}</p>
                        <img src={onTime} alt="" className='cardImage' />
                      </div>
                      <p className={styles.title}>On Time</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.card}>
                      <div className='flexSpaceBetween'>
                        <p className={styles.data}>{adminWidget.absent_count}</p>
                        <img src={absent} alt="" className='cardImage' />
                      </div>
                      <p className={styles.title}>Absent</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.card}>
                      <div className='flexSpaceBetween'>
                        <p className={styles.data}>{adminWidget.on_late}</p>
                        <img src={lateArrival} alt="" className='cardImage' />
                      </div>
                      <p className={styles.title}>Late Arrival</p>
                    </div>
                  </Col>

                  <Col span={12}>
                    <div className={styles.card}>
                      <div className='flexSpaceBetween'>
                        <p className={styles.data}>{adminWidget.early_departures}</p>
                        <img src={Early} alt="" className='cardImage' />
                      </div>
                      <p className={styles.title}>Early Departures</p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles.card}>
                      <div className='flexSpaceBetween'>
                        <p className={styles.data}>{adminWidget.on_holiday}</p>
                        <img src={timeOff} alt="" className='cardImage' />
                      </div>
                      <p className={styles.title}>On-Holiday</p>
                    </div>
                  </Col>
                </Row>
              </div>
            }

            <div className={styles.divider}></div>
            {
              role_list?.includes("attendance")
              &&
              <div className={styles.attendanceReport}>

                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                  <p className={styles.heading} style={{ margin: "0px" }}>Attendance Report</p>
                  <img src={download} alt="download" className="employeeArrowImage"
                    onClick={() => {
                      setDownloadModal(true);
                    }}
                  />
                  <img
                    onClick={() => navigate("/admin/attendance/dashboard/3")}
                    src={arrowCircle}
                    alt="arrow"
                    className="employeeArrowImage"
                    style={{ cursor: "pointer", marginRight: "16px" }}
                  />
                </div>
                {/* <p className={styles.heading}>Attendance Report</p> */}
                <div style={{ display: "flex", marginBottom: "10px", padding: "0 16px" }}>
                  {/* <Select
                    style={{ width: 150, height: 30, marginTop: "20px" }}
                    placeholder="Select an option"
                    onChange={handleDaysChange}
                    defaultValue={attendanceReportDays}
                  >
                    <Select.Option value={7}>Last 7 days</Select.Option>
                    <Select.Option value={14}>Last 14 days</Select.Option>
                    <Select.Option value={30}>Last 30 days</Select.Option>
                    <Select.Option value="custom">
                      <span
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        Custom Date
                      </span>
                    </Select.Option>
                  </Select> */}
                </div>
                <Modal
                  title="Select Date Range"
                  centered
                  open={open}
                  onCancel={() => setOpen(false)}
                  onOk={async () => {
                    setOpen(false);
                  }}
                >
                  <RangePicker
                    value={dateRange}
                    onChange={handleDateChange}
                    format="YYYY-MM-DD"
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                    style={{ color: "black !important" }}
                  />
                </Modal>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px", marginBottom: "25px" }}>
                  <div>
                    <Switch
                      checked={calendarView}
                      onChange={handleSwitchChange}
                      size='small'
                    />
                    <span style={{ marginLeft: "10px", fontSize: "12px", color: "#616161" }}>
                      {
                        calendarView ? "Calendar View" : "Calendar View"
                      }
                    </span>
                  </div>

                  {/* <DownloadOutlined style={{ color: "#616161" }}
                    onClick={() => downloadReport()}
                  /> */}

                </div>
                {
                  calendarView
                  &&
                  <div style={{ display: "flex", alignItems: "center", gap: "30px", padding: "0px 16px" }}>
                    <AttendanceCalendarReportSearchBar
                      setTableDataForSearchEmployee={setCalendarData}
                      fetchTableDataFromApi={null}
                      placeholderText="Search for employees"
                      empType="admin"
                      setEmployeeId={setEmployeeId}
                    />
                    {/* <Select
                    className='dropDown'
                    placeholder="Select a person"
                    onChange={handleSelectChange}
                  >
                    {
                      employeeList?.map((item, index) => {
                        return (
                          <Select.Option key={index} value={item.employement_number}>{item.employee_name}</Select.Option>
                        )
                      }
                      )
                    }
                  </Select> */}
                  </div>
                }
                <div>
                  {
                    calendarView
                      ?
                      <div style={{ padding: "0px 16px" }}>
                        <CalendarScheduler
                          data={calendarData}
                          setCalendarMonth={setCalendarMonth}
                          setCalendarYear={setCalendarYear}
                          employeeId={employeeId}
                        />
                      </div>
                      :
                      <>
                        {
                          attendanceReport.map((report, index) => {
                            return (
                              <div>
                                <div key={index} className={styles.card}>
                                  <div className={styles.header}>
                                    <p className={styles.name}>{report.employee_name}</p>
                                    {/* <p className={styles.workingPolicy}>{report.working_policy}</p> */}
                                    <>
                                    {
                                        report.status === "Present" && <span style={{ backgroundColor: "#008080", padding: "5px 10px", color: "white", borderRadius: "30px", fontSize: "12px" }}>{report.status}</span>
                                    }
                                    {
                                        report.status === "Absent" && <span style={{ backgroundColor: "#F9A1B5", padding: "5px 10px", color: "white", borderRadius: "30px", fontSize: "12px" }}>{report.status}</span>
                                    }
                                    {
                                        report.status === "On Leave" && <span style={{ backgroundColor: report.color, padding: "5px 10px", color: "white", borderRadius: "30px", fontSize: "12px" }}>{report.leave_type}</span>
                                    }
                                    {
                                        report.status === "Weekly Off" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>{report.status}</span>
                                    }
                                    {
                                        report.status === "Yet to checkin" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>Pending</span>
                                    }
                                    {
                                        report.status === "Holiday" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>{report.holiday_name}</span>
                                    }
                                    {
                                        report.status === "Late ClockIn" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>{report.status}</span>
                                    }
                                    {
                                        report.status === "Early Departure" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "30px", fontSize: "12px" }}>Short Duration</span>
                                    }
                                </>
                                  </div>
                                  <div>
                                    <p className={styles.shift}>Shift  <span className={styles.shiftTiming}>{report.shift_timing}</span></p>
                                    <p className={styles.department}>{report.department}</p>
                                    {
                                      !(report.status === "Absent" || report.status === "Weekly Off" || report.status === "On Leave")
                                      &&
                                      <>
                                        <p className={styles.date}>
                                          <img src={dateImg} alt="" style={{ marginRight: "5px" }} />
                                          {report.attendance_date}
                                        </p>
                                        <p className={styles.date}> <img src={dateImg} alt="" style={{ marginRight: "5px" }} /><span className={styles.shiftTiming}>{report.check_in_time} - {report.check_out_time}</span></p>

                                        <p className={styles.workingDuration}>
                                          <img src={timeduration} alt="" style={{ marginRight: "5px" }} />
                                          {report.working_duration}
                                        </p>
                                      </>
                                    }

                                  </div>
                                </div>
                                {
                                  index < attendanceReport.length - 1
                                  &&
                                  <hr style={{
                                    height: "1px",
                                    backgroundColor: "#F0F0F0",
                                    border: "none",
                                  }} />
                                  // <hr className={styles.line} />
                                  // <hr style={{ border: "1px solid #DEDEDE" }} />

                                }
                              </div>
                            )
                          })
                        }
                      </>
                  }
                </div>
              </div>
            }

            <div className={styles.divider}></div>
            {
              role_list?.includes("recruitment")
              &&

              <div className={styles.requisitionContainer}>
                {/* <p className={styles.heading}>Requisition</p> */}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                  <p className={styles.heading} style={{ margin: "0px" }}>Requisition</p>
                  <img
                    onClick={() => navigate("/admin/recruitment", { replace: true })}
                    src={arrowCircle}
                    alt="arrow"
                    className="employeeArrowImage"
                    style={{ cursor: "pointer", marginRight: "16px" }}
                  />
                </div>
                <div>
                  {
                    requisitionList.map((item, index) => {
                      return (
                        <div>
                          <div className={styles.card}>
                            <p className={styles.jobTitle}>{item.job_title}</p>
                            <p className={styles.jobFunction}>{item.job_function}</p>
                            <p className={styles.industry}>{item.industry}</p>
                            <p className={styles.location}>
                              <img src={location} alt="" style={{ marginRight: "5px" }} />
                              {item.location.map((name, i) => {
                                return (
                                  <span>
                                    {name}
                                    {i < item.location.length - 1 ? ", " : ""}
                                  </span>
                                )
                              })}</p>
                          </div>
                          {
                            index < requisitionList.length - 1
                            &&
                            <hr style={{
                              height: "1px",
                              backgroundColor: "#F0F0F0",
                              border: "none",
                            }} />
                            // <hr className={styles.line} />
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            }


            <div className={styles.divider}></div>

            {
              role_list?.includes("recruitment")
              &&
              <div className={styles.latestJobsContainer}>
                {/* <p className={styles.heading}>Latest Jobs</p> */}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                  <p className={styles.heading} style={{ margin: "0px" }}>Latest Jobs </p>
                  <div style={{ width: "25%", display: "flex", justifyContent: "space-between", marginRight: "10px", flexDirection: "row" }}>
                    <img src={add} alt="add"
                      onClick={() => navigate("/admin/recruitment/post-job", { replace: true })}
                      className="employeeArrowImage"
                    />
                    <img
                      onClick={() => navigate("/admin/recruitment", { replace: true })}
                      src={arrowCircle}
                      alt="arrow"
                      className="employeeArrowImage"
                      style={{ cursor: "pointer", }}
                    />
                  </div>
                </div>

                <div>
                  {
                    latestJobList.map((item, index) => {
                      return (
                        <div>
                          <div className={styles.card}>
                            <div className={styles.header}>
                              <p className={styles.jobTitle}>{item.job_title}</p>
                              {/* <img src={edit} alt="" /> */}
                              <button
                                style={{ background: "#008080", color: "white", border: "none", borderRadius: "5px", padding: "5px 10px" }}
                                onClick={() => navigate(`/admin/recruitment/post-job/${item.id}`, { replace: true })}
                              >Edit</button>
                            </div>
                            <p className={styles.companyName}>{item.industry}</p>
                            {/* <p className={styles.date}>{moment(item.posting_date).format("DD MMM YYYY")}</p> */}
                            <p className={styles.date}>
                              <img src={calendar} alt="calendar" width={20} height={20} />{" "}
                              {moment(item.posting_date).format("DD MMM YYYY")}
                            </p>
                            <p style={{ fontSize: "12px", color: "#8A8A8A" }}>
                              <img src={workMode} alt="workMode" width={20} height={20} />{" "}
                              {item?.job_function}
                            </p>
                            <p className={styles.location}>
                              <img src={location} alt="" style={{ marginRight: "5px" }} />
                              {item.location}
                            </p>
                          </div>
                          {
                            index < latestJobList.length - 1
                            &&
                            <hr style={{
                              height: "1px",
                              backgroundColor: "#F0F0F0",
                              border: "none",
                            }} />
                            // <hr className={styles.line} />
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            }


            <div className={styles.divider}></div>
            {
              role_list?.includes("payroll")
              &&
              <div className={styles.employeeSalaryContainer}>
                {/* <p className={styles.heading}>Employee Salary Manager</p> */}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                  <p className={styles.heading} style={{ margin: "0px" }}>Employee Salary Manager</p>
                  <img
                    onClick={() => navigate("/admin/payroll/4")}
                    src={arrowCircle}
                    alt="arrow"
                    className="employeeArrowImage"
                    style={{ cursor: "pointer", marginRight: "16px" }}
                  />
                </div>
                <div>
                  {
                    employeeSalaryManagerData.map((item, index) => {
                      return (
                        <div>
                          <div className={styles.card}>
                            <div className={styles.header}>
                              <p className={styles.name}>{item.employee_name}</p>
                              <button
                                onClick={() => {
                                  setselectedEmployeeSalaryId({
                                    ...selectedEmployeeSalary,
                                    id: item.employement_number,
                                    name: item.employee_name
                                  })
                                  setReviseSalary(true)

                                }}
                                style={{
                                  background: "#F2F2F2",
                                  border: "none",
                                  color: "#8A8A8A",
                                  padding: "5px 12px",
                                  fontSize: "16px",
                                  borderRadius: "4px"
                                }}
                              >
                                Revise Salary
                              </button>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <p className={styles.title}>Employee Code</p>
                              <p className={styles.value}>{item.employement_number}</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <p className={styles.title}>Salary Structure</p>
                              <p className={styles.value}>{item.structure_name}</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <p className={styles.title}>CTC</p>
                              <p className={styles.value}>₹{item.ctc}</p>
                            </div>
                          </div>
                          {
                            index < employeeSalaryManagerData.length - 1
                            &&
                            // <hr className={styles.line} />
                            <hr style={{
                              height: "1px",
                              backgroundColor: "#F0F0F0",
                              border: "none",
                            }} />
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            }


            <div style={{ height: "100px" }}>
            </div>
          </div>
          :
          //   desktop view
          <div className={styles.container}>
            {/* <p className={styles.heading}>Dashboard</p> */}

            <Row gutter={[32, 32]}>
              <Col span={8}>
                <div className={styles.quickLinks}>
                  <Row gutter={[32, 32]}>
                    {
                      quickLinks.map((link, index) => {
                        return (
                          role_list?.includes(link.model)
                          &&
                          <Col span={12}>
                            <div key={index} onClick={() => navigate(link.path)}>
                              <center>
                                <div className={styles.quickLink}>

                                  <img src={link.image} alt={link.label} style={{ height: "80px", width: "80px" }} />
                                  <p className={styles.label}>{link.label}</p>
                                </div>
                              </center>
                            </div>
                          </Col>
                        )
                      })
                    }
                  </Row>
                </div>
              </Col>
              <Col span={16}>
                <div>
                  {
                    role_list?.includes("attendance")
                    &&
                    <div className={styles.attendanceSummary}>
                      {/* <p className={styles.heading}>Attendance Summary</p> */}
                      <Row gutter={[32, 32]}>
                        <Col span={8}>
                          <div className={styles.card}>
                            <div className='flexSpaceBetween'>
                              <p className={styles.data}>{adminWidget.total_count}</p>
                              <img src={total} alt="" className='cardImage' />
                            </div>
                            <p className={styles.title}>Total Employees</p>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className={styles.card}>
                            <div className='flexSpaceBetween'>
                              <p className={styles.data}>{adminWidget.on_time}</p>
                              <img src={onTime} alt="" className='cardImage' />
                            </div>
                            <p className={styles.title}>On Time</p>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className={styles.card}>
                            <div className='flexSpaceBetween'>
                              <p className={styles.data}>{adminWidget.absent_count}</p>
                              <img src={absent} alt="" className='cardImage' />
                            </div>
                            <p className={styles.title}>Absent</p>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className={styles.card}>
                            <div className='flexSpaceBetween'>
                              <p className={styles.data}>{adminWidget.on_late}</p>
                              <img src={lateArrival} alt="" className='cardImage' />
                            </div>
                            <p className={styles.title}>Late Arrival</p>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className={styles.card}>
                            <div className='flexSpaceBetween'>
                              <p className={styles.data}>{adminWidget.early_departures}</p>
                              <img src={Early} alt="" className='cardImage' />
                            </div>
                            <p className={styles.title}>Early Departures</p>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className={styles.card}>
                            <div className='flexSpaceBetween'>
                              <p className={styles.data}>{adminWidget.on_holiday}</p>
                              <img src={timeOff} alt="" className='cardImage' />
                            </div>
                            <p className={styles.title}>On-Holiday</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  }
                </div>
              </Col>
            </Row>






            {
              role_list?.includes("attendance")
              &&
              <div style={{ marginTop: "30px", padding: "30px", background: "white" }}>
                <div className='flexSpaceBetween'>
                  <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                    <p
                      style={{ fontSize: "20px", fontWeight: "500" }}
                    >Attendance Report</p>
                    <span>
                      <Switch
                        checked={calendarView}
                        onChange={handleSwitchChange}
                      />
                      {" "}
                      <span style={{ paddingLeft: "10px" }}>
                        {
                          calendarView ? " Calendar View" : "Calendar View"
                        }
                      </span>
                    </span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "30px", marginBottom: "30px" }}>
                    <>
                      {
                        !calendarView
                        &&
                        <AttendanceReportSearchBar
                          setTableDataForSearchEmployee={setAttendanceReport}
                          fetchTableDataFromApi={fetchAttendanceReport}
                          placeholderText="Search for employees"
                          empType="admin"
                        />
                      }
                    </>
                    {/* <Select
                      style={{ width: 300, height: 40 }}
                      placeholder="Select an option"
                      onChange={handleDaysChange}
                      dafaultValue={attendanceReportDays}
                    >
                      <Select.Option value={7}>Last 7 days</Select.Option>
                      <Select.Option value={14}>Last 14 days</Select.Option>
                      <Select.Option value={30}>Last 30 days</Select.Option>
                      <Select.Option value="custom">
                        <Popover
                          title=""
                          content={
                            <div className="modal-content">
                              <RangePicker
                                value={dateRange}
                                onChange={handleDateChange}
                                format="YYYY-MM-DD"
                                disabledDate={(current) =>
                                  current && current > moment().endOf("day")
                                }
                              />
                            </div>
                          }
                          getPopupContainer={getPopupContainer}
                          placement="right"
                        >
                          <p
                            style={{
                              cursor: "pointer",
                              width: "90%",
                              margin: "0px",
                            }}
                          >
                            Custom Date
                          </p>
                        </Popover>
                      </Select.Option>
                    </Select>
                    <DownloadOutlined style={{ color: "#616161", fontSize: "25px" }}
                      onClick={() => downloadReport()}
                    /> */}
                    <Popover placement="bottomRight" trigger="click" title="Download report" content={attendanceReportDownloadPopover} arrow={false}>
                      <img src={download} alt="download" width={35} height={35}
                      />
                    </Popover>
                    <img src={fullView} alt="fullView" width={35} height={35} style={{ marginLeft: "20px" }}
                      onClick={() => {
                        navigate("/admin/attendance/dashboard/attendance-report", { replace: true })
                      }}
                    />
                  </div>


                </div>
                <div>
                  <>
                    {
                      calendarView
                      &&
                      <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
                        <AttendanceCalendarReportSearchBar
                          setTableDataForSearchEmployee={setCalendarData}
                          fetchTableDataFromApi={null}
                          placeholderText="Search for employees"
                          empType="admin"
                          setEmployeeId={setEmployeeId}
                        />
                        {/* <Select
                        style={{ width: 300, height: 40 }}
                        placeholder="Select a person"
                        onChange={handleSelectChange}
                      >
                        {
                          employeeList?.map((item, index) => {
                            return (
                              <Select.Option key={index} value={item.employement_number}>{item.employee_name}</Select.Option>
                            )
                          }
                          )
                        }
                      </Select> */}
                      </div>
                    }
                  </>

                </div>
                {
                  calendarView
                    ?
                    <CalendarScheduler
                      data={calendarData}
                      setCalendarMonth={setCalendarMonth}
                      setCalendarYear={setCalendarYear}
                      employeeId={employeeId}
                    />
                    :
                    <Table
                      columns={attendanceReportColumns}
                      dataSource={attendanceReport}
                      pagination={false}
                    />
                }

              </div>
            }

            {
              role_list?.includes("recruitment")
              &&
              <div className={styles.requisitionContainer}>
                <div className={styles.headerContainer}>
                  <p className={styles.heading}>Requisition</p>
                  <div>
                    {/* <button className={styles.button}
                      onClick={() => { navigate("/admin/recruitment/post-job") }}
                    >Add Requisition</button> */}
                    <img src={fullView} alt=""
                      onClick={() => { navigate("/admin/recruitment/requisition/full-view", { replace: true }) }} />
                  </div>
                </div>
                <Table
                  columns={requisitionColumns}
                  dataSource={requisitionList}
                  pagination={false}
                />
              </div>
            }

            {
              role_list?.includes("recruitment")
              &&
              <div className={styles.latestJobsContainer}>
                <div className={styles.headerContainer}>
                  <p className={styles.heading}>Latest Job</p>
                  <div >
                    <button className={styles.filterButton}>Filter</button>
                    <button className={styles.button}
                      onClick={() => { navigate("/admin/recruitment/post-job") }}
                    >Post a Job</button>
                    <img src={fullView} alt=""
                      onClick={() => { navigate("/admin/recruitment/job-list/full-view", { replace: true }) }} />
                  </div>
                </div>
                <Table
                  columns={latestJobsColumns}
                  dataSource={latestJobList}
                  pagination={false}
                />
              </div>
            }

            {
              role_list?.includes("payroll")
              &&
              <div className={styles.employeeSalaryContainer}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "30px" }}>
                  <p className={styles.heading}>Employee Salary Manager</p>
                  <img src={fullView} alt="" style={{ cursor: "pointer", marginBottom: "20px" }}
                    onClick={() => { navigate("/admin/payroll/employee-salary-manager", { replace: true }) }} />
                </div>
                <Table
                  columns={EmployeeSalaryColumn}
                  dataSource={employeeSalaryManagerData}
                  // loading={loading}
                  pagination={false}
                />
              </div>
            }
          </div>
      }

      <Modal
        title="Revise Salary"
        centered
        open={reviseSalary}
        // onOk={() => setReviseSalary(false)}
        onCancel={() => setReviseSalary(false)}
        width={400}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={selectedEmployeeSalary}
          // onFinishFailed={onFinishFailed}
          fields={[
            {
              name: ["name"],
              value: selectedEmployeeSalary?.name
            }
          ]}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Employee Name"
                name="name"
              >
                <Input
                  size='large'
                />
              </Form.Item>

            </Col>
            <p>Current CTC</p>
            <Col span={24}>
              <Form.Item
                label=""
                name="appraisal_type"
              >
                <Radio.Group
                  options={[
                    {
                      label: 'Increment',
                      value: 'increment',
                    },
                    {
                      label: 'Decrement',
                      value: 'decrement',
                    },
                  ]}
                  // onChange={onChange3}
                  // value={value3}
                  optionType="button"
                  buttonCheckedBg="red"
                  buttonStyle="solid"
                  colorBgContainer="#E2F6F6"
                />
              </Form.Item>

            </Col>
            <Col span={24}>
              <Form.Item
                name="amount"
              >
                <Input
                  size='large'
                  placeholder="Enter Amount"
                />
              </Form.Item>

            </Col>
            <Col span={24}>
              <Form.Item
                name="component_choice"
              >
                <Select>
                  <Select.Option value={0}>Flat</Select.Option>
                  <Select.Option value={1}>Percentage</Select.Option>
                </Select>
              </Form.Item>

            </Col>
            <Col span={24}>
              <Form.Item
              >
                <Input
                  size='large'
                  placeholder="Enter Amount"
                  value="CTC"
                  disabled={true}
                />
              </Form.Item>

            </Col>
          </Row>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>

        </Form>
      </Modal>
      <Modal
        title="Download Report"
        centered
        open={downloadModal}
        // onOk={() => setReviseSalary(false)}
        onCancel={() => setDownloadModal(false)}
        width={800}
        footer={null}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
          <Select
            style={{ width: "100%", marginTop: "20px" }}
            placeholder="Select an option"
            onChange={handleDaysChange}
            defaultValue={attendanceReportDays}
            size='large'
          >
            <Select.Option value={7}>Last 7 days</Select.Option>
            <Select.Option value={14}>Last 14 days</Select.Option>
            <Select.Option value={30}>Last 30 days</Select.Option>
            <Select.Option value="custom">
              <span
                onClick={() => {
                  setOpen(true);
                }}
              >
                Custom Date
              </span>
            </Select.Option>
          </Select>

          <Modal
            title="Select Date Range"
            centered
            open={open}
            onCancel={() => setOpen(false)}
            onOk={async () => {

              setOpen(false);
            }}
          >
            <RangePicker
              value={dateRange}
              onChange={handleDateChange}
              format="YYYY-MM-DD"
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              style={{ color: "black !important" }}
            />
          </Modal>


        </div>

        <Button style={{ width: "120px", background: "#008080", color: "white", height: "40px", fontWeight: "600" }}
          onClick={() => downloadReport()}
        >
          Download
        </Button>

      </Modal>

    </div>
  )
}

export default Dashboard