import React, { useEffect, useRef, useState } from "react";
import add from "../../../../assets/Org/add.svg";
import edit from "../../../../assets/edit.svg";
import { useNavigate } from "react-router-dom";
import request from "../../../../utils/axios";
import {
  DownOutlined,

  UpOutlined,
} from "@ant-design/icons";
import { Popover } from "antd";
import getScreenSize from "../../../../utils/getScreenSize";

function AdminLeaveTypeFullView() {
  const navigate = useNavigate();
  const ref = useRef(null);
  const screenSize = getScreenSize();
  const [showLeaveTypeModal, setShowLeaveTypeModal] = useState(false);
  const [leaveTypesList, setLeaveTypesList] = useState([]);
  const fetchLeaveTypeList = async () => {
    try {
      const data = await request({
        method: "get",
        url: "leave/admin/leave_types/all/",
      });

      setLeaveTypesList(data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchLeaveTypeList();
  }, []);

  const onClickHandler = () => {
    navigate("/admin/leave/leave-type-form", { replace: true });
  };

  const [isCollapsed, setIsCollapsed] = useState(true);
  const defaultDisplayLimit = 8;

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const iconLabelList = [
    {
      value: "Male",
      name: "is_for_male",
    },
    {
      value: "Female",
      name: "is_for_female",
    },
    {
      value: "Other",
      name: "is_for_others",
    },
    {
      value: "Permanent",
      name: "is_for_full_time",
    },
    {
      value: "Part Time",
      name: "is_for_part_time",
    },
    {
      value: "Contractual",
      name: "is_for_contractual",
    },
    {
      value: "Internship",
      name: "is_for_internship",
    },
    {
      value: "Married",
      name: "is_for_married",
    },
    {
      value: "Single",
      name: "is_for_single",
    },
    {
      value: "Pro Rata",
      name: "is_pro_rata",
    },
    {
      value: "Once During Employement",
      name: "is_once_during_employement_year",
    },
    {
      value: "Document Required",
      name: "is_document_required",
    },
    {
      value: "Allowed During Notice Period",
      name: "is_allowed_during_notice_period",
    },
    {
      value: "Half Day Interval",
      name: "is_half_day_interval_allowed",
    },
    {
      value: "Waiting Period",
      name: "is_waiting_period",
    },
    {
      value: "Weekly Off",
      name: "is_weekly_off_inclusion",
    },
    {
      value: "Back Dated Allowed",
      name: "is_back_dated_allowed",
    },
    {
      value: "Carry Forward Leaves",
      name: "is_carry_forward_leaves",
    },
    {
      value: "Encashement",
      name: "is_encashement",
    },
    {
      value: "Cooling Off Period",
      name: "is_cooling_off_period",
    },
    {
      value: "New Joinee",
      name: "is_applicable_for_new_joinee",
    },
    {
      value: "Disallowed",
      name: "is_disallowed",
    },
    {
      value: "Max Allowed",
      name: "is_max_allowed",
    },
    {
      value: "Min Allowed",
      name: "is_minimum_allowed",
    },
  ];

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      {/* {screenSize.width < 768 ? ( */}
      <div ref={ref}>
        <div className="myTeamContainerNew" style={{ padding: "20px" }}>
          {/* <div> */}
          <div className="myTeamTextNew">Leave Type</div>

          <img
            src={add}
            alt="add"
            className="addImage"
            onClick={() => {
              setShowLeaveTypeModal(true);
              onClickHandler();
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            marginBottom: "20px",
          }}
        ></div>
        {/* </div> */}
        {
          <>
            {leaveTypesList?.map((item, index) => {
              const icon = item.image;

              return (
                <div
                  style={{
                    border: "1px solid #F7F7F7",
                    padding: "25px 20px",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img src={icon} alt="" width={25} height={25} />
                      <span className="leaveTypeTitle">
                        {item?.leave_type_name}
                      </span>
                    </div>
                    <img
                      src={edit}
                      alt="edit"
                      width={15}
                      height={15}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/admin/leave/leave-type-form/${item.id}`, { replace: true });
                      }}
                    />
                  </div>
                  <div style={{}}>
                    {/* <span style={{ fontSize: "14px", color: "#575757" }}>
                              {item.description}
                            </span> */}
                    <div style={{ width: "100%", marginTop: "20px" }}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr 1fr",
                          gap: "10px",
                        }}
                      >
                        {item.data.map((el, i) => {
                          const leaveTypeIcon =
                            process.env.REACT_APP_IMG_BASE_URL + el.icon;

                          const iconLabel = iconLabelList.filter(
                            (x) => x.name == el.name
                          );

                          const content = el.text.map((data) => {
                            return (
                              <>
                                {data.value && (
                                  <>
                                    <p
                                      style={{
                                        color: "#252C58",
                                        fontSize: "16px",
                                        margin: "0px",
                                      }}
                                    >
                                      {data.title}
                                    </p>
                                    <p
                                      style={{
                                        color: "#6A6A6A",
                                        fontSize: "14px",
                                        margin: "0px",
                                      }}
                                    >
                                      {data.value}
                                    </p>
                                  </>
                                )}
                              </>
                            );
                          });
                          return (
                            <>
                              {i < 8 && (
                                <Popover
                                  placement="top"
                                  title={iconLabel[0]?.value}
                                  content={content}
                                >
                                  <img
                                    key={i}
                                    src={leaveTypeIcon}
                                    alt={leaveTypeIcon}
                                  />
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "#313131",
                                      textAlign: "center",
                                    }}
                                  >
                                    {iconLabel[0]?.value}
                                  </p>
                                </Popover>
                              )}
                              {!isCollapsed && i >= 8 && (
                                <Popover
                                  placement="top"
                                  title={iconLabel[0]?.value}
                                  content={content}
                                >
                                  <img
                                    key={i}
                                    src={leaveTypeIcon}
                                    alt={leaveTypeIcon}
                                  />
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "#313131",
                                      textAlign: "center",
                                    }}
                                  >
                                    {iconLabel[0]?.value}
                                  </p>
                                </Popover>
                              )}
                            </>
                          );
                        })}
                      </div>
                      <>
                        {item?.data?.length > defaultDisplayLimit && (
                          <div onClick={handleToggleCollapse}>
                            <center>
                              {isCollapsed ? (
                                <DownOutlined
                                  style={{
                                    color: "#008080",
                                    fontWeight: "bolder",
                                    fontSize: "x-large",
                                  }}
                                />
                              ) : (
                                <UpOutlined
                                  style={{
                                    color: "#008080",
                                    fontWeight: "bolder",
                                    fontSize: "x-large",
                                  }}
                                />
                              )}
                            </center>
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        }
      </div>
      {/* ) : (
        <>
          <LeaveTypeFullView />
        </>
      )} */}
    </>
  );
}

export default AdminLeaveTypeFullView;
