import React from 'react'





function PrivacyPolicy() {
   
    

  

    return (
        <div >
       
             <div style={{background:'#008080',height:"63px",borderBottomRightRadius:"10px",borderBottomLeftRadius:"10px",alignItems:"center",display:"flex"}}>
                <p style={{color:"white",marginLeft:"10px",marginBottom:"0px"}}>Privacy Policy</p>
              
                </div>
          
           <div style={{marginLeft:'10px',marginTop:'10px'}} > <h2>Engage Privacy Policy</h2>
<p>Engage, is owned and operated by Executive Solutions Private Limited. This Privacy Policy describes how Executive Solutions (“Engage,” “We,” “Our,” or “Us”) collects, uses, secures, and discloses Personal Information, and what choices you have with respect to that information.
Updates in this latest version of the Privacy Policy reflect changes in applicable data protection laws, including the European Union General Data Protection Regulation (“GDPR”).</p>
<h2>Applicability</h2>
<p>This Privacy Policy describes the policies and procedures of Engage on the collection, use, access, correction, and disclosure of your personal information on Engage.com (the “Site”) and our mobile applications on Android and iOS platforms: Engage (together, the “Mobile Apps”). Your personal information will include any information which, either alone or with other data, is reasonably available to Us and relates to you (“Personal Information”).</p>


<p>This Privacy Policy also covers any of your Personal Information which is provided to Us and which is used in connection with the marketing of the services, features or content We offer (the “Services”) to Our Clients and/or the support that We may give you in connection with the provision of our Services and the Mobile Apps.</p>


<p>This Privacy Policy also describes the choices available to you regarding the use of, your access to, and your rights in relation to your Personal Information.</p>


<p>This Privacy Policy does not apply to any third-party applications or software that can be accessed from the Site, the Services or the Mobile Apps (“Third Party Services”).</p>


<p>A separate agreement governs the delivery, access, and use of the Services and Mobile Apps (the “Client Agreement”), including the processing of Personal Information and data submitted through Services accounts. The organization (i.e. your employer) that entered into the Client Agreement (“Client”) is the data controller for its instance of the Services (their “Platform”) and any associated client data. If you have any questions about specific Platform settings, the processing of Personal Information in the Platform, or its privacy practices, please contact the HR Administrator of the organization (i.e. your employer).</p>
<h2>Information we collect</h2>
<p>Browsing and Log Data</p>
<p>Like most Websites, We automatically gather and store certain information when you browse the Site. This information does not identify you personally and cannot be linked back to you unless you decide at some point to identify yourself. If you are only browsing, the information we collect may include Internet protocol (IP) addresses, type of browser and operating system used to access the Site, Internet service provider (ISP), referring and exit pages, the files and pages viewed on Our site, date and time stamp, and/or clickstream data.
The legal basis (according to GDPR) for processing this information is the legitimate interest in improving the relevance of Our Site.</p>
<p>Cookies</p>
<p>We may use “cookies” to analyze trends, administer the Site, track users’ movements around the Site, and to gather demographic information about Our user base as a whole. “Cookies” are small identifiers sent from a web server and stored on your computer’s hard drive, similar to a license plate, that help us to recognize you if you visit the website again. Cookies are not designed to be used to get data from your hard drive, your e-mail or any other personal data about you. To protect your privacy, we do not use cookies to store or transmit any personal information about you on the Internet. You can reject cookies by changing your browser settings.
The legal basis (according to GDPR) for processing this information is the legitimate interest in improving the relevance of Our Site.</p>
<p>Personal and Contact Information</p>
<p>You may browse this website without being obliged to provide any personal information. However, you may choose to provide Us with certain Personal Information, such as your name, email address, phone number, employment details. This information is used to
communicate with you by responding to your requests, comments and questions
perform the Services provided by Engage
improve the Site
The legal basis (according to GDPR) for processing this information is
the legitimate interest in communicating with you to answer your questions
improving Our Site
the contractual obligation to perform the Services</p>
<p>Location Information</p>
<p>We do not ask you for, access, or track any location based information from your mobile device at any time while downloading or using the Mobile Apps. However, if you are using the Engage Mobile Apps, your employer may enable location tracking technology for attendance or tracking purposes.
The legal basis (according to GDPR) for processing this information is the contractual obligation to your employer to perform the Services.</p>
<p>Additional Information</p>
<p>Single Sign-On (SSO): You can log in to Our Services using sign-in services such as Log in With Google or an Open ID provider. These services will authenticate your identity and provide you the option to share certain Personal Information with Us such as your name and email address to pre-populate Our login forms.
Social Media: Our Site may include social media features, such as the Facebook, Twitter, and Linkedin “Like” button and widgets, such as the “Share This” button. These features may collect your IP address, which page you are visiting on Our Site, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on Our Site. Your interactions with these features are governed by the privacy policy of the company providing it.
Blog:Our Site may offer publicly accessible blogs or community forums. You should be aware that any information you provide in these areas may be read, collected, and used by others who access them.
Testimonials:We may display personal testimonials of satisfied customers on Our Site in addition to other endorsements. With your consent, We may post your testimonial along with your name and employment details. In addition to your other rights, if you wish to update or delete your testimonial, you can contact Us at contact@start10x.in
Referrals:We may include a Referral form on Our Site. If you choose to provide a friend’s details in that form, We will ask you for your friend’s name, email address, phone number and employment details. You must have the consent of your friend before using this service. We will automatically send your friend a one-time email inviting him or her to visit the Site. Engage stores this information for the sole purpose of sending this one-time email and tracking the success of Our referral program. In addition to their other rights, your friend may contact Us at contact@start10x.in to request that We remove this information from our database.</p>
<p>The legal basis (according to GDPR) for processing these information is your consent.</p>
<p>Information Related to data collected for Our Clients (i.e. Your Employer)</p>
<p>When acting as a software service provider, Engage only collects information under the direction of its Clients. The Client Agreement which We sign with Our Clients governs the delivery, access, and use of the Engage Services and Mobile Apps, including the processing of Personal Information and data submitted through Services accounts. The Client controls their Platform instance and any associated data including but not limited to Clients’ current and previous employees. If you have any questions about specific Platform instance settings, the processing of Personal Information in the Platform instance, or its privacy practices, please contact the HR Administrator of Our Client (i.e. your current or previous employer).
Client data is be used by Engage in accordance to Client’s instructions, applicable terms in the Client Agreement, Client’s use of Services functionality, and as required by applicable law.
Under applicable GDPR guidelines, Engage is a processor of Client data and Client is the controller.</p>
<h2>How we share your information</h2>
<p>With Our Service Providers</p>
<p>We may share your information with third parties who provide services to Us. These third parties are authorized to use your Personal Information only as necessary to provide these services to Us. These services may include the provision of
email services to send marketing communications
mapping services
customer service or support
providing cloud computing infrastructure</p>
<p>Third Party Services</p>
<p>At times, you may be able to access other Third Party Services through the Site, for example by clicking on links to those Third Party Services from within the Site. We are not responsible for the privacy policies and/or practices of these Third Party Services, and you are responsible for reading and understanding those Third Party Services’ privacy policies.</p>
<p>Information Shared with Our Sub-Processors</p>
<p>We employ and contract with people and other entities that perform certain tasks on Our behalf and who are under Our control such as an email service providers to send emails on Our behalf, mapping service providers, and customer support providers Our “Sub-Processors”). We may need to share Personal Information with Our Sub-Processors in order to provide Services to you. Unless We tell you differently, Our Sub-Processors do not have any right to use Personal Information or other information We share with them beyond what is necessary to assist Us. Transfers to subsequent third parties are covered by onward transfer agreements between Engage and each Sub-Processor. Engage Sub-Processors that process Personal Information of individuals are Amazon Web Services, and Google.</p>
<p>Information Disclosed Pursuant to Business Transfers</p>
<p>In certain situations, We may go through a business transition such as a merger, acquisition by another company, or sale of all or a portion of its assets, user Information will likely be among the assets transferred or acquired. You acknowledge that such transfers may occur, and that any acquirer of Us or Our assets may continue to use your Personal Information as set forth in this Privacy Policy. You will be notified via email and/or a prominent notice on Our Site of any change in the legal owner or uses of your Personal Information.</p>
<p>Legal and Protective Disclosures</p>
<p>In certain situations, We may be required to disclose Personal Information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. We also reserve the right to access, read, preserve, and disclose any information as We reasonably believe is necessary to
enforce this Privacy Policy, including investigation of potential violations
satisfy any applicable law, regulation, legal process or governmental request
respond to user support requests
detect, prevent, or otherwise address fraud, security, or technical issues
protect Our rights, property, or safety
This includes exchanging information with other companies and organizations for fraud protection and spam/malware prevention.</p>
<p>Marketing</p>
<p>As part of the Services, you may choose to opt-in to receive occasional emails and/or other communications from Us, such as communications relating to promotions. You may opt-out of receiving such communications at any time by using the “Unsubscribe” link found in such emails, or by emailing Us at contact@start10x.in. In the context of Us providing you marketing communication, We may analyze your preferences to make sure the information We provide is relevant.</p>
<p>Children</p>
<p>We do not knowingly collect personally identifiable information of anyone under the age of 18. Do not register, download materials or complete any of our web-forms if you are under the age of18 and do not provide us with any information regarding any individual under the age of 18. We recommend that parents actively participate in their children's use of the Internet.</p>
<p>Data Retention</p>
<p>We will retain your Personal Information and the Personal Information We process on behalf of Our Clients for as long as needed to provide Services to Our Clients, and as necessary to comply with Our legal obligations, resolve disputes, and enforce Our agreements. You may request removal of your Personal Information at any time by contacting privacy@Engage.com.</p>
<p>Data Security</p>
<p>The security of your Personal Information and Our Clients’ information is important to Us. We put in place appropriate technical and organizational measures to ensure your Personal Information is kept secure and protected from unauthorized access, use, disclosure, alteration or destruction, in accordance with applicable laws and regulations. You can view more details about our data security here.
If you have any questions about security on Our Site, you can contact Us at privacy@Engage.com.</p>
<h2>Your Rights to your Personal Information</h2>
<p>In the event that you have provided Personal Information to Us in your use of the Site, We will provide you with information about whether We hold any of your Personal Information.
You may access, correct, or request deletion of your Personal Information by contacting Us at privacy@Engage.com. We will respond to your request within a reasonable timeframe.
When acting as a service provider of Our Clients, Engage has no direct relationship with the individuals whose Personal Information is provided to Engage through the Services. An individual who is or was employed by one of Our Clients and who seeks access to, or who seeks to correct, amend, object to the processing or profiling of, or to delete his/her Personal Information in the Platform, should direct his/her query to the HR department of the Client that uses the Platform and for which he/she works or used to work if he/she cannot make the appropriate changes via its access to the Platform provided by the Client.
If located in the European Economic Area (“EEA”), you have the following rights regarding your Personal Information We collect and/or control:
Right of Access You can request details of your Personal Information We hold. We will confirm whether We are processing your Personal Information and We will disclose additional information including the types of Personal Information, the sources it originated from, the purpose and legal basis for the processing, the expected retention period and the safeguards regarding data transfers to non-EEA countries, subject to the limitations set out in applicable laws and regulations.
Right of correction – You can request Us to correct incomplete or inaccurate parts of your Personal Information, although We may need to verify the accuracy of the new information you provide Us.
Right to be forgotten – You can request Us to delete your Personal Information if:
it is no longer necessary for Us to retain your Personal Information
you withdraw the consent which formed the legal basis for the processing of your Personal Information
you object to the processing of your Personal Information (see below) and there are no overriding legitimate grounds for such processing
the Personal Information was processed illegally
the Personal Information must be deleted for Us to comply with Our legal obligations
We will decline your request for deletion of your Personal Information if it is necessary:
for Us to comply with Our legal obligations
for the establishment, exercise or defense of legal claims
for the performance of a task in the public interest
Right to restrict processing You can request Us to restrict the processing of your Personal Information if:
you dispute the accuracy of your Personal Information;
your Personal Information was processed illegally and you request a limitation on processing rather than the deletion of your Personal Information
we no longer need to process your Personal Information, but you need your Personal Information in connection with the establishment, exercise or defence of a legal claim
you object to the processing of your Personal Information (see below) pending verification as to whether an overriding legitimate ground for such processing exists.
We may continue to store your Personal Information to the extent required to ensure that your request to restrict processing is respected in the future.
Right to object Where We rely on Our legitimate interests (or that of a third party) to process your Personal Information, you have the right to object to this processing on grounds relating to your particular situation if you feel it impacts on your fundamental rights and freedoms. We will comply with your request unless We have compelling legitimate grounds for the processing which override your rights and freedoms, or where the processing is in connection with the establishment, exercise or defence of legal claims. We will always comply with your objection to processing your Personal Information for direct marketing purposes.
Right not to be subject to decisions based solely on automated processing You will not be subject to decisions with a legal or similarly significant effect (including profiling) that are based solely on the automated processing of your Personal Information, unless you have given Us your explicit consent or where they are necessary for the performance of a contract with Us.
Right to data portability You can request us to provide you free of charge, your Personal Information in a structured, commonly used and machine readable format, if:
you provided Us with your Personal Information;
the processing of your Personal Information is required for the performance of a contract
the processing is carried out by automated means
Right to withdraw consent You have the right to withdraw any consent you may have previously given Us at any time. In order to exercise your rights in this section We may ask you for certain identifying information to ensure the security of your Personal Information.
To request to exercise any of the above rights, please contact us at contact@start10x.in. We will respond to your request within 30 days or provide you with reasons for the delay.
Usually, We will not charge you any fees in connection with the exercise of your rights. If your request is manifestly unfounded or excessive, for example, because of its repetitive character, We may charge a reasonable fee, taking into account the administrative costs of dealing with your request. If We refuse your request We will notify you of the relevant reasons.
In so far as practicable, We will notify Our Clients and third parties with any correction, deletion, and/or restriction to the processing of your Personal Information. Please note that We cannot guarantee our Clients or other third parties will comply with your requests and We encourage you to contact them directly.
Please note that if you decide to exercise some of your rights, We may be unable to perform the actions necessary to achieve the purposes set out above or you may not be able to use or take full advantage of the Site and Mobile Apps.
Right to complain to a supervisory authority If you are not satisfied with Our response, you have the right to complain or seek advice from a supervisory authority and/or bring a claim against Us in any court of competent jurisdiction.</p>
<h2>Notification of Privacy Policy Changes</h2>
<p>If our information practices change at some time in the future we will post the policy changes to this website to notify you of these changes and provide you with the ability to opt out of these new uses. If you are concerned about how your information is used, you should check back this website periodically.
If you have any questions or concerns about this Privacy Policy please contact us at contact@start10x.in</p>
<p>Last change Date: 09.08.2024</p>
</div>
</div>
)}











            


export default PrivacyPolicy;