import { configureStore } from '@reduxjs/toolkit'

import onboardingReducer from "./reducer/OnboardingReducer"
import EmployeeReducer from "./reducer/EmployeeReducer"
// import personalDetailsReducer from './reducer/PersonalDetailsReducer'
// import employmentDetailsReducer from './reducer/EmployeeDetailsReducer'
import userProfileReducer from './reducer/UserProfileReducer'
import Form24SettingReducer from './reducer/Form24SettingReducer'
import taxDeclarationReducer from './reducer/TaxDeclarationReducer'
import LoaderReducer from './reducer/LoaderReducer'
import companyNameReducer from './reducer/CompName'
import BulkPayrollProcessingReducer from './reducer/BulkPayrollProcessing'
export const store = configureStore({
    reducer: {
        companyName: companyNameReducer,
        onboarding: onboardingReducer,
        employee: EmployeeReducer,
        // personalDetails: personalDetailsReducer,
        // employmentDetails: employmentDetailsReducer,
        userProfile: userProfileReducer,
        form24Setting: Form24SettingReducer,
        taxDeclaration: taxDeclarationReducer,
        loader: LoaderReducer,
        selectedEmployeePayrollProcessing: BulkPayrollProcessingReducer
    }
})

