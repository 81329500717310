import { Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';


function Loader() {
    const loading = useSelector(state => state.loader)

    return (
        <>
            {
                loading
                    ?
                    <div style={{ position: "absolute", top: "0px", width: "100vw", height: "100vh", background: "#00000075", zIndex: "999" }}>
                        <div style={{ width: "100%", position: "absolute", top: "50%", left: "50%", }}>
                            <Spin size="large" />
                        </div>
                    </div>
                    :
                    null
            }
        </>
    )
}

export default Loader