import home from "../assets/MobileMenu/home.png";
import homeActive from "../assets/MobileMenu/homeActive.png";
import attendance from "../assets/MobileMenu/attendance.png";
import attendanceActive from "../assets/MobileMenu/attendanceActive.png";
// import profile from "../assets/MobileMenu/profile.png";
// import profileActive from "../assets/MobileMenu/profileActive.png";
import more from "../assets/MobileMenu/more.png";
import moreActive from "../assets/MobileMenu/moreActive.png";
import activeLeaveImg from "../assets/MobileMenu/leaveActive.png";
import leave from "../assets/MobileMenu/leave.png";


const adminMobileMenuList = [
    {
        key: "home",
        image: home,
        activeImage: homeActive,
        label: "Home",
        path: "/admin/dashboard"
    },
    {
        key: "attendance",
        image: attendance,
        activeImage: attendanceActive,
        label: "Attendance",
        path: "/admin/attendance/dashboard"
    },
    {
        key: "leave",
        image: leave,
        activeImage: activeLeaveImg,
        label: "Leave",
        path: "/admin/leave"
    },
    {
        key: "more",
        image: more,
        activeImage: moreActive,
        label: "More",
        path: ""
    },
]


export default adminMobileMenuList;