import React, { useState, useEffect } from 'react';
import styles from './DocumentationDetails.scss';
import request from '../../../../utils/axios';
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import leave from "../../../../assets/Inbox/leave.png";
// import performance from "../../../assets/Inbox/Increase.png";
import resign from "../../../../assets/Inbox/resignation.png";
import LeaveList from '../../../Inbox/Leave/LeaveList/LeaveList';
import Resignation from '../../Resignation/Resignation';
import documentation from "../../../../assets/Inbox/newDocumentSigning.png";
import dashboardStyles from "../../Dashboard/Dashboard.module.scss"
import getScreenSize from "../../../../utils/getScreenSize";
import { Tabs } from 'antd';


function DocsDetail({ leaveId, isListView, setIsListView }) {
    const { width } = getScreenSize();
    const frontend_base_url = process.env.REACT_APP_FRONT_APP_BASE_URL;

    const menu = [
        {
            label: "Leave",
            key: "leave",
            url: "/inbox/leave",
            image: leave,
            component: <LeaveList />
        },
        {
            label: "New Documentation Signing",
            key: "documentation",
            url: "/inbox/documentation",
            image: documentation
        },
        {
            label: "Resignation",
            key: "resign",
            url: "/inbox/resign",
            image: resign,
            component: <Resignation />
        },
        // {
        //     label: "Attendance",
        //     key: "attendance",
        //     url: "/inbox/attendance",
        //     image: attendance
        // },
        // {
        //     label: "Performance Management",
        //     key: "performance",
        //     url: "/inbox/performance",
        //     image: performance
        // },
    ]

    const items = [
        {
            key: 'leave',
            label: 'Leave',
            // children: <LeaveList />,
        },
        {
            key: 'documentation',
            label: 'Documentation',
            children: <>

            </>,
        },
        {
            key: 'resign',
            label: 'Resignation',
            children: <>

            </>,
        },
    ];


    const navigate = useNavigate();
    const [leaveDetail, setLeaveDetail] = useState(null);
    const params = useParams();
    const [selectedMenu, setSelectedMenu] = React.useState("leave")

    useEffect(() => {
        const selectedMenu = window.location.href?.split(frontend_base_url)[1].split("/eta/inbox/")[1].split("/")[0];

        setSelectedMenu(selectedMenu);
    }, [window.location.href])

    useEffect(() => {
        if (params.id) {
            fetchLeaveDetail(params.id);
        }
    }, [params.id]);

    const fetchLeaveDetail = async (id) => {
        try {
            const data = await request({
                method: "get",
                url: `inbox/document/details/?doc_id=${id}`,
            });

            setLeaveDetail(data?.data);
            // console.log(data.data);
        } catch (error) {
            console.log(error);
        }
    };
    const rejectOrApproveLeave = async (id) => {
        try {
            await request({
                method: "post",
                url: `/documentation/policy/document/signed`,
                data: {
                    sign: true,
                    policy_document: document.id
                }
            });

            setTimeout(() => {
                // setIsListView(true);
                window.location.reload();
            }, 1000);

            toast.success("Document Signed successfully", {
                position: "bottom-left",
            });

        } catch (error) {
            console.log(error);

            toast.error("Document could not be signed, please try after some time", {
                position: "bottom-left",
            });

        }
    };
    // function LeaveDetail({ isListView, setIsListView }) {
    return (
        <>
            {
                width < 768
                    // mobile view
                    ?
                    <>
                        <div style={{ width: "100%" }}>
                            <Tabs activeKey={selectedMenu} centered items={items} style={{ width: "100%" }}
                                onChange={(value) => {
                                    setSelectedMenu(value);
                                    navigate(`/inbox/${value}`, { replace: true })
                                }}
                            />
                            <div style={{
                                padding: "0px 16px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    cursor: "pointer",
                                    padding: "5px 10px",
                                    border: "1px solid #616161",
                                    width: "fit-content",
                                    borderRadius: "5px"
                                }}
                                    onClick={() => navigate(-2)}
                                >
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/05cfa04f1b309ee35d7f2d1c66a1927dcfeded1810a60d950eda8db082c37e71?"
                                        className={styles.img}
                                    />
                                    <p
                                        style={{
                                            margin: "0px",
                                            fontSize: "16px",
                                            color: "#616161",
                                        }}

                                    >Back</p>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0px 16px"
                            }}>
                                <p style={{
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    color: "#1A1A1A",
                                    marginTop: "20px"
                                }}>{leaveDetail?.name}</p>
                                <p
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        color: "#1A1A1A",
                                        marginTop: "20px"
                                    }}
                                >{leaveDetail?.created_at}</p>
                            </div>
                            <div style={{ padding: "0px 16px" }}>
                                <p style={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    color: "#2B2B2B"
                                }}>
                                    Hello {leaveDetail?.employee_name},
                                </p>
                                <p style={{
                                    fontSize: "18px",
                                    color: "#565656"
                                }}>
                                    New Document has been Uploaded .
                                </p>

                                <div className={styles.actions}>
                                    <button
                                        style={{
                                            height: "40px",
                                            padding: "0px 20px",
                                            background: "#4AD471",
                                            borderRadius: "5px",
                                            color: "white",
                                            border: "none"
                                        }}
                                        onClick={() => {
                                            rejectOrApproveLeave(leaveDetail.id);
                                        }}
                                    >Sign Off</button>
                                    <button
                                        style={{
                                            height: "40px",
                                            padding: "0px 20px",
                                            background: "none",
                                            borderRadius: "5px",
                                            color: "#616161",
                                            border: "1px solid #616161",
                                            marginLeft: "10px"
                                        }}
                                    >
                                        View
                                    </button>
                                </div>
                                <p
                                    style={{
                                        fontSize: "18px",
                                        color: "#565656",
                                        marginTop: "20px"
                                    }}
                                >Best Regards</p>
                                <p style={{
                                    fontSize: "18px",
                                    color: "#565656"
                                }}>Team HR</p>
                            </div>
                        </div>
                    </>
                    :
                    <div className={dashboardStyles.dashboardContainer}>
                        <div className={dashboardStyles.dashboardInnerContainer}>
                            <div>
                                <p className={dashboardStyles.inboxHeading}>Inbox</p>
                                {
                                    menu.map((item) => {
                                        return (
                                            <div
                                                className={selectedMenu === item.key ? dashboardStyles.selectedItemBox : dashboardStyles.itemBox}
                                                onClick={() => navigate(item.url)}
                                            >
                                                <img src={item.image} alt={item.image} />
                                                <p className={dashboardStyles.label}>{item.label}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div style={{ width: "100%" }}>
                                <div className={styles.leaveDetailContainer}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                        cursor: "pointer",
                                        padding: "5px 10px",
                                        border: "1px solid #616161",
                                        width: "fit-content",
                                        borderRadius: "5px"
                                    }}
                                        onClick={() => navigate(-2)}
                                    >
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/05cfa04f1b309ee35d7f2d1c66a1927dcfeded1810a60d950eda8db082c37e71?"
                                            className={styles.img}
                                        />
                                        <p
                                            style={{
                                                margin: "0px",
                                                fontSize: "16px",
                                                color: "#616161",
                                            }}

                                        >Back</p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}>
                                        <p style={{
                                            fontSize: "20px",
                                            fontWeight: "600",
                                            color: "#1A1A1A",
                                            marginTop: "20px"
                                        }}>{leaveDetail?.name}</p>
                                        <p
                                            style={{
                                                fontSize: "15px",
                                                fontWeight: "500",
                                                color: "#1A1A1A",
                                                marginTop: "20px"
                                            }}
                                        >{leaveDetail?.created_at}</p>
                                    </div>
                                    <div className={styles.leaveDetails}>
                                        <p style={{
                                            fontSize: "18px",
                                            fontWeight: "600",
                                            color: "#2B2B2B"
                                        }}>
                                            Hello {leaveDetail?.employee_name},
                                        </p>
                                        <p style={{
                                            fontSize: "18px",
                                            color: "#565656"
                                        }}>
                                            New Document has been Uploaded .
                                        </p>

                                        <div className={styles.actions}>
                                            <button
                                                style={{
                                                    height: "40px",
                                                    padding: "0px 20px",
                                                    background: "#4AD471",
                                                    borderRadius: "5px",
                                                    color: "white",
                                                    border: "none"
                                                }}
                                                onClick={() => {
                                                    rejectOrApproveLeave(leaveDetail.id);
                                                }}
                                            >Sign Off</button>
                                            <button
                                                style={{
                                                    height: "40px",
                                                    padding: "0px 20px",
                                                    background: "none",
                                                    borderRadius: "5px",
                                                    color: "#616161",
                                                    border: "1px solid #616161",
                                                    marginLeft: "10px"
                                                }}
                                            >
                                                View
                                            </button>
                                        </div>
                                        <p
                                            style={{
                                                fontSize: "18px",
                                                color: "#565656",
                                                marginTop: "20px"
                                            }}
                                        >Best Regards</p>
                                        <p style={{
                                            fontSize: "18px",
                                            color: "#565656"
                                        }}>Team HR</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            }
        </>



    )
}

export default DocsDetail