import React, { useEffect, useState } from 'react'
import styles from './PersonResponsibleForDeductions.module.scss';
import { Button, Col, Form, Input, Row, Select } from "antd";
import request from '../../../../../../utils/axios';
import { setPersonResponsibleForDeduction } from "../../../../../../redux/reducer/Form24SettingReducer";
import { useDispatch, useSelector } from 'react-redux';

function PersonResponsibleForDeductions({ prev, next }) {
    const dispatch = useDispatch()
    let personResponsibleForDeductions = useSelector((state) => state.form24Setting.personResponsibleForDeduction)
    console.log("personResponsibleForDeduction : ", personResponsibleForDeductions);
    const onFinish = (values) => {
        console.log("values : ", values);
        const data = {
            name: values.name,
            designation: values.designation,
            address_changed: values.address_changed,
            flat_no: values.flat_no,
            building: values.building,
            street: values.street,
            city: values.city,
            state: values.state,
            pincode: values.pincode,
            email: values.email,
            telephone_no: values.telephone_no,
            mobile_no: values.mobile_no,
            pan_no: values.pan_no,
        }
        dispatch(setPersonResponsibleForDeduction(data))
        next();
    }
    const [allStates, setAllStates] = useState([])
    const getStatesList = async () => {
        const data = await request({
            method: "get",
            url: `tax-management/admin/get-all-states`,
        })
        console.log("data : ", data);
        setAllStates(data.data)
    }
    useEffect(() => {
        getStatesList()
    }, [])
    return (
        <div className={styles.container}>
            <p className={styles.heading}>24Q Parameters</p>
            <div className={styles.subContainer}>
                <p className={styles.subHeading}>Particulars Of the Person responsible for deductions of Tax</p>
            </div>

            <div>
                <Form layout="vertical"
                    onFinish={onFinish}
                    initialValues={personResponsibleForDeductions}
                    fields={[
                        {
                            name: ["name"],
                            value: personResponsibleForDeductions.name
                        },
                        {
                            name: ["designation"],
                            value: personResponsibleForDeductions.designation
                        },
                        {
                            name: ["address_changed"],
                            value: personResponsibleForDeductions.address_changed
                        },
                        // {
                        //   name: ["flat_no"],
                        //   value: personResponsibleForDeductions.flat_no
                        // },
                        {
                            name: ["building"],
                            value: personResponsibleForDeductions.building
                        },
                        {
                            name: ["street"],
                            value: personResponsibleForDeductions.street
                        },
                        {
                            name: ["city"],
                            value: personResponsibleForDeductions.city
                        },
                        {
                            name: ["state"],
                            value: personResponsibleForDeductions.state
                        },
                        {
                            name: ["telephone_no"],
                            value: personResponsibleForDeductions.telephone_no
                        },
                        {
                            name: ["mobile_no"],
                            value: personResponsibleForDeductions.mobile_no
                        },
                        {
                            name: ["email"],
                            value: personResponsibleForDeductions.email
                        },
                        {
                            name: ["pincode"],
                            value: personResponsibleForDeductions.pincode
                        },
                        {
                            name: ["pan_no"],
                            value: personResponsibleForDeductions.pan_no
                        },
                    ]}
                >
                    <Row gutter={[48, 16]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Name"
                                name="name"
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Name"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Designation"
                                name="designation"
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Designation"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Has the Address Changed From the Last time"
                                name="address_changed"
                            >
                                <Select
                                    size='large'
                                    placeholder="Select"
                                >
                                    <Select.Option value="0">Yes</Select.Option>
                                    <Select.Option value="1">No</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Flat No."
                                name="flat_no"
                            >
                                <Input size='large' placeholder="Enter Flat No." />
                            </Form.Item>
                        </Col> */}
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Name Of Permises / Building"
                                name="building"
                            >
                                <Input size='large' placeholder="Enter Name Of Permises / Building" />
                            </Form.Item>
                        </Col>
                        {/* <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="STD Code"
                                name="address_changed"
                            >
                                <Input size='large' placeholder="Enter STD Code" />
                            </Form.Item>
                        </Col> */}


                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Street"
                                name="street"
                            >
                                <Input size='large' placeholder="Enter Street" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="City"
                                name="city"
                            >
                                <Input size='large' placeholder="Enter City" />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="State"
                                name="state"
                            >
                                <Select
                                    className='dropDown'
                                >
                                    {
                                        allStates.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                                            )
                                        }
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Pin Code"
                                name="pincode"
                            >
                                <Input size='large' placeholder="Enter Pin Code" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Email"
                                name="email"
                            >
                                <Input size='large' placeholder="Enter Email" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Telephone No."
                                name="telephone_no"
                            >
                                <Input size='large' placeholder="Enter Telephone No." />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Mobile No."
                                name="mobile_no"
                            >
                                <Input size='large' placeholder="Enter Mobile No." />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="PAN No."
                                name="pan_no"
                            >
                                <Input size='large' placeholder="Enter PAN No." />
                            </Form.Item>
                        </Col>
                    </Row>
                    <button onClick={() => prev()}
                        className={styles.prevBtn}
                    > prev</button>
                    <button htmlType="submit"
                        className={styles.nextBtn}
                    >next</button>
                </Form>
            </div>
        </div>
    )
}

export default PersonResponsibleForDeductions