import { Input, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import request from '../../../../../utils/axios';
import { useDispatch } from 'react-redux';
import { setSelectedEmployeePayrollProcessing } from '../../../../../redux/reducer/BulkPayrollProcessing';
import { useNavigate } from 'react-router-dom';
import SearchBox from './SearchBox/SearchBox';
import InfiniteScroll from 'react-infinite-scroll-component';

function Selective() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectiveColumns = [
        {
            title: "ID",
            dataIndex: "employement_number",
            key: "employement_number"
        },
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
    ]

    const [selectiveDataList, setSelectiveDataList] = useState([]);
    const [selectedSelectiveRowKeys, setSelectedSelectiveRowKeys] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 12,
        total: 0
    })
    const [hasMore, setHasMore] = useState(true)

    const rowSelection = {
        selectedSelectiveRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedSelectiveRowKeys(selectedRowKeys)
        }
    };

    const fetchEmployeesDetailList = async (paginationParam, dataList) => {
        try {
            const { current } = paginationParam || pagination;
            const data = await request({
                method: "get",
                url: `payroll/admin/payroll/get-bulk-details?select_by=selective&page=${current}&page_size=${pagination.pageSize}`,
                // payroll/admin/payroll/get-bulk-details?select_by=selective&employee_name=komal&page=2&page_size=1
            })
            if (paginationParam) {
                setSelectiveDataList([...dataList, ...data?.data?.pagination_data?.data])
            }
            else {
                setSelectiveDataList([...selectiveDataList, ...data?.data?.pagination_data?.data])
            }

            setPagination({
                ...pagination,
                total: data?.data?.pagination_data?.total,
                current: current + 1,
            })
            if (data?.data?.pagination_data?.next === null) {
                setHasMore(false);
                return;
            }
        }
        catch (error) {
            console.log("error", error)
            setHasMore(false);
        }
    }

    useEffect(() => {
        fetchEmployeesDetailList();
    }, [])

    const runPayroll = () => {
        console.log(selectedSelectiveRowKeys)
        // dispatch(setSelectedEmployeePayrollProcessing([1]))
        // dispatch(setSelectedEmployeePayrollProcessing({ selectedSelectiveRowKeys: "dfds" }));
        dispatch(setSelectedEmployeePayrollProcessing(
            selectedSelectiveRowKeys,


        ));
        navigate("/admin/payroll/9")
    }

    const renderUIDesktop = (dataList) => {
        return (
            <Table
                rowSelection={rowSelection}
                columns={selectiveColumns}
                dataSource={dataList}
                pagination={false}
                sticky={{ offsetHeader: 0 }}
            />
        )
    }

    return (
        <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {/* <Input
                    style={{ width: "300px", height: "40px" }}
                    placeholder='Search Employee'
                /> */}
                <SearchBox
                    setTableDataForSearchEmployee={setSelectiveDataList}
                    setHasMore={setHasMore}
                    fetchTableDataFromApi={fetchEmployeesDetailList}
                    setPagination={setPagination}
                    placeholderText="Search Employee"
                />
                <button
                    style={{
                        border: "none",
                        background: "#008080",
                        color: "white",
                        cursor: "pointer",
                        borderRadius: "4px",
                        height: "40px",
                        width: "150px",
                        marginLeft: "20px"
                    }}
                    onClick={() => runPayroll()}
                >Run Payroll</button>
            </div>
            <div style={{ marginTop: "20px" }}>
                <div
                    id="scrollableDiv"
                    style={{
                        height: "auto",
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }}
                >
                    <InfiniteScroll
                        dataLength={pagination.total - (selectiveDataList.length)}
                        next={fetchEmployeesDetailList}
                        hasMore={hasMore}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                        loader={
                            <p style={{ textAlign: 'center' }}>
                                <b>Loading...</b>
                            </p>
                        }
                        scrollableTarget="scrollableDiv"
                        height="70vh"
                    >
                        {
                            renderUIDesktop(selectiveDataList)
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    )
}

export default Selective