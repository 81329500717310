import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { Spin, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react';
import HRAComponent from "./HRA/HRA"
import styles from "./TaxDeclarationForm.module.scss"
import HouseProperty from './HouseProperty/HouseProperty';
import InvestmentDeclaration from './InvestmentDeclaration/InvestmentDeclaration';
import Mediclaim from './Mediclaim/Mediclaim';
import Donation from './Donation/Donation';
import Others from './Others/Others';
import TaxFreeAllowance from './TaxFreeAllowance/TaxFreeAllowance';
import { useDispatch, useSelector } from 'react-redux';
import request from '../../../../utils/axios';
import { Buffer } from "buffer";
import { setLoader } from "../../../../redux/reducer/LoaderReducer"
import { toast } from 'react-toastify';
import { setDonation, setHouseProperty, setInvestmentDeclaration, setTaxFreeDeclarationState } from "../../../../redux/reducer/TaxDeclarationReducer";

function TaxDeclarationForm() {
  const dispatch = useDispatch();
  const ref = useRef();
  const [windowOpen, setWindowOpen] = useState(null)
  const componentList = [
    <HRAComponent windowOpen={windowOpen} />,
    <TaxFreeAllowance windowOpen={windowOpen} />,
    <HouseProperty windowOpen={windowOpen} />,
    <InvestmentDeclaration windowOpen={windowOpen} />,
    <Mediclaim windowOpen={windowOpen} />,
    <Donation windowOpen={windowOpen} />,
    <Others windowOpen={windowOpen} />
  ];

  const HRA = useSelector(state => state.taxDeclaration.HRA)
  const houseProperty = useSelector(state => state.taxDeclaration.houseProperty)
  const investmentDeclaration = useSelector(state => state.taxDeclaration.investmentDeclaration)
  const mediclaimDeclaration = useSelector(state => state.taxDeclaration.mediclaimDeclaration)
  const taxFreeAllowance = useSelector(state => state.taxDeclaration.taxFreeAllowance)
  const donation = useSelector(state => state.taxDeclaration.donation)
  const otherDecalaration = useSelector(state => state.taxDeclaration.otherDecalaration)

  const [tabList, setTabList] = useState([]);
  const [closeDate, setCloseDate] = useState(null)
  const fetchTabsList = async () => {
    try {
      const response = await request({
        method: "get",
        url: "/regim_tax_rules/get-tax-declaration-tabs",
      });

      setTabList([...response?.data?.data.map((item) => {
        return {
          ...item,
          children: componentList[item.key - 1],

        }
      })]);
      setWindowOpen(response?.data?.is_window_open);
      setCloseDate(response?.data?.close_date);

    }
    catch (error) {
      console.log(error)
    }
  }



  // console.log("HRA", HRA);
  const saveAsDraft = async () => {
    try {
      dispatch(setLoader({ loading: true }))
      for (const month in HRA) {
        await handleSaveAllMonths(month, HRA[month]);
      }

      for (const data in taxFreeAllowance) {
        await setTaxFreeAllowance(taxFreeAllowance[data]);
      }

      for (const data in houseProperty) {
        await setSaveAsDraftHouseProperty(houseProperty[data]);
      }

      for (const data in investmentDeclaration) {
        await setSavedAsDraftInvestmentDeclaration(investmentDeclaration[data]);
      }

      for (const data in mediclaimDeclaration) {
        await setMediclaim(mediclaimDeclaration[data])
      }

      for (const data in donation) {
        await setDonationDeclaration(donation[data])
      }

      setOtherDecalaration(otherDecalaration)

      dispatch(setLoader({ loading: false }))
    }
    catch (error) {
      console.log(error)
    }
  }
  const finalSubmit = async () => {
    try {
      dispatch(setLoader({ loading: true }))
      for (const month in HRA) {
        await finalhandleSaveAllMonths(month, HRA[month]);
      }

      for (const data in taxFreeAllowance) {
        await setFinalTaxFreeAllowance(taxFreeAllowance[data]);
      }

      for (const data in houseProperty) {
        await setFinalHouseProperty(houseProperty[data]);
      }

      for (const data in investmentDeclaration) {
        await setFinalInvestmentDeclaration(investmentDeclaration[data]);
      }

      for (const data in mediclaimDeclaration) {
        await setFinalMediclaim(mediclaimDeclaration[data])
      }

      for (const data in donation) {
        await setFinalDonationDeclaration(donation[data])
      }
      // for (const data in otherDecalaration) {
      //   await setFinalOtherDecalaration(otherDecalaration[data])
      // }

      setFinalOtherDecalaration(otherDecalaration)
      setTaxCalculationAfterDeclaration()

      dispatch(setLoader({ loading: false }))
    }
    catch (error) {
      console.log("error...", error)
      dispatch(setLoader({ loading: false }))
      toast.error(error.response.data.msg)
    }
  }

  const base64ToFormData = (base64String) => {
    const trim = base64String.trim();

    const replace = trim.replace("data:application/pdf;base64,", "")

    // Decode the base64 string to binary data
    const binaryData = atob(replace);

    // Create a Uint8Array to hold the binary data
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    // Populate the Uint8Array with binary data
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: 'application/pdf' });

    return blob;
  };

  const handleSaveAllMonths = async (month, details) => {
    try {
      const formData = new FormData();
      formData.append("address", details.address);
      formData.append("metro_city", details.metro_city);
      formData.append("declared_amount", details.declared_amount);
      formData.append("owner_name", details.owner_name);
      formData.append("owner_pan_no", details.owner_pan_no);
      formData.append("month", details.month);
      if (details?.attachment) {
        if (details?.attachment?.includes("https://")) {
          formData.append("attachment", details?.attachment)
        }
        else {
          const blob = base64ToFormData(details?.attachment);
          formData.append("attachment", blob)
        }
      }
      // if (details.file) {
      //   const blob = base64ToFormData(details.file);
      //   formData.append("file", blob)
      // }
      else {
        formData.append("file", "")
      }
      await request({
        method: "post",
        url: `regim_tax_rules/hra_declaration`,
        data: formData
      })

    } catch (error) {
      console.error("Error saving months as draft:", error);
      throw error;
    }
  }

  const setTaxFreeAllowance = async (details) => {
    try {
      const formData = new FormData();
      formData.append("value", details.value);
      formData.append("componant_name", details.componant_name);
      formData.append("declared_amount", details.declared_amount);
      if (details?.attachment) {
        if (details?.attachment?.includes("https://")) {
          formData.append("attachment", details?.attachment)
        }
        else {
          const blob = base64ToFormData(details?.attachment);

          formData.append("attachment", blob)
        }
      }

      // if (details.attachment) {
      //   const blob = base64ToFormData(details.attachment);

      //   formData.append("attachment", blob)
      // }
      else {
        formData.append("attachment", "")
      }

      await request({
        method: "post",
        url: `/regim_tax_rules/taxfree-allowance-declaration`,
        data: formData
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  const setSaveAsDraftHouseProperty = async (details) => {
    try {
      const formData = new FormData();
      formData.append("section", details.section);
      formData.append("choice", details.choice);
      formData.append("declared_amount", details.declared_amount);
      if (details?.attachment) {
        if (details?.attachment?.includes("https://")) {
          formData.append("attachment", details?.attachment)
        }
        else {
          const blob = base64ToFormData(details?.attachment);

          formData.append("attachment", blob)
        }
      }

      // if (details.attachment) {
      //   const blob = base64ToFormData(details.attachment);

      //   formData.append("attachment", blob)
      // }
      else {
        formData.append("attachment", "")
      }

      await request({
        method: "post",
        url: `/regim_tax_rules/house-property-declaration`,
        data: formData
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  const setSavedAsDraftInvestmentDeclaration = async (details) => {
    try {
      const formData = new FormData();
      formData.append("section", details.section);
      formData.append("choice", details.choice);
      formData.append("declared_amount", details.declared_amount);
      if (details?.attachment) {
        if (details?.attachment?.includes("https://")) {
          formData.append("attachment", details?.attachment)
        }
        else {
          const blob = base64ToFormData(details?.attachment);

          formData.append("attachment", blob)
        }
      }

      // if (details.attachment) {
      //   const blob = base64ToFormData(details.attachment);

      //   formData.append("attachment", blob)
      // }
      else {
        formData.append("attachment", "")
      }

      await request({
        method: "post",
        url: `/regim_tax_rules/investment-declaration`,
        data: formData
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  const setMediclaim = async (details) => {
    try {
      const formData = new FormData();
      formData.append("section", details.section);
      formData.append("choice", details.choice);
      formData.append("declared_amount", details.declared_amount);
      if (details?.attachment) {
        if (details?.attachment?.includes("https://")) {
          formData.append("attachment", details?.attachment)
        }
        else {
          const blob = base64ToFormData(details?.attachment);

          formData.append("attachment", blob)
        }
      }

      // if (details.attachment) {
      //   const blob = base64ToFormData(details.attachment);

      //   formData.append("attachment", blob)
      // }
      else {
        formData.append("attachment", "")
      }

      await request({
        method: "post",
        url: `/regim_tax_rules/mediclaim-declaration`,
        data: formData
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  const setDonationDeclaration = async (details) => {
    try {
      const formData = new FormData();
      formData.append("section", details.section);
      formData.append("choice", details.choice);
      formData.append("declared_amount", details.declared_amount);
      if (details?.attachment) {
        if (details?.attachment?.includes("https://")) {
          formData.append("attachment", details?.attachment)
        }
        else {
          const blob = base64ToFormData(details?.attachment);

          formData.append("attachment", blob)
        }
      }

      // if (details.attachment) {
      //   const blob = base64ToFormData(details.attachment);

      //   formData.append("attachment", blob)
      // }
      else {
        formData.append("attachment", "")
      }

      await request({
        method: "post",
        url: `/regim_tax_rules/donation-declaration`,
        data: formData
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  const setOtherDecalaration = async (details) => {
    try {
      console.log("komal....", details)
      const formData = new FormData();
      formData.append("section", "OtherSources");
      formData.append("choice", details.choice);
      formData.append("declared_amount", details.declared_amount);
      if (details?.attachment) {
        if (details?.attachment?.includes("https://")) {
          formData.append("attachment", details?.attachment)
        }
        else {
          const blob = base64ToFormData(details?.attachment);

          formData.append("attachment", blob)
        }
      }

      // if (details.attachment) {
      //   const blob = base64ToFormData(details.attachment);

      //   formData.append("attachment", blob)
      // }
      else {
        formData.append("attachment", "")
      }

      await request({
        method: "post",
        url: `/regim_tax_rules/other_sources-declaration`,
        data: formData
      })
    }
    catch (error) {
      console.log(error)
    }
  }


  const finalhandleSaveAllMonths = async (month, details) => {
    try {
      const formData = new FormData();
      formData.append("address", details.address);
      formData.append("metro_city", details.metro_city);
      formData.append("declared_amount", details.declared_amount);
      formData.append("owner_name", details.owner_name);
      formData.append("owner_pan_no", details.owner_pan_no);
      formData.append("month", details.month);
      // if (details.file) {
      //   const blob = base64ToFormData(details.file);

      //   formData.append("file", blob)
      // }
      if (details?.attachment) {
        if (details?.attachment?.includes("https://")) {
          formData.append("attachment", details?.attachment)
        }
        else {
          const blob = base64ToFormData(details?.attachment);
          formData.append("attachment", blob)
        }
      }
      else {
        formData.append("attachment", "")
      }
      await request({
        method: "post",
        url: `investment-declaration/final-hra_declaration`,
        data: formData
      })

    } catch (error) {
      console.error("Error saving months as draft:", error);
      throw error;
    }
  }

  const setFinalTaxFreeAllowance = async (details) => {
    try {
      const formData = new FormData();
      formData.append("value", details.value);
      formData.append("componant_name", details.componant_name);
      formData.append("declared_amount", details.declared_amount);

      if (details?.attachment) {
        if (details?.attachment?.includes("https://")) {
          formData.append("attachment", details?.attachment)
        }
        else {
          const blob = base64ToFormData(details?.attachment);

          formData.append("attachment", blob)
        }
      }
      else {
        formData.append("attachment", "")
      }

      await request({
        method: "post",
        url: `/investment-declaration/final-Tax-free-allowance`,
        data: formData
      })
    }
    catch (error) {
      console.log(error)
      throw error;
    }
  }

  const setFinalHouseProperty = async (details) => {
    try {
      const formData = new FormData();
      formData.append("section", details.section);
      formData.append("choice", details.choice);
      formData.append("declared_amount", details.declared_amount);

      if (details?.attachment) {
        if (details?.attachment?.includes("https://")) {
          formData.append("attachment", details?.attachment)
        }
        else {
          const blob = base64ToFormData(details.attachment);

          formData.append("attachment", blob)
        }
      }
      else {
        formData.append("attachment", "")
      }

      await request({
        method: "post",
        url: `/investment-declaration/final-house-property-declaration`,
        data: formData
      })
    }
    catch (error) {
      console.log(error)
      throw error;
    }
  }


  const setFinalInvestmentDeclaration = async (details) => {
    try {
      const formData = new FormData();
      formData.append("section", details.section);
      formData.append("choice", details.choice);
      formData.append("declared_amount", details.declared_amount);

      if (details.attachment) {
        if (details.attachment.includes("https://")) {
          formData.append("attachment", details.attachment)
        }
        else {
          const blob = base64ToFormData(details.attachment);

          formData.append("attachment", blob)
        }
      }
      else {
        formData.append("attachment", "")
      }

      await request({
        method: "post",
        url: `/investment-declaration/final-investment-declaration`,
        data: formData
      })
    }
    catch (error) {
      console.log(error)
      throw error;
    }
  }


  const setFinalMediclaim = async (details) => {
    try {
      const formData = new FormData();
      formData.append("section", details.section);
      formData.append("choice", details.choice);
      formData.append("declared_amount", details.declared_amount);

      if (details.attachment) {
        if (details.attachment.includes("https://")) {
          formData.append("attachment", details.attachment)
        }
        else {
          const blob = base64ToFormData(details.attachment);

          formData.append("attachment", blob)
        }
      }
      else {
        formData.append("attachment", "")
      }

      await request({
        method: "post",
        url: `/investment-declaration/final-mediclaim-declaration`,
        data: formData
      })
    }
    catch (error) {
      console.log(error)
      throw error;
    }
  }

  const setFinalDonationDeclaration = async (details) => {
    try {
      const formData = new FormData();
      formData.append("section", details.section);
      formData.append("choice", details.choice);
      formData.append("declared_amount", details.declared_amount);

      if (details.attachment) {
        if (details.attachment.includes("https://")) {
          formData.append("attachment", details.attachment)
        }
        else {
          const blob = base64ToFormData(details.attachment);

          formData.append("attachment", blob)
        }
      }
      else {
        formData.append("attachment", "")
      }

      await request({
        method: "post",
        url: `/investment-declaration/final-donation-declaration`,
        data: formData
      })
    }
    catch (error) {
      throw error;
    }
  }
  const setFinalOtherDecalaration = async (details) => {
    try {
      const formData = new FormData();
      formData.append("section", "OtherSources");
      formData.append("choice", details.choice);
      formData.append("declared_amount", details.declared_amount);

      if (details.attachment) {
        if (details.attachment.includes("https://")) {
          formData.append("attachment", details.attachment)
        }
        else {
          const blob = base64ToFormData(details.attachment);

          formData.append("attachment", blob)
        }
      }
      else {
        formData.append("attachment", "")
      }

      await request({
        method: "post",
        url: `/investment-declaration/final-others-source-declaration`,
        data: formData
      })
    }
    catch (error) {
      console.log(error)
      throw error;
    }
  }
  const setTaxCalculationAfterDeclaration = async () => {
    try {
      await request({
        method: "post",
        url: `/regim_tax_rules/tax-calculation`
      })
      toast.success("Tax declaration declaration submitted successfully", {
        position: "bottom-left",
      })
    }
    catch (error) {
      console.log(error)
      toast.error("Tax declaration declaration could not be submitted, please try after some time", {
        position: "bottom-left",
      })
    }
  }

  const fetchDonationData = async () => {
    try {
      const data = await request({
        method: "GET",
        url: `regim_tax_rules/get-all-donation-declaration`,
      })
      dispatch(setDonation(data.data))

    }
    catch (error) {
      console.log("error", error);
    }
  }
  const fetchInvestmentDeclarationData = async () => {
    try {
      const data = await request({
        method: "GET",
        url: `regim_tax_rules/get-all-investment-declaration`,
      })
      dispatch(setInvestmentDeclaration(data.data))
    }
    catch (error) {
      console.log("error", error);
    }
  }
  const fetchHousePropertyDeclarationData = async () => {
    try {
      const data = await request({
        method: "GET",
        url: `regim_tax_rules/get-all-house-property-declaration`,
      })

      dispatch(setHouseProperty(data.data))
    }
    catch (error) {
      console.log("error", error);
    }
  }
  const fetchTaxFreeAllowanceList = async () => {
    try {
      const data = await request({
        method: "GET",
        url: `regim_tax_rules/get-taxfree-allowance-components`,
      })
      const dynamicStatesObject = {};
      data.data.map(item => {
        dynamicStatesObject[item.value] = {
          ...item
        }
      })
      dispatch(setTaxFreeDeclarationState(dynamicStatesObject));
    }
    catch (error) {

    }
  }


  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    fetchTabsList();
    fetchDonationData();
    fetchInvestmentDeclarationData();
    fetchHousePropertyDeclarationData();
    fetchTaxFreeAllowanceList();
  }, []);

  return (
    <div className={styles.taxDeclaration} ref={ref}>
      {/* <p className={styles.heading}>Compensation</p> */}
      <div className={styles.taxDeclarationHeader}>
        <div className='flexSpaceBetween'>
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <p className={styles.taxDeclarationText}>Tax Declarations</p>
            <div style={{ display: "flex", gap: "20px", alignItems: "center", border: "1px solid #008080", padding: "10px", borderRadius: "4px" }} >
              <p className={styles.openForDeclarationsText}>{windowOpen ? "Open" : "Closed"} For Declarations</p>
              <p className={styles.openForDeclarationsDate}>{windowOpen && closeDate}{" "}
                {
                  windowOpen
                    ?
                    <UnlockOutlined />
                    :
                    <LockOutlined />
                }
              </p>
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <button className={styles.saveButton} onClick={() => saveAsDraft()}>Save as Draft</button>
            <button className={styles.saveButton} onClick={() => finalSubmit()}>Submit</button>
          </div>
        </div>
        <br />
        <Tabs defaultActiveKey="1" items={tabList} />

      </div>

    </div>
  )
}

export default TaxDeclarationForm