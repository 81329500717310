import { DatePicker, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import request from '../../../../utils/axios'
import dayjs from 'dayjs'

function PayrollSummaryInsight() {

    const payrollSummaryInsightColumns = [
        {
            title: 'Component',
            dataIndex: 'component_name',
            key: 'component_name',
        },
        {
            title: 'Total Employee',
            dataIndex: 'total_employees',
            key: 'total_employees',
        },
        {
            title: 'Total Amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
        }
    ]

    const [payrunDataList, setPayrunDataList] = useState([])
    const [selectPayrun, setSelectPayrun] = useState(1)
    const [processingDate, setProcessingDate] = useState({
        month: dayjs().format('MM'),
        year: dayjs().format('YYYY'),
    })

    const fetchPayRunData = async () => {
        try {
            const data = await request({
                method: "GET",
                url: "payroll/admin/payroll/get-all-payrun?month=08&year=2024",
            })

            setPayrunDataList(data.payrun_list)
        }
        catch (error) {
            console.log(error)
        }
    }

    const [cardDetails, setCardDetails] = useState({
        paid_employees: "",
        skipped_employee: "0",
        new_joiner_skipped: "0",
        salary_with_held_employee: "0"
    })

    const fetchCardDetails = async () => {
        try {
            const data = await request({
                method: "GET",
                url: `payroll/admin/payroll/get-payrun-employees?month=${processingDate.month}&year=${processingDate.year}&value=${selectPayrun}`,
            })

            console.log("data : ", data)
            setCardDetails({
                ...cardDetails,
                ...data
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    const [tableDataList, setTableDataList] = useState([])
    const fetchTableDataList = async () => {
        try {
            const data = await request({
                method: "GET",
                url: `payroll/admin/payroll/get-payrun-components-data?month=${processingDate.month}&year=${processingDate.year}&value=${selectPayrun}`,
            })

            console.log("data : ", data.data)
            setTableDataList(data.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchPayRunData();
    }, [])

    useEffect(() => {
        fetchCardDetails();
        fetchTableDataList();
    }, [selectPayrun, processingDate])
    return (
        <div>
            <div style={{ background: "white", padding: "30px", borderRadius: "5px" }}>
                <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
                    <p style={{ color: "#161616", fontSize: "20px", fontWeight: "500", margin: "0" }}>Insight</p>
                    <DatePicker
                        size="large"
                        picker="month"
                        defaultValue={dayjs()}
                        style={{
                            // border: "none",
                            outline: "none",
                            background: "#FFFFFF",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000000",
                        }}
                        onChange={(e) => {
                            console.log("e...", e);
                            const [calendarYear, calendarMonth] = dayjs(e)
                                .format("YYYY-MM")
                                .split("-");
                            setProcessingDate({
                                ...processingDate,
                                month: calendarMonth,
                                year: calendarYear
                            })
                        }}
                        // can we set default value as month name
                        // defaultValue={moment().format("MM")}
                        // defaultValue={dayjs().startOf('month')}
                        placeholder="Month"
                        format={"MMMM YYYY"}
                    />
                </div>
                <div>
                    <div style={{ display: "flex", displayWrap: "wrap", gap: "15px" }}>
                        {
                            payrunDataList.map((item, index) => {
                                return (
                                    <span
                                        key={index}
                                        style={{ fontSize: "16px", fontWeight: "400", color: item.value === selectPayrun ? "#FFFFFF" : "#878787", padding: "7px 15px", background: item.value === selectPayrun ? "#008080" : "#EDEDED", borderRadius: "21px", cursor: "pointer" }}
                                        onClick={() => setSelectPayrun(item.value)}
                                    >
                                        {item.name}
                                    </span>
                                )
                            })
                        }
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                    <div style={{ width: "250px", height: "106px", background: "#FAFAFA", borderRadius: "5px", padding: "10px" }}>
                        <p style={{ color: "#161616", fontSize: "24px", fontWeight: "500", margin: "0" }}>{cardDetails.paid_employees}</p>
                        <p style={{ color: "#8A8A8A", fontSize: "16px", fontWeight: "400", margin: "0" }}>Paid Employee</p>
                        <p style={{ color: "#008080", fontSize: "12px", fontWeight: "500" }}><u>View Details</u></p>
                    </div>
                    <div style={{ width: "250px", height: "106px", background: "#FAFAFA", borderRadius: "5px", padding: "10px" }}>
                        <p style={{ color: "#161616", fontSize: "24px", fontWeight: "500", margin: "0" }}>{cardDetails.skipped_employee}</p>
                        <p style={{ color: "#8A8A8A", fontSize: "16px", fontWeight: "400", margin: "0" }}>Skipped Employee</p>
                        <p style={{ color: "#008080", fontSize: "12px", fontWeight: "500" }}><u>View Details</u></p>
                    </div>
                    <div style={{ width: "250px", height: "106px", background: "#FAFAFA", borderRadius: "5px", padding: "10px" }}>
                        <p style={{ color: "#161616", fontSize: "24px", fontWeight: "500", margin: "0" }}>{cardDetails.new_joiner_skipped}</p>
                        <p style={{ color: "#8A8A8A", fontSize: "16px", fontWeight: "400", margin: "0" }}>New Joiner’s Skipped</p>
                        <p style={{ color: "#008080", fontSize: "12px", fontWeight: "500" }}><u>View Details</u></p>
                    </div>
                    <div style={{ width: "250px", height: "106px", background: "#FAFAFA", borderRadius: "5px", padding: "10px" }}>
                        <p style={{ color: "#161616", fontSize: "24px", fontWeight: "500", margin: "0" }}>{cardDetails.salary_with_held_employee}</p>
                        <p style={{ color: "#8A8A8A", fontSize: "16px", fontWeight: "400", margin: "0" }}>Salary Withheld Employees</p>
                        <p style={{ color: "#008080", fontSize: "12px", fontWeight: "500" }}><u>View Details</u></p>
                    </div>
                </div>
            </div>

            <div style={{ background: "white", padding: "30px", borderRadius: "5px", marginTop: "30px" }}>
                <div>
                    <Table
                        columns={payrollSummaryInsightColumns}
                        dataSource={tableDataList}
                        pagination={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default PayrollSummaryInsight