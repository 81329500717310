import React from 'react';
import MeetingForm from './EditMeetingForm';
import {useLocation, useNavigate} from 'react-router-dom';
import back from '../../../assets/Recruitment/back.svg';
const EditMeeting = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {record, MeetingType} = location.state || {};
  // console.log(record, 'meetingType');
  return (
    <div style={{padding: '50px'}}>
      <p
        onClick={() => {
          navigate(-1);
        }}
        style={{cursor: 'pointer'}}>
        <img src={back} alt="back" />
        <span style={{paddingLeft: '10px'}}>Back</span>
      </p>

      <p style={{fontSize: '32px', font: 500}}>{'Edit Meeting'}</p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}>
        <MeetingForm MeetingType={MeetingType} record={record} />
      </div>
    </div>
  );
};

export default EditMeeting;
