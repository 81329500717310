import React, { useEffect, useState } from 'react'
import styles from "./ParticularsOfDeductor.module.scss";
import { Button, Col, Form, Input, Row, Select } from "antd";
import request from '../../../../../../utils/axios';
import { setParticularsOfDeductor } from "../../../../../../redux/reducer/Form24SettingReducer";
import { useDispatch, useSelector } from 'react-redux';
function ParticularsOfDeductor({ prev, next }) {
  const dispatch = useDispatch()
  let particularsOfDeductor = useSelector((state) => state.form24Setting.particularsOfDeductor)
  console.log("particularsOfDeductor : ", particularsOfDeductor);

  const onFinish = (values) => {
    console.log("values : ", values);
    const data = {
      name: values.name,
      email: values.email,
      pincode: values.pincode,
      telephone_no: values.telephone_no,
      address_changed: values.address_changed,
      building: values.building,
      street: values.street,
      city: values.city,
      state: values.state,
      mobile_no: values.mobile_no,
      branch: values.branch,
      gstin: values.gstin,
      pan_no: values.pan_no
    }
    dispatch(setParticularsOfDeductor(values))
    next();
  }
  const [allStates, setAllStates] = useState([])
  const getStatesList = async () => {
    const data = await request({
      method: "get",
      url: `tax-management/admin/get-all-states`,
    })
    setAllStates(data.data)
  }
  useEffect(() => {
    getStatesList()
  }, [])

  return (
    <div className={styles.container}>
      
      <div className={styles.subContainer}>
        <p className={styles.subHeading}>Particulars Of the Deductor</p>
      </div>

      <div>
        <Form layout="vertical"
          onFinish={onFinish}
          initialValues={particularsOfDeductor}
          fields={[
            {
              name: ["name"],
              value: particularsOfDeductor?.name
            },
            {
              name: ["email"],
              value: particularsOfDeductor?.email
            },
            {
              name: ["address_changed"],
              value: particularsOfDeductor?.address_changed
            },
            {
              name: ["building"],
              value: particularsOfDeductor?.building
            },
            {
              name: ["pincode"],
              value: particularsOfDeductor?.pincode
            },
            {
              name: ["street"],
              value: particularsOfDeductor?.street
            },
            {
              name: ["city"],
              value: particularsOfDeductor?.city
            },
            {
              name: ["state"],
              value: particularsOfDeductor?.state
            },
            {
              name: ["gstin"],
              value: particularsOfDeductor?.gstin
            },
            {
              name: ["branch"],
              value: particularsOfDeductor?.branch
            },
            {
              name: ["telephone_no"],
              value: particularsOfDeductor?.telephone_no
            },
            {
              name: ["mobile_no"],
              value: particularsOfDeductor?.mobile_no
            },
            {
              name: ["pan_no"],
              value: particularsOfDeductor?.pan_no
            },
          ]}
        >
          <Row gutter={[48, 16]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Name"
                name="name"
              >
                <Input size='large' placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Has the Address Changed From the Last time"
                name="address_changed"
              >
                <Select
                  size='large'
                  placeholder="Select"
                >
                  <Select.Option value="0">Yes</Select.Option>
                  <Select.Option value="1">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Name Of Permises / Building"
                name="building"
              >
                <Input size='large' placeholder="Enter Name Of Permises / Building" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Street"
                name="street"
              >
                <Input size='large' placeholder="Enter Street" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="City"
                name="city"
              >
                <Input size='large' placeholder="Enter City" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="State"
                name="state"
              >
                <Select
                  className='dropDown'
                >
                  {
                    allStates.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                      )
                    }
                    )
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Pin Code"
                name="pincode"
              >
                <Input size='large' placeholder="Enter Pin Code" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="GSTIN"
                name="gstin"
              >
                <Input size='large' placeholder="Enter gstin" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Branch/Division"
                name="branch"
              >
                <Input size='large' placeholder="Enter Branch" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Telephone No."
                name="telephone_no"
              >
                <Input size='large' placeholder="Enter Telephone No." />
              </Form.Item>
            </Col>

            {/* <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Branch/Division"
                name="address_changed"
              >
                <Select
                  size='large'
                  placeholder="Select"
                >
                  <Select.Option value="1">Option 1</Select.Option>
                  <Select.Option value="2">Option 2</Select.Option>
                  <Select.Option value="3">Option 3</Select.Option>
                </Select>
              </Form.Item>
            </Col> */}
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="PAN No."
                name="pan_no"
              >
                <Input size='large' placeholder="Enter PAN No." />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Email"
                name="email"
              >
                <Input size='large' placeholder="Enter Email" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Mobile No."
                name="mobile_no"
              >
                <Input size='large' placeholder="Enter Mobile No." />
              </Form.Item>
            </Col>
          </Row>

          <button onClick={() => prev()}
            className={styles.prevBtn}
          > Prev</button>
          <button htmlType="submit"
            className={styles.nextBtn}
          >Next</button>
        </Form>
      </div>
    </div >
  )
}

export default ParticularsOfDeductor