// import { Button, Col, Row, Form } from 'antd'
import {
    Form,
    Col,
    DatePicker,
    Row,
    TimePicker,
    Button,
    Input,
    Modal,
    Select,
    Tabs
} from "antd";
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import "./SpecficJob.scss"
import "../../../../styles/_global.scss"
import request from '../../../../utils/axios';
import { toast } from "react-toastify";
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination';
import GiveFeedBack from '../../../Job/FeedBack/GiveFeedBack/GiveFeedBack'
// import ViewFeedBack from '../../FeedBack/ViewFeedBack/ViewFeedBack'
import viewFeedBack from "../../../../assets/viewFeedBack.svg"
import giveFeedBack from "../../../../assets/giveFeedBack.svg"
import CommonTable from "../../../../component/CommonTable/CommonTable";

import { saveAs } from 'file-saver';


function AdminJobDetails() {
    const params = useParams();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    const [data, setData] = useState([
        {
            name: "pankaj",
            email: "pankaj@gmail.com",
            education: "BTech-Mechanical",
            imgUrl: `https://images.unsplash.com/photo-1575936123452-b67c3203c357?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`,
            date: "20",
            applied_for: "Full Time",
            status: "selected",
            year_of_passing: "2020",
            checkOut: "20",
        }
    ]);
    const navigate = useNavigate();

    const [selectedApplicantId, setSelectedApplicantId] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const [currentFilter, setCurrentFilter] = useState("all");

    const showModal = (applicantId) => {
        console.log(applicantId, "ApplicantId");
        setSelectedApplicantId(applicantId);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleFormSubmit = async (values) => {
        // Format date and time values before sending the request
        const formattedValues = {
            ...values,
            interview_date: values.interview_date.format('YYYY-MM-DD'),
            interview_start_time: values.interview_start_time.format('HH:mm:ss'),
            interview_end_time: values.interview_end_time.format('HH:mm:ss')
        };

        try {
            await request({
                method: "post",
                url: `recruitment/admin/schedule-interview?applicant_id=${selectedApplicantId}`,
                data: formattedValues
            });

            setIsModalVisible(false);
            // Fetch updated data after scheduling the interview
            fetchRowData();
            toast.success("Interview scheduled Successfully", {
                position: "bottom-left",
            });
        } catch (error) {
            // Handle error if request fails
            console.error("Error scheduling interview:", error);
            // Optionally, display an error message to the user
            toast.error("Failed to schedule interview. Please try again later.", {
                position: "bottom-left",
            });
        }
    };


    const onHandleChangeForStatus = async (value, id) => {
        if (value === 1) {
            showModal(id); // Open the modal on click of "Interview Schedule" button
        }

        else {
            const data = await request({
                url: `recruitment/applicants/status/update?candidate_id=${id}`,
                method: "PUT",
                data: {
                    status: value
                }

            })
            toast.success("Status Updated successfully", {
                position: "bottom-left",
            })
            fetchData()
            fetchRowData()
        }
    }

    const columns = [
        {
            title: ' Name',
            dataIndex: 'imgUrl',
            key: 'a',
            render: (text, record) => <div style={{ display: "flex" }}>
                <div>
                    <p>{record.candidate_name}</p>
                </div>
            </div>
        },
        {
            title: 'Email',
            dataIndex: 'candidate_email',
            key: 'candidate_email',
        },
        {
            title: 'Phone no.',
            dataIndex: 'candidate_contact',
            key: 'candidate_contact',
        },
        {
            title: 'Linkdin URL',
            dataIndex: 'linkedin_url',
            key: 'linkedin_url',

        },
        {
            title: 'Resume',
            dataIndex: 'candidate_resume',
            key: 'candidate_resume',
            render: (text, record) =>
                <p style={{ backgroundColor: "#E2EDFF", color: "#0060FF", padding: "5px 0px", borderRadius: "15px", textAlign: "center", cursor: "pointer" }}
                    onClick={async () => {

                        try {
                            const response = await fetch(text);
                            const blob = await response.blob();
                            saveAs(blob, `resume_${record.candidate_name}.pdf`);
                        } catch (error) {
                            console.error('Error downloading PDF:', error);
                        }
                    }}
                // onClick={async () => {
                //     const response = await request({
                //         method: "get",
                //         url: `recruitment/resume/download/applicant/${record.id}/`,
                //         responseType: 'arraybuffer',
                //     })

                //     const file = new Blob([response], { type: 'application/pdf' });
                //     const url = window.URL.createObjectURL(file);
                //     const link = document.createElement('a');
                //     link.href = url;
                //     link.download = 'file.pdf';
                //     link.click();
                // }}
                >
                    Download
                </p>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            // render: (text, record) =>
            //     <p style={{ backgroundColor: "#DBFFE9", color: "#28B15F", padding: "5px 0px", borderRadius: "15px", textAlign: "center" }}>{record.status}</p>
            render: (text, record) => (
                <Select
                    value={record.status === null ? 4 : record.status}
                    onChange={(value) => onHandleChangeForStatus(value, record.id)}
                    style={{ width: "100%" }}
                >
                    {/* <Select.Option value={0}>Select</Select.Option> */}
                    <Select.Option value={0} >Shortlisted</Select.Option>
                    <Select.Option value={1}>Interview</Select.Option>
                    <Select.Option value={2}>Selected</Select.Option>
                    <Select.Option value={3}>Rejected</Select.Option>
                    <Select.Option value={4}>Pending</Select.Option>
                </Select>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => <div>
                <img
                    src={giveFeedBack}
                    alt="giveFeedBack"
                    onClick={() => {
                        navigate(`/admin/recruitment/details/${record.id}/feedback`, { replace: true })
                    }}
                    style={{ cursor: "pointer", marginRight: "10px" }} />
                <img
                    src={viewFeedBack}
                    alt="interview-schedule"
                    onClick={() => {
                        showModal(record.id); // Open the modal on click of "Interview Schedule" button
                    }}
                    style={{ cursor: "pointer" }}
                />
            </div>

        }
    ]

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [jobDetails, setJobDetails] = useState();
    const [applicants, setApplicants] = useState([]);

    const fetchData = async () => {
        const data = await request({
            method: "get",
            url: `recruitment/job-detail/${params.id}`,
        })


        setJobDetails(data.data.data)
    }

    const [applicantList, setApplicantList] = useState([]);

    const fetchRowData = async () => {
        const applicants = await request({
            method: "get",
            url: `recruitment/jobs/applicants?job_id=${params.id}&page=${pagination.current}&page_size=${pagination.pageSize}&type=${currentFilter}`,

        })

        setApplicantList(applicants?.pagination_data.data)
        setPagination({
            ...pagination,
            total: applicants?.pagination_data?.total
        })
    }
    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        fetchRowData();
    }, [pagination.current, currentFilter])

    const onHandleFilterChange = (value) => {
        setPagination({
            ...pagination,
            current: 1,
            pageSize: 5,
            total: 0,
        })
        setCurrentFilter(value)
    }

    const items = [
        {
            key: '1',
            label: `All Candidates (${jobDetails?.applicant_count})`,
            // label: 'All Candidates',
        },
        {
            key: '2',
            label: `Inprocess (${jobDetails?.in_progress_count})`,
            // label: 'All Candidates',

        },
        {
            key: '3',
            label: `Selected (${jobDetails?.selected_count})`,
            // label: 'All Candidates',

        },
        {
            key: '4',
            // label: 'Rejected',
            label: `Rejected (${jobDetails?.rejected_count})`,

        },
    ];

    const changeTabsValue = (key) => {
        setTabsValue(key);
        switch (key) {
            case '1':
                onHandleFilterChange("all");
                break;
            case '2':
                onHandleFilterChange("in_process");
                break;
            case '3':
                onHandleFilterChange("selected");
                break;
            case '4':
                onHandleFilterChange("rejected");
                break;
            default:
                onHandleFilterChange("all");
        }
    };
    const [tabsValue, setTabsValue] = useState("1")


    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className='speficJobContainer' style={{ width: "93%" }}>

                <Row style={{ backgroundColor: "white", padding: "20px", marginTop: "10px" }}>
                    <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                        <div>
                            <p className='heading'>{jobDetails?.job_title}</p>
                            {/* <p style={{ fontSize: "18px",color: "#535151" }}>
                                {jobDetails?.industry} / {jobDetails?.employee_type} / {jobDetails?.job_type}
                            </p> */}
                            <div dangerouslySetInnerHTML={{ __html: jobDetails?.job_description }} />
                        </div>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                        <div className='buttonContainer' >
                            <Button className='editBtn' onClick={() => { navigate(`/admin/recruitment/post-job/${params.id}`, { replace: true }) }}>Edit Job</Button>

                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <Tabs defaultActiveKey="1" items={items}
                    onChange={changeTabsValue}
                />
                {/* <Row> */}

                {/* <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                        <div className='flexSpaceBetween'>
                            <span className='jobTag' onClick={() => onHandleFilterChange("all")}>All Candidates ({jobDetails?.applicant_count})</span>
                            <span className='jobTag' onClick={() => onHandleFilterChange("in_process")}>Inprocess ({jobDetails?.in_progress_count})</span>
                            <span className='jobTag' onClick={() => onHandleFilterChange("selected")}>Selected ({jobDetails?.selected_count})</span>
                            <span className='jobTag' onClick={() => onHandleFilterChange("rejected")}>Rejected ({jobDetails?.rejected_count})</span>
                        </div>
                    </Col> */}
                {/* <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                    <img src="" alt="filter" />
                </Col> */}
                {/* </Row> */}
                {/* <br /> */}
                {/* <br /> */}
                {/* Simple div modal for scheduling interview */}
                {isModalVisible && (
                    <Modal
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        footer={null}
                        style={{
                            width: '100%',
                            height: '547px',
                            top: '20%',
                            borderRadius: '24px',
                        }}
                    >
                        <Form
                            layout="vertical"
                            onFinish={handleFormSubmit}
                            form={form}
                        >
                            <div style={{ padding: 0 }}>
                                <h1 style={{ fontSize: 22, marginBottom: "20px" }}>Meeting Scheduling</h1>
                                <Row>
                                    <Col xs={26} lg={9} style={{ marginRight: "5%" }}>
                                        <Form.Item
                                            label="Interview Date"
                                            name="interview_date"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter Interview Date!",
                                                },
                                            ]}
                                        >
                                            <DatePicker format={"YYYY-MM-DD"} style={{ width: "180px", height: "50px" }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={9} style={{ marginRight: "5%" }}>
                                        <Form.Item
                                            label="Start Time"
                                            name="interview_start_time"
                                            rules={[{ required: true, message: 'Please select a time!' }]}
                                        >
                                            <TimePicker format="HH:mm" style={{ width: '180px', height: "50px" }} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={26} lg={9} style={{ marginRight: "5%" }}>
                                        <Form.Item
                                            label="End Time"
                                            name="interview_end_time"
                                            rules={[{ required: true, message: 'Please select a time!' }]}
                                        >
                                            <TimePicker format="HH:mm" style={{ width: '180px', height: "50px" }} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={26} lg={9}>
                                        <Form.Item
                                            label="Interviewer"
                                            name="interviewer"
                                            rules={[
                                                { required: true, message: 'Please input an Interviewer!' },
                                                { type: 'text', message: 'Please enter a valid Employement ID!' },
                                            ]}
                                        >
                                            <Input style={{ width: "180px", height: "50px" }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{ width: "124px", height: "39px", borderRadius: "4px", backgroundColor: "#008080" }}>
                                        Schedule
                                    </Button>
                                </Form.Item>

                            </div>
                        </Form>
                    </Modal>
                )
                }
                <CommonTable
                    columns={columns}
                    data={applicantList}
                    pagination={pagination}
                    setPagination={setPagination}
                    handleRowClick={() => { }}
                />
            </div >
        </div>
    )
}

export default AdminJobDetails