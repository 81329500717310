import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination';
import request from '../../../../utils/axios';
import useScreenSize from '../../../../utils/getScreenSize';
import calendar from "../../../../assets/calendar.svg"
import location from "../../../../assets/location.svg"
import InfiniteScroll from '../../../../component/InfiniteScroll/InfiniteScroll';
import { useNavigate } from 'react-router-dom';
import back from "../../../../assets/Recruitment/back.svg"
// import "../ReferralOpening/ReferralOpening.scss"
import "../../User/ReferralOpening/ReferralOpening.scss"
import CommonTable from '../../../../component/CommonTable/CommonTable';
import { toast } from 'react-toastify';
import { Button, Col, DatePicker, Input, Modal, Popover, Row, Select, Switch, Table } from 'antd';
import mobileStyle from "./MobileInterviewFullView.module.scss";
import dayjs from 'dayjs';
import time from "../../../../assets/time.svg"
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';

function InterviewFullView() {
    const navigate = useNavigate();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    const schedulingColumns = [
        {
            title: 'Date',
            dataIndex: 'interview_date',
            key: 'interview_date',
        },
        {
            title: 'Candidate Name',
            dataIndex: 'candidate',
            key: 'candidate',
        },
        {
            title: 'Job Title',
            dataIndex: 'job_title',
            key: 'job_title',
        },
        {
            title: 'Timing',
            dataIndex: 'interview_time',
            key: 'interview_time',
        },
        {
            title: 'Scheduled By',
            dataIndex: 'scheduled_by',
            key: 'scheduled_by',
        }
    ]

    const screenSize = useScreenSize();

    const [interviewList, setinterviewList] = useState([])
    const fetchRowSchedulingData = async (page, page_size) => {

        const data = await request({
            method: "get",
            url: `recruitment/interviews/all?page=${pagination.current}&page_size=${pagination.pageSize}`,
        })
        setinterviewList(data?.pagination_data.data)
        // return data
        setPagination({
            ...pagination,
            total: data?.pagination_data?.total
        })
    }
    useEffect(() => {
        fetchRowSchedulingData()
    }, [pagination.current])

    const renderUI = (dataList) => {
        return dataList?.map((item, index) => {
            return (
                <>
                    <div key={index} className={mobileStyle.interviewContainer}>

                        <div>
                            <p className={mobileStyle.candidate}>
                                {item.candidate}
                            </p>
                            <p className={mobileStyle.jobTitle}>{item.job_title}</p>
                            <p className={mobileStyle.date}>
                                <img src={calendar} alt="calendar" width={20} height={20} />{" "}
                                {dayjs(item.interview_date).format("DD-MM-YYYY")}
                            </p>
                            <p className={mobileStyle.time}>
                                <img src={time} alt="time" width={20} height={20} />{" "}
                                {item?.interview_start_time}
                            </p>
                        </div>
                        <button className={mobileStyle.feedbackBtn}
                            onClick={() => navigate(`/recruitment/interview-schedule/feedback`, { replace: true })}
                        >Feedback</button>
                    </div>
                    <hr />
                </>
            )
        })
    }

    return (
        <div >
            {
                screenSize.width < 768 ?  // mobile view

                    
                    <div className={mobileStyle.interviewSchedule}>
                        <div className={mobileStyle.headerContainer}>
                            <p className={mobileStyle.heading}>Interview Schedule</p>
                            {/* <img src={fullView} alt="fullView" /> */}
                        </div>

                        <InfiniteScrollComponent
                            apiEndpoint="recruitment/interviews/all"
                            renderUI={renderUI}
                            initialPage={1}
                        />
                    </div>

                    :

                    <>
                        <div className='Container'>
                            {/* <h1>Interview Scheduling</h1> */}

                            <div className='referralSubContainer' style={{ "background": "white" }}>
                                <div >
                                    <CommonTable
                                        columns={schedulingColumns}
                                        data={interviewList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        handleRowClick={() => { }}
                                    />
                                </div>
                            </div>
                        </div>

                    </>

            }

        </div>
    )
}

export default InterviewFullView 