import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import styles from "./ChallanForm.module.scss";
import request from '../../../../../utils/axios';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setLoader } from '../../../../../redux/reducer/LoaderReducer';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

function ChallanForm() {
    const navigate = useNavigate();
    const disptach = useDispatch();
    const params = useParams();
    const ref = useRef();
    const [minorHeads, setMinorHeads] = useState([])
    const getMinorHeadList = async () => {
        const data = await request({
            method: "get",
            url: `tax-management/admin/get-all-minor-heads`,
        })
        setMinorHeads(data.data)
    }
    const [monthList, setMonthList] = useState([])
    const getMonthList = async () => {
        try {
            const data = await request({
                method: "get",
                url: `tax-management/admin/get-all-challan-months`,
            })
            console.log("data.............. : ", data)
            setMonthList(data.data)
        }
        catch (err) {
            console.log(err)
        }
    }
    const [quarterList, setQuarterList] = useState([])
    const getQuarterList = async () => {
        try {
            const data = await request({
                method: "get",
                url: `tax-management/admin/get-quarter-list`,
            })
            setQuarterList(data.data)
        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getMinorHeadList()
        getMonthList()
        getQuarterList()
    }, [])
    const onFinish = async (values) => {
        disptach(setLoader({ loading: true }))
        // if (params.id) {
        //     const data = await request({
        //         method: "post",
        //         url: `tax-management/admin/update-challan-details?id=${params.id}`,
        //         data: {
        //             ...values,
        //             voucher_date: values.voucher_date ? dayjs(values.voucher_date).format("YYYY-MM-DD") : null,
        //             payment_date: values.payment_date ? dayjs(values.payment_date).format("YYYY-MM-DD") : null
        //         }
        //     })
        //     toast.success("Challan details updated successfully", {
        //         position: "bottom-left",
        //     })
        // }
        // else {
            try {
                const data = await request({
                    method: "post",
                    url: `tax-management/admin/add-challan-details`,
                    data: {
                        ...values,
                        voucher_date: values.voucher_date ? dayjs(values.voucher_date).format("YYYY-MM-DD") : null,
                        payment_date: values.payment_date ? dayjs(values.payment_date).format("YYYY-MM-DD") : null
                    }
                })
                toast.success("Challan details submitted successfully", {
                    position: "bottom-left",
                })
                navigate("/admin/payroll", { replace: true });
            }
            catch (err) {
                console.log(err)
                toast.error(err.response.data.msg, {
                    position: "bottom-left",
                })
            }
        // }

        disptach(setLoader({ loading: false }))

    }
    const [challanForm, setChallanForm] = useState({})
    const fetchChallanDetails = async () => {
        try {
            const data = await request({
                method: "get",
                url: `tax-management/admin/get-challan-detail?id=${params.id}`,
            })
            console.log("data : ", data)
            setChallanForm(data.data)
            let payment_date;
            let voucher_date;
            if (data.data.payment_date !== null) {
                payment_date = new Date(data.data.payment_date).toISOString();
            }
            if (data.data.voucher_date !== null) {
                voucher_date = new Date(data.data.voucher_date).toISOString();
            }
            setChallanForm({
                ...challanForm,
                ...data.data,
                payment_date: data.data.payment_date && dayjs(payment_date),
                voucher_date: data.data.voucher_date && dayjs(voucher_date)
            });
        }
        catch (error) {
            console.log("error : ", error)
        }
    }
    useEffect(() => {
        if (params.id) {
            fetchChallanDetails()
        }
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, [])

    return (
        <div className={styles.challanFormContainer} ref={ref}>
            <p className={styles.heading}>Payroll</p>
            <div className={styles.container}>
                <p className={styles.subHeading}>Challan</p>
                <div>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        initialValues={challanForm}
                        fields={[
                            {
                                name: ['payroll_Month'],
                                value: challanForm.payroll_Month
                            },
                            {
                                name: ['quarter'],
                                value: challanForm.quarter
                            },
                            {
                                name: ['pending_cess'],
                                value: challanForm.pending_cess
                            },
                            {
                                name: ['pending_income_tax'],
                                value: challanForm.pending_income_tax
                            },
                            {
                                name: ['pending_surcharge'],
                                value: challanForm.pending_surcharge
                            },
                        ]}
                    >
                        <Row gutter={[48, 4]}>
                            <Col span={12}>
                                <Form.Item
                                    label="Payroll Month"
                                    name="payroll_Month"
                                >
                                    <Select
                                        className='dropDown'
                                        size='large'
                                    >
                                        {
                                            monthList.map((item, index) => {
                                                return (
                                                    <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                                                )
                                            }
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Financial Quarters"
                                    name="quarter"
                                >
                                    <Select
                                        className='dropDown'
                                    >
                                        {
                                            quarterList.map((item, index) => {
                                                return (
                                                    <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                                                )
                                            }
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Challan Serial No."
                                    name="challan_serial_no"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Challan Serial No.',
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Total Challan Amount"
                                    name="total_challan_amount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Total Challan Amount',
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Voucher Date"
                                    name="voucher_date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Voucher Date',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        className='leaveInputField'
                                    // onChange={(voucher_date, dateString) => voucher_date}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="BSR Code"
                                    name="bsr_code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter BSR Code',
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Bank Name"
                                    name="bank_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Bank Name',
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Income Tax Amount"
                                    name="pending_income_tax"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter TDS Amount',
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Surcharge"
                                    name="pending_surcharge"
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Health & Education Cess"
                                    name="pending_cess"
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Interest"
                                    name="interest"
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Cheque / DD No."
                                    name="cheque_no"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter check no.',
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Payment Date"
                                    name="payment_date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Payment Date',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        className='leaveInputField'
                                        onChange={(payment_date, dateString) => payment_date}
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col span={12}>
                                <Form.Item
                                    label="Deduction Date"
                                    name="deduction_date"
                                >
                                    <DatePicker
                                        className='leaveInputField'
                                        onChange={(deduction_date, dateString) =>  deduction_date}
                                />
                                </Form.Item>
                            </Col> */}
                            <Col span={12}>
                                <Form.Item
                                    label="Ack No."
                                    name="acknowlege_no"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter ack no.',
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Minor Head Code"
                                    name="minor_head"
                                >
                                    <Select
                                        className='dropDown'
                                        size='large'
                                    >
                                        {
                                            minorHeads.map((item, index) => {
                                                return (
                                                    <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                                                )
                                            }
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Late Pay Fee"
                                    name="late_pay_fees"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter late pay fee.',
                                        },
                                    ]}
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Others Penalty"
                                    name="other_penalty"
                                >
                                    <Input
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <button htmlType='submit' className={styles.saveBtn} >Save</button>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default ChallanForm