import React, { useEffect, useState } from "react";

import DynamicTable from "../../../../component/TableWithPagination/TableWithPagination";
import request from "../../../../utils/axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import back from "../../../../assets/Recruitment/back.svg";
import useScreenSize from "../../../../utils/getScreenSize";
import ApplyJob from "../ApplyJob/ApplyJob";
import calendar from "../../../../assets/calendar.svg";
import time from "../../../../assets/time.svg";
import location from "../../../../assets/location.svg";

import "./InternalJobOpening.scss";
import CardListWithInfiniteScroll from "../../../../component/InfiniteScroll/InfiniteScroll";
import mobileStyle from "./MobileInternalJobOpening.module.scss";
import "../../User/ReferralOpening/ReferralOpening.scss";
import ApplyReferCardList from "../../../../component/ApplyReferCardList/ApplyReferCardList";
import dayjs from "dayjs";
import InfiniteScrollComponent from "../../../../component/InfiniteScroll/InfiniteScroll";
import { Table } from "antd";
function InternalJobOpening() {
  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const internalJobColumns = [
    {
      title: "Date",
      dataIndex: "posting_date",
      key: "posting_date",
      render: (text, record) => (
        <span>{moment(text).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Posted By",
      dataIndex: "posted_by",
      key: "posted_by",
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
    },
    {
      title: "Funtion",
      dataIndex: "job_function",
      key: "job_function",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <ApplyJob
          title={"Apply"}
          jobId={record.id}
          btnContent="Apply"
          loginUserData={loginUserDetails}
        />
      ),
    },
  ];

  const [internalJobOpening, setInternalJobOpening] = useState([]);

  const fetchRowInternalJobOpeningData = async (page, page_size) => {
    const data = await request({
      method: "get",
      url: `recruitment/active-jobs?page=${page}&page_size=${page_size}`,
    });
    setInternalJobOpening([...data.pagination_data.data]);

    return data;
  };

  const [loginUserDetails, setLoginUserDetails] = useState({
    candidate_name: "",
    candidate_email: "",
    candidate_contact: "",
    current_company: "",
    candidate_resume: "",
    linkedin_url: "",
  });
  const fetchUserData = async () => {
    const employee_number = localStorage.getItem("employee_number");

    let data = await request({
      method: "get",
      url: `profile/details/`,
    });

    setLoginUserDetails({
      ...loginUserDetails,
      candidate_name: data.data.data.employee_name,
      candidate_email: data.data.data.official_email,
      candidate_contact: data.data.data.contact,
    });
  };

  useEffect(() => {
    fetchUserData();
    if (screenSize.width > 768) {
      fetchRowInternalJobOpeningData(1, 10);
    }
  }, []);

  const renderUI = (dataList) => {
    return dataList?.map((item, index) => {
      return (
        <>
          <div key={index} className={mobileStyle.internalJobOpeningContainer}>
            <div>
              <p className={mobileStyle.candidate}>{item.job_title}</p>
              <p className={mobileStyle.jobTitle}>{item.industry}</p>
              <p className={mobileStyle.date}>
                <img src={calendar} alt="calendar" width={20} height={20} />{" "}
                {dayjs(item.posting_date).format("DD-MM-YYYY")}
              </p>
              <p className={mobileStyle.time}>
                <img src={time} alt="time" width={20} height={20} />{" "}
                {item?.job_function}
              </p>
              <p className={mobileStyle.location}>
                <img src={location} alt="location" width={20} height={20} />{" "}
                {item?.location[0]}
              </p>
            </div>
            <button
              className={mobileStyle.applyBtn}
              onClick={() => {
                navigate(`/recruitment/apply-form/${item.id}`, {
                  replace: true,
                });
              }}
            >
              Apply
            </button>
          </div>
          <hr />
        </>
      );
    });
  };

  const renderUIDesktop = (dataList) =>{
    return (
      <Table
        columns={internalJobColumns}
        dataSource={dataList}
        pagination={false}
      />
    )
  }

  return (
    <div>
      {screenSize.width < 768 ? (
        // mobile view
        <div className={mobileStyle.internalJobOpening}>
          <div className={mobileStyle.headerContainer}>
            <p className={mobileStyle.heading}>Internal Job Openings</p>
          </div>

          <InfiniteScrollComponent
            apiEndpoint="recruitment/active-jobs"
            renderUI={renderUI}
            initialPage={1}
          />
        </div>
      ) : (
        <div className="Container">
          {/* <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
              <img src={back} alt="back" />
              <span style={{ paddingLeft: "10px" }}>Back</span>
            </p>
            <h1>Internal Job Openings</h1> */}
          <div className="referralSubContainer">
            <div>
              {/* <DynamicTable
                fetchData={fetchRowInternalJobOpeningData}
                columns={internalJobColumns}
                handleRowClick={() => {}}
                pageSize={5}
                showPagination={true}
              /> */}

              <InfiniteScrollComponent
                apiEndpoint="recruitment/active-jobs"
                renderUI={renderUIDesktop}
                initialPage={1}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InternalJobOpening;
