import { Button, Col, Input, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import request from '../../../../../utils/axios'
import { useParams } from 'react-router-dom'
import update from 'immutability-helper';
import style from "./EmployeeDeclarationDetail.module.scss";
import declarationIcon from "../../../../../assets/Payroll/Admin/DeclarationIcon.png"
import { toast } from 'react-toastify';
import { setLoader } from '../../../../../redux/reducer/LoaderReducer';
import { useDispatch } from 'react-redux';

function EmployeeDeclarationDetail() {
    const disptach = useDispatch();
    const params = useParams()
    const [declarationList, setDeclarationList] = useState([])
    const [showDeclarationDetails, setShowDeclarationDetails] = useState(null)
    const [showSubDeclarationDetails, setShowSubDeclarationDetails] = useState(null)
    const [specificdeclarationDetails, setSpecificDeclarationDetails] = useState({})
    const [approvedAmount, setApprovedAmount] = useState(null)
    const ref = useRef(null);
    const fetchDeclarationList = async () => {
        try {
            let data = await request({
                method: "get",
                url: `investment-declaration/admin/get-employee-declaration-list?employee_number=${params.id}`,
            })

            setDeclarationList(data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const [sectionName, setSectionName] = useState({
        id: "",
        name: ""
    })

    const fetchSubDeclarationList = async (id, name) => {
        try {
            let data = await request({
                method: "get",
                url: `investment-declaration/admin/get-employees-for-declaration-review?id=${id}&section=${name}&employee_number=${params.id}`,
            })

            setSpecificDeclarationDetails(data.data)
            setApprovedAmount(data.data.approved_amount)
        }
        catch (error) {
            console.log(error)
        }
    }

    const acceptOrReject = async (btnValue) => {
        try {
            if (specificdeclarationDetails.declared_amount < approvedAmount) {
                toast.error("Approved Amount cannot be greater than Request Amount", {
                    position: "bottom-left",
                })
            }
            else {
                let data = await request({
                    method: "post",
                    url: `investment-declaration/admin/approved-declaration-amount`,
                    data: {
                        id: sectionName.id,
                        section: sectionName.name,
                        approved_amount: approvedAmount,
                        status: btnValue === "approve" ? 2 : 3
                    }
                })
                console.log(data.data)

                fetchDeclarationList()
            }

        }
        catch (error) {
            console.log(error)
        }
    }

    const calculateFinalIncomeTax = async () => {
        disptach(setLoader({ loading: true }))
        try {
            await request({
                method: "post",
                url: `/regim_tax_rules/final-incomeTax-calculation`,
                data: {
                    employee_number: declarationList?.employee_number
                }
            })
            toast.success("Final Income Tax calculation submitted successfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log(error)
            toast.error(error.response.data.msg, {
                position: "bottom-left",
            })
        }
        disptach(setLoader({ loading: false }))
    }
    const bulkApprovedDeclaredAmount = async () => {
        disptach(setLoader({ loading: true }))
        try {
            await request({
                method: "post",
                url: `regim_tax_rules/admin/approved-bulk-declaration-amount`,
                data: {
                    employee_number: declarationList?.employee_number
                }
            })
            toast.success("Declared Amount Approved successfully", {
                position: "bottom-left",
            })
        }
        catch (error) {
            console.log(error)
            toast.error("Unable to approve declared amount, please try after some time", {
                position: "bottom-left",
            })
        }
        disptach(setLoader({ loading: false }))
        fetchDeclarationList()
    }

    useEffect(() => {
        fetchDeclarationList()
    }, [])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div className={style.employeeDeclaration} ref={ref}>
            <p className={style.heading} >Tax Declaration Details</p>
            <Row gutter={[32, 32]}>
                <Col span={6}>
                    <p style={{ fontSize: "24px", fontWeight: "600", margin: "0px" }}>
                        {declarationList?.username}
                    </p>
                </Col>
                <Col span={18}>
                   
                    <div style={{ display: "flex", justifyContent: "end", padding: "0px 2vw" , gap: "20px"}}>
                    <Button size='large' style={{ color: "white", background: "#008080" }}
                            onClick={() => bulkApprovedDeclaredAmount()}
                        >
                            Bulk Approve
                        </Button>
                        <Button size='large' style={{ color: "white", background: "#008080" }}
                            onClick={() => calculateFinalIncomeTax()}
                        >
                            Submit Approval
                        </Button>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={style.boxFixedHeightContainer}>
                        {
                            declarationList?.data?.map((item, index) => {
                                return (
                                    <>
                                        <div
                                            onClick={() => {
                                                if (showDeclarationDetails === index) {
                                                    setShowDeclarationDetails(null)
                                                } else {
                                                    setShowDeclarationDetails(index)
                                                }
                                                setShowSubDeclarationDetails(null)
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                            }}
                                            className={showDeclarationDetails === index ? style.selectedDeclarationListCard : style.declarationListCard}
                                        >
                                            <img src={declarationIcon} alt="icon" width={26} height={26} />
                                            <div>
                                                <p style={{ cursor: "pointer", margin: "0px", fontSize: "14px", fontWeight: "400", color: "#6B6B6B" }}>{item?.name}</p>
                                                <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "3px" }}>
                                                    <span className={style.approvedStatus} >
                                                        Approve ( {item?.approved_count} )
                                                    </span>
                                                    <span className={style.pendingStatus} >
                                                        Pending  ( {item?.pending_count} )
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            showDeclarationDetails === index &&
                                            <>
                                                {
                                                    item?.detail?.map((el, elIndex) => {
                                                        return (
                                                            <div key={elIndex}
                                                                className={showSubDeclarationDetails === el.id ? style.selectedSubDeclarationListCard : style.subDeclarationListCard}
                                                                onClick={() => {
                                                                    setShowSubDeclarationDetails(el.id)
                                                                    setSectionName({ id: el.id, name: el.section })
                                                                    fetchSubDeclarationList(el.id, el.section)
                                                                }}
                                                            >
                                                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                    <img src={declarationIcon} alt="icon" width={14} height={14} />
                                                                    <p style={{ cursor: "pointer", margin: "0px", fontSize: "14px", fontWeight: "400", color: "#6B6B6B" }}>{el.name}</p>
                                                                </div>
                                                                <div>
                                                                    <span
                                                                        className={el.status === 1 ? style.pendingStatus : el.status === 2 ? style.approvedStatus : style.rejectedStatus}
                                                                    >
                                                                        {el.status === 1 ? "Pending" : el.status === 2 ? "Approved" : "Rejected"}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                </Col>
                <Col span={18}>
                    <div className={style.boxFixedHeightContainer} style={{ padding: "0px 2vw", }}>
                        <p className={style.declarationName}>
                            {showDeclarationDetails !== null && declarationList?.data[showDeclarationDetails]?.name}
                        </p>
                        <Row>
                            <Col span={12}>
                                <p className={style.label}>
                                    Max Limit :
                                    <span className={style.amount}>
                                        {specificdeclarationDetails?.maximum_limit === 'No Limit' ? 'No Limit' : specificdeclarationDetails?.maximum_limit && `₹ ${specificdeclarationDetails?.maximum_limit}`}
                                    </span>
                                </p>
                                <p className={style.label}>
                                    Amount Requested :
                                    <span className={style.amount}>
                                        ₹ {specificdeclarationDetails.declared_amount}
                                    </span>
                                </p>
                            </Col>

                            <Col span={12}>
                                <p className={style.label}>Amount Approved </p>
                                <Input
                                    placeholder="Enter Amount"
                                    size='large'
                                    onChange={(e) => {
                                        setApprovedAmount(e.target.value)
                                    }}
                                    value={approvedAmount}
                                />
                            </Col>
                            <Col span={24}>
                                <div className={style.btns}>
                                    <Button className={style.acceptBtn} onClick={() => acceptOrReject("approve")}>
                                        Accept
                                    </Button>
                                    <Button className={style.rejectBtn} onClick={() => acceptOrReject("reject")}>
                                        Reject
                                    </Button>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className={style.attachment}>
                                    <embed

                                        src={specificdeclarationDetails?.attachment}
                                        // src={pdfUrl}
                                        type="application/pdf"
                                        width="100%"
                                        height="600px"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default EmployeeDeclarationDetail