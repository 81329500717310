import { Button, Col, Form, Input, Row, Select, Spin, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import request from '../../../utils/axios'
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

function DocumentPolicyForm({ modelFor }) {
    const [form] = Form.useForm()
    const navigate = useNavigate();

    const [File, setFile] = useState(null)
    const [requireSign, setRequireSign] = useState(false);

    const [policyLoader, setPolicyLoader] = useState(false);

    const onFinish = (values) => {
        const formData = new FormData();
        setPolicyLoader(true)
        try {
            formData.append("name", values.name);
            formData.append("file_type", values.file_type);
            formData.append("document", File);
            formData.append("is_signed_required", requireSign);

            request({
                method: "post",
                url: `/documentation/policy/document/upload`,
                data: formData
            })
        }
        catch (error) {
            console.log("error")
        }
        finally {
            form.resetFields()
            setFile(null)
            setPolicyLoader(false)
            navigate("/admin/docs", { replace: true })
        }

    }

    const [fileTypeList, setFileType] = useState([])
    const fetchFileType = async () => {
        try {
            const data = await request({
                method: "get",
                url: `/documentation/file_type/all?type=PolicyDocuments`,
            })
            setFileType(data)
        }
        catch (error) {
            console.log("error");
        }
    }


    useEffect(() => {
        fetchFileType();
    }, [])

    return (
        <div style={{ padding: "16px", background: "white" }}>
            <p style={{ fontSize: "20px", fontWeight: "600" }}>New Policy</p>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            // onValuesChange={onValuesChange}
            >
                <Row gutter={[8, 8]}>
                    <Col xs={{ span: 24 }}>
                        <Form.Item
                            label="File Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your File Name!',
                                },
                            ]}
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label="File Type"
                            name="file_type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your File Type!',
                                },
                            ]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                size='large'
                            >
                                {
                                    fileTypeList?.map((option) => {
                                        return (
                                            <Select.Option value={option.value} >
                                                {option.label}
                                            </Select.Option>

                                        )
                                    })
                                }
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <p>Upload File</p>
                        <Form.Item
                            label="Upload File"
                            name="end_time"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your Date (To)!',
                                },
                            ]}
                        >
                            <div className="file-upload-container">
                                <input
                                    type="file"
                                    id="file-upload"
                                    className="input-file"
                                    onChange={(e) => {
                                        const selectedFile = e.target.files[0];
                                        const maxSizeInBytes = 5 * 1024 * 1024;

                                        if (selectedFile && selectedFile.type === 'application/pdf') {
                                            if (selectedFile.size <= maxSizeInBytes) {
                                                setFile(e.target.files && selectedFile)
                                            }
                                            else {
                                                setFile(null);
                                                alert('File size exceeds the limit of 5MB.');
                                            }
                                        } else {
                                            // Reset the file state and show an error message
                                            setFile(null);
                                            alert('Please upload a CSV file.');
                                        }
                                    }}
                                />
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                    <label htmlFor="file-upload" className="file-upload-label">

                                    </label>
                                    {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                    <p style={{ zIndex: 1, margin: "0px" }}>{File ? File.name : 'Drop Your File Here'}</p>
                                </div>
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div style={{ marginTop: "60px" }}>
                            <Switch
                                checked={requireSign}
                                size='small'
                                onChange={(checked) => setRequireSign(checked)}
                            />
                            {" "}
                            <span style={{ color: "#9F9F9F", fontSize: "12px" }}>Requires Signing Off By Employee</span>
                        </div>
                    </Col>
                </Row>



                <Button
                    htmlType='submit'
                    style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                >
                    {
                        policyLoader
                            ?
                            <Spin
                                indicator={
                                    <LoadingOutlined
                                        style={{ fontSize: "15px", color: "white" }}
                                        spin
                                    />
                                }
                            />
                            :
                            "Submit"
                    }
                </Button>
            </Form>
        </div>
    )
}

export default DocumentPolicyForm