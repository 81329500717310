import React, { useEffect, useState } from 'react';
import styles from "./Salary.module.scss";
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import request from '../../../utils/axios';
import { useParams } from 'react-router-dom';
import edit from "../../../assets/edit.svg"
import TextArea from 'antd/es/input/TextArea';
import { toast } from 'react-toastify';

function Salary() {
  const params = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [salaryDetails, setSalaryDetails] = useState({
    bank_name: '',
    ifsc_code: '',
    account_number: '',
    payment_mode: '',
    pan_no: '',
    payment_mode_id: '',
    uan_no: '',
  })

  const onFinish = async (values) => {
    try {
      const data = await request({
        method: "post",
        url: `/profile/bank/details/update?id=${salaryDetails.id}`,
        data: values
      })
      console.log(data)
      setIsEdit(false)
      fetchBankDetails()
      toast.success("Bank Details Updated Successfully", {
        position: "bottom-left",
      });
    }
    catch (err) {
      console.log(err)
      toast.error("Bank Details could not be updated, please try after some time", {
        position: "bottom-left",
      })
    }
  }

  const fetchBankDetails = async () => {
    try {

      let data;
      if (params?.id) {
        // console.log("on_admin_click")
        data = await request({
          method: "get",
          url: `/admin/profile/bank/details?employement_number=${params.id}`,
        })

      }
      else {
        data = await request({
          method: "get",
          url: `/profile/bank/details/`,
        })

      }

      setSalaryDetails(data.data.data)
    }
    catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    fetchBankDetails();
  }, [])
  const [salaryBreakup, setSalaryBreakup] = useState()
  const fetchSalaryBreakup = async () => {
    try {
      if (params?.id) {
        let data = await request({
          method: "get",
          url: `payroll/admin/profile/salary-breakup?employement_number=${params.id}`,
        })
        console.log(data)
        setSalaryBreakup(data?.data)
      }
      else {
        let data = await request({
          method: "get",
          url: `payroll/profile/salary-breakup`,
        })
        console.log(data)
        setSalaryBreakup(data?.data)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchSalaryBreakup();
  }, [window.location.pathname])


  return (
    <div className={styles.salaryDetailsContainer}>
      <div className={styles.salaryDetailsInnerContainer} style={{ marginBottom: "20px" }}>
        <div className='flexSpaceBetween'>
          <p className={styles.heading}>Bank Details</p>
          {
            !params.id && <img
              src={edit}
              alt={edit}
              width={25}
              height={25}
              style={{ cursor: "pointer" }}
              onClick={() => setIsEdit(!isEdit)}
            />
          }

        </div>
        {/* <p className={styles.heading}>Bank Details</p> */}

        <Row>

          <Col span={8}>
            <p className={styles.keyText}>Bank Name</p>
          </Col>
          <Col span={16}>
            <p className={styles.valueText}>{salaryDetails.bank_name}</p>
          </Col>

          <Col span={8}>
            <p className={styles.keyText}>IFSC Code</p>
          </Col>
          <Col span={16}>
            <p className={styles.valueText}>{salaryDetails.ifsc_code}</p>
          </Col>

          <Col span={8}>
            <p className={styles.keyText}>Account No.</p>
          </Col>
          <Col span={16}>
            <p className={styles.valueText}>{salaryDetails.account_number}</p>
          </Col>

          <Col span={8}>
            <p className={styles.keyText}>Mode Of Payment</p>
          </Col>
          <Col span={16}>
            <p className={styles.valueText}>{salaryDetails.payment_mode_display}</p>
          </Col>
          <Col span={8}>
            <p className={styles.keyText}>Pan No.</p>
          </Col>
          <Col span={16}>
            <p className={styles.valueText}>{salaryDetails.pan_no}</p>
          </Col>
          <Col span={8}>
            <p className={styles.keyText}>UAN No.</p>
          </Col>
          <Col span={16}>
            <p className={styles.valueText}>{salaryDetails.uan_no}</p>
          </Col>

        </Row>
      </div>
      <div className={styles.salaryDetailsInnerContainer}>
        <Col span={8}>
          {/* <p className={styles.heading}>Payroll</p> */}
        </Col>
        {/* <p>Payroll</p> */}
        <div>
          <Col span={16}>
            <p className={styles.heading}>Salary Breakup</p>
          </Col>
          {/* <p>Salary Breakup</p> */}
          <Row>
            <Col span={16}>
              <p style={{
                fontSize: "18px",
                fontWeight: "600",
              }}>
                Component
              </p>
            </Col>
            <Col span={4}>
              <p style={{
                fontSize: "18px",
                fontWeight: "600",
              }}>
                Annually
              </p>
            </Col>
            <Col span={4}>
              <p style={{
                fontSize: "18px",
                fontWeight: "600",
              }}>Monthly</p>
            </Col>
          </Row>
          <Row>
            {
              salaryBreakup?.fixed.length > 0 &&
              <Col span={24}>
                <p style={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}>Fixed</p>
              </Col>
            }

            {
              salaryBreakup?.fixed.map((item, index) => {
                return (
                  <>
                    <Col span={16}><p className={styles.keyText}>{item?.name}</p></Col>
                    <Col span={4}><p className={styles.valueText}>₹ {item?.yearly_amount}</p></Col>
                    <Col span={4}><p className={styles.valueText}>₹ {item?.monthly_amount}</p></Col>
                  </>
                )
              })
            }
          </Row>
          <Row>
            {
              salaryBreakup?.flexi.length > 0 &&
              <Col span={24}>
                <p style={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}>Flexi</p>
              </Col>
            }

            {
              salaryBreakup?.flexi.map((item, index) => {
                return (
                  <>
                    <Col span={16}><p className={styles.keyText}>{item?.name}</p></Col>
                    <Col span={4}><p className={styles.valueText}>₹ {item?.yearly_amount}</p></Col>
                    <Col span={4}><p className={styles.valueText}>₹ {item?.monthly_amount}</p></Col>
                  </>
                )
              })
            }
          </Row>
          <Row>
            {
              salaryBreakup?.variable.length > 0 &&
              <Col span={24}>
                <p style={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}>Variable</p>
              </Col>
            }

            {
              salaryBreakup?.variable.map((item, index) => {
                return (
                  <>
                    <Col span={16}><p className={styles.keyText}>{item?.name}</p></Col>
                    <Col span={4}><p className={styles.valueText}>₹ {item?.yearly_amount}</p></Col>
                    <Col span={4}><p className={styles.valueText}>₹ {item?.monthly_amount}</p></Col>
                  </>
                )
              })
            }
          </Row>
          <Row>
            {
              salaryBreakup?.retirals.length > 0 &&
              <Col span={24}>
                <p style={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}>Retirals</p>
              </Col>
            }

            {
              salaryBreakup?.retirals.map((item, index) => {
                return (
                  <>
                    <Col span={16}><p className={styles.keyText}>{item?.name}</p></Col>
                    <Col span={4}><p className={styles.valueText}>₹ {item?.yearly_amount}</p></Col>
                    <Col span={4}><p className={styles.valueText}>₹ {item?.monthly_amount}</p></Col>
                  </>
                )
              })
            }
          </Row>
        </div>

      </div>
      <Modal
        title="Bank Details"
        centered
        open={isEdit}
        footer={null}
        onCancel={() => setIsEdit(!isEdit)}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          // onValuesChange={handleFormChange}
          initialValues={salaryDetails}
          fields={[
            {
              name: ["bank_name"],
              value: salaryDetails.bank_name
            },
            {
              name: ["ifsc_code"],
              value: salaryDetails.ifsc_code
            },
            {
              name: ["payment_mode"],
              value: salaryDetails.payment_mode_id
            },
            {
              name: ["account_number"],
              value: salaryDetails.account_number
            },
            {
              name: ["pan_no"],
              value: salaryDetails.pan_no
            },
            {
              name: ["uan_no"],
              value: salaryDetails.uan_no,
            }
          ]}

        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Bank Name"
                name="bank_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Bank Name!',
                  },
                ]}
              >
                <Input
                  size='large'
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="IFSC Code"
                name="ifsc_code"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your IFSC code!!',
                  },
                ]}
              >
                <Input
                  size='large'
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Account No."
                name="account_number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Account No.!',
                  },
                ]}
              >
                <Input
                  size='large'
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>


            <Col span={24}>
              <Form.Item
                label="Mode Of Payment"
                name="payment_mode"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Mode Of Payment!',
                  },
                ]}
              >
                <Select
                  size='large'
                // onChange={handleMaritalStatusChange}
                >
                  <Select.Option value={0}>Bank Transfer</Select.Option>
                  <Select.Option value={1}>Cheques</Select.Option>
                  <Select.Option value={2}>Cash</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Pan No."
                name="pan_no"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Pan No.!',
                  },
                ]}
              >
                <Input
                  size='large'
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="UAN No."
                name="uan_no"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your UAN No.!',
                  },
                ]}
              >
                <Input
                  size='large'
                  style={{ width: "80%" }}
                />
              </Form.Item>
            </Col>

          </Row>

          <Button
            htmlType='submit'
            style={{ width: "100%", height: "47px", background: "#008080", color: "white" }}
          >
            Save
          </Button>
        </Form>
      </Modal >

    </div>
  )
}

export default Salary