import { createSlice } from '@reduxjs/toolkit'


const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState: {
        personalDetails: {
            employee_name: '',
            personal_email: '',
            contact: '',
            address: '',
            dob: '',
            gender: '',
            marital_status: '',
            anniversary: '',
        },
        employmentDetails: {
            designation: '',
            reporting_manager: '',
            joining_date: '',
            employee_type: '',
            department: '',
            work_mode: '',
            work_location: '',
            notice_period: '',
            employee_number: '',
            empfunction: ''
        },
        bankDetails: {
            bank_name: '',
            ifsc_code: '',
            account_no: '',
            mode_of_payment: '',
            pan_no: '',
            uan_no: ''
        },
        comanyPolicy: []

    },
    reducers: {
        setPersonalDetails: (state, action) => {
            const {
                employee_name,
                personal_email,
                contact,
                address,
                dob,
                gender,
                marital_status,
                anniversary
            } = action.payload;

            state.personalDetails.employee_name = employee_name;
            state.personalDetails.personal_email = personal_email;
            state.personalDetails.contact = contact;
            state.personalDetails.address = address;
            state.personalDetails.dob = dob;
            state.personalDetails.gender = gender;
            state.personalDetails.marital_status = marital_status;
            state.personalDetails.anniversary = anniversary;
        },

        setEmploymentDetails: (state, action) => {
            const {
                designation,
                reporting_manager,
                joining_date,
                employee_type,
                department,
                work_mode,
                work_location,
                notice_period,
                employee_number,
                empfunction
            } = action.payload;

            state.employmentDetails.designation = designation;
            state.employmentDetails.reporting_manager = reporting_manager;
            state.employmentDetails.joining_date = joining_date;
            state.employmentDetails.employee_type = employee_type;
            state.employmentDetails.department = department;
            state.employmentDetails.work_mode = work_mode;
            state.employmentDetails.work_location = work_location;
            state.employmentDetails.notice_period = notice_period;
            state.employmentDetails.employee_number = employee_number;
            state.employmentDetails.empfunction = empfunction;
        },
        setBankDetails: (state, action) => {
            const {
                bank_name,
                ifsc_code,
                account_no,
                mode_of_payment,
                pan_no,
                uan_no
            } = action.payload;

            state.bankDetails.bank_name = bank_name;
            state.bankDetails.ifsc_code = ifsc_code;
            state.bankDetails.account_no = account_no;
            state.bankDetails.mode_of_payment = mode_of_payment;
            state.bankDetails.pan_no = pan_no;
            state.bankDetails.uan_no = uan_no;
        },
        companyPolicyDetails: (state, action) => {
            console.log("action.payload....", action.payload);
        }
    },
})
export const { setPersonalDetails, setEmploymentDetails, setBankDetails } = onboardingSlice.actions;

export default onboardingSlice.reducer;