import { createSlice } from "@reduxjs/toolkit";

const loadingSlice = createSlice({
    name: 'loader',
    initialState: false,
    reducers: {
        setLoader: (state, action) => {
            return action.payload.loading;
        },
    },
})


export const { setLoader } = loadingSlice.actions;

export default loadingSlice.reducer;