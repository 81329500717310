import { Button, Input, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import request from '../../utils/axios'
import { useNavigate } from 'react-router-dom';
// import TableWithPagination from '../../../component/TableWithPagination/TableWithPagination';
import edit from "../../assets/edit.svg";
import share from "../../assets/share.svg"
import calendar from "../../assets/calendar.svg";
import location from "../../assets/location.svg";
// import "./AdminJobList.scss"

import useScreenSize from "../../utils/getScreenSize"
// import DynamicTable from '../../..component/TableWithPagination/TableWithPagination';
import DynamicTable from '../../component/TableWithPagination/TableWithPagination';

import moment from 'moment';
import InfiniteScroll from '../../component/InfiniteScroll/InfiniteScroll';

import ApplyJob from "./ExternalApplyJob/externalApplyJob";

import ExternalRecruitmentBgImage from "../../assets/Recruitment/External Recruitment/external-recruitment.svg"
import RecruitmentHeader from './RecruitmentHeader/RecruitmentHeader';
import CommonTable from '../../component/CommonTable/CommonTable';
function ExternalRecruitment() {
    // const [jobList, setJobList] = useState(null);ExternalRecruitment
    const navigate = useNavigate();
    const screenSize = useScreenSize();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    const columns = [
        {
            title: 'Date',
            dataIndex: 'posting_date',
            key: 'posting_date',
            render: (text, record) => <span>
                {
                    moment(text).format('DD-MM-YYYY')
                }
            </span>
        },
        {
            title: 'Job Title',
            dataIndex: 'job_title',
            key: 'job_title',
        },
        {
            title: 'Posted By',
            dataIndex: 'posted_by',
            key: 'posted_by',
        },
        {
            title: 'Industry',
            dataIndex: 'industry',
            key: 'industry',
        },
        {
            title: 'Function',
            dataIndex: 'job_function',
            key: 'job_function',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (text, record) =>
                <div>
                    {
                        record.location.map((item, index) => {
                            return (
                                <span key={index}>
                                    <span>{item}</span>
                                    {
                                        record?.location?.length - 1 !== index && <span> , </span>
                                    }
                                </span>

                            )
                        })
                    }
                </div>
        },
        {
            title: 'Candidates',
            dataIndex: 'applicant_count',
            key: 'applicant_count',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => <div style={{ display: "flex" }}>
                {
                    record?.is_active
                    ?
                    <ApplyJob title={"Add Candidate"} jobId={record.id} btnContent="Add Candidate" />
                    :
                    <Button type="primary" style={{ backgroundColor: "#008080",color:"white",height:"32px",padding:"4px 15px",borderRadius:"6px",fontSize:"14px",boxShadow:"0 2px 0 rgba(5, 145, 255, 0.1)" }} disabled>
                            Add Candidate
                        </Button>
                }
                {/* <button
                    font-size: 14px;
                    style={{ padding: "4px 10px", border: "none", backgroundColor: "#008080", color: "white", fontSize: "13px", fontWeight: "600" }}
                >
                    
                </button> */}
            </div>
        }
    ]

    const [jobList, setJobList] = useState([]);
    const fetchRowData = async () => {
        const data = await request({
            method: "get",
            url: `recruitment/recruiter/job/list?page=${pagination.current}&page_size=${pagination.pageSize}`,
        })
        setJobList(data?.pagination_data?.data)
        setPagination({
            ...pagination,
            total: data?.pagination_data?.total
        })
        // return data
    }

    useEffect(() => {
        fetchRowData()
    }, [pagination.current])

    const handleRowClick = (record) => {
        // Navigate to a new component with the selected record data
        navigate("/recruitment/external-recruitment/detail/" + record.id, { replace: true });
    };

    const [search, setSearch] = useState("");
    useEffect(() => {

        let timer = setTimeout(() => {
            console.log("seaching....", search);
            // fetchData();
        }, 2000)

        return () => clearTimeout(timer)
    }, [search])


    const renderPosts = (posts) => (
        <>
            {posts.map((item, index) => (
                <div className='mobileContainer'
                    onClick={(e) => {
                        navigate("/admin/recruitment/details/" + item.id, { replace: true });
                    }}>
                    <div key={index} className='adminJobListCard'>
                        <div className='headingContainer'>
                            <span className='title'>{item.job_title}</span >
                            <div className='action'>
                                <img alt="edit" src={edit}
                                    style={{ width: "14px", height: "14px" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/admin/recruitment/post-job/${item.id}`, { replace: true });
                                    }}
                                />
                                <img alt="edit" src={share} width={50} height={25}
                                    style={{ width: "14px", height: "14px" }}
                                />
                            </div>
                        </div>
                        <span className='function'>{item.job_function}</span >
                        <div className='adminJobListCardFooter'>
                            <span style={{ display: "flex", alignItems: "center" }}>
                                <img src={calendar} alt="" style={{ height: "14px" }} />
                                <span className='date'>12/12/12</span>
                            </span>
                            <span style={{ display: "flex", alignItems: "center" }}>
                                <img src={location} alt="" /> {" "}
                                <span className='location'>{item.location}</span>
                            </span>
                        </div>
                    </div>

                </div>
            ))}
        </>
    );

    return (
        <div>
            <div>
                {
                    screenSize.width < 768 ?
                        <div style={{ width: "100vw", display: "flex", justifyContent: "center" }}>
                            <div>
                                <div className='searchContainer'>
                                    <Input
                                        prefix={<SearchOutlined />}
                                        placeholder="Search for employees or action"
                                        style={{ width: "82vw", height: "40px", borderRadius: "21px" }}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </div>
                                <div style={{ display: "flex", gap: "20px", justifyContent: "end", marginTop: "3vh" }}>
                                    <Select className='filterSelect'
                                        placeholder="Filter"
                                    >
                                        <Select.Option value="0">HOME</Select.Option>
                                        <Select.Option value="1">OFFICE</Select.Option>
                                        <Select.Option value="2">HYBRID</Select.Option>
                                    </Select>
                                </div>

                                <div style={{ marginTop: "2vh" }}>
                                    <InfiniteScroll
                                        fetchData={fetchRowData}
                                        page_size={10}
                                        renderPosts={renderPosts}
                                    />
                                </div>
                            </div>
                        </div>
                        :

                        // desktop view
                        <div>
                            <RecruitmentHeader />
                            <div
                                style={{
                                    backgroundImage: `url(${ExternalRecruitmentBgImage})`,
                                    width: "100%",
                                    height: "340px",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                }}
                            >

                            </div>
                            <div className='adminJobListContainer'>
                                <center>
                                    <h1>Latest Jobs</h1>
                                </center>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <Input
                                            prefix={<SearchOutlined />}
                                            placeholder="Search" style={{ width: "400px", height: "40px", borderRadius: "21px" }}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </div>
                                    <div style={{ display: "flex", gap: "20px" }}>
                                        <Select className='filterSelect'
                                            placeholder="Filter"
                                        >
                                            <Select.Option value="0">HOME</Select.Option>
                                            <Select.Option value="1">OFFICE</Select.Option>
                                            <Select.Option value="2">HYBRID</Select.Option>
                                        </Select>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <CommonTable
                                    columns={columns}
                                    data={jobList}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    handleRowClick={handleRowClick}
                                />
                            </div>

                        </div>
                }
            </div>




        </div>
    )
}

export default ExternalRecruitment
