import { Col, Row, Table, Tabs, Modal, Form, Input, Button, DatePicker, Radio, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import salaryDetails from "../../../../assets/Payroll/salaryDetails.svg";
import myTeamSalary from "../../../../assets/Payroll/myTeamSalary.svg";
import { DownOutlined, LockOutlined, RightOutlined, UnlockOutlined, UpOutlined } from '@ant-design/icons';
import yellowTick from "../../../../assets/Payroll/yellowTick.svg";
import arrowCircle from "../../../../assets/fullView.svg";
import add from "../../../../assets/Org/add.svg"
import downloadImg from "../../../../assets/downlaod.svg";
import request from '../../../../utils/axios';
import { saveAs } from 'file-saver';
import download from "../../../../assets/downlaod.svg"
import dayjs from 'dayjs';
import getScreenSize from '../../../../utils/getScreenSize';
import NetIncome from "../../../../assets/Payroll/NetIncome.png";
import Earnings from "../../../../assets/Payroll/Earnings.png";
import Deductions from "../../../../assets/Payroll/Deductions.png";
import { toast } from 'react-toastify';

function DashboardView({ changeTabsValue }) {
  const navigate = useNavigate();
  const [employeeReimbursement, setEmployeeReimbursement] = useState(false)

  const screenSize = getScreenSize();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  const reimbursementColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Amount ',
      dataIndex: 'claim_amount',
      key: 'amount',
    },
    {
      title: 'Merchant Name',
      dataIndex: 'merchant_name',
      key: 'merchant_name',
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice_id',
      key: 'invoice ',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Documents',
      dataIndex: 'file_upload',
      key: 'file_upload',
      render: (file_upload, record) => (
        <p style={{
          borderRadius: "15px",
          textAlign: "center",
          cursor: "pointer",
        }}
          onClick={async () => {
            try {
              const file_upload = record.file_upload
              const response = await fetch(file_upload); // Fetch the PDF file
              const blob = await response.blob();
              const filename = file_upload.substring(file_upload.lastIndexOf('/') + 1);
              saveAs(blob, filename); // Save the blob as a PDF file
            } catch (error) {
              console.error('Error downloading PDF:', error);
            }
          }}>
          <img src={downloadImg} alt="downloadImg" />
        </p>
      )
    }
  ]

  const loanColumns = [
    {
      title: 'Date',
      dataIndex: 'issue_date',
      key: 'issue_date',
    },
    // {
    //   title: 'Year',
    //   dataIndex: 'date',
    //   key: 'date',
    // },
    {
      title: 'Loan Amount ',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Tenure',
      dataIndex: 'tenure',
      key: 'tenure',
    },
    {
      title: 'Interest ',
      dataIndex: 'interest',
      key: 'interest',
    },
  ]

  const investmentColumns = [
    {
      title: 'Investment',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => <span>
        ₹ {amount}
      </span>
    }
  ]

  const flexibleColumns = [
    {
      title: 'Flexible',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => <span>
        ₹ {amount}
      </span>
    }
  ]
  const [taxRegime, setTaxRegime] = useState(0)
  const [isWindowOpen, setIsWindowOpen] = useState()

  const [employeeTaxSummary, setEmployeeTaxSummary] = React.useState()
  const [employeeReimbursementSummary, setEmployeeReimbursementSummary] = React.useState()
  const [employeeLoanSummary, setEmployeeLoanSummary] = React.useState()

  const [showEarnings, setShowEarnings] = useState(false);
  const [showDeductions, setShowDeductions] = useState(false);
  const [taxSummaryType, setTaxSummaryType] = useState("declaration")
  const [formSubmit, setFormSubmit] = useState(false)

  const taxSummaryForDeclaration = async () => {
    try {
      let data = await request({
        method: "get",
        url: `regim_tax_rules/declaration-tax_summary_for_employee`,
      })

      setTaxRegime(data?.regim_type)
      setIsWindowOpen(data?.is_window_open)
      setEmployeeTaxSummary(data?.data)
    }
    catch (err) {
      console.log(err)
    }
  }

  const taxSummaryApprovedDeclaration = async () => {
    try {
      let data = await request({
        method: "get",
        url: `regim_tax_rules/approved-tax_summary_for_employee`,
      })
      console.log(data)
      setEmployeeTaxSummary(data?.data)
    }
    catch (err) {
      console.log(err)
    }
  }

  const fetchemployeeReimbursement = async () => {
    try {
      let data = await request({
        method: "get",
        url: `payroll/get-employee-reimbursement?page=1&page_size=5`,
      })
      console.log(data)
      setEmployeeReimbursementSummary([...data.pagination_data.data])
    }
    catch (err) {
      console.log(err)
    }
  }
  const fetchemployeeLoanSummary = async () => {
    try {
      let data = await request({
        method: "get",
        url: `loan/get-employee-loan-history?page=1&page_size=5`,
      })
      console.log(data)
      setEmployeeLoanSummary([...data.pagination_data.data])
    }
    catch (err) {
      console.log(err)
    }
  }
  const [EmployeeReimbursementData, setEmployeeReimbursementData] = useState({
    title: "",
    amount: "",
    merchant_name: "",
    description: "",
    invoice_id: "",
    date: "",
    attach_file: []
  })

  const onHandleChange = (key, value) => {
    if (key === "attach_file") {
      setEmployeeReimbursementData({
        ...EmployeeReimbursementData,
        "attach_file": [...EmployeeReimbursementData.attach_file, value]
      });
    }
    else {
      setEmployeeReimbursementData({
        ...EmployeeReimbursementData,
        [key]: value
      });
    }
  }
  const [reimbursementForm] = Form.useForm()
  const onFinish = async (values) => {
    const formData = new FormData();
    try {
      formData.append("title", EmployeeReimbursementData.title);
      formData.append("amount", EmployeeReimbursementData.amount);
      formData.append("merchant_name", EmployeeReimbursementData.merchant_name);
      formData.append("description", EmployeeReimbursementData.description);
      formData.append("invoice_id", EmployeeReimbursementData.invoice_id);
      // formData.append("file", EmployeeReimbursementData.attach_file)
      EmployeeReimbursementData.attach_file.forEach(file => {
        formData.append("files", file); // Append each file
      });
      formData.append("date", EmployeeReimbursementData.date)

      await request({
        method: "post",
        url: `payroll/create-employee-reimbursement`,
        data: formData
      })
      fetchemployeeReimbursement();
      setEmployeeReimbursement(false);
      reimbursementForm.resetFields();
      toast.success("Reimbursement Request submitted successfully", {
        position: "bottom-left",
      })
    }
    catch (error) {
      console.log(error)
      toast.error("Reimnursement request could not be submitted, please try after some time", {
        position: "bottom-left",
      })
    }
  }
  const [employeePayrollSummry, setEmployeePayrollSummary] = useState();
  const fetchEmployeePayrollSummary = async () => {
    try {
      if (paySlipMonthYear.month) {
        let data = await request({
          method: "get",
          url: `payroll/employee-payroll-summary?month=${paySlipMonthYear.month}&year=${paySlipMonthYear.year}`,
        })
        console.log("data...///..", data.data)
        setEmployeePayrollSummary(data.data)
      }

    }
    catch (error) {
      console.log("error")
    }
  }
  const [taxDeclarationSummary, setTaxDecalarationSummary] = useState();
  const fetchTaxDeclarationSummary = async () => {
    try {
      let data = await request({
        method: "get",
        url: `investment-declaration/investment-declaration-summary`,
      })
      setTaxDecalarationSummary(data.data)

    }
    catch (error) {
      console.log("error")
    }
  }

  const fetchTaxApprovedSummary = async () => {
    try {
      let data = await request({
        method: "get",
        url: `investment-declaration/investment-approved-summary`,
      })
      setTaxDecalarationSummary(data.data)

    }
    catch (error) {
      console.log("error")
    }
  }

  const [availableMonthPaySlip, setAvailableMonthPaySlip] = useState([])

  const getAvailableMonthPaySlip = async () => {
    try {
      const response = await request({
        method: "get",
        url: `payroll/employee-monthly-paylist?year=${paySlipMonthYear.year}`,
      })

      let lastPaySlipAvailableMonth;
      response.data.forEach(item => {
        if (item.status) {
          lastPaySlipAvailableMonth = item
        }
      })

      setAvailableMonthPaySlip([...response.data])
      setSelectedMonthForDownloadPayslip(lastPaySlipAvailableMonth.month)
      setPaySlipMonthYear({ ...paySlipMonthYear, month: lastPaySlipAvailableMonth.month_id })
    }
    catch (error) {
      console.log(error)
    }
  }

  const sendTaxRegime = async (value) => {
    try {
      await request({
        method: "post",
        url: `regim_tax_rules/employee-regim-selection`,
        data: { regim_type: value }
      })

      toast.success("Regime declaration window updated succesfully", {
        position: "bottom-left",
      })
    }
    catch (error) {
      console.log(error)
      toast.error("Regime declaration window could not be updated, please try after some time", {
        position: "bottom-left",
      })
    }
  }


  useEffect(() => {

    fetchemployeeReimbursement();
    fetchemployeeLoanSummary();
    fetchTaxDeclarationSummary();
  }, [])

  const [paySlipMonthYear, setPaySlipMonthYear] = useState({
    month: "",
    year: dayjs(new Date()).format('YYYY'),
  })

  useEffect(() => {
    const currentDate = new Date();
    const previousMonthDate = new Date(currentDate);
    previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
    // console.log("previousMonthDate", dayjs(previousMonthDate).format('MM'));
    setPaySlipMonthYear({
      // month: dayjs(previousMonthDate).format('MM'),
      month: "",
      year: dayjs(previousMonthDate).format('YYYY'),
    });
  }, []);

  useEffect(() => {
    console.log("paySlipMonthYear...,paySlipMonthYear", paySlipMonthYear);
    fetchEmployeePayrollSummary();
  }, [paySlipMonthYear.month, paySlipMonthYear.year])

  const [selectedMonthForDownloadPayslip, setSelectedMonthForDownloadPayslip] = useState("")


  const downloadPaySlip = async () => {
    try {
      const response = await request({
        method: "get",
        url: `payroll/employee-monthly-payslip?month=${paySlipMonthYear.month}&year=${paySlipMonthYear.year}`,
        responseType: 'arraybuffer',
      })

      const file = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      const fileName = `payslip_${paySlipMonthYear.month}_${paySlipMonthYear.year}.pdf`;
      link.href = url;
      link.download = fileName
      link.click();
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  }



  useEffect(() => {
    getAvailableMonthPaySlip();
  }, [paySlipMonthYear.year])

  const ref = useRef(null);
  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (taxSummaryType === "declaration") {
      taxSummaryForDeclaration();
      fetchTaxDeclarationSummary();
    }
    else {
      fetchTaxApprovedSummary();
      taxSummaryApprovedDeclaration();
    }
  }, [taxSummaryType])

  return (
    <div ref={ref}>
      {
        screenSize.width < 768
          ?
          <>
            {/* <Tabs
              defaultActiveKey="1"
              items={items}
            // onChange={onChange}
            /> */}

            {/* 1 */}
            <div style={{ display: "grid", gap: "10px", gridTemplateColumns: "1fr 1fr", padding: "0px 16px" }}>
              <div style={{ width: "100%", background: "#FAFAFA", padding: "10px 20px", borderRadius: "4px", cursor: "pointer" }}>
                <div className='flexSpaceBetween' style={{ margin: "10px 0px 20px 0px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "600", color: "#145757", margin: "0" }}
                  >{employeePayrollSummry?.total_ctc || 0}</p>
                  <img src={salaryDetails} alt="salaryDetails" width={20} height={20} />
                </div>
                <p style={{ fontSize: "12px", color: "#161616" }}
                  onClick={() => navigate('/payroll/salary-details', { replace: true })}
                >Salary Details <RightOutlined style={{ fontWeight: "600", color: '#145757' }} /></p>
              </div>

              {/* <div style={{ width: "100%", background: "#FAFAFA", padding: "10px 20px", borderRadius: "4px" }}>
                <div className='flexSpaceBetween' style={{ margin: "10px 0px 20px 0px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "600", color: "#145757", margin: "0" }}>1,46,44,508</p>
                  <img src={myTeamSalary} alt="myTeamSalary" width={20} height={20} />
                </div>
                <p style={{ fontSize: "12px", color: "#161616" }}>My Team Salary <RightOutlined style={{ fontWeight: "600", color: '#145757' }} /></p>
              </div> */}
            </div>

            {/* divider */}
            <div
              style={{
                height: "8px",
                backgroundColor: "#F7F8FC",
                marginTop: "20px",
              }}
            >
            </div>

            {/* 2 */}
            <div style={{ padding: "10px 16px" }}>
              <div className='flexSpaceBetween' style={{ margin: "10px 0px 20px 0px" }}>
                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0px" }}>Payroll Summary</p>
                <img src={arrowCircle} alt="arrowCircle" onClick={() => changeTabsValue("2")} />
              </div>
              <DatePicker
                onChange={
                  (date, dateString) => {
                    setPaySlipMonthYear({ ...paySlipMonthYear, year: dateString })
                    setSelectedMonthForDownloadPayslip("")
                  }
                }
                value={dayjs(paySlipMonthYear.year)}
                picker="year" />
              <div style={{ display: "flex", gap: "30px", overflowX: "auto" }}>
                {
                  availableMonthPaySlip?.map((item, index) => {
                    return (
                      <>
                        {
                          selectedMonthForDownloadPayslip === item.month
                            ?
                            <div style={{ margin: "10px 0px 20px 0px", background: "#FBFBFB", border: "1px solid #008080", padding: "10px 15px", borderRadius: "4px" }}
                              onClick={() => {
                                setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                setSelectedMonthForDownloadPayslip(item.month)
                              }
                              }
                            >
                              <center>
                                {
                                  <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} width={30} height={30} />
                                }
                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                              </center>
                            </div>
                            :
                            <div style={{ margin: "10px 0px 20px 0px", padding: "10px 0px", }}
                              onClick={() => {
                                if (item.status) {
                                  setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                  setSelectedMonthForDownloadPayslip(item.month)
                                }
                              }}
                            >
                              <center>
                                {
                                  item.status
                                    ?


                                    <img src={yellowTick} alt="yellowTick" width={30} height={30} style={{ cursor: "pointer" }} />


                                    :
                                    <div style={{ width: "30px", height: "30px", borderRadius: "50%", border: "2px solid #FBA602", display: "flex", justifyContent: "center", alignItems: "center", color: "#FBA602", fontSize: "12px", fontWeight: "600", cursor: "not-allowed" }}>
                                      {index + 1}
                                    </div>
                                }
                                <p style={{ fontSize: "12px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                              </center>
                            </div>
                        }
                      </>

                    )
                  })
                }
              </div>

              {
                paySlipMonthYear.month
                &&
                <div>
                  {/* Net Income */}
                  <div style={{ padding: "20px 0px" }}>
                    <div>
                      <Row>
                        <Col span={16}>
                          <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616", margin: "0" }}>
                            <img src={NetIncome} alt="NetIncome" width={20} height={20} style={{ marginRight: "10px" }} />
                            Net Income</p>
                        </Col>
                        <Col span={8}>
                          <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", margin: "0" }}>₹ {employeePayrollSummry?.net_pay}</p>
                        </Col>
                      </Row>
                    </div>

                  </div>

                  {/* Earnings */}
                  <div >
                    <Row>
                      <Col span={16}>
                        <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616" }}>
                          <img src={Earnings} alt="Earnings" width={20} height={20} style={{ marginRight: "10px" }} />
                          Earnings</p>
                      </Col>
                      <Col span={8}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", margin: "0" }}>₹ {employeePayrollSummry?.total_earnings_amount}
                          </p>
                          {
                            showEarnings
                              ?
                              <UpOutlined
                                style={{ fontSize: "16px", color: "#717070" }}
                                onClick={() => setShowEarnings(false)}
                              />
                              :
                              <DownOutlined style={{ fontSize: "16px", color: "#717070" }}
                                onClick={() => setShowEarnings(true)}
                              />
                          }
                        </div>
                      </Col>
                    </Row>

                    {
                      showEarnings
                      &&
                      <>
                        <>
                          {
                            employeePayrollSummry?.earnings?.map((item, index) => {
                              return (
                                <Row>
                                  <Col span={16}>
                                    <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>{item.type}</p>
                                  </Col>
                                  <Col span={8}>
                                    <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {item.monthly_amount}</p>
                                  </Col>
                                </Row>
                              )
                            })
                          }
                        </>
                        <Row>
                          {
                            employeePayrollSummry?.reimbursement > 0
                            &&
                            <>
                              <Col span={16}>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Reimbursement</p>
                              </Col>
                              <Col span={8}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.reimbursement}</p>
                              </Col>
                            </>
                          }
                          {
                            employeePayrollSummry?.arrear_amount > 0
                            &&
                            <>
                              <Col span={16}>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Arrear Payment</p>
                              </Col>
                              <Col span={8}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.arrear_amount}</p>
                              </Col>
                            </>
                          }
                          {
                            employeePayrollSummry?.adhoc_payment_amount > 0
                            &&
                            <>
                              <Col span={16}>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Adhoc-Payment mmmm</p>
                              </Col>
                              <Col span={8}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.adhoc_payment_amount}</p>
                              </Col>
                            </>
                          }
                          {
                            employeePayrollSummry?.bonus_amount > 0
                            &&
                            <>
                              <Col span={16}>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Bonus</p>
                              </Col>
                              <Col span={8}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.bonus_amount}</p>
                              </Col>
                            </>
                          }

                        </Row>
                      </>
                    }

                  </div>

                  {/* Deductions */}
                  <div>
                    <Row>
                      <Col span={16}>
                        <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616" }}>
                          <img src={Deductions} alt="Deductions" width={20} height={20} style={{ marginRight: "10px" }} />
                          Deductions</p>
                      </Col>
                      <Col span={8}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <p style={{ fontSize: "16PX", fontWeight: "600", color: "#161616", margin: "0" }}>₹ {employeePayrollSummry?.total_deductions_amount}</p>
                          {
                            showDeductions
                              ?
                              <UpOutlined
                                style={{ fontSize: "16px", color: "#717070" }}
                                onClick={() => setShowDeductions(false)}
                              />
                              :
                              <DownOutlined style={{ fontSize: "16px", color: "#717070" }}
                                onClick={() => setShowDeductions(true)}
                              />
                          }


                        </div>
                      </Col>
                    </Row>
                    {
                      showDeductions
                      &&
                      <>
                        {
                          employeePayrollSummry?.deductions?.map((item, index) => {
                            return (
                              <Row>
                                <Col span={16}>
                                  <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>{item.type}</p>
                                </Col>
                                <Col span={8}>
                                  <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {item.monthly_amount}</p>
                                </Col>
                              </Row>
                            )
                          })
                        }
                        {
                          employeePayrollSummry?.income_tax > -1
                          &&
                          <Row>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>TDS</p>
                            </Col>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.income_tax}</p>
                            </Col>
                          </Row>
                        }
                        {
                          employeePayrollSummry?.lop > 0
                          &&
                          <Row>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Loss of Pay</p>
                            </Col>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.lop}</p>
                            </Col>
                          </Row>
                        }
                        {
                          employeePayrollSummry?.adhoc_deductions > 0
                          &&
                          <Row>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Adhoc-Deductions</p>
                            </Col>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.adhoc_deductions}</p>
                            </Col>
                          </Row>
                        }
                        {
                          employeePayrollSummry?.advance_salary > 0
                          &&
                          <Row>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Advance Salary</p>
                            </Col>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.advance_salary}</p>
                            </Col>
                          </Row>
                        }
                        {
                          employeePayrollSummry?.loan_amount > 0
                          &&
                          <Row>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Loan Amount</p>
                            </Col>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.loan_amount}</p>
                            </Col>
                          </Row>
                        }

                      </>
                    }


                  </div>
                </div>
              }
            </div>

            {/* divider */}
            <div
              style={{
                height: "8px",
                backgroundColor: "#F7F8FC",
                marginTop: "20px",
              }}
            >
            </div>

            {/* 3 */}
            <div style={{ background: "white", padding: "20px", borderRadius: "4px" }}>
              <div className='flexSpaceBetween'>
                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0" }}>Tax Summary</p>
                <img src={arrowCircle} alt="arrowCircle" onClick={() => changeTabsValue("3")} />
              </div>
              <div style={{ marginTop: "20px" }}>
                <Radio.Group
                  size='large'
                  options={[
                    {
                      label: 'New Regime',
                      value: 0,
                    },
                    {
                      label: 'Old Regime',
                      value: 1,
                    }
                  ]}
                  onChange={(e) => {
                    setTaxRegime(e.target.value)
                    sendTaxRegime(e.target.value)
                  }}
                  value={taxRegime}
                  optionType="button"
                  disabled={!isWindowOpen}
                />
              </div>
              <div className='flexSpaceBetween' style={{ marginTop: "20px" }}>
                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Total CTC</p>
                <p style={{ fontSize: "18px", fontWeight: "600", color: "#333333" }}>₹{employeeTaxSummary?.total_ctc || 0}</p>
              </div>
              <div className='flexSpaceBetween'>
                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Net Taxable Income</p>
                <p style={{ fontSize: "18px", fontWeight: "600", color: "#333333" }}>₹{employeeTaxSummary?.net_taxable_income || 0}</p>
              </div>
              <div className='flexSpaceBetween'>
                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>TDS</p>
                <p style={{ fontSize: "18px", fontWeight: "600", color: "#333333" }}>₹{employeeTaxSummary?.total_income_tax || 0}</p>
              </div>
            </div>

            {/* divider */}
            <div
              style={{
                height: "8px",
                backgroundColor: "#F7F8FC",
                marginTop: "10px",
              }}
            >
            </div>

            {/* 4 */}
            <div style={{ background: "white", padding: "0px 16px", marginTop: "20px", borderRadius: "4px" }}>
              <div className='flexSpaceBetween'>
                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0" }}>Tax Declarations</p>
                {
                  taxDeclarationSummary?.is_window_open
                    ?
                    <img src={arrowCircle} alt="arrowCircle" onClick={() => changeTabsValue("4")} />
                    :
                    <img src={arrowCircle} alt="arrowCircle" />

                }
              </div>
              <div style={{ display: "flex", gap: "20px", alignItems: "center", border: "1px solid #008080", padding: "10px", borderRadius: "4px", marginTop: "10px" }} >
                <p style={{ margin: "0px", color: "#161616", fontSize: "16px", fontWeight: "500" }}>{taxDeclarationSummary?.is_window_open ? "Open" : "Closed"} For Declarations</p>
                <p style={{ margin: "0px", color: "#008080", fontSize: "12px", fontWeight: "400" }}>{taxDeclarationSummary?.is_window_open && taxDeclarationSummary?.closed_date}{" "}
                  {
                    taxDeclarationSummary?.is_window_open
                      ?
                      <UnlockOutlined />
                      :
                      <LockOutlined />
                  }
                </p>
              </div>
              <Row style={{ marginTop: "20px" }}>
                <Col span={24}>
                  <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>Investment</p>
                  <>
                    {
                      taxDeclarationSummary?.investments?.map((item, index) => {
                        return (
                          <Row>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>{item.type}</p>
                            </Col>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", textAlign: "right" }}>₹ {item.amount}</p>
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </>
                </Col>
                <Col span={24}>
                  <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>Flexible</p>
                  <>
                    {
                      taxDeclarationSummary?.flexible?.map((item, index) => {
                        return (
                          <Row>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>{item.type}</p>
                            </Col>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", textAlign: "right" }}>₹ {item.amount}</p>
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </>
                </Col>
              </Row>
            </div>

            {/* divider */}
            <div
              style={{
                height: "8px",
                backgroundColor: "#F7F8FC",
                marginTop: "10px",
              }}
            >
            </div>

            {/* 5 */}
            <div style={{ marginTop: "20px", background: "white", borderRadius: "4px" }}>
              <div className='flexSpaceBetween' style={{ padding: "0px 16px" }}>
                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0" }}>Reimbursement</p>
                <div style={{ display: "flex", gap: "20px" }}>
                  <img src={add} alt="add" width={35} onClick={() => navigate("/payroll/reimbursement/form", { replace: true })} />
                  <img src={arrowCircle} alt="arrowCircle" onClick={() => changeTabsValue("5")} />
                </div>
              </div>

              <div>
                {
                  employeeReimbursementSummary?.map((item, index) => {
                    return (
                      <div>
                        <div style={{ padding: "0px 16px", marginTop: "20px" }}>
                          <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>{item.title}</p>
                          <p style={{ fontSize: "16px", fontWeight: "400", color: "#8A8A8A" }}>₹ {item.date}</p>
                          <Row>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Amount</p>
                            </Col>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>₹ {item.claim_amount}</p>
                            </Col>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Invoice ID</p>
                            </Col>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>{item.invoice_id}</p>
                            </Col>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Merchant</p>
                            </Col>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>{item.merchant_name}</p>
                            </Col>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Document</p>
                            </Col>
                            <Col span={16}>
                              <p style={{
                                borderRadius: "15px",
                                textAlign: "end",
                                cursor: "pointer",
                              }}
                                onClick={async () => {
                                  try {
                                    const file_upload = item.file_upload
                                    const response = await fetch(file_upload); // Fetch the PDF file
                                    const blob = await response.blob();
                                    const filename = file_upload.substring(file_upload.lastIndexOf('/') + 1);
                                    saveAs(blob, filename); // Save the blob as a PDF file
                                  } catch (error) {
                                    console.error('Error downloading PDF:', error);
                                  }
                                }}>
                                <img src={downloadImg} alt="downloadImg" />
                              </p>
                            </Col>
                            <Col span={24}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616", marginBottom: "1px" }}>Description</p>
                              {/* </Col>
                            <Col span={24}> */}
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>{item.description}</p>
                            </Col>
                          </Row>
                        </div>
                        {
                          index !== employeeReimbursementSummary.length - 1 && <hr />
                        }

                      </div>
                    )
                  })
                }
              </div>
            </div>

            {/* divider */}
            <div
              style={{
                height: "8px",
                backgroundColor: "#F7F8FC",
                marginTop: "10px",
              }}
            >
            </div>

            {/* 6 */}
            <div style={{ marginTop: "20px", background: "white", borderRadius: "4px" }}>
              <div className='flexSpaceBetween' style={{ padding: "0px 16px" }}>
                <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0" }}>Loan</p>
                <div style={{ display: "flex", gap: "20px" }}>
                  <img src={arrowCircle} alt="arrowCircle" onClick={() => changeTabsValue("6")} />
                </div>
              </div>

              <div>
                {
                  employeeLoanSummary?.map((item, index) => {
                    return (
                      <div>
                        <div style={{ padding: "0px 16px", marginTop: "20px" }}>
                          <Row>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Month</p>
                            </Col>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>₹ {item.tenure}</p>
                            </Col>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Year</p>
                            </Col>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>{item.issue_date}</p>
                            </Col>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Loan Amount</p>
                            </Col>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>{item.amount}</p>
                            </Col>
                            <Col span={8}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#161616" }}>Interest</p>
                            </Col>
                            <Col span={16}>
                              <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333", textAlign: "right" }}>{item.interest}</p>
                            </Col>

                          </Row>
                        </div>
                        {
                          index !== employeeLoanSummary.length - 1 && <hr />
                        }

                      </div>
                    )
                  })
                }
              </div>
            </div>
          </>
          :
          <>
            {/* <Tabs
              defaultActiveKey="1"
              items={items}
            // onChange={onChange}
            /> */}

            <div style={{ padding: "50px" }}>

              {/* <p style={{ fontSize: "32px" }}>Compensation</p> */}

              <div style={{ display: "flex", gap: "100px" }}>
                <div style={{ width: "300px", background: "white", padding: "10px 20px", borderRadius: "4px", cursor: "pointer" }}
                >
                  <div className='flexSpaceBetween' style={{ margin: "10px 0px 20px 0px" }}>
                    <p style={{ fontSize: "24px", fontWeight: "600", color: "#145757", margin: "0" }}
                    >{employeePayrollSummry?.total_ctc || 0}</p>
                    <img src={salaryDetails} alt="salaryDetails" />
                  </div>
                  <p style={{ fontSize: "18px", color: "#161616" }}
                    onClick={() => navigate('/payroll/salary-details', { replace: true })}
                  >Salary Details <RightOutlined style={{ fontWeight: "600", color: '#145757' }} /></p>
                </div>

                {/* <div style={{ width: "300px", background: "white", padding: "10px 20px", borderRadius: "4px" }}>
            <div className='flexSpaceBetween' style={{ margin: "10px 0px 20px 0px" }}>
              <p style={{ fontSize: "24px", fontWeight: "600", color: "#145757", margin: "0" }}>1,46,44,508</p>
              <img src={myTeamSalary} alt="myTeamSalary" />
            </div>
            <p style={{ fontSize: "18px", color: "#161616" }}>My Team Salary <RightOutlined style={{ fontWeight: "600", color: '#145757' }} /></p>
          </div> */}
              </div>

              <div style={{ marginTop: "30px", background: "white", padding: "20px", borderRadius: "4px" }}>
                <div className='flexSpaceBetween'>
                  <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Payroll Summary</p>
                  <img src={arrowCircle} alt="arrowCircle" onClick={() => changeTabsValue("2")} />
                </div>
                <DatePicker
                  onChange={
                    (date, dateString) => {
                      setPaySlipMonthYear({ ...paySlipMonthYear, year: dateString })
                      setSelectedMonthForDownloadPayslip("")
                    }
                  }
                  value={dayjs(paySlipMonthYear.year)}
                  picker="year" />
                <div style={{ display: "flex", gap: "50px" }}>
                  {
                    availableMonthPaySlip?.map((item, index) => {
                      return (
                        <>
                          {
                            selectedMonthForDownloadPayslip === item.month
                              ?
                              <div style={{ margin: "10px 0px 20px 0px", background: "#FBFBFB", border: "1px solid #008080", padding: "10px 15px", borderRadius: "4px" }}
                                onClick={() => {
                                  setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                  setSelectedMonthForDownloadPayslip(item.month)
                                }
                                }
                              >
                                <center>
                                  {
                                    <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} />
                                  }
                                  <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                </center>
                              </div>
                              :
                              <div style={{ margin: "10px 0px 20px 0px", padding: "10px 0px", }}
                                onClick={() => {
                                  if (item.status) {
                                    setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                    setSelectedMonthForDownloadPayslip(item.month)
                                  }
                                }}
                              >
                                <center>
                                  {
                                    item.status
                                      ?


                                      <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} />


                                      :
                                      <div style={{ width: "40px", height: "40px", borderRadius: "50%", border: "2px solid #FBA602", display: "flex", justifyContent: "center", alignItems: "center", color: "#FBA602", fontWeight: "800", cursor: "not-allowed" }}>
                                        {index + 1}
                                      </div>
                                  }
                                  <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                </center>
                              </div>
                          }
                        </>

                      )
                    })
                  }
                </div>

                <br />
                {
                  paySlipMonthYear.month
                  &&
                  <div>
                    <div style={{ display: "flex", gap: "100px", justifyContent: "space-between", padding: "20px 0px" }}>
                      <div style={{ width: "50%" }}>
                        <Row>
                          <Col span={16}>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#28B15F", margin: "0" }}>Net Income</p>
                          </Col>
                          <Col span={8}>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#28B15F", margin: "0" }}>₹ {employeePayrollSummry?.net_pay}</p>
                          </Col>
                        </Row>
                      </div>
                      <div style={{ width: "50%", display: "flex", justifyContent: "end" }}>

                        <img src={download} alt="download"
                          onClick={() => downloadPaySlip()}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      {/* Earnings */}
                      <div style={{ width: "50%" }}>

                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>Earnings</p>
                        <>
                          {
                            employeePayrollSummry?.earnings?.map((item, index) => {
                              return (
                                <Row>
                                  <Col span={16}>
                                    <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>{item.type}</p>
                                  </Col>
                                  <Col span={8}>
                                    <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {item.monthly_amount}</p>
                                  </Col>
                                </Row>
                              )
                            })
                          }
                        </>

                      </div>
                      {/* Deductions */}
                      <div style={{ width: "50%" }}>
                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>Deductions</p>
                        <>
                          {
                            employeePayrollSummry?.deductions?.map((item, index) => {
                              return (
                                <Row>
                                  <Col span={16}>
                                    <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>{item.type}</p>
                                  </Col>
                                  <Col span={8}>
                                    <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {item.monthly_amount}</p>
                                  </Col>
                                </Row>
                              )
                            })
                          }
                          {
                            employeePayrollSummry?.income_tax > 0
                            &&
                            <Row>
                              <Col span={16}>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>TDS</p>
                              </Col>
                              <Col span={8}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.income_tax}</p>
                              </Col>
                            </Row>
                          }
                          {
                            employeePayrollSummry?.lop > 0
                            &&
                            <Row>
                              <Col span={16}>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Loss of Pay</p>
                              </Col>
                              <Col span={8}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.lop}</p>
                              </Col>
                            </Row>
                          }
                          {
                            employeePayrollSummry?.adhoc_deductions > 0
                            &&
                            <Row>
                              <Col span={16}>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Adhoc-Deductions</p>
                              </Col>
                              <Col span={8}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.adhoc_deductions}</p>
                              </Col>
                            </Row>
                          }
                          {
                            employeePayrollSummry?.advance_salary > 0
                            &&
                            <Row>
                              <Col span={16}>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Advance Salary</p>
                              </Col>
                              <Col span={8}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.advance_salary}</p>
                              </Col>
                            </Row>
                          }
                          {
                            employeePayrollSummry?.loan_amount > 0
                            &&
                            <Row>
                              <Col span={16}>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Loan Amount</p>
                              </Col>
                              <Col span={8}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.loan_amount}</p>
                              </Col>
                            </Row>
                          }

                        </>

                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <Row>
                          {
                            employeePayrollSummry?.reimbursement > 0
                            &&
                            <>
                              <Col span={16}>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Reimbursement</p>
                              </Col>
                              <Col span={8}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.reimbursement}</p>
                              </Col>
                            </>
                          }
                          {
                            employeePayrollSummry?.arrear_amount > 0
                            &&
                            <>
                              <Col span={16}>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Arrear Payment</p>
                              </Col>
                              <Col span={8}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.arrear_amount}</p>
                              </Col>
                            </>
                          }
                          {
                            employeePayrollSummry?.adhoc_payment_amount > 0
                            &&
                            <>
                              <Col span={16}>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Adhoc-Payment</p>
                              </Col>
                              <Col span={8}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.adhoc_payment_amount}</p>
                              </Col>
                            </>
                          }
                          {
                            employeePayrollSummry?.bonus_amount > 0
                            &&
                            <>
                              <Col span={16}>
                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Bonus</p>
                              </Col>
                              <Col span={8}>
                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.bonus_amount}</p>
                              </Col>
                            </>
                          }
                          {/* <Col span={16}>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#28B15F" }}>Gross Earnings</p>
                          </Col>
                          <Col span={8}>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#28B15F" }}>₹ {employeePayrollSummry?.total_earnings_amount}</p>
                          </Col> */}
                        </Row>
                      </div>
                      <div style={{ width: "50%" }}>

                      </div>
                    </div>
                    <Row>
                      <Col span={8}>
                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#28B15F" }}>Gross Earnings</p>
                      </Col>
                      <Col span={4}>
                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#28B15F" }}>₹ {employeePayrollSummry?.total_earnings_amount}</p>
                      </Col>
                      <Col span={8}>
                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#FA0D0D" }}>Gross Deductions</p>
                      </Col>
                      <Col span={4}>
                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#FA0D0D" }}>₹ {employeePayrollSummry?.total_deductions_amount}</p>
                      </Col>
                    </Row>
                  </div>
                }
              </div>

              <div style={{ marginTop: "30px", padding: "30px", background: "white", borderRadius: "4px" }}>
                <Row>
                  <Col span={24}>
                    <div style={{ background: "white", borderRadius: "4px" }}>
                      <div className='flexSpaceBetween'>
                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0" }}>Tax Summary</p>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Select defaultValue={taxSummaryType} style={{ width: 120 }}
                            onChange={(e) => {
                              setTaxSummaryType(e)
                            }}
                          >
                            <Select.Option value="approved">Approved</Select.Option>
                            <Select.Option value="declaration">Declared</Select.Option>
                          </Select>
                          <img src={arrowCircle} alt="arrowCircle" onClick={() => changeTabsValue("3")} />
                        </div>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <Radio.Group
                          size='large'
                          options={[
                            {
                              label: 'New Regime',
                              value: 0,
                            },
                            {
                              label: 'Old Regime',
                              value: 1,
                            }
                          ]}
                          onChange={(e) => {
                            setTaxRegime(e.target.value)
                            sendTaxRegime(e.target.value)
                          }}
                          value={taxRegime}
                          optionType="button"
                          disabled={!isWindowOpen}
                        />
                      </div>
                      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "20px", marginTop: "20px" }}>

                        <div>
                          <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>
                            Total CTC :
                            <span style={{ fontSize: "18px", fontWeight: "600", color: "#333333", marginLeft: "10px" }}>₹{employeeTaxSummary?.total_ctc || 0}</span>
                          </p>
                        </div>
                        <div>
                          <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>
                            Net Taxable Income :
                            <span style={{ fontSize: "18px", fontWeight: "600", color: "#333333", marginLeft: "10px" }}>₹{employeeTaxSummary?.net_taxable_income || 0}</span>
                          </p>
                        </div>
                        <div>
                          <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>TDS
                            <span style={{ fontSize: "18px", fontWeight: "600", color: "#333333", marginLeft: "10px" }}>₹{employeeTaxSummary?.total_income_tax || 0}</span>
                          </p>

                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={24}>
                    <div style={{ background: "white", borderRadius: "4px", marginTop: "20px" }}>
                      <div className='flexSpaceBetween'>
                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Tax Declarations</p>
                        {/* <div style={{ display: "flex", gap: "20px", alignItems: "center", border: "1px solid #008080", padding: "10px", borderRadius: "4px" }} > */}
                        <button style={{ background: "#008080", border: "1px solid #008080", borderRadius: "4px", color: "white", height: "40px", padding: "0 20px" }} onClick={() => {
                          if (taxDeclarationSummary?.is_window_open) {
                            navigate("/payroll/tax-declaration/form", { replace: true });
                          }
                        }}>Edit</button>
                        {/* <p style={{ margin: "0px", color: "#161616", fontSize: "16px", fontWeight: "500" }}>Open For Declarations</p>
                          <p style={{ margin: "0px", color: "#008080", fontSize: "12px", fontWeight: "400" }} onClick={() => {
                              if (taxDeclarationSummary?.is_window_open) {
                                navigate("/payroll/tax-declaration/form");
                              }
                            }}>
                              {taxDeclarationSummary?.closed_date}
                              {taxDeclarationSummary?.is_window_open ? <UnlockOutlined /> : <LockOutlined />}
                          </p> */}
                        {/* </div> */}
                        {/* {
                          taxDeclarationSummary?.is_window_open
                            ?
                            <img src={arrowCircle} alt="arrowCircle" onClick={() => navigate("/payroll/tax-declaration/form")} />
                            :
                            <img src={arrowCircle} alt="arrowCircle" />

                        } */}
                      </div>

                      <Row gutter={[32, 32]}>
                        <Col md={10}>
                          {/* <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }}>Investment</p> */}
                          {/* <> */}
                          <Table
                            columns={investmentColumns}
                            dataSource={taxDeclarationSummary?.investments}
                            pagination={false}
                          />
                          {/* {
                              taxDeclarationSummary?.investments?.map((item, index) => {
                                return (
                                  <Row>
                                    <Col span={16}>
                                      <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>{item.type}</p>
                                    </Col>
                                    <Col span={8}>
                                      <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {item.amount}</p>
                                    </Col>
                                  </Row>
                                )
                              })
                            } */}
                          {/* </> */}
                        </Col>
                        <Col md={2}>
                        </Col>
                        <Col md={10}>
                          {/* <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }}>Flexible</p>
                          <>
                            {
                              taxDeclarationSummary?.flexible?.map((item, index) => {
                                return (
                                  <Row>
                                    <Col span={16}>
                                      <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>{item.type}</p>
                                    </Col>
                                    <Col span={8}>
                                      <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {item.amount}</p>
                                    </Col>
                                  </Row>
                                )
                              })
                            }
                          </> */}
                          <Table
                            columns={flexibleColumns}
                            dataSource={taxDeclarationSummary?.flexible}
                            pagination={false}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>

              <div style={{ marginTop: "30px", background: "white", padding: "20px", borderRadius: "4px" }}>
                <div className='flexSpaceBetween'>
                  <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Reimbursement</p>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <img src={add} alt="add" width={35} onClick={() => setEmployeeReimbursement(true)} />
                    <img src={arrowCircle} alt="arrowCircle" onClick={() => changeTabsValue("5")} />
                  </div>
                </div>
                <Table
                  columns={reimbursementColumns}
                  dataSource={employeeReimbursementSummary}
                  pagination={false}
                />
              </div>

              <div style={{ marginTop: "30px", background: "white", padding: "20px", borderRadius: "4px" }}>
                <div className='flexSpaceBetween'>
                  <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Loan</p>
                  <div style={{ display: "flex", gap: "20px" }}>
                    {/* <img src={add} alt="add" width={35} height={35} /> */}
                    <img src={arrowCircle} alt="arrowCircle" onClick={() => changeTabsValue("6")} />
                  </div>
                </div>
                <Table
                  columns={loanColumns}
                  dataSource={employeeLoanSummary}
                  pagination={false}
                />
              </div>
            </div>
          </>
      }

      <Modal
        title="Reimbursement"
        centered
        open={employeeReimbursement}
        // onOk={() => setReviseSalary(false)}
        onCancel={() => setEmployeeReimbursement(false)}
        width={1000}
        footer={null}>
        <Form
          layout="vertical"
          onFinish={onFinish} >
          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }}>
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Please enter title!',
                  },
                ]}
              >
                <Input
                  size='large'
                  placeholder="Enter title"
                  onChange={(e) => onHandleChange("title", e.target.value)}
                  value={EmployeeReimbursementData.title}
                />
              </Form.Item>
            </Col>

          </Row>

          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Date"
                name="date"
                rules={[
                  {
                    required: true,
                    message: 'Please enter date!',
                  },
                ]}
              >
                <DatePicker
                  className='leaveInputField'
                  onChange={(date, dateString) => onHandleChange("date", dateString)}
                />
              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: 'Please enter amount!',
                  },
                ]}
              >
                <Input
                  size='large'
                  placeholder="Enter Amount"
                  onChange={(e) => onHandleChange("amount", e.target.value)}
                  value={EmployeeReimbursementData.amount}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 32]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Invoice ID"
                name="InvoiceId"
                rules={[
                  {
                    required: true,
                    message: 'Please enter invoice id!',
                  },
                ]}
              >
                <Input
                  size='large'
                  placeholder="Enter Invoice Id"
                  onChange={(e) => onHandleChange("invoice_id", e.target.value)}
                  value={EmployeeReimbursementData.invoice_id}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label="Merchant Name"
                name="merchant_name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter merchant name!',
                  },
                ]}
              >
                <Input
                  size='large'
                  placeholder="Enter Merchant Name"
                  onChange={(e) => onHandleChange("merchant_name", e.target.value)}
                  value={EmployeeReimbursementData.merchant_name}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Please enter description!',
                  },
                ]}
              >
                <Input
                  size='large'
                  placeholder="Enter Description"
                  onChange={(e) => onHandleChange("description", e.target.value)}
                  value={EmployeeReimbursementData.description}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={{ span: 24 }}>
            <Form.Item
              label="Upload File"
              name="file"
            // rules={[
            //     {
            //         required: true,
            //         message: 'Please enter your Date (To)!',
            //     },
            // ]}
            >
              <p>Upload File</p>
              <div className="file-upload-container">
                <input
                  type="file"
                  id="file-upload"
                  className="input-file"
                  onChange={(e) => {
                    const selectedFile = e.target.files[0];
                    if (selectedFile && selectedFile.type === 'application/pdf') {
                      onHandleChange("attach_file", selectedFile)
                    } else {
                      // Reset the file state and show an error message
                      alert('Please upload a PDF file.');
                    }
                  }}
                />
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                  <label htmlFor="file-upload" className="file-upload-label">

                  </label>
                  <p style={{ zIndex: 1, margin: "0px" }}>{EmployeeReimbursementData?.attach_file.name ? EmployeeReimbursementData?.attach_file.name : "Upload File"}</p>
                </div>
              </div>
              {
                (formSubmit && EmployeeReimbursementData.attach_file === "") && <p style={{ color: "red", fontSize: "12px", marginTop: "40px" }}>Please Upload File!</p>
              }
            </Form.Item>
          </Col>
          <Button htmlType="submit"
            style={{ width: "200px", marginTop: "40px", marginBottom: "20px", height: "40px", backgroundColor: "#008080", color: "white" }}
            onClick={() => setFormSubmit(true)}
          >
            Submit
          </Button>
        </Form>


      </Modal>
    </div >
  )
}

export default DashboardView