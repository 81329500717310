import { DeleteOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Input, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import request from '../../../../utils/axios';

function GoalForm() {
    const { TextArea } = Input;

    const [goal, setGoal] = useState({
        title: "",
        description: "",
        start_date: "",
        end_date: "",
        stutus: "",
        sub_goals: []
    })

    const onHandleSubGoalChange = (key, index, value) => {
        const updatedSubGoals = [...goal.sub_goals];
        updatedSubGoals[index] = {
            ...updatedSubGoals[index],
            [key]: value
        };
        setGoal({ ...goal, sub_goals: updatedSubGoals });
    }

    const submitForm = async () => {
        try {
            console.log(goal)
            await request({
                method: "POST",
                url: "/performance-management/employee/goal/create",
                data: goal
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    const onDeleteSubGoal = (index) => {
        const updatedSubGoals = goal.sub_goals.filter((_, subGoalIndex) => subGoalIndex !== index);
        setGoal({ ...goal, sub_goals: updatedSubGoals });
    }



    return (
        <div style={{ padding: "50px" }}>
            <p style={{ fontSize: "24px", fontWeight: "600", color: "#161616" }}>Add Goal</p>
            <div style={{ background: "white", padding: "30px", borderRadius: "5px" }}>
                <Form
                    layout="vertical"
                >
                    <Row gutter={[32, 8]}>
                        <Col span={24}>
                            <p style={{ fontSize: "24px", fontWeight: "600", color: "#161616" }}>Goal Details</p>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Goal Title"
                                name="title"
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Goal Title Here"
                                    value={goal.title}
                                    onChange={(e) => setGoal({ ...goal, title: e.target.value })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Start Date"
                                name="start_date"
                            >
                                {/* <Input
                                    size='large'
                                    
                                    value={goal.start_date}
                                    onChange={(e) => }
                                /> */}
                                <DatePicker
                                    size="large"
                                    // picker="month"
                                    style={{
                                        // border: "none",
                                        width: "100%",
                                        outline: "none",
                                        // background: "#FFFFFF",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#000000",
                                    }}
                                    onChange={(value) => {
                                        setGoal({ ...goal, start_date: value })
                                    }}
                                    // can we set default value as month name
                                    // defaultValue={moment().format("MMMM")}
                                    // defaultValue={dayjs().startOf('month')}
                                    value={goal.start_date}
                                    placeholder="Enter Start Date"
                                    format={"DD MMMM YYYY"}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="End Date"
                                name="end_date"
                            >
                                {/* <Input
                                    size='large'
                                   
                                    value={goal.end_date}
                                    onChange={(e) => }
                                /> */}
                                <DatePicker
                                    size="large"
                                    // picker="month"
                                    style={{
                                        // border: "none",
                                        width: "100%",
                                        outline: "none",
                                        // background: "#FFFFFF",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#000000",
                                    }}
                                    onChange={(value) => {
                                        setGoal({ ...goal, end_date: value })
                                    }}
                                    // can we set default value as month name
                                    // defaultValue={moment().format("MMMM")}
                                    // defaultValue={dayjs().startOf('month')}
                                    value={goal.end_date}
                                    placeholder="Enter End Date"
                                    format={"DD MMMM YYYY"}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Goal Status"
                                name="status"
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Goal Title Here"
                                    value={goal.title}
                                    onChange={(e) => setGoal({ ...goal, status: e.target.value })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Achievement%"
                                name="achievement"
                            >
                                <Input
                                    size='large'
                                    placeholder="Enter Achievement"
                                    value={goal.achievement}
                                    onChange={(e) => setGoal({ ...goal, achievement: e.target.value })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Description"
                                name="description"
                            >
                                <TextArea
                                    rows={5}
                                    placeholder="Enter Description Here"
                                    value={goal.description}
                                    onChange={(e) => setGoal({ ...goal, description: e.target.value })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div style={{ display: "flex", gap: "20px" }}>
                    <button
                        style={{ color: "#008080", border: "1px solid #008080", background: "none", height: "40px", padding: "0 20px", borderRadius: "5px", fontWeight: "600" }}
                        onClick={() => {
                            setGoal(
                                {
                                    ...goal,
                                    sub_goals: [...goal.sub_goals,
                                    {
                                        title: "",
                                        description: "",
                                        start_date: "",
                                        end_date: ""
                                    }]
                                })
                        }}
                    >
                        + Sub Goal
                    </button>
                    <button
                        style={{ color: "white", border: "none", background: "#008080", height: "40px", padding: "0 20px", borderRadius: "5px", fontWeight: "600" }}
                        onClick={() => submitForm()}
                    >
                        Save
                    </button>
                </div>
                <br />
                <br />

                {
                    goal.sub_goals.map((item, index) => {
                        return (
                            <Form
                                layout="vertical"
                            >
                                <Row gutter={[32, 8]}>
                                    <Col span={24}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
                                            <p style={{ fontSize: "24px", fontWeight: "600", color: "#161616" }}>Sub Goal {index + 1}</p>
                                            <DeleteOutlined
                                                style={{ color: "red", fontSize: "20px" }}
                                                onClick={() => onDeleteSubGoal(index)} />
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Sub Goal Title"
                                            name="title"
                                        >
                                            <Input
                                                size='large'
                                                placeholder="Enter Goal Title Here"
                                                value={item.title}
                                                onChange={(e) => onHandleSubGoalChange("title", index, e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Start Date"
                                            name="start_date"
                                        >
                                            {/* <Input
                                                size='large'
                                                placeholder="Enter Start Date"
                                                value={item.start_date}
                                                onChange={(e) =>}
                                            /> */}
                                            <DatePicker
                                                size="large"
                                                // picker="month"
                                                style={{
                                                    // border: "none",
                                                    width: "100%",
                                                    outline: "none",
                                                    // background: "#FFFFFF",
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    color: "#000000",
                                                }}
                                                onChange={(value) => {
                                                    onHandleSubGoalChange("start_date", index, value)
                                                }}
                                                // can we set default value as month name
                                                // defaultValue={moment().format("MMMM")}
                                                // defaultValue={dayjs().startOf('month')}
                                                value={goal.start_date}
                                                placeholder="Enter Start Date"
                                                format={"DD MMMM YYYY"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="End Date"
                                            name="end_date"
                                        >
                                            {/* <Input
                                                size='large'
                                                placeholder="Enter End Date"
                                                value={item.end_date}
                                                onChange={(e) => }
                                            /> */}
                                            <DatePicker
                                                size="large"
                                                // picker="month"
                                                style={{
                                                    // border: "none",
                                                    width: "100%",
                                                    outline: "none",
                                                    // background: "#FFFFFF",
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    color: "#000000",
                                                }}
                                                onChange={(value) => {
                                                    // setGoal({ ...goal, start_date: value })
                                                    onHandleSubGoalChange("end_date", index, value)
                                                }}
                                                // can we set default value as month name
                                                // defaultValue={moment().format("MMMM")}
                                                // defaultValue={dayjs().startOf('month')}
                                                value={goal.start_date}
                                                placeholder="Enter Start Date"
                                                format={"DD MMMM YYYY"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Sub Goal Status"
                                            name="status"
                                        >
                                            <Input
                                                size='large'
                                                placeholder="Enter Goal Title Here"
                                                value={item.status}
                                                onChange={(e) => onHandleSubGoalChange("status", index, e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Achievement%"
                                            name="achievement"
                                        >
                                            <Input
                                                size='large'
                                                placeholder="Enter Achievement"
                                                value={item.achievement}
                                                onChange={(e) => onHandleSubGoalChange("achievement", index, e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Description"
                                            name="description"
                                        >
                                            <TextArea
                                                rows={5}
                                                placeholder="Enter Description Here"
                                                value={item.description}
                                                onChange={(e) => onHandleSubGoalChange("description", index, e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <button
                                    style={{ color: "white", border: "none", background: "#008080", height: "40px", padding: "0 20px", borderRadius: "5px", fontWeight: "600" }}
                                    onClick={() => submitForm()}
                                >Save</button>
                                {
                                    index !== goal.sub_goals.length - 1 && <hr style={{ width: "100%", border: "1px solid #ECECEC" }} />
                                }
                            </Form>
                        )
                    })
                }


            </div>
        </div>
    )
}

export default GoalForm