import React from 'react'
import styles from './CompanyName.module.scss'
import engageLogoBig from "../../assets/engageLogoBig.svg"
import { Form, Input, Button, center } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCompanyName } from '../../redux/reducer/CompName';

function CompanyName() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onFinish = (values) => {
        const companyName = (values.companyName.replace(/\s+/g, '')).toLocaleLowerCase();
        // console.log("values : ", values)
        // lowerCase the company name



        dispatch(setCompanyName(companyName))
        localStorage.setItem("companyName", companyName)
        // Send the tenant name to the React Native app
        if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
            // window.ReactNativeWebView.postMessage(JSON.stringify({ tenantName }));
            window.ReactNativeWebView.postMessage(JSON.stringify({ companyName }));
        } else {
            console.warn('ReactNativeWebView is not defined.');
        }
        navigate(`/${companyName}/login`, { replace: true })
        // window.open(`localhost:3000/eta/login`)
        // console.log("values : ", `/${companyName}/login`)


    };

    return (

        <div className={styles.appPageContainer}>

            <img src={engageLogoBig} alt="" className={styles.engageLogo} />
            <Form

                onFinish={onFinish}
                autoComplete="on"
            >
                <Form.Item
                    label="Company Name"
                    name="companyName"
                    rules={[
                        {
                            required: true,
                            message: 'Company Name must be required',
                        }
                    ]}
                >
                    <Input
                        size='large'
                        placeholder='Company Name'
                    />
                </Form.Item>
                <Button
                    htmlType="Submit"
                    className={styles.signInBtn}
                >
                    Submit
                </Button>
            </Form>



        </div>



    )
}

export default CompanyName