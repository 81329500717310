import React, { useEffect, useState } from 'react'
import "./CalendarScheduler.scss"
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import getScreenSize from "../../utils/getScreenSize"
import request from '../../utils/axios';

const localizer = momentLocalizer(moment);

function CalendarScheduler({ data, setCalendarMonth, setCalendarYear, type, employeeId, getMonthAndYearFromCalendar }) {
    const screenSize = getScreenSize();
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [leavesList, setLeavesList] = useState([]);

    const EventComponent = ({ event }) => {
        return (
            <div >
                <span
                    style={{
                        color: "#535151",
                        fontSize: "14px"
                    }}
                >
                    {event?.check_in_time && event?.check_in_time !== '-' ?
                        <>
                            <span>{event?.yet_to_checkin === false ? "Pending" : event?.check_in_time !== '-' && moment(event?.check_in_time).format('h:mm A')}</span> - <span> {event.check_out_time === "no_checkout" ? (<span style={{ color: "rgb(0, 96, 255)" }}>No Check Out</span>) : event?.is_pending === true ? "Pending" : event?.check_out_time === null ? "Pending" : event?.check_out_time !== '-' && moment(event?.check_out_time).format('h:mm A')}</span>
                        </> : ""}
                </span>

                <div style={{ marginTop: "10px", marginBottom: "15px" }}>
                    {event?.working_duration === "0h 0m" ?
                        "" :
                        <span
                            style={{
                                width: "140px",
                                backgroundColor: "#F3F3F3",
                                padding: "5px 20px",
                                color: "#535151",
                                fontSize: "12px",
                                fontWeight: "600"
                            }}>{event?.working_duration === "N/A" ? "Pending" : event?.is_pending === true ? "Pending" : event?.working_duration}</span>
                    }
                </div>

                {
                    event?.is_absent && !event?.is_weekly_holiday
                    &&
                    <div
                        style={{
                            width: "190px",
                            minWidth: "auto",
                            height: "30px",
                            borderLeft: "3px solid #F3557C",
                            backgroundColor: "#FFEEEF",
                            padding: "5px",
                            color: "#F3557C",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        Absent
                    </div>
                }
                {
                    event?.is_late_checked_in
                    &&
                    <div
                        style={{
                            width: "190px",
                            minWidth: "auto",
                            height: "30px",
                            borderLeft: "3px solid #f53444",
                            backgroundColor: "#FFEEEF",
                            padding: "5px",
                            color: "#F3557C",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        Late ClockIn
                    </div>
                }
                {
                    !event?.is_absent && !event?.on_leave && !event?.is_weekly_holiday && !event?.is_holiday && !event?.is_pending && !event?.is_half_day && !event?.yet_to_checkin && !event?.is_late_checked_in
                    &&
                    <div
                        style={{
                            width: "190px",
                            minWidth: "auto",
                            height: "30px",
                            borderLeft: "3px solid #20CA9A",
                            backgroundColor: "#F5FFFC",
                            padding: "5px",
                            color: "#20CA9A",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        Present
                    </div>
                }
                {
                    event?.is_weekly_holiday
                    &&
                    <div
                        style={{
                            width: "190px",
                            minWidth: "auto",
                            height: "30px",
                            borderLeft: "3px solid #0060FF",
                            backgroundColor: "#E9F1FF",
                            padding: "5px",
                            color: "#0060FF",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        Weekly Off
                    </div>
                }
                                {
                    event?.yet_to_checkin  && !event?.is_absent
                    &&
                    <div
                        style={{
                            width: "190px",
                            minWidth: "auto",
                            height: "30px",
                            borderLeft: "3px solid #0060FF",
                            backgroundColor: "#E9F1FF",
                            padding: "5px",
                            color: "#0060FF",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        Pending
                    </div>
                }
                {
                    event?.is_holiday
                    &&
                    <div
                        style={{
                            width: "190px",
                            minWidth: "auto",
                            height: "30px",
                            borderLeft: "3px solid #0060FF",
                            backgroundColor: "#E9F1FF",
                            padding: "5px",
                            color: "#0060FF",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        {event?.holiday_name}
                    </div>
                }
                {
                    event?.is_pending && !event?.is_half_day && !event?.on_leave && !event?.is_weekly_holiday && !event?.is_holiday && !event?.is_late_checked_in
                    &&
                    <div
                        style={{
                            width: "190px",
                            minWidth: "auto",
                            height: "30px",
                            borderLeft: "3px solid #0060FF",
                            backgroundColor: "#E9F1FF",
                            padding: "5px",
                            color: "#0060FF",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        Approval Pending
                    </div>
                }
                {
                    event?.is_half_day && !event?.on_leave && !event?.is_weekly_holiday && !event?.is_holiday && !event?.is_pending && !event?.is_late_checked_in
                    &&
                    <div
                        style={{
                            width: "190px",
                            minWidth: "auto",
                            height: "30px",
                            borderLeft: "3px solid #0060FF",
                            backgroundColor: "#E9F1FF",
                            padding: "5px",
                            color: "#0060FF",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        Half Day
                    </div>
                }
                {
                    event?.on_leave
                    &&
                    <>
                        {/* <div style={{ marginTop: "10px", marginBottom: "15px" }}>
                            <div style={{
                                width: "190px",
                                backgroundColor: "#F3F3F3",
                                padding: "10px 10px",
                                borderRadius: "3px"
                            }}>
                                <span
                                    style={{
                                        color: "#717070",
                                        fontSize: "15px",
                                        fontWeight: "500"
                                    }}>Comment
                                </span>
                                <br />
                                <span
                                    style={{
                                        color: "black",
                                        fontSize: "13px",
                                        fontWeight: "400"
                                    }}>{event?.leave_comment}</span>
                            </div>

                        </div> */}
                        <div
                            style={{
                                width: "190px",
                                minWidth: "auto",
                                height: "30px",
                                borderLeft: `3px solid ${event?.leave_color}`,
                                background: `${event?.leave_color}20`,
                                padding: "5px",
                                color: `${event?.leave_color}`,
                                fontSize: "12px",
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            {event?.leave_type}
                        </div>
                    </>
                }
            </div>
        );
    };

    const handleNavigate = async (newDate) => {
        const currentDate = moment();
        const navigatedDate = moment(newDate);

        const newMonth = navigatedDate.format('MM');
        const newYear = navigatedDate.format('YYYY');

        setCalendarMonth(newMonth);
        setCalendarYear(newYear);
        if (getMonthAndYearFromCalendar) {
            getMonthAndYearFromCalendar(newMonth, newYear);
        }
        setIsNextDisabled(navigatedDate.isSameOrAfter(currentDate, 'month'));
    };

    const CustomToolbar = (toolbar) => {
        const goToNext = () => {
            if (!isNextDisabled) {
                toolbar.onNavigate('NEXT');
            }
        };

        const currentDate = moment(toolbar.date);
        const currentMonth = currentDate.format('MMMM');
        const currentYear = currentDate.format('YYYY');


        return (
            <div className="rbc-toolbar">
                <div style={{ display: "flex" }}>
                    <div>
                        <span className="rbc-btn-group">
                            <button
                                type="button"
                                onClick={() => toolbar.onNavigate('PREV')}
                                style={{
                                    border: 'none',
                                    background: 'none',
                                    cursor: 'pointer',
                                    color: '#4F55FB',
                                    fontSize: "28px",
                                    fontWeight: "600"
                                }}
                            >
                                {'<'}
                            </button>
                            <span className="rbc-toolbar-label">{currentMonth} , {currentYear}</span>
                            <button
                                type="button"
                                onClick={goToNext} disabled={isNextDisabled}
                                style={{
                                    border: 'none',
                                    background: 'none',
                                    cursor: 'pointer',
                                    color: '#4F55FB',
                                    fontSize: "28px",
                                    fontWeight: "600"
                                }}
                            >
                                {'>'}
                            </button>
                        </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 30, marginLeft: "20px" }}>
                        {
                            leavesList?.map((item, index) => (
                                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                    <div style={{ width: "14px", height: "14px", backgroundColor: item?.color, borderRadius: "50%" }}></div>
                                    <span>{item?.name}</span>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>
        );
    };

    const fetchLeavesList = async () => {
        let employee_number
        if (type === "self") {
            employee_number = localStorage.getItem("employee_number");
        }
        else {
            employee_number = employeeId
        }
        try {
            if (employee_number) {
                const data = await request({
                    method: "get",
                    url: `leave/employee/leave_type/all/?employement_number=${employee_number}`,
                })
                setLeavesList(data.data)
            }

        } catch (error) {
            console.error('Error sending image to the server:', error.response.data.error);
        }
    }

    useEffect(() => {
        fetchLeavesList()
    }, [data])
    return (
        <div style={{ overflow: "auto" }}>
            {
                screenSize.width < 768
                    ?
                    <Calendar
                        localizer={localizer}
                        events={data}
                        startAccessor="start"
                        endAccessor="end"
                        components={{
                            event: EventComponent,
                            toolbar: CustomToolbar,
                        }}

                        style={{ height: 1000, width: 950 }}
                        onNavigate={handleNavigate}
                    />
                    :
                    <Calendar
                        localizer={localizer}
                        events={data}
                        startAccessor="start"
                        endAccessor="end"
                        components={{
                            event: EventComponent,
                            toolbar: CustomToolbar,
                        }}

                        style={{ height: 800 }}
                        onNavigate={handleNavigate}
                    />
            }

        </div>
    )
}

export default CalendarScheduler