// import { Col, Row, Select } from 'antd'
import {
    Col,
    Row,

} from "antd";
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import "../../../styles/_global.scss"
import request from '../../../utils/axios';
import DynamicTable from '../../../component/TableWithPagination/TableWithPagination';

import back from "../../../assets/Recruitment/back.svg"
import CommonTable from "../../../component/CommonTable/CommonTable";

import { saveAs } from 'file-saver';


function JobDetail() {
    const params = useParams();
    const navigate = useNavigate();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    const [currentFilter, setCurrentFilter] = useState("all");
    const columns = [
        {
            title: ' Name',
            dataIndex: 'imgUrl',
            key: 'a',
            render: (text, record) => <div style={{ display: "flex" }}>
                <div>
                    <p>{record.candidate_name}</p>
                </div>
            </div>
        },
        {
            title: 'Email',
            dataIndex: 'candidate_email',
            key: 'candidate_email',
        },
        {
            title: 'Phone no.',
            dataIndex: 'candidate_contact',
            key: 'candidate_contact',
        },
        {
            title: 'Linkdin URL',
            dataIndex: 'linkedin_url',
            key: 'linkedin_url',

        },
        {
            title: 'Resume',
            dataIndex: 'candidate_resume',
            key: 'candidate_resume',
            render: (text, record) =>
                <p style={{ backgroundColor: "#E2EDFF", color: "#0060FF", padding: "5px 0px", borderRadius: "15px", textAlign: "center", cursor: "pointer" }}
                        onClick={async () => {

                            try {
                            const response = await fetch(text);
                            const blob = await response.blob();
                            saveAs(blob, `resume_${record.candidate_name}.pdf`);
                            } catch (error) {
                            console.error('Error downloading PDF:', error);
                            }
                        }}
                    // onClick={async () => {
                    //     const response = await request({
                    //         method: "get",
                    //         url: `recruitment/recruiter/resume/download?id=${record.id}`,
                    //         responseType: 'arraybuffer',
                    //     })

                    //     const file = new Blob([response], { type: 'application/pdf' });
                    //     const url = window.URL.createObjectURL(file);
                    //     const link = document.createElement('a');
                    //     link.href = url;
                    //     link.download = 'file.pdf';
                    //     link.click();
                    // }}
                >
                    Download
                </p>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) =>
                <p style={{ backgroundColor: "#DBFFE9", color: "#28B15F", padding: "5px 0px", borderRadius: "15px", textAlign: "center" }}>{record.status}</p>

        },
    ]

    const [jobDetails, setJobDetails] = useState();

    const fetchData = async () => {
        const data = await request({
            method: "get",
            url: `recruitment/recruiter/job/details?id=${params.id}`,
        })


        setJobDetails(data.data.data)
    }

    const [applicantList, setApplicantList] = useState([]);
    const fetchRowData = async () => {
        const applicants = await request({
            method: "get",
            url: `recruitment/recruiter/applicant/list?job_id=${params.id}&page=${pagination.current}&page_size=${pagination.pageSize}&type=${currentFilter}`,

        }) 


        setApplicantList(applicants?.pagination_data.data)
        setPagination({
            ...pagination,
            total: applicants?.pagination_data?.total
        })
    }
    const onHandleFilterChange = (value) => {
        setPagination({
            ...pagination,
            current: 1,
            pageSize: 5,
            total: 0,
        })
        setCurrentFilter(value)
    }
    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        fetchRowData();
    }, [pagination.current , currentFilter])
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className='speficJobContainer' style={{ width: "93%" }}>
            <p onClick={() => { navigate(-1) }} style={{ cursor: "pointer" }}>
                <img src={back} alt="back" />
                <span style={{ paddingLeft: "10px" }}>Back</span>
            </p>
            <Row>
                <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                    <div>
                        <p className='heading'>{jobDetails?.job_title}</p>
                        <div dangerouslySetInnerHTML={{ __html: jobDetails?.job_description }} />
                    </div>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        {/* <div>
                            <p>20 open position. + Only {jobDetails?.job_type}</p>
                            <p>{jobDetails?.job_type} {jobDetails?.location}</p>
                        </div> */}
                    </div>
                </Col>
            </Row>
            <br />
            <br />
            <Row>
                <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                    <div className='flexSpaceBetween'>
                    <span className='jobTag' onClick={() =>onHandleFilterChange("all")}>All Candidates ({jobDetails?.applicant_count})</span>
                        <span className='jobTag' onClick={() => onHandleFilterChange("in_process")}>Inprocess ({jobDetails?.in_progress_count})</span>
                        <span className='jobTag' onClick={() => onHandleFilterChange("selected")}>Selected ({jobDetails?.selected_count})</span>
                        <span className='jobTag' onClick={() => onHandleFilterChange("rejected")}>Rejected ({jobDetails?.rejected_count})</span>
                    </div>
                </Col>
            </Row>
            <CommonTable
                columns={columns}
                data={applicantList}
                pagination={pagination}
                setPagination={setPagination}
                handleRowClick={() => { }}
            />
        </div >
    </div>
    )
}


export default JobDetail
