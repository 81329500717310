import React, { useState } from 'react'
import styles from "./CalendarSearchBar.module.scss";
import request from '../../../../../utils/axios';

function CalendarSearchBar({
    setEmployeeId,
    setCalendarData,
    empType
}) {
    const [searchEmployeeList, setSearchEmployeeList] = useState([])
    const handleSelectChange = async (value) => {
        try {
            setEmployeeId(value)

            setSearchEmployeeList([])
        }
        catch (err) {
            console.log(err);
        }

    }

    const search = async (data) => {
        try {
            const response = await request({
                method: "get",
                url: `leave/search/employees/?employee_name=${data}&type=${empType}`,

            })
            setSearchEmployeeList(response)
            if (data === "" || data === undefined) {
                setEmployeeId("")
                setCalendarData([])
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className={styles.searchContainer}>
            <input
                type="text"
                placeholder={"Search Employee"}
                onChange={(e) => search(e.target.value)}
            />
            {searchEmployeeList.length > 0 && (
                <ul>
                    {searchEmployeeList.map((item, index) => (
                        <li key={index} onClick={() => handleSelectChange(item.employement_number)}>
                            {item.employee_name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default CalendarSearchBar