import React, { useEffect, useRef, useState } from 'react';
import getScreenSize from '../../../../utils/getScreenSize';
import add from "../../../../assets/Org/add.svg"
import downloadImg from "../../../../assets/downlaod.svg";
import back from "../../../../assets/Recruitment/back.svg"
import yellowTick from "../../../../assets/Payroll/yellowTick.svg";
import NetIncome from "../../../../assets/Payroll/NetIncome.png";
import Earnings from "../../../../assets/Payroll/Earnings.png";
import Deductions from "../../../../assets/Payroll/Deductions.png";
import download from "../../../../assets/downlaod.svg"

import { Col, DatePicker, Row, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import request from '../../../../utils/axios';
import dayjs from 'dayjs';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

function PayRollSummaryFullView() {
    const ref = useRef(null);
    const screenSize = getScreenSize();
    const navigate = useNavigate();

    const [paySlipMonthYear, setPaySlipMonthYear] = useState({
        month: "",
        year: dayjs(new Date()).format('YYYY'),
    })
    const [selectedMonthForDownloadPayslip, setSelectedMonthForDownloadPayslip] = useState("")
    const [availableMonthPaySlip, setAvailableMonthPaySlip] = useState([])
    const [employeePayrollSummry, setEmployeePayrollSummary] = useState();
    const [showEarnings, setShowEarnings] = useState(false);
    const [showDeductions, setShowDeductions] = useState(false);


    const getAvailableMonthPaySlip = async () => {
        try {
            const response = await request({
                method: "get",
                url: `payroll/employee-monthly-paylist?year=${paySlipMonthYear.year}`,
            })

            let lastPaySlipAvailableMonth;
            response.data.forEach(item => {
                if (item.status) {
                    lastPaySlipAvailableMonth = item
                }
            })
            setAvailableMonthPaySlip([...response.data])
            setSelectedMonthForDownloadPayslip(lastPaySlipAvailableMonth.month)
            setPaySlipMonthYear({ ...paySlipMonthYear, month: lastPaySlipAvailableMonth.month_id })
        }
        catch (error) {
            console.log(error)
        }
    }

    const fetchEmployeePayrollSummary = async () => {
        try {
            if (paySlipMonthYear.month) {
                let data = await request({
                    method: "get",
                    url: `payroll/employee-payroll-summary?month=${paySlipMonthYear.month}&year=${paySlipMonthYear.year}`,
                })
                console.log("data...///..", data.data)
                setEmployeePayrollSummary(data.data)
            }

        }
        catch (error) {
            console.log("error")
        }
    }

    const downloadPaySlip = async () => {
        try {
            const response = await request({
                method: "get",
                url: `payroll/employee-monthly-payslip?month=${paySlipMonthYear.month}&year=${paySlipMonthYear.year}`,
                responseType: 'arraybuffer',
            })

            const file = new Blob([response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(file);
            const link = document.createElement('a');
            const fileName = `payslip_${paySlipMonthYear.month}_${paySlipMonthYear.year}.pdf`;
            link.href = url;
            link.download = fileName
            link.click();
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    }

    useEffect(() => {
        getAvailableMonthPaySlip();
    }, [paySlipMonthYear.year])

    useEffect(() => {
        console.log("paySlipMonthYear...,paySlipMonthYear", paySlipMonthYear);
        fetchEmployeePayrollSummary();
    }, [paySlipMonthYear.month, paySlipMonthYear.year])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div ref={ref}>
            {
                screenSize.width < 768
                    ?

                    <div style={{ padding: "10px 16px" }}>
                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Payroll Summary</p>
                        <DatePicker
                            onChange={
                                (date, dateString) => {
                                    setPaySlipMonthYear({ ...paySlipMonthYear, year: dateString })
                                    setSelectedMonthForDownloadPayslip("")
                                }
                            }
                            value={dayjs(paySlipMonthYear.year)}
                            picker="year" />
                        <div style={{ display: "flex", gap: "30px", overflowX: "auto" }}>
                            {
                                availableMonthPaySlip?.map((item, index) => {
                                    return (
                                        <>
                                            {
                                                selectedMonthForDownloadPayslip === item.month
                                                    ?
                                                    <div style={{ margin: "10px 0px 20px 0px", background: "#FBFBFB", border: "1px solid #008080", padding: "10px 15px", borderRadius: "4px" }}
                                                        onClick={() => {
                                                            setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                                            setSelectedMonthForDownloadPayslip(item.month)
                                                        }
                                                        }
                                                    >
                                                        <center>
                                                            {
                                                                <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} width={30} height={30} />
                                                            }
                                                            <p style={{ fontSize: "12px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                                        </center>
                                                    </div>
                                                    :
                                                    <div style={{ margin: "10px 0px 20px 0px", padding: "10px 0px", }}
                                                        onClick={() => {
                                                            if (item.status) {
                                                                setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                                                setSelectedMonthForDownloadPayslip(item.month)
                                                            }
                                                        }}
                                                    >
                                                        <center>
                                                            {
                                                                item.status
                                                                    ?


                                                                    <img src={yellowTick} alt="yellowTick" width={30} height={30} style={{ cursor: "pointer" }} />


                                                                    :
                                                                    <div style={{ width: "30px", height: "30px", borderRadius: "50%", border: "2px solid #FBA602", display: "flex", justifyContent: "center", alignItems: "center", color: "#FBA602", fontSize: "12px", fontWeight: "600", cursor: "not-allowed" }}>
                                                                        {index + 1}
                                                                    </div>
                                                            }
                                                            <p style={{ fontSize: "12px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                                        </center>
                                                    </div>
                                            }
                                        </>

                                    )
                                })
                            }
                        </div>

                        {
                            paySlipMonthYear.month
                            &&
                            <div>
                                {/* Net Income */}
                                <div style={{ padding: "20px 0px" }}>
                                    <div>
                                        <Row>
                                            <Col span={16}>
                                                <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616", margin: "0" }}>
                                                    <img src={NetIncome} alt="NetIncome" width={20} height={20} style={{ marginRight: "10px" }} />
                                                    Net Income</p>
                                            </Col>
                                            <Col span={8}>
                                                <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", margin: "0" }}>₹ {employeePayrollSummry?.net_pay}</p>
                                            </Col>
                                        </Row>
                                    </div>

                                </div>

                                {/* Earnings */}
                                <div >
                                    <Row>
                                        <Col span={16}>
                                            <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616" }}>
                                                <img src={Earnings} alt="Earnings" width={20} height={20} style={{ marginRight: "10px" }} />
                                                Earnings</p>
                                        </Col>
                                        <Col span={8}>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616", margin: "0" }}>₹ {employeePayrollSummry?.total_earnings_amount}
                                                </p>
                                                {
                                                    showEarnings
                                                        ?
                                                        <UpOutlined
                                                            style={{ fontSize: "16px", color: "#717070" }}
                                                            onClick={() => setShowEarnings(false)}
                                                        />
                                                        :
                                                        <DownOutlined style={{ fontSize: "16px", color: "#717070" }}
                                                            onClick={() => setShowEarnings(true)}
                                                        />
                                                }
                                            </div>
                                        </Col>
                                    </Row>

                                    {
                                        showEarnings
                                        &&
                                        <>
                                            <>
                                                {
                                                    employeePayrollSummry?.earnings?.map((item, index) => {
                                                        return (
                                                            <Row>
                                                                <Col span={16}>
                                                                    <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>{item.type}</p>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {item.monthly_amount}</p>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })
                                                }
                                            </>
                                            <Row>
                                                {
                                                    employeePayrollSummry?.reimbursement > 0
                                                    &&
                                                    <>
                                                        <Col span={16}>
                                                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Reimbursement</p>
                                                        </Col>
                                                        <Col span={8}>
                                                            <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.reimbursement}</p>
                                                        </Col>
                                                    </>
                                                }
                                                {
                                                    employeePayrollSummry?.arrear_amount > 0
                                                    &&
                                                    <>
                                                        <Col span={16}>
                                                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Arrear Payment</p>
                                                        </Col>
                                                        <Col span={8}>
                                                            <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.arrear_amount}</p>
                                                        </Col>
                                                    </>
                                                }
                                                {
                                                    employeePayrollSummry?.adhoc_payment_amount > 0
                                                    &&
                                                    <>
                                                        <Col span={16}>
                                                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Adhoc-Payment</p>
                                                        </Col>
                                                        <Col span={8}>
                                                            <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.adhoc_payment_amount}</p>
                                                        </Col>
                                                    </>
                                                }

                                            </Row>
                                        </>
                                    }

                                </div>

                                {/* Deductions */}
                                <div>
                                    <Row>
                                        <Col span={16}>
                                            <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616" }}>
                                                <img src={Deductions} alt="Deductions" width={20} height={20} style={{ marginRight: "10px" }} />
                                                Deductions</p>
                                        </Col>
                                        <Col span={8}>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <p style={{ fontSize: "16PX", fontWeight: "600", color: "#161616", margin: "0" }}>₹ {employeePayrollSummry?.total_deductions_amount}</p>
                                                {
                                                    showDeductions
                                                        ?
                                                        <UpOutlined
                                                            style={{ fontSize: "16px", color: "#717070" }}
                                                            onClick={() => setShowDeductions(false)}
                                                        />
                                                        :
                                                        <DownOutlined style={{ fontSize: "16px", color: "#717070" }}
                                                            onClick={() => setShowDeductions(true)}
                                                        />
                                                }


                                            </div>
                                        </Col>
                                    </Row>
                                    {
                                        showDeductions
                                        &&
                                        <>
                                            {
                                                employeePayrollSummry?.deductions?.map((item, index) => {
                                                    return (
                                                        <Row>
                                                            <Col span={16}>
                                                                <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>{item.type}</p>
                                                            </Col>
                                                            <Col span={8}>
                                                                <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {item.monthly_amount}</p>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                            {
                                                employeePayrollSummry?.income_tax > -1
                                                &&
                                                <Row>
                                                    <Col span={16}>
                                                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Income Tax</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.income_tax}</p>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                employeePayrollSummry?.lop > 0
                                                &&
                                                <Row>
                                                    <Col span={16}>
                                                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Loss of Pay</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.lop}</p>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                employeePayrollSummry?.adhoc_deductions > 0
                                                &&
                                                <Row>
                                                    <Col span={16}>
                                                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Adhoc-Deductions</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.adhoc_deductions}</p>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                employeePayrollSummry?.advance_salary > 0
                                                &&
                                                <Row>
                                                    <Col span={16}>
                                                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Advance Salary</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.advance_salary}</p>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                employeePayrollSummry?.loan_amount > 0
                                                &&
                                                <Row>
                                                    <Col span={16}>
                                                        <p style={{ fontSize: "16px", fontWeight: "400", color: "#333333" }}>Loan Amount</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.loan_amount}</p>
                                                    </Col>
                                                </Row>
                                            }

                                        </>
                                    }


                                </div>
                            </div>
                        }
                    </div>

                    :
                    <div style={{ margin: "50px", background: "white", padding: "20px", borderRadius: "4px" }}>
                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Payroll Summary</p>
                        <DatePicker
                            onChange={
                                (date, dateString) => {
                                    setPaySlipMonthYear({ ...paySlipMonthYear, year: dateString })
                                    setSelectedMonthForDownloadPayslip("")
                                }
                            }
                            value={dayjs(paySlipMonthYear.year)}
                            picker="year" />
                        <div style={{ display: "flex", gap: "50px" }}>
                            {
                                availableMonthPaySlip?.map((item, index) => {
                                    return (
                                        <>
                                            {
                                                selectedMonthForDownloadPayslip === item.month
                                                    ?
                                                    <div style={{ margin: "10px 0px 20px 0px", background: "#FBFBFB", border: "1px solid #008080", padding: "10px 15px", borderRadius: "4px" }}
                                                        onClick={() => {
                                                            setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                                            setSelectedMonthForDownloadPayslip(item.month)
                                                        }
                                                        }
                                                    >
                                                        <center>
                                                            {
                                                                <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} />
                                                            }
                                                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                                        </center>
                                                    </div>
                                                    :
                                                    <div style={{ margin: "10px 0px 20px 0px", padding: "10px 0px", }}
                                                        onClick={() => {
                                                            if (item.status) {
                                                                setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                                                setSelectedMonthForDownloadPayslip(item.month)
                                                            }
                                                        }}
                                                    >
                                                        <center>
                                                            {
                                                                item.status
                                                                    ?


                                                                    <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} />


                                                                    :
                                                                    <div style={{ width: "40px", height: "40px", borderRadius: "50%", border: "2px solid #FBA602", display: "flex", justifyContent: "center", alignItems: "center", color: "#FBA602", fontWeight: "800", cursor: "not-allowed" }}>
                                                                        {index + 1}
                                                                    </div>
                                                            }
                                                            <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                                        </center>
                                                    </div>
                                            }
                                        </>

                                    )
                                })
                            }
                        </div>

                        <br />
                        {
                            paySlipMonthYear.month
                            &&
                            <div>
                                <div style={{ display: "flex", gap: "100px", justifyContent: "space-between", padding: "20px 0px" }}>
                                    <div style={{ width: "50%" }}>
                                        <Row>
                                            <Col span={16}>
                                                <p style={{ fontSize: "24px", fontWeight: "500", color: "#28B15F", margin: "0" }}>Net Income</p>
                                            </Col>
                                            <Col span={8}>
                                                <p style={{ fontSize: "24px", fontWeight: "500", color: "#28B15F", margin: "0" }}>₹ {employeePayrollSummry?.net_pay}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div style={{ width: "50%", display: "flex", justifyContent: "end" }}>

                                        <img src={download} alt="download"
                                            onClick={() => downloadPaySlip()}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: "flex", width: "100%" }}>
                                    {/* Earnings */}
                                    <div style={{ width: "50%" }}>

                                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>Earnings</p>
                                        <>
                                            {
                                                employeePayrollSummry?.earnings?.map((item, index) => {
                                                    return (
                                                        <Row>
                                                            <Col span={16}>
                                                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>{item.type}</p>
                                                            </Col>
                                                            <Col span={8}>
                                                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {item.monthly_amount}</p>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </>

                                    </div>
                                    {/* Deductions */}
                                    <div style={{ width: "50%" }}>
                                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>Deductions</p>
                                        <>
                                            {
                                                employeePayrollSummry?.deductions?.map((item, index) => {
                                                    return (
                                                        <Row>
                                                            <Col span={16}>
                                                                <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>{item.type}</p>
                                                            </Col>
                                                            <Col span={8}>
                                                                <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {item.monthly_amount}</p>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                            {
                                                employeePayrollSummry?.income_tax > -1
                                                &&
                                                <Row>
                                                    <Col span={16}>
                                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Income Tax</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.income_tax}</p>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                employeePayrollSummry?.lop > 0
                                                &&
                                                <Row>
                                                    <Col span={16}>
                                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Loss of Pay</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.lop}</p>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                employeePayrollSummry?.adhoc_deductions > 0
                                                &&
                                                <Row>
                                                    <Col span={16}>
                                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Adhoc-Deductions</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.adhoc_deductions}</p>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                employeePayrollSummry?.advance_salary > 0
                                                &&
                                                <Row>
                                                    <Col span={16}>
                                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Advance Salary</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.advance_salary}</p>
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                employeePayrollSummry?.loan_amount > 0
                                                &&
                                                <Row>
                                                    <Col span={16}>
                                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Loan Amount</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.loan_amount}</p>
                                                    </Col>
                                                </Row>
                                            }

                                        </>

                                    </div>
                                </div>

                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "50%" }}>
                                        <Row>
                                            {
                                                employeePayrollSummry?.reimbursement > 0
                                                &&
                                                <>
                                                    <Col span={16}>
                                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Reimbursement</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.reimbursement}</p>
                                                    </Col>
                                                </>
                                            }
                                            {
                                                employeePayrollSummry?.arrear_amount > 0
                                                &&
                                                <>
                                                    <Col span={16}>
                                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Arrear Payment</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.arrear_amount}</p>
                                                    </Col>
                                                </>
                                            }
                                            {
                                                employeePayrollSummry?.adhoc_payment_amount > 0
                                                &&
                                                <>
                                                    <Col span={16}>
                                                        <p style={{ fontSize: "18px", fontWeight: "400", color: "#333333" }}>Adhoc-Payment</p>
                                                    </Col>
                                                    <Col span={8}>
                                                        <p style={{ fontSize: "18px", fontWeight: "600", color: "#161616" }}>₹ {employeePayrollSummry?.adhoc_payment_amount}</p>
                                                    </Col>
                                                </>
                                            }

                                        </Row>
                                    </div>
                                    <div style={{ width: "50%" }}>

                                    </div>
                                </div>
                                <Row>
                                    <Col span={8}>
                                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#28B15F" }}>Gross Earnings</p>
                                    </Col>
                                    <Col span={4}>
                                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#28B15F" }}>₹ {employeePayrollSummry?.total_earnings_amount}</p>
                                    </Col>
                                    <Col span={8}>
                                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#FA0D0D" }}>Gross Deductions</p>
                                    </Col>
                                    <Col span={4}>
                                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#FA0D0D" }}>₹ {employeePayrollSummry?.total_deductions_amount}</p>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </div>

            }

        </div>
    )
}

export default PayRollSummaryFullView