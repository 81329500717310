import { createSlice } from '@reduxjs/toolkit'


const form24SettingSlice = createSlice({
    name: 'form24Setting',
    initialState: {
        particularsOfReturn: {
            tan: '',
            section: '',
        },
        particularsOfDeductor: {
            name: '',
            email: '',
            pincode: '',
            address_changed: '',
            building: '',
            street: '',
            city: '',
            state: '',
            gstin: '',
            branch: '',
            telephone_no: '',
            mobile_no: '',
            pan_no: '',
        },
        personResponsibleForDeduction: {
            name: '',
            email: '',
            pincode: '',
            designation: '',
            mobile_no: '',
            address_changed: '',
            building: '',
            street: '',
            city: '',
            state: '',
            telephone_no: '',
            pan_no: '',
        },
        toggleOption: [],

    },
    reducers: {
        setToggleOption: (state, action) => {
            state.toggleOption = action.payload
        },

        setParticularsOfReturn: (state, action) => {
            const {
                tan,
                section,
            } = action.payload;

            state.particularsOfReturn.tan = tan;
            state.particularsOfReturn.section = section;
        },
        setParticularsOfDeductor: (currentstate, action) => {
            const {
                name,
                address_changed,
                gstin,
                branch,
                building,
                street,
                city,
                state,
                pincode,
                email,
                telephone_no,
                mobile_no,
                pan_no,
            } = action.payload;

            currentstate.particularsOfDeductor.name = name;
            currentstate.particularsOfDeductor.address_changed = address_changed;
            currentstate.particularsOfDeductor.gstin = gstin;
            currentstate.particularsOfDeductor.branch = branch;
            currentstate.particularsOfDeductor.building = building;
            currentstate.particularsOfDeductor.street = street;
            currentstate.particularsOfDeductor.city = city;
            currentstate.particularsOfDeductor.state = state;
            currentstate.particularsOfDeductor.pincode = pincode;
            currentstate.particularsOfDeductor.email = email;
            currentstate.particularsOfDeductor.telephone_no = telephone_no;
            currentstate.particularsOfDeductor.mobile_no = mobile_no;
            currentstate.particularsOfDeductor.pan_no = pan_no;

        },
        setPersonResponsibleForDeduction: (currentstate, action) => {
            const {
                name,
                designation,
                address_changed,
                flat_no,
                building,
                street,
                city,
                state,
                pincode,
                email,
                telephone_no,
                mobile_no,
                pan_no,
            } = action.payload;

            currentstate.personResponsibleForDeduction.name = name;
            currentstate.personResponsibleForDeduction.designation = designation;
            currentstate.personResponsibleForDeduction.address_changed = address_changed;
            currentstate.personResponsibleForDeduction.flat_no = flat_no;
            currentstate.personResponsibleForDeduction.building = building;
            currentstate.personResponsibleForDeduction.street = street;
            currentstate.personResponsibleForDeduction.city = city;
            currentstate.personResponsibleForDeduction.state = state;
            currentstate.personResponsibleForDeduction.pincode = pincode;
            currentstate.personResponsibleForDeduction.email = email;
            currentstate.personResponsibleForDeduction.telephone_no = telephone_no;
            currentstate.personResponsibleForDeduction.mobile_no = mobile_no;
            currentstate.personResponsibleForDeduction.pan_no = pan_no;
        },


    },
})
export const { setParticularsOfReturn, setParticularsOfDeductor, setPersonResponsibleForDeduction, setToggleOption } = form24SettingSlice.actions;

export default form24SettingSlice.reducer;