import React, { useState } from 'react';
import { Button, Steps } from 'antd';

import "./OnBoarding.scss";
import PersonalDetails from './PersonalDetails/PersonalDetails';
import EmploymentDetails from './EmploymentDetails/EmploymentDetails';
import CompanyPolicy from './CompanyPolicy/CompanyPolicy';
import BankDetails from './BankDetails/BankDetails';
import getScreenSize from "../../utils/getScreenSize";
import CustomStepper from '../../component/CustomStepper/CustomStepper';
import UploadDocs from './UploadDocs/UploadDocs';

const steps = [
    {
        title: 'Personal Details',
        content: 'Please enter your personal details.',
        // icon: 'user',
    },
    {
        title: 'Employment Details',
        content: 'Please enter your employment details.',
        // icon: 'briefcase',
    },
    {
        title: 'Bank Details',
        content: 'Fill up your Bank details',
        // icon: 'file-text',
    },
    {
        title: 'Upload Documents',
        content: 'Please upload documents.',
        // icon: 'file-text',
    },
    {
        title: 'Company Policy',
        content: 'Please agree to the company policy.',
        // icon: 'file-text',
    },
];

function OnBoarding() {
    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const submit = () => {
        // TODO: Submit the form data.
    };

    const screenSize = getScreenSize();

    return (
        <div>
            {
                screenSize.width < 768 ?
                    <>
                        <div style={{ background: "#008080" }}>
                            <p style={{ color: "#fff", padding: "16px" }}>Welcome to Engage</p>
                        </div>
                        <div className='onBoardingContainer'>
                            <div style={{ width: "100%", overflow: "auto" }}>
                                <CustomStepper steps={steps} current={current} />
                            </div>

                            <div>
                                {current === 0 && (
                                    <PersonalDetails next={next} />
                                )}
                                {current === 1 && (
                                    <EmploymentDetails next={next} prev={prev} />
                                )}
                                {current === 2 && (
                                    <BankDetails next={next} prev={prev} />
                                )}
                                {current === 3 && (
                                    <UploadDocs prev={prev} next={next} />
                                )}
                                {current === 4 && (
                                    <CompanyPolicy prev={prev} />
                                )}

                            </div>
                        </div>
                    </>
                    :
                    <div className='onBoardingContainer'>

                        <div className='stepsContainer'>
                            <Steps direction='horizontal' current={current} items={steps} />
                        </div>
                        <div  >
                            {current === 0 && (
                                <PersonalDetails next={next} />
                            )}
                            {current === 1 && (
                                <EmploymentDetails next={next} prev={prev} />
                            )}
                            {current === 2 && (
                                <BankDetails next={next} prev={prev} />
                            )}
                            {current === 3 && (
                                <UploadDocs prev={prev} next={next} />
                            )}
                            {current === 4 && (
                                <CompanyPolicy prev={prev} />
                            )}

                        </div>
                    </div>
            }
        </div>
    )
}

export default OnBoarding