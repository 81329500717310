import {
  Select,
  Tabs,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./Tabs.scss";
import "../../../../styles/_global.scss";

import getScreenSize from "../../../../utils/getScreenSize";
import AttendanceDashboardView from "../Dashboard/AttendanceView";
import MyTeam from "../ReportingManager/MyTeam/MyTeam";
import LogsFullView from "../Employee/LogsFullView/LogsFullView";
import request from "../../../../utils/axios";
function Attendance() {

  const screenSize = getScreenSize();


  const changeTabsValue = (e) => {
    setTabsValue(e)
  }

  const tab_items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <AttendanceDashboardView changeTabsValue={changeTabsValue} />,
    },
    {
      key: '2',
      label: 'My Team',
      children: <MyTeam />,
    },
    {
      key: '3',
      label: 'My Attendance Logs',
      children: <LogsFullView />,
    },
  ];

  const tab_item_withOutReporting = [
    {
      key: '1',
      label: 'Dashboard',
      children: <AttendanceDashboardView changeTabsValue={changeTabsValue} />,
    },
    {
      key: '3',
      label: 'My Attendance Logs',
      children: <LogsFullView />,
    },
  ];


  const [tabsValue, setTabsValue] = useState("1")
  const [isReporting, setIsReporting] = useState(false);

  const myTeamDetail = async () => {
    const data = await request({
      method: "get",
      url: `attendance/my-team/widget/`,
    });

    setIsReporting(data.is_reporting);
  };

  const ref = useRef(null);
  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: "instant" });
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    myTeamDetail();
  }, []);

  return (
    <div className="" ref={ref}>
      {screenSize.width < 768 ? (
        // mobile view
        <Tabs
          activeKey={tabsValue}
          items={isReporting ? tab_items : tab_item_withOutReporting}
          onChange={changeTabsValue}
        />
      ) : (
        // desktop view
        <Tabs
          activeKey={tabsValue}
          items={isReporting ? tab_items : tab_item_withOutReporting}
          onChange={changeTabsValue}
        />
      )}


    </div>
  );
}

export default Attendance;
