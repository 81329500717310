import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import "./ApplyJob.scss"
import request from '../../../../utils/axios';
import { useParams } from 'react-router-dom'

function ApplyJob({ title, jobId, btnContent, loginUserData }) {
    const params = useParams();
    const [form] = Form.useForm()
    const [open, setOpen] = useState(false);
    const [userDetail, setUserDetail] = useState({
        candidate_name: "",
        candidate_email: "",
        candidate_contact: "",
        current_company: "",
        linkedin_url: "",
        candidate_resume: "",
    })

    const onFinish = async (values) => {
        const formData = new FormData();
        formData.append('candidate_name', values.candidate_name);
        formData.append('candidate_email', values.candidate_email);
        formData.append('candidate_contact', values.candidate_contact);
        formData.append('current_company', values.current_company);
        formData.append('linkedin_url', values.linkedin_url);
        formData.append('candidate_resume', resumeFile);
        if (params.id) {
            formData.append('job', params.id);
        }
        else {
            formData.append('job', jobId);
        }

        if (btnContent === "Apply") {
            await request({
                method: "post",
                url: `recruitment/create-applicant`,
                data: formData
            })
        }
        else {
            await request({
                method: "post",
                url: `recruitment/referral/applicant/create`,
                data: formData
            })
        }

        setUserDetail({
            candidate_name: "",
            candidate_email: "",
            candidate_contact: "",
            current_company: "",
            linkedin_url: "",
            candidate_resume: "",
        })
        setOpen(false)
        setResumeFile(null);
        form.resetFields();
    }

    useEffect(() => {
        setUserDetail({ ...loginUserData })
    }, [])

    const handleFormChange = (changedValues, allValues) => {
        if (!changedValues?.candidate_resume) {
            if (!userDetail.candidate_resume) {
                setUserDetail({ ...userDetail, ...changedValues, candidate_resume: undefined });
            }
            else {
                setUserDetail({ ...userDetail, ...changedValues });
            }
        }
    }

    const [resumeFile, setResumeFile] = useState(null);


    return (
        <div onClick={(e) => { e.stopPropagation() }} >
            <Button type="primary" style={{ backgroundColor: "#008080" }} onClick={() => setOpen(true)}>
                {btnContent}
            </Button>
            <Modal
                title={title}
                centered
                open={open}
                footer={null}
                width={1000}
                onCancel={() => setOpen(false)}
            >
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={userDetail}
                    onValuesChange={handleFormChange}
                    form={form}
                    fields={[
                        {
                            name: ["candidate_name"],
                            value: userDetail?.candidate_name ? userDetail?.candidate_name : loginUserData?.candidate_name
                        },
                        {
                            name: ["candidate_email"],
                            value: userDetail?.candidate_email ? userDetail?.candidate_email : loginUserData?.candidate_email
                        },
                        {
                            name: ["candidate_contact"],
                            value: userDetail?.candidate_contact ? userDetail?.candidate_contact : loginUserData?.candidate_contact
                        },
                        {
                            name: ["current_company"],
                            value: userDetail?.current_company ? userDetail?.current_company : loginUserData?.current_company
                        },
                        {
                            name: ["linkedin_url"],
                            value: userDetail?.linkedin_url
                        },
                    ]}
                >
                    <Row>

                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Name"
                                name="candidate_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your name!',
                                    },
                                ]}
                            >
                                <Input
                                    className='inputField'
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Email Id"
                                name="candidate_email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your email!',
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address!',
                                    },
                                ]}
                            >
                                <Input
                                    className='inputField'
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Phone no."
                                name="candidate_contact"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your contact!',
                                    },
                                    {
                                        pattern: /^[0-9]{10}$/, // A regular expression that matches a 10-digit contact number
                                        message: 'Please enter a valid 10-digit contact number!',
                                    },
                                ]}
                            >
                                <Input
                                    className='inputField'
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="Current Company"
                                name="current_company"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Current  Company!',
                                    }
                                ]}
                            >
                                <Input
                                    className='inputField'
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                label="LinkedIn Url"
                                name="linkedin_url"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your linkedin_url!',
                                    },
                                ]}
                            >
                                <Input
                                    className='inputField'
                                    style={{ width: "100% " }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <Form.Item
                                name="candidate_resume"
                                label="Resume"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please upload resume!',
                                    },
                                ]}
                            >
                                <input
                                    type='file'
                                    className='inputField'
                                    name='candidate_resume'
                                    onChange={(e) => setResumeFile(e.target.files && e.target.files[0])}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Button
                        style={{
                            height: "40px",
                            padding: "0px 20px",
                            background: "#008080",
                            color: "white",
                            fontWeight: "600",
                        }}
                        htmlType='submit'
                    >Apply</Button>
                </Form>
            </Modal>
        </div>
    );
}

export default ApplyJob

