import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import request from "../../../../utils/axios";
import { toast } from "react-toastify";
import getScreenSize from "../../../../utils/getScreenSize";

function LmsVideo() {
  const params = useParams();
  const navigate = useNavigate();
  const [videoTotalDuration, setVideoTotalDuration] = useState(0);
  const screenSize = getScreenSize();

  const [nextBtnStatus, setNextBtnStatus] = useState(true);

  const [videoUrl, setVideoUrl] = useState({
    video_url: "",
    is_quiz_available: "",
  });

  const fetchVideoUrl = async () => {
    const response = await request({
      url: `/lms/employee/video/get?id=${params.id}`,
      method: "GET",
    });

    setVideoUrl(response?.data);
  };

  const handleProgress = (data) => {
    if ((data.playedSeconds * 100) / videoTotalDuration > 50) {
      setNextBtnStatus(false);
    }
  };

  const handleDuration = (duration) => {
    setVideoTotalDuration(duration);
  };

  const onHandleClick = async () => {
    if (videoUrl.is_quiz_available) {
      navigate(`/lms/quiz/${params.id}`, { replace: true });
    } else {
      const response = await request({
        url: `/lms/employee/training-video/complete`,
        method: "post",
        data: {
          training_video: params.id,
          is_video_completed: true,
        },
      });
      toast.success("Training video completed successfully", {
        position: "bottom-left",
      });
      navigate("/lms", { replace: true });
    }
  };

  useEffect(() => {
    fetchVideoUrl();
  }, []);

  return (
    <div>
      {screenSize.width < 768 ? (
        <div>
          <div
            style={{
              padding: "0px 16px",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <p
              style={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#4A4A4A",
                marginTop: "70px",
              }}
            >
              Personal development Video
            </p>

            <div style={{ marginTop: "0px" }}>
              <ReactPlayer
                className="react-player"
                url={videoUrl.video_url}
                width="100%"
                height="220px"
                controls={true}
                onProgress={handleProgress}
                onDuration={handleDuration}
              />
            </div>

            <button
              style={{
                width: "92%",
                height: "40px",
                borderRadius: "5px",
                border: "none",
                color: nextBtnStatus ? "#5F5F5F" : "white",
                backgroundColor: nextBtnStatus ? "#DBDBDB" : "#008080",
                cursor: nextBtnStatus ? "not-allowed" : "pointer", // Change cursor if disabled
                position : "absolute",
                bottom : "90px",
              }}
              disabled={nextBtnStatus}
              onClick={() => {
                onHandleClick();
                console.log("clicked...");
              }}
            >
              {videoUrl.is_quiz_available ? "Next" : "Submit"}
            </button>
          </div>
        </div>
      ) : (
        <div style={{ padding: "50px" }}>
          <div
            style={{
              padding: "30px",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <p
              style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}
            >
              Personal development Video
            </p>

            <div style={{ marginTop: "50px" }}>
              <ReactPlayer
                className="react-player"
                url={videoUrl.video_url}
                width="60%"
                height="500px"
                controls={true}
                onProgress={handleProgress}
                onDuration={handleDuration}
              />
            </div>

            <button
              style={{
                marginTop: "30px",
                width: "120px",
                height: "40px",
                borderRadius: "5px",
                border: "none",
                color: nextBtnStatus ? "#5F5F5F" : "white",
                backgroundColor: nextBtnStatus ? "#DBDBDB" : "#008080",
                cursor: nextBtnStatus ? "not-allowed" : "pointer", // Change cursor if disabled
              }}
              disabled={nextBtnStatus}
              onClick={() => {
                onHandleClick();
                console.log("clicked...");
              }}
            >
              {videoUrl.is_quiz_available ? "Next" : "Submit"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LmsVideo;
