import React, { useEffect, useRef, useState } from 'react'
import request from '../../../utils/axios';
import { Button, Col, Form, Input, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

function ApplyForm() {
    const ref = useRef(null);
    const params = useParams();
    const navigate = useNavigate();
    const [loginUserDetails, setLoginUserDetails] = useState({
        candidate_name: "",
        candidate_email: "",
        candidate_contact: "",
        current_company: "",
        candidate_resume: "",
        linkedin_url: ""
    });
    const [resumeFile, setResumeFile] = useState(null);

    const fetchUserData = async () => {
        // const employee_number = localStorage.getItem("employee_number");

        let data = await request({
            method: "get",
            url: `profile/details/`,
        })

        setLoginUserDetails({
            ...loginUserDetails,
            candidate_name: data.data.data.employee_name,
            candidate_email: data.data.data.official_email,
            candidate_contact: data.data.data.contact,
            current_company: data.data.data.current_company,

        })
    }

    const onFinish = async (values) => {
        const formData = new FormData();
        formData.append('candidate_name', values.candidate_name);
        formData.append('candidate_email', values.candidate_email);
        formData.append('candidate_contact', values.candidate_contact);
        formData.append('current_company', values.current_company);
        formData.append('linkedin_url', values.linkedin_url);
        formData.append('candidate_resume', resumeFile);
        if (params.id) {
            formData.append('job', params.id);
        }
       
        await request({
            method: "post",
            url: `recruitment/create-applicant`,
            data: formData
        })
       
        setResumeFile(null);
        // form.resetFields();
        navigate("/recruitment", { replace: true })
    }

    const handleFormChange = (changedValues, allValues) => {
        if (!changedValues?.candidate_resume) {
            if (!loginUserDetails.candidate_resume) {
                setLoginUserDetails({ ...loginUserDetails, ...changedValues, candidate_resume: undefined });
            }
            else {
                setLoginUserDetails({ ...loginUserDetails, ...changedValues });
            }
        }
    }

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        fetchUserData();
    }, [])

    return (
        <div style={{ padding: "0px 16px", marginTop: "80px" }}>
            <p style={{ fontSize: "24px", fontWeight: "500" }}>Refer</p>
            <Form
                layout="vertical"
                onFinish={onFinish}
                // initialValues={userDetail}
                onValuesChange={handleFormChange}
                // form={form}
                fields={[
                    {
                        name: ["candidate_name"],
                        value: loginUserDetails?.candidate_name
                    },
                    {
                        name: ["candidate_email"],
                        value: loginUserDetails?.candidate_email
                    },
                    {
                        name: ["candidate_contact"],
                        value: loginUserDetails?.candidate_contact
                    },
                    {
                        name: ["current_company"],
                        value: loginUserDetails?.current_company
                    },
                    {
                        name: ["linkedin_url"],
                        value: loginUserDetails?.linkedin_url
                    },
                ]}
            >
                <Row gutter={[0, 12]}>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="Name"
                            name="candidate_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your name!',
                                },
                            ]}
                        >
                            <Input
                                className='inputField'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="Email Id"
                            name="candidate_email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your email!',
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email address!',
                                },
                            ]}
                        >
                            <Input
                                className='inputField'
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="Phone no."
                            name="candidate_contact"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your contact!',
                                },
                                {
                                    pattern: /^[0-9]{10}$/, // A regular expression that matches a 10-digit contact number
                                    message: 'Please enter a valid 10-digit contact number!',
                                },
                            ]}
                        >
                            <Input
                                className='inputField'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="Current Company"
                            name="current_company"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your Current  Company!',
                                }
                            ]}
                        >
                            <Input
                                className='inputField'
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="LinkedIn Url"
                            name="linkedin_url"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your linkedin_url!',
                                },
                            ]}
                        >
                            <Input
                                className='inputField'
                                style={{ width: "100% " }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            name="candidate_resume"
                            label="Resume"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please upload resume!',
                                },
                            ]}
                        >
                            <input
                                type='file'
                                className='inputField'
                                name='candidate_resume'
                                onChange={(e) => setResumeFile(e.target.files && e.target.files[0])}
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Button
                    style={{
                        height: "40px",
                        padding: "0px 20px",
                        background: "#008080",
                        color: "white",
                        fontWeight: "600",
                    }}
                    htmlType='submit'
                >Apply</Button>
            </Form>
        </div>
    )
}

export default ApplyForm