import React, { useEffect, useState } from "react";
import CommonTable from "../../../../component/CommonTable/CommonTable";
import { useNavigate } from "react-router-dom";
import request from "../../../../utils/axios";
import { toast } from "react-toastify";
import { Select } from "antd";
import getScreenSize from "../../../../utils/getScreenSize";
import InfiniteScrollComponent from "../../../../component/InfiniteScroll/InfiniteScroll";

function LmsList() {
  const navigate = useNavigate();
  const screenSize = getScreenSize();
  const columns = [
    {
      title: "Training title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Watched by",
      dataIndex: "watched_by",
      key: "watched_by",
    },
    {
      title: "Training completed",
      dataIndex: "training_video_completed_by",
      key: "training_video_completed_by",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <>
          <Select
            defaultValue={record.is_active}
            onChange={(value) => onHandleChangeForStatus(value, record.id)}
          >
            <Select.Option value={true}>Active</Select.Option>
            <Select.Option value={false}>In-Active</Select.Option>
          </Select>
        </>
      ),
    },
  ];

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5, // Number of items per page
    total: 0,
  });

  const [trainingList, setTrainingList] = useState([]);

  const fetchData = async () => {
    const response = await request({
      method: "get",
      url: `/lms/admin/training-videos/list?page=${pagination.current}&page_size=${pagination.pageSize}`,
    });
    setTrainingList(response?.pagination_data?.data);

    setPagination({
      ...pagination,
      total: response?.pagination_data?.total,
    });
  };

  const onHandleChangeForStatus = (value, id) => {
    try {
      const response = request({
        method: "post",
        url: `/lms/admin/training-videos/action?id=${id}`,
        data: {
          is_active: value,
        },
      });
      if (value === true) {
        toast.success("Training Video Activated successfully", {
          position: "bottom-left",
        });
      }
      if (value === false) {
        toast.success("Training Video In-Activated successfully", {
          position: "bottom-left",
        });
      }
      fetchData();
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong", {
        position: "bottom-left",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagination.current]);

  const renderUI = (dataList) => {
    return (
      <div style={{}}>
        {dataList?.map((item, index) => {
          return (
            <div key={index} style={{ marginTop: "20px" }}>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 16px",
                    marginBottom: "20px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#161616",
                      margin: "0",
                    }}
                  >
                    {item?.title}
                  </p>
                  <Select
                    // defaultValue={record.is_active}
                    // onChange={(value) => onHandleChangeForStatus(value, record.id)}
                    style={{
                      width: "100px",
                    }}
                  >
                    <Select.Option value={true}>Active</Select.Option>
                    <Select.Option value={false}>In-Active</Select.Option>
                  </Select>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 16px",
                  }}
                >
                  <p>Watched by</p>
                  <p>{item?.watched_by}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 16px",
                  }}
                >
                  <p>Quiz completion</p>
                  <p>{item?.training_video_completed_by}</p>
                </div>
              </div>
              <hr />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {screenSize.width < 768 ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "70px",
              padding: "0px 16px",
            }}
          >
            <p
              style={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#161616",
                margin: "0",
              }}
            >
              Training
            </p>
            <button
              style={{
                color: "white",
                background: "#008080",
                border: "none",
                borderRadius: "5px",
                height: "40px",
                width: "170px",
                fontSize: "16px",
              }}
              onClick={() => navigate("/admin/lms/training-form")}
            >
              Add Training Video
            </button>
          </div>
          <div style={{ marginTop: "20px" }}>
            <InfiniteScrollComponent
              apiEndpoint="/lms/admin/training-videos/list"
              renderUI={renderUI}
              initialPage={1}
            />
          </div>
        </div>
      ) : (
        <div style={{ padding: "50px" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
          <div
            style={{
              padding: "30px",
              background: "white",
              borderRadius: "10px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "500",
                  color: "#161616",
                  margin: "0",
                }}
              >
                Training
              </p>
              <button
                style={{
                  color: "white",
                  background: "#008080",
                  border: "none",
                  borderRadius: "5px",
                  height: "40px",
                  width: "170px",
                }}
                onClick={() => navigate("/admin/lms/training-form")}
              >
                Add Training Video
              </button>
            </div>
            <CommonTable
              columns={columns}
              data={trainingList}
              pagination={pagination}
              setPagination={setPagination}
              handleRowClick={(record) => {
                navigate(`/admin/lms/details/${record?.id}`);
              }}
            />
          </div>

          {/* <button
                    onClick={() => navigate("/lms/instruction")}
                >Next</button> */}
        </div>
      )}
    </div>
  );
}

export default LmsList;
