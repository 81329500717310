import { LinkOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from "./Mediclaim.module.scss"
import request from '../../../../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import {
    setMediclaimDeclarationSection80DForSelf, setMediclaimDeclarationSection80DForParents,
    setMediclaimDeclarationSection80DForSeniorParents,
    setMediclaimDeclarationSection80DForSelfChildrenAndSeniorParent
} from "../../../../../redux/reducer/TaxDeclarationReducer";

function Mediclaim({ windowOpen }) {
    const dispatch = useDispatch();
    const mediclaimDeclaration = useSelector(state => state.taxDeclaration.mediclaimDeclaration)

    const [mediclaimDeclarationData, setMediclaimDeclarationData] = useState({
        section80DForSelfAndChildren: {
            choice: null,
            declared_amount: "",
            attachment: "",
            file_name: ""
        },
        section80DForParents: {
            choice: null,
            declared_amount: "",
            attachment: "",
            file_name: ""
        },
        section80DForSeniorParents: {
            choice: null,
            declared_amount: "",
            attachment: "",
            file_name: ""
        },
        section80DForSelfChildrenAndSeniorParent: {
            choice: null,
            declared_amount: "",
            attachment: "",
            file_name: ""
        }
    })
    const [section80DForSelfAndChildrenData, setsection80DForSelfAndChildrenData] = useState([])
    const [section80DForParentsData, setsection80DForParentsData] = useState([])
    const [section80DForSeniorParentsData, setsection80DForSeniorParentsData] = useState([])
    const [section80DForSelfChildrenAndSeniorParentData, setsection80DForSelfChildrenAndSeniorParentData] = useState([])

    const section80DForSelfAndChildren = async () => {
        try {
            const data = await request({
                method: "get",
                url: `regim_tax_rules/sec80dForSelfAndChildren_choices`,
            })
            console.log("data ; ", data)

            setsection80DForSelfAndChildrenData([...data.data])

        }
        catch (error) {
            console.log("error", error);
        }
    }
    const section80DForParents = async () => {
        try {
            const data = await request({
                method: "get",
                url: `regim_tax_rules/sec80dForParents_choices`,
            })
            console.log("data ; ", data)

            setsection80DForParentsData([...data.data])

        }
        catch (error) {
            console.log("error", error);
        }
    }
    const section80DForSeniorParents = async () => {
        try {
            const data = await request({
                method: "get",
                url: `regim_tax_rules/sec80dForSeniorParents_choices`,
            })
            console.log("data ; ", data)

            setsection80DForSeniorParentsData([...data.data])

        }
        catch (error) {
            console.log("error", error);
        }
    }
    const section80DForSelfChildrenAndSeniorParent = async () => {
        try {
            const data = await request({
                method: "get",
                url: `regim_tax_rules/sec80dForSelfChildrenAndParents_choices`,
            })
            console.log("data ; ", data)

            setsection80DForSelfChildrenAndSeniorParentData([...data.data])

        }
        catch (error) {
            console.log("error", error);
        }
    }

    const fetchMediclaimDeclarationData = async () => {
        try {
            const data = await request({
                method: "GET",
                url: `regim_tax_rules/get-all-mediclaim-declaration`,
            })

            dispatch(setMediclaimDeclarationSection80DForSelf(data.data.section80DForSelfAndChildren));
            dispatch(setMediclaimDeclarationSection80DForParents(data.data.section80DForParents));
            dispatch(setMediclaimDeclarationSection80DForSeniorParents(data.data.section80DForSeniorParents));
            dispatch(setMediclaimDeclarationSection80DForSelfChildrenAndSeniorParent(data.data.section80DForSelfChildrenAndSeniorParent));
            setMediclaimDeclarationData(
                data.data
            )
        }
        catch (error) {
            console.log("error", error);
        }
    }


    useEffect(() => {
        section80DForSelfAndChildren()
        section80DForParents()
        section80DForSeniorParents()
        section80DForSelfChildrenAndSeniorParent()
        fetchMediclaimDeclarationData()
    }, [])

    const setDataInRedux = (section, key, value, file_name) => {

        if (section === "section80DForSelfAndChildren") {
            dispatch(setMediclaimDeclarationSection80DForSelf({
                ...mediclaimDeclarationData[section],
                [key]: value,
                file_name
            }))
        }
        else if (section === "section80DForParents") {
            dispatch(setMediclaimDeclarationSection80DForParents({
                ...mediclaimDeclarationData[section],
                [key]: value,
                file_name
            }))
        }
        else if (section === "section80DForSeniorParents") {
            dispatch(setMediclaimDeclarationSection80DForSeniorParents({
                ...mediclaimDeclarationData[section],
                [key]: value,
                file_name
            }))
        }
        else {
            dispatch(setMediclaimDeclarationSection80DForSelfChildrenAndSeniorParent({
                ...mediclaimDeclarationData[section],
                [key]: value,
                file_name
            }))
        }

    }

    const onHandleChange = (section, key, value) => {

        if (key === "attachment") {

            const reader = new FileReader();
            reader.readAsDataURL(value);
            reader.onload = () => {
                setMediclaimDeclarationData(prevState => ({
                    ...prevState,
                    [section]: {
                        ...prevState[section],
                        [key]: reader.result,
                        file_name: value.name
                    }
                }))

                setDataInRedux(section, key, reader.result, value.name)

            };
            reader.onerror = (error) => {
                console.error('Error uploading image: ', error);
            };


        }
        else {
            setMediclaimDeclarationData(prevState => ({
                ...prevState,
                [section]: {
                    ...prevState[section],
                    [key]: value
                }
            }))

            setDataInRedux(section, key, value)
        }
    }


    return (
        <div className={styles.mediclaim}>
            <p className={styles.mediclaimText}>Mediclaim</p>
            <div>
                <Form>
                    <Row gutter={32}>
                        <Col span={24}>
                            <div className={styles.declartionBox}>
                                <p className={styles.declartionText}>Section 80D ( MEDICAL INSURANCE FOR SELF, SPOUSE AND DEPENDENT CHILDREN (QUALIFIED AMOUNT: 25000 INR))</p>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                <Select size='large'
                                    onChange={(e) => onHandleChange("section80DForSelfAndChildren", "choice", e)}
                                    value={mediclaimDeclaration.section80DForSelfAndChildren.choice}
                                    disabled={windowOpen}
                                >
                                    {section80DForSelfAndChildrenData.map((item, index) => (
                                        <Select.Option key={index} value={item.value}>{item.name}</Select.Option>

                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18}></Col>
                        <Col span={24}>

                            {
                                mediclaimDeclaration.section80DForSelfAndChildren.choice !== null &&
                                <Row gutter={32}>
                                    <Col span={6}>
                                        <Form.Item>
                                            <Input size='large'
                                                placeholder='Enter Amount'
                                                value={mediclaimDeclaration.section80DForSelfAndChildren.declared_amount}
                                                onChange={(e) => onHandleChange("section80DForSelfAndChildren", "declared_amount", e.target.value)}
                                                disabled={windowOpen}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item>
                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <div
                                                    className={styles.attachFileBox}
                                                >
                                                    <input
                                                        type="file"
                                                        id="file-upload"
                                                        className={styles.inputFile}
                                                        onChange={(e) =>
                                                        // setFile(e.target.files && e.target.files[0])
                                                        {
                                                            console.log("e.target.files[0]", e.target.files[0])

                                                            const selectedFile = e.target.files[0];

                                                            if (selectedFile) {
                                                                // onHandleChange(index, "attach_file", selectedFile)
                                                                // setFile(e.target.files && selectedFile)
                                                                onHandleChange("section80DForSelfAndChildren", "attachment", selectedFile)
                                                            }
                                                        }
                                                        }
                                                        disabled={windowOpen}
                                                    />
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>

                                                        <label htmlFor="file-upload" >

                                                        </label>
                                                        <p
                                                            className={styles.attachFileLabel}
                                                            style={{ marginTop: "7px" }}>
                                                            <LinkOutlined /> {" "}Attach File
                                                        </p>
                                                    </div>
                                                </div>
                                                <p style={{ margin: '0' }}>{mediclaimDeclaration.section80DForSelfAndChildren?.file_name}</p>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </Col>
                        <Col span={24}>
                            <div className={styles.declartionBox}>
                                <p className={styles.declartionText}>Section 80D ( MEDICAL INSURANCE FOR PARENTS (QUALIFIED AMOUNT: 25000 INR) )</p>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                <Select size='large'
                                    onChange={(e) => onHandleChange("section80DForParents", "choice", e)}
                                    value={mediclaimDeclaration.section80DForParents.choice}
                                >
                                    {section80DForParentsData.map((item, index) => (
                                        <Select.Option key={index} value={item.value}>{item.name}</Select.Option>

                                    ))}
                                </Select>
                            </Form.Item>
                            {/* <p>Permanent Physical Disability ( Above 80% ) - 80U</p>
                            <p>Permanent Physical Disability ( Above 40% ) - 80U</p> */}
                        </Col>
                        <Col span={18}></Col>
                        <Col span={24}>
                            {
                                mediclaimDeclaration.section80DForParents.choice !== null &&
                                <Row gutter={32}>
                                    <Col span={6}>
                                        <Form.Item>
                                            <Input size='large'
                                                placeholder='Enter Amount'
                                                value={mediclaimDeclaration.section80DForParents.declared_amount}
                                                onChange={(e) => onHandleChange("section80DForParents", "declared_amount", e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item>
                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <div
                                                    className={styles.attachFileBox}
                                                >
                                                    <input
                                                        type="file"
                                                        id="file-upload"
                                                        className={styles.inputFile}
                                                        onChange={(e) =>
                                                        // setFile(e.target.files && e.target.files[0])
                                                        {
                                                            console.log("e.target.files[0]", e.target.files[0])

                                                            const selectedFile = e.target.files[0];

                                                            if (selectedFile) {

                                                                // onHandleChange(index, "attach_file", selectedFile)
                                                                // setFile(e.target.files && selectedFile)
                                                                onHandleChange("section80DForParents", "attachment", selectedFile)
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>

                                                        <label htmlFor="file-upload" >

                                                        </label>
                                                        <p
                                                            className={styles.attachFileLabel}
                                                            style={{ marginTop: "7px" }}>
                                                            <LinkOutlined /> {" "}Attach File
                                                        </p>
                                                    </div>
                                                </div>
                                                <p style={{ margin: '0' }}>{mediclaimDeclaration.section80DForParents?.file_name}</p>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </Col>
                        <Col span={24}>
                            <div className={styles.declartionBox}>
                                <p className={styles.declartionText}>Section 80D ( MEDICAL INSURANCE FOR PARENT(S) IF SENIOR CITIZEN(S) (QUALIFIED AMOUNT: 50000 INR))</p>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                <Select size='large'
                                    onChange={(e) => onHandleChange("section80DForSeniorParents", "choice", e)}
                                    value={mediclaimDeclaration.section80DForSeniorParents.choice}
                                >
                                    {section80DForSeniorParentsData.map((item, index) => (
                                        <Select.Option key={index} value={item.value}>{item.name}</Select.Option>

                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18}></Col>
                        <Col span={24}>

                            {
                                mediclaimDeclaration.section80DForSeniorParents.choice !== null &&
                                <Row gutter={32}>
                                    <Col span={6}>
                                        <Form.Item>
                                            <Input size='large'
                                                placeholder='Enter Amount'
                                                value={mediclaimDeclaration.section80DForSeniorParents.declared_amount}
                                                onChange={(e) => onHandleChange("section80DForSeniorParents", "declared_amount", e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item>
                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <div
                                                    className={styles.attachFileBox}
                                                >
                                                    <input
                                                        type="file"
                                                        id="file-upload"
                                                        className={styles.inputFile}
                                                        onChange={(e) =>
                                                        // setFile(e.target.files && e.target.files[0])
                                                        {
                                                            console.log("e.target.files[0]", e.target.files[0])

                                                            const selectedFile = e.target.files[0];

                                                            if (selectedFile) {
                                                                // onHandleChange(index, "attach_file", selectedFile)
                                                                // setFile(e.target.files && selectedFile)
                                                                onHandleChange("section80DForSeniorParents", "attachment", selectedFile)
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>

                                                        <label htmlFor="file-upload" >

                                                        </label>
                                                        <p
                                                            className={styles.attachFileLabel}
                                                            style={{ marginTop: "7px" }}>
                                                            <LinkOutlined /> {" "}Attach File
                                                        </p>
                                                    </div>
                                                </div>
                                                <p style={{ margin: '0' }}>{mediclaimDeclaration.section80DForSeniorParents?.file_name}</p>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </Col>
                        <Col span={24}>
                            <div className={styles.declartionBox}>
                                <p className={styles.declartionText}>Section 80D ( HEALTH INSURANCE FOR SELF, SPOUSE AND CHILDREN IF SENIOR CITIZEN (QUALIFIED AMOUNT: 50000 INR) )</p>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                <Select size='large'
                                    onChange={(e) => onHandleChange("section80DForSelfChildrenAndSeniorParent", "choice", e)}
                                    value={mediclaimDeclaration.section80DForSelfChildrenAndSeniorParent.choice}
                                >
                                    {section80DForSelfChildrenAndSeniorParentData.map((item, index) => (
                                        <Select.Option key={index} value={item.value}>{item.name}</Select.Option>

                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18}></Col>
                        <Col span={24}>

                            {
                                mediclaimDeclaration.section80DForSelfChildrenAndSeniorParent.choice !== null &&
                                <Row gutter={32}>
                                    <Col span={6}>
                                        <Form.Item>
                                            <Input size='large'
                                                placeholder='Enter Amount'
                                                value={mediclaimDeclaration.section80DForSelfChildrenAndSeniorParent.declared_amount}
                                                onChange={(e) => onHandleChange("section80DForSelfChildrenAndSeniorParent", "declared_amount", e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item>
                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <div
                                                    className={styles.attachFileBox}
                                                >
                                                    <input
                                                        type="file"
                                                        id="file-upload"
                                                        className={styles.inputFile}
                                                        onChange={(e) =>
                                                        // setFile(e.target.files && e.target.files[0])
                                                        {
                                                            console.log("e.target.files[0]", e.target.files[0])

                                                            const selectedFile = e.target.files[0];

                                                            if (selectedFile) {
                                                                // onHandleChange(index, "attach_file", selectedFile)
                                                                // setFile(e.target.files && selectedFile)
                                                                onHandleChange("section80DForSelfChildrenAndSeniorParent", "attachment", selectedFile)
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>

                                                        <label htmlFor="file-upload" >

                                                        </label>
                                                        <p
                                                            className={styles.attachFileLabel}
                                                            style={{ marginTop: "7px" }}>
                                                            <LinkOutlined /> {" "}Attach File
                                                        </p>
                                                    </div>
                                                </div>
                                                <p style={{ margin: '0' }}>{mediclaimDeclaration.section80DForSelfChildrenAndSeniorParent?.file_name}</p>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>

    )
}

export default Mediclaim