import React, { useEffect, useRef, useState } from "react";
import request from "../../../utils/axios";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  DatePicker,
  Spin,
} from "antd";
import edit from "../../../assets/edit.svg";
import add from "../../../assets/Org/add.svg";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { setOrgDetails, resetEmployeeDetails } from "../../../redux/reducer/EmployeeReducer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DownOutlined, FilterTwoTone, LoadingOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import useScreenSize from "../../../utils/getScreenSize";
import location from "../../../assets/location.svg";
import forgotImg from "../../../assets/Attendance/forgotImg.svg";
import styles from "./EmployeeList.module.scss";
import CommonTable from "../../../component/CommonTable/CommonTable";
import EmployeeSearchBar from "../EmployeeSearchBar/EmployeeSearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
function EmployeeList() {
  const [statusValue, setStatusValue] = useState("");
  const [probationModal, setProbationModal] = useState(false);
  const [resignationModal, setResignationModal] = useState(false);
  const [fandfPendingModal , setFandfPendingModal] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Number of items per page
    total: 0,
  })
  const ref = useRef(null);
  const [submitLoader, setSubmitLoader] = useState(false)
  const employeeColumn = [
    {
      title: "Name",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (text, record) => (
        <span
          onClick={() => {
            navigate(`/admin/employee/detail/${record.employement_number}`, { replace: true });
          }}
        >
          <u>{record?.employee_name}</u>
        </span>
      ),
    },
    {
      title: "Employee ID",
      dataIndex: "employement_number",
      key: "employement_number",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "Location",
      dataIndex: "work_location",
      key: "work_location",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Edit",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Dropdown
          menu={{ items: actionList(record.employement_number) }}
          placement="bottom"
          trigger={['click']}
        >
          <Button
            onClick={() => {
              setEmployementNumber(record.employement_number);
            }}
            style={{
              color: "white",
              background: "#008080",
            }}
          >
            Action <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const [updatePasswordForm] = Form.useForm();
  const [resignationForm] = Form.useForm();
  const [fandfPendingForm] = Form.useForm();
  const [probationForm] = Form.useForm();
  const [employementNumber, setEmployementNumber] = useState(null);
  const org = useSelector((state) => state.employee.org);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const [openModal, setOpenModal] = useState(false);
  const [form] = Form.useForm()
  const [addEmployeeModel, setAddEmployeeModel] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [reload, setReload] = useState(false);
  const downloadLink = `${process.env.REACT_APP_IMG_BASE_URL}/media/Sample_Formats/Employee Creation Sample Format.csv`;

  const actionList = (employement_number) => {
    const items = [
      {
        key: "1",
        label: (
          <a
            target="_blank"
            onClick={() =>
              navigate(`/admin/employee/detail/${employement_number}`, { replace: true })
            }
          >
            Edit
          </a>
        ),
      },
      // {
      //   key: "2",
      //   label: <a target="_blank">Delete</a>,
      // },
      {
        key: "3",
        label: (
          <a target="_blank" onClick={() => setUpdatePasswordModal(true)}>
            Update Password
          </a>
        ),
      },
      {
        key: "4",
        label: (
          <a target="_blank" onClick={() => setChangeStatusModal(true)}>
            Change Status
          </a>
        ),
      },
    ];
    return items;
  };

  const fetchEmployeeData = async () => {
    try {
      const data = await request({
        method: "get",
        url: `employee/all/details/?page=${pagination.current}&page_size=${pagination.pageSize}${selectedOrganisation ? `&org=${selectedOrganisation}` : ""}`,
      });
      setEmployeeList([...employeeList, ...data?.pagination_data?.data]);
      setPagination({
        ...pagination,
        total: data?.pagination_data?.total,
        current: pagination.current + 1,
      })

      if (data?.pagination_data?.next === null) {
        setHasMore(false);
        return;
      }
    }
    catch (error) {
      console.error("Error fetching data: ", error);
      setHasMore(false);
    }
  };

  const handleChange = (event) => {
    setStatusValue(event);
    if (event === 0) {
      setProbationModal(true);
      setChangeStatusModal(false);
    } else if (event === 2) {
      setResignationModal(true);
      setChangeStatusModal(false);
    }
    else if (event === 3) {
      setFandfPendingModal(true)
      setChangeStatusModal(false)
    }
    else {
      try {
        const response = request({
          method: "post",
          url: `admin/status/change?employement_number=${employementNumber}`,
          data: {
            status: event
          },
        })
        setChangeStatusModal(false)
        toast.success("Status changed successfully", {
          position: "bottom-left",
        })
      }
      catch (error) {
        console.error(error)
        toast.error("Something went wrong. Please try again later", {
          position: "bottom-left",
        })
      }
    }
  };

  const onFinishProbationForm = async (values) => {
    try {
      await request({
        method: "post",
        url: `admin/status/probation?employement_number=${employementNumber}`,
        data: {
          'probation_period': values.probation_period,
          'emp_status': 0,
        },
      });
      probationForm.resetFields();
      setStatusValue("");
      setProbationModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onFinishResignationForm = async (values) => {
    try {
      await request({
        method: "post",
        url: `admin/resignation/create?employement_number=${employementNumber}`,
        data: {
          'resignation_date': dayjs(values.resignation_date).format("YYYY-MM-DD"),
        },
      });
      resignationForm.resetFields();
      setResignationModal(false);
      setStatusValue("");
    }
    catch (error) {
      console.error(error);
    }
  }

  const onFinishFandfPendingForm = async (values) => {
    try {
      await request({
        method: "post",
        url: `admin/status/fandf/change?employement_number=${employementNumber}`,
        data: {
          'last_working_date': dayjs(values.last_working_date).format("YYYY-MM-DD"),
        },
      });
      fandfPendingForm.resetFields();
      setFandfPendingModal(false);
      setStatusValue("");
      toast.success("Status changed successfully", {
        position: "bottom-left",
      })
    }
    catch (error) {
      console.error(error);
    }
  }

  const [organisationList, setOrganisationList] = useState([]);
  const fetchOrganisationList = async () => {
    try {
      const data = await request({
        method: "get",
        url: `org/all`,
      });
      setOrganisationList(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      // setReload(false)
    }
  };

  const setOrg = (value) => {
    dispatch(setOrgDetails(value.org));
    navigate("/admin/employee/add", { replace: true });
  };
  const [allStatusDropdown, setAllStatusDropdown] = useState([]);
  const fetchDropdownData = async () => {
    try {
      const data = await request({
        method: "get",
        url: `status/all`,
      });
      setAllStatusDropdown(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  const [GetJoiningDateForEmployee, setGetJoiningDateForEmployee] = useState(null)
  const fetchGetJoiningDateForEmployee = async () => {
    console.log(employementNumber);
    try {
      const data = await request({
        method: "get",
        url: `employee/joining-date?employement_number=${employementNumber}`,
      });
      console.log(data?.data);
      setGetJoiningDateForEmployee(data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  const [File, setFile] = useState(null)

  const onFinish = async (values) => {
    const formData = new FormData();
    setSubmitLoader(true)
    try {
      formData.append("org", values.org);
      formData.append("csv_file", File);

      const data = await request({
        method: "post",
        url: `/admin/employee/bulk-upload`,
        data: formData
      })
      console.log(data)
      setSubmitLoader(false)
      setOpenModal(false);
      if (screenSize.width > 768) {
        fetchEmployeeData();
      } else {
        console.log(data?.data)
        fetchEmployeeListForMobile();
      }
      for (const x in data.message) {
        console.log(data.message[x].status)
        if (data.message[x].status === 400) {
          toast.error(data.message[x].message, {
            position: 'bottom-left',
          });
        }
        else if (data.message[x].status === 200) {
          toast.success(data.message[x].message, {
            position: 'bottom-left',
          });
        }
      }
    }
    catch (error) {
      console.log("error")
      setSubmitLoader(false)
      toast.error("Could not add Employee, please try after some time", {
        position: 'bottom-left',
      });
    }
  }




  useEffect(() => {
    // if (screenSize.width > 768) {

    //   fetchEmployeeData();
    // }
    fetchDropdownData();
    fetchOrganisationList();
    if (probationModal && employementNumber) { // Check if employementNumber is set
      fetchGetJoiningDateForEmployee();
    }
  }, [pagination.current, probationModal, selectedOrganisation]);


  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [sendMailSuccessfullModel, setSendMailSuccessfullModel] =
    useState(false);
  const updatePassword = async (value) => {
    try {
      const data = await request({
        method: "post",
        url: `users/password/change`,
        data: {
          ...value,
          employement_number: employementNumber,
        },
      });
      console.log(value);
      setSendMailSuccessfullModel(true);
    } catch (error) {
      toast.error(error.response.data.message);
      const errorMessage = error.response.data.message;

      if (Array.isArray(errorMessage)) {
        errorMessage.forEach(msg => {
          toast.error(msg);
        });
      } else {
        toast.error(errorMessage);
      }
    } finally {
      setUpdatePasswordModal(false);
      updatePasswordForm.resetFields();
    }
  };

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const [hasMore, setHasMore] = useState(true);

  const fetchEmployeeListForMobile = async () => {
    try {
      const res = await request({
        method: "get",
        url: `employee/all/details/?page=${pagination.current}&page_size=${pagination.pageSize}${selectedOrganisation ? `&org=${selectedOrganisation}` : ""}`,
      })
      setEmployeeList([...employeeList, ...res?.pagination_data?.data]);
      setPagination({
        ...pagination,
        current: pagination.current + 1,
        total: res?.pagination_data?.total
      })

      if (res?.pagination_data?.next === null) {
        setHasMore(false);
        return;
      }
    }
    catch (error) {
      console.error("Error fetching data: ", error);
      setHasMore(false);
    }
  };


  useEffect(() => {
    if (screenSize.width < 768) {
      fetchEmployeeListForMobile();
    }
    if (screenSize.width > 768) {
      fetchEmployeeData();
    }
  }, [selectedOrganisation]);


  return (
    <div ref={ref}>

      {screenSize.width < 768 ? (
        <div style={{ minHeight: "100vh", background: "white" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px" }}>
            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0px" }}>Employee List</p>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px", gap: "15px" }}>
              <img
                src={add}
                alt="add"
                onClick={() => {
                  dispatch(resetEmployeeDetails());
                  setAddEmployeeModel(true)
                }}
              />
              <button
                style={{
                  background: "#008080",
                  color: "white",
                  border: "none",
                  padding: "8px 16px",
                  borderRadius: "4px",
                }}
                onClick={() => {
                  setOpenModal(true)
                }}
              >
                Upload
              </button>
            </div>
          </div>
          <div style={{ padding: "0px 16px" }}>
            <Select
              size="large"
              placeholder="Select Organisation"
              style={{ width: "100%" }}
              value={selectedOrganisation}
              onChange={(value) => {
                setSelectedOrganisation(value);
                setPagination({ ...pagination, current: 1 });
                setEmployeeList([]);

              }}
            >
              {organisationList?.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.id}>
                    {item.org_name}
                  </Select.Option>
                );
              })}
            </Select>
            <div style={{ marginTop: "16px" }}>
              <EmployeeSearchBar
                setEmployeeListTable={setEmployeeList}
                fetchTableDataFromApi={fetchEmployeeData}
              />
            </div>
          </div>
          <div
            id="scrollableDiv"
            style={{
              height: "auto",
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            <InfiniteScroll
              dataLength={pagination.total - (employeeList.length * pagination.current)}
              next={fetchEmployeeListForMobile}
              hasMore={hasMore}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              loader={
                <p style={{ textAlign: 'center' }}>
                  <b>Loading...</b>
                </p>
              }
              scrollableTarget="scrollableDiv"
              height="70vh"
            >
              {employeeList?.map((item, index) => {
                return (
                  <div>
                    <div style={{ padding: "16px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "6px 0px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "#484848",
                            margin: "0px",
                          }}
                          onClick={() => {
                            navigate(
                              `/admin/employee/detail/${item.employement_number}`, { replace: true }
                            );
                          }}
                        >
                          <u>{item?.employee_name}</u>
                        </p>
                        <Dropdown menu={{ items: actionList() }} placement="bottom">
                          <Button
                            onMouseEnter={() => {
                              setEmployementNumber(item.employement_number);
                            }}
                            style={{
                              color: "white",
                              background: "#008080",
                            }}
                          >
                            Action <DownOutlined />
                          </Button>
                        </Dropdown>
                      </div>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#494949",
                        }}
                      >
                        {item.employement_number}
                      </p>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#969696",
                        }}
                      >
                        {item.designation}
                      </p>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#969696",
                        }}
                      >
                        <img src={location} alt="" /> {item.work_location}
                      </p>
                    </div>
                    <div style={{ height: "8px", background: "#F7F8FC" }}></div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.container}>
            <h1 className="heading">Employees</h1>

            <div style={{ padding: "40px", background: "white" }}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  <h2 style={{ margin: "0px" }}>Employees</h2>
                  <img
                    src={add}
                    alt="add"
                    onClick={() => {
                      dispatch(resetEmployeeDetails());
                      setAddEmployeeModel(true)
                    }}
                  />
                  <button

                    style={{
                      background: "#008080",
                      color: "white",
                      border: "none",
                      padding: "8px 16px",
                      borderRadius: "4px",
                    }}
                    onClick={() => {
                      setOpenModal(true)
                    }}
                  >
                    Upload
                  </button>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "18px" }}>

                  <Select
                    size="large"
                    placeholder="Select Organisation"
                    style={{ width: "250px" }}
                    value={selectedOrganisation}
                    onChange={(value) => {
                      setSelectedOrganisation(value);
                      setPagination({ ...pagination, current: 1 });
                      setEmployeeList([]);
                    }}
                  >
                    {organisationList?.map((item, index) => {
                      return (
                        <Select.Option key={index} value={item.id}>
                          {item.org_name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                  <EmployeeSearchBar
                    setEmployeeListTable={setEmployeeList}
                    fetchTableDataFromApi={fetchEmployeeData}
                    selectedOrganisation={selectedOrganisation}
                  />
                </div>
              </div>
              <div
                id="scrollableDiv"
                style={{
                  height: "auto",
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column-reverse',
                }}
              >
                <InfiniteScroll
                  dataLength={pagination.total - (employeeList.length * pagination.current)}
                  next={fetchEmployeeData}
                  hasMore={hasMore}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  loader={
                    <p style={{ textAlign: 'center' }}>
                      <b>Loading...</b>
                    </p>
                  }
                  scrollableTarget="scrollableDiv"
                  height="70vh"
                >
                  <Table
                    columns={employeeColumn}
                    dataSource={employeeList}
                    pagination={false}
                    sticky={{ offsetHeader: 0 }}
                  />
                </InfiniteScroll>
              </div>
              {/* <Table
                columns={employeeColumn}
                dataSource={employeeList}
                pagination={false}
                // loading={loading}
                // onChange={handleTableChange}
                onRow={(record) => ({
                  // onClick: () => {
                  //     handleRowClick(record);
                  // }
                })}
              /> */}
            </div>
          </div>
        </>
      )}

      <Modal
        centered
        open={addEmployeeModel}
        footer={null}
        onCancel={() => {
          setAddEmployeeModel(false);
          // updatePasswordForm.resetFields();
        }}
      >
        <p className="forgotPasswordText">Add Employee</p>
        <Form
          layout="vertical"
          onFinish={setOrg}
          // form={updatePasswordForm}
          autoComplete="on"
        >
          <Form.Item
            label="Organisation"
            name="org"
            rules={[
              {
                required: true,
                message: "Please Select Organisation!",
              },
            ]}
          >
            <Select size="large">
              {organisationList?.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.id}>
                    {item.org_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Button htmlType="submit" className="forgotPasswordButton">
            Next
          </Button>
        </Form>
      </Modal>

      <Modal
        centered
        open={updatePasswordModal}
        footer={null}
        onCancel={() => {
          setUpdatePasswordModal(false);
          updatePasswordForm.resetFields();
        }}
      >
        <p className="forgotPasswordText">Change Password</p>
        <Form
          layout="vertical"
          onFinish={updatePassword}
          form={updatePasswordForm}
          autoComplete="on"
        >
          <Form.Item
            label="New Password"
            name="new_password"
            rules={[
              {
                required: true,
                message: "Please enter your email!",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter New Password"
              autoComplete="on"
            />
          </Form.Item>

          <Button htmlType="submit" className="forgotPasswordButton">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        centered
        open={sendMailSuccessfullModel}
        footer={null}
        onOk={() => setSendMailSuccessfullModel(false)}
        onCancel={() => setSendMailSuccessfullModel(false)}
      >
        <center>
          <img src={forgotImg} alt="forgotImg" />
          <p>Password is successfully updated</p>
        </center>
      </Modal>

      {/* Change Status */}
      <Modal
        centered
        open={changeStatusModal}
        footer={null}
        onCancel={() => {
          setStatusValue("");
          setChangeStatusModal(false);
        }}
      >
        <p className="forgotPasswordText">Change Status</p>
        <div className="dropdown-container">
          <Select
            id="dropdown"
            value={statusValue}
            onChange={handleChange}
            className="dropDown"
            placeholder="Select"
            size="large"
          >
            {allStatusDropdown?.map(status => (
              <Select.Option value={status.value}>{status.label}</Select.Option>
            ))}
          </Select>
        </div>
      </Modal>

      {/* Probation */}
      <Modal
        centered
        open={probationModal}
        footer={null}
        onCancel={() => {
          setStatusValue("")
          setProbationModal(false);
        }}
      >
        <p className="forgotPasswordText">Probation</p>
        <Form
          form={probationForm}
          onFinish={onFinishProbationForm}
          layout="vertical"
          fields={[
            {
              name: ['start_date'],
              value: GetJoiningDateForEmployee ? dayjs(GetJoiningDateForEmployee) : null
            }
          ]}
        >
          <Form.Item
            name="probation_period"
            label="Probation Period"
            rules={[
              { required: true, message: "Required" },
            ]}
          >
            <Select
              size="large"
              placeholder="Select"
            >
              <Select.Option value={15} > 15 Days</Select.Option>
              <Select.Option value={30}>1 Month</Select.Option>
              <Select.Option value={45}>1.5 Months</Select.Option>
              <Select.Option value={60}>2 Months</Select.Option>
              <Select.Option value={75}>2.5 Months</Select.Option>
              <Select.Option value={90}>3 Months</Select.Option>

            </Select>
          </Form.Item>

          <Form.Item
            name="start_date"
            label="Start Date"
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              disabled={GetJoiningDateForEmployee !== null}
              disabledDate={(current) => current && current > dayjs().endOf("day")}
            />
          </Form.Item>

          <Button
            htmlType="submit"
            className="forgotPasswordButton"
          >
            Submit
          </Button>
        </Form>
      </Modal>

      {/* Serving Notice Period */}
      <Modal
        centered
        open={resignationModal}
        footer={null}
        onCancel={() => {
          setResignationModal(false);
        }}
      >
        <p className="forgotPasswordText">Serving Notice Period</p>
        <Form
          layout="vertical"
          onFinish={onFinishResignationForm}
          form={resignationForm}
        >

          <Form.Item
            name="resignation_date"
            label="Resignation Date"
            rules={[
              {
                required: true,
                message: "Please enter date!",
              },
            ]}
          >

            <DatePicker
              style={{ width: "100%" }}
              size="large"
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
            />
          </Form.Item>
          <Button htmlType="submit" className="forgotPasswordButton">
            Submit
          </Button>
        </Form>
      </Modal>

      {/* F & F Pending */}
      <Modal
        centered
        open={fandfPendingModal}
        footer={null}
        onCancel={() => {
          setFandfPendingModal(false);
        }}
      >
        <p className="forgotPasswordText">F & F Pending</p>
        <Form
          layout="vertical"
          onFinish={onFinishFandfPendingForm}
          form={fandfPendingForm}
        >

          <Form.Item
            label="Last Working Date"
            name="last_working_date"
            rules={[
              {
                required: true,
                message: "Please enter date!",
              },
            ]}
          >

            <DatePicker
              style={{ width: "100%" }}
              size="large"
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
            />
          </Form.Item>
          <Button htmlType="submit" className="forgotPasswordButton">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        open={openModal}
        onOk={() => { }}
        onCancel={() => {
          setOpenModal(false)
          form.resetFields();
          setFile(null);
        }}
        footer={null}
        centered
        width={540}
      >
        <p className='leavePolicyModelHeading'>Upload Employee List</p>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        // onValuesChange={onValuesChange}
        >

          <Row gutter={[8, 8]}>
            <Col span={24}>

              <Form.Item
                label="Organisation"
                name="org"
                rules={[
                  {
                    required: true,
                    message: "Please Select Organisation!",
                  },
                ]}
              >
                <Select size="large">
                  {organisationList?.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.id}>
                        {item.org_name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <p>Upload File</p>
              <Form.Item
                label="Upload File"
                name="end_time"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Date (To)!',
                  },
                ]}
              >
                <div className="file-upload-container">
                  <input
                    type="file"
                    id="file-upload"
                    className="input-file"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      const maxSizeInBytes = 5 * 1024 * 1024;

                      if (selectedFile && selectedFile.type === 'text/csv') {
                        if (selectedFile.size <= maxSizeInBytes) {
                          setFile(e.target.files && selectedFile)
                        }
                        else {
                          setFile(null);
                          alert('File size exceeds the limit of 5MB.');
                        }
                      } else {
                        // Reset the file state and show an error message
                        setFile(null);
                        alert('Please upload a CSV file.');
                      }
                    }}
                  />
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                    <label htmlFor="file-upload" className="file-upload-label">

                    </label>
                    {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                    <p style={{ zIndex: 1, margin: "0px" }}>{File ? File.name : 'Drop Your File Here'}</p>
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>

          <div style={{ marginTop: "40px" }}>
            <Row gutter={[8, 8]}>
              <Col xs={24} md={12} >
                <p
                  style={{ color: "#008080", margin: "0", marginTop: "10px" }}
                >
                  <a href={downloadLink} download style={{ color: "#008080", textDecoration: "none" }}>
                    Download Sample CSV
                  </a>
                </p>
              </Col>
              <Col xs={24} md={12}>
                <Button
                  htmlType='submit'
                  style={{ width: "100%", color: "white", background: "#008080", height: "44px" }}
                >
                  {
                    submitLoader
                      ?
                      <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} />
                      :
                      "Submit"
                  }
                </Button>
              </Col>
            </Row>
          </div>

        </Form>
      </Modal>
    </div >
  )
}

export default EmployeeList;
