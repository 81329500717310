// package
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs';

// scss
import styles from "./PayrollSummary.module.scss";
import mobileStyle from "./MobilePayrollSummary.module.scss";

// images
import yellowTick from "../../../../assets/Payroll/yellowTick.svg";
import calendarDays from "../../../../assets/Payroll/Admin/calendarDays.png"
import employee from "../../../../assets/Payroll/Admin/employee.png"
import inc from "../../../../assets/Payroll/Admin/inc.png"
import dec from "../../../../assets/Payroll/Admin/dec.png"
import payrollProcessed from "../../../../assets/Payroll/Admin/payrollProcessed.png"

// utils
import request from '../../../../utils/axios';
import getScreenSize from "../../../../utils/getScreenSize";
import PayrollSummaryInsight from '../PayrollSummaryInsight/PayrollSummaryInsight';

function PayrollSummary({ tabsValue }) {
    const screenSize = getScreenSize();
    const [availableMonthPaySlip, setAvailableMonthPaySlip] = useState([])
    const [selectedMonthForDownloadPayslip, setSelectedMonthForDownloadPayslip] = useState("")
    const [paySlipMonthYear, setPaySlipMonthYear] = useState({
        month: "",
        year: dayjs(new Date()).format('YYYY'),
    })
    const [totalPayrollCostDetail, setTotalPayrollCostDetail] = useState({
        total_cost: "",
        total_salary: "",
        bonus_amount: "",
        tds_amount: "",
        total_cost_percentage: "",
        total_cost_change_indicator: "",
        total_salary_percentage: "",
        total_salary_change_indicator: "",
        bonus_percentage: "",
        bonus_change_indicator: "",
        tds_percentage: "",
        tds_change_indicator: ""
    })
    const [monthlyPayrollSummary, setMonthlyPayrollSummary] = useState()

    const getAvailableMonthPaySlip = async () => {
        try {
            const response = await request({
                method: "get",
                url: `payroll/admin/employee-monthly-paylist?year=${paySlipMonthYear.year}`,
            })
            setAvailableMonthPaySlip([...response.data])

            let lastPaySlipAvailableMonth;
            response.data.forEach(item => {
                if (item.status) {
                    lastPaySlipAvailableMonth = item
                }
            })

            setSelectedMonthForDownloadPayslip(lastPaySlipAvailableMonth.month)
            setPaySlipMonthYear({ ...paySlipMonthYear, month: lastPaySlipAvailableMonth.month_id })
        }
        catch (error) {
            console.log(error)
        }
    }

    const fetchTotalPayrollCost = async () => {
        try {
            if (paySlipMonthYear.month) {
                const response = await request({
                    method: "get",
                    url: `payroll/admin/get-monthly-payroll-cost?month=${paySlipMonthYear.month}&year=${paySlipMonthYear.year}`,
                })
                setTotalPayrollCostDetail(response.data)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const fetchMonthlyPayrollSummary = async () => {
        if (paySlipMonthYear.month && paySlipMonthYear.year) {
            const data = await request({
                method: "get",
                url: `payroll/admin/get-payroll-monthly-summary?month=${paySlipMonthYear.month}&year=${paySlipMonthYear.year}`,
            })
            console.log("monthly payroll summary @@@@", data)
            setMonthlyPayrollSummary(data.data)
        }

    }

    useEffect(() => {
        if (tabsValue === "2") {
            getAvailableMonthPaySlip()
        }
    }, [tabsValue])

    useEffect(() => {
        if (tabsValue === "2") {
            fetchTotalPayrollCost();
            fetchMonthlyPayrollSummary();
        }
    }, [paySlipMonthYear.month, paySlipMonthYear.year, tabsValue])

    return (
        <div>
            {
                screenSize.width < 768
                    ?
                    <div className={mobileStyle.payRollSummaryContainer}>
                        <div className={mobileStyle.headerContainer}>
                            <p className={mobileStyle.heading}>Payroll Summary</p>
                            {/* <DatePicker
                                        size="medium"
                                        picker="year"
                                        style={{
                                            borderColor: "#D5D9DD",
                                            borderWidth: 1,
                                            background: "#FFFFFF",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            color: "#000000",
                                            height: "36px",
                                            width: "120px",
                                        }}
                                    // placeholder="Today"
                                    // onChange={(e) => {
                                    //     fetchDataWithRespectedMonth(e);
                                    // }}
                                    // defaultValue={dayjs().startOf('month')}
                                    // format={"MMMM YYYY"}
                                    /> */}

                        </div>
                        <div style={{ display: "flex", gap: "30px", overflowX: "auto" }}>
                            {
                                availableMonthPaySlip?.map((item, index) => {
                                    return (
                                        <>
                                            {
                                                selectedMonthForDownloadPayslip === item.month
                                                    ?
                                                    <div style={{ margin: "10px 0px 20px 0px", background: "#FBFBFB", border: "1px solid #008080", padding: "5px 10px", borderRadius: "4px" }}
                                                        onClick={() => {
                                                            setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                                            setSelectedMonthForDownloadPayslip(item.month)
                                                        }
                                                        }
                                                    >
                                                        <center>
                                                            {
                                                                item.status
                                                                    ?


                                                                    <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} width={30} height={30} />


                                                                    :
                                                                    <div style={{ width: "30px", height: "30px", borderRadius: "50%", border: "2px solid #FBA602", display: "flex", justifyContent: "center", alignItems: "center", color: "#FBA602", fontWeight: "800", cursor: "not-allowed" }}>
                                                                        {index + 1}
                                                                    </div>
                                                            }
                                                            <p style={{ fontSize: "12px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                                        </center>
                                                    </div>
                                                    :
                                                    <div style={{ margin: "10px 0px 20px 0px", padding: "10px 0px", }}
                                                        onClick={() => {
                                                            setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                                            if (item.status) {
                                                                setSelectedMonthForDownloadPayslip(item.month)
                                                            }
                                                        }}
                                                    >
                                                        <center>
                                                            {
                                                                item.status
                                                                    ?


                                                                    <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} width={30} height={30} />


                                                                    :
                                                                    <div style={{ width: "30px", height: "30px", borderRadius: "50%", border: "2px solid #FBA602", display: "flex", justifyContent: "center", alignItems: "center", color: "#FBA602", fontWeight: "800", cursor: "not-allowed" }}>
                                                                        {index + 1}
                                                                    </div>
                                                            }
                                                            <p style={{ fontSize: "12px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                                        </center>
                                                    </div>
                                            }
                                        </>

                                    )
                                })
                            }
                        </div>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p>Total Payroll</p>
                                <p>₹ {totalPayrollCostDetail.total_cost}</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p>Salary</p>
                                <p>₹ {totalPayrollCostDetail.total_salary}</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p>Bonus & Reimbursement</p>
                                <p>₹ {totalPayrollCostDetail.bonus_amount}</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <p>Tax Payment</p>
                                <p>₹ {totalPayrollCostDetail.tds_amount}</p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={styles.payRollContainer}>
                        <div className={styles.subContainer}>
                            <p className={styles.heading}>Payroll Summary</p>
                            {/* month  */}
                            <div>
                                <div style={{ display: "flex", gap: "50px" }}>
                                    {
                                        availableMonthPaySlip?.map((item, index) => {
                                            return (
                                                <>
                                                    {
                                                        selectedMonthForDownloadPayslip === item.month
                                                            ?
                                                            <div style={{ margin: "10px 0px 20px 0px", background: "#FBFBFB", border: "1px solid #008080", padding: "10px 15px", borderRadius: "4px" }}
                                                                onClick={() => {
                                                                    setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                                                    setSelectedMonthForDownloadPayslip(item.month)
                                                                }
                                                                }
                                                            >
                                                                <center>
                                                                    {
                                                                        item.status
                                                                            ?


                                                                            <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} />


                                                                            :
                                                                            <div style={{ width: "40px", height: "40px", borderRadius: "50%", border: "2px solid #FBA602", display: "flex", justifyContent: "center", alignItems: "center", color: "#FBA602", fontWeight: "800", cursor: "not-allowed" }}>
                                                                                {index + 1}
                                                                            </div>
                                                                    }
                                                                    <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                                                </center>
                                                            </div>
                                                            :
                                                            <div style={{ margin: "10px 0px 20px 0px", padding: "10px 0px", }}
                                                                onClick={() => {
                                                                    setPaySlipMonthYear({ ...paySlipMonthYear, month: item.month_id })
                                                                    if (item.status) {
                                                                        setSelectedMonthForDownloadPayslip(item.month)
                                                                    }
                                                                }}
                                                            >
                                                                <center>
                                                                    {
                                                                        item.status
                                                                            ?


                                                                            <img src={yellowTick} alt="yellowTick" style={{ cursor: "pointer" }} />


                                                                            :
                                                                            <div style={{ width: "40px", height: "40px", borderRadius: "50%", border: "2px solid #FBA602", display: "flex", justifyContent: "center", alignItems: "center", color: "#FBA602", fontWeight: "800", cursor: "not-allowed" }}>
                                                                                {index + 1}
                                                                            </div>
                                                                    }
                                                                    <p style={{ fontSize: "18px", fontWeight: "400", color: "#161616", margin: "0", marginTop: "10px", }}>{item?.month?.slice(0, 3)}</p>
                                                                </center>
                                                            </div>
                                                    }
                                                </>

                                            )
                                        })
                                    }
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button
                                        style={{
                                            border: "none",
                                            background: "#008080",
                                            color: "white",
                                            width: "120px",
                                            height: "40px",
                                            borderRadius: "5px"
                                        }}
                                    >View Detail</button>
                                </div>
                            </div>

                            <div className={styles.payrollSummaryContainer}>
                                <div className={styles.payrollSummaryCard}>
                                    <div className={styles.innerContainer}>
                                        <p className={styles.value}>{monthlyPayrollSummary?.calender_days}</p>
                                        <img src={calendarDays} alt="calendarDays" />
                                    </div>
                                    <p className={styles.title}>Calendar Days</p>
                                </div>
                                <div className={styles.payrollSummaryCard}>
                                    <div className={styles.innerContainer}>
                                        <p className={styles.value}>{monthlyPayrollSummary?.total_employees}</p>
                                        <img src={employee} alt="employee" />
                                    </div>
                                    <p className={styles.title}>Total Employees
                                        <img src={inc} alt="inc" width={15} height={15} />
                                        {monthlyPayrollSummary?.total_new_joiner}
                                        <img src={dec} alt="dec" width={15} height={15} />
                                        {monthlyPayrollSummary?.total_full_And_Final_employees}</p>

                                </div>
                                <div className={styles.payrollSummaryCard}>
                                    <div className={styles.innerContainer}>
                                        <p className={styles.value}>{monthlyPayrollSummary?.current_month_salary_processed}/{monthlyPayrollSummary?.total_employees}</p>
                                        <img src={payrollProcessed} alt="payrollProcessed" />
                                    </div>
                                    <p className={styles.title}>Payroll Processed</p>
                                </div>
                            </div>

                            <div>
                                <div style={{ display: "flex", alignItems: "center", border: "1px solid #F0F0F0", padding: "20px 10px", marginTop: "5vh" }}>
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", width: "100%" }}>
                                        <div className={styles.appresalBox}  >
                                            <div>
                                                <p className={styles.appresalHeading}>Total Payroll Cost</p>
                                                <p className={styles.appresalValue}>₹ {totalPayrollCostDetail.total_cost}</p>
                                                <p className={styles.reviseAppresalValue}>
                                                    <span className={styles.appresalHeading}>{totalPayrollCostDetail.total_cost_change_indicator}</span>{" "}
                                                    {totalPayrollCostDetail.total_cost_percentage}%
                                                </p>
                                            </div>
                                            <p className={styles.appresalSign}>
                                                =
                                            </p>
                                        </div>
                                        <div className={styles.appresalBox}>
                                            <div>
                                                <p className={styles.appresalHeading}>Salary</p>
                                                <p className={styles.appresalValue}>₹ {totalPayrollCostDetail.total_salary}</p>
                                                <p className={styles.reviseAppresalValue}>
                                                    <span className={styles.appresalHeading}>{totalPayrollCostDetail.total_salary_change_indicator}</span>{" "}
                                                    {totalPayrollCostDetail.total_salary_percentage}%
                                                </p>
                                            </div>
                                            <p className={styles.appresalSign}>
                                                +
                                            </p>
                                        </div>
                                        <div className={styles.appresalBox}>
                                            <div>
                                                <p className={styles.appresalHeading}>Bonus</p>
                                                <p className={styles.appresalValue}>₹ {totalPayrollCostDetail.bonus_amount}</p>
                                                <p className={styles.reviseAppresalValue}>
                                                    <span className={styles.appresalHeading}>{totalPayrollCostDetail.bonus_change_indicator}</span>{" "}
                                                    {totalPayrollCostDetail.bonus_percentage}%
                                                </p>
                                            </div>
                                            <p className={styles.appresalSign}>
                                                +
                                            </p>
                                        </div>
                                        <div className={styles.appresalBox}>
                                            <div>
                                                <p className={styles.appresalHeading}>Tax</p>
                                                <p className={styles.appresalValue}>₹ {totalPayrollCostDetail.tds_amount}</p>
                                                <p className={styles.reviseAppresalValue}>
                                                    <span className={styles.appresalHeading}>{totalPayrollCostDetail.tds_change_indicator}</span>{" "}
                                                    {totalPayrollCostDetail.tds_percentage}%
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <PayrollSummaryInsight />
                    </div>
            }

        </div>
    )
}

export default PayrollSummary