import React, {useState, useEffect} from 'react';
import CommonTable from '../../../../component/CommonTable/CommonTable';
import {Switch, Table} from 'antd';
import request from '../../../../utils/axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import update from 'immutability-helper';
import SearchBar from '../../SearchBar/Search';
import getScreenSize from '../../../../utils/getScreenSize';
function Setting() {
  const screenSize = getScreenSize();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Number of items per page
    total: 0,
  });
  const ToggleTracking = async (record, index, key) => {
    const apiUrl = `/tracking/admin/employee-tracking/action?employement_number=${record.employement_number}`;

    try {
      const res = await request({
        method: 'post',
        url: apiUrl,
        data: {
          is_on: !record.is_field_tracking_enabled,
        },
      });

      const updatedArray = update(dataList, {
        [index]: {
          [key]: {
            $set: !record.is_field_tracking_enabled,
          },
        },
      });
      setDataList(updatedArray);

      console.log(res);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  useEffect(() => {
    LoadData();
  }, []);

  // useEffect(() => {
  //   console.table('dataList...', dataList);
  // }, [dataList]);

  const LoadData = async newPaginaiton => {
    const {current, pageSize} = newPaginaiton || pagination;
    const apiUrl = `tracking/reporting-manager/employee-list/all?page=${current}&page_size=${pageSize}`;

    try {
      const res = await request({
        method: 'get',
        url: apiUrl,
      });
      setDataList([...dataList, ...res.data?.pagination_data?.data]);
      setPagination({
        ...pagination,
        current: current + 1,
        total: res.data?.pagination_data?.total,
      });

      if (res.data?.pagination_data?.next === null) {
        setHasMore(false);
        return;
      }
      // setDataList(res.data);
      // console.log(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const EmployeeListColmns = [
    {
      title: 'Employee Id',
      dataIndex: 'employement_number',
      key: 'employement_number',
    },
    {
      title: 'Employee Name',
      dataIndex: 'employee_name',
      key: 'employee_name',
    },

    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
    },
    // {
    //   title: 'Location',
    //   dataIndex: 'location',
    //   key: 'location',
    // },
    {
      title: 'Field Tracking',
      dataIndex: 'is_field_tracking_enabled',
      key: 'is_field_tracking_enabled',
      render: (text, record, index) => (
        <div>
          <Switch
            checked={record.is_field_tracking_enabled}
            onClick={() => {
              ToggleTracking(record, index, 'is_field_tracking_enabled');
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '24px',
        }}>
        <div style={{fontSize: '24px', fontWeight: '600', color: '#161616'}}>
          Employee List
        </div>
        <SearchBar placeholderText="Search for Employees" />
      </div>
      <div
        id="scrollableDiv"
        style={{
          height: 'auto',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}>
        <InfiniteScroll
          dataLength={pagination.total - dataList?.length * pagination.current}
          next={LoadData}
          hasMore={hasMore}
          endMessage={
            <p style={{textAlign: 'center'}}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          loader={
            <p style={{textAlign: 'center'}}>
              <b>Loading...</b>
            </p>
          }
          scrollableTarget="scrollableDiv"
          height="70vh">
          <Table
            columns={EmployeeListColmns}
            dataSource={dataList}
            pagination={false}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default Setting;
