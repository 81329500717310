import React from 'react';
// import Location from "../Location/Location";
import TeamLocation from './Location/Location';
import TeamMeeting from './TeamMeeting/TeamMeeting';
import MyMeeting from './Mymeeting/MyMeeting';
import getScreenSize from '../../../../utils/getScreenSize';
const Dashboard = ({changeTabsValue}) => {
  const screenSize = getScreenSize();
  return (
    <>
      {screenSize.width < 768 ? (
        //moile view
        <div>
          <TeamLocation changeTabsValue={changeTabsValue} />
          <div
            style={{
              height: '12px',
              backgroundColor: 'rgb(247, 248, 252)',
              marginTop: '20px',
            }}></div>
          <TeamMeeting changeTabsValue={changeTabsValue} />
          <div
            style={{
              height: '12px',
              backgroundColor: 'rgb(247, 248, 252)',
              marginTop: '20px',
            }}></div>
          <MyMeeting changeTabsValue={changeTabsValue} />
        </div>
      ) : (
        //desktop view
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}>
          <TeamLocation changeTabsValue={changeTabsValue} />
          <TeamMeeting changeTabsValue={changeTabsValue} />
          <MyMeeting changeTabsValue={changeTabsValue} />
        </div>
      )}
    </>
  );
};

export default Dashboard;
// rgb(247, 248, 252)
