import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState: {
        employee_name: "",
        personal_email: "",
        contact: "",
        location: "",
        address: "",
        marital_status: "",
        dob: "",
        joining_date: "",
        department: "",
        reporting_manager: "",
        anniversary: "",
        employee_type: "",
        employement_number: "",
        emp_functions: "",
        profile_image: ""
    },
    reducers: {
        setUserProfileInRedux: (state, action) => {
            const {
                employee_name,
                personal_email,
                contact,
                location,
                address,
                marital_status,
                dob,
                joining_date,
                department,
                reporting_manager,
                anniversary,
                employee_type,
                employement_number,
                emp_functions,
                profile_image
            } = action.payload;

            state.employee_name = employee_name;
            state.personal_email = personal_email;
            state.contact = contact;
            state.location = location;
            state.address = address;
            state.marital_status = marital_status;
            state.dob = dob;
            state.joining_date = joining_date;
            state.department = department;
            state.reporting_manager = reporting_manager;
            state.anniversary = anniversary;
            state.employee_type = employee_type;
            state.employement_number = employement_number;
            state.emp_functions = emp_functions;
            state.profile_image = profile_image
        },
    },
})


export const { setUserProfileInRedux } = userProfileSlice.actions;

export default userProfileSlice.reducer;