import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

import "./ViewFeedBack.scss"
import request from '../../../../utils/axios'
import { useParams } from 'react-router-dom'
import getScreenSize from "../../../../utils/getScreenSize"
import GiveFeedBack from "../GiveFeedBack/GiveFeedBack"
function ViewFeedBack({ applicantId }) {
    const params = useParams();
    const screenSize = getScreenSize();
    const [reload, setReload] = useState(false)
    const [feedbackList, setFeedbackList] = useState([])
    const fetchData = async (page, page_size) => {
        const data = await request({
            method: "get",
            url: `recruitment/applicant/${params.id}/feedback/?page=${page}&page_size=${page_size}`,
        })

        setFeedbackList(data.pagination_data.data)
    }
    const [feedBackSummary, setFeedBackSummary] = useState([])
    const fetctSummaryData = async () => {
        const data = await request({
            method: "get",
            url: `recruitment/feedback/summary/?candidate_id=${params.id}`,
        })
        setFeedBackSummary(data?.data)
    }

    useEffect(() => {
        fetchData(1, 20);
        fetctSummaryData();
    }, [reload])

    const ratingList = [
        {
            id: 1,
            value: 1,
            color: "#FF5757"
        },
        {
            id: 2,
            value: 2,
            color: "#FE9494"
        },
        {
            id: 3,
            value: 3,
            color: "#FFD90F"
        },
        {
            id: 4,
            value: 4,
            color: "#B0DC00"
        },
        {
            id: 5,
            value: 5,
            color: "#91B501"
        }
    ]

    return (
        <div className='viewFeedBack'>
            <div >
                <div className='firstRow'>
                    <p className='heading'>FeedBack</p>
                    <GiveFeedBack
                        feedback_for={params.id}
                        btnContent={<Button type="primary" className='feedBackBtn'>Add FeedBack</Button>}
                        reload={reload}
                        setReload={setReload}
                    />


                </div>
                
                <div className='secondRow'>
                    <div className='boxShadow detailBox'>
                        <p>Overall Rating</p>
                        <center>
                            <div
                                className='rating'
                            >
                                <span>{feedBackSummary?.overall_rating}</span>
                            </div>
                        </center>
                    </div>

                    <div className='boxShadow detailBox' >
                        <p>No. of Rounds</p>
                        <center>
                            <div
                                className='noOfRounds'
                            >
                                <span>{feedBackSummary?.round_count}</span>
                            </div>
                        </center>
                    </div>
                </div>

                <div className='thirdRow'>
                    {
                        feedbackList?.map((item, index) => {
                            return (
                                <>
                                    {
                                        screenSize.width < 767
                                            ?
                                            <div className='boxShadow feedBackViewContainer'>

                                                <div className='flexSpaceBetween' >
                                                    <p style={{ fontSize: "24px", fontWeight: "500" }}>Round {item?.round}</p>
                                                    <div key={item.id} style={{ marginRight: "5vw" }}>
                                                        <div
                                                            style={{ backgroundColor: ratingList[item?.ratings - 1]?.color }}
                                                            className="rating"
                                                        >
                                                            <span>{item.ratings}</span>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div>

                                                    <p>{item.feedback_by}</p>
                                                    <p>{item.feedback}</p>
                                                </div>
                                            </div>
                                            :
                                            <div className='boxShadow feedBackViewContainer'>
                                                <div>
                                                    <p style={{ fontSize: "24px", fontWeight: "500" }}>Round {item?.round}</p>
                                                    <p>{item.feedback_by}</p>
                                                    <p>{item.feedback}</p>
                                                </div>
                                                <div className='' >

                                                    <div key={item.id} style={{ marginRight: "5vw" }}>
                                                        <div
                                                            style={{ backgroundColor: ratingList[item?.ratings - 1]?.color }}
                                                            className="rating"
                                                        >
                                                            <span>{item.ratings}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                    }
                                </>

                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ViewFeedBack