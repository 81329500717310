import { Tabs } from 'antd'
import React, { useState } from 'react'
import Dashboard from '../Dashboard/Dashboard';
import Posts from '../Posts/Posts';
import SurveyAnalysis from '../SurveyAnalysis/SurveyAnalysis';

function FeedsTab() {
    const [currentTab, setCurrentTab] = useState("1")
    const tab_items = [
        {
            key: '1',
            label: 'Dashboard',
            children: <Dashboard setCurrentTab={setCurrentTab} />,
        },
        {
            key: '2',
            label: 'Posts',
            children: <Posts />,
        },
        {
            key: '3',
            label: 'Survey Analysis',
            children: <SurveyAnalysis />,
        },
    ];

    return (
        <div>
            <Tabs
                activeKey={currentTab}
                items={tab_items}
                onChange={(e) => setCurrentTab(e)}
            />
        </div>
    )
}

export default FeedsTab