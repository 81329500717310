import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    org: '',
    personalDetails: {
        employee_name: '',
        personal_email: null,
        contact: null,
        address: null,
        dob: null,
        gender: null,
        marital_status: null,
        anniversary: null,
    },
    employmentDetails: {
        designation: '',
        reporting_manager: '',
        joining_date: '',
        employee_type: '',
        department: '',
        probation_period: '',
        work_location: '',
        notice_period: '',
        employement_number: '',
        empfunction: '',
        official_email: '',
        grade: '',
        hrbp: '',
    },
    policies: {
        leave_policy: '',
        work_policy: '',
        salary_structure: ''
    },
    salaryDetails: {
        ctc: '',
        salaryBreakUp: null
    }
}
const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        setOrgDetails: (state, action) => {
            state.org = action.payload
        },
        setPersonalDetails: (state, action) => {
            const {
                employee_name,
                personal_email,
                contact,
                address,
                dob,
                gender,
                marital_status,
                anniversary
            } = action.payload;

            state.personalDetails.employee_name = employee_name;
            state.personalDetails.personal_email = personal_email;
            state.personalDetails.contact = contact;
            state.personalDetails.address = address;
            state.personalDetails.dob = dob;
            state.personalDetails.gender = gender;
            state.personalDetails.marital_status = marital_status;
            state.personalDetails.anniversary = anniversary;
        },

        setEmploymentDetails: (state, action) => {
            const {
                designation,
                reporting_manager,
                joining_date,
                employee_type,
                department,
                probation_period,
                work_location,
                notice_period,
                employement_number,
                empfunction,
                official_email,
                grade,
                hrbp,
            } = action.payload;

            state.employmentDetails.designation = designation;
            state.employmentDetails.reporting_manager = reporting_manager;
            state.employmentDetails.joining_date = joining_date;
            state.employmentDetails.employee_type = employee_type;
            state.employmentDetails.department = department;
            state.employmentDetails.probation_period = probation_period;
            state.employmentDetails.work_location = work_location;
            state.employmentDetails.notice_period = notice_period;
            state.employmentDetails.employement_number = employement_number;
            state.employmentDetails.empfunction = empfunction;
            state.employmentDetails.official_email = official_email.toLocaleLowerCase();
            state.employmentDetails.grade = grade;
            state.employmentDetails.hrbp = hrbp;

        },

        setPolicies: (state, action) => {
            const {
                leave_policy,
                work_policy,
                salary_structure
            } = action.payload;

            state.policies.leave_policy = leave_policy;
            state.policies.work_policy = work_policy;
            state.policies.salary_structure = salary_structure;
        },

        setSalaryDetails: (state, action) => {
            const {
               ctc,
               salaryBreakUp
            } = action.payload;

            state.salaryDetails.ctc = ctc;
            state.salaryDetails.salaryBreakUp = salaryBreakUp
        },


        resetEmployeeDetails: (state, action) => {
            state.org = initialState.org;
            state.personalDetails = initialState.personalDetails;
            state.employmentDetails = initialState.employmentDetails;
            state.policies = initialState.policies;
            state.salaryDetails = initialState.salaryDetails
        }
    },
})
export const { setOrgDetails, setPersonalDetails, setEmploymentDetails, setPolicies, setSalaryDetails, resetEmployeeDetails } = employeeSlice.actions;

export default employeeSlice.reducer;