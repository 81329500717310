import React from 'react';
import getScreenSize from '../../utils/getScreenSize';
const Layout = ({children}) => {
  const screenSize = getScreenSize();

  return (
    <>
      {screenSize.width < 768 ? (
        <div>{children}</div>
      ) : (
        <div style={{padding: '50px'}}>{children}</div>
      )}
    </>
  );
};

export default Layout;
