import React, {useState, useEffect} from 'react';
import Meeting from './Meeting';
import Setting from '../Setting/Setting';
import back from '../../../../assets/Recruitment/back.svg';
import getScreenSize from '../../../../utils/getScreenSize';
const TeamMeeting = ({changeTabsValue, tabsValue}) => {
  const [tabs, setTabs] = useState(1);
  const screenSize = getScreenSize();
  const handletabs = value => {
    setTabs(value);
  };

  return (
    <>
      {tabs == 2 && (
        <p
          onClick={() => {
            setTabs(1);
          }}
          style={{cursor: 'pointer'}}>
          <img src={back} alt="back" />
          <span style={{paddingLeft: '10px'}}>Back</span>
        </p>
      )}

      <div
        style={{
          background: screenSize < 768 ? '' : 'white',
          padding: screenSize.width < 768 ? '' : '30px 30px',
        }}>
        {tabs == 1 && (
          <Meeting
            changeTabsValue={changeTabsValue}
            tabsValue={tabsValue}
            handletabs={handletabs}
          />
        )}

        {tabs == 2 && <Setting />}
      </div>
    </>
  );
};

export default TeamMeeting;
