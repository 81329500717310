import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import { Modal, Switch } from 'antd';


import "./MobileMenu.scss";

import mobileMenuList from "../../data/mobileMenuList";
import adminMobileMenu from "../../data/adminMobileMenu";

import org from "../../assets/MobileMenu/more/org.jpg";
import profile from "../../assets/MobileMenu/more/profile.jpg";
import employee from "../../assets/MobileMenu/more/employee.jpg";
import leave from "../../assets/MobileMenu/more/leave.jpg";
import payRoll from "../../assets/MobileMenu/more/payroll.jpg";
import job from "../../assets/MobileMenu/more/job.jpg"
import docs from "../../assets/MobileMenu/more/docs.jpg"
import headerprofile from "../../assets/headerprofile.svg";
import logout from "../../assets/logout.svg";
import request from "../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfileInRedux } from "../../redux/reducer/UserProfileReducer";
import InitiateSepration from "../../assets/InitiateSepration.png"
import getScreenSize from "../../utils/getScreenSize";
import userProfileImg from "../../assets/user.png"


function MobileMenu({ content, menuTitle, showBackButton }) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState();
  const [profileModel, setProfileModel] = useState(false);
  const userProfile = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const [initiateSeprationModel, setInitialSeperationModel] = useState(false)

  const is_admin = JSON.parse(localStorage.getItem("is_admin"));
  const role = localStorage.getItem("role");
  const role_list = JSON.parse(localStorage.getItem("role_list"));
  const profileRef = useRef(null);
  const screenSize = getScreenSize();

  const navigate = useNavigate();

  const handleItemClick = (key) => {
    setActiveItem(key);
    if (key === "more") {
      toggleMenu();
    }
  };

  const toggleMenu = (label) => {
    setIsOpen(!isOpen);
    if (label === "attendance" || label === "profile") {
      setActiveItem(label);
    } else {
      setActiveItem("more");
    }
  };

  const switchAvatar = (userType) => {
    localStorage.setItem("role", userType);
    navigate("/dashboard");
  };

  const fetchUserProfileImage = async () => {
    try {
      const data = await request({
        method: "GET",
        url: "/users/dashboard/profile-image",
      });

      dispatch(
        setUserProfileInRedux({
          ...userProfile,
          profile_image:
            data.profile_image,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {

    fetchUserProfileImage();
  }, []);

  let location = useLocation();
  const currentUrl = location.pathname;

  const logoutUser = async () => {
    try {

      let deviceType;
      if (screenSize.width < 768) {
        // mobile
        deviceType = 0
      }
      else if (screenSize.width < 1440) {
        // laptop
        deviceType = 1
      }
      else {
        // desktop
        deviceType = 2
      }

      await request({
        method: "post",
        url: `/users/logout`,
        data: {
          device_type: deviceType
        }
      })

      localStorage.clear();
      setProfileModel(!profileModel);
      let logout = "logout"
      if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
        window.ReactNativeWebView.postMessage(JSON.stringify({ logout }));
      } else {
        console.warn('ReactNativeWebView is not defined.');
      }
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const urlContainAdmin = currentUrl.split("/")[1] === "admin";
    const moduleName = currentUrl.split("/")[2];

    if (urlContainAdmin && is_admin) {
      const isAuthorized = role_list?.includes(moduleName);
      if (isAuthorized) {
        navigate(currentUrl);
      } else {
        navigate("/dashboard");
      }
    } else if (!urlContainAdmin) {
      navigate(currentUrl);
    }

    const path = window.location.pathname;

    if (role === "Admin") {
      if (path.includes("dashboard")) {
        setActiveItem("home");
      }
      else if (path.includes("attendance")) {
        setActiveItem("attendance");
      }
      else if (path.includes("leave")) {
        setActiveItem("leave");
      }
      else {
        setActiveItem("more");
      }

    }
    else {
      if (path.includes("dashboard")) {
        setActiveItem("home");
      }
      else if (path.includes("attendance")) {
        setActiveItem("attendance");
      }
      else if (path.includes("inbox")) {
        setActiveItem("inbox");
      }
      else {
        setActiveItem("more");
      }
    }

  }, [currentUrl]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        // Clicked outside the popup, so close it
        setProfileModel(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const initiateSeparation = async () => {
    try {
      await request({
        method: "post",
        url: `/employee/resignation/create`,
        data: {
          need_sepration: true
        }
      })

      setInitialSeperationModel(false)
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="mobileMenu">
      <div className="content">
        <div className="header flexSpaceBetween">
          {is_admin ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  gap: "10px",
                  paddingLeft: "16px",
                }}
              >
                {showBackButton && (
                  <span
                    onClick={() => {
                      navigate(-2);
                    }}
                  >
                    <LeftOutlined style={{ color: "white" }} />
                  </span>
                )}
                <p className="menuTitle">{menuTitle}</p>
              </div>
              {/* <div className="avatar-container">
              
                <div
                  onClick={() => switchAvatar("Emp")}
                  className={
                    role === "Emp"
                      ? "empSelected flexCenter"
                      : "empNotSelected flexCenter"
                  }
                >
                  <span>Emp</span>
                </div>
                <div
                  onClick={() => {
                    is_admin && switchAvatar("Admin");
                  }}
                  className={
                    role === "Admin"
                      ? "adminSelected flexCenter"
                      : is_admin
                      ? "adminNotSelected flexCenter"
                      : "adminNotSelectedDisable flexCenter"
                  }
                >
                  Admin
                </div>
              </div> */}
              <div className="avatar-container">
                <Switch
                  checked={role === 'Admin' ? true : false}
                  onChange={(e) => {
                    switchAvatar(e ? 'Admin' : 'Emp')
                    if (e) {
                      navigate('/admin/dashboard');
                    }
                    else {
                      navigate('/dashboard');
                    }
                  }}
                />
                <span style={{ fontWeight: "600", marginLeft: "4px" }}>
                  {" "}
                  Admin
                </span>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "14px",
                  right: "15px",
                  zIndex: "999",
                }}
                onClick={() => {
                  setProfileModel(!profileModel);
                }}
              >
                <div
                  style={{
                    // position: "relative",
                    right: "0px",
                    border: "2px solid #3D2879",
                    height: "32px",
                    width: "32px",
                    borderRadius: "50%",
                    background: "white",
                    color: "black",
                    fontSize: "10px",
                    fontWeight: "600",
                    zIndex: "1", alignItems: 'center'
                  }}
                >
                  <img
                    src={userProfile?.profile_image ? userProfile.profile_image : userProfileImg}
                    alt=""

                    style={{
                      borderRadius: "50%",
                      top: "0px",
                      width: "100%",
                      height: "100%",
                      // position: "relative",
                    }}
                  />
                </div>
              </div>
              {profileModel && (
                <div class="arrowContainer" ref={profileRef}>
                  <ul
                    style={{
                      listStyleType: "none",
                      margin: "0px",
                      padding: "0px",
                      textAlign: "start",
                    }}
                  >
                    <div style={{ marginLeft: "20px" }}>
                      <li
                        onClick={() => {
                          navigate("/user-profile");
                          setProfileModel(!profileModel);
                        }}
                        style={{
                          lineHeight: "50px"
                        }}
                      >
                        <img src={headerprofile} alt="" width={15} height={15} />
                        <span
                          style={{
                            color: "#8E8E8E",
                            fontWeight: "400",
                            fontSize: "18px",
                            marginLeft: "10px",
                          }}
                        >
                          Profile
                        </span>
                      </li>
                      <li
                        onClick={() => logoutUser()}
                        style={{
                          lineHeight: "50px"
                        }}
                      >
                        <img src={logout} alt="Avatar" width={15} height={15} />
                        <span
                          style={{
                            color: "#8E8E8E",
                            fontWeight: "400",
                            fontSize: "18px",
                            marginLeft: "10px",
                          }}
                        >
                          Logout
                        </span>
                      </li>
                      <li
                        onClick={() => setInitialSeperationModel(true)}
                        style={{
                          lineHeight: "50px"
                        }}>
                        <img src={InitiateSepration} alt="Avatar" width={15} height={15} />
                        <span style={{ color: "#8E8E8E", fontWeight: "400", fontSize: "18px", marginLeft: "10px" }}>Initiate Sepration</span>
                      </li>
                    </div>
                  </ul>
                </div>
              )}
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  gap: "10px",
                  paddingLeft: "16px",
                }}
              >
                {showBackButton && (
                  <span
                    onClick={() => {
                      navigate(-3);
                    }}
                  >
                    <LeftOutlined style={{ color: "white" }} />
                  </span>
                )}
                <p className="menuTitle">{menuTitle}</p>
              </div>

              <div
                style={{
                  position: "absolute",
                  top: "14px",
                  right: "15px",
                  zIndex: "999",
                }}
                onClick={() => {
                  setProfileModel(!profileModel);
                }}
              >
                <div
                  style={{
                    // position: "relative",
                    right: "0px",
                    border: "2px solid #3D2879",
                    height: "32px",
                    width: "32px",
                    borderRadius: "50%",
                    background: "white",
                    color: "black",
                    fontSize: "10px",
                    fontWeight: "600",
                    zIndex: "1",
                  }}
                >
                  <img
                    src={userProfile?.profile_image}
                    alt=""
                    width={25}
                    height={25}
                    style={{
                      borderRadius: "50%",
                      top: "0px",
                      position: "relative",
                    }}
                  />
                </div>
              </div>

              {profileModel && (
                <div class="arrowContainer" ref={profileRef}>
                  <ul
                    style={{
                      listStyleType: "none",
                      margin: "0px",
                      padding: "0px",
                      textAlign: "start",
                    }}
                  >
                    <div style={{ marginLeft: "20px" }}>
                      <li
                        onClick={() => {
                          navigate("/user-profile");
                          setProfileModel(!profileModel);
                        }}
                        style={{
                          lineHeight: "40px",
                        }}
                      >
                        <img src={headerprofile} alt="" />
                        <span
                          style={{
                            color: "#8E8E8E",
                            fontWeight: "400",
                            fontSize: "18px",
                            marginLeft: "10px",
                          }}
                        >
                          Profile
                        </span>
                      </li>
                      <li
                        onClick={() => logoutUser()}
                        style={{
                          lineHeight: "50px",
                        }}
                      >
                        <img src={logout} alt="Avatar" />
                        <span
                          style={{
                            color: "#8E8E8E",
                            fontWeight: "400",
                            fontSize: "18px",
                            marginLeft: "10px",
                          }}
                        >
                          Logout
                        </span>
                      </li>
                      <li
                        onClick={() => setInitialSeperationModel(true)}
                        style={{
                          lineHeight: "50px",
                        }}>
                        <img src={InitiateSepration} alt="Avatar" width={15} height={15} />
                        <span style={{ color: "#8E8E8E", fontWeight: "400", fontSize: "18px", marginLeft: "10px" }}>Initiate Sepration</span>
                      </li>
                    </div>
                  </ul>
                </div>
              )}
              <div className="avatar-container">{/* empty */}</div>
            </>
          )}
        </div>
        <div className="main-content">
          {content}
          <div style={{ height: "100px" }}></div>
        </div>
        <div className="footer">
          {isOpen && <MorePopUp toggleMenu={toggleMenu} />}
          <ul style={{ justifyContent: "space-around", height: "76px" }}>
            {role === "Admin"
              ?
              adminMobileMenu.map((item, index) => {
                return (
                  <Link to={item.path}>
                    <li key={index} onClick={() => {
                      handleItemClick(item.key)
                      if (isOpen) {
                        setIsOpen(false)
                      }
                    }}>
                      <div className="menuItemContainer">
                        <img
                          src={
                            activeItem === item.key
                              ? item.activeImage
                              : item.image
                          }
                          width={24}
                          height={24}
                        />
                        <p
                          className={` ${activeItem === item.key ? "activeMenuLabel" : "menuLabel"
                            }`}
                        >
                          {item.label}
                        </p>
                      </div>
                    </li>
                  </Link>
                );
              })
              : mobileMenuList.map((item, index) => {
                return (
                  <Link to={item.path}>
                    <li key={index} onClick={() => {
                      handleItemClick(item.key)
                      if (isOpen) {
                        setIsOpen(false)
                      }
                    }}>
                      <div className="menuItemContainer">
                        <img
                          src={
                            activeItem === item.key
                              ? item.activeImage
                              : item.image
                          }
                          width={24}
                          height={24}
                        />
                        <p
                          className={` ${activeItem === item.key ? "activeMenuLabel" : "menuLabel"
                            }`}
                        >
                          {item.label}
                        </p>
                      </div>
                    </li>
                  </Link>
                );
              })}
          </ul>
        </div>
      </div>

      <Modal
        centered
        open={initiateSeprationModel}
        onOk={() => setInitialSeperationModel(false)}
        onCancel={() => setInitialSeperationModel(false)}
        footer={null}
        width={600}
      >
        <p style={{ fontSize: "24px" }}>Initiate Separation</p>
        <p style={{ fontSize: "18px" }}>Are you sure you want to proceed with your resignation</p>
        <div style={{ display: "flex", gap: "10px" }}>
          <button style={{ color: "#008080", border: "1px solid #008080", background: "none", height: "40px", width: "100px", borderRadius: "2px" }}
            onClick={() => setInitialSeperationModel(false)}
          >No</button>
          <button style={{ color: "white", background: "#008080", border: "1px solid #008080", height: "40px", width: "100px", borderRadius: "2px" }}
            onClick={() => initiateSeparation()}
          >Yes</button>
        </div>
      </Modal>
    </div>
  );
}
export default MobileMenu;

const MorePopUp = ({ toggleMenu }) => {
  const role = localStorage.getItem("role");
  const [data, setData] = useState([]);

  const moreMenuList = [
    {
      image: job,
      path: "/recruitment",
      label: "Job",
    },
    {
      image: payRoll,
      path: "/payroll",
      label: "Payroll",
    },
    {
      image: leave,
      path: "/leave",
      label: "Leave",
    },
    {
      image: profile,
      path: "/user-profile",
      label: "Profile",
    },
  ];

  const adminMoreMenuList = [
    {
      key: "org",
      image: org,
      path: "/admin/org",
      label: "Org",
    },
    {
      key: "payroll",
      image: payRoll,
      path: "/admin/payroll",
      label: "Payroll",
    },
    {
      key: "recruitment",
      image: job,
      path: "/admin/recruitment",
      label: "Job",
    },
    {
      key: "employee",
      image: employee,
      path: "/admin/employee",
      label: "employees",
    },
    {
      key: "profile",
      image: profile,
      path: "/user-profile",
      label: "Profile",
    },
    {
      key: "docs",
      image: docs,
      path: "/admin/docs",
      label: "Documents",
    },

  ];

  useEffect(() => {
    const roleList = JSON.parse(localStorage.getItem("role_list")) || [];

    const filteredData = adminMoreMenuList.filter((item) =>
      roleList?.includes(item.key)
    );
    setData(filteredData);
  }, []);

  let menuList = role === "Admin" ? data : moreMenuList;

  return (
    <div className="backgroundContainer">
      <div className="morePopUpContainer">
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              flex: 1,
              textAlign: "center",
              fontSize: 24,
              marginTop: "30px",
              marginBottom: "50px",
              fontSize: "18px",
              fontWeight: 600,
              color: "#161616",
            }}
          >
            More
          </p>
          <div style={{ marginRight: "10px", marginTop: "-15px" }}>
            <CloseOutlined onClick={() => toggleMenu()} style={{ color: "#5C5E61" }} />
          </div>
        </div>
        <div className="more">
          <div className="grid-container">
            {menuList.map((item, index) => {
              return (
                <Link
                  to={item.path}
                  onClick={() => toggleMenu(item.label)}
                  key={index}
                  style={{ textDecoration: "none" }}
                >

                  <img src={item.image} alt={item.image} width={52} height={52} />
                  <p style={{ marginTop: "10px", color: "#5C5E61", fontSize: "12px" }}>{item.label}</p>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
