import { Button, Col, Form, Input, Row, Select, Icon, Table } from "antd";
import {
  CalendarOutlined,
  EnterOutlined,
  EnvironmentFilled,
  SearchOutlined,
  home,
} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import request from "../../../../utils/axios";
import { useNavigate } from "react-router-dom";
import TableWithPagination from "../../../../component/TableWithPagination/TableWithPagination";
import edit from "../../../../assets/edit.svg";
import share from "../../../../assets/share.svg";
import calendar from "../../../../assets/calendar.svg";
import location from "../../../../assets/location.svg";
import "./Jobs.scss";
import workMode from "../../../../assets/workMode.png";

import useScreenSize from "../../../../utils/getScreenSize";
import DynamicTable from "../../../../component/TableWithPagination/TableWithPagination";
import moment from "moment";
import InfiniteScroll from "../../../../component/InfiniteScroll/InfiniteScroll";
// import Icon from '@ant-design/icons/lib/components/Icon';
import mobileStyle from "../Tabs/Mobile.module.scss";
import dayjs from "dayjs";
import CommonTable from "../../../../component/CommonTable/CommonTable";
import InfiniteScrollComponent from "../../../../component/InfiniteScroll/InfiniteScroll";

function AdminJobListFullView() {
  const ref = useRef();
  const [jobList, setJobList] = useState(null);
  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5, // Number of items per page
    total: 0,
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "posting_date",
      key: "posting_date",
      render: (text, record) => (
        <span>{moment(text).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Posted By",
      dataIndex: "posted_by",
      key: "posted_by",
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
    },
    {
      title: "Function",
      dataIndex: "job_function",
      key: "job_function",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div style={{ display: "flex" }}>
          <img
            alt="edit"
            src={edit}
            width={50}
            height={20}
            style={{ borderRadius: "50%", marginRight: "10px" }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/admin/recruitment/post-job/${record.id}`, {
                replace: true,
              });
            }}
          />
          <img
            alt="edit"
            src={share}
            width={50}
            height={25}
            style={{ borderRadius: "50%", marginRight: "10px" }}
          />
        </div>
      ),
    },
  ];
  const [LatestJobList, setLatestJobList] = useState([]);

  const fetchRowData = async () => {
    const data = await request({
      method: "get",
      url: `recruitment/all-jobs?page=${pagination.current}&page_size=${pagination.pageSize}`,
    });
    setLatestJobList(data?.pagination_data.data);
    setPagination({
      ...pagination,
      total: data?.pagination_data?.total,
    });

    return data;
  };

  const handleRowClick = (record) => {
    // Navigate to a new component with the selected record data
    navigate("/admin/recruitment/details/" + record.id, { replace: true });
  };

  const [search, setSearch] = useState("");
  useEffect(() => {
    let timer = setTimeout(() => {
      console.log("seaching....", search);
      // fetchData();
    }, 2000);

    return () => clearTimeout(timer);
  }, [search]);

  useEffect(() => {
    fetchRowData();
  }, [pagination.current]);
  const renderPosts = (posts) => (
    <>
      {posts.map((item, index) => (
        <div
          className="mobileContainer"
          onClick={(e) => {
            navigate("/admin/recruitment/details/" + item.id, {
              replace: true,
            });
          }}
        >
          <div key={index} className="adminJobListCard">
            <div className="headingContainer">
              <span className="title">{item.job_title}</span>
              <div className="action">
                <img
                  alt="edit"
                  src={edit}
                  style={{ width: "14px", height: "14px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/admin/recruitment/post-job/${item.id}`, {
                      replace: true,
                    });
                  }}
                />
                <img
                  alt="edit"
                  src={share}
                  width={50}
                  height={25}
                  style={{ width: "14px", height: "14px" }}
                />
              </div>
            </div>
            <span className="function">{item.job_function}</span>
            <div className="adminJobListCardFooter">
              <span style={{ display: "flex", alignItems: "center" }}>
                <img src={calendar} alt="" style={{ height: "14px" }} />
                <span className="date">12/12/12</span>
              </span>
              <span style={{ display: "flex", alignItems: "center" }}>
                <img src={location} alt="" />{" "}
                <span className="location">{item.location}</span>
              </span>
            </div>
          </div>
        </div>
      ))}
    </>
  );

  //   useEffect(() => {
  //     ref?.current?.scrollIntoView({ behavior: "instant" });
  //     document.body.scrollTop = document.documentElement.scrollTop = 0;
  //   }, []);

  const renderUIMobile = (dataList) => {
    return (
      LatestJobList?.map((item, index) => {
        return (
          <>
            <div
              key={index}
              className={mobileStyle.internalJobOpeningContainer}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 0px",
                  }}
                >
                  <p
                    className={mobileStyle.candidate}
                    style={{ margin: "0px" }}
                  >
                    {item.job_title}
                  </p>
                  <button
                    style={{
                      background: "#008080",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      padding: "5px 10px",
                    }}
                    onClick={() =>
                      navigate(`/admin/recruitment/post-job/${item.id}`, {
                        replace: true,
                      })
                    }
                  >
                    Edit
                  </button>
                </div>
                <p className={mobileStyle.jobTitle}>{item.industry}</p>
                <p className={mobileStyle.date}>
                  <img
                    src={calendar}
                    alt="calendar"
                    width={20}
                    height={20}
                  />{" "}
                  {dayjs(item.posting_date).format("DD-MM-YYYY")}
                </p>
                <p className={mobileStyle.time}>
                  <img
                    src={workMode}
                    alt="workMode"
                    width={20}
                    height={20}
                  />{" "}
                  {item?.job_function}
                </p>
                <p className={mobileStyle.location}>
                  <img
                    src={location}
                    alt="location"
                    width={20}
                    height={20}
                  />{" "}
                  {item.location.map((el, elIndex) => {
                    return (
                      <span key={elIndex}>
                        {el}
                        {item?.location?.length - 1 !== elIndex && (
                          <span> , </span>
                        )}
                      </span>
                    );
                  })}
                </p>
              </div>
            </div>
            {index !== LatestJobList.length - 1 && <hr />}
          </>
        );
      })
    )
  }

  const renderUIDesktop = (dataList) => {
    return (
      <Table
        columns={columns}
        dataSource={dataList}
        pagination={false}
      />
    );
  };

  return (
    <div className="adminJobListContainer" ref={ref}>
      <div>
        {screenSize.width < 768 ? (
          <div className={mobileStyle.internalJobOpening}>
            <div className={mobileStyle.headerContainer}>
              <p className={mobileStyle.heading}>Latest Jobs</p>
              {/* <img src={fullView} alt="fullView" /> */}
            </div>

            <InfiniteScrollComponent
              apiEndpoint="recruitment/all-jobs"
              renderUI={renderUIMobile}
              initialPage={1}
            />


          </div>
        ) : (
          // desktop view
          <div
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <h1>Latest Jobs</h1>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Input
                  prefix={<SearchOutlined />}
                  placeholder="Search"
                  style={{
                    width: "400px",
                    height: "40px",
                    borderRadius: "21px",
                  }}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div style={{ display: "flex", gap: "20px" }}>
                {/* <Select className='filterSelect'
                                        placeholder="Filter"
                                    >
                                        <Select.Option value="0">HOME</Select.Option>
                                        <Select.Option value="1">OFFICE</Select.Option>
                                        <Select.Option value="2">HYBRID</Select.Option>
                                    </Select> */}
                <Button
                  onClick={() =>
                    navigate("/admin/recruitment/post-job", { replace: true })
                  }
                  className="postJobBtn"
                >
                  Post a Job
                </Button>
              </div>
            </div>
            <br />
            <br />

            <InfiniteScrollComponent
              apiEndpoint="recruitment/all-jobs"
              renderUI={renderUIDesktop}
              initialPage={1}
            />
          </div>
        )}
        { }
      </div>
    </div>
  );
}

export default AdminJobListFullView;
