import React, { useEffect, useRef, useState } from 'react'
import styles from "./Org.module.scss"
import request from '../../utils/axios';
import DynamicTable from '../../component/TableWithPagination/TableWithPagination';
import edit from "../../assets/edit.svg";
import add from "../../assets/Org/add.svg"
import { Button, Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import AddOrEditOrg from './AddOrEditOrg/AddOrEditOrg';
import AddOrEditAdmin from './AddOrEditAdmin/AddorEditAdmin';
import useScreenSize from '../../utils/getScreenSize';
import { useNavigate } from 'react-router-dom';
import fullView from "../../assets/fullView.svg";
import { FilterTwoTone } from '@ant-design/icons';

function Org() {
    const [editOrgDetail, setEditOrgDetail] = useState(null)
    const [open, setOpen] = useState(false);

    const parentOrgCol = [
        {
            title: 'Company Name',
            dataIndex: 'org_name',
            key: 'org_name',
        },
        {
            title: 'Domain Name',
            dataIndex: 'domain_name',
            key: 'domain_name',
            render: (text, record) => <p>
                {
                    record.domain_name.map((item, index) => {
                        return <span>{item}
                            {
                                record.domain_name?.length - 1 !== index && <span> , </span>
                            }
                        </span>
                    })
                }
            </p>
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Emp Code',
            dataIndex: 'fixed_emp_code',
            key: 'fixed_emp_code',
        },
        {
            title: 'GST Details',
            dataIndex: 'gst_details',
            key: 'gst_details',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',


            render: (text, record) => (
                <Select
                    value={0}
                    onChange={(value) => {
                        onHandleChangeForOrg(value, record)
                    }}
                    style={{ width: 150 }}
                >
                    <Select.Option value={0}>Select</Select.Option>
                    <Select.Option value={1} >
                        {/* <AddOrEditOrg
                            // btn={<img src={edit} alt="" width={18} height={18} style={{ cursor: "pointer" }} />}
                            btn={<p style={{ cursor: "pointer" }}>Edit</p>}
                            type="Update"
                            detail={record}
                            setReload={setReload}
                            reload={reload}
                        /> */}
                        Edit
                    </Select.Option>
                    <Select.Option value={2} >Settings</Select.Option>
                </Select>
            ),
        }
    ]

    const childOrgCol = [
        {
            title: 'Company Name',
            dataIndex: 'org_name',
            key: 'org_name',
        },
        {
            title: 'Domain Name',
            dataIndex: 'domain_name',
            key: 'domain_name',
            render: (text, record) => <p>
                {
                    record.domain_name.map((item, index) => {
                        return <span>{item}
                            {
                                record.domain_name?.length - 1 !== index && <span> , </span>
                            }
                        </span>
                    })
                }
            </p>
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Emp Code',
            dataIndex: 'fixed_emp_code',
            key: 'fixed_emp_code',
        },
        {
            title: 'GST Details',
            dataIndex: 'gst_details',
            key: 'gst_details',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',


            render: (text, record) => (
                <Select
                    value={0}
                    onChange={(value) => {
                        onHandleChangeForOrg(value, record)
                    }}
                    style={{ width: 150 }}
                >
                    <Select.Option value={0}>Select</Select.Option>
                    <Select.Option value={1} >
                        {/* <AddOrEditOrg
                            // btn={<img src={edit} alt="" width={18} height={18} style={{ cursor: "pointer" }} />}
                            btn={<p style={{ cursor: "pointer" }}>Edit</p>}
                            type="Update"
                            detail={record}
                            setReload={setReload}
                            reload={reload}
                        /> */}
                        Edit
                    </Select.Option>
                    <Select.Option value={2} >Settings</Select.Option>
                </Select>
            ),
        }
    ]

    const onHandleChangeForOrg = async (value, record) => {
        console.log("value, id", value, record)
        if (value === 1) {
            setEditOrgDetail(record)
            setOpen(true)
        }

        // Rejected
        if (value === 2) {
            navigate(`/admin/org/config/${record?.id}`);

        }

    }

    const adminCol = [
        {
            title: 'Name',
            dataIndex: 'employee',
            key: 'employee',
        },
        {
            title: 'Access',
            dataIndex: 'role',
            key: 'role',
            render: (text, record) =>
                <span style={{ display: "flex", gap: "15px" }}>
                    {
                        record?.role?.map((item, index) => {
                            return (
                                <span key={index} style={{ padding: "5px 25px", border: "1px solid #008080", color: "#008080", borderRadius: "16px" }}>{item}</span>
                            )
                        })}
                </span>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <img src={edit} alt="" width={18} height={18} style={{ cursor: "pointer" }}
                    onClick={() => {
                        setAdminOrgDetailId(record.id);
                        setAminModal(true)
                        setType("Update")
                    }}
                />
            // <AddOrEditAdmin

            //     btn={<img src={edit} alt="" width={18} height={18} style={{ cursor: "pointer" }} />}
            //     type="Update"
            //     adminId={record.id}
            //     reload={reload}
            //     setReload={setReload}
            //     selectedOrganisation={selectedOrganisation}
            // />
        }
    ]

    const screenSize = useScreenSize();

    const [parentOrg, setParentOrg] = useState(null)
    const [childOrg, setChildOrg] = useState(null)
    const [adminList, setAdminList] = useState(null)
    const [reload, setReload] = useState(false)
    const [selectedOrganisation, setSelectedOrganisation] = useState(null);
    const navigate = useNavigate();
    const fetchParentData = async (page, page_size) => {
        const data = await request({
            method: "get",
            url: `parent-org/all?page=${page}&page_size=${page_size}`,
        })
        setParentOrg(data.pagination_data.data[0])
        return data
    }

    const fetchChildData = async (page, page_size) => {
        const parent_company_id = localStorage.getItem("parent_company_id")
        const data = await request({
            method: "get",
            url: `child-org/${parent_company_id}/all?page=${page}&page_size=${page_size}`,
        })
        return data
    }

    const fetchAdminData = async (page, page_size) => {
        const data = await request({
            method: "get",
            url: `org/admin/all?page=${page}&page_size=${page_size}${selectedOrganisation ? `&org=${selectedOrganisation}` : ""}`,
        })
        setAdminList(data.pagination_data.data)
        return data
    }

    const [organisationList, setOrganisationList] = useState([]);
    const fetchOrganisationList = async () => {
        try {
            const data = await request({
                method: "get",
                url: `org/all`,
            });
            setOrganisationList(data.data);
        } catch (error) {
            console.log(error);
        } finally {
            // setReload(false)
        }
    };

    const apiCallForMobileView = async () => {
        const parent = await fetchParentData(1, 1)
        setParentOrg(parent.pagination_data.data[0])
        const child = await fetchChildData(1, 20)
        setChildOrg(child.pagination_data.data)
        const admin = await fetchAdminData(1, 20)
        setAdminList(admin.pagination_data.data)
    }

    useEffect(() => {
        if (screenSize.width < 768) {
            apiCallForMobileView()
        }
    }, [reload])

    const ref = useRef(null);
    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        fetchOrganisationList();
    }, []);

    useEffect(() => {
        fetchAdminData(1, 5)
    }, [selectedOrganisation])

    const onFinish = async (values) => {


        await request({
            method: "put",
            url: `org/${editOrgDetail.id}/update/`,
            data: values
        })

        setReload(!reload)
        setOpen(false)
    }

    const { useForm } = Form;
    const [form] = useForm();
    const [adminModal, setAminModal] = useState(false);

    const [employeeList, setEmployeeList] = useState([])
    const [moduleList, setModuleList] = useState([])
    const [updatesMade, setUpdatesMade] = useState(false);
    const [editAdminDetailId, setAdminOrgDetailId] = useState(null);
    const [type, setType] = useState(null);
    const getEmployeeList = async () => {

        try {
            let data
            if (selectedOrganisation) {
                console.log(selectedOrganisation, "selectedOrganisation................................");
                data = await request({
                    method: "get",
                    url: `get-all-employees?org=${selectedOrganisation}`,
                })

            } else {
                data = await request({
                    method: "get",
                    url: `get-all-employees`,
                })
            }
            setEmployeeList(data.data)
        }
        catch (err) {
            console.log(err);
        }
    }

    const getModuleList = async () => {
        let data = await request({
            method: "get",
            url: `module/all`,
        })
        setModuleList(data.data)
    }

    const getAdminDetail = async () => {
        let data = await request({
            method: "get",
            url: `org/admin/${editAdminDetailId}/details`,
        })
        form.setFieldsValue(data.data.data)
    }
    const onFinishAdminForm = async (values) => {
        console.log("values", values);

        if (type === "Add") {
            await request({
                method: "post",
                url: `org/admin/create`,
                data: values
            })
        }
        else {
            await request({
                method: "put",
                url: `org/admin/${editAdminDetailId}/update/`,
                data: values
            })

        }
        setReload(!reload)
        setAminModal(false)
        fetchAdminData(1, 5)
        setAdminOrgDetailId(null)
        form.resetFields();
    }

    useEffect(() => {
        getEmployeeList()
        getModuleList()

    }, [])

    useEffect(() => {
        if (type === "Update" && editAdminDetailId) {
            getAdminDetail()
        }
    }, [editAdminDetailId])

    useEffect(() => {
        console.log(employeeList, "employeeList");
    }, [employeeList])

    useEffect(() => {
        getEmployeeList()
    }, [selectedOrganisation])
    const handleFormChange = () => {
        setUpdatesMade(true)
    }

    return (
        <div ref={ref}>

            {
                screenSize.width < 768
                    ?
                    <div >
                        <div className={styles.card}>
                            <div className='flexSpaceBetween'>
                                <p className={styles.cardHeading}>Parent Organisation</p>
                                {/* <img src={edit} alt="" width={18} height={18} style={{ cursor: "pointer" }}
                                    onClick={() => navigate(`/admin/org/parent/edit/${parentOrg?.id}`)}
                                /> */}
                            </div>
                            <div className={styles.cardTextBox}>
                                <div className={styles.detailBox}>
                                    <p className={styles.companyName}>{parentOrg?.org_name}</p>
                                    <Select
                                        size="medium"
                                        placeholder="Select"
                                        onChange={(value) => {
                                            if (value === "edit") {
                                                navigate(`/admin/org/parent/edit/${parentOrg?.id}`, { replace: true })
                                            }
                                            else {
                                                navigate(`/admin/org/config/${parentOrg?.id}`, { replace: true });
                                            }
                                        }}
                                    >
                                        <Select.Option value={"edit"} >
                                            Edit
                                        </Select.Option>
                                        <Select.Option value={"setting"}>
                                            Setting
                                        </Select.Option>
                                    </Select>
                                </div>
                                <div className={styles.detailBox}>
                                    <p className={styles.key}>Domain Name</p>
                                    <p className={styles.otherDetails}>{parentOrg?.domain_name}</p>
                                </div>
                                <div className={styles.detailBox}>
                                    <p className={styles.key}>Address</p>
                                    <p className={styles.otherDetails}>{parentOrg?.address}</p>
                                </div>
                                <div className={styles.detailBox}>
                                    <p className={styles.key}>GST Details</p>
                                    <p className={styles.otherDetails}>{parentOrg?.gst_details}</p>
                                </div>
                                <div className={styles.detailBox}>
                                    <p className={styles.key}>Employee Code</p>
                                    <p className={styles.otherDetails}>{parentOrg?.fixed_emp_code}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "8px", background: "#F7F8FC" }}></div>

                        <div className={styles.card}>
                            <div className='flexSpaceBetween '>
                                <p className={styles.cardHeading}>Child Organisation</p>
                                <img src={add} alt="" width={32} height={32} style={{ cursor: "pointer" }}
                                    onClick={() => navigate(`/admin/org/child/add`, { replace: true })}
                                />
                            </div>
                            <br />
                            {
                                childOrg?.map((item, index) => (
                                    <div className='cardTextBox'>
                                        <div className='flexSpaceBetween'>
                                            <p className={styles.companyName}>{item?.org_name}</p>

                                            <Select
                                                size="medium"
                                                placeholder="Select"
                                                onChange={(value) => {
                                                    if (value === "edit") {
                                                        navigate(`/admin/org/child/edit/${item?.id}`, { replace: true })
                                                    }
                                                    else {
                                                        navigate(`/admin/org/config/${item?.id}`, { replace: true });
                                                    }
                                                }}
                                            >
                                                <Select.Option value={"edit"} >
                                                    Edit
                                                </Select.Option>
                                                <Select.Option value={"setting"}>
                                                    Setting
                                                </Select.Option>
                                            </Select>
                                        </div>
                                        <div className={styles.detailBox}>
                                            <p className={styles.key}>Domain Name</p>
                                            <p className={styles.otherDetails}>{parentOrg?.domain_name}</p>
                                        </div>
                                        <div className={styles.detailBox}>
                                            <p className={styles.key}>Address</p>
                                            <p className={styles.otherDetails}>{parentOrg?.address}</p>
                                        </div>
                                        <div className={styles.detailBox}>
                                            <p className={styles.key}>GST Details</p>
                                            <p className={styles.otherDetails}>{parentOrg?.gst_details}</p>
                                        </div>
                                        <div className={styles.detailBox}>
                                            <p className={styles.key}>Employee Code</p>
                                            <p className={styles.otherDetails}>{parentOrg?.fixed_emp_code}</p>
                                        </div>
                                        <hr />
                                    </div>
                                ))
                            }

                        </div>
                        <div style={{ height: "8px", background: "#F7F8FC" }}></div>

                        <div className={styles.card} style={{ marginBottom: "40px" }}>
                            <div className='flexSpaceBetween '>
                                <p className={styles.cardHeading}>Admin</p>
                                <div>
                                    <img src={add} alt="" style={{ cursor: "pointer" }} width={32} height={32}
                                        onClick={() => navigate("/admin/org/admin/add", { replace: true })}
                                    />
                                    <img src={fullView} alt="fullView" width={35} height={35} style={{ marginLeft: "20px" }}
                                        onClick={() => {
                                            navigate("/admin/org/admins/full-view", { replace: true })
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ margin: "20px 0px" }}>
                                <Select
                                    size="large"
                                    placeholder="Select Organisation"
                                    style={{ width: "100%" }}
                                    value={selectedOrganisation}
                                    onChange={(value) => {
                                        setSelectedOrganisation(value);
                                    }}
                                >
                                    {organisationList?.map((item, index) => {
                                        return (
                                            <Select.Option key={index} value={item.id}>
                                                {item.org_name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </div>
                            {
                                adminList?.map((item, index) => (
                                    <div className='cardTextBox'>
                                        <div className='flexSpaceBetween'>
                                            <p className={styles.otherDetails}>{item?.employee}</p>
                                            <img src={edit} alt="" width={18} height={18} style={{ cursor: "pointer" }}
                                                onClick={() => navigate("/admin/org/admin/edit/" + item?.id, { replace: true })}
                                            />
                                        </div>
                                        <div style={{ display: "flex", flexWrap: "wrap", overflow: "auto" }}>
                                            {
                                                item?.role?.map((el, index) => {
                                                    return (
                                                        <span key={index} style={{ fontSize: "12px", padding: "5px 25px", border: "1px solid #008080", color: "#008080", borderRadius: "16px", marginRight: "10px", marginBlock: "5px" }}>{el}</span>
                                                    )
                                                })}
                                        </div>
                                        <hr />
                                    </div>
                                ))
                            }

                        </div>
                        <div style={{ height: "8px", background: "#F7F8FC" }}></div>
                    </div>
                    :
                    <div className={styles.orgContainer}>
                        {/* <h1 className='heading'>Organisation</h1> */}
                        <div className={styles.orgCardContainer}>
                            <div>
                                <div className='flexSpaceBetween'>
                                    <h2 className=''>Parent Organisation</h2>
                                    {/* <AddOrEditOrg
                                        btn={<img src={edit} alt="" width={18} height={18} style={{ cursor: "pointer" }} />}
                                        type="Update"
                                        detail={parentOrg}
                                        reload={reload}
                                        setReload={setReload}
                                    /> */}
                                </div>
                                <DynamicTable
                                    fetchData={fetchParentData}
                                    columns={parentOrgCol}
                                    handleRowClick={() => { }}
                                    pageSize={1}
                                    showPagination={false}
                                    reload={reload}
                                />
                            </div>
                            <div>
                                <div style={{ display: "flex", alignItems: "center", gap: "16px", marginTop: "5vh" }}>
                                    <h2>Child Organisation</h2>
                                    <AddOrEditOrg
                                        btn={<img src={add} alt="" style={{ cursor: "pointer" }} />}
                                        type="Add"
                                        detail={null}
                                        reload={reload}
                                        setReload={setReload}
                                    />
                                </div>

                                <DynamicTable
                                    fetchData={fetchChildData}
                                    columns={childOrgCol}
                                    handleRowClick={() => { }}
                                    pageSize={20}
                                    showPagination={false}
                                    reload={reload}
                                />
                            </div>
                        </div>

                        <div className={`${styles.orgCardContainer} ${styles.admin}`} >
                            <div className='flexSpaceBetween'>
                                <h2>Admin</h2>
                                <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                    <Select
                                        size="large"
                                        placeholder="Select Organisation"
                                        style={{ width: "250px" }}
                                        value={selectedOrganisation}
                                        onChange={(value) => {
                                            setSelectedOrganisation(value);
                                        }}
                                    >
                                        {organisationList?.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.id}>
                                                    {item.org_name}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                    {/* <AddOrEditAdmin
                                        btn={<Button type="primary" style={{ background: "#008080", height: "40px" }}>Add</Button>}
                                        type="Add"
                                        reload={reload}
                                        setReload={setReload}
                                    /> */}
                                    <Button type="primary" style={{ background: "#008080", height: "40px" }}
                                        onClick={() => {
                                            // setAdminOrgDetailId(record.id);
                                            setAminModal(true)
                                            setType("Add")
                                        }}
                                    >Add</Button>
                                    <img src={fullView} alt="fullView" width={35} height={35} style={{ marginLeft: "20px" }}
                                        onClick={() => {
                                            navigate("/admin/org/admins/full-view", { replace: true })
                                        }}
                                    />

                                </div>


                            </div>

                            {/* <DynamicTable
                                fetchData={fetchAdminData}
                                columns={adminCol}
                                handleRowClick={() => { }}
                                pageSize={5}
                                showPagination={false}
                                reload={reload}
                            /> */}
                            <Table
                                columns={adminCol}
                                dataSource={adminList}
                                pagination={false}
                            />
                        </div>
                    </div>
            }

            <Modal
                centered
                open={open}
                footer={null}
                width={1000}
                onCancel={() => setOpen(false)}
            >
                <div style={{ padding: "50px 0px 50px 0px" }}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        fields={[
                            {
                                name: ["org_name"],
                                value: editOrgDetail?.org_name
                            },
                            {
                                name: ["domain_name"],
                                value: editOrgDetail?.domain_name
                            },
                            {
                                name: ["address"],
                                value: editOrgDetail?.address
                            },
                            {
                                name: ["gst_details"],
                                value: editOrgDetail?.gst_details
                            },
                            {
                                name: ["fixed_emp_code"],
                                value: editOrgDetail?.fixed_emp_code
                            },
                            {
                                name: ["variable_emp_code"],
                                value: editOrgDetail?.variable_emp_code
                            }
                        ]}
                    >
                        <Row gutter={[32, 32]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Company Name"
                                    name="org_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Company Name!',
                                        },
                                    ]}
                                >
                                    <Input
                                        // className='inputField'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Domain Name"
                                    name="domain_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Domain Name!',
                                        },
                                    ]}
                                >
                                    <Input
                                        // className='inputField'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[32, 32]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Address"
                                    name="address"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Address!',
                                        }
                                    ]}
                                >
                                    <Input
                                        // className='inputField'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="GST Details"
                                    name="gst_details"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter GST Details!',
                                        }
                                    ]}
                                >
                                    <Input
                                        // className='inputField'
                                        size='large'
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                        <p style={{ fontWeight: "600" }}>Employee Code Sequence</p>
                        <Row gutter={[32, 32]}>

                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Form.Item
                                        label="Fixed"
                                        name="fixed_emp_code"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Fixed!',
                                            }
                                        ]}
                                    >
                                        <Input
                                            // className='inputField'
                                            size='large'
                                            style={{ width: "90%" }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Variable"
                                        name="variable_emp_code"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Variable!',
                                            }
                                        ]}
                                    >
                                        <Input
                                            type="number" // Set the type attribute to "number"
                                            // className='inputField'
                                            size='large'
                                            placeholder='Numbers Only'
                                            style={{ width: "100%" }}
                                            disabled={true}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>


                        </Row>
                        <Button
                            className='button'
                            htmlType='submit'
                        >
                            {/* {type} */}
                            Update
                        </Button>
                    </Form>
                </div>
            </Modal>

            <Modal
                centered
                open={adminModal}
                footer={null}
                width={1000}
                onCancel={() => setAminModal(false)}
            >
                <div className='addOrEditAdmin'>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onFinishAdminForm}
                        onValuesChange={handleFormChange}
                    >
                        <Row gutter={[32, 32]}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Employee Name"
                                    name="employee"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your employee name!',
                                        },
                                    ]}
                                >
                                    {
                                        type === "Update"
                                            ?
                                            <Input disabled className='inputField' />
                                            :
                                            <Select
                                                className='dropDown'
                                                disabled={type === "Update"}
                                            >
                                                {
                                                    employeeList.map((item, index) => {
                                                        return (
                                                            <Select.Option key={index} value={item.employement_number}>{item.employee_name}</Select.Option>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Select>
                                    }

                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Module"
                                    name="role"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your role!',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        className='multiSelectDropDown'
                                        showSearch={false}
                                        size='large'
                                    >
                                        {
                                            moduleList.map((item, index) => {
                                                return (
                                                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                                )
                                            }
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Button
                            className='button'
                            htmlType='submit'
                            disabled={type === "Update" && !updatesMade}
                        >
                            {type}
                        </Button>

                    </Form>
                </div>

            </Modal>
        </div>
    )
}

export default Org