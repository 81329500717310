import { Button, Col, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import request from '../../../../utils/axios'
import { useNavigate, useParams } from 'react-router-dom';

import DynamicTable from '../../../../component/TableWithPagination/TableWithPagination';
import moment from 'moment';
import useScreenSize from '../../../../utils/getScreenSize';
import { SearchOutlined } from '@ant-design/icons';

import calendar from "../../../../assets/calendar.svg";
import location from "../../../../assets/location.svg";

import "./UserJobList.scss"
import CardListWithInfiniteScroll from '../../../../component/InfiniteScroll/InfiniteScroll';
import ApplyReferCardList from '../../../../component/ApplyReferCardList/ApplyReferCardList';
import ExternalUserApplyJob from '../ExternalUserApplyJob/ExternalUserApplyJob';

import ExternalRecruitmentBgImage from "../../../../assets/Recruitment/External Recruitment/external-recruitment2.png"
import RecruitmentHeader from '../../RecruitmentHeader/RecruitmentHeader';
import InfiniteScrollComponent from '../../../../component/InfiniteScroll/InfiniteScroll';
import ApplyJob from '../../../../container/Recruitment/User/ApplyJob/ApplyJob';

function UserJobList() {

    const [jobList, setJobList] = useState(null);
    const navigate = useNavigate();
    const screenSize = useScreenSize();
    const params = useParams();

    const columns = [
        {
            title: 'Date',
            dataIndex: 'posting_date',
            key: 'posting_date',
            render: (text, record) => <span>
                {
                    moment(text).format('DD-MM-YYYY')
                }
            </span>
        },
        {
            title: 'Job Title',
            dataIndex: 'job_title',
            key: 'job_title',
        },
        {
            title: 'Posted By',
            dataIndex: 'posted_by',
            key: 'posted_by',
        },
        {
            title: 'Industry',
            dataIndex: 'industry',
            key: 'industry',
        },
        {
            title: 'Function',
            dataIndex: 'job_function',
            key: 'job_function',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (text, record) =>
                <div>
                    {
                        record.location.map((item, index) => {
                            return (
                                <span key={index}>
                                    <span>{item}</span>
                                    {
                                        record?.location?.length - 1 !== index && <span> , </span>
                                    }
                                </span>

                            )
                        })
                    }
                </div>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <div>
                    <ExternalUserApplyJob jobId={record.id} btnContent="Apply" companyName={params.company_name} />
                </div>
        }
    ]

    const fetchRowData = async (page, page_size) => {

        const data = await request({
            method: "get",
            url: `recruitment/external/job/list?name=${params.company_name}&page=${page}&page_size=${page_size}`,
        })
        return data
    }

    const fetctData = async () => {
        const data = await request({
            method: "get",
            url: `recruitment/external/job/list?page=1&page_size=10`,
        })
        setJobList(data.pagination_data.data)
    }

    const [search, setSearch] = useState("");
    useEffect(() => {

        let timer = setTimeout(() => {
            console.log("seaching....", search);
            // fetchData();
        }, 2000)

        return () => clearTimeout(timer)
    }, [search])

    const renderUI = (posts) => (
        <>
            {
                posts.map((item, index) => (
                    <div key={index} onClick={() => navigate(`/${params.company_name}/careers/jobs/job-detail/${item?.id}`, { replace: true })} >
                        <div style={{ padding: "0px 16px" }}  >
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                <span className='title'>{item?.job_title}</span >{ }
                                {
                                    item?.id && <ApplyJob title={"Apply"} jobId={item?.id} btnContent={"Apply"} loginUserData={"loginUserData"} />
                                }
                            </div>
                            <span style={{ color: "#8A8A8A", fontSize: "16px" }} >{item?.industry}</span >
                            <div>
                                <div>
                                    <span style={{ display: "flex", alignItems: "center", gap: "5px", marginTop: "10px" }}>
                                        <img src={calendar} alt="" style={{ height: "14px" }} />
                                        <span style={{ color: "#616161", fontSize: "12px" }}>{moment(item?.posting_date).format('DD-MM-YYYY')}</span>
                                    </span>
                                    <span style={{ display: "flex", alignItems: "center", marginTop: "10px", gap: "5px", }}>
                                        <img src={location} alt="" /> {" "}
                                        <span className='location'>
                                            {
                                                item?.location.map((el, index) => {
                                                    return (
                                                        <span key={index}>
                                                            <span style={{ color: "#616161", fontSize: "12px" }}>{el}</span>
                                                            {
                                                                item?.location?.length - 1 !== index && <span> , </span>
                                                            }
                                                        </span>

                                                    )
                                                })
                                            }
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                ))
            }
        </>
    )

    return (
        <div>
            {
                screenSize.width < 768 ?
                    <div className='userJobListContainer'>
                        <RecruitmentHeader />

                        <div
                            style={{
                                backgroundImage: `url(${ExternalRecruitmentBgImage})`,
                                width: "100%",
                                height: "160px",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain"
                            }}
                        >

                        </div>
                        <center><p style={{ fontSize: "24px", fontWeight: "500" }}>Latest Jobs</p></center>
                        <InfiniteScrollComponent
                            apiEndpoint={`recruitment/external/job/list?name=${params.company_name}&`}
                            page_size={10}
                            renderUI={renderUI}
                        />

                    </div>
                    :
                    <>
                        <RecruitmentHeader />
                        <div
                            style={{
                                backgroundImage: `url(${ExternalRecruitmentBgImage})`,
                                width: "100%",
                                height: "350px",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >

                        </div>
                        <div className='userJobListContainer'>
                            <center>
                                <h1>Latest Jobs</h1>
                            </center>
                            <br />
                            <div style={{ padding: "0px 80px", }}>
                                <DynamicTable
                                    fetchData={fetchRowData}
                                    columns={columns}
                                    handleRowClick={(record) => {
                                        console.log("record", record);
                                        navigate(`/${params.company_name}/careers/jobs/job-detail/${record.id}`)
                                    }}
                                    showPagination={true}
                                    pageSize={5}
                                />
                            </div>
                        </div>
                    </>
            }

        </div>




    )
}

export default UserJobList
