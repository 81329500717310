import { Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import styles from "./HRAForm.module.scss"
import { LinkOutlined } from '@ant-design/icons';
import request from '../../../../../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import { setHRADetails } from '../../../../../../redux/reducer/TaxDeclarationReducer';

function HRAForm({ monthName, windowOpen }) {
    const monthDetails = useSelector(state => state.taxDeclaration.HRA[monthName])

    const monthsName = [
        {
            value: 1,
            label: "January"
        },
        {
            value: 2,
            label: "February"
        },
        {
            value: 3,
            label: "March"
        },
        {
            value: 4,
            label: "April"
        },
        {
            value: 5,
            label: "May"
        },
        {
            value: 6,
            label: "June"
        },
        {
            value: 7,
            label: "July"
        },
        {
            value: 8,
            label: "August"
        },
        {
            value: 9,
            label: "September"
        },
        {
            value: 10,
            label: "October"
        },
        {
            value: 11,
            label: "November"
        },
        {
            value: 12,
            label: "December"
        }
    ]

    const dispatch = useDispatch();

    const [HRAData, setHRAData] = useState({
        address: "",
        metro_city: null,
        declared_amount: null,
        owner_name: "",
        owner_pan_no: "",
        attachment: "",
        file_name: ""
    })

    // console.log("monthName", monthName);

    const handleImageUpload = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setHRAData({
                ...HRAData,
                file: reader.result,
                file_name: file.name
            })
            dispatch(setHRADetails({ ...HRAData, file: reader.result, monthName, file_name: file.name }));
        };
        reader.onerror = (error) => {
            console.error('Error uploading image: ', error);
        };
    };

    const onHandleChange = (key, value) => {

        setHRAData({
            ...HRAData,
            [key]: value
        });
        dispatch(setHRADetails({ ...HRAData, [key]: value, monthName }));
    }

    useEffect(() => {
        setHRAData({
            ...HRAData,
            ...monthDetails
        })
    }, [monthDetails])


    return (
        <div className={styles.HRA}>
            <Form
                layout="vertical"
                fields={[
                    {
                        name: ["address"],
                        value: HRAData?.address,
                    },
                    {
                        name: ["metro_city"],
                        value: HRAData?.metro_city,
                    }
                ]}
            >
                <div className={styles.formBox}>
                    <p className={styles.monthName}>{monthName}</p>
                    <Row gutter={32}>
                        <Col span={8}>
                            <Form.Item
                                label="Address"
                                name="address"
                            >
                                <Input
                                    size='large'
                                    placeholder='Enter Address'
                                    onChange={(e) => onHandleChange("address", e.target.value)}
                                    disabled={windowOpen}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>

                            <Form.Item
                                label="In metro"
                                name="metro_city"
                            >
                                <Select size='large' placeholder='Select'
                                    onChange={(e) => onHandleChange("metro_city", e)}
                                    value={HRAData.metro_city}
                                    disabled={windowOpen}
                                >
                                    <Select.Option value={0}>Metro</Select.Option>
                                    <Select.Option value={1}>Non-Metro</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col span={4}>
                            <Form.Item
                                label="Month"
                            // name="form"
                            >
                                <Select size='large' placeholder='Select'
                                    onChange={(e) => onHandleChange("month", e)}
                                    value={HRAData.month}
                                >
                                    {
                                        monthsName.map((item, index) => {
                                            return (
                                                <Select.Option value={item.value}>{item.label}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col span={4}>
                            <Form.Item
                                label="Monthly Rent"
                            // name="declared_amount"
                            >
                                <Input size='large' placeholder='Enter Monthly Rent'
                                    onChange={(e) => onHandleChange("declared_amount", e.target.value)}
                                    value={HRAData.declared_amount}
                                    disabled={windowOpen}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="Owner Name"
                            // name="owner_name"
                            >
                                <Input size='large' placeholder='Enter Owner Name'
                                    onChange={(e) => onHandleChange("owner_name", e.target.value)}
                                    value={HRAData.owner_name}
                                    disabled={windowOpen}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Owner PAN"
                            // name="owner_pan_no"
                            >
                                <Input size='large' placeholder='Enter Owner PAN'
                                    onChange={(e) => onHandleChange("owner_pan_no", e.target.value)}
                                    value={HRAData.owner_pan_no}
                                    disabled={windowOpen}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                            // label="Attach File"
                            // name="file"
                            >
                                <div style={{ display: "flex", alignItems: "center" , gap: "10px" }}>
                                    <div className={styles.attachFileBox}>
                                        <input
                                            type="file"
                                            id="file-upload"
                                            className={styles.inputFile}
                                            onChange={(e) =>
                                            // setFile(e.target.files && e.target.files[0])
                                            {
                                                console.log("e.target.files[0]", e.target.files[0])

                                                const selectedFile = e.target.files[0];
                                                // const maxSizeInBytes = 5 * 1024 * 1024;

                                                if (selectedFile) {

                                                    handleImageUpload(selectedFile)
                                                    // setFile(e.target.files && selectedFile)

                                                }
                                            }
                                            }
                                            disabled={windowOpen}
                                        />
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>

                                            <label htmlFor="file-upload" >

                                            </label>
                                            {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                            <p className={styles.attachFileLabel} style={{ marginTop: "7px" }}>
                                                {/* {HRAData[index]?.file ? HRAData[index]?.file.name : */}
                                                {/* <> */}
                                                <LinkOutlined /> {" "}Attach File
                                                {/* </> */}
                                                {/* } */}
                                            </p>
                                        </div>

                                    </div>
                                    <p style={{ margin: '0', marginTop: "30px" }}>{HRAData?.file_name}</p>
                                </div>

                            </Form.Item>
                        </Col>
                        <Col span={3}>

                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    )
}

export default HRAForm