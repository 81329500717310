import { Radio } from 'antd'
import React, { useState } from 'react'
import Selective from './Selective/Selective';
import EveryOne from './EveryOne/EveryOne';

function BulkAttendancePolicy() {
    const [bulkUploadType, setBulkUploadType] = useState("selective");
    const [selectiveDataList, setSelectiveDataList] = useState([]);
    // const [selectedSelectiveRowKeys, setSelectedSelectiveRowKeys] = useState([]);
    // const [selectedEveryOneRowKeys, setSelectedEveryOneRowKeys] = useState([]);
    // const rowSelection = {
    //     selectedSelectiveRowKeys,
    //     onChange: (selectedRowKeys, selectedRows) => {
    //         console.log("selectedRowKeys, selectedRows...", selectedRowKeys, selectedRows);
    //         setSelectedSelectiveRowKeys(selectedRowKeys)
    //     }
    // };

    // const rowSelectionEveryOne = {
    //     selectedEveryOneRowKeys,
    //     onChange: (selectedRowKeys, selectedRows) => {
    //         setSelectedEveryOneRowKeys(selectedRowKeys)
    //     }
    // }

    // const [changeByDropdownList, setChangeByDropdownList] = useState([]);
    // const [selectedChangeBy, setSelectedChangeBy] = useState('');

    return (
        <div style={{ padding: "50px" }}>
            <div style={{ background: "white", padding: "30px", borderRadius: "10px" }}>
                <div>
                    <p style={{ color: "#161616", fontSize: "24px", fontWeight: "600" }}>Select Employees for Attendance Policy Change</p>

                    <div >
                        <Radio.Group
                            size='large'
                            value={bulkUploadType}
                            onChange={(e) => {
                                // setSelectedSelectiveRowKeys([]);
                                setSelectiveDataList([]);
                                // setTimeout(() => {
                                setBulkUploadType(e.target.value)
                                // }, 500)
                            }}
                        >
                            <Radio.Button value="selective">Selective</Radio.Button>
                            <Radio.Button value="everyone">Everyone</Radio.Button>
                        </Radio.Group>

                    </div>
                </div>
                <div>
                    {
                        bulkUploadType === "selective"
                            ?
                            
                            <Selective />
                            :
                            <EveryOne />
                    }
                </div>
            </div>
        </div>
    )
}

export default BulkAttendancePolicy