import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import request from '../../../../../utils/axios';
import getScreenSize from "../../../../../utils/getScreenSize";
import mobileStyle from "./MobileEmployeeDeclarationFullView.module.scss"

function EmployeeDeclarationFullView() {
    const employeeDeclarationSummaryColumn = [
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',

        },
        {
            title: 'Date of Joining',
            dataIndex: 'joining_date',
            key: 'joining_date',
        },
        {
            title: 'Claim Amount',
            dataIndex: 'declared_amount',
            key: 'declared_amount',
        },
        {
            title: 'Approved Amount',
            dataIndex: 'approved_amount',
            key: 'approved_amount',
        },
        {
            title: 'View Documents',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <button style={{
                        color: "#8A8A8A",
                        background: "#F2F2F2",
                        border: "none",
                        borderRadius: "3px",
                        fontSize: "16px",
                        height: "35px",
                        padding: "0 15px",
                    }}
                        onClick={() => navigate(`/admin/payroll/employee-declaration/${record.employee_number}`, { replace: true })}
                    >Review Claim</button>
                </>,
            width: 300,
        },
    ]

    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const [allEmployeeDeclarationSummary, setAllEmployeeDeclarationSummary] = useState()

    const fetchAllemployeeDeclarationSummary = async () => {
        try {
            let data = await request({
                method: "get",
                url: `regim_tax_rules/admin/employee-declaration-summary`,
            })
            console.log(data)
            setAllEmployeeDeclarationSummary(data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchAllemployeeDeclarationSummary()
    }, [])
    return (
        <div>
            {
                screenSize.width < 768 ?
                    <div className={mobileStyle.employeeSalaryContainer}>
                        <div className={mobileStyle.headerContainer}>
                            <p className={mobileStyle.heading}>Employee Salary Manager</p>
                        </div>
                        <div>
                            {
                                allEmployeeDeclarationSummary?.map((item, index) => {
                                    return (
                                        <div>
                                            <div className={mobileStyle.card}>
                                                <div className={mobileStyle.header}>
                                                    <p className={mobileStyle.name}>{item.employee_name}</p>
                                                    <button
                                                        onClick={() => navigate(`/admin/payroll/employee-declaration/${item.employee_number}`, { replace: true })}
                                                        style={{
                                                            background: "#F2F2F2",
                                                            border: "none",
                                                            color: "#8A8A8A",
                                                            padding: "5px 12px",
                                                            fontSize: "16px",
                                                            borderRadius: "4px"
                                                        }}
                                                    >
                                                        Revise Salary
                                                    </button>
                                                </div>
                                                <div>
                                                    <p className={mobileStyle.title}>Date of Joining</p>
                                                    <p className={mobileStyle.value}>{item.joining_date}</p>
                                                </div>
                                                <div>
                                                    <p className={mobileStyle.title}>Claim Amount</p>
                                                    <p className={mobileStyle.value}>{item.declared_amount}</p>
                                                </div>
                                                <div>
                                                    <p className={mobileStyle.title}>Approved Amount</p>
                                                    <p className={mobileStyle.value}>₹{item.approved_amount}</p>
                                                </div>
                                            </div>
                                            {
                                                index < allEmployeeDeclarationSummary.length - 1
                                                &&
                                                <hr className={mobileStyle.line} />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    :
                    <div style={{ margin: "50px", background: "white", padding: "20px", borderRadius: "4px" }}>
                        <div className='flexSpaceBetween'>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Employee Declaration Summary</p>
                            <div style={{ display: "flex", gap: "20px" }}>
                            </div>
                        </div>
                        <Table
                            columns={employeeDeclarationSummaryColumn}
                            dataSource={allEmployeeDeclarationSummary}
                            pagination={false}
                        />
                    </div>
            }

        </div>
    )
}

export default EmployeeDeclarationFullView