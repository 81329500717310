import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import request from '../../../../utils/axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import update from "immutability-helper";

function LeavePolicyForm() {
    const [leavePolicyForm, setLeavePolicyForm] = useState({
        name: "",
        start_time: "",
        end_time: "",
        leave_type_details: [],
    });
    const [leavePolicyId, setLeavePolicyId] = useState(null);
    const [editLeaveTypeId, setEditLeaveTypeId] = useState(null);
    const [isClone, setIsClone] = useState(false)
    const [openLeavePolicy, setOpenLeavePolicy] = useState(false);
    const navigate = useNavigate();
    const [leavTypeForm, setLeaveTypeForm] = useState({
        name: "",
        description: "",
        is_predefined: false,
        color: "",
        image: null,
    });
    const [leavePolicyDataList, setLeavePolicyDataList] = useState([]);
    const [holidayLeavePolicyList, setHolidayLeavePolicyList] = useState([]);
    const params = useParams();
    const fetchLeaveType = async () => {
        try {
            const data = await request({
                method: "get",
                url: "leave/leave_types/all/",
            });

            setLeavePolicyForm({
                ...leavePolicyForm,
                leave_type_details: data.map((item) => ({
                    ...item,
                    leaves_numbers: null,
                })),
            });
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSpecficLeavePolicy = async () => {
        try {
            let leaveTypeData = await request({
                method: "get",
                url: "leave/leave_types/all/",
            });

            const leaveTypeList = leaveTypeData.map((item) => ({
                ...item,
                leaves_numbers: null,
            }));

            const data = await request({
                method: "get",
                url: `leave/leave_policy/details/?leave_id=${params.id}`,
            });

            const start_date = new Date(data.data.start_time).toISOString();
            const end_date = new Date(data.data.end_time).toISOString();

            const mergedArray = leaveTypeList.map((item1) => {
                const item2 = data.data.leave_type_details.find((i) => {
                    return i.name === item1.name;
                });

                return { ...item1, ...item2 };
            });

            setLeavePolicyForm({
                ...leavePolicyForm,
                name: data.data.name,
                start_time: dayjs(start_date),
                end_time: dayjs(end_date),
                leave_type_details: mergedArray,
            });
        } catch (err) {
            console.log(err);
        }
    };

    // const fetchSpecficLeaveType = async () => {
    //     try {
    //         const data = await request({
    //             method: "get",
    //             url: `leave/leave_type/detail/?id=${editLeaveTypeId}`,
    //         });

    //         setLeaveTypeForm({
    //             name: data.name,
    //             description: data.description,
    //             is_predefined: data.is_predefined,
    //             color: data.color,
    //             image: "",
    //         });
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    const onFinishPolicyForm = async (values) => {
        // console.log('Success:', leavePolicyForm);

        try {
            const start_time = dayjs(leavePolicyForm.start_time).format("YYYY-MM-DD");
            const end_time = dayjs(leavePolicyForm.end_time).format("YYYY-MM-DD");
            // values = { ...values, dob }

            if (leavePolicyId) {
                if (isClone) {
                    await request({
                        method: "post",
                        url: `leave/admin/leave-policy/clone`,
                        data: {
                            ...leavePolicyForm,
                            start_time: start_time,
                            end_time: end_time
                        }
                    })
                }
                else {
                    await request({
                        method: "put",
                        url: `leave/leave_policy/update/?leave_id=${leavePolicyId}`,
                        data: {
                            ...leavePolicyForm,
                            start_time: start_time,
                            end_time: end_time,
                        },
                    });

                    toast.success("Leave Policy updated", {
                        position: "bottom-left",
                    });
                }

            } else {
                await request({
                    method: "post",
                    url: "leave/leave_policy/create/",
                    data: {
                        ...leavePolicyForm,
                        start_time: start_time,
                        end_time: end_time,
                    },
                });
                toast.success("New Leave Policy added", {
                    position: "bottom-left",
                });
            }
            setLeavePolicyId(null);
            setOpenLeavePolicy(false);
            setTimeout(() => {
                fetchAllLeavePolicy();
            }, 1000);
            // fetchAllLeavePolicy()
            navigate("/admin/leave/leave-policy", { replace: true });
        } catch (err) {
            console.log(err);
            if (leavePolicyId) {
                toast.error(
                    "Leave Type could not be updated, please try after some time",
                    {
                        position: "bottom-left",
                    }
                );
            } else {
                toast.error(
                    "New Leave Policy could not be added, please try after some time",
                    {
                        position: "bottom-left",
                    }
                );
            }
        }
    };

    const fetchAllLeavePolicy = async () => {
        try {
            const data = await request({
                method: "get",
                url: "leave/leave_policy/all/?page=1&page_size=2",
            });

            setLeavePolicyDataList(data.pagination_data.data);
            const holidayLeavePolicyList = data.pagination_data.data.map(
                (item, index) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                }
            );

            setHolidayLeavePolicyList(holidayLeavePolicyList);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        // if (editLeaveTypeId) {
        //     fetchSpecficLeaveType();
        // }
        fetchLeaveType();
        if (params.id) {
            fetchSpecficLeavePolicy();
        }
    }, [params.id]);

    const ref = useRef(null);
    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div style={{ padding: "0px 16px", marginTop: "80px" }} ref={ref}>
            <p style={{ fontSize: "24px", fontWeight: "500" }}>Leave Policy</p>
            <Form
                layout="vertical"
                onFinish={onFinishPolicyForm}
                fields={[
                    {
                        name: ["name"],
                        value: leavePolicyForm.name,
                    },
                    {
                        name: ["start_time"],
                        value: leavePolicyForm.start_time,
                    },
                    {
                        name: ["end_time"],
                        value: leavePolicyForm.end_time,
                    },
                ]}
            >
                <Row gutter={[32, 12]}>
                    <Col xs={{ span: 24 }}>
                        <Form.Item
                            label="Policy Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your Policy Name!",
                                },
                            ]}
                        >
                            <Input
                                className="leavePolicyInputField"
                                // placeholder='Enter Policy Name'
                                onChange={(e) =>
                                    setLeavePolicyForm({
                                        ...leavePolicyForm,
                                        name: e.target.value,
                                    })
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="Date (From)"
                            name="start_time"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your Date (From)!",
                                },
                            ]}
                        >
                            <DatePicker
                                className="leavePolicyInputField"
                                onChange={(e) =>
                                    setLeavePolicyForm({ ...leavePolicyForm, start_time: e })
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            label="Date (To)"
                            name="end_time"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your Date (To)!",
                                },
                            ]}
                        >
                            <DatePicker
                                className="leavePolicyInputField"
                                onChange={(e) =>
                                    setLeavePolicyForm({ ...leavePolicyForm, end_time: e })
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <p className="noOfLeaveDaysHeadingModel">No. of leave Days</p>
                        {leavePolicyForm?.leave_type_details?.map((item, index) => (
                            <div key={index}>
                                <Row>
                                    <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                                        <p className="leaveNameModel">{item.name}</p>
                                    </Col>
                                    <Col xs={{ span: 12 }} lg={{ span: 19 }}>
                                        <Form.Item style={{ margin: "0px" }}>
                                            <Select
                                                size="default"
                                                className="selectNoOfLeave"
                                                onChange={(value) => {
                                                    if (
                                                        leavePolicyForm.leave_type_details[index]
                                                            .leaves_numbers === null
                                                    ) {
                                                        setLeavePolicyForm(
                                                            update(leavePolicyForm, {
                                                                leave_type_details: {
                                                                    [index]: {
                                                                        id: {
                                                                            $set: "",
                                                                        },
                                                                        leave_type_id: {
                                                                            $set: leavePolicyForm
                                                                                .leave_type_details[index].id,
                                                                        },
                                                                        leaves_numbers: {
                                                                            $set: value,
                                                                        },
                                                                    },
                                                                },
                                                            })
                                                        );
                                                    } else {
                                                        setLeavePolicyForm(
                                                            update(leavePolicyForm, {
                                                                leave_type_details: {
                                                                    [index]: {
                                                                        leaves_numbers: {
                                                                            $set: value,
                                                                        },
                                                                    },
                                                                },
                                                            })
                                                        );
                                                    }
                                                }}
                                                value={item.leaves_numbers}
                                            >
                                                {[...Array(51)].map((_, i) => (
                                                    <Select.Option key={i} value={i}>
                                                        {i}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </Col>
                </Row>
                {
                    isClone ?
                        <Button htmlType="submit" className="addNewPolicyFormButton">
                            Clone Policy
                        </Button>
                        :
                        <Button htmlType="submit" className="addNewPolicyFormButton">
                            {params?.id ? "Update Policy" : "Add New Policy"}
                        </Button>
                }

            </Form>
        </div>
    )
}

export default LeavePolicyForm