import { Table } from 'antd'
import React from 'react'

function PaidEmployee() {

    const paidEmployeeColumns = [
        {
            title: 'Employee Id',
            dataIndex: 'employee_id',
            key: 'employee_id',
        },
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
        },
        {
            title: 'Cost to Company',
            dataIndex: 'cost_to_company',
            key: 'cost_to_company',
        }
    ]

    return (
        <div style={{ margin: "50px" }}>
            <div style={{ padding: "20px", background: "white", borderRadius: "5px" }}>
                <p style={{ fontSize: "20px", fontWeight: "500", color: "#161616" }}>Paid Employee</p>
                <Table
                    columns={paidEmployeeColumns}
                    dataSource={[]}
                />
            </div>
        </div>
    )
}

export default PaidEmployee