import { Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import styles from "./AddPost.module.scss"
import request from '../../../../../utils/axios';


function AddPost() {
    const { TextArea } = Input;

    const [postDetails, setPostDetails] = useState({
        visible_to_departments: "",
        choseTitle: "",
        text: "",
        uploadType: "",
        image: "",
    })

    const onFinish = async (values) => {
        try {
            const formData = new FormData();
            formData.append("visible_to_departments", postDetails.visible_to_departments);
            formData.append("post_type", postDetails.post_type);
            formData.append("text", postDetails.text);
            formData.append("image", postDetails.image);
            formData.append("added_by_admin", true);

            const response = await request({
                method: "post",
                url: `feeds/admin/posts/create`,
                data: formData
            })
            console.log("response", response.data);
        }
        catch (error) {
            console.log("error", error)
        }
    }

    const [employeeList, setEmployeeList] = useState([])

    const getEmployeeList = async () => {
        let data = await request({
            method: "get",
            url: `get-all-employees`,
        })
        setEmployeeList(data.data)
    }

    useEffect(() => {
        getEmployeeList()
    }, [])

    return (
        <div>
            <div style={{ padding: "0px 16px", marginTop: "80px" }}>
                <p style={{ color: "#161616", fontSize: "24px", fontWeight: "500", lineHeight: "27px" }}>Add Post</p>
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    style={{ marginTop: "20px" }}
                    fields={[
                        {
                            name: "visible_to_departments",
                            value: postDetails.visible_to_departments
                        },
                        {
                            name: "choseTitle",
                            value: postDetails.choseTitle
                        },
                        {
                            name: "text",
                            value: postDetails.text
                        },
                        {
                            name: "uploadType",
                            value: postDetails.uploadType
                        },
                    ]}
                >
                    <Form.Item
                        label="Post Visible"
                        name="title"
                    >
                        <Select
                            size='large'
                            style={{ width: "100%" }}
                            placeholder="Select Category"
                            value={postDetails.uploadType}
                            onChange={(value) => setPostDetails({
                                ...postDetails,
                                visible_to_departments: value
                            })}
                        >
                            {/* <Select.Option value="" disabled ><span style={{ color: "red !important" }}>Select Department</span></Select.Option> */}
                            <Select.Option value="1">Option 1</Select.Option>
                            <Select.Option value="2">Option 2</Select.Option>
                            <Select.Option value="3">Option 3</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Choose Title"
                        name="post_type"
                    >
                        <Select
                            size='large'
                            style={{ width: "100%" }}
                            placeholder="Select Category"
                            value={postDetails.post_type}
                            onChange={(value) => setPostDetails({
                                ...postDetails,
                                post_type: value
                            })}
                        >
                            <Select.Option value="1">Option 1</Select.Option>
                            <Select.Option value="2">Option 2</Select.Option>
                            <Select.Option value="3">Option 3</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Text"
                        name="text"
                    >
                        <TextArea
                            placeholder="Write text here"
                            size='large'
                            rows={4}
                            onChange={(e) => setPostDetails({
                                ...postDetails,
                                text: e.target.value
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Upload image"
                        name="uploadType"
                    >
                        <Select
                            size='large'
                            style={{ width: "100%" }}
                            placeholder="Select Category"
                            value={postDetails.uploadType}
                            onChange={(value) => setPostDetails({
                                ...postDetails,
                                uploadType: value
                            })}
                        >
                            <Select.Option value="1">Default Image</Select.Option>
                            <Select.Option value="2">Upload Image</Select.Option>
                            <Select.Option value="3">None</Select.Option>
                        </Select>
                    </Form.Item>

                    {
                        postDetails.uploadType === "1" &&
                        <div style={{ marginBottom: "20px" }}>
                            <img src="https://picsum.photos/200" alt="" width={100} height={100} />
                        </div>
                    }

                    {
                        postDetails.uploadType === "2" &&
                        <Form.Item
                            // label="Upload File"
                            name="document"

                        >
                            <div className={styles.fileUploadContainer}>
                                <input
                                    type="file"
                                    id="file-upload"
                                    className={styles.inputFile}
                                    onChange={(e) => {
                                        const selectedFile = e.target.files[0];
                                        const maxSizeInBytes = 5 * 1024 * 1024;

                                        const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', "image/jpg"];

                                        // Check if the selected file is an allowed image type
                                        if (allowedImageTypes.includes(selectedFile.type)) {
                                            // Check if the file size is within the allowed limit
                                            if (selectedFile.size <= maxSizeInBytes) {
                                                console.log(selectedFile);
                                                setPostDetails({
                                                    ...postDetails,
                                                    image: selectedFile
                                                })
                                            } else {
                                                // File size exceeds the limit
                                                // setFile(null);
                                                setPostDetails({
                                                    ...postDetails,
                                                    image: ""
                                                })
                                                alert('File size exceeds the limit of 5MB.');
                                            }
                                        } else {
                                            // File is not an allowed image type
                                            setPostDetails({
                                                ...postDetails,
                                                image: ""
                                            })
                                            alert('Please upload a valid image file (JPEG, PNG, GIF).');
                                        }
                                    }
                                    }
                                />
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                    <label htmlFor="file-upload" className={styles.fileUploadLabel}>

                                    </label>
                                    {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                    <p style={{ zIndex: 1, margin: "10px" }}>{postDetails?.image ? postDetails?.image.name : "Select a image or drag and drop here"}</p>
                                </div>
                            </div>
                        </Form.Item>
                    }

                    <button
                        style={{ border: "none", background: "#008080", color: "white", height: "40px", width: "150px", borderRadius: "5px" }}
                    >Post</button>
                </Form>
            </div>
        </div>
    )
}

export default AddPost