import React, { useEffect, useState } from 'react'
import ParticularsOfReturn from './ParticularsOfReturn/ParticularsOfReturn'
import styles from './Form24Setting.module.scss'
import ParticularsOfDeductor from './ParticularsOfDeductor/ParticularsOfDeductor'
import PersonResponsibleForDeductions from './PersonResponsibleForDeductions/PersonResponsibleForDeductions'
import Preferences from './Preferences/Preferences'
import request from '../../../../../utils/axios'
import { useDispatch } from 'react-redux'
import { setParticularsOfDeductor, setParticularsOfReturn, setPersonResponsibleForDeduction, setToggleOption } from '../../../../../redux/reducer/Form24SettingReducer'

function Form24Setting() {
    const dispatch = useDispatch();
    const [showForm, setShowForm] = useState(1)

    const [current, setCurrent] = useState(1);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };
    // const [form24QSettingData, setform24QSettingData] = useState([])

    const fetchForm24QData = async () => {
        try {
            const data = await request({
                method: "GET",
                url: `tax-management/admin/get-form24Q-settings`,
            })
            dispatch(setParticularsOfReturn(data.data.particularsOfReturn));
            dispatch(setParticularsOfDeductor(data.data.particularsOfDeductor));
            dispatch(setPersonResponsibleForDeduction(data.data.personResponsibleForDeductions));
            dispatch(setToggleOption(data.data.preferencesList));

            // setform24QSettingData(data.data)
            console.log("data................ : ", data.data)
        }
        catch (error) {
            console.log("error", error);
        }
    }
    useEffect(() => {
        fetchForm24QData()
    }, [])


    return (
        <div className={styles.container}>
            {/* <p className={styles.heading}>Payroll</p> */}

            {/* <button onClick={() => setShowForm(1)}>1</button>
            <button onClick={() => setShowForm(2)}>2</button>
            <button onClick={() => setShowForm(3)}>3</button>
            <button onClick={() => setShowForm(4)}>4</button> */}

            {
                current === 1 && <ParticularsOfReturn next={next} />
            }
            {
                current === 2 && <ParticularsOfDeductor prev={prev} next={next} />
            }
            {
                current === 3 && <PersonResponsibleForDeductions prev={prev} next={next} />
            }
            {
                current === 4 && <Preferences prev={prev} />
            }
        </div>
    )
}

export default Form24Setting