import React, { useEffect, useRef, useState } from "react"
import styles from "./Form24Q.module.scss"
import { Button, Col, Form, Input, Modal, Row, Select, Table, DatePicker } from "antd";
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import request from '../../../../utils/axios';
import { useNavigate } from "react-router-dom";
import downloadImg from "../../../../assets/downlaod.svg";
import { saveAs } from 'file-saver';
function Form24Q() {
    const navigate = useNavigate();
    const [uploadCSIFileModal, setUploadCSIFileModal] = useState(false)

    const [challanData, setChallanData] = useState([])
    const [fileData,setFiledata]= useState({
        txt_file:"",
        csi_file: ""
    })
    const onHandleChange = (key, value) => {
        setFiledata({
              ...fileData,
              [key]: value
          });
      }
    const onFinish = (values) => {
        const formData = new FormData();
        try {
          formData.append("txt_file",fileData.txt_file)
          formData.append("csi_file",fileData.csi_file)

          console.log("Form_data : ",formData)
    
          request({
              method: "post",
              url: `tax-management/admin/upload-csi`,
              data: formData
          })
          setUploadCSIFileModal(false);
        }
        catch (error) {
            console.log("error")
        }
    }
    const fetchChallanDetails = async () => {
        const data = await request({
            method: "get",
            url: `tax-management/admin/get-challan-details`
        })
        console.log("data : ", data)
        setChallanData([...data.data])
    }
    const [txtFiles, setTxtFiles] = useState([])
    const fetchTxtFiles = async () => {
        const data = await request({
            method: "get",
            url: `tax-management/admin/get-all-txt-files`
        })
        console.log("data : ", data)
        setTxtFiles([...data.data])
    }
    useEffect(() => {
        fetchChallanDetails();
        fetchTxtFiles();
    }, [])
    // useEffect(() => {
    //      console.log("fileData : ", fileData.csi_file.name)
    // }, [fileData])
    const fileInputRef = useRef(null);
    
    const handleFileUpload = async (event) => {
        const file = event.target.files[0]; // Get the uploaded file
        if (!file) return;
    
        const reader = new FileReader(); // Create a new FileReader instance
    
        // Callback function when file reading is complete
        reader.onload = async (e) => {
          try {
            const jsonData = JSON.parse(e.target.result); // Parse JSON data
            const data = await request({
                method: "post",
                headers: {
                            'Content-Type': 'application/json'
                            // Add any other headers if needed
                        },
                url: `tax-management/admin/upload-json`,
                data:jsonData,         
            })
            console.log('Uploaded JSON data:', jsonData);
          } catch (err) {
            console.log('Error parsing JSON:', err);
          }
        };
    
        // Read the uploaded file as text
        reader.readAsText(file);
      };
      const txtFileColumns = [
        // {
        //     title: "File Name",
        //     dataIndex: "file_name",
        //     key: "file_name"
        // },
        {
            title: "Quarter",
            dataIndex: "quarter",
            key: "quarter"
        },
        {
            title: "Generated On",
            dataIndex: "file_generated_month",
            key: "file_generated_month"
        },
        {
            title: "Download",
            dataIndex: "txt_file",
            key: "txt_file",
            render: (txt_file, record) =>(
                <p
                style={{
                borderRadius: "15px",
                // textAlign: "center",
                cursor: "pointer",
                }}
                onClick={async () => {
                try {
                    const file_url = record.txt_file; // Get the URL of the txt file
                    console.log("file_url : ", file_url)
                    const response = await fetch(file_url); // Fetch the txt file
                    const blob = await response.blob(); // Convert the response to a Blob object
                    const filename = file_url.substring(file_url.lastIndexOf('/') + 1); // Extract the file name
                    saveAs(blob, filename); // Save the blob as a txt file
                } catch (error) {
                    console.error('Error downloading txt file:', error);
                }
                    }}
                >
                    <img src={downloadImg} alt="downloadImg" />
                </p>
            )
        }
    ]

    const form24Qtabs = [
        {
            name: "Challan",
            key: "challan",
            value: 1
        },
        {
            name: "Txt File",
            key: "txtFile",
            value: 2
        },
        {
            name: "CSI File Upload",
            key: "csiFileUpload",
            value: 3
        },
        {
            name: "Output",
            key: "output",
            value: 4
        }
    ]
    const [selectTab, setSelectTab] = useState(1)

    const csiFileUploadColumn = [
        // {
        //     title: 'File Name',
        //     dataIndex: 'FVU',
        //     key: 'FVU',
        // },
        {
            title: 'Quarter',
            dataIndex: 'quarter',
            key: 'quarter',
        },
        {
            title: 'FVU Files',
            dataIndex: 'fvu_file',
            key: 'fvu_file',
            render: (fvu_file, record) =>(
                <p
                style={{
                borderRadius: "15px",
                // textAlign: "center",
                cursor: "pointer",
                }}
                onClick={async () => {
                try {
                    const file_url = record.fvu_file; // Get the URL of the txt file
                    console.log("file_url : ", file_url)
                    const response = await fetch(file_url); // Fetch the txt file
                    const blob = await response.blob(); // Convert the response to a Blob object
                    const filename = file_url.substring(file_url.lastIndexOf('/') + 1); // Extract the file name
                    saveAs(blob, filename); // Save the blob as a txt file
                } catch (error) {
                    console.error('Error downloading txt file:', error);
                }
                    }}
                >
                    <img src={downloadImg} alt="downloadImg" />
                </p>
            )
        },
        {
            title: 'Receipt',
            dataIndex: 'receipt_file',
            key: 'receipt_file',
            render: (receipt_file, record) =>(
                <p
                style={{
                borderRadius: "15px",
                // textAlign: "center",
                cursor: "pointer",
                }}
                onClick={async () => {
                try {
                    const file_url = record.receipt_file; // Get the URL of the txt file
                    console.log("file_url : ", file_url)
                    const response = await fetch(file_url); // Fetch the txt file
                    const blob = await response.blob(); // Convert the response to a Blob object
                    const filename = file_url.substring(file_url.lastIndexOf('/') + 1); // Extract the file name
                    saveAs(blob, filename); // Save the blob as a txt file
                } catch (error) {
                    console.error('Error downloading txt file:', error);
                }
                    }}
                >
                    <img src={downloadImg} alt="downloadImg" />
                </p>
            )
        }
    ]
    

    const challanColumn = [
        {
            title: 'Month',
            dataIndex: 'get_payroll_Month_display',
            key: 'get_payroll_Month_display',
        },
        {
            title: 'Regular TDS',
            dataIndex: 'tds_amount',
            key: 'tds_amount',
        },
        {
            title: 'Paid On',
            dataIndex: 'payment_date',
            key: 'payment_date',
        },
        {
            title: 'Challan No',
            dataIndex: 'challan_serial_no',
            key: 'challan_serial_no',
        },
        {
            title: 'Total Challan Amount',
            dataIndex: 'total_challan_amount',
            key: 'total_challan_amount',
        },
        // {
        //     title: 'CSI File',
        //     dataIndex: 'action',
        //     key: 'action',
        // }
    ]

    const outputColumn = [
        {
            title: 'Files',
            dataIndex: 'employee',
            key: 'employee',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
        }
    ]
    return (
        <div className={styles.form24QContainer}>
            <p className={styles.heading}>Payroll</p>
            <div className={styles.container}>
                <p className={styles.subHeading}>Form 24Q</p>
                <div className={styles.tabButtonContainer}>
                    {
                        form24Qtabs.map((item, index) => {
                            return (
                                <div
                                    key={item.key}
                                    className={selectTab === item.value ? styles.selectTabButton + " " + styles.tabButton : selectTab > index + 1 ? styles.filledTabButton + " " + styles.tabButton : styles.tabButton}
                                    onClick={() => setSelectTab(item.value)}
                                >
                                    <div className={selectTab > index + 1 ? styles.filledIndexBox : styles.indexBox} >
                                        <center>
                                            {
                                                selectTab > index + 1
                                                    ?
                                                    <CheckOutlined />
                                                    :
                                                    item.value
                                            }

                                        </center>
                                    </div>
                                    {item.name}
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        selectTab === 1
                        &&
                        <>
                            <div style={{ display: "flex", justifyContent: "end" }}>
                                <button
                                    style={{ height: "40px", padding: "0px 15px", background: "#008080", color: "white", border: "none", borderRadius: "5px" }}
                                    onClick={() => navigate("/admin/payroll/form24Q/challan", { replace: true })}
                                >Add Challan</button>
                            </div>
                            <Table
                                columns={challanColumn}
                                dataSource={challanData}
                                pagination={false}
                            />
                            <div>
                            <input
                                type="file"
                                accept=".json"
                                onChange={handleFileUpload}
                                style={{ display: 'none' }} // Hide the input element
                                ref={fileInputRef} // Set the ref to file input
                            />
                            <button onClick={() => fileInputRef.current.click()}>Upload JSON</button>

                            {/* {fileData && <p>File uploaded: {fileData}</p>} */}
                            </div>
                            


                        </>
                        
                    }
                  
                </div>
                <div>
                    {
                        selectTab === 2
                        &&
                        <>
                            <Table
                                columns={txtFileColumns}
                                dataSource={txtFiles}
                                pagination={false}
                            />
                        </>
                    }
                </div>
                <div>
                    {
                        selectTab === 3
                        &&
                        <>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                                <button
                                    style={{ height: "40px", padding: "0px 15px", background: "#008080", color: "white", border: "none", borderRadius: "5px" }}
                                    onClick={() => setUploadCSIFileModal(true)}
                                >Upload CSI File</button>
                            </div>
                            <Table
                                columns={csiFileUploadColumn}
                                dataSource={txtFiles}
                                pagination={false}
                            />
                        </>
                    }
                </div>
                <div>
                    {
                        selectTab === 4
                        &&
                        <>
                            <Table
                                columns={outputColumn}
                                dataSource={[]}
                                pagination={false}
                            />
                        </>
                    }
                </div>
            </div>
            <Modal 
            title="Upload CSI & Txt File"
            centered
            open={uploadCSIFileModal}
            // onOk={() => setReviseSalary(false)}
            onCancel={() => setUploadCSIFileModal(false)}
            width={400}
            footer={null}>
                <Form
                    // form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    // onValuesChange={onValuesChange}
                >
                    <Row gutter={[32, 32]}>                      
                        <Col span={24}>
                            <p>Upload CSI File</p>
                            <Form.Item
                                label="Upload CSI File"
                                name="csi_file"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select CSI File!',
                                    },
                                ]}
                            >
                                <div className="file-upload-container">
                                    <input
                                        type="file"
                                        id="csi"
                                        className="input-file"
                                        // onChange={(e) => setHolidayListFile(e.target.files && e.target.files[0])}
                                        onChange={(e) => {
                                            const selectedFile = e.target.files[0];
                                            const maxSizeInBytes = 5 * 1024 * 1024;

                                            if (selectedFile) {
                                                onHandleChange("csi_file", selectedFile)
                
                                            }
                                        }}
                                    />
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                        <label htmlFor="csi" className="file-upload-label">

                                        </label>
                                        {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                        <p style={{ zIndex: 1, margin: "0px" }}>{fileData ? fileData.csi_file.name : 'Choose a file'}</p>
                                    </div>
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <p>Upload Txt File</p>
                            <Form.Item
                                label="Upload Txt File"
                                name="txt_file"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Txt File!',
                                    },
                                ]}
                            >
                                <div className="file-upload-container">
                                    <input
                                        type="file"
                                        id="txt"
                                        className="input-file"
                                        // onChange={(e) => setHolidayListFile(e.target.files && e.target.files[0])}
                                        onChange={(e) => {
                                            const selectedTxtFile = e.target.files[0];
                                            const maxSizeInBytes = 5 * 1024 * 1024;
                                           

                                            if (selectedTxtFile) {
                                                
                                                onHandleChange("txt_file", selectedTxtFile)
                
                                            }
                                        }}
                                    />
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                        <label htmlFor="txt" className="file-upload-label">

                                        </label>
                                        {/* <p style={{ zIndex: 1, margin: "0px" }}> Choose a file</p> */}
                                        <p style={{ zIndex: 1, margin: "0px" }}>{fileData ? fileData.txt_file.name : 'Choose a file'}</p>
                                    </div>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button
                        htmlType='submit'
                        style={{ width: "100%", color: "white", background: "#008080", height: "44px", marginTop: "40px" }}
                    >
                        Submit
                    </Button>
                </Form>


            </Modal>

        </div>

    )
}
export default Form24Q