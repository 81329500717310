import { Checkbox, Col, Row, Switch } from 'antd'
import React from 'react'

function Filter({ setFilter, setTrendingPost }) {
    const postType = ["Appreciation Post", "Rewards Post", "Promotion Post", "Polls/survey", "Festive Post", "Polls/survey"]
    const timeDuration = ["Latest Post"]
    const postedBy = ["Admin", "Employee"]
    const postByDepartment = ["Human Resources", "Sales", "Marketing", "IT", "Finance", "Operations", "Customer Support"]


    const onChange = (checkedValues) => {
        setFilter(checkedValues)
    };

    const onChangeToggle = (checked) => {
        setTrendingPost(checked)
    };

    return (
        <div>
            <div style={{ border: "1px solid #ECECEC", borderRadius: "3px" }}>
                <div style={{ borderBottom: "1px solid #ECECEC", paddingBottom: "10px", padding: "15px 25px" }}>
                    <Switch
                        size='small'
                        onChange={onChangeToggle}
                    />
                    <span style={{ color: "#161616", marginLeft: "10px", fontWeight: "500", fontSize: "18px" }}>Trending post</span>
                </div>
                <Checkbox.Group
                    style={{ width: '100%' }}
                    onChange={onChange}
                >
                    <div style={{ borderBottom: "1px solid #ECECEC", paddingBottom: "10px", padding: "15px 25px", marginTop: "10px", width: "100%" }}>
                        <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }}>Post Type</p>
                        <Row>
                            {
                                postType.map((item, index) => {
                                    return (
                                        <Col span={24} style={{ marginBottom: "10px" }}>
                                            <Checkbox value={item} key={index}>{item}</Checkbox>
                                        </ Col>
                                    )
                                })
                            }
                        </Row>

                    </div>
                    <div style={{ borderBottom: "1px solid #ECECEC", paddingBottom: "10px", padding: "15px 25px", marginTop: "10px", width: "100%" }}>
                        <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }}>Time Duration</p>
                        <Row>
                            {
                                timeDuration.map((item, index) => {
                                    return (
                                        <Col span={24} style={{ marginBottom: "10px" }}>
                                            <Checkbox value={item} key={index}>{item}</Checkbox>
                                        </ Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    <div style={{ borderBottom: "1px solid #ECECEC", paddingBottom: "10px", padding: "15px 25px", marginTop: "10px", width: "100%" }}>
                        <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }}>Posted by</p>
                        <Row>
                            {
                                postedBy.map((item, index) => {
                                    return (
                                        <Col span={24} style={{ marginBottom: "10px" }}>
                                            <Checkbox value={item} key={index}>{item}</Checkbox>
                                        </ Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    <div style={{ borderBottom: "1px solid #ECECEC", paddingBottom: "10px", padding: "15px 25px", marginTop: "10px" }}>
                        <p style={{ fontSize: "18px", fontWeight: "500", color: "#161616" }}>Post by Department</p>
                        <Row>
                            {
                                postByDepartment.map((item, index) => {
                                    return (
                                        <Col span={24} style={{ marginBottom: "10px" }}>
                                            <Checkbox value={item} key={index}>{item}</Checkbox>
                                        </ Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </Checkbox.Group>
            </div >
        </div>
    )
}

export default Filter