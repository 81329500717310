import { Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react';
import styles from './ParticularsOfReturn.module.scss';
import { setParticularsOfReturn } from "../../../../../../redux/reducer/Form24SettingReducer";
import { useSelector, useDispatch } from 'react-redux'
import request from '../../../../../../utils/axios';

import getScreenSize from '../../../../../../utils/getScreenSize';

function ParticularsOfReturn({ next }) {
    const dispatch = useDispatch()
    const screenSize = getScreenSize();
    let particularsOfReturn = useSelector((state) => state.form24Setting.particularsOfReturn)
    console.log("particularsOfReturn : ", particularsOfReturn);
    const onFinish = (values) => {
        console.log("values : ", values);
        const data = {
            tan: values.tan,
            section: values.section
        }
        dispatch(setParticularsOfReturn(data))
        next();
    }
    const [paymentSection, setPaymentSection] = useState([])
    const getPaymentSectionList = async () => {
        const data = await request({
            method: "get",
            url: `tax-management/admin/payment-section-choices`,
        })
        setPaymentSection(data.data)
    }
    useEffect(() => {
        getPaymentSectionList()
    }, [])
    return (
        <div>
            {
                screenSize.width < 768
                    ?
                    // mobile
                    <div style={{ padding: "0px 16px" }} >
                        {/* <p className={styles.heading}>24Q Parameters</p> */}
                        <div className={styles.subContainer}>
                            <p style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#6B7280"
                            }}>Particulars Of Return</p>
                        </div>

                        <div>
                            <Form layout="vertical"
                                onFinish={onFinish}
                                initialValues={particularsOfReturn}
                                fields={[
                                    {
                                        name: ["tan"],
                                        value: particularsOfReturn.tan
                                    },
                                    {
                                        name: ["section"],
                                        value: particularsOfReturn.section
                                    }
                                ]}
                            >
                                <Row gutter={[32, 12]}>
                                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                        <Form.Item
                                            label="Type Of Deductor"
                                            name="tan"
                                        >
                                            <Input size='large' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                        <Form.Item
                                            label="Payment Section Code"
                                            name="section"
                                        >
                                            <Select
                                                className='dropDown'
                                            >
                                                {
                                                    paymentSection.map((item, index) => {
                                                        return (
                                                            <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <button htmlType="submit"
                                    style={{
                                        height: "40px",
                                        color: "white",
                                        background: "#008080",
                                        border: "none",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        marginTop: "20px",
                                        padding: "0px 16px"
                                    }}
                                >Next</button>
                            </Form>
                        </div>
                    </div>
                    :
                    <div className={styles.container}>
                        {/* <p className={styles.heading}>24Q Parameters</p> */}
                        <div className={styles.subContainer}>
                            <p className={styles.subHeading}>Particulars Of Return</p>
                        </div>

                        <div>
                            <Form layout="vertical"
                                onFinish={onFinish}
                                initialValues={particularsOfReturn}
                                fields={[
                                    {
                                        name: ["tan"],
                                        value: particularsOfReturn.tan
                                    },
                                    {
                                        name: ["section"],
                                        value: particularsOfReturn.section
                                    }
                                ]}
                            >
                                <Row gutter={[32, 32]}>
                                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                        <Form.Item
                                            label="Type Of Deductor"
                                            name="tan"
                                        >
                                            <Input size='large' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                        <Form.Item
                                            label="Payment Section Code"
                                            name="section"
                                        >
                                            <Select
                                                className='dropDown'
                                            >
                                                {
                                                    paymentSection.map((item, index) => {
                                                        return (
                                                            <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <button htmlType="submit"
                                    className={styles.btn}
                                >Next</button>
                            </Form>
                        </div>
                    </div>
            }

        </div>
    )
}

export default ParticularsOfReturn