import { CloseOutlined, LinkOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import desktopStyles from "./Desktop.module.scss";
import mobileStyles from "./Mobile.module.scss";
import update from "immutability-helper";
import pdf from "../../../assets/PDF.png"
import { Button } from "antd";
import request from "../../../utils/axios";
import getScreenSize from "../../../utils/getScreenSize";
import upload from "../../../assets/upload.png";

function UploadDocs({ prev, next }) {
  const screenSize = getScreenSize();

  const [docs, setDocs] = useState([
    {
      name: "Experience Letter",
      key: "experience_letter",
      file: "",
    },
    {
      name: "Education certificates",
      key: "educational_certificate",
      file: "",
    },
    {
      name: "Appointment letter of previous employer",
      key: "appointment_letter",
      file: "",
    },
    {
      name: "Resignation Acceptance",
      key: "resignation_acceptance",
      file: "",
    },
    {
      name: "Resignation Acceptance letter",
      key: "resignation_acceptance_letter",
      file: "",
    },
    {
      name: "Last 3 months payslip",
      key: "salary_slip_three_months",
      file: "",
    },
    {
      name: "Bank statement",
      key: "bank_statement",
      file: "",
    },
    {
      name: "Resume",
      key: "resume",
      file: "",
    },
    {
      name: "Adhaar Card",
      key: "aadhar_card",
      file: "",
    },
    {
      name: "PAN Card",
      key: "pan",
      file: "",
    },
    {
      name: "Cancelled Cheque",
      key: "cancelled_cheque",
      file: "",
    },
    {
      name: "Passport size photo",
      key: "passport_size_photo",
      file: "",
    },
  ]);

  const handleImageUpload = (file, index) => {
    console.log("file", file, index);
    const updatedDocs = update(docs, {
      [index]: {
        file: { $set: file },
      },
    });

    console.log("updatedDocs", updatedDocs);
    setDocs(updatedDocs);
  };

  const fetchUploadedDocs = async () => {
    try {
      const res = await request({
        method: "get",
        url: `/onbaording/employee/personal-documents/all`,
      });
      const data = docs?.map(doc => {
        const matchedData = res?.data?.find(item => item.document_type === doc.key);
        if (matchedData) {
          return { ...doc, file: matchedData.filename };
        }
        return doc;
      });

      console.log("check", data);

      setDocs(data)
    }
    catch (error) {
      console.log("error", error);
    }
  };


  const submit = async (move) => {
    try {
      const formData = new FormData();

      for (let i = 0; i < docs?.length; i++) {
        const doc = docs[i];
        const file = doc.file;
        console.log("file", typeof file);
        if (file) {
          formData.append(docs[i].key, file);
        }
      }
      await request({
        method: "post",
        url: `/onboarding/employee/document/upload`,
        data: formData
      })

      if (move === "prev") {
        prev()
      }
      else {
        next();
      }
    }
    catch (error) {
      console.log("error", error);
    }
  };


  useEffect(() => {
    fetchUploadedDocs()
  }, [])

  return (
    <div>
      {
        screenSize.width < 768 ?
          <div style={{ marginTop: "25px" }}>
            {docs?.map((doc, docIndex) =>

            (
              <div
                className={mobileStyles.docsContainer}
                key={docIndex}
              >
                <div>
                  <p
                    className={mobileStyles.label}
                  >
                    {doc.name}
                  </p>
                  {
                    doc?.file
                    &&
                    <div className={mobileStyles.uploadFileContainer}>
                      <img src={pdf} alt="pdf" width={15} height={15} />
                      <p className={mobileStyles.fileName}>
                        {doc?.file?.name ? doc?.file?.name : doc?.file}
                        {/* {doc?.file && } */}
                      </p>
                      <CloseOutlined className={mobileStyles.cross} />
                    </div>
                  }

                </div>

                <div
                  className={mobileStyles.attachFileBox}
                >
                  <input
                    type="file"
                    className={mobileStyles.inputFile}
                    onChange={(event) => handleImageUpload(event.target.files[0], docIndex)
                    }
                  />
                  <div>

                    <img src={upload} alt="upload" />

                  </div>
                </div>
              </div>
            )
            )}

            <Button
              size='large'
              onClick={() => {
                submit("prev")

              }}
              style={{ color: "white", background: "#008080" }}
            >
              Previous
            </Button>

            <Button
              size='large'
              onClick={() => {
                submit("next")

              }}
              style={{ color: "white", background: "#008080" }}
            >
              Next
            </Button>
          </div>
          :
          <>
            <p className={desktopStyles.heading}>Upload Documents</p>
            {docs?.map((doc, docIndex) =>

            (
              <div
                className={desktopStyles.docsContainer}
                key={docIndex}
              >
                <div>
                  <p
                    className={desktopStyles.label}
                  >
                    {doc.name}
                  </p>
                  {
                    doc?.file
                    &&
                    <div className={desktopStyles.uploadFileContainer}>
                      <img src={pdf} alt="pdf" />
                      <p className={desktopStyles.fileName}>
                        {doc?.file?.name ? doc?.file?.name : doc?.file}
                        {/* {doc?.file && } */}
                      </p>
                      <CloseOutlined className={desktopStyles.cross} />
                    </div>
                  }

                </div>
                <div
                  className={desktopStyles.attachFileBox}
                >
                  <input
                    type="file"
                    className={desktopStyles.inputFile}
                    onChange={(event) => handleImageUpload(event.target.files[0], docIndex)
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label htmlFor="file-upload"></label>
                    <p
                      className={desktopStyles.attachFileLabel}
                    >
                      <LinkOutlined /> Attach File
                    </p>
                  </div>
                </div>
              </div>
            )
            )}

            <Button
              size='large'
              onClick={() => {
                submit("prev")

              }}
              style={{ color: "white", background: "#008080" }}
            >
              Previous
            </Button>

            <Button
              size='large'
              onClick={() => {
                submit("next")

              }}
              style={{ color: "white", background: "#008080" }}
            >
              Next
            </Button>

          </>
      }


    </div>
    // </div >
  );
}

export default UploadDocs;

