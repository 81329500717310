import React from 'react';
import bullet from "../../assets/bullet.svg"

const CustomStepper = ({ steps, current, setCurrent }) => {

    return (
        <div style={{ padding: "0px 16px" }}>
            <div style={{ display: "flex", gap: "10px" }}>
                {
                    steps.map((item, index) => {
                        return (
                            <div>
                                <div style={{ display: "flex", gap: "10px", alignItems: "start" }}>

                                    <div
                                    //  onClick={() => setCurrent(index)}
                                    >
                                        {
                                            index < current
                                                ?
                                                <img src={bullet} style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
                                                :
                                                <>
                                                    {
                                                        index === current
                                                            ?
                                                            <div style={{ width: "30px", height: "30px", borderRadius: "50%", border: "2px solid #008080", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <center style={{ marginTop: "35%" }}>
                                                                    <p style={{ fontSize: "12px", color: "#008080", fontWeight: "600" }}>
                                                                        {index + 1}
                                                                    </p>
                                                                </center>
                                                            </div>
                                                            :
                                                            <div style={{ width: "30px", height: "30px", borderRadius: "50%", border: "1px solid #ccc", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <center style={{ marginTop: "30%" }}>
                                                                    <p style={{ fontSize: "12px" }}>
                                                                        {index + 1}
                                                                    </p>
                                                                </center>
                                                            </div>
                                                    }
                                                </>

                                        }

                                    </div>
                                    {
                                        index !== steps.length - 1
                                        &&
                                        <div>
                                            {
                                                index < current
                                                    ?
                                                    <hr style={{ border: "1px solid #008080", width: "10vw", marginTop: "15px" }} />
                                                    :
                                                    <hr style={{ border: "1px solid #ccc", width: "10vw", marginTop: "15px" }} />
                                            }
                                        </div>
                                    }
                                </div>
                                <div style={{ width: "50px" }}>
                                    {
                                        index <= current
                                            ?
                                            <p style={{ color: "#008080", fontSize: "12px", textAlign: "center", marginLeft: "-15px", marginTop: "10px" }}>{item.title}</p>
                                            :
                                            <p style={{ color: "#ccc", fontSize: "12px", textAlign: "center", marginLeft: "-15px", marginTop: "10px" }}>{item.title}</p>
                                    }

                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default CustomStepper;
