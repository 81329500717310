import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {
    DownOutlined,
    InfoCircleOutlined,
    SearchOutlined,
  } from "@ant-design/icons";
import { toast } from 'react-toastify';
import { Button, Col, DatePicker, Input, Modal, Popover, Row, Select, Switch, Table } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '../../../../component/CommonTable/CommonTable';
import useScreenSize from '../../../../utils/getScreenSize';
import request from '../../../../utils/axios';

function LeaveDetailsFullView() {
    const navigate = useNavigate();
    const screenSize = useScreenSize();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    })

    const [applyLeaveModel, setApplyLeaveModel] = useState(false);
    const myLeaveDetailColumns = [
        {
          title: "From",
          dataIndex: "start_date",
          key: "start_date",
          render: (text, record) => (
            <span>{moment(text).format("DD-MM-YYYY")}</span>
          ),
        },
        {
          title: "To",
          dataIndex: "end_date",
          key: "end_date",
          render: (text, record) => (
            <span>{moment(text).format("DD-MM-YYYY")}</span>
          ),
        },
        {
          title: "Leave Type",
          dataIndex: "leave_type",
          key: "leave_type",
        },
        {
          title: "No. Of Leave",
          dataIndex: "leave_day",
          key: "leave_day",
          render: (text, record) => (
            <>
              <span>
                {text}{" "}
                <Popover
                  placement="top"
                  title="Leaves"
                  content={leaveDetailPopUp(record)}
                >
                  <InfoCircleOutlined />
                </Popover>
              </span>
            </>
          ),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          // width: "200px",
          render: (text, record) => (
            <span>
              {moment(new Date()).format("YYYY-MM-DD") < record.start_date && record.is_pending && ( // Checking if current date is before end date
                <>
                  <span
                    onClick={() => {
                      setAppliedLeaveId(record.id);
                      setApplyLeaveModel(true); // Triggering edit action
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    Edit
                  </span>
    
                  <span
                    onClick={() => {
                      withdrawLeaveApplication(record.id); // Triggering withdraw action
                    }}
                  >
                    Withdraw
                  </span>
                </>)}
    
            </span>
          ),
        }
      ];

    const [appliedLeaveId, setAppliedLeaveId] = useState(null);

    const [myAppliedLeaveDetails, setMyAppliedLeaveDetails] = useState([]);
    const fetchLeaveDetails = async () => {
      const data = await request({
        method: "get",
        url: `leave/leave-application/all/details/?page=${pagination.current}&page_size=${pagination.pageSize}`,
      });
  
      if (data && data?.pagination_data && data?.pagination_data?.data) {
        setMyAppliedLeaveDetails([...data?.pagination_data?.data]);
      }
        // return data
        setPagination({
            ...pagination,
            total: data?.pagination_data?.total
        })
    }
    const withdrawLeaveApplication = async (id) => {
        try {
          await request({
            method: "post",
            url: `leave/employee/leave-application/withdraw?leave_application_id=${id}`,
          });
          fetchLeaveDetails();
        }
        catch (error) {
    
        }
      }
    const leaveDetailPopUp = (record) => {
        return (
          <div>
            <div style={{ display: "flex", gap: "10px" }}>
              {record.leave_dates.map((item, index) => {
                return (
                  <>
                    <span>
                      {item.leave_for === "full_day" ? (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "white",
                            padding: "3px",
                            borderRadius: "50%",
                            background: "#008080",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "25px",
                          }}
                        >
                          {dayjs(item.date).format("DD")}
                        </div>
                      ) : record.from_second_half_start_date ? (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "black",
                            padding: "3px",
                            borderRadius: "50%",
                            background:
                              "linear-gradient(to left, #008080, #008080 50%, #DDDDDD 50%)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "25px",
                          }}
                        >
                          {dayjs(item.date).format("DD")}
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "black",
                            padding: "3px",
                            borderRadius: "50%",
                            background:
                              "linear-gradient(to right, #008080, #008080 50%, #DDDDDD 50%)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "25px",
                            height: "25px",
                          }}
                        >
                          {dayjs(item.date).format("DD")}
                        </div>
                      )}
                    </span>
                  </>
                );
              })}
            </div>
          </div>
        );
      };
    useEffect(() => {
        fetchLeaveDetails()
    }, [pagination.current])

    return (
        <div >
            {
                screenSize.width < 768 ?  // mobile view

                    // <div style={{ marginTop: "2vh", display: "flex", justifyContent: "center" }}>
                    //     <InfiniteScroll
                    //         fetchData={interviewList}
                    //         page_size={10}
                    //         renderPosts={renderPosts}
                    //     />
                    <div>
                    <div style={{ padding: "0px 16px" }}>
                      {myAppliedLeaveDetails.length > 0 && (
                        <div className="teamContainer" style={{ marginBottom:"10px" }}>
                          <div className="teamText">Leave Requests</div>
                        </div>
                      )}
                    </div>
                    {myAppliedLeaveDetails.map((item, index) => {
                      return (
                        <div key={index}>
                          <div style={{ padding: "0px 16px" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <p className="name">{item?.employee_name}</p>
                              <div
                                className="pendingContainer"
                                style={{
                                  borderColor: item?.is_approved ? "#28B15F" : "red",
                                }}
                              >
                                <p
                                  className="status"
                                  style={{ color: item?.is_approved ? "#28B15F" : "red" }}
                                >
                                  {item?.is_pending && "Pending"}
                                  {item?.is_approved || item?.is_predefined_leaves
                                    ? "Approved"
                                    : ""}
        
                                  {item?.is_rejected && "Rejected"}
                                </p>
                              </div>
                            </div>
        
                            <p className="leaveText">{item?.leave_type}</p>
        
                            <div>
                              <p className="date">
                                {moment(item?.start_date).format("DD-MM-YYYY")} To{" "}
                                {moment(item?.end_date).format("DD-MM-YYYY")}
                              </p>
                            </div>
                            <div className="leavesText">
                              {item?.leave_day} leaves
                              <Popover
                                placement="topRight"
                                title="Leaves"
                                content={leaveDetailPopUp(item)}
                              >
                                <DownOutlined />
                              </Popover>
        
                            </div>
                          </div>
                          {index !== myAppliedLeaveDetails?.length - 1 && (
                            // <hr className="divider" />
                            <hr style={{
                              height: "1px",
                              backgroundColor: "#F0F0F0",
                              border: "none",
                            }} />
                          )}
                        </div>
                      );
                    })}
                  </div>

                    :

                    <>
                        <div className='Container'>
                            <h1>Leave Details</h1>

                            <div className='referralSubContainer' style={{ "background": "white" }}>
                                <div style={{ marginTop: "2vh" }}>
                                    <CommonTable
                                        columns={myLeaveDetailColumns}
                                        data={myAppliedLeaveDetails}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        handleRowClick={() => { }}
                                    />
                                </div>
                            </div>
                        </div>

                    </>

            }

        </div>
    )
}

export default LeaveDetailsFullView 