import { Button, Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import request from '../../../utils/axios';
import { setSalaryDetails, resetEmployeeDetails } from '../../../redux/reducer/EmployeeReducer';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import getScreenSize from '../../../utils/getScreenSize';
import dayjs from 'dayjs';
import update from "immutability-helper"

function SalaryDetails({ prev }) {
    const [ctc, setCtc] = useState("");
    const [ctcBreakUp, setCtcBreakUp] = useState(null)
    const ref = useRef();
    const params = useParams();
    const navigate = useNavigate();
    const screenSize = getScreenSize();

    const org = useSelector((state) => state.employee.org)
    let personalDetails = useSelector((state) => state.employee.personalDetails)
    let employmentDetails = useSelector((state) => state.employee.employmentDetails)
    const policies = useSelector((state) => state.employee.policies)
    const salaryDetails = useSelector((state) => state.employee.salaryDetails)
    const [canEdit, setCanEdit] = useState(false);
    const dispatch = useDispatch();

    const onFinishCTC = async (values) => {
        try {
            setCtc(values.ctc)

            const data = await request({
                method: "get",
                url: `payroll/admin/employee-salary-breakup?ctc=${values.ctc}&salary_id=${policies.salary_structure}`,
            })

            dispatch(setSalaryDetails({
                ...values,
                salaryBreakUp: data.data
            }));
            setCtcBreakUp(data.data)
        }
        catch (err) {
            console.log("err : ", err);
        }
    }

    const submit = async (values) => {
        try {
            if (personalDetails?.dob) {
                const dob = dayjs(personalDetails.dob).format('YYYY-MM-DD');
                personalDetails = { ...personalDetails, dob }
            }

            if (personalDetails?.anniversary) {
                const anniversary = dayjs(personalDetails?.anniversary).format('YYYY-MM-DD');
                personalDetails = { ...personalDetails, anniversary }
            }
            const joining_date = dayjs(employmentDetails.joining_date).format('YYYY-MM-DD');
            employmentDetails = { ...employmentDetails, joining_date }

            if (window.location.pathname.split("/").includes("edit")) {
                await request({
                    method: "put",
                    url: `/admin/employee/update/${params.id}/`,
                    data: {
                        org,
                        ...personalDetails,
                        ...employmentDetails,
                        ...policies,
                        ctc: salaryDetails.ctc,
                        "user_id": localStorage.getItem("user_id"),
                        ctcBreakUp
                    }
                })
            }
            else {
                const data = await request({
                    method: "post",
                    url: '/create-employee',
                    data: {
                        org,
                        ...personalDetails,
                        ...employmentDetails,
                        ...policies,
                        ctc: salaryDetails.ctc,
                        "user_id": localStorage.getItem("user_id"),
                        ctcBreakUp
                    }
                })
            }


            dispatch(resetEmployeeDetails())
            navigate('/admin/employee', { replace: true });
            toast.success("Employee created successfully", {
                position: "bottom-left",
            })
        }
        catch (err) {
            console.log("err...", err)
            toast.error(err.response.data.message);
        }
    }

    useEffect(() => {
        if (salaryDetails.salaryBreakUp) {
            setCtcBreakUp(salaryDetails.salaryBreakUp)
        }
    }, [])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    const handleChangeCTC = (breakupType, index, value) => {
        let updatedValue = update(ctcBreakUp, {
            [breakupType]: {
                [index]: {
                    yearly_amount: { $set: value },
                }
            }
        });

        setCtcBreakUp(updatedValue);
    }

    return (
        <div className='employeeEmploymentDetailsContainer' ref={ref}>
            {/* <hr /> */}
            <br />
            {
                screenSize.width < 768
                    ?
                    <div>

                        <div style={{ marginTop: "20px" }}>
                            <Form
                                // form={form}
                                layout="vertical"
                                onFinish={onFinishCTC}
                                autoComplete="true"
                                fields={[{ name: "ctc", value: salaryDetails.ctc }]}
                            >
                                <Row gutter={32}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="ctc"
                                            // label="CTC"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Enter CTC!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                size='large'
                                                placeholder="Enter CTC"
                                                value={salaryDetails.ctc}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <div className="buttonContainer">
                                            <Button style={{
                                                padding: "0px 15px",
                                                height: "40px",
                                                background: "#008080",
                                                color: "white",
                                                fontSize: "16px",
                                                // marginTop: "10px"
                                            }} onClick={() => prev()} >Previous</Button>
                                            <button type="submit" style={{
                                                padding: "0px 15px",
                                                // marginTop: "3vh",
                                                height: "40px",
                                                background: "#008080",
                                                color: "white",
                                                border: "none",
                                                borderRadius: "5px",
                                                alignItems: "center",
                                                float: "right"
                                            }}>Get BreakUp</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        {
                            (ctcBreakUp?.fixed.length > 0 || ctcBreakUp?.flexi.length > 0 || ctcBreakUp?.retirals?.length > 0 || ctcBreakUp?.variable?.length > 0)
                            &&
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
                                <p style={{ color: "#161616", fontWeight: "500", fontSize: "20px", margin: "0px" }}>Salary BreakUp</p>
                                <button style={{ padding: "0px 15px", height: "40px", background: "#008080", color: "white", border: "none", borderRadius: "5px", fontSize: "18px" }}
                                    onClick={() => setCanEdit(true)}
                                >Edit</button>
                            </div>
                        }
                        <Form
                            // form={form}
                            layout="vertical"
                            // initialValues={employmentDetails}
                            onFinish={submit}
                            autoComplete="true"
                        >
                            <Row gutter={16}>
                                {
                                    ctcBreakUp?.fixed.length > 0
                                    &&
                                    <>
                                        <Col span={24}>
                                            <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Fixed</p>
                                        </Col>

                                        {
                                            ctcBreakUp?.fixed?.map((item, index) => {
                                                return (
                                                    <Col span={24}>
                                                        <Form.Item
                                                            key={index}
                                                            label={item?.name}
                                                        >
                                                            <Input
                                                                size='large'
                                                                placeholder="Enter Amount"
                                                                value={item?.yearly_amount}
                                                                disabled={!canEdit}
                                                            />

                                                        </Form.Item>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </>
                                }

                            </Row>

                            <Row gutter={16}>
                                {
                                    ctcBreakUp?.flexi.length > 0
                                    &&
                                    <>
                                        <Col span={24}>
                                            <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Flexi</p>
                                        </Col>
                                        {
                                            ctcBreakUp?.flexi?.map((item, index) => {
                                                return (
                                                    <Col span={24}>
                                                        <Form.Item
                                                            key={index}
                                                            label={item?.name}
                                                        >
                                                            <Input
                                                                size='large'
                                                                placeholder="Enter Amount"
                                                                value={item?.yearly_amount}
                                                                disabled={!canEdit}
                                                            />

                                                        </Form.Item>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </Row>

                            <Row gutter={16}>
                                {
                                    ctcBreakUp?.retirals?.length > 0
                                    &&
                                    <>
                                        <Col span={24}>
                                            <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Retirals</p>
                                        </Col>
                                        {
                                            ctcBreakUp?.retirals?.map((item, index) => {
                                                return (
                                                    <Col span={24}>
                                                        <Form.Item
                                                            key={index}
                                                            label={item?.name}
                                                        >
                                                            <Input
                                                                size='large'
                                                                placeholder="Enter Amount"
                                                                value={item?.yearly_amount}
                                                                disabled={!canEdit}
                                                            />

                                                        </Form.Item>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </Row>

                            <Row gutter={16}>
                                {
                                    ctcBreakUp?.variable?.length > 0
                                    &&
                                    <>
                                        <Col span={24}>
                                            <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Variable</p>
                                        </Col>
                                        {
                                            ctcBreakUp?.variable?.map((item, index) => {
                                                return (
                                                    <Col span={24}>
                                                        <Form.Item
                                                            key={index}
                                                            label={item?.name}
                                                        >
                                                            <Input
                                                                size='large'
                                                                placeholder="Enter Amount"
                                                                value={item?.yearly_amount}
                                                                disabled={!canEdit}
                                                            />

                                                        </Form.Item>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </>
                                }


                            </Row>

                            {
                                (ctcBreakUp?.fixed.length > 0 || ctcBreakUp?.flexi.length > 0 || ctcBreakUp?.retirals?.length > 0 || ctcBreakUp?.variable?.length > 0)
                                &&
                                <div className="buttonContainer">
                                    {/* <Button style={{
                                    padding: "0px 15px",
                                    height: "40px",
                                    background: "#008080",
                                    color: "white",
                                    fontSize: "16px",
                                    marginTop: "10px"
                                }} onClick={() => prev()} >Previous</Button> */}
                                    <Button className='button' htmlType='submit' >Submit</Button>

                                </div>
                            }
                        </Form>

                    </div>
                    :
                    <div>

                        <Form
                            // form={form}
                            layout="vertical"
                            onFinish={onFinishCTC}
                            autoComplete="true"
                            fields={[{ name: "ctc", value: salaryDetails.ctc }]}
                        >
                            <Row gutter={32}>
                                <Col span={12}>
                                    <Form.Item
                                        name="ctc"
                                        // label="CTC"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter CTC!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            size='large'
                                            placeholder="Enter CTC"
                                            value={salaryDetails.ctc}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <div className="buttonContainer">
                                        <Button style={{
                                            padding: "0px 15px",
                                            height: "40px",
                                            background: "#008080",
                                            color: "white",
                                            fontSize: "16px",
                                            // marginTop: "10px"
                                        }} onClick={() => prev()} >Previous</Button>
                                        <button type="submit" style={{
                                            padding: "0px 15px",
                                            // marginTop: "3vh",
                                            height: "40px",
                                            background: "#008080",
                                            color: "white",
                                            border: "none",
                                            borderRadius: "5px"
                                        }}>Get BreakUp</button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                        {
                            (ctcBreakUp?.fixed.length > 0 || ctcBreakUp?.flexi.length > 0 || ctcBreakUp?.retirals?.length > 0 || ctcBreakUp?.variable?.length > 0)
                            &&
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
                                <p style={{ color: "#161616", fontWeight: "500", fontSize: "24px", margin: "0px" }}>Salary BreakUp</p>
                                <button style={{ padding: "0px 15px", height: "40px", background: "#008080", color: "white", border: "none", borderRadius: "5px" }}
                                    onClick={() => setCanEdit(true)}
                                >Edit</button>
                            </div>
                        }

                        <Form
                            // form={form}
                            layout="vertical"
                            // initialValues={employmentDetails}
                            onFinish={submit}
                            autoComplete="true"
                            fields={
                                [
                                    {
                                        name: "ctc",
                                        value: salaryDetails.ctc
                                    },
                                    {
                                        name: "salary_id",
                                        value: policies.salary_structure
                                    },

                                ]
                            }
                        >
                            <Row gutter={16}>
                                {
                                    ctcBreakUp?.fixed.length > 0
                                    &&
                                    <>
                                        <Col span={24}>
                                            <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Fixed</p>
                                        </Col>

                                        {
                                            ctcBreakUp?.fixed?.map((item, index) => {
                                                return (
                                                    <>
                                                        <Col span={12}>
                                                            <p>{item?.name}</p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                key={index}
                                                            >
                                                                <Input
                                                                    size='large'
                                                                    placeholder="Enter Amount"
                                                                    value={item?.yearly_amount}
                                                                    disabled={!canEdit}
                                                                    onChange={(e) => handleChangeCTC("fixed", index, e.target.value)}
                                                                />

                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                }

                            </Row>

                            <Row gutter={16}>
                                {
                                    ctcBreakUp?.flexi.length > 0
                                    &&
                                    <>
                                        <Col span={24}>
                                            <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Flexi</p>
                                        </Col>
                                        {
                                            ctcBreakUp?.flexi?.map((item, index) => {
                                                return (
                                                    <>
                                                        <Col span={12}>
                                                            <p>{item?.name}</p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                key={index}
                                                            >
                                                                <Input
                                                                    size='large'
                                                                    placeholder="Enter Amount"
                                                                    value={item?.yearly_amount}
                                                                    disabled={!canEdit}
                                                                    onChange={(e) => handleChangeCTC("flexi", index, e.target.value)}
                                                                />

                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </Row>

                            <Row gutter={16}>
                                {
                                    ctcBreakUp?.retirals?.length > 0
                                    &&
                                    <>
                                        <Col span={24}>
                                            <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Retirals</p>
                                        </Col>
                                        {
                                            ctcBreakUp?.retirals?.map((item, index) => {
                                                return (
                                                    <>
                                                        <Col span={12}>
                                                            <p>{item?.name}</p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                key={index}
                                                            >
                                                                <Input
                                                                    size='large'
                                                                    placeholder="Enter Amount"
                                                                    value={item?.yearly_amount}
                                                                    disabled={!canEdit}
                                                                    onChange={(e) => handleChangeCTC("retirals", index, e.target.value)}
                                                                />

                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </Row>

                            <Row gutter={16}>
                                {
                                    ctcBreakUp?.variable?.length > 0
                                    &&
                                    <>
                                        <Col span={24}>
                                            <p style={{ color: "#161616", fontWeight: "500", fontSize: "2vh" }}>Variable</p>
                                        </Col>
                                        {
                                            ctcBreakUp?.variable?.map((item, index) => {
                                                return (
                                                    <>
                                                        <Col span={12}>
                                                            <p>{item?.name}</p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                key={index}
                                                            >
                                                                <Input
                                                                    size='large'
                                                                    placeholder="Enter Amount"
                                                                    value={item?.yearly_amount}
                                                                    disabled={!canEdit}
                                                                    onChange={(e) => handleChangeCTC("variable", index, e.target.value)}
                                                                />

                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                }


                            </Row>
                            {
                                (ctcBreakUp?.fixed.length > 0 || ctcBreakUp?.flexi.length > 0 || ctcBreakUp?.retirals?.length > 0 || ctcBreakUp?.variable?.length > 0)
                                &&
                                <div className="buttonContainer">
                                    <Button className='button' htmlType='submit' >Submit</Button>
                                </div>
                            }
                            <div className="buttonContainer">
                                {/* <Button style={{
                                    padding: "0px 15px",
                                    height: "40px",
                                    background: "#008080",
                                    color: "white",
                                    fontSize: "16px",
                                    marginTop: "10px"
                                }} onClick={() => prev()} >Previous</Button> */}
                                {/* <Button className='button' htmlType='submit' >Submit</Button> */}

                            </div>
                        </Form>

                    </div>
            }

        </div>
    )
}

export default SalaryDetails