import { Col, Row, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'

// images
import bullet from "../../../../assets/bullet.svg"

// scss
import desktopStyle from "./Desktop.module.scss";
import request from '../../../../utils/axios';

import getScreenSize from "../../../../utils/getScreenSize";
import { useNavigate } from 'react-router-dom';

function Challan24Q({ tabsValue }) {
    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const challanColumns = [
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: 'Tax Deducted',
            dataIndex: 'total_tds_amount',
            key: 'total_tds_amount',
        },
        {
            title: 'Challan Payment',
            dataIndex: 'challan_payments',
            key: 'challan_payments',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) =>
                <>
                    <button
                        style={{
                            border: "none",
                            background: "#008080",
                            color: "white",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            cursor: "pointer"
                        }}
                        onClick={() => navigate(`/admin/payroll/form24Q/challan/${record.id}}`, { replace: true })}
                    >Add Challan</button>
                </>
        },
    ]

    const [selectedQuarter, setSelectedQuarter] = useState(null)
    const [quarterList, setQuarterList] = useState([])

    const fetchQuarterList = async () => {
        try {
            const data = await request({
                method: "GET",
                url: "tax-management/admin/get-quarterly-details",
            })

            setQuarterList([...data.data])
        }
        catch (error) {
            console.log("error : ", error)
        }
    }

    const [quarterTableData, setQuarterTableData] = useState([])

    const fetchQuarterData = async (id) => {
        try {
            const data = await request({
                method: "GET",
                url: `tax-management/admin/get-quarterly-challan-details?id=${id}`,
            })

            setQuarterTableData([...data.data])
        }
        catch (error) {
            console.log("error : ", error)
        }
    }

    useEffect(() => {
        if (tabsValue === "12") {
            fetchQuarterList()
        }
    }, [tabsValue])
    return (
        <div  >
            {
                screenSize.width < 768
                    ?
                    // mobile
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px" }}>
                            <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616", margin: "0px" }}>Quarters</p>
                            <Select
                                placeholder="Select Quarter"
                                defaultValue={selectedQuarter}
                                size='large'
                                onChange={(value) => {
                                    fetchQuarterData(value)
                                    // setSelectedQuarter(value)
                                }}
                            >
                                {
                                    quarterList?.map((item, index) => {
                                        return (
                                            <Select.Option value={item.id}>{item.monthly_quarters}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        <div>
                            {
                                quarterTableData?.map((item, index) => {
                                    return (
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px", marginBottom: "10px", marginTop: "20px" }}>
                                                <p style={{
                                                    fontSize: "18px",
                                                    fontWeight: "500",
                                                    color: "#161616",
                                                    margin: "0px"
                                                }}>{item?.month}</p>
                                                <button
                                                    style={{
                                                        border: "none",
                                                        background: "#008080",
                                                        color: "white",
                                                        padding: "5px 10px",
                                                        borderRadius: "5px",
                                                        cursor: "pointer"
                                                    }}
                                                >Add Challan</button>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px", marginBottom: "10px" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "500", color: "#4A4A4A", margin: "0px" }}>Tax Deducted</p>
                                                <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616", margin: "0px" }} >{item?.total_tds_amount}</p>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 16px" }}>
                                                <p style={{ fontSize: "18px", fontWeight: "500", color: "#4A4A4A", margin: "0px" }}>Challan Payment</p>
                                                <p style={{ fontSize: "16px", fontWeight: "500", color: "#161616", margin: "0px" }}>{item?.challan_payments}</p>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    :
                    // desktop
                    <div className={desktopStyle.container}>
                        <div>
                            <p className={desktopStyle.title}>24Q Challan</p>
                        </div>

                        <div className={desktopStyle.tableContainer} >
                            <Row>
                                <Col span={4}>
                                    <div className={desktopStyle.leftContainer} >
                                        <p className={desktopStyle.title}>Financial Quarters</p>
                                        {
                                            quarterList?.map((item, index) => {
                                                return (
                                                    <div className={desktopStyle.quarterContainer}
                                                        onClick={() => {
                                                            fetchQuarterData(item.id)
                                                            setSelectedQuarter(index)
                                                        }}
                                                    >
                                                        <div>
                                                            <p className={desktopStyle.monthDuration}>{item.monthly_quarters}</p>
                                                            <p className={desktopStyle.status}>{item.status}</p>
                                                        </div>
                                                        <img src={bullet} alt="bullet" />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Col>
                                <Col span={20}>
                                    <div className={desktopStyle.rightContainer}>
                                        <p className={desktopStyle.title}>{
                                            selectedQuarter !== null &&
                                            <span>
                                                Q{selectedQuarter + 1} ({quarterList[selectedQuarter]?.monthly_quarters})
                                            </span>
                                        }
                                        </p>
                                        <Table
                                            dataSource={quarterTableData}
                                            columns={challanColumns}
                                            pagination={false}
                                        />
                                    </div>

                                </Col>
                            </Row>
                        </div>
                    </div>
            }
        </div>
    )
}

export default Challan24Q