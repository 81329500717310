import {
  Button,
  Col,
  ColorPicker,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  theme,
  Tooltip,
  TreeSelect,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import getScreenSize from "../../../../utils/getScreenSize";
import moment from "moment";
import "./EmployeeLeavesFullView.scss";
import edit from "../../../../assets/edit.svg";
import request from "../../../../utils/axios";
import { InfoCircleOutlined, DownOutlined } from "@ant-design/icons";
import back from "../../../../assets/Recruitment/back.svg"
import { useNavigate } from "react-router-dom";
import arrowCircle from "../../../../assets/fullView.svg";
import add from "../../../../assets/Org/add.svg";
import CommonTable from "../../../../component/CommonTable/CommonTable";
import InfiniteScrollComponent from "../../../../component/InfiniteScroll/InfiniteScroll";
function LeavePolicyManagerFullView() {
  const screenSize = getScreenSize();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5, // Number of items per page
    total: 0,
  })

  const employeeLeavePolicyMangerColumns = [
    {
      title: 'Id',
      dataIndex: 'employement_number',
      key: 'employement_number',
    },
    {
      title: 'Employee Name',
      dataIndex: 'employee_name',
      key: 'employee_name',
    },
    {
      title: 'Leave Policy',
      dataIndex: 'leave_policy',
      key: 'leave_policy',
    },
    {
      title: 'Action',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => <img src={edit} alt="edit" width={20} height={20} onClick={() => {
        setSelectedEmployeeLeavePolicyManager({
          ...selectedEmployeeLeavePolicyManager,
          user_id: record.employement_number,
          policy_id: +record.policy_id
        })
        setChangeLeavePolicyModel(true)
      }} />
    }
  ]

  const [changeLeavePolicyModel, setChangeLeavePolicyModel] = useState(false)
  const [selectedEmployeeLeavePolicyManager, setSelectedEmployeeLeavePolicyManager] = useState({
    user_id: "",
    policy_id: ""
  })

  const [employeeLeavePolicyMangerList, setEmployeeLeavePolicyMangerList] = useState([])
  const fetchEmployeeLeavePolicyManger = async () => {
    try {
      const data = await request({
        method: "get",
        url: `leave/admin/leave-policy-management/?page=${pagination.current}&page_size=${pagination.pageSize}`,
      })
      setEmployeeLeavePolicyMangerList(data.pagination_data.data)
      setPagination({
        ...pagination,
        total: data?.pagination_data?.total
      })
    } catch (error) {
      console.log(error)
    }
  }
  const updateEmployeeLeavePolicy = async (values) => {
    try {
      const response = await request({
        method: "post",
        url: `leave/admin/employee/leave-policy/update?employement_number=${selectedEmployeeLeavePolicyManager.user_id}`,
        data: {
          policy_id: values.leave_policy
        }
      });
      setChangeLeavePolicyModel(false)
      fetchEmployeeLeavePolicyManger();
    }
    catch (err) {
      console.log("err", err);
    }
  }
  const [leavePolicy, setLeavePolicy] = useState([])
  const getLeavePolicy = async () => {
    let data = await request({
      method: "get",
      url: `leave/leave/policy/all/dropdown`,
    })
    setLeavePolicy(data.data)
  }

  useEffect(() => {
    if (screenSize.width > 768) {
      fetchEmployeeLeavePolicyManger();
    }
    getLeavePolicy();
  }, [pagination.current]);

  const ref = useRef(null);
  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'instant' })
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const renderUI = (dataList) => {

    return dataList?.map((item, index) => {
      return (
        <div key={index}>
          <div style={{ padding: "0px 16px", marginTop: "15px" }}>
            <Row>
              <Col span={23}>
                <p style={{ fontSize: "16px", fontWeight: "600", color: "#161616" }}>{item?.employee_name}</p>
              </Col>
              <Col span={1}>
                <img src={edit} alt="edit" className="editImage"
                  onClick={() => {

                    setSelectedEmployeeLeavePolicyManager({
                      ...selectedEmployeeLeavePolicyManager,
                      user_id: item.employement_number,
                      policy_id: +item.policy_id
                    })
                    setChangeLeavePolicyModel(true)
                  }}
                />
              </Col>
              <Col span={24}>
                <p style={{ fontSize: "16px", fontWeight: "400", color: "#939393" }}>{item?.employement_number}</p>
              </Col>
              <Col span={24}>
                <p style={{ fontSize: "16px", fontWeight: "400", color: "#939393" }}>{item?.leave_policy}</p>
              </Col>
            </Row>
          </div>
          {
            index !== employeeLeavePolicyMangerList?.length - 1 && (
              <hr className="divider" />
            )
          }
        </div>
      )
    })

  }

  const renderDesktopUI = (dataList) => {
    return <Table
      columns={employeeLeavePolicyMangerColumns}
      dataSource={dataList}
      pagination={false}
      sticky={{ offsetHeader: 0 }}
    />
  }

  return (
    <div className="employeeLeavesFullViewContainer" ref={ref}>
      {
        screenSize.width < 768
          ?
          <div>
            <div style={{ padding: "0px 16px" }}>
              <p style={{ fontSize: "24px", color: "#161616", fontWeight: "500px", margin: "0px" }}>Employee Leave Policy Manager</p>
              <div>
                {/* <img src={add} alt="add" className="addImage"
                  onClick={() => {
                    // navigate("/admin/leave/leave-policy/form")
                  }}
                /> */}


              </div>
            </div>
            <InfiniteScrollComponent
              apiEndpoint="leave/admin/leave-policy-management/"
              renderUI={renderUI}
              initialPage={1}
            />

          </div>
          :
          <>
            {/* <div>
              <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                <img src={back} alt="back" />
                <span style={{ paddingLeft: "10px" }}>Back</span>
              </p>
              <p className="heading">Leave</p>

            </div> */}

            <div style={{ marginBottom: "40px" }}>
              <div className="leavePolicyContainer" style={{ padding: "30px", background: "white", borderRadius: "5px" }}>
                <div>
                  <div className="flexSpaceBetween" style={{ marginBottom: "20px" }}>
                    <p className="employeeLeavesHeading">Leave Policy Manager </p>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "20px" }}
                    ></div>
                    <button
                      style={{ width: "120px", height: "40px", background: "#008080", color: "white", border: "none", borderRadius: "5px", cursor: "pointer" }}
                      onClick={() => navigate("/admin/leave/bulk-leave-policy")}
                    >
                      Bulk Update
                    </button>
                  </div>
                  <InfiniteScrollComponent
                    apiEndpoint="leave/admin/leave-policy-management/"
                    renderUI={renderDesktopUI}
                    initialPage={1}
                  />
                  {/* <CommonTable
                    columns={employeeLeavePolicyMangerColumns}
                    data={employeeLeavePolicyMangerList}
                    pagination={pagination}
                    setPagination={setPagination}
                    handleRowClick={() => { }}
                  /> */}
                </div>
              </div>
            </div>
          </>
      }

      {/* Change Leave Policy */}
      <Modal
        title="Change Leave Policy"
        centered
        open={changeLeavePolicyModel}
        onOk={() => setChangeLeavePolicyModel(false)}
        onCancel={() => setChangeLeavePolicyModel(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          // initialValues={policies}
          onFinish={updateEmployeeLeavePolicy}
          fields={[
            {
              name: "leave_policy",
              value: selectedEmployeeLeavePolicyManager.policy_id
            }
          ]}
        >
          <Form.Item
            label="Leave Policy"
            name="leave_policy"
            rules={[
              {
                required: true,
                message: 'Please Select Leave Policy!',
              },
            ]}
          >
            <Select
              size='large'
            >
              {
                leavePolicy.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                  )
                }
                )
              }
            </Select>

          </Form.Item>
          <Button htmlType="submit" style={{ background: "#008080", color: "white" }}>Update</Button>
        </Form>
      </Modal>
    </div>
  );
}

export default LeavePolicyManagerFullView;
