import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import request from '../../utils/axios';
import getScreenSize from "../../utils/getScreenSize";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

function Google() {
    const params = useParams()
    const navigate = useNavigate();
    const screenSize = getScreenSize();
    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const googleClientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET

    const handleGoogleLogin = async (token) => {
        try {
            let deviceType;
            if (screenSize.width < 768) {
                // mobile
                deviceType = 0
            }
            else if (screenSize.width < 1440) {
                // laptop
                deviceType = 1
            }
            else {
                // desktop
                deviceType = 2
            }
            const res = await request({

                method: "post",
                url: `/users/verify-login`,
                data: {
                    token: token,
                    backend: "google-oauth2",
                    grant_type: "convert_token",
                    client_id: googleClientId,
                    client_secret: googleClientSecret,
                    device_type: deviceType
                }
            })

            localStorage.setItem("access_token", res.data.access_token);
            localStorage.setItem("employee_number", res.data.employee_number);
            localStorage.setItem("on_boarding", res.data.on_boarding);
            localStorage.setItem("user_id", res.data.user_id)
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("parent_company_id", res.data.parent_company_id)
            localStorage.setItem("is_admin", res.data.is_admin)
            localStorage.setItem("role", "Emp")
            localStorage.setItem("role_list", JSON.stringify(res.data.role_list))
            localStorage.setItem("is_org_head", res.data.is_org_head);

            setInterval(() => {
                if (res && res.data.access_token && res.data.on_boarding) {
                    navigate('/dashboard');
                } else {
                    navigate('/onboarding')
                }
            }, 1000)
        }
        catch (err) {
            console.log(err)
            navigate('/login')
        }
    };


    useEffect(() => {
        handleGoogleLogin(params.token)
    }, [])

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48, color: "#008080" }} spin />} />
        </div>
    )
}

export default Google