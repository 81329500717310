import React, { useEffect, useRef, useState } from "react";
import { Input, Layout, Modal, Select, Switch } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";

import "./SideMenuWithHeader.scss";
import request from "../../utils/axios";

// import SearchBar from '../SearchBar/SearchBar';

import menuList from "../../data/menuList";
import AdminMenuList from "../../data/adminMenuList";
import profileImg from "../../assets/Leave/profileImg.svg";
import { SearchOutlined } from "@ant-design/icons";
import headerprofile from "../../assets/headerprofile.svg";
import logout from "../../assets/logout.svg";
import desktopLogo from "../../assets/desktopLogo.png";
import InitiateSepration from "../../assets/InitiateSepration.png";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfileInRedux } from "../../redux/reducer/UserProfileReducer";
import Loader from "../Loader/Loader";
import getScreenSize from "../../utils/getScreenSize";
import userProfileImg from "../../assets/user.png"

const { Header, Footer, Sider, Content } = Layout;

function SideMenuWithHeader({ content }) {
  const [activeItem, setActiveItem] = useState(null);
  const [profileModel, setProfileModel] = useState(false);
  const role_list = JSON.parse(localStorage.getItem("role_list"));
  const is_admin = JSON.parse(localStorage.getItem("is_admin"));
  const role = localStorage.getItem("role");
  const is_org_head = JSON.parse(localStorage.getItem("is_org_head"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile);
  const profileRef = useRef(null);
  const handleItemClick = (key) => {
    setActiveItem(key);
  };
  const [initiateSeprationModel, setInitialSeperationModel] = useState(false);
  const screenSize = getScreenSize();
  const switchAvatar = (userType) => {
    localStorage.setItem("role", userType);
    navigate("/dashboard", { replace: true });
  };

  const logoutUser = async () => {
    try {
      let deviceType;
      if (screenSize.width < 768) {
        // mobile
        deviceType = 0;
      } else if (screenSize.width < 1440) {
        // laptop
        deviceType = 1;
      } else {
        // desktop
        deviceType = 2;
      }

      await request({
        method: "post",
        url: `/users/logout`,
        data: {
          device_type: deviceType,
        },
      });

      localStorage.clear();
      let logout = "logout"
      if (window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
        window.ReactNativeWebView.postMessage(JSON.stringify({ logout }));
      } else {
        console.warn('ReactNativeWebView is not defined.');
      }
      setProfileModel(!profileModel);
      navigate("/login", { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  let location = useLocation();
  const currentUrl = location.pathname;

  const fetchUserProfileImage = async () => {
    try {
      const data = await request({
        method: "GET",
        url: "/users/dashboard/profile-image",
      });

      dispatch(
        setUserProfileInRedux({
          ...userProfile,
          profile_image:
            data.profile_image,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const [companyNameList, setCompanyNameList] = useState();
  const fetchCompanyName = async () => {
    try {
      const data = await request({
        method: "GET",
        url: "/admin/organizations/list",
      });
      console.log(data.data);
      setCompanyNameList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserProfileImage();
    if (is_org_head) {
      fetchCompanyName();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        // Clicked outside the popup, so close it
        setProfileModel(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role === "Admin") {
      const urlContainAdmin = currentUrl.split("/")[1] === "admin";
      const moduleName = currentUrl.split("/")[2];

      if (urlContainAdmin && is_admin) {
        const isAuthorized = role_list?.includes(moduleName);
        if (isAuthorized) {
          navigate(currentUrl);
        } else {
          navigate("/admin/dashboard");
        }
      } else if (!urlContainAdmin) {
        navigate(currentUrl);
      }
    } else {
      const moduleName = currentUrl.split("/")[1];
      localStorage.setItem("activeMenu", moduleName);

      setActiveItem(moduleName);
    }
  }, [currentUrl]);

  const [searchEmployeeList, setSearchEmployeeList] = useState([]);

  const search = async (data) => {
    try {
      const response = await request({
        method: "get",
        url: `leave/search/employees/?employee_name=${data}&type=admin`,
      });

      setSearchEmployeeList(response);
    } catch (err) {
      console.log(err);
    }
  };

  const initiateSeparation = async () => {
    try {
      await request({
        method: "post",
        url: `/employee/resignation/create`,
        data: {
          need_sepration: true,
        },
      });

      setInitialSeperationModel(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="sideMenuWithHeader">
      <Layout>
        <Sider width={90} className="sideMenu">
          <div className="logo">
            <img
              src={desktopLogo}
              alt="Logo"
              width={79}
              height={50}
              onClick={() => navigate("/dashboard", { replace: true })}
            />
          </div>
          <div className="sideMenuList">
            <ul>
              {role === "Admin" ? (
                <AdminMenuList />
              ) : (
                menuList.map((item, index) => {
                  return (
                    <Link to={item.path}>
                      <li
                        key={index}
                        className={` ${activeItem === item.key && "active"}`}
                        onClick={() => handleItemClick(item.key)}
                      >
                        <div className="menuItemContainer">
                          <img
                            src={
                              activeItem === item.key
                                ? item.activeImage
                                : item.image
                            }
                            className="menuItemImage"
                          />
                          <p
                            className={` ${activeItem === item.key
                              ? "activeMenuItemLabel"
                              : "menuItemLabel"
                              }`}
                          >
                            {item.label}
                          </p>
                        </div>
                      </li>
                    </Link>
                  );
                })
              )}
            </ul>
          </div>
        </Sider>
        <Layout>
          <Header className="header">
            <div className="globalSearch">
              <input
                type="text"
                placeholder="Search Employee"
                onChange={(e) => search(e.target.value)}
              />
              <SearchOutlined className="searchIcon" />
              {searchEmployeeList.length > 0 && (
                <ul>
                  {searchEmployeeList.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        navigate(`/user-profile/${item.employement_number}`, { replace: true });
                        setSearchEmployeeList([]);
                      }}
                    >
                      <div>{item.employee_name}</div>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                {is_admin && (
                  // <div className="avatar-container">
                  //     <div
                  //         onClick={() => switchAvatar('Emp')}
                  //         className={role === 'Emp' ? 'empSelected flexCenter' : 'empNotSelected flexCenter'}
                  //     >
                  //         <span>Emp</span>
                  //     </div>
                  //     {
                  //         JSON.parse(is_admin) && <div
                  //             onClick={() => { is_admin && switchAvatar('Admin') }}
                  //             className={role === 'Admin' ? 'adminSelected flexCenter' : is_admin ? 'adminNotSelected flexCenter' : 'adminNotSelectedDisable flexCenter'}
                  //         >
                  //             Admin
                  //         </div>
                  //     }
                  // </div>
                  <div className="avatar-container" style={{ gap: "30px" }}>
                    {
                      (role === 'Admin' && is_org_head)
                      &&
                      <Select
                        defaultValue={+localStorage.getItem("childCompany") ? +localStorage.getItem("childCompany") : ""}
                        style={{ width: 150 }}
                        onChange={(value) => localStorage.setItem("childCompany", value)}
                      >
                        <Select.Option value="" disabled>
                          Select Org
                        </Select.Option>
                        {companyNameList &&
                          companyNameList?.map((item, index) => {
                            return (

                              <Select.Option value={item.id}>
                                {item.org_name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    }

                    <div>
                      <Switch
                        checked={role === "Admin" ? true : false}
                        onChange={(e) => {
                          switchAvatar(e ? "Admin" : "Emp");
                          if (e) {
                            navigate("/admin/dashboard", { replace: true });
                          } else {
                            navigate("/dashboard", { replace: true });
                          }
                        }}
                      />
                      <span style={{ fontWeight: "600", marginLeft: "4px" }}>
                        {" "}
                        Admin
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div
                  style={{
                    position: "absolute",
                    top: "16px",
                    right: "50px",
                    zIndex: "999",
                  }}
                  onClick={() => {
                    setProfileModel(!profileModel);
                  }}
                >
                  <div
                    style={{
                      // position: "relative",
                      right: "40px",
                      border: "2px solid #3D2879",
                      height: "36px",
                      width: "36px",
                      borderRadius: "50%",
                      color: "black",
                      fontSize: "10px",
                      fontWeight: "600",
                      zIndex: "1",
                    }}
                  >
                    <img
                      src={userProfile?.profile_image ? userProfile.profile_image : userProfileImg}
                      alt=""
                      width={32}
                      height={32}
                      style={{
                        borderRadius: "50%",
                        top: "-17px",
                        position: "relative",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              {profileModel && (
                <div class="arrowContainer" ref={profileRef}>
                  <ul
                    style={{
                      listStyleType: "none",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <li
                      onClick={() => {
                        navigate("/user-profile", { replace: true });
                        setProfileModel(!profileModel);
                      }}
                      style={{
                        lineHeight: "50px",
                        marginLeft: "20px",
                      }}
                    >
                      <img src={headerprofile} alt="" />
                      <span
                        style={{
                          color: "#8E8E8E",
                          fontWeight: "400",
                          fontSize: "18px",
                          marginLeft: "10px",
                        }}
                      >
                        Profile
                      </span>
                    </li>
                    <li
                      onClick={() => logoutUser()}
                      style={{
                        lineHeight: "50px",
                        marginLeft: "20px",
                      }}
                    >
                      <img src={logout} alt="Avatar" />
                      <span
                        style={{
                          color: "#8E8E8E",
                          fontWeight: "400",
                          fontSize: "18px",
                          marginLeft: "10px",
                        }}
                      >
                        Logout
                      </span>
                    </li>
                    <li
                      onClick={() => setInitialSeperationModel(true)}
                      style={{
                        lineHeight: "50px",
                        marginLeft: "20px",
                      }}
                    >
                      <img
                        src={InitiateSepration}
                        alt="Avatar"
                        width={15}
                        height={15}
                      />
                      <span
                        style={{
                          color: "#8E8E8E",
                          fontWeight: "400",
                          fontSize: "18px",
                          marginLeft: "10px",
                        }}
                      >
                        Initiate Sepration
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </Header>
          <Content style={{ height: "93vh", overflow: "auto" }}>
            {content}
          </Content>
        </Layout>
      </Layout>

      <Modal
        centered
        open={initiateSeprationModel}
        onOk={() => setInitialSeperationModel(false)}
        onCancel={() => setInitialSeperationModel(false)}
        footer={null}
        width={600}
      >
        <p style={{ fontSize: "24px" }}>Initiate Separation</p>
        <p style={{ fontSize: "18px" }}>
          Are you sure you want to proceed with your resignation
        </p>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            style={{
              color: "#008080",
              border: "1px solid #008080",
              background: "none",
              height: "40px",
              width: "100px",
              borderRadius: "2px",
            }}
            onClick={() => setInitialSeperationModel(false)}
          >
            No
          </button>
          <button
            style={{
              color: "white",
              background: "#008080",
              border: "1px solid #008080",
              height: "40px",
              width: "100px",
              borderRadius: "2px",
            }}
            onClick={() => initiateSeparation()}
          >
            Yes
          </button>
        </div>
      </Modal>

      <Loader />
    </div>
  );
}

export default SideMenuWithHeader;
