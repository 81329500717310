import React from 'react';
import MeetingForm from './MeetingForm';
import {useLocation, useNavigate} from 'react-router-dom';
import back from '../../../assets/Recruitment/back.svg';
const AddMeeting = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {MeetingType} = location.state || {};
  console.log(MeetingType, 'meetingType');
  return (
    <div style={{padding: '50px'}}>
      <p
        onClick={() => {
          navigate(-1);
        }}
        style={{cursor: 'pointer'}}>
        <img src={back} alt="back" />
        <span style={{paddingLeft: '10px'}}>Back</span>
      </p>

      <p style={{fontSize: '32px', font: 500}}>
        {MeetingType == 'TeamMeeting' ? 'Add Team Meeting' : 'Add My Meeting'}
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}>
        <MeetingForm MeetingType={MeetingType} />
      </div>
    </div>
  );
};

export default AddMeeting;
