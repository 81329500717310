import React, { useEffect, useRef, useState } from 'react'
import useScreenSize from '../../../../../utils/getScreenSize';
import { useNavigate } from 'react-router-dom';
import back from "../../../../../assets/Recruitment/back.svg"
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Switch, Table, TimePicker } from 'antd';
import request from '../../../../../utils/axios';
import moment from 'moment';
import CalendarScheduler from '../../../../../component/CalendarScheduler/CalendarScheduler';
import Comment from '../../Comment/Comment';
import edit from "../../../../../assets/edit.svg"
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import DynamicTable from '../../../../../component/TableWithPagination/TableWithPagination';
import location from "../../../../../assets/location.svg"
import "./LogsFullView.scss"
import CalendarSearchBar from '../CalendarSearchBar/CalendarSearchBar';
import LogsSearchBar from '../LogsSearchBar/LogsSearchBar';
import Vector from '../../../../../assets/Vector.svg';
import CommonTable from '../../../../../component/CommonTable/CommonTable';
import TextArea from 'antd/es/input/TextArea';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';

function LogsFullView() {
    const ref = useRef(null);

    const [dataLength, setDataLength] = useState(null);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const [openModal, setOpenModal] = useState(false);

    const [regularizationForm] = Form.useForm();

    const [selectedDate, setSelectedDate] = useState(new Date())

    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null)

    const [selectedShift, setSelectedShift] = useState(null)

    const logsColumns = [
        {
            title: 'Id',
            dataIndex: 'employee_id',
            key: 'employee_id',
        },
        {
            title: ' Date',
            dataIndex: 'attendance_date',
            key: 'attendance_date',
        },
        {
            title: 'Employee',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Role/Department',
            dataIndex: 'role',
            key: 'role',
            render: (text, record) => <>
                {
                    record.designation + " / " + record.department
                }
            </>
        },
        {
            title: ' Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <>
                {
                    text === "Present" && <span style={{ backgroundColor: "#008080", padding: "5px 10px", color: "white", borderRadius: "5px" }}>{text}</span>
                }
                {
                    text === "Absent" && <span style={{ backgroundColor: "#F9A1B5", padding: "5px 10px", color: "white", borderRadius: "5px" }}>{text}</span>
                }
                {
                    text === "On Leave" && <span style={{ backgroundColor: record.color, padding: "5px 10px", color: "white", borderRadius: "5px" }}>{record.leave_type}</span>
                }
                {
                    text === "Weekly Off" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "5px" }}>{text}</span>
                }
                {
                    text === "Yet to checkin" && <span style={{ backgroundColor: "rgb(233, 241, 255)", padding: "5px 10px", color: "rgb(0, 96, 255)", borderRadius: "5px" }}>Pending</span>
                }
                {
                    text === "Late ClockIn" && <span style={{ backgroundColor: "#f53444", padding: "5px 10px", color: "white", borderRadius: "5px" }}>{text}</span>
                }
                {
                    text === "Early Departure" && <span style={{ backgroundColor: "rgb(0, 96, 255)", padding: "5px 10px", color: "rgb(233, 241, 255)", borderRadius: "5px", margin: "5px 0px", textAlign: "center" }}>Short Duration</span>
                }
            </>
        },
        {
            title: 'Check-in',
            dataIndex: 'check_in_time',
            key: 'check_in_time',
        },
        {
            title: 'Check-out',
            dataIndex: 'check_out_time',
            key: 'check_out_time',
            render: (text, record) => <span>
                {
                    text === null ? "Pending..." : text === "no_checkout" ? "No Check Out" : text
                }
            </span>
        },
        {
            title: 'Work hours',
            dataIndex: 'working_duration',
            key: 'working_duration',
        },
        // {
        //   title: "Comment",
        //   dataIndex: "comment",
        //   key: "comment",
        //   render: (text, record) => (
        //     <span>
        //       {text ? (
        //         <div style={{ display: "flex", gap: "10px" }}>
        //           <p>{text}</p>
        //           <img src={edit} alt="edit" />{" "}
        //         </div>
        //       ) : (
        //         <span
        //           style={{
        //             color: "#3E3D3D",
        //             backgroundColor: "#EBEBEB",
        //             padding: "10px",
        //             borderRadius: "3px",
        //             fontSize: "13px",
        //           }}
        //         >
        //           Add Comment
        //         </span>
        //       )}
        //     </span>
        //   ),
        // },

        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
                <div>
                    {
                        !record.is_holiday && !record.is_weekly_holiday && !record.on_leave && !record.is_pending &&

                        <Button className="button" onClick={() => {
                            setSelectedDate(record.attendance_date)
                            setSelectedEmployeeId(record.employee_id)
                            setSelectedShift(record.shift_type)
                            setOpenModal(true)
                        }}>
                            Regularise
                        </Button>


                    }
                </div>)
        },

    ];
    const screenSize = useScreenSize();
    const navigate = useNavigate();
    const [calendarView, setCalendarView] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [open, setOpen] = useState(false);
    const [days, setDays] = useState(7);
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const { Option } = Select;
    const [dateRange, setDateRange] = useState([]);
    const [tableData, setTableData] = useState();
    // const [loading, setLoading] = useState(true);
    const [calendarData, setCalendarData] = useState([])
    const [calendarMonth, setCalendarMonth] = useState('');
    const [calendarYear, setCalendarYear] = useState('');
    const [reload, setReload] = useState(false)
    const [logsDetails, setLogsDetails] = useState();
    const [specificDateForLogs, setSpecificDateForLogs] = useState("");
    const [employeeList, setEmployeeList] = useState([]);
    const [employeeId, setEmployeeId] = useState('');
    const [attendanceReportCustomDate, setAttendanceReportCustomDate] = useState({
        start_date: "",
        end_date: ""
    });
    const [attendanceReportDays, setAttendanceReportDays] = useState(7);
    const { RangePicker } = DatePicker

    // const [page, setPage] = useState(1);
    // const page_size = 5;
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10, // Number of items per page
        total: 0,
    })

    const handleDateChange = (value) => {
        const start_date = value[0]?.format("YYYY-MM-DD");
        const end_date = value[1]?.format("YYYY-MM-DD");
        setDateRange(value);
        setAttendanceReportCustomDate({ ...attendanceReportCustomDate, start_date, end_date })
    };

    const handleSwitchChange = (checked) => {
        setCalendarView(checked);
    }

    const fetchLogsData = async (pageParams, dataList) => {
        const { current } = pageParams || pagination;
        console.log("current", current);
        if (attendanceReportDays === "custom") {
            if (attendanceReportCustomDate.start_date && attendanceReportCustomDate.end_date) {
                const data = await request({
                    method: "get",
                    url: `attendance/my-team/employees/attendance/details/?page=${current}&page_size=${pagination.pageSize}&start_date=${attendanceReportCustomDate.start_date}&end_date=${attendanceReportCustomDate.end_date}`,
                });

                if (pageParams) {
                    setItems([...dataList, ...data.pagination_data.data])
                }
                else {
                    setItems([...items, ...data.pagination_data.data])
                }

                setDataLength(data?.pagination_data?.total);
                setPagination({
                    ...pagination,
                    total: data?.pagination_data?.total,
                    current: current + 1
                })
                if (data?.pagination_data?.next === null) {
                    setHasMore(false);
                    return;
                }
            }
        } else {
            const data = await request({
                method: "get",
                url: `attendance/my-team/employees/attendance/details/?page=${current}&page_size=${pagination.pageSize}&days=${attendanceReportDays}`,
            });

            if (pageParams) {
                setItems([...dataList, ...data.pagination_data.data])
            }
            else {
                setItems([...items, ...data.pagination_data.data])
            }

            setDataLength(data?.pagination_data?.total);
            setPagination({
                ...pagination,
                total: data?.pagination_data?.total,
                current: current + 1
            })
            if (data?.pagination_data?.next === null) {
                setHasMore(false);
                return;
            }

        }
    };

    const regularizeRequest = async (values) => {
        console.log("values .....", values);
        const data = await request({
            method: "post",
            url: `attendance/manager/regularization/apply?shift=${selectedShift}&employement_number=${selectedEmployeeId}`,
            data: {
                attendance_date: selectedDate   ,
                ...values,
                check_in_time: dayjs(values.check_in_time).format(" HH:mm:ss"),
                check_out_time: values.check_out_time ? dayjs(values.check_out_time).format(" HH:mm:ss") : null,

            },
        });
        setItems([]);
        setPagination({
            ...pagination,
            current: 1
        })
        setOpenModal(false);
        fetchLogsData();
        regularizationForm.resetFields();
        toast.success("Regularization request submitted successfully", {
            position: "bottom-left",
        });
    }

    useEffect(() => {
        fetchLogsData();
    }, [specificDateForLogs, attendanceReportDays, attendanceReportCustomDate.start_date, attendanceReportCustomDate.end_date]);

    const myTeamEmployee = async () => {
        const data = await request({
            method: "get",
            url: `attendance/my-team/employees/`,
        })

        setEmployeeList(data)
    }


    const fetchCalendarData = async () => {
        const data = await request({
            method: "get",
            url: `attendance/my-team/employee/calender/monthly/?employement_number=${employeeId}&month=${calendarMonth}&year=${calendarYear}`,
        })
        if (data && data.data && data.data.monthly_attendance) {
            setCalendarData(data?.data?.monthly_attendance)
        }
    }

    const handleSelectChange = (value) => {
        setEmployeeId(value)
    }


    useEffect(() => {
        if (calendarView) {
            fetchCalendarData();
            myTeamEmployee();
        }
        else {
            setCalendarData([])
            setEmployeeId('')
        }
    }, [calendarView, employeeId, calendarMonth, calendarYear])

    useEffect(() => {
        ref?.current?.scrollIntoView({ behavior: 'instant' })
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    const getPopupContainer = (triggerNode) => {
        return triggerNode.parentNode;
    };

    const handleDaysChange = (value) => {
        console.log("value", value);
        if (value === "custom") {
            // setShowDatePicker(true)
            setAttendanceReportDays(value);
            setOpen(true);
        } else {
            // setShowDatePicker(false)
            setAttendanceReportDays(value);
        }
    };

    const renderUIDesktop = (dataList) => {
        return <Table
            columns={logsColumns}
            dataSource={items}
            pagination={false}
            sticky={{ offsetHeader: 0 }}
        />
    }


    return (
        <div className='logsFullViewContainer' ref={ref}>
            {
                screenSize.width < 768
                    ?
                    // mobile view
                    <div className="logsContainer">
                        <div
                            className="flexSpaceBetween"
                            style={{ marginBottom: "30px", width: "100%" }}
                        >
                            <span className="logs">Logs</span>

                        </div>

                        <div>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
                                <Switch
                                    checked={calendarView}
                                    onChange={handleSwitchChange}
                                />
                                <p style={{ fontSize: "16px", margin: "0px" }}>
                                    {!calendarView ? "Calendar View" : "Calendar View"}
                                </p>
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                                {calendarView ? (
                                    <CalendarSearchBar
                                        setEmployeeId={setEmployeeId}
                                        setCalendarData={setCalendarData}
                                        empType="my_team"
                                    />
                                ) : (
                                    <>
                                        <Select
                                            style={{ width: "100%", height: 40 }}
                                            placeholder="Select an option"
                                            onChange={handleDaysChange}
                                            value={attendanceReportDays}
                                        >
                                            <Select.Option value={7}>Last 7 days</Select.Option>
                                            <Select.Option value={14}>Last 14 days</Select.Option>
                                            <Select.Option value={30}>Last 30 days</Select.Option>
                                            <Select.Option value="custom">
                                                <span
                                                    onClick={() => {
                                                        setOpen(true);
                                                    }}
                                                >
                                                    Custom Date
                                                </span>
                                            </Select.Option>
                                        </Select>

                                        <div style={{ marginTop: "20px" }}>
                                            <LogsSearchBar
                                                setTableDataForSearchEmployee={setLogsDetails}
                                                fetchTableDataFromApi={fetchLogsData}
                                                placeholderText="Search for employees"
                                                empType="my_team"
                                            />
                                        </div>
                                    </>
                                )}
                            </div>

                            {calendarView ? (
                                <CalendarScheduler
                                    data={calendarData}
                                    employeeId={employeeId}
                                    setCalendarMonth={setCalendarMonth}
                                    setCalendarYear={setCalendarYear}
                                />
                            ) : (
                                <div >
                                    {logsDetails?.map((item, index) => {
                                        return (
                                            <div>
                                                <p
                                                    style={{
                                                        fontSize: "18px",
                                                        fontFamily: "Poppins-Medium",
                                                        color: "black",
                                                        marginTop: "5px",
                                                    }}
                                                >
                                                    {item?.attendance_date}
                                                </p>
                                                <p style={{ fontSize: "16px" }}>
                                                    {item.employee_name}
                                                </p>
                                                <p style={{ fontSize: "16px" }}>
                                                    {item.department}
                                                </p>
                                                {item?.is_absent ||
                                                    item?.is_holiday ||
                                                    item?.is_weekly_holiday
                                                    ?
                                                    (
                                                        <div>
                                                            <p>
                                                                {item?.is_holiday ? (
                                                                    item?.is_holidayName
                                                                ) : item?.is_weekly_holiday ? (
                                                                    <div className="weeklyOff">
                                                                        <p
                                                                            style={{
                                                                                fontSize: "10px",
                                                                                color: "#008080",
                                                                                padding: "4px",
                                                                                fontWeight: 400,
                                                                                textAlign: "center",
                                                                                marginTop: "8px",
                                                                            }}
                                                                        >
                                                                            Weekly Off
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    <div style={{
                                                                        display: "flex",
                                                                        padding: "0px 8px",
                                                                        border: "1px solid red",
                                                                        borderRadius: "21px",
                                                                        height: "fit-content",
                                                                        width: "fit-content",
                                                                        lineHeight: "1px",
                                                                    }}>
                                                                        <p
                                                                            style={{
                                                                                fontSize: "10px",
                                                                                color: "red",
                                                                                padding: "4px",
                                                                                fontWeight: 400,
                                                                                textAlign: "center",
                                                                                marginTop: "8px",
                                                                            }}
                                                                        >
                                                                            {item?.is_absent && "Absent"}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </p>
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <p
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontFamily: "Poppins-Regular",
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    Shift 1
                                                                </p>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <p
                                                                        style={{
                                                                            fontFamily: "Poppins-Regular",
                                                                            fontSize: "12px",
                                                                            marginLeft: "10px",
                                                                            color: "#8A8A8A",
                                                                        }}
                                                                    >
                                                                        {item.check_in_time}
                                                                    </p>
                                                                    <p style={{ fontFamily: "Poppins-Regular", fontSize: "12px", color: "#8A8A8A" }}>- - -</p>
                                                                    <p
                                                                        style={{
                                                                            fontFamily: "Poppins-Regular",
                                                                            fontSize: "12px",
                                                                            color: "#8A8A8A",
                                                                        }}
                                                                    >
                                                                        {item.check_out_time === null ? "Pending..." : item.check_out_time === "no_checkout" ? "No Check Out" : item.check_out_time}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <img
                                                                    alt="noimg"
                                                                    src={Vector}
                                                                    style={{
                                                                        width: "11px",
                                                                        height: "15px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                />
                                                                <p
                                                                    style={{
                                                                        fontFamily: "Poppins-Regular",
                                                                        fontSize: "12px",
                                                                        color: "#616161",
                                                                        marginLeft: "5px",
                                                                    }}
                                                                >
                                                                    {item?.location}
                                                                </p>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <p
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        fontFamily: "Poppins-Regular",
                                                                        color: "black",
                                                                    }}
                                                                >
                                                                    Comment
                                                                </p>
                                                                <p
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        fontFamily: "Poppins-Regular",
                                                                        color: "#8A8A8A",
                                                                    }}
                                                                >
                                                                    {item?.comment}
                                                                </p>
                                                            </div>
                                                        </>
                                                    )}
                                                <hr
                                                    style={{
                                                        height: "1px",
                                                        backgroundColor: "#F0F0F0",
                                                        border: "none",
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>

                    :
                    // desktop view
                    <>
                        <p onClick={() => { navigate(-2) }} style={{ cursor: "pointer" }}>
                            <img src={back} alt="back" />
                            <span style={{ paddingLeft: "10px" }}>Back</span>
                        </p>
                        <h1>Attendance</h1>
                        <div style={{ backgroundColor: "white", padding: "20px" }}>
                            <div className='flexSpaceBetween'>
                                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                    <p style={{ fontSize: "24px" }}>My Team</p>
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                        <Switch
                                            checked={calendarView}
                                            onChange={handleSwitchChange}
                                        />
                                        <span className='calendarView'>{
                                            calendarView ? "Calendar View" : "Calendar View"
                                        }</span>
                                    </div>
                                </div>

                                <div className='flexCenter'>
                                    {
                                        calendarView
                                            ?
                                            <CalendarSearchBar
                                                setEmployeeId={setEmployeeId}
                                                setCalendarData={setCalendarData}
                                                empType="my_team"
                                            />
                                            :
                                            <>
                                                <div style={{ marginRight: "20px" }}>
                                                    <LogsSearchBar
                                                        setTableData={setItems}
                                                        setHasMore={setHasMore}
                                                        fetchTableDataFromApi={fetchLogsData}
                                                        setPagination={setPagination}
                                                        placeholderText="Search for employees"
                                                        empType="my_team"
                                                    />
                                                </div>
                                                <Select
                                                    style={{ width: "300px", height: 40 }}
                                                    placeholder="Select an option"
                                                    onChange={handleDaysChange}
                                                    value={attendanceReportDays}
                                                >
                                                    <Select.Option value={7}>Last 7 days</Select.Option>
                                                    <Select.Option value={14}>Last 14 days</Select.Option>
                                                    <Select.Option value={30}>Last 30 days</Select.Option>
                                                    <Select.Option value="custom">
                                                        <span
                                                            onClick={() => {
                                                                setOpen(true);
                                                            }}
                                                        >
                                                            Custom Date
                                                        </span>
                                                    </Select.Option>
                                                </Select>
                                            </>
                                    }

                                </div>
                            </div>
                            {
                                calendarView
                                    ?
                                    <CalendarScheduler
                                        data={calendarData}
                                        employeeId={employeeId}
                                        setCalendarMonth={setCalendarMonth}
                                        setCalendarYear={setCalendarYear}
                                    />
                                    :
                                    // <CommonTable
                                    //     columns={logsColumns}
                                    //     data={logsDetails}
                                    //     pagination={pagination}
                                    //     setPagination={setPagination}
                                    //     handleRowClick={() => { }}
                                    // />
                                    <div
                                        id="scrollableDiv"
                                        style={{
                                            height: "auto",
                                            overflow: 'auto',
                                            display: 'flex',
                                            flexDirection: 'column-reverse',
                                        }}
                                    >
                                        <InfiniteScroll
                                            dataLength={dataLength - (items.length)}
                                            next={fetchLogsData}
                                            hasMore={hasMore}
                                            endMessage={
                                                <p style={{ textAlign: 'center' }}>
                                                    <b>Yay! You have seen it all</b>
                                                </p>
                                            }
                                            loader={
                                                <p style={{ textAlign: 'center' }}>
                                                    <b>Loading...</b>
                                                </p>
                                            }
                                            scrollableTarget="scrollableDiv"
                                            height="70vh"
                                        >
                                            {
                                                renderUIDesktop(items)
                                            }
                                        </InfiniteScroll>
                                    </div>
                                // <Table
                                //     columns={logsColumns}
                                //     dataSource={logsDetails}
                                //     // loading={loading}
                                //     pagination={true}
                                // />
                            }
                        </div>
                    </>

            }

            <Modal
                title="Select Date Range"
                centered
                open={open}
                onCancel={() => setOpen(false)}
                onOk={async () => {

                    setOpen(false);
                }}
                footer={null}
            >
                <RangePicker
                    value={dateRange}
                    onChange={handleDateChange}
                    format="YYYY-MM-DD"
                    disabledDate={(current) =>
                        current && current > moment().endOf("day")
                    }
                    style={{ color: "black !important" }}
                />
            </Modal>
            {/* regularization request */}
            <Modal
                centered
                open={openModal}
                footer={null}
                onCancel={() => {
                    setOpenModal(false);
                    setSelectedEmployeeId(null);
                    setSelectedShift(null);
                    regularizationForm.resetFields();
                }}

            >
                <p style={{ fontSize: "20px", lineHeight: "30px", fontWeight: "500" }}>Regularisation Request</p>
                <Form
                    form={regularizationForm}
                    onFinish={regularizeRequest}
                    layout="vertical"
                // fields={[
                //   {
                //     name: ['start_date'],
                //     value: GetJoiningDateForEmployee ? dayjs(GetJoiningDateForEmployee) : null
                //   }
                // ]}
                >
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Check In"
                                name="check_in_time"
                                rules={[
                                    { required: true, message: "Required" },
                                ]}
                            >
                                <TimePicker
                                    hourStep={1}
                                    minuteStep={15}
                                    format="HH:mm"
                                    // placeholder="-ve Earliest Time Allowed"
                                    size='large'
                                    style={{ width: "100%" }}
                                    onChange={(time, timeString) => {
                                        console.log(time, "time.....................");
                                        // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                                        // const totalMinutes = hours * 60 + minutes;
                                    }}
                                />
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Check Out"
                                name="check_out_time"
                            >
                                <TimePicker
                                    hourStep={1}
                                    minuteStep={15}
                                    format="HH:mm"
                                    // placeholder="-ve Earliest Time Allowed"
                                    size='large'
                                    style={{ width: "100%" }}
                                    onChange={(time, timeString) => {
                                        console.log(time, "time.....................22222222222222");
                                        // const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
                                        // const totalMinutes = hours * 60 + minutes;
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: "Please add Reason!",
                                },
                            ]}
                        >
                            <TextArea
                                rows={5}
                                placeholder="Enter Your Reason"
                                className="leaveTextArea"
                                onChange={(e) =>
                                    "reason"
                                    // handleFormChange("reason", e.target.value)
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Button
                        htmlType="submit"
                        className="forgotPasswordButton"
                    >
                        Update
                    </Button>
                </Form>
            </Modal>

        </div>
    )
}

export default LogsFullView